export const severityButtons = ["Critical", "Moderate", "Minor"];
export const iecButtons = ["IEC 1", "IEC 2", "IEC 3", "Uncategorized"];
export const labelButtons = ["New", "Persistent", "Transitioned", "Recurring"];
export const anomalyStatusData = [
  {
    type: "pending",
    label: "Pending",
    view: "buttonView",
  },
  {
    type: "notfound",
    view: "buttonView",
    label: "Not Found",
  },
  {
    type: "inprogress",
    view: "buttonView",
    label: "In Progress",
  },
  {
    type: "falsepositive",
    label: "False +ve",
    view: "buttonView",
  },
  {
    type: "resolved",
    label: "Resolved",
    view: "buttonView",
  },
];
