const styles = {
  paperDialog: {
    "@media only screen and (max-width: 600px)": {
      margin: 5,
      width: "100%",
    },
  },
  dialogActions: {
    "@media only screen and (max-width: 600px)": {
      display: "flex",
      justifyContent: "flex-end",
      flexDirection: "row-reverse",
      alignContent: "flex-start",
    },
  },
  clearBtn: {
    borderRadius: 8,
    color: "#536471",
    width: "16px",
    height: "16px",

    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "20px",
    marginRight: "32px",
  },
  titleBtns: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    height: "100%",
  },
  applyBtn: {
    background: "linear-gradient(266.69deg, #59AFFF 0.23%, #0882F3 99.28%)",
    borderRadius: 8,
    width: "100px",
    height: "36px",
    backgroundColor: "#005aff",
    color: "#fff",
    marginRight: 32,
    marginLeft: 10,
  },
  durationBtn: {
    border: "1px solid #0084FF",
    margin: 0,
    padding: "8px, 12px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "20px",
    color: "#536471",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: "#0084FF",
      color: "#fff",
    },
  },
  durationBtnClick: {
    border: "1px solid #0084FF",
    margin: 0,
    padding: "8px, 12px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "20px",

    borderRadius: "8px",
    backgroundColor: "#0084FF",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#0084FF",
      color: "#fff",
    },
  },
  toolTipLoss: {
    maxWidth: "149px",
    color: "#ffffff",
    background: "#08001D",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
    borderRadius: "5px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "11px",
    lineHeight: "16px",
  },
  btnGroup: {
    display: "flex",
    justifyContent: "flex-start",
    alignContent: "stretch",
  },
  downloadBtn: {
    flex: "none",
    order: 1,
    flexGrow: 0,
    padding: "8px 12px;",
    color: "#536471",
    margin: "0px 16px",
    background: "#FFFFFF",
    border: "1px solid #DDE5EC",
    boxSizing: "border-box",
    borderRadius: "8px",
  },
  btns: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    overflowY: "visible",
  },
  hsection: {
    margin: "0px",
    padding: "0px",
    minWidth: "900px",
    border: "2px solid red",
    overflow: "scroll",
  },
  gridContainer: {
    paddingLeft: "32px",
    paddingRight: "24px",
    paddingBottom: "0px",
    cursor: "pointer",
  },
  gridContainer2: {
    height: "100%",
    paddingLeft: "24px",
    paddingRight: "24px",
    minWidth: "500px",
    overflow: "auto",
  },
  grid: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "20px",
    color: "#536471",
  },
  middle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap",
    alignItems: "center",
    alignContent: "center",

    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "20px",
    color: "#536471",
  },
  highlight: {
    fontWeight: 550,
    color: "#08001D",
    clear: "both",
    display: "inline-block",
  },
  left: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    alignItems: "center",
    alignContent: "center",

    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "20px",
    color: "#536471",
  },
  right: {
    flexDirection: "row",
    justifyContent: "flex-end",
    flexWrap: "wrap",
    alignItems: "center",
    alignContent: "center",
    textAlign: "right",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontSize: "12px",
    lineHeight: "20px",
    color: "#536471",
    maxWidth: 135,
    minWidth: 135,
    marginRight: 15,
  },

  forSpanishLangRight: {
    flexDirection: "row",
    justifyContent: "flex-end",
    flexWrap: "wrap",
    alignItems: "center",
    alignContent: "center",
    textAlign: "right",

    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "20px",
    color: "#536471",
    maxWidth: 135,
    minWidth: 135,
    marginRight: "-60px",
    marginLeft: "50px",
  },

  forSpanishLangRightYield: {
    flexDirection: "row",
    justifyContent: "flex-end",
    flexWrap: "wrap",
    alignItems: "center",
    alignContent: "center",
    textAlign: "right",

    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "20px",
    color: "#536471",
    maxWidth: 135,
    minWidth: 135,
    marginRight: "-100px",
    marginLeft: "110px",
  },

  forEnergyRecovered: {
    flexDirection: "row",
    justifyContent: "flex-end",
    flexWrap: "wrap",
    alignItems: "center",
    alignContent: "center",
    textAlign: "right",

    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "20px",
    color: "#536471",
    maxWidth: 135,
    minWidth: 135,
    marginRight: "-165px",
    marginLeft: "150px",
  },

  forTariff: {
    flexDirection: "row",
    justifyContent: "flex-end",
    flexWrap: "wrap",
    alignItems: "center",
    alignContent: "center",
    textAlign: "right",

    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "20px",
    color: "#536471",
    maxWidth: 135,
    minWidth: 135,
    marginRight: "-280px",
    marginLeft: "330px",
  },

  forLoss: {
    flexDirection: "row",
    justifyContent: "flex-end",
    flexWrap: "wrap",
    alignItems: "center",
    alignContent: "center",
    textAlign: "right",

    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "20px",
    color: "#536471",
    maxWidth: 135,
    minWidth: 135,
    marginRight: "-280px",
    marginLeft: "330px",
  },

  forRevRecov: {
    flexDirection: "row",
    justifyContent: "flex-end",
    flexWrap: "wrap",
    alignItems: "center",
    alignContent: "center",
    textAlign: "right",

    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "20px",
    color: "#536471",
    maxWidth: 135,
    minWidth: 135,
    marginRight: "-350px",
    marginLeft: "330px",
  },

  dropDownHeader: {
    overflow: "visible",
    padding: "10px 0px 10px 0px ",
  },
  headerContent: {
    paddingLeft: "32px",
    paddingRight: "24px",
    paddingBottom: "0px",

    padding: 0,
    background: "#FBFCFF",
    minHeight: "56px",
    maxHeight: "56px",
    position: "sticky",
    zIndex: 100,
    top: 0,
  },
  headerContent2: {
    paddingRight: 24,
    paddingLeft: 24,
    background: "#FBFCFF",
    height: "36px",
    display: "flex",
  },
  mainContent: {
    paddingRight: 24,
    paddingLeft: 24,
    background: "#FFF",
    height: "36px",
    display: "flex",
    overflowY: "visible",
    alignItems: "center",
  },
  mainContent2: {
    paddingRight: 24,
    paddingLeft: 24,
    fontWeight: 700,
    background: "#FFF",
    height: "36px",
    display: "flex",
    overflowY: "visible",
    alignItems: "center",
  },
  down: {
    position: "static",
    width: "8px",
    height: "4px",
    left: "87px",
    top: "calc(50% - 4px/2)",

    background: "#0084FF",
    borderRadius: "0.5px",

    flex: "none",
    order: 1,
    flexGrow: 0,
    margin: "0px 6px",
  },
  ip$: {
    borderRadius: "8px 0px 0px 8px",
    width: "24px",
    height: "33px",

    background: "#F0F4FA",
    border: "1px solid #DDE5EC",
    boxSizing: "border-box",

    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",

    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "20px",
    color: "#536471",
    marginTop: 1,
    marginRight: "-6px",
    zIndex: 1,
  },
  input: {
    borderRadius: 8,
    width: "96px",
    height: "32px",
    background: "#FFFFFF",
    border: "1px solid #DDE5EC",
    boxSizing: "border-box",
    paddingLeft: 12,
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "20px",
    color: "#08001D",
  },
  input2: {
    borderRadius: "0px 8px 8px 0px",
    width: "72px",
    height: "32px",

    background: "#FFFFFF",
    border: "1px solid #DDE5EC",
    boxSizing: "border-box",

    paddingLeft: 12,
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "20px",
    color: "#08001D",
  },
  text1: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "20px",
    /* identical to box height, or 167% */

    textAlign: "right",

    /* Purple / 1 */

    color: "#08001D",
  },
  recovered: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 625,
    fontSize: "12px",
    lineHeight: "20px",
    color: "#08001D",
  },
  text2: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "20px",
    color: "#08001D",
  },
  text3: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "11px",
    lineHeight: "16px",
    padding: "0 24px",
    color: "#536471",
  },
  tri: {
    position: "static",
    width: "8px",
    height: "4px",
    left: "87px",
    top: "calc(50% - 4px/2)",

    /* Primary / Blue */

    background: "#0084FF",
    borderRadius: "0.5px",

    /* Inside Auto Layout */

    flex: "none",
    order: "1",
    flexGrow: "0",
    margin: "0px 6px",
    /* Vector 2 */
  },
  heavy: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 525,
    fontSize: "11px",
    lineHeight: "16px",

    color: "#08001D",
  },

  inspDetailsBox: {
    border: "1px solid #F0F4FA",
    boxSizing: "border-box",
    borderRadius: "8px",
    minHeight: "50px",
    background: "#FFF",
  },
  inspDateHeader: {
    display: "flex",
    flexDirection: "row",
    height: "48px",
  },
  infoIcon: {
    color: "#C7CEDB",
  },
  siteList: {
    overflowX: "auto",
    padding: 0,
    overflowY: "auto",
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
  },
  inspD: {
    background: "#F0F4FA",
    padding: "16px",
  },
  greenPercent: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 12,
    lineHeight: "20px",
    textAlign: "right",
    color: "#1CA74A",
  },
  redPercent: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 12,
    lineHeight: "20px",
    textAlign: "right",
    color: "#f84343",
  },
  dataTab: {
    "&:focus": {
      outline: "unset",
    },
  },
  titleTxt: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 18,
    lineHeight: "20px",
    color: "#1D2D47",
  },
  root: {
    padding: "12px 18px 12px 24px",
  },
  tableHeader: {
    minWidth: 140,
    padding: 8,
  },
  paperRoot: {
    width: "100%",
    overflowX: "auto",
    boxShadow: "none",
  },
  inspTableHeader: {
    minWidth: 140,
    padding: 12,

    width: 140,
    maxWidth: 140,
    marginRight: 15,
  },
  dateBox: {
    padding: "16px 32px",
  },
  dialogContentRoot: {
    padding: "8px 0",
  },
  padd12x8: {
    padding: "12px 8px",
  },
  arrowCss: {
    padding: "0 8px",
    display: "inline-block",
  },
  bgColor: {
    background: "#FBFCFF",
    padding: "0 24px",
  },
  siteListContainer: {
    padding: 0,
    minWidth: 1530,
    overflow: "auto",
  },
  flexWrap:{
    display: "flex",
    flexWrap: "nowrap",
  },
  revenueRecovered:{
    display: "flex",
    flexWrap: "nowrap",
    marginLeft: "26px",
  },
  lossDetails:{
    minHeight: 64,
    borderBottom: "1px solid #F0F4FA",
    flexWrap: "nowrap",
  }
};

export default styles;
