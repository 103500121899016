import { applyMiddleware, createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";

import { composeWithDevTools } from "redux-devtools-extension";
import logger from "redux-logger";
import rootReducer from "../reducers";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

const middleware = [thunk];

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["product", "snackbar"],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  persistedReducer,
  {},
  composeWithDevTools(applyMiddleware(logger, ...middleware))
);
export const persistor = persistStore(store);
