import {
  Box,
  Container,
  Divider,
  Grid,
  Hidden,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { Trans, withTranslation } from "react-i18next";

import CloseIcon from "@material-ui/icons/Close";
import Config from "../../config";
import CustomButton from "../common/customStyle/Button";
import FileUpload from "../common/customStyle/fileUpload";
import React from "react";
import { allRequestHandler } from "../../api";
import { connect } from "react-redux";
import fileInput from "../../assets/img/fileInput.svg";
import styles from "./defectMap.style";
import { withStyles } from "@material-ui/core/styles";

const requestUrl = Config.hostName;

//To handle proof upload
class ProofUpload extends React.Component {
  state = {
    // submit: null,
    // showInputField: false,
    checked: true,
    updateResponse: { aws_params: [] },
    proofText: null,
  };

  //Handles the input fields
  handleInput = (e, field) => {
    this.setState({ [field]: e.target.value });
  };

  //Update data to the server
  setData = (value) => {
    this.setState((prevState) => {
      return {
        updateResponse: {
          aws_params: [...prevState.updateResponse.aws_params, ...value],
        },
      };
    });
  };

  // setInput = (value) => {
  // this.setState({
  //   showInputField: value,
  // });
  // };

  //Set the toggle to checked
  setChecked = () => {
    this.setState((prevState) => {
      return { checked: !prevState.checked };
    });
  };

  //Handle delete proof
  delete = (k) => {
    const getResp = this.state.updateResponse;
    const deleted = {
      aws_params: getResp.aws_params.filter((items) => {
        return items.attachment_id !== k;
      }),
    };
    this.setState({
      updateResponse: deleted,
    });
  };

  //Handles upload proof
  uploadProof = async () => {
    const getAttachId = [];
    this.state.updateResponse &&
      this.state.updateResponse.aws_params.length &&
      this.state.updateResponse.aws_params.map((getId) => {
        return getAttachId.push(getId.attachment_id);
      });
    try {
      const uploadData = await allRequestHandler({
        requestType: "PATCH",
        requestUrl: `${requestUrl}/api/defects/${this.props.defectId}/status/falsepositive`,
        requestData: {
          text: this.state.proofText,
          attachment_list: getAttachId,
        },
      });

      if (uploadData === "") {
        this.props.updateListLocally(this.props.defectId, "falsepositive");
        this.props.closeProofDialog();
      }
    } catch {
      console.log("Error in uploading");
    }
  };

  //Renders the proof upload dialog
  render() {
    const { classes } = this.props;
    const { t } = this.props;

    return (
      <Grid container className={classes.popupContainer} direction="column">
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Grid
            container
            justifyContent="space-between"
            className={classes.proofUploadPopup}
            direction="column"
          >
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              className={classes.paddAlign}
            >
              <Typography
                className={classes.popUpTitle}
                style={{ marginLeft: "32px" }}
              >
                {" "}
                <Trans>MARK AS FALSE +VE</Trans>{" "}
              </Typography>
              <CloseIcon
                fontSize="large"
                className={classes.closeIcon}
                style={{ marginRight: "16px" }}
                onClick={() => {
                  return this.props.closeProofDialog();
                }}
              />
            </Grid>
            <Divider />
            <Grid container style={{ padding: "1rem 0" }}>
              <Grid item xs={12} md={12} style={{ padding: "10px 30px" }}>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "rgba(205, 199, 254, 0.3)",
                    borderRadius: "8px",
                    paddingTop: "8px",
                    paddingBottom: "8px",
                  }}
                >
                  <Hidden only={["xs", "sm"]}>
                    <svg
                      style={{ marginLeft: 8 }}
                      width="28"
                      height="28"
                      viewBox="0 0 13 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12.5 6C12.5 9.31371 9.81371 12 6.5 12C3.18629 12 0.5 9.31371 0.5 6C0.5 2.68629 3.18629 0 6.5 0C9.81371 0 12.5 2.68629 12.5 6ZM5.68799 3.84741C5.68799 4.29601 6.05165 4.65967 6.50024 4.65967C6.94884 4.65967 7.3125 4.29601 7.3125 3.84741C7.3125 3.39882 6.94884 3.03516 6.50024 3.03516C6.05165 3.03516 5.68799 3.39882 5.68799 3.84741ZM5.68799 8.72094C5.68799 9.16954 6.05165 9.5332 6.50024 9.5332C6.94884 9.5332 7.3125 9.16954 7.3125 8.72094V6.28418C7.3125 5.83558 6.94884 5.47192 6.50024 5.47192C6.05165 5.47192 5.68799 5.83558 5.68799 6.28418V8.72094Z"
                        fill="#5B4DD3"
                      />
                    </svg>
                  </Hidden>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      style={{ fontSize: 11, marginLeft: 8, marginRight: 8 }}
                    >
                      <Trans>
                        Marking anomaly False +ve will be verified by Zeitview
                        thermal experts to help validate the fault. Upload a
                        supporting document or image for verification.
                      </Trans>
                    </Typography>
                  </div>
                </Box>
                <Box>
                  <Typography style={{ color: "#536471", marginTop: "20px" }}>
                    <Trans>Comment</Trans>
                  </Typography>
                  <TextField
                    style={{ marginTop: "0px" }}
                    value={this.state.proofText ? this.state.proofText : ""}
                    multiline
                    variant="outlined"
                    margin="normal"
                    placeholder={t("Write your comment...")}
                    fullWidth
                    onChange={(e) => {
                      this.handleInput(e, "proofText");
                    }}
                  />
                </Box>
                {this.state.updateResponse &&
                this.state.updateResponse.aws_params ? (
                  <Box style={{ minHeight: 110 }}>
                    <Typography
                      variant="h6"
                      style={{ marginTop: "1rem" }}
                      className={classes.titleText}
                    >
                      File:{" "}
                    </Typography>

                    {this.state.updateResponse &&
                    this.state.updateResponse.aws_params.length ? (
                      this.state.updateResponse.aws_params.map((items) => {
                        return (
                          <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                            className={classes.fileInputField}
                            key={items.attachment_id}
                          >
                            <Box
                              component="div"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <IconButton disabled>
                                <img src={fileInput} alt="fileInput" />
                              </IconButton>
                              <Typography className={classes.titleText}>
                                <Trans>{items.file_name}</Trans>
                              </Typography>
                            </Box>
                            <Typography className={classes.titleText}>
                              <label
                                htmlFor="Cancel-id"
                                component="span"
                                className={`${classes.selectLink} ${classes.titleText}`}
                                onClick={() => {
                                  return this.delete(items.attachment_id);
                                }}
                                aria-hidden="true"
                              >
                                <Trans>Cancel File</Trans>
                              </label>
                            </Typography>
                          </Grid>
                        );
                      })
                    ) : (
                      <Typography className={classes.titleText}>
                        {" "}
                        <Trans>No File added</Trans>{" "}
                      </Typography>
                    )}

                    <FileUpload
                      setData={this.setData}
                      // setInput={this.setInput}
                    />
                  </Box>
                ) : (
                  <FileUpload
                    url={this.state.getUrl}
                    file={this.state.file}
                    listArray={this.state.listArray}
                  />
                )}
              </Grid>
            </Grid>

            <Divider className={classes.marTB20} />
            <Grid
              container
              className={classes.paddAlign}
              style={{ justifyContent: "flex-end", paddingRight: "30px" }}
            >
              {!this.props.isImportingAnomaly && (
                <CustomButton
                  variant="outlined"
                  onClick={() => {
                    return this.props.closeProofDialog();
                  }}
                  text="Cancel"
                  type="cancel"
                  id={"gtm_cancelProofButtonFalse"}
                />
              )}
              <CustomButton
                onClick={() => {
                  return this.uploadProof();
                }}
                disabled={this.props.isImportingAnomaly}
                style={{
                  background: `${
                    this.state.proofText &&
                    this.state.updateResponse.aws_params.length
                      ? "#5B4DD3"
                      : "linear-gradient(266.69deg, #BEC5D2 0.23%, #BEC5D2 99.28%)"
                  }`,
                  color: `${
                    this.props.isImportingAnomaly ? "#536471" : "white"
                  }`,
                  marginLeft: "8px",
                }}
                text={
                  this.props.isImportingAnomaly
                    ? "Importing..."
                    : "Upload & Continue"
                }
                id={"gtm_uploadProofButtonFalse"}
              />
            </Grid>
          </Grid>
        </Container>
      </Grid>
    );
  }
}

//Calling redux to get the product information (pro/basic)
const mapStateToProps = (state) => {
  return {
    product: state.product,
  };
};

export default connect(mapStateToProps)(
  withStyles(styles)(withTranslation()(ProofUpload))
);
