import {
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import CustomButton from "../common/customStyle/Button";
import React from "react";
import { Trans } from "react-i18next";
import UserUpdate from "./UserUpdate";
import moment from "moment";
import styles from "./user.style";
import { withStyles } from "@material-ui/core/styles";
import MultSiteAccess from "./multSiteAccess";

//To view user details
class ViewUserDetails extends React.Component {
  state = {
    confDialog: false,
  };

  //Toggles the confirmation dialog open or close
  handleDialogClose = () => {
    this.setState((prevState) => {
      return {
        confDialog: !prevState.confDialog,
      };
    });
  };

  //Renders the view user details component
  render() {
    const { classes, userData, index } = this.props;
    const roleType = sessionStorage.getItem("userRole");

    return (
      <>
        <Grid container alignItems="center" style={{ height: 70 }}>
          <Grid item xs>
            <Grid container>
              <Avatar className={classes.avatarName}>
                {userData.first_name[0]}
                {userData.last_name[0]}
              </Avatar>
              <Typography className={classes.title}>
                {userData.first_name} {userData.last_name}
              </Typography>
            </Grid>
          </Grid>
          <Grid item style={{ paddingRight: 25 }}>
            <CloseIcon
              onClick={this.props.handleViewDrawerClose}
              style={{ cursor: "pointer" }}
            />
          </Grid>
        </Grid>
        <Divider />

        <Grid container direction="column" style={{ padding: "15px 30px" }}>
          <Typography className={classes.subHeader}>
            <Trans>User details</Trans>
          </Typography>
          <Grid container style={{ marginTop: 6 }}>
            <Grid item xs={3} className={classes.headerTxt}>
              <Trans>Id</Trans>
            </Grid>
            <Grid item xs className={classes.userValue}>
              {" "}
              {index}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={3} className={classes.headerTxt}>
              <Trans>Status</Trans>
            </Grid>
            <Grid item xs className={classes.userValue}>
              {" "}
              {userData.active ? (
                <div className={classes.success}>
                  <Trans>Active</Trans>
                </div>
              ) : (
                <div className={classes.danger}>
                  <Trans>Inactive</Trans>
                </div>
              )}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={3} className={classes.headerTxt}>
              <Trans>Name</Trans>
            </Grid>
            <Grid item xs className={classes.userValue}>
              {" "}
              {userData.first_name} {userData.last_name}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={3} className={classes.headerTxt}>
              <Trans>Email</Trans>
            </Grid>
            <Grid item xs className={classes.userValue}>
              {" "}
              {userData.email_id}{" "}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={3} className={classes.headerTxt}>
              <Trans>Job Title</Trans>
            </Grid>
            <Grid item xs className={classes.userValue}>
              {" "}
              {userData.job_title}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={3} className={classes.headerTxt}>
              <Trans>Date Created</Trans>
            </Grid>
            <Grid item xs className={classes.userValue}>
              {" "}
              {moment(new Date(userData.created_on)).format("DD MMM YYYY")}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={3} className={classes.headerTxt}>
              <Trans>Last Login</Trans>
            </Grid>
            <Grid item xs className={classes.userValue}>
              {" "}
              {userData.last_sign_in ? (
                <div>
                  {moment(userData.last_sign_in, "YYYYMMDD").fromNow()}{" "}
                </div>
              ) : (
                <div>-</div>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Divider />

        <Grid container direction="column" style={{ padding: "15px 30px" }}>
          <Typography className={classes.subHeader}>
            <Trans>Site Access</Trans>
          </Typography>

          {/* updated view option UI */}
          <MultSiteAccess
            sitesaccess={this.props.userData.site_access}
            isViewOption={true}
          />
        </Grid>

        <Divider />

        {/* site admin cannot edit and delete general admin. */}
        {roleType === "site:admin" &&
        this.props.userData.role_type === "CLIENT_ADMIN" ? (
          ""
        ) : (
          <Grid container style={{ padding: "14px 30px" }}>
            <UserUpdate
              plantList={this.props.plantList}
              usersList={this.props.usersList}
              data={this.props.userData}
              fromDrawer
            />
            <CustomButton
              onClick={() => {
                return this.handleDialogClose();
              }}
              type="cancel"
              text={<Trans>Delete User</Trans>}
            />
          </Grid>
        )}

        {/* Confirmation dialog for delete user */}
        <Dialog
          open={this.state.confDialog}
          onClose={() => {
            return this.handleDialogClose();
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <Trans>Delete User</Trans>
          </DialogTitle>
          <DialogContent style={{ minWidth: 400 }}>
            <Trans>Are you sure want to delete user ?</Trans>
          </DialogContent>
          <DialogActions>
            <CustomButton
              onClick={() => {
                return this.props.deleteUser(userData.user_id);
              }}
              text={<Trans>Delete User</Trans>}
            />
            <CustomButton
              onClick={() => {
                return this.handleDialogClose();
              }}
              type="cancel"
              text={<Trans>Cancel</Trans>}
            />
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default withStyles(styles)(ViewUserDetails);
