import {
  Button,
  Grid,
  Input,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
} from "@material-ui/core";

import React from "react";
import { Trans } from "react-i18next";
import { URLS } from "../../constants";
import { allRequestHandler } from "../../api";
import { connect } from "react-redux";
import i18n from "i18next";
import styles from "./settings.style";

//rename defects
class RenameDefects extends React.Component {
  state = {
    allData: this.props.allDefects,
    selectedData: null,
    editDefName: null,
  };

  //These functions are called when the component updates
  componentDidUpdate(prevProps) {
    //Update the defects state when the props are updated
    if (prevProps.allDefects !== this.props.allDefects)
      this.setState({ allData: this.props.allDefects });
  }

  //Handles the toggle switch
  handleSwitch = (index) => {
    this.setState((prevState) => {
      return {
        [index]: !prevState[index],
      };
    });
  };

  //Handles the change in input fields (defect name)
  handleInput = (e, val) => {
    const filterObj = val;
    filterObj.custom_defect_name = this.state.editDefName;

    this.setState({ selectedData: filterObj, editDefName: e.target.value });
  };

  //Update the defects name
  updateDefects = async () => {
    if (this.state.selectedData) {
      await allRequestHandler({
        requestType: "PUT",
        requestUrl: URLS.UPDATE_DEFECT_NAME,
        requestData: {
          custom_defect_list: [
            {
              custom_defect_name: this.state.selectedData.custom_defect_name,
              defect_type_id: this.state.selectedData.defect_type_id,
            },
          ],
        },
      });
      this.props.notifyMsg("info", "Defect name changed.");

      this.setState({ editedValue: null, selectedData: null });
    }
  };

  //Renders the tab to change the name of defects
  render() {
    const { classes } = this.props;

    return (
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell
                className={classes.tableCell}
                style={{ borderRight: "1px solid #E4EAF5", width: "50%" }}
              >
                {" "}
                <Trans>Default Name</Trans>
              </TableCell>
              <TableCell className={classes.tableCell} style={{ width: "50%" }}>
                {" "}
                <Trans>Rename Anomalies</Trans>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.allData &&
              this.state.allData.map((def, index) => {
                return (
                  <TableRow hover key={def.defect_type_id}>
                    <TableCell
                      className={classes.bodyText}
                      style={{ borderRight: "1px solid #E4EAF5" }}
                    >
                      {i18n.t(def.default_defect_name)}
                    </TableCell>
                    <TableCell className={classes.bodyText}>
                      <Grid container alignItems="center">
                        <div
                          style={{ minWidth: 40, marginRight: 20 }}
                          data-intercom-target="toggle-rename-anomalies"
                        >
                          {!def.custom_defect_name && (
                            <Switch
                              onChange={() => {
                                return this.handleSwitch(index);
                              }}
                              size="small"
                              color="primary"
                              style={{ color: "#FFFFFF" }}
                              name={def.default_defect_name}
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                          )}
                        </div>

                        {(def.custom_defect_name || this.state[index]) && (
                          <>
                            <Grid item xs>
                              <Input
                                defaultValue={
                                  def.custom_defect_name
                                    ? def.custom_defect_name
                                    : i18n.t(def.default_defect_name)
                                }
                                classes={{ root: classes.defTxt }}
                                onBlur={(e) => {
                                  return this.handleInput(e, def);
                                }}
                                onChange={(e) => {
                                  return this.setState({
                                    editDefName: e.target.value,
                                  });
                                }}
                                fullWidth
                              />
                            </Grid>

                            <Button
                              className={classes.saveBtn}
                              onClick={() => {
                                return this.updateDefects();
                              }}
                            >
                              {" "}
                              <Trans>Save</Trans>
                            </Button>
                          </>
                        )}
                      </Grid>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

//Calling redux to get the selected language
const mapStateToProps = (state) => {
  return {
    language: state.language.selectedLanguage,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(RenameDefects));
