import { Grid, Typography } from "@material-ui/core";
import { Trans, withTranslation } from "react-i18next";

import AnomalyStatus from "../common/anomalyStatus";
import CloseIcon from "@material-ui/icons/Close";
import CustomButton from "../common/customStyle/Button";
import CustomCheckbox from "../common/customStyle/CheckBox";
import CustomFilterButton from "../common/customStyle/FilterButton";
import CustomFilterChip from "../common/customStyle/FilterChip";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import React from "react";
import TemperatureRange from "../common/temperatureRange";
import falsepositiveImg from "../../assets/img/falsePositive.svg";
import i18n from "i18next";
import iec1 from "../../assets/img/iec1.svg";
import iec2 from "../../assets/img/iec2.svg";
import iec3 from "../../assets/img/iec3.svg";
import minorImg from "../../assets/img/Subtract.svg";
import notfoundImg from "../../assets/img/notfound.svg";
import pendingImg from "../../assets/img/pending.svg";
import progressImg from "../../assets/img/progress.svg";
import resolvedImg from "../../assets/img/resolved.svg";
import styles from "./defectMap.style";
import subtractImg from "../../assets/img/Moderate2.svg";
import uncategorized from "../../assets/img/uncategorized.svg";
import vectorImg from "../../assets/img/Vector.svg";
import { withStyles } from "@material-ui/core/styles";

//import Button from "@material-ui/core/Button";

//import { withStyles } from "@material-ui/core/styles";

//import CloseIcon from "@material-ui/icons/Close";
// import falsepositiveImg from "../../../assets/img/falsePositive.svg";
//import Divider from "@material-ui/core/Divider";

const titleStyles = (theme) => {
  return {
    root: {
      margin: 0,
      minHeight: 60,
      flexGrow: 1,
    },

    closeButton: {
      position: "absolute",
      width: "1.5em !important",
      height: "1.5em !important",
      right: "24px",
      top: "20px",
      color: theme.palette.grey[500],
    },

    dialogTitle: {
      position: "absolute",
      width: "58px",
      height: "20px",
      left: "30px",
      top: "20px",
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "18px",
      color: "#08001D",
    },
  };
};

const DialogTitle = withStyles(titleStyles)((props) => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography className={classes.dialogTitle} component={"div"}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          style={{ height: "1.5em", wifth: "1.5em" }}
          onClick={onClose}
          absolute="true"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(() => {
  return {
    root: {
      position: "relative",
    },
    // paper: {
    //   position: "relative",
    //   textAlign: "center",
    //   color: theme.palette.text.secondary,
    // },
  };
})(MuiDialogContent);

const DialogActions = withStyles((theme) => {
  return {
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  };
})(MuiDialogActions);

// const useStyles = makeStyles((theme) => ({
//   dialogPaper: {
//     height: "500px",
//   },
// }));

//Filter dialog
class FilterDrawer extends React.Component {
  state = {
    selectedSeverity: null,
    selectedStatus: [],
    tempRange: [],
    defectsName: null,
    is_commented: false,
    el_image: false,
    handheld_image: false,
    // thermal_image:false,
    iv_curve_data: false,
    tempChanged: false,
    selectedIEC: null,
    current: "anomalyName",
    defectSelectCount: 0,
    // filterCount: 0,
    hasCount: 0,
    // displayFilters: null,
    selectedLabel: null,
  };

  //These functions are called whenever the component updates
  componentDidUpdate(prevProps) {
    //Update filter object whenever filter parameters are changed
    if (prevProps.filterFaultType !== this.props.filterFaultType) {
      const filterDefect =
        this.props.filterFaultType &&
        this.props.filterFaultType.map((def) => {
          return {
            defect_type_name: def.defect_type_name,
            // defect_type_id: def.defect_type_id,
            color_code: def.color_code,
            checked: false,
          };
        });

      // skipcq Avoid usage of setState in componentDidUpdate JS-0443
      this.setState({
        defectsName: filterDefect,
        selectedStatus: [],
      });
    }

    //after filters updated on map, they will updated in filter popover as well
    if (this.props.removedDefect !== prevProps.removedDefect) {
      const updatedDefectsName =
        this.state.defectsName &&
        this.state.defectsName.map((defect) => {
          if (
            defect.defect_type_name ===
            this.props.removedDefect?.defect_type_name
          ) {
            return {
              defect_type_name: defect.defect_type_name,
              color_code: defect.color_code,
              checked: false,
            };
          }
          return defect;
        });

      if (updatedDefectsName) {
        // skipcq Avoid usage of setState in componentDidUpdate JS-0443
        this.setState((prevState) => {
          return {
            defectSelectCount: prevState.defectSelectCount - 1,
            defectsName: updatedDefectsName,
          };
        });
      }
    }

    //Clear filter if section update
    if (
      prevProps.selectedSection !== this.props.selectedSection ||
      (prevProps.displayFilters !== this.props.displayFilters &&
        !this.props.displayFilters)
    )
      this.clearFilter();

    // If temperature filter is removed
    if (
      this.props.displayFilters !== prevProps.displayFilters &&
      (!this.props.displayFilters || !this.props.displayFilters.tempValue) &&
      this.state.tempChanged
    ) {
      // skipcq Avoid usage of setState in componentDidUpdate JS-0443
      this.setState({ tempChanged: false });
    }
  }

  //Handles the temperature range sliders
  handleSlider = (event, value) => {
    if (value && value.length) {
      value[0] = Number(value[0]);
      value[1] = Number(value[1]);
    }
    if (value[0] === 0 && value[1] === 100)
      this.setState({ tempRange: value, tempChanged: false });
    else this.setState({ tempRange: value, tempChanged: true });
  };

  //Handles close or open of filter popover
  handleDrawer = () => {
    this.props.closeFilterDrawer();
  };

  //Handles the field selected
  fieldSelected = (value, field) => {
    //this.setState({ [field]: value });
    if (field === "selectedStatus") {
      let selectedStatus = [];
      if (this.state.selectedStatus !== null) {
        if (this.state.selectedStatus.includes(value)) {
          selectedStatus = this.state.selectedStatus.filter((data) => {
            return data !== value;
          });
        } else {
          selectedStatus = [...this.state.selectedStatus, value];
          // selectedStatus.push(value);
        }
      } else {
        selectedStatus = [value];
      }
      this.setState({ selectedStatus });
    } else {
      this.setState({ [field]: value });
    }
  };

  //Clear all the applied filters
  clearFilter = () => {
    // skipcq Avoid using this.state inside this.setState() JS-0435
    const defectsName = this.state.defectsName;
    defectsName &&
      defectsName.map((def) => {
        return def.checked ? (def.checked = false) : null;
      });

    this.setState({
      selectedSeverity: null,
      selectedStatus: null,
      selectedIEC: null,
      is_commented: false,
      defectsName,
      tempRange: [0, 100],
      tempChanged: false,
      defectSelectCount: 0,
      hasCount: 0,
      el_image: false,
      handheld_image: false,
      // thermal_image:false,
      iv_curve_data: false,
      // defectsFilter: [],
      selectedLabel: null,
    });
  };

  //Handles the toggle checkbox
  handleCheckbox = (event, value) => {
    // skipcq Avoid using this.state inside this.setState() JS-0435
    const filterDefect = this.state.defectsName;
    filterDefect.map((def) => {
      if (def.defect_type_name === value.defect_type_name) {
        def.checked
          ? this.setState((prevState) => {
              return {
                defectSelectCount: prevState.defectSelectCount - 1,
              };
            })
          : this.setState((prevState) => {
              return {
                defectSelectCount: prevState.defectSelectCount + 1,
              };
            });

        def.checked = !def.checked;
      }
      return false;
    });

    this.setState({
      defectsName: filterDefect,
    });
  };

  //handle removal of a selected filter
  handleRemoveDefect = (value, index) => {
    if (value === "severity") this.setState({ selectedSeverity: null });
    else if (value === "status") {
      // skipcq Avoid using this.state inside this.setState() JS-0435
      let deletedStatus = this.state.selectedStatus;
      deletedStatus = deletedStatus.filter((stat) => {
        return stat !== index;
      });
      this.setState({ selectedStatus: deletedStatus });
    } else if (value === "tempValue")
      this.setState({ tempRange: [0, 100], tempChanged: false });
    else if (value === "is_commented")
      this.setState((prevState) => {
        return {
          is_commented: false,
          hasCount: prevState.hasCount - 1,
        };
      });
    else if (value === "el_image")
      this.setState((prevState) => {
        return {
          el_image: false,
          hasCount: prevState.hasCount - 1,
        };
      });
    else if (value === "handheld_image")
      this.setState((prevState) => {
        return {
          handheld_image: false,
          hasCount: prevState.hasCount - 1,
        };
      });
    else if (value === "iv_curve_data")
      this.setState((prevState) => {
        return {
          iv_curve_data: false,
          hasCount: prevState.hasCount - 1,
        };
      });
    // else if (value === "thermal_image") this.setState({ thermal_image: false ,hasCount:this.state.hasCount-1});
    else if (value === "iec_category") this.setState({ selectedIEC: null });
    else if (value === "label") this.setState({ selectedLabel: null });
    else if (this.state.defectsName) {
      // skipcq Avoid using this.state inside this.setState() JS-0435
      const filterDefect = this.state.defectsName;
      filterDefect.map((def) => {
        if (def.defect_type_name === value.defect_type_name) {
          def.checked
            ? this.setState((prevState) => {
                return {
                  defectSelectCount: prevState.defectSelectCount - 1,
                };
              })
            : this.setState((prevState) => {
                return {
                  defectSelectCount: prevState.defectSelectCount + 1,
                };
              });

          def.checked = !def.checked;
        }
        return false;
      });

      this.setState({
        defectsName: filterDefect,
      });
    }
  };

  //Apply the filters and close the filter popover
  applyFilter = () => {
    const defaultTempValue = this.state.tempRange.length
      ? this.state.tempRange
      : [0, 100];
    const filterDefects =
      this.state.defectsName &&
      this.state.defectsName.length &&
      this.state.defectsName.filter((def) => {
        return def.checked;
      });

    const displayFilters = {
      severity: this.state.selectedSeverity,
      status:
        this.state.selectedStatus && this.state.selectedStatus.length
          ? this.state.selectedStatus
          : null,
      iec_category: this.state.selectedIEC,
      label: this.state.selectedLabel,
      tempValue:
        defaultTempValue && this.state.tempChanged ? defaultTempValue : null,
      defectsFilter: filterDefects,
      is_commented: this.state.is_commented,
      el_image: this.state.el_image,
      handheld_image: this.state.handheld_image,
      iv_curve_data: this.state.iv_curve_data,
      // thermal_image:this.state.thermal_image
    };

    this.props.filterDefects(displayFilters);
  };

  //Handles "Anomaly Has" filter
  handleAnomalyHas = (e, value) => {
    let counter = this.state.hasCount;
    if (e.target.checked) {
      this.setState({
        hasCount: counter + 1,
      });
    } else {
      this.setState({
        hasCount: counter - 1,
      });
    }

    this.setState({
      [value]: e.target.checked,
    });
  };

  //Handles "Status" filter
  handleStatus = (status_selected) => {
    let selectedStatus = [];
    if (
      this.state.selectedStatus !== null &&
      this.state.selectedStatus.includes(status_selected)
    ) {
      selectedStatus = this.state.selectedStatus.filter((data) => {
        return data !== status_selected;
      });
    } else {
      selectedStatus = [...selectedStatus, status_selected];
    }
    this.setState({ selectedStatus });
  };

  //Add severity icon based on the severity value
  addSeverityIcon = (value) => {
    if (value === "Critical")
      return (
        <span>
          {" "}
          <img alt="sevImg" src={vectorImg} style={{ marginTop: -3 }} /> {value}{" "}
        </span>
      );
    else if (value === "Moderate")
      return (
        <span>
          {" "}
          <img alt="sevImg" src={subtractImg} style={{ marginTop: -3 }} />{" "}
          {value}{" "}
        </span>
      );

    return (
      <span>
        <img alt="sevImg" src={minorImg} /> {value}
      </span>
    );
  };

  //Add status icon based on the status value
  addStatusIcon = (value) => {
    if (value === "pending")
      return (
        <span>
          {" "}
          <img
            alt="statusImg"
            src={pendingImg}
            style={{ marginTop: -3 }}
          />{" "}
          Pending{" "}
        </span>
      );
    else if (value === "inprogress")
      return (
        <span>
          {" "}
          <img alt="statusImg" src={progressImg} style={{ marginTop: -3 }} /> In
          Progress{" "}
        </span>
      );
    else if (value === "resolved")
      return (
        <span>
          {" "}
          <img
            alt="statusImg"
            src={resolvedImg}
            style={{ marginTop: -3 }}
          />{" "}
          Resolved{" "}
        </span>
      );
    else if (value === "falsepositive")
      return (
        <span>
          {" "}
          <img
            alt="statusImg"
            src={falsepositiveImg}
            style={{ marginTop: -3 }}
          />{" "}
          False +Ve{" "}
        </span>
      );

    return (
      <span>
        {" "}
        <img alt="statusImg" src={notfoundImg} style={{ marginTop: -3 }} /> Not
        Found{" "}
      </span>
    );
  };

  //Add IEC icon based on the IEC value
  addIecIcon = (value) => {
    if (value === "IEC 1")
      return (
        <span>
          {" "}
          <img alt="iecImg" src={iec1} style={{ marginTop: -3 }} /> {value}{" "}
        </span>
      );
    else if (value === "IEC 2")
      return (
        <span>
          {" "}
          <img alt="iecImg" src={iec2} style={{ marginTop: -3 }} /> {value}{" "}
        </span>
      );
    else if (value === "IEC 3")
      return (
        <span>
          {" "}
          <img alt="iecImg" src={iec3} style={{ marginTop: -3 }} /> {value}{" "}
        </span>
      );
    else if (value === "Uncategorized")
      return (
        <span>
          {" "}
          <img
            alt="iecImg"
            src={uncategorized}
            style={{ marginTop: -3 }}
          />{" "}
          {value}{" "}
        </span>
      );

    return false;
  };

  selectStyle = {
    // grid style when selected
    display: "flex",
    width: "100%",
    color: "#08001D",
    background: "#FFFFFF",
    height: 70,
  };
  gridStyle = {
    //grid normal style
    display: "flex",
    width: "100%",
    color: "#536471",
    background: "#F0F4FA",
    height: 70,
    cursor: "pointer",
  };
  blueDiv = {
    height: "100%",
    width: "2px",
  };
  blueDivSelect = {
    height: "100%",
    width: "2px",
    background: "#3FC0C2",
  };
  selectedCount = {
    // count style
    fontFamily: "Montserrat",
    color: "#5B4DD3",
  };

  //Renders the filter dialog
  render() {
    const { classes } = this.props;
    const severityButtons = ["Critical", "Moderate", "Minor"];
    const iecButtons = ["IEC 1", "IEC 2", "IEC 3", "Uncategorized"];
    const labelButtons = ["NEW", "PERSISTENT", "TRANSITIONED", "RECURRING"];

    const numSelected =
      this.state.defectSelectCount +
      this.state.hasCount +
      (this.state.tempChanged ? 1 : 0) +
      (this.state.selectedIEC ? 1 : 0) +
      (this.state.selectedLabel ? 1 : 0) +
      (this.state.selectedSeverity ? 1 : 0) +
      (this.state.selectedStatus ? this.state.selectedStatus.length : null);

    return (
      <Dialog
        fullWidth={true}
        style={{ width: "100%" }}
        onClose={this.handleDrawer}
        aria-labelledby="customized-dialog-title"
        open={this.props.open}
        classes={{
          paper: classes.paperDialog,
        }}
      >
        <DialogTitle id="customized-dialog-title" onClose={this.handleDrawer}>
          <Typography className={classes.filtersName} component={"div"}>
            <Trans>Filters</Trans>
          </Typography>
        </DialogTitle>

        <DialogContent dividers>
          <Typography className={classes.chipStyle} component={"div"}>
            {numSelected} <Trans>Filters Selected</Trans>
          </Typography>
          <div className={classes.dialogChips}>
            {numSelected === 0 && (
              <div
                style={{
                  width: "100%",
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography className={classes.noSelectText} component={"div"}>
                  <Trans>Selected filters will show up here</Trans>
                </Typography>
              </div>
            )}

            {this.state.defectsName &&
              this.state.defectsName.map((f, index) => {
                return f.checked ? (
                  <Grid item key={f.defect_type_name}>
                    <CustomFilterChip
                      onDelete={() => {
                        return this.handleRemoveDefect(f, index);
                      }}
                      type="anomalies"
                      name={f.defect_type_name}
                      color={f.color_code}
                    />
                  </Grid>
                ) : null;
              })}

            {this.state.selectedSeverity && (
              <Grid item>
                <CustomFilterChip
                  onDelete={() => {
                    return this.handleRemoveDefect("severity");
                  }}
                  type="severity"
                  name={this.state.selectedSeverity}
                />
              </Grid>
            )}

            {this.state.selectedStatus &&
              this.state.selectedStatus.map((item) => {
                return (
                  <Grid item key={item}>
                    <CustomFilterChip
                      onDelete={() => {
                        return this.handleRemoveDefect("status", item);
                      }}
                      type="status"
                      name={item}
                    />
                  </Grid>
                );
              })}

            {this.state.selectedIEC && (
              <Grid item>
                <CustomFilterChip
                  onDelete={() => {
                    return this.handleRemoveDefect("iec_category");
                  }}
                  type="iec"
                  name={this.state.selectedIEC}
                />
              </Grid>
            )}

            {this.state.selectedLabel && (
              <Grid item>
                <CustomFilterChip
                  onDelete={() => {
                    return this.handleRemoveDefect("label");
                  }}
                  type="label"
                  name={this.state.selectedLabel}
                />
              </Grid>
            )}

            {this.state.tempRange.length &&
              !(
                this.state.tempRange[0] === 0 && this.state.tempRange[1] === 100
              ) && (
                <Grid item>
                  <CustomFilterChip
                    onDelete={() => {
                      return this.handleRemoveDefect("tempValue");
                    }}
                    type="temperature_range"
                    lowerTempValue={this.state.tempRange[0]}
                    higherTempValue={this.state.tempRange[1]}
                  />
                </Grid>
              )}

            {this.state.is_commented && (
              <Grid item>
                <CustomFilterChip
                  onDelete={() => {
                    return this.handleRemoveDefect("is_commented");
                  }}
                  type="images"
                  name="comments"
                />
              </Grid>
            )}
            {this.state.el_image && (
              <Grid item>
                <CustomFilterChip
                  onDelete={() => {
                    return this.handleRemoveDefect("el_image");
                  }}
                  type="images"
                  name="el_image"
                />
              </Grid>
            )}
            {this.state.handheld_image && (
              <Grid item>
                <CustomFilterChip
                  onDelete={() => {
                    return this.handleRemoveDefect("handheld_image");
                  }}
                  type="images"
                  name="handheld_image"
                />
              </Grid>
            )}
            {this.state.iv_curve_data && (
              <Grid item>
                <CustomFilterChip
                  onDelete={() => {
                    return this.handleRemoveDefect("iv_curve_data");
                  }}
                  type="images"
                  name="iv_curve_data"
                />
              </Grid>
            )}
          </div>
        </DialogContent>

        <DialogContent dividers className={classes.filterDialogContent}>
          <Grid
            container
            alignItems="flex-start"
            className={classes.filterSidebar}
          >
            <Grid
              item
              xs={12}
              onClick={() => {
                return this.setState({ current: "anomalyName" });
              }}
              style={
                this.state.current === "anomalyName"
                  ? this.selectStyle
                  : this.gridStyle
              }
              id={"gtm_filterAnomalyNameBox"}
            >
              <div
                style={
                  this.state.current === "anomalyName"
                    ? this.blueDivSelect
                    : this.blueDiv
                }
              />
              <Typography
                xs={4}
                varient="body1"
                className={classes.comStyle}
                component={"div"}
              >
                <Trans>Anomaly Name</Trans>
                {this.state.defectSelectCount ? (
                  <div style={this.selectedCount}>
                    {this.state.defectSelectCount} <Trans>Selected</Trans>
                  </div>
                ) : null}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              onClick={() => {
                return this.setState({ current: "severity" });
              }}
              style={
                this.state.current === "severity"
                  ? this.selectStyle
                  : this.gridStyle
              }
              id={"gtm_filterSeverityBox"}
            >
              <div
                style={
                  this.state.current === "severity"
                    ? this.blueDivSelect
                    : this.blueDiv
                }
              />
              <Typography
                xs={4}
                varient="body1"
                className={classes.comStyle}
                component={"div"}
              >
                <Trans>Severity</Trans>
                {this.state.selectedSeverity && (
                  <div style={this.selectedCount}>
                    1<Trans>Selected</Trans>
                  </div>
                )}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              onClick={() => {
                return this.setState({ current: "labels" });
              }}
              style={
                this.state.current === "labels"
                  ? this.selectStyle
                  : this.gridStyle
              }
              id={"gtm_filterLabelsBox"}
            >
              <div
                style={
                  this.state.current === "labels"
                    ? this.blueDivSelect
                    : this.blueDiv
                }
              />
              <Typography
                xs={4}
                varient="body1"
                className={classes.comStyle}
                component={"div"}
              >
                <Trans>Anomaly Category</Trans>
                {this.state.selectedLabel && (
                  <div style={this.selectedCount}>
                    1 <Trans>Selected</Trans>
                  </div>
                )}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              onClick={() => {
                return this.setState({ current: "status" });
              }}
              style={
                this.state.current === "status"
                  ? this.selectStyle
                  : this.gridStyle
              }
              id={"gtm_filterStatusBox"}
            >
              <div
                style={
                  this.state.current === "status"
                    ? this.blueDivSelect
                    : this.blueDiv
                }
              />
              <Typography
                xs={4}
                varient="body1"
                className={classes.comStyle}
                component={"div"}
              >
                <Trans>Status</Trans>
                {this.state.selectedStatus ? (
                  <div style={this.selectedCount}>
                    {this.state.selectedStatus.length
                      ? `${this.state.selectedStatus.length} Selected`
                      : null}
                  </div>
                ) : null}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              onClick={() => {
                return this.setState({ current: "iecs" });
              }}
              style={
                this.state.current === "iecs"
                  ? this.selectStyle
                  : this.gridStyle
              }
              id={"gtm_filterIecsBox"}
            >
              <div
                style={
                  this.state.current === "iecs"
                    ? this.blueDivSelect
                    : this.blueDiv
                }
              />
              <Typography
                xs={4}
                varient="body1"
                className={classes.comStyle}
                component={"div"}
              >
                <Trans>IEC CoA</Trans>
                {this.state.selectedIEC && (
                  <div style={this.selectedCount}>
                    1 <Trans>Selected</Trans>
                  </div>
                )}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              onClick={() => {
                return this.setState({ current: "anomaly has" });
              }}
              style={
                this.state.current === "anomaly has"
                  ? this.selectStyle
                  : this.gridStyle
              }
              id={"gtm_filterAnomalyHasBox"}
            >
              <div
                style={
                  this.state.current === "anomaly has"
                    ? this.blueDivSelect
                    : this.blueDiv
                }
              />
              <Typography
                xs={4}
                varient="body1"
                className={classes.comStyle}
                component={"div"}
              >
                <Trans>Anomaly Has</Trans>
                {this.state.hasCount ? (
                  <div style={this.selectedCount}>
                    {this.state.hasCount} <Trans>Selected</Trans>
                  </div>
                ) : null}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              onClick={() => {
                return this.setState({ current: "temperature" });
              }}
              style={
                this.state.current === "temperature"
                  ? this.selectStyle
                  : this.gridStyle
              }
              id={"gtm_filterTempBox"}
            >
              <div
                style={
                  this.state.current === "temperature"
                    ? this.blueDivSelect
                    : this.blueDiv
                }
              />

              <Typography
                varient="body1"
                className={classes.comStyle}
                component={"div"}
              >
                <Trans>Temperature Difference (∆T)</Trans>
                {this.state.tempChanged ? (
                  <div style={this.selectedCount}>
                    {" "}
                    <Trans>Selected</Trans>
                  </div>
                ) : null}
                {/* <div>&nbsp;</div> */}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            xs={7}
            item
            direction="column"
            justifyContent="space-between"
            alignItems="baseline"
            style={{ padding: "20px", width: "100%" }}
          >
            {
              /*Anomaly name filter*/
              this.state.current === "anomalyName" ? (
                <div>
                  <Grid item xs={12} md={12} className={classes.paddAlign}>
                    <Typography className={classes.subText} component={"div"}>
                      <Trans>Anomalies Name</Trans>
                    </Typography>
                    <Grid container>
                      {this.state.defectsName &&
                        this.state.defectsName.map((faultData, index) => {
                          return (
                            <Grid item xs={12} key={faultData.defect_type_name}>
                              <CustomCheckbox
                                checked={
                                  this.state.defectsName
                                    ? this.state.defectsName[index].checked
                                    : false
                                }
                                onChange={(e) => {
                                  return this.handleCheckbox(e, faultData);
                                }}
                                value={
                                  this.state.defectsName &&
                                  this.state.defectsName[index].checked
                                }
                                id={"gtm_filterAnomalyName"}
                                label={faultData.defect_type_name}
                              />
                            </Grid>
                          );
                        })}
                    </Grid>
                  </Grid>
                </div>
              ) : this.state.current === "severity" ? (
                /*Severity filter*/
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography className={classes.subText} component={"div"}>
                    <Trans>Severity</Trans>
                  </Typography>
                  {severityButtons.map((btnValue) => {
                    return (
                      <CustomFilterButton
                        onClick={() => {
                          this.fieldSelected(btnValue, "selectedSeverity");
                        }}
                        selected={this.state.selectedSeverity === btnValue}
                        name={btnValue}
                        type="severity"
                        gtmId={`gtm_filterSev${btnValue}`}
                        key={btnValue}
                      />
                    );
                  })}
                </div>
              ) : this.state.current === "labels" ? (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography className={classes.subText} component={"div"}>
                    Labels
                  </Typography>
                  {labelButtons.map((labelValue) => {
                    return (
                      <Grid key={labelValue}>
                        <CustomFilterButton
                          onClick={() => {
                            this.fieldSelected(labelValue, "selectedLabel");
                          }}
                          selected={this.state.selectedLabel === labelValue}
                          name={labelValue}
                          type="label"
                          gtmId={`gtm_filterLabel${labelValue}`}
                          key={labelValue}
                        />
                      </Grid>
                    );
                  })}
                </div>
              ) : this.state.current === "status" ? (
                /* status Filter*/
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    component={"div"}
                    className={classes.subText}
                    style={{ marginLeft: "5px" }}
                  >
                    <Trans>Status</Trans>
                  </Typography>
                  <AnomalyStatus
                    statusType="pending"
                    statusLabel="Pending"
                    statusView="buttonView"
                    handleStatus={(v) => {
                      return this.fieldSelected(v, "selectedStatus");
                    }}
                    status={this.state.selectedStatus}
                    gtmId="gtm_FilterSevPending"
                  />
                  <AnomalyStatus
                    statusType="notfound"
                    statusView="buttonView"
                    statusLabel="Not Found"
                    handleStatus={(v) => {
                      return this.fieldSelected(v, "selectedStatus");
                    }}
                    status={this.state.selectedStatus}
                    gtmId="gtm_FilterSevNotfound"
                  />
                  <AnomalyStatus
                    statusType="inprogress"
                    statusView="buttonView"
                    statusLabel="In Progress"
                    handleStatus={(v) => {
                      return this.fieldSelected(v, "selectedStatus");
                    }}
                    status={this.state.selectedStatus}
                    gtmId="gtm_FilterSevInprogress"
                  />
                  <AnomalyStatus
                    statusType="falsepositive"
                    statusLabel="False +ve"
                    statusView="buttonView"
                    handleStatus={(v) => {
                      return this.fieldSelected(v, "selectedStatus");
                    }}
                    status={this.state.selectedStatus}
                    gtmId="gtm_FilterSevFalse"
                  />
                  <AnomalyStatus
                    // handleStatus={this.handleStatus}
                    statusType="resolved"
                    statusLabel="Resolved"
                    statusView="buttonView"
                    handleStatus={(v) => {
                      return this.fieldSelected(v, "selectedStatus");
                    }}
                    status={this.state.selectedStatus}
                    gtmId="gtm_FilterSevResolved"
                  />
                </div>
              ) : this.state.current === "iecs" ? (
                /*IEC filter*/
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography className={classes.subText} component={"div"}>
                    IEC CoA
                  </Typography>
                  {iecButtons.map((iecValue) => {
                    return (
                      <Grid key={iecValue}>
                        <CustomFilterButton
                          onClick={() => {
                            this.fieldSelected(iecValue, "selectedIEC");
                          }}
                          selected={this.state.selectedIEC === iecValue}
                          name={iecValue}
                          type="iec"
                          gtmId={`gtm_filterIec${iecValue}`}
                          key={iecValue}
                        />
                      </Grid>
                    );
                  })}
                </div>
              ) : this.state.current === "anomaly has" ? (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography className={classes.subText} component={"div"}>
                    <Trans>Anomaly Has</Trans>
                  </Typography>
                  <Grid container alignItems="center">
                    <CustomCheckbox
                      value={this.state.is_commented}
                      checked={this.state.is_commented}
                      onChange={(e) => {
                        return this.handleAnomalyHas(e, "is_commented");
                      }}
                      label={"Comments"}
                      id={"gtm_filterComment"}
                    />
                  </Grid>
                  <Grid container alignItems="center">
                    <CustomCheckbox
                      value={this.state.el_image}
                      checked={this.state.el_image}
                      onChange={(e) => {
                        return this.handleAnomalyHas(e, "el_image");
                      }}
                      label={"EL Image"}
                      id={"gtm_filterElimage"}
                    />
                  </Grid>
                  <Grid container alignItems="center">
                    <CustomCheckbox
                      checked={this.state.iv_curve_data}
                      onChange={(e) => {
                        return this.handleAnomalyHas(e, "iv_curve_data");
                      }}
                      value={this.state.iv_curve_data}
                      label={"IV Curve Image"}
                      id={"gtm_filterIvdata"}
                    />
                  </Grid>
                  <Grid container alignItems="center">
                    <CustomCheckbox
                      checked={this.state.handheld_image}
                      onChange={(e) => {
                        return this.handleAnomalyHas(e, "handheld_image");
                      }}
                      value={this.state.handheld_image}
                      label={i18n.t("Handheld Image")}
                      id={"gtm_filterHandheld"}
                    />
                  </Grid>
                </div>
              ) : this.state.current === "temperature" ? (
                /* Temperature range filter */
                <TemperatureRange
                  tempRange={this.state.tempRange}
                  onHandleSlider={this.handleSlider}
                  showInstruction
                />
              ) : null
            }
          </Grid>
        </DialogContent>

        <DialogActions className={classes.dialogActions}>
          <CustomButton
            variant="outlined"
            onClick={() => {
              this.clearFilter();
            }}
            text="Clear"
            type="cancel"
            id={"gtm_clearFilter"}
          />
          <CustomButton
            onClick={() => {
              this.applyFilter();
            }}
            text="Apply Filters"
            id={"gtm_applyFilter"}
          />
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(withTranslation()(FilterDrawer));
