import { Grid, Typography, withStyles } from "@material-ui/core";
import React, { Component } from "react";

import BarcodePicker from "./barcodeScanner/index";
import CloseIcon from "@material-ui/icons/Close";
import CustomButton from "../common/customStyle/Button";
import CustomTextField from "../common/customStyle/TextField";
import EmailDialog from "./EmailDialog";
import NewLoader from "../../layout/newLoader";
import ProLabel from "../common/ProLabel";
import { ScanSettings } from "scandit-sdk";
import { Trans } from "react-i18next";
import { URLS } from "../../constants";
import _ from "lodash";
import { allRequestHandler } from "../../api";
import barcodeSvg from "../../assets/img/barcode.svg";
import { callSnackbarAction } from "../../reducers/snackbar";
import { connect } from "react-redux";
import i18n from "i18next";
import shortid from "shortid";
import { store } from "../../store";
import styles from "./defectMap.style";

//panel details page
class PanelDetails extends Component {
  //Initialize state
  constructor(props) {
    super(props);
    this.state = {
      defect_max_power_current: null,
      defect_max_power_voltage: null,
      // defect_module_power: null,
      defect_panel_current: null,
      // defect_panel_temperature: null,
      defect_panel_voltage: null,
      string_current: null,
      healthy_panel_current: null,
      // healthy_panel_temperature: null,
      healthy_max_power_current: null,
      healthy_max_power_voltage: null,
      // healthy_module_power: null,
      healthy_panel_voltage: null,
      panel_reference: null,
      string_reference: null,
      panel_manufacturer: null,
      prevDetails: null,
      loading: false,
      isEditPanel: false,
      showBarcodePopUp: false,
      openFilter: false,
    };

    this._onDetected = this._onDetected.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  //Check if the input type should be text or number
  handleType = (type) => {
    if (
      type === "Module Serial Number" ||
      type === "String ID" ||
      type === "Manufacturer Name" ||
      type === "Serial Number"
    )
      return "text";

    return "number";
  };

  async componentDidMount() {
    //Get panel details on the initial render
    (await this.props.selectedDefect) && this.getPanelDetails();
  }

  async componentDidUpdate(prevProps) {
    //Get panel details of the selected defect when the selected defect changes

    if (this.props.selectedDefect !== prevProps.selectedDefect)
      await this.getPanelDetails();
  }

  //API call to get panel details
  getPanelDetails = async () => {
    this.setState({ loading: true });
    let res = {};
    // let panelUrl =
    //   this.props.href_list &&
    //   this.props.href_list.length &&
    //   this.props.href_list.filter((url) => {
    //     return url.panel_details;
    //   })[0];

    // if (panelUrl) {
    if (!this.props.isDigitized) {
      res = await allRequestHandler({
        requestType: "GET",
        requestUrl: `${
          URLS.ANOMALY_DETAILS
        }${this.props.selectedDefect.defect_id.toString()}/panel_details`,
      });
    } else {
      res = await allRequestHandler({
        requestType: "GET",
        requestUrl: `${
          URLS.ANOMALY_DETAILS
        }${this.props.selectedDefect.defect_id.toString()}/panels/${this.props.selectedDefect.panel_id.toString()}/panel_details`,
      });
    }
    if (res) {
      this.setState({ ...res, prevDetails: { ...res }, loading: false });
    }
    console.log("***res", res);
    // }
  };

  //Verify the input fields and call the API to update the panel details if valid
  handleUpdateDetails = async () => {
    try {
      let data = this.state;
      const prevDetails = data.prevDetails;

      data = _.omit(data, "prevDetails");
      data = _.omit(data, "loading");
      data = _.omit(data, "isEditPanel");
      data = _.omit(data, "showBarcodePopUp");
      data = _.omit(data, "scanSuccess");
      if (!_.isEqual(data, prevDetails)) {
        // Remove extra state variable
        delete data.openFilter;
        let res = {};

        if (!this.props.isDigitized) {
          res = await allRequestHandler({
            requestType: "PUT",
            requestUrl: `${
              URLS.ANOMALY_DETAILS
            }${this.props.selectedDefect.defect_id.toString()}/panel_details`,
            requestData: data,
          });
        } else {
          res = await allRequestHandler({
            requestType: "PUT",
            requestUrl: `${
              URLS.ANOMALY_DETAILS
            }${this.props.selectedDefect.defect_id.toString()}/panels/${this.props.selectedDefect.panel_id.toString()}/panel_details`,
            requestData: data,
          });
        }
        if (res) {
          const dataToUpdate = { ...this.props.selectedDefect };
          dataToUpdate.string_reference = res.string_reference;
          this.props.updateSelectedDefect(dataToUpdate);
          this.setState({ prevDetails: { ...data } });
          store.dispatch(
            callSnackbarAction("Panel details updated.", "success")
          );
          this.setState({ isEditPanel: false });
        }
      } else {
        this.setState({ isEditPanel: false });
      }
    } catch (error) {
      this.props.callSnackbar(
        "Please write valid Details. Values should be within the limit of 0-999",
        "warning"
      );
    }
  };

  //Undo the changes made to the panel details
  handleDiscardDetails = () => {
    this.setState((prevState) => {
      return {
        isEditPanel: false,
        defect_module_power: prevState.prevDetails.defect_module_power,
        defect_panel_current: prevState.prevDetails.defect_panel_current,
        defect_panel_voltage: prevState.prevDetails.defect_panel_voltage,
        healthy_panel_current: prevState.prevDetails.healthy_panel_current,
        healthy_module_power: prevState.prevDetails.healthy_module_power,
        string_current: prevState.prevDetails.string_current,
        healthy_panel_voltage: prevState.prevDetails.healthy_panel_voltage,
        panel_reference: prevState.prevDetails.panel_reference,
        string_reference: prevState.prevDetails.string_reference,
        panel_manufacturer: prevState.prevDetails.panel_manufacturer,
      };
    });
  };

  //Close the edit panel details dialog
  handleClose = () => {
    this.setState((prevState) => {
      return {
        showBarcodePopUp: !prevState.showBarcodePopUp,
      };
    });
  };

  //handle detected scan
  _onDetected(result) {
    this.setState({
      showBarcodePopUp: false,
      scanCode: result ? result.codeResult.code : "",
      scanSuccess: result ? true : false,
      results: result,
      panel_reference: result ? result.codeResult.code : "",
    });
  }

  handleEmail = () => {
    this.setState((prevState) => {
      return {
        openFilter: !prevState.openFilter,
      };
    });
  };

  //Render the panel details tab
  render() {
    const fields = {
      "Defective Panel": {
        // panel_reference: "Serial Number",
        // panel_manufacturer: "Manufacturer Name",
        string_reference: "String ID",
        // defect_panel_voltage: "Panel Open Circuit Voltage(Voc)",
        defect_module_power: "Module Power (Wp)",
        defect_panel_voltage: "Open Circuit Voltage (Voc)",
        defect_panel_current: "Short Circuit Current (Isc)",
        string_current: "String Current (I Str)",
        // defect_max_power_voltage: "Module Vmp",
        // defect_max_power_current: "Module Imp",
      },
      "Healthy Panel": {
        healthy_module_power: "Module Power (Wp)",
        healthy_panel_voltage: "Open Circuit Voltage (Voc)",
        healthy_panel_current: "Short Circuit Current (Isc)",
        // healthy_max_power_voltage: "Ref Module Vmp",
        // healthy_max_power_current: "Ref Module Imp",
        // healthy_panel_voltage: "Panel Short Circuit Voltage(Vsc)",
      },
    };

    const editFields = {
      panel_reference: { label: "Serial Number", unit: "" },
      panel_manufacturer: { label: "Manufacturer Name", unit: "" },
      heading1: "Defective Panel",
      string_reference: { label: "String ID", unit: "" },
      defect_module_power: { label: i18n.t("Module Power (Wp)"), unit: "W" },
      defect_panel_voltage: { label: "Open Circuit Voltage (Voc)", unit: "V" },
      defect_panel_current: { label: "Short Circuit Current (Isc)", unit: "A" },
      string_current: { label: "String Current (I Str)", unit: "A" },
      // defect_max_power_voltage: { label: "Module Vmp", unit: "V" },
      // defect_max_power_current: { label: "Module Imp", unit: "A" },
      heading2: "Healthy Panel",
      healthy_module_power: { label: "Module Power (Wp)", unit: "W" },
      healthy_panel_voltage: { label: "Open Circuit Voltage (Voc)", unit: "V" },
      healthy_panel_current: {
        label: "Short Circuit Current (Isc)",
        unit: "A",
      },
      // healthy_max_power_voltage: { label: "Ref Module Vmp", unit: "V" },
      // healthy_max_power_current: { label: "Ref Module Imp", unit: "A" },
    };

    const { classes } = this.props;

    return this.state.loading ? (
      
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100%" }}
      >
        <NewLoader />
      </Grid>
    ) : this.state.isEditPanel ? (
      <div
        style={{
          overflow: "auto",
          margin: "0",
        }}
      >
        {/***************Barcode Scanner*********/}

        {this.state.showBarcodePopUp ? (
          <Grid
            container
            direction="column"
            wrap="nowrap"
            style={{ backgroundColor: "#F3F5FA" }}
            className={classes.barcodeBackDrop}
          >
            <div>
              <div className={classes.barcodeModalContent}>
                <CloseIcon
                  fontSize="large"
                  onClick={this.handleClose}
                  className={classes.barcodeCloseIcon}
                />

                <Grid>
                  <BarcodePicker
                    playSoundOnScan
                    vibrateOnScan
                    scanSettings={
                      new ScanSettings({
                        enabledSymbologies: ["code128"],
                        codeDuplicateFilter: 1000,
                      })
                    }
                    onScan={(scanResult) => {
                      this.setState({
                        showBarcodePopUp: false,
                        panel_reference: scanResult.barcodes.reduce(
                          (barcode) => {
                            return barcode.data;
                          },
                          ""
                        ),
                      });
                    }}
                    // onError={(error) => {}}
                  />
                </Grid>
              </div>
            </div>
          </Grid>
        ) : (
          ""
        )}
        {/*********Barcode Scanner End********/}

        <Grid
          container
          className={classes.editDetailsBox}
          style={{ marginBottom: "15px" }}
        >
          {Object.entries(editFields).map((el, index) => {
            return (
              <>
                {el[0] === "heading1" || el[0] === "heading2" ? (
                  <Grid item xs={12}>
                    <div style={{ color: "#536471", padding: "20px 24px 8px" }}>
                      <b>
                        <Trans>{el[1]}</Trans>
                      </b>
                    </div>
                  </Grid>
                ) : el[0] === "panel_reference" ? (
                  <Grid
                    container
                    direction="column"
                    style={{ padding: "8px 24px", position: "relative" }}
                  >
                    <Grid item xs={12} md={6}>
                      <CustomTextField
                        label={el[1].label}
                        unit={el[1].unit}
                        type={this.handleType(el[1].label)}
                        value={this.state[el[0]] ? this.state[el[0]] : ""}
                        handleChange={(value) => {
                          return this.setState({
                            [el[0]]: value.target.value
                              ? value.target.value
                              : null,
                          });
                        }}
                        gtmId={`${this.props.loggedInUser.user_profile.client_name}-${this.props.selectedSiteName.plant_name}-textBoxBarcode`}
                      />
                    </Grid>
                    <CustomButton
                      style={{ width: "220px", marginTop: 10 }}
                      margin="8px"
                      text="Scan Panel Barcode"
                      btnIcon={barcodeSvg}
                      //  onClick={() => {
                      // this.setState({ showBarcodePopUp: true, scanSuccess: false });
                      //  }}
                      onClick={() => {
                        return this.handleEmail();
                      }}
                      id={`${this.props.loggedInUser.user_profile.client_name}-${this.props.selectedSiteName.plant_name}-barcode`}
                    />

                    <div className={classes.trialLabel}>
                      <ProLabel
                        marginRight="0"
                        marginLeft="5px"
                        show
                        text={"Trial"}
                      />
                    </div>

                    {/* Send Email for Barcode */}
                    <EmailDialog
                      open={this.state.openFilter}
                      closeEmailDialog={() => {
                        return this.handleEmail();
                      }}
                    />
                  </Grid>
                ) : (
                  <Grid
                    container
                    direction="column"
                    style={{ padding: "8px 24px" }}
                  >
                    <Grid
                      item
                      xs={12}
                      md={6}
                      style={{
                        marginBottom:
                          index === Object.keys(editFields).length - 1
                            ? "80px"
                            : "",
                      }}
                    >
                      <CustomTextField
                        label={el[1].label}
                        unit={el[1].unit}
                        type={this.handleType(el[1].label)}
                        value={this.state[el[0]] ? this.state[el[0]] : ""}
                        handleChange={(value) => {
                          return this.setState({
                            [el[0]]: value.target.value
                              ? value.target.value
                              : null,
                          });
                        }}
                      />
                    </Grid>
                  </Grid>
                )}
              </>
            );
          })}

          <Grid className={classes.editFooter}>
            <CustomButton
              variant="contained"
              onClick={() => {
                return this.handleUpdateDetails();
              }}
              text="Save Changes"
              style={{ marginRight: "20px" }}
              id={"gtm_savePanelDetails"}
            />

            <CustomButton
              variant="contained"
              onClick={() => {
                return this.handleDiscardDetails();
              }}
              type="cancel"
              text="Discard"
              id={"gtm_discardPanelDetails"}
            />
          </Grid>
        </Grid>
      </div>
    ) : (
      <div style={{ overflow: "auto", backgroundColor: "#F3F5FA" }}>
        <Grid container>
          <CustomButton
            onClick={() => {
              return this.setState({ isEditPanel: true });
            }}
            text="Edit Panel Details"
            style={{
              margin: "20px",
              marginBottom: 0,
            }}
            id={"gtm_editPanelDetails"}
          />
          <Grid style={{ padding: 24, marginBottom: 80 }}>
            <Grid
              container
              className={classes.detailsBox}
              style={{
                marginTop: "0px",
              }}
            >
              <Grid item xs={6} style={{ borderBottom: "1px solid #DDE5EC" }}>
                <Typography className={classes.panelDetailsValue}>
                  {i18n.t("Serial Number")}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  paddingRight: "20px",
                  borderBottom: "1px solid #DDE5EC",
                }}
              >
                {" "}
                <Typography
                  className={classes.panelDetailsValue}
                  style={{ color: "#1D2D47" }}
                >
                  {this.state.panel_reference
                    ? this.state.panel_reference
                    : "-"}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography className={classes.panelDetailsValue}>
                  {i18n.t("Manufacturer Name")}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  paddingRight: "20px",
                }}
              >
                <Typography
                  className={classes.panelDetailsValue}
                  style={{ color: "#1D2D47" }}
                >
                  {this.state.panel_manufacturer
                    ? this.state.panel_manufacturer
                    : "-"}
                </Typography>
              </Grid>
            </Grid>
            {Object.entries(fields).map((heading) => {
              return (
                <React.Fragment key={shortid.generate()}>
                  <p className={classes.headingText}>
                    <Trans>{heading[0]}</Trans>
                  </p>

                  <Grid
                    container
                    className={classes.detailsBox}
                    style={{ borderBottom: "none" }}
                  >
                    {Object.entries(fields[heading[0]]).map((el) => {
                      return (
                        <React.Fragment
                          // style={{ borderBottom: "unset" }}
                          key={shortid.generate()}
                        >
                          <Grid
                            item
                            xs={6}
                            style={{
                              paddingRight: 0,
                              borderBottom: "1px solid #DDE5EC",
                            }}
                          >
                            <Trans>{el[1]}</Trans>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            style={{ borderBottom: "1px solid #DDE5EC" }}
                          >
                            <Grid container>
                              <Grid
                                item
                                xs
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  className={classes.panelDetailsValue}
                                  style={{ color: "#1D2D47" }}
                                >
                                  {this.state[el[0]] ? this.state[el[0]] : "-"}{" "}
                                  <span
                                    style={{
                                      paddingRight: "20px",
                                      color: "#1D2D47",
                                    }}
                                  >
                                    {" "}
                                    <Trans>
                                      {this.state[el[0]]
                                        ? [
                                            "defect_module_power",
                                            "healthy_module_power",
                                          ].indexOf(el[0]) > -1
                                          ? "W"
                                          : [
                                              "defect_max_power_current",
                                              "healthy_max_power_current",
                                              "defect_panel_current",
                                              "healthy_panel_current",
                                              "string_current",
                                            ].indexOf(el[0]) > -1
                                          ? "A"
                                          : [
                                              "defect_max_power_voltage",
                                              "healthy_max_power_voltage",
                                              "defect_panel_voltage",
                                              "healthy_panel_voltage",
                                            ].indexOf(el[0]) > -1
                                          ? "V"
                                          : " "
                                        : ""}
                                    </Trans>
                                  </span>
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </React.Fragment>
                      );
                    })}
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
          <Grid className={classes.panelFooter} />
        </Grid>
      </div>
    );
  }
}

//calling redux to get the selected language
const mapStateToProps = (state) => {
  return {
    language: state.language.selectedLanguage,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(PanelDetails));
