import { Button, Grid, withStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";

import MenuDot from "../../../assets/img/menuDot.svg";
import { Trans } from "react-i18next";
import YieldTariffPopup from "./yieldTariffPopup";
import styles from "../../reports/reports.style";

//To show yield dropdown
const YieldDropDown = ({
  classes,
  siteId,
  siteData,
  setRevenueLossTrigger,
  revenueLossTrigger,
  popupToggle,
  setPopupToggle,
}) => {
  const [showMenu, toggleMenu] = useState(false);

  const menuRef = useRef();
  useEffect(() => {
    //function to handle yieldDropdown
    const handler = (event) => {
      if (menuRef && menuRef.current) {
        if (!menuRef.current.contains(event.target)) {
          toggleMenu(false);
        }
      }
    };

    //Adding event listener to handle click outside of yieldDropdown
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  //To handle yield popup
  const handleYieldPopup = () => {
    setPopupToggle(true);
    toggleMenu(false);
  };

  //To handle cancel popup
  const handleCancelPopup = () => {
    setPopupToggle(false);
    toggleMenu(false);
  };

  return (
    <>
      <Grid
        className={classes.menuDot}
        onClick={() => {
          return toggleMenu(!showMenu);
        }}
        style={{
          transform: "rotate(-90deg)",
          padding: "10px",
        }}
      >
        <img src={MenuDot} alt="dot" />
      </Grid>

      {showMenu && (
        <Grid
          ref={menuRef}
          container
          justifyContent="center"
          style={{ width: "190px" }}
          className={classes.menuOptionsForYield}
        >
          <Button
            className={classes.menuButtonForYield}
            onClick={handleYieldPopup}
          >
            <Trans>Yield & Feed in Tariff</Trans>
          </Button>
        </Grid>
      )}

      {popupToggle && (
        <YieldTariffPopup
          siteId={siteId}
          siteData={siteData}
          handlepopup={handleCancelPopup}
          setRevenueLossTrigger={setRevenueLossTrigger}
          revenueLossTrigger={revenueLossTrigger}
        />
      )}
    </>
  );
};

export default withStyles(styles)(YieldDropDown);
