import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { Grid, Typography, withStyles } from "@material-ui/core";
import React, { useState } from "react";
import { Trans, withTranslation } from "react-i18next";

import CloseIcon from "@material-ui/icons/Close";
import CustomButton from "./Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import i18n from "i18next";

//css for styling the component
const styles = () => {
  return {
    dialogOuter: {
      minWidth: 600,
    },
    title: {
      fontSize: "14px",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "24px",
      letterSpacing: "normal",
      color: "#536471",
      fontFamily: "Montserrat",
      fontWeight: "600",
      textTransform: "uppercase",
    },
    titleText: {
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      fontFamily: "Montserrat",
      fontWeight: "550",
      fontSize: "12px",
      lineHeight: "20px",
      color: "#536471",
      wordWrap: "break-word",
      width: "fit-content",
      marginTop: 16,
    },
    langText: {
      marginRight: 20,
    },
    closeIcon: {
      cursor: "pointer",
      color: "#536471",
    },
    iconPortrait: {
      height: 20,
      width: 14,
      background: "#0084FF",
      color: "#fff",
      padding: 3,
      borderRadius: 3,
      fontSize: 12,
      fontWeight: 700,
      marginRight: 4,
    },
    iconLandscape: {
      height: 16,
      width: 22,
      background: "#FFC101",
      color: "#fff",
      padding: "1px 6px",
      borderRadius: 3,
      fontSize: 12,
      fontWeight: 700,
      marginRight: 4,
    },
    orientLabel: {
      marginRight: "0px !important",
    },
    orientText: {
      marginRight: 24,
    },
    contentRoot: {
      borderBottom: "1px solid rgb(221, 229, 236)",
      borderTop: "1px solid rgb(221, 229, 236)",
    },
  };
};

//custom report download
const CustomReportDownload = (props) => {
  const { classes } = props;
  const [orientValue, setOrientValue] = useState("portrait");
  const [langValue, setLangValue] = useState("english");

  //close popover
  const closeReportDialog = () => {
    props.closeReportDialog();
  };

  //handle report download
  const downloadHandler = () => {
    const reportObj = {
      orientation: orientValue,
      language: langValue,
    };
    props.downloadReportHandler(reportObj);
    props.closeReportDialog();
  };

  //handle orientation
  const handleOrientChange = (event) => {
    setOrientValue(event.target.value);
  };

  //handle language change
  const handleLanguageChange = (event) => {
    setLangValue(event.target.value);
  };

  return (
    <Dialog
      open={props.reportDialog}
      onClose={closeReportDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: classes.dialogOuter }}
    >
      <MuiDialogTitle id="customized-dialog-title" onClose={closeReportDialog}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography className={classes.title}>
            {" "}
            <Trans>Report Download</Trans>{" "}
          </Typography>
          <CloseIcon
            fontSize="large"
            className={classes.closeIcon}
            onClick={closeReportDialog}
          />
        </Grid>
      </MuiDialogTitle>
      <MuiDialogContent className={classes.contentRoot}>
        <Grid container direction="column">
          <Typography className={classes.titleText}>
            {" "}
            <Trans>Site Name</Trans>{" "}
          </Typography>
          <Typography className={classes.langText}>
            {" "}
            {props.siteName}{" "}
          </Typography>
          <Typography className={classes.titleText}>
            {" "}
            <Trans>Report Type</Trans>{" "}
          </Typography>
          <Typography className={classes.langText}>
            {" "}
            <Trans>{props.reportType}</Trans>{" "}
          </Typography>
        </Grid>

        <Grid container direction="column">
          <Typography className={classes.titleText}>
            <Trans>PDF Orientation</Trans>
          </Typography>

          <RadioGroup
            value={orientValue}
            onChange={handleOrientChange}
            name="radio-buttons-group"
          >
            <Grid container alignItems="center">
              <FormControlLabel
                value="portrait"
                control={
                  <Radio
                    style={{
                      color: orientValue === "portrait" ? "#7363FB" : "#C5C9D0",
                    }}
                  />
                }
                label=""
                classes={{ root: classes.orientLabel }}
              />
              <div className={classes.iconPortrait}>P</div>
              <Typography className={classes.orientText}>
                <Trans>Portrait</Trans>
              </Typography>
              <FormControlLabel
                value="landscape"
                control={
                  <Radio
                    style={{
                      color:
                        orientValue === "landscape" ? "#7363FB" : "#C5C9D0",
                    }}
                  />
                }
                label=""
                classes={{ root: classes.orientLabel }}
              />
              <div className={classes.iconLandscape}>L</div>
              <Typography className={classes.orientText}>
                <Trans>Landscape</Trans>
              </Typography>
            </Grid>
          </RadioGroup>
        </Grid>

        <Grid container direction="column">
          <Typography className={classes.titleText}>
            <Trans>Language</Trans>
          </Typography>
          <RadioGroup
            value={langValue}
            onChange={handleLanguageChange}
            name="radio-buttons-group"
          >
            <Grid container alignItems="center">
              <FormControlLabel
                value="english"
                control={
                  <Radio
                    style={{
                      color: langValue === "english" ? "#7363FB" : "#C5C9D0",
                    }}
                  />
                }
                label={i18n.t("English")}
              />
              <FormControlLabel
                value="spanish"
                control={
                  <Radio
                    style={{
                      color: langValue === "spanish" ? "#7363FB" : "#C5C9D0",
                    }}
                  />
                }
                label={i18n.t("Spanish")}
              />
            </Grid>
          </RadioGroup>
        </Grid>
      </MuiDialogContent>
      <Grid
        container
        justifyContent="flex-end"
        style={{ padding: "16px 24px" }}
      >
        <CustomButton
          variant="outlined"
          type={"cancel"}
          text={"Cancel"}
          onClick={closeReportDialog}
        />
        <CustomButton text={"Generate & Download"} onClick={downloadHandler} />
      </Grid>
    </Dialog>
  );
};

export default withStyles(styles)(withTranslation()(CustomReportDownload));
