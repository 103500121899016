import React, { useState } from "react";

import CategoriesTooltipPopup from "./compare Insp charts/CategoriesTooltipPopup";
import IECTooltipPopup from "./compare Insp charts/IECTooltipPopup";
import Modal from "@material-ui/core/Modal";
import criticalIcon from "../../assets/img/critical.svg";
import falsepositiveImg from "../../assets/img/falsePositive.svg";
import iec1Icon from "../../assets/img/iec1.svg";
import iec2Icon from "../../assets/img/iec2.svg";
import iec3Icon from "../../assets/img/iec3.svg";
import minorIcon from "../../assets/img/minor.svg";
import moderateIcon from "../../assets/img/moderateicon.svg";
import moment from "moment";
import notfoundImg from "../../assets/img/notfound.svg";
import pendingIcon from "../../assets/img/pending.svg";
import progressImg from "../../assets/img/progress.svg";
import resolvedIcon from "../../assets/img/resolved.svg";
import tooltipIcon from "../../assets/img/tooltipIcon.svg";
import uncategorizedIcon from "../../assets/img/uncategorized.svg";

//Anomaly table
function AnomalyTableCreator({
  selectedDefect,
  category,
  value,
  type,
  areAnyRgb,
  areAnyThermal,
  displayImageOpen,
}) {
  const [tooltipComp, setTooltipComp] = useState("");

  //close popup
  const closePopup = () => {
    setTooltipComp("");
  };

  //Creates a row for the anomaly table (Inspection table)
  return (
    <>
      {tooltipComp ? (
        <div style={{ position: "absolute" }}>
          <Modal open={tooltipComp === "Anomaly Category"}>
            <div
              style={{
                width: "100vw",
                height: "100vh",
                background: "rgba(0, 0, 0, 0.2)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CategoriesTooltipPopup handleClosePopup={closePopup} />
            </div>
          </Modal>

          <Modal open={tooltipComp === "IEC CoA"}>
            <div
              style={{
                width: "100vw",
                height: "100vh",
                background: "rgba(0, 0, 0, 0.2)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <IECTooltipPopup handleClosePopup={closePopup} />
            </div>
          </Modal>
        </div>
      ) : (
        ""
      )}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          // overflowX: "auto",
          background: "#fff",
          flexWrap: "np-wrap",
          boxSize: "border-box",
        }}
      >
        <div>
          <div
            style={{
              width: "200px",
              minHeight:
                type === "anomaly" && selectedDefect.length === 1
                  ? "60px"
                  : type === "anomaly" && selectedDefect.length > 1
                  ? "130px"
                  : "40px",
              height:
                type === "date"
                  ? "56px"
                  : // : type === "anomaly"
                  // ? "140px"
                  type === "thermal" && areAnyThermal
                  ? "112px"
                  : type === "rgb" && areAnyRgb
                  ? "112px"
                  : "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              padding: "0px 16px",
              borderBottom: "1px solid #DDE5EC",
            }}
          >
            {category === "Anomaly Category" || category === "IEC CoA" ? (
              <p
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setTooltipComp(category);
                }}
                aria-hidden="true"
              >
                {category}{" "}
                <span style={{ marginLeft: "2px" }}>
                  <img
                    src={tooltipIcon}
                    style={{ position: "relative", top: "4px" }}
                    alt="tooltip"
                  />
                </span>
              </p>
            ) : (
              category
            )}
          </div>
        </div>
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // flexDirection: "row-reverse",
            }}
          >
            {selectedDefect.map((item) => {
              return (
                <div
                  key={item.defect_id}
                  style={{
                    minHeight:
                      type === "anomaly" && selectedDefect.length === 1
                        ? "60px"
                        : type === "anomaly" && selectedDefect.length > 1
                        ? "130px"
                        : "40px",
                    height:
                      type === "date"
                        ? "56px"
                        : // : type === "anomaly"
                        // ? "140px"
                        type === "thermal" && areAnyThermal
                        ? "112px"
                        : type === "rgb" && areAnyRgb
                        ? "112px"
                        : "40px",
                    width: selectedDefect.length > 1 ? "122px" : "180px",
                    color: "#1D2D47",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    padding: "0px 16px",
                    borderLeft: "1px solid #DDE5EC",
                    textAlign: "right",
                    borderBottom: "1px solid #DDE5EC",
                    // paddingLeft: type === "date" ? "30px" : "10px",
                  }}
                >
                  {item[value] ? (
                    type === "date" ? (
                      `Inspection:${moment(item[value]).format("DD MMM YYYY")}`
                    ) : type === "category" ? (
                      item[value].charAt(0).toUpperCase() +
                      item[value].substr(1).toLowerCase()
                    ) : type === "image" ? (
                      <img
                        src={
                          item[value] === "Critical"
                            ? criticalIcon
                            : item[value] === "Moderate"
                            ? moderateIcon
                            : item[value] === "Minor"
                            ? minorIcon
                            : item[value] === "IEC 1"
                            ? iec1Icon
                            : item[value] === "IEC 2"
                            ? iec2Icon
                            : item[value] === "IEC 3"
                            ? iec3Icon
                            : uncategorizedIcon
                        }
                        alt="IEC CoA"
                      />
                    ) : type === "severity" ? (
                      <img
                        src={
                          item[value] === "Critical"
                            ? criticalIcon
                            : item[value] === "Moderate"
                            ? moderateIcon
                            : minorIcon
                        }
                        alt="Severity"
                      />
                    ) : type === "id" ? (
                      `#${item[value]}`
                    ) : type === "status" ? (
                      item[value] === "resolved" ? (
                        <>
                          <img
                            alt="statusImg"
                            style={{ marginRight: "6.5px" }}
                            src={resolvedIcon}
                          />{" "}
                          Resolved
                        </>
                      ) : item[value] === "pending" ? (
                        <>
                          <img
                            alt="statusImg"
                            style={{ marginRight: "6.5px" }}
                            src={pendingIcon}
                          />
                          Pending
                        </>
                      ) : item[value] === "inprogress" ? (
                        <>
                          <img
                            alt="statusImg"
                            style={{ marginRight: "6.5px" }}
                            src={progressImg}
                          />
                          In Progress
                        </>
                      ) : item[value] === "notfound" ? (
                        <>
                          <img
                            alt="statusImg"
                            src={notfoundImg}
                            style={{ marginRight: "6.5px" }}
                          />
                          Not Found
                        </>
                      ) : (
                        <>
                          <img
                            alt="statusImg"
                            src={falsepositiveImg}
                            style={{ marginRight: "6.5px" }}
                          />
                          False +ve
                        </>
                      )
                    ) : type === "thermal" ? (
                      <div>
                        <img
                          alt="statusImg"
                          src={item[value]}
                          style={{
                            height: "80px",
                            width: "80px",
                            borderRadius: "4px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            return displayImageOpen(
                              item.thermal_image_url,
                              "thermal_image_url"
                            );
                          }}
                          aria-hidden="true"
                        />
                      </div>
                    ) : type === "rgb" ? (
                      <div>
                        <img
                          alt="statusImg"
                          src={item[value]}
                          style={{
                            height: "80px",
                            width: "80px",
                            borderRadius: "4px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            return displayImageOpen(
                              item.rgb_image_url,
                              "rgb_image_url"
                            );
                          }}
                          aria-hidden="true"
                        />
                      </div>
                    ) : type === "temperature" ? (
                      `${item[value]}°C`
                    ) : (
                      item[value]
                    )
                  ) : (
                    "-"
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default AnomalyTableCreator;
