export const statusList = [
  {
    name: "Pending",
    value: "pending",
  },
  {
    name: "Resolved",
    value: "resolved",
  },
  {
    name: "In Progress",
    value: "inprogress",
  },
  {
    name: "Not Found",
    value: "notfound",
  },
  {
    name: "False +ve",
    value: "falsepositive",
  },
];
