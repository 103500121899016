import {
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  InputBase,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, { useState } from "react";

import CloseIcon from "@material-ui/icons/Close";
import Config from "../../../config";
import CustomTooltip from "../../common/customStyle/Tooltip";
import Info from "@material-ui/icons/Info";
import { Trans } from "react-i18next";
import { allRequestHandler } from "../../../api";
import { callSnackbarAction } from "../../../reducers/snackbar";
import { store } from "../../../store";
import styles from "../../reports/reports.style";

const requestUrl = Config.hostName;

//yield and tariff popover
const YieldTariffPopup = ({
  classes,
  handlepopup,
  siteId,
  setRevenueLossTrigger,
  siteData
}) => {
  const [yieldValue, setYieldValue] = useState(siteData?.pv_out);
  const [tariff, setTariff] = useState(siteData?.tariff_data?.power_tariff);
  // const [siteData, setSiteData] = useState();

  // useEffect(() => {
  //   //fetching site_details data here
  //   async function fetchData() {
  //     const data = await allRequestHandler({
  //       requestType: "GET",
  //       requestUrl: `${requestUrl}/api/sites/${siteId}/site_details`,
  //     });

  //     if (data) {
  //       delete data.mounting_options;
  //       delete data.upload_href;
  //       const updatedData = data;
  //       setSiteData(updatedData);
  //       setYieldValue(data.pv_out);
  //       setTariff(data.tariff_data.power_tariff);
  //     }
  //   }
  //   fetchData();
  // }, []);

  //function to handle yield change
  const handleYieldChange = (event) => {
    if (event.target.value >= 0) {
      const conVal = String(event.target.value);
      conVal.replace("-", "");
      if (conVal.length <= 5) {
        setYieldValue(parseFloat(event.target.value));
      }
    } else {
      setYieldValue(parseFloat(event.target.value * -1));
    }
  };

  //function to handle tariff change
  const handleTariffChange = (event) => {
    if (event.target.value >= 0) {
      const conVal = String(event.target.value);
      conVal.replace("-", "");
      if (conVal.length <= 5) {
        setTariff(parseFloat(event.target.value));
      }
    } else {
      setTariff(parseFloat(event.target.value * -1));
    }
  };

  //function to save and update changes for yield and tariff
  const handleSave = async () => {
    if (yieldValue > 9999 || yieldValue < 0 || tariff > 9999 || tariff < 0) {
      store.dispatch(
        callSnackbarAction("Please enter valid yield and tariff", "info")
      );
    } else {
      await allRequestHandler({
        requestType: "PUT",
        requestUrl: `${requestUrl}/api/sites/${siteId}/site_details`,
        requestData: {
          ...siteData,
          pv_out: yieldValue !== "" ? yieldValue : null,
          tariff_data: {
            ...siteData.tariff_data,
            power_tariff: tariff !== "" ? tariff : null,
          },
          pv_technology_options: undefined,
        },
      });

      store.dispatch(
        callSnackbarAction(
          "Yield and Tariff Data Updated Successfully",
          "success"
        )
      );
      handlepopup();
      setRevenueLossTrigger(true);
    }
  };
  
  return (
    siteData && (
      <Grid container className={classes.popupContainer} direction="column">
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Grid
            container
            justifyContent="space-between"
            className={classes.popupItem}
            style={{ height: "320px", width: "401px" }}
            direction="column"
          >
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              className={classes.paddAlign}
            >
              <Typography className={classes.popUpTitle}>
                <Trans>Add Yield & Feed in Tariff</Trans>
              </Typography>
              <CloseIcon
                fontSize="large"
                className={classes.closeIcon}
                onClick={handlepopup}
              />
            </Grid>
            <Divider style={{ height: "1.5px" }} className={classes.marTB20} />
            <Grid container style={{ padding: "1rem 0" }}>
              <Grid
                item
                xs={12}
                md={12}
                style={{
                  paddingLeft: "30px",
                  paddingBottom: "30px",
                  paddingTop: "11px",
                }}
              >
                <FormControl className={classes.inputLabelForYield}>
                  <Grid>
                    <Grid style={{ marginTop: "25px" }}>
                      <Grid container>
                        <Typography className={classes.titleTextForYield}>
                          <Trans>Yield (kWh/kWp per year)</Trans>
                        </Typography>
                        <Grid>
                          <CustomTooltip
                            title={
                              <div>
                                {" "}
                                Yield is the ratio between total annual energy
                                produced and the solar installed capacity.
                                <br /> Yield (kWh/kWp per year) = annual plant
                                output (kWh) / installed capacity (kWp)
                              </div>
                            }
                          >
                            <Info
                              style={{
                                marginLeft: 5,
                                position: "relative",
                                color: "#C7CEDB",
                              }}
                            />
                          </CustomTooltip>
                        </Grid>
                        <InputBase
                          id="reportName"
                          name="reportName"
                          type="number"
                          className={`${classes.customInputForYield} ${classes.titleText}`}
                          onChange={handleYieldChange}
                          value={yieldValue}
                        />
                      </Grid>
                    </Grid>

                    <Grid style={{ marginTop: "15px" }}>
                      <Grid container>
                        <Typography className={classes.titleTextForYield}>
                          <Trans>Feed in tariff/kWh</Trans>
                        </Typography>
                        <Grid>
                          <CustomTooltip
                            title={
                              "Enter the selling price of your energy to calculate total revenue losses due to defects at your site."
                            }
                          >
                            <Info
                              style={{
                                marginLeft: 5,
                                position: "relative",
                                color: "#C7CEDB",
                              }}
                            />
                          </CustomTooltip>
                        </Grid>
                      </Grid>
                      <Grid
                        style={{ marginLeft: "6px" }}
                        container
                        alignItems="center"
                      >
                        <Grid
                          className={classes.currencyContainer}
                          alignItems="center"
                          justifyContent="center"
                          container
                        >
                          <Typography style={{ color: "#536471" }}>
                            $
                          </Typography>
                        </Grid>
                        <InputBase
                          id="reportName"
                          name="reportName"
                          type="number"
                          className={`${classes.customInputForFeed} ${classes.titleText}`}
                          onChange={handleTariffChange}
                          value={tariff}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
            </Grid>

            <Divider style={{ height: "1.5px" }} className={classes.marTB20} />
            <Grid
              container
              className={classes.paddAlign}
              style={{
                justifyContent: "flex-end",
                paddingRight: "30px",
                paddingTop: "10px",
              }}
            >
              <Button
                variant="outlined"
                style={{ borderRadius: 8 }}
                onClick={handlepopup}
              >
                <Trans>Cancel</Trans>
              </Button>
              <Button
                variant="contained"
                className={classes.reviewButton}
                onClick={handleSave}
              >
                <Trans>Save</Trans>
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    )
  );
};

export default withStyles(styles)(YieldTariffPopup);
