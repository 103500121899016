import LanguageDetector from "i18next-browser-languagedetector";
import en from "./en";
import i18n from "i18next";
import jp from "./jp";
import sp from "./sp";

i18n.use(LanguageDetector).init({
  resources: {
    en,
    jp,
    sp,
  },
  fallbackLng: "en",
  debug: false,

  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false,

  interpolation: {
    escapeValue: false,
    formatSeparator: ",",
  },

  react: {
    wait: true,
  },
});

export default i18n;
