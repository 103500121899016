import "./indexForgot.css";

import {
  Button,
  Grid,
  Hidden,
  IconButton,
  Snackbar,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import { URLS, version } from "../../constants";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Link } from "react-router-dom";
import MuiAlert from "@material-ui/lab/Alert";
import React from "react";
import { allRequestHandler } from "../../api";
import loginIllustration from "../../components/assets/img/login_Illustration_compressed.png";
import styles from "./ForgotPassword.style";
import zeitviewLogo from "../../assets/img/zeitviewLogo.svg";

//forgotPassword
class ForgotPassword extends React.Component {
  //Initiate the state variables
  constructor() {
    super();
    this.state = {
      email: "",
      displayMsg: null,
      useSnackbar: false,
      sentMsg: false,
    };
  }

  //handling username and password input
  handleChange = (event, prop) => {
    this.setState({ [prop]: event.target.value });
  };

  //Verify if email is valid and send password reset instructions to email
  handleEmail = async () => {
    if (this.state.email === "") {
      this.setState({
        useSnackbar: true,
        displayMsg: "Please Enter your email Id.",
      });
    } else {
      const response = await allRequestHandler({
        requestType: "POST",
        requestUrl: URLS.FORGOT_PASSWORD,
        requestData: {
          email_id: this.state.email,
        },
      });

      if (response) {
        this.setState({
          useSnackbar: true,
          displayMsg:
            "Check your email. We just sent an email to you with a link to reset your password!",
          sentMsg: true,
        });
      } else {
        this.setState({
          sentMsg: false,
        });
      }
    }
  };

  //Closing the snackbar (warnings, info and errors alert )
  handleCloseSnackbar = () => {
    this.setState({ useSnackbar: false });
  };

  //rendering the forgot password page where users can enter their email id to get password reset instructions
  render() {
    const { classes } = this.props;

    return (
      <Grid
        container
        className="height100 overHide outerDiv"
        alignItems="center"
      >
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={3000}
          open={this.state.useSnackbar}
          onClose={this.handleCloseSnackbar}
        >
          <MuiAlert elevation={6} variant="filled" severity="info">
            {" "}
            {this.state.displayMsg}{" "}
          </MuiAlert>
        </Snackbar>
        <Grid container className="rowC overHide" alignItems="center">
          <Grid item xs={12} sm={6} className={classes.mobBox}>
            <Grid
              container
              className="height100"
              justifyContent="center"
              alignItems="center"
            >
              {!this.state.sentMsg ? (
                <Grid item className="heading">
                  <img
                    className={classes.imgLogo}
                    src={zeitviewLogo}
                    alt="logo"
                  />
                  <h5 className={classes.versionTxt}> {version} </h5>
                  <Grid
                    item
                    xs
                    zeroMinWidth
                    className={classes.backBtnContainer}
                  >
                    <IconButton component={Link} to={"/"}>
                      <ArrowBackIcon className="Back" />
                      <Typography
                        className={classes.backLabel}
                        style={{ marginLeft: 4 }}
                      >
                        Back to login
                      </Typography>
                    </IconButton>
                  </Grid>
                  <Typography className={classes.loginText}>
                    Forgot Password ?{" "}
                  </Typography>

                  <form>
                    <p
                      className={classes.backLabel}
                      style={{ margin: "50px 0px 30px" }}
                    >
                      Enter your email address and we’ll send you an email with
                      instructions to reset your password.
                    </p>
                    <div className="mail">
                      <div
                        className={classes.backLabel}
                        style={{ marginBottom: "5px" }}
                      >
                        Email
                      </div>
                      <TextField
                        classes={{ root: classes.loginInput }}
                        style={{ fontSize: "12px" }}
                        size="small"
                        variant="outlined"
                        color="primary"
                        placeholder="username@email.com"
                        fullWidth
                        id="email"
                        name="email"
                        autoComplete="off"
                        autoFocus
                        value={this.state.email}
                        onChange={(e) => {
                          this.handleChange(e, "email");
                        }}
                      />
                    </div>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.sendBtn}
                      onClick={() => {
                        return this.handleEmail();
                      }}
                      classes={{ label: classes.btnLoginLabel }}
                    >
                      Send Instructions
                    </Button>
                  </form>
                </Grid>
              ) : (
                <Grid container>
                  <Grid className="Arrowback" xs zeroMinWidth>
                    <IconButton component={Link} to={"/"}>
                      <ArrowBackIcon className="Back" />
                      <Typography
                        className={classes.backLabel}
                        style={{ marginLeft: 8 }}
                      >
                        Back to login
                      </Typography>
                    </IconButton>
                  </Grid>
                  <Typography>
                    Check your email. <br />
                    We just sent an email to you with a link to reset your
                    password!
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>

          <Hidden xsDown>
            <Grid item xs={12} sm={6}>
              <img
                className={classes.illustrationImg}
                src={loginIllustration}
                alt="login_Design"
              />
            </Grid>
          </Hidden>
        </Grid>
      </Grid>
    );
    // }
  }
}

export default withStyles(styles)(ForgotPassword);
