import { Grid, withStyles } from "@material-ui/core";

import { Bar } from "react-chartjs-2";
import LegendView from "./legendView";
import React from "react";
import _ from "lodash";
import styles from "../../../dashboard/dashboard.styles";
import { useEffect } from "react";
import { useState } from "react";

const HorizontalBarChart = (props) => {
  const [allData, setAllData] = useState(null);
  const [updateLegendData, setUpdateLegendData] = useState(null);

  useEffect(() => {
    //Chart data processing
    handleDataset();
  }, [props.dataType, props.getChartData]);

  const sortedSeverityTypes = () => {
    const sortSeverityTypes = _.sortBy(
      props.getChartData.severity_list,
      "name"
    );
    const shiftItem = sortSeverityTypes.pop();
    sortSeverityTypes.splice(1, 0, shiftItem);

    return sortSeverityTypes;
  };

  //chart dataset
  const handleDataset = () => {
    let getAllLegendData;
    // Update selected dataType on toggle
    if (props.dataType === "Severity") {
      getAllLegendData = sortedSeverityTypes();
    } else if (props.dataType === "Status") {
      getAllLegendData = props.getChartData.status_list;
    } else getAllLegendData = props.getChartData.iec_category_list;

    const labels = [""];
    let updatedData = [];

    // Push all data to single array which can be passed in Bar chart
    getAllLegendData &&
      getAllLegendData.map((loop) => {
        return updatedData.push({
          label: loop && loop.name.replace(/ /g, ""),
          backgroundColor: loop && loop.color,
          data: [loop && loop.count],
          maxBarThickness: 15,
        });
      });

    //Chart data (formatting)
    const currState = {
      fontSize: "15px",
      labels,
      datasets: updatedData,
    };

    setUpdateLegendData(getAllLegendData);
    setAllData(currState);
  };

  //chart options
  const options = {
    indexAxis: "y",
    maintainAspectRatio: false,
    scales: {
      x: {
        max: props.getChartData.total_anomalies,
        barThickness: "flex",
        display: false,
        stacked: true,
        ticks: {
          beginAtZero: true,
        },
      },
      y: {
        stacked: true,
        display: false,
      },
    },
    hover: { mode: null },
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  return (
    allData && (
      <React.Fragment>
        <Grid item xs style={{ height: 78, width: "100%" }}>
          {/* rendering horizontal anomalies bar chart in dasboard page. */}
          <Bar id="bar" options={options} data={allData} />
        </Grid>

        <LegendView
          total_anomalies={props.getChartData.total_anomalies}
          updateLegendData={updateLegendData}
          dataType={props.dataType}
        />
      </React.Fragment>
    )
  );
};

export default withStyles(styles)(HorizontalBarChart);
