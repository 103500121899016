import { Chip, Grid, Typography } from "@material-ui/core";
import { Trans, withTranslation } from "react-i18next";

import React from "react";
import deleteIcon from "../../../assets/img/deleteIcon.svg";
import falsepositiveImg from "../../../assets/img/falsePositive.svg";
import i18n from "i18next";
import iec1 from "../../../assets/img/iec1.svg";
import iec2 from "../../../assets/img/iec2.svg";
import iec3 from "../../../assets/img/iec3.svg";
import minorImg from "../../../assets/img/Subtract.svg";
import notfoundImg from "../../../assets/img/notfound.svg";
import pendingImg from "../../../assets/img/pending.svg";
import progressImg from "../../../assets/img/progress.svg";
import resolvedImg from "../../../assets/img/resolved.svg";
import subtractImg from "../../../assets/img/Moderate2.svg";
import uncategorized from "../../../assets/img/uncategorized.svg";
import vectorImg from "../../../assets/img/Vector.svg";
import { withStyles } from "@material-ui/core/styles";

//styles for filter chips
const styles = () => {
  return {
    filterChip: {
      margin: 4,
      height: 32,
      color: "#545B65",
      backgroundColor: "rgba(205, 199, 254, 0.3)",
      borderRadius: "8px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "20px",
      fontStretch: "normal",
      letterSpacing: "normal",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: 0,
      "&:focus": {
        backgroundColor: "#F2F9FF",
      },
    },
    circle: {
      width: 8,
      height: 8,
      borderRadius: "50%",
      marginRight: 4,
    },
    txtName: {
      fontFamily: "Montserrat",
      fontStyle: "inherit",
      fontSize: 12,
      color: "#536471",
    },
  };
};

//common component for filterChips
class CustomFilterChip extends React.Component {
  //Add severity icon as per the key provided
  addSeverityIcon = (value) => {
    const sevImg =
      value === "Critical"
        ? vectorImg
        : value === "Moderate"
        ? subtractImg
        : minorImg;

    return (
      <Grid container alignItems="center" wrap="nowrap">
        <div
          style={{
            width: 15,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "8px",
          }}
        >
          <img alt="sevImg" src={sevImg} />
        </div>
        <Typography style={{ textTransform: "capitalize" }}>
          <Trans>{value}</Trans>
        </Typography>
      </Grid>
    );
  };

  //Add status icon as per the key provided
  addStatusIcon = (value) => {
    const statusImg =
      value === "pending"
        ? pendingImg
        : value === "inprogress"
        ? progressImg
        : value === "resolved"
        ? resolvedImg
        : value === "falsepositive"
        ? falsepositiveImg
        : notfoundImg;

    return (
      <Grid container alignItems="center" wrap="nowrap">
        <div
          style={{
            width: 20,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "8px",
          }}
        >
          <img alt="sevImg" src={statusImg} />
        </div>
        <Typography style={{ textTransform: "capitalize" }}>
          <Trans>
            {value === "inprogress"
              ? "In Progress"
              : value === "falsepositive"
              ? "False +Ve"
              : value === "resolved"
              ? "Resolved"
              : value === "notfound"
              ? "Not Found"
              : value === "pending"
              ? "Pending"
              : value}
          </Trans>
        </Typography>
      </Grid>
    );
  };

  //Add IEC icon as per the key provided
  addIecIcon = (value) => {
    const iecImg =
      value === "IEC 1"
        ? iec1
        : value === "IEC 2"
        ? iec2
        : value === "IEC 3"
        ? iec3
        : uncategorized;

    return (
      <Grid container alignItems="center" justifyContent="center" wrap="nowrap">
        <div
          style={{
            width: 20,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "8px",
          }}
        >
          <img alt="sevImg" src={iecImg} />
        </div>
        <Typography style={{ textTransform: "capitalize" }}>
          <Trans>{i18n.t(value)}</Trans>
        </Typography>
      </Grid>
    );
  };

  //Add label icon as per the key provided
  addLabelIcon = (value) => {
    return (
      <Grid container alignItems="center" justifyContent="center" wrap="nowrap">
        <div
          style={{
            height: "8px",
            width: "8px",
            background:
              value === "NEW"
                ? "#149B9E"
                : value === "PERSISTENT"
                ? "#8D95A2"
                : value === "TRANSITIONED"
                ? "#F26649"
                : "#FFCB05",
            borderRadius: "100%",
            marginRight: "5px",
          }}
        />
        <Typography style={{ textTransform: "capitalize" }}>
          <Trans>
            {value === "NEW"
              ? i18n.t("New")
              : value === "PERSISTENT"
              ? i18n.t("Persistent")
              : value === "TRANSITIONED"
              ? i18n.t("Transitioned")
              : i18n.t("Recurring")}
          </Trans>
        </Typography>
      </Grid>
    );
  };

  //Renders the custom filter chips
  render() {
    const { classes } = this.props;

    return (
      <Chip
        variant={this.props.variant}
        deleteIcon={
          <img
            alt="sevImg"
            style={{ width: 8, height: 8, marginLeft: 2, marginRight: 12 }}
            src={deleteIcon}
          />
        }
        onDelete={this.props.onDelete}
        size="small"
        className={classes.filterChip}
        label={
          this.props.type === "status" ? (
            this.addStatusIcon(this.props.name)
          ) : this.props.type === "severity" ? (
            this.addSeverityIcon(this.props.name)
          ) : this.props.type === "label" ? (
            this.addLabelIcon(this.props.name)
          ) : this.props.type === "iec" ? (
            this.addIecIcon(this.props.name)
          ) : this.props.type === "temperature_range" ? (
            `∆T: ${this.props.lowerTempValue}\u2103 - ${this.props.higherTempValue}\u2103`
          ) : this.props.type === "images" ? (
            this.props.name === "comments" ? (
              i18n.t("Comments")
            ) : this.props.name === "el_image" ? (
              i18n.t("EL Image")
            ) : this.props.name === "handheld_image" ? (
              i18n.t("Handheld Image")
            ) : (
              i18n.t("IV Curve Data")
            )
          ) : (
            <Grid container alignItems="center" wrap="nowrap">
              <div
                className={classes.circle}
                style={{ background: this.props.color }}
              />
              <Typography>
                {" "}
                <Trans>{this.props.name}</Trans>
              </Typography>
            </Grid>
          )
        }
      />
    );
  }
}

export default withStyles(styles)(withTranslation()(CustomFilterChip));
