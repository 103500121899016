import { Link, NavLink } from "react-router-dom";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  withStyles,
} from "@material-ui/core";

import React from "react";
import styles from "./header.style";

function customLink({
  isActivePath,
  path,
  Icon,
  menuItemName,
  classes,
  closeSidebar,
}) {
  const TagName = path === "/" ? Link : NavLink;
  return (
    <TagName onClick={closeSidebar} className={isActivePath} to={path}>
      <ListItem className={{ button: classes.listItemRoot }} button>
        <ListItemIcon style={{ minWidth: 35 }}>{Icon}</ListItemIcon>
        <ListItemText
          classes={{ primary: classes.linkStyles }}
          primary={menuItemName}
        />
      </ListItem>
    </TagName>
  );
}

export default withStyles(styles)(customLink);
