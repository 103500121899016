export default {
  ClientLogo: {
    fontSize: 15,
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "15px",
    letterSpacing: "normal",
    color: "#181A1C",
    padding: "6px 0 0 2px",
    height: 32,
    borderRadius: 6,
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    cursor: "default",
    marginLeft: 15,
    marginTop: -12,
    "@media(max-width : 600px)": {
      padding: "6px 0 0 12px",
    },
  },
};
