import { Grid } from "@mui/material";
import React from "react";
import appStoreContainer from "../../assets/img/playStoreContainer.svg";
import playStoreContainer from "../../assets/img/googlePlayContainer.svg";
import solarPanelImgBkg from "../../assets/img/solarPanelBkg.png";
import zeitviewLogo from "../../assets/img/zeitviewLogo.svg";

const WebToMobileScreen = ({ classes }) => {
  return (
    <Grid
      className={classes.backgroundParent}
      container
      direction="column"
      alignItems="center"
    >
      <Grid item className={classes.imgParent}>
        <img className={classes.solarImg} src={solarPanelImgBkg} />
      </Grid>
      <Grid item className={classes.backgroundTrans}>
        <Grid
          container
          justifyContent={"center"}
          direction={"column"}
          className={classes.logoAndText}
        >
          <img className={classes.zeitviewLogoMobile} src={zeitviewLogo} />
          <Grid className={classes.redirectText}>
            {`Experience uninterrupted access to our site, online or offline,
            with our mobile app's offline feature.`}
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent={"space-evenly"}
          className={classes.playStoreOptions}
        >
          <a href="https://apps.apple.com/us/app/zeitview-inspect/id6443456417">
            <img src={appStoreContainer} />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.airprobe">
            <img src={playStoreContainer} />
          </a>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default WebToMobileScreen;
