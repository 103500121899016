import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import { Trans, withTranslation } from "react-i18next";
import { URLS, uploadFileOptions } from "../../constants";

import Config from "../../config";
import CustomChip from "../common/customStyle/FilterChip";
import MuiAlert from "@material-ui/lab/Alert";
import React from "react";
import _ from "lodash";
import { allRequestHandler } from "../../api";
import { connect } from "react-redux";
import elImage from "../../assets/img/elImage.svg";
import falsepositiveImg from "../../assets/img/falsePositive.svg";
import fileImg from "../../assets/img/fileImg.svg";
import handledImg from "../../assets/img/handheld.svg";
import i18n from "i18next";
import ivDataImg from "../../assets/img/ivDataImg.svg";
import moment from "moment";
import styles from "./defectMap.style";
import { withStyles } from "@material-ui/core/styles";

const requestUrl = Config.hostName;

//comments tab
class Comments extends React.Component {
  state = {
    addComment: null,
    commentList: null,
    selectedFileType: null,
    fileAttached: null,
    useSnackbar: false,
    displayMsg: null,
    attachment_list: [],
  };

  //fetch all comments on load
  componentDidMount() {
    this.fetchAllComment();
  }

  //When selected defect is changed, fetch comments for that defect
  componentDidUpdate(prevProps) {
    if (prevProps.selectedDefect !== this.props.selectedDefect) {
      this.fetchAllComment();
    }
  }

  //API call to fetch all comments
  fetchAllComment = async () => {
    try {
      const getComment = await allRequestHandler({
        requestType: "GET",
        requestUrl: `${
          URLS.ANOMALY_DETAILS
        }${this.props.selectedDefect?.defect_id.toString()}/comments`,
      });

      this.setState({
        commentList: _.orderBy(getComment.comments_list, "comment_time", "asc"),
      });
    } catch (error) {
      console.log("error:", error);
    }
  };

  //Handles input from the text field
  handleInput = (e, field) => {
    this.setState({ [field]: e.target.value });
  };

  //Handles file upload
  handleUpload = (e) => {
    this.setState({ selectedFileType: e.target.value });
  };

  //API call to upload file
  uploadFileChange = async (e) => {
    const files = e.target.files[0];

    this.setState({ fileAttached: files });
    const eveObj = [
      {
        file_name: files.name.split(" ").join("_").trim(),
        content_type: files.type,
        content_length: files.size,
        attachment_type:
          this.state.selectedFileType === "file"
            ? "comment"
            : this.state.selectedFileType,
      },
    ];

    try {
      const getUrl = await allRequestHandler({
        requestType: "POST",
        requestUrl: `${requestUrl}/api/attachments/aws_params`,
        requestData: { attachment_list: eveObj },
      });
      const getAttach = [];
      getAttach.push(getUrl.aws_params[0].attachment_id);
      this.setState({
        attachment_list: getAttach,
      });
      if (getUrl && getUrl.aws_params && getUrl.aws_params.length) {
        const data = new FormData();
        data.append("key", getUrl.aws_params[0].fields.key);
        data.append(
          "Content-Type",
          getUrl.aws_params[0].fields["Content-Type"]
        );
        data.append("policy", getUrl.aws_params[0].fields.policy);
        data.append("acl", "public-read");
        data.append("success_action_status", "201");
        data.append(
          "x-amz-algorithm",
          getUrl.aws_params[0].fields["x-amz-algorithm"]
        );
        data.append(
          "x-amz-credential",
          getUrl.aws_params[0].fields["x-amz-credential"]
        );
        data.append("x-amz-date", getUrl.aws_params[0].fields["x-amz-date"]);
        data.append(
          "x-amz-signature",
          getUrl.aws_params[0].fields["x-amz-signature"]
        );
        data.append("file", files, getUrl.aws_params[0].file_name);
        const xhr = new XMLHttpRequest();
        // xhr.withCredentials = true;

        xhr.open("POST", getUrl.aws_params[0].url);
        xhr.send(data);
        this.props.dataUpdated(
          this.props.selectedDefect.defect_id,
          "attachment"
        );
      }
    } catch (error) {
      console.log("error:", error);
    }
  };

  //API call to post an comment
  postComment = async () => {
    if (this.state.addComment && this.state.addComment !== "") {
      // let commentUrl = this.props.href_list.filter((url) => {
      //   return url.comments;
      // })[0];
      const requestBody = {
        text: this.state.addComment,
        attachment_list: this.state.attachment_list,
      };

      await allRequestHandler({
        requestType: "POST",
        requestUrl: `${
          URLS.ANOMALY_DETAILS
        }${this.props.selectedDefect.defect_id.toString()}/comments`,
        requestData: requestBody,
      });

      this.setState({
        selectedFileType: null,
        fileAttached: null,
        addComment: null,
        attachment_list: null,
      });
      this.fetchAllComment();
      this.props.dataUpdated(this.props.selectedDefect.defect_id, "comment");
    } else {
      this.setState({
        useSnackbar: true,
        displayMsg: "Please enter comment",
      });
    }
  };

  //Handles the file deletion
  handleDelete = () => {
    this.setState({ fileAttached: null });
  };

  //Closing the snackbar (warnings, info and errors alert)
  handleCloseSnackbar = () => {
    this.setState({ useSnackbar: false });
  };

  //Renders the comments tab
  render() {
    const { classes } = this.props;

    return (
      <Grid container direction="column" className={classes.commBg}>
        {/* Update Notification */}
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={3000}
          open={this.state.useSnackbar}
          onClose={this.handleCloseSnackbar}
        >
          <MuiAlert elevation={6} variant="filled" severity="warning">
            {" "}
            {this.state.displayMsg}{" "}
          </MuiAlert>
        </Snackbar>
        {this.props.product?.remaining_days ||
        this.props.product?.product_type === "Pro" ? (
          <Typography className={classes.addText}>
            (<Trans>Data storage upto</Trans> 10GB/100MW)
          </Typography>
        ) : (
          <Typography className={classes.addText}>
            (<Trans>Data storage upto</Trans> 1GB/100MW)
          </Typography>
        )}
        <Grid item xs className={classes.commentFlow}>
          {this.state.commentList &&
            this.state.commentList.map((comm) => {
              return (
                <Card
                  className={classes.commCard}
                  style={{}}
                  key={comm.comment_id}
                >
                  <CardContent classes={{ root: classes.cardRoot }}>
                    <Grid container alignItems="center">
                      <Typography className={classes.msgUser}>
                        {comm.commenter}
                      </Typography>

                      {/* If False+ve proof display icon */}
                      {comm.comment_type === "false_positive_proof" && (
                        <Grid container className={classes.outerFalse}>
                          <img
                            alt="falseImg"
                            className={classes.btnImg}
                            src={falsepositiveImg}
                          />
                          <Typography
                            className={classes.plantName}
                            style={{ color: "#536471" }}
                          >
                            <Trans>False +ve</Trans>
                          </Typography>
                        </Grid>
                      )}

                      <div style={{ flex: "auto" }} />
                      <Typography className={classes.msgTime}>
                        {moment.utc(comm.comment_time).local().format("lll")}
                      </Typography>
                    </Grid>
                    <div className={classes.msgText}> {comm.text} </div>
                    {comm.attachments && comm.attachments.length ? (
                      <Grid container alignItems="center">
                        <img
                          alt="fileImg"
                          className={classes.linkIcon}
                          src={fileImg}
                        />

                        {comm.attachments.map((link) => {
                          return (
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={link}
                              style={{ marginLeft: 6, marginRight: 5 }}
                              key={link}
                            >
                              {link.split("/").pop().split(".")[0]}
                              {"  "}
                            </a>
                          );
                        })}
                      </Grid>
                    ) : null}
                  </CardContent>
                </Card>
              );
            })}
        </Grid>

        <Grid item>
          <Grid data-intercom-target="add-new-comment">
            <Typography className={classes.addText}>
              <Trans>Add a new comment</Trans>
            </Typography>
            <TextField
              value={this.state.addComment ? this.state.addComment : ""}
              multiline
              variant="outlined"
              margin="normal"
              classes={{ root: classes.bgWhite }}
              placeholder={i18n.t("Write your comment...")}
              fullWidth
              onChange={(e) => {
                this.handleInput(e, "addComment");
              }}
              InputProps={{
                classes: {
                  input: classes.inputComment,
                },
              }}
            />

            {/* File attachment */}
            {this.state.fileAttached && (
              <Grid style={{ width: "200px" }}>
                <CustomChip
                  onDelete={this.handleDelete}
                  variant="outlined"
                  name={this.state.fileAttached.name}
                />
              </Grid>
            )}
          </Grid>

          <Grid
            container
            justifyContent="flex-start"
            alignItems="center"
            style={{ marginBottom: "24px" }}
          >
            <Button
              variant="contained"
              className={classes.postButton}
              classes={{ label: classes.postLabel }}
              onClick={() => {
                return this.postComment();
              }}
            >
              <Trans>Post Comment</Trans>
            </Button>

            <FormControl
              variant="outlined"
              data-intercom-target="comment-image-upload"
            >
              <Select
                value={"file1"}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  classes: { paper: classes.blockMenu },
                }}
                onChange={(e) => {
                  return this.handleUpload(e);
                }}
                className={classes.selectBlock}
              >
                <MenuItem
                  ListItemClasses={{
                    root: classes.blockList,
                    selected: classes.selectedList,
                  }}
                  value="file1"
                  disabled
                  style={{ display: "none" }}
                >
                  <Trans>Upload an Image or File</Trans>
                </MenuItem>

                {uploadFileOptions.map((fileData) => {
                  return (
                    <MenuItem
                      ListItemClasses={{
                        root: classes.blockList,
                        selected: classes.selectedList,
                      }}
                      classes={{ root: classes.fileList }}
                      value={fileData.value}
                      key={fileData.value}
                    >
                      <label className={classes.fileLabel} htmlFor="fileUpload">
                        {/* File icon added */}
                        {fileData.value === "el_image" ? (
                          <img
                            alt="elImg"
                            className={classes.uploadTypeImg}
                            src={elImage}
                          />
                        ) : fileData.value === "handheld_image" ? (
                          <img
                            alt="HandheldImg"
                            className={classes.uploadTypeImg}
                            src={handledImg}
                          />
                        ) : fileData.value === "iv_data" ? (
                          <img
                            alt="ivImg"
                            className={classes.uploadTypeImg}
                            src={ivDataImg}
                          />
                        ) : (
                          <img
                            alt="fileImg"
                            className={classes.uploadTypeImg}
                            src={fileImg}
                          />
                        )}{" "}
                        {i18n.t(fileData.name)}{" "}
                      </label>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            {/* EL Image */}
            <input
              className={classes.fileInput}
              accept=".pdf, .csv, .doc, .docx, .png, .jpg, .jpeg, .zip, .rar"
              id="fileUpload"
              type="file"
              onChange={(e) => {
                this.uploadFileChange(e);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

//calling redux to get the product information (pro/basic) and selected language
const mapStateToProps = (state) => {
  return {
    product: state.product,
    language: state.language.selectedLanguage,
  };
};

export default connect(mapStateToProps)(
  withStyles(styles)(withTranslation()(Comments))
);
