import { Grid, Typography, withStyles } from "@material-ui/core";

import ChangeLanguageComp from "./changeLanguageComp";
import CustomNavlink from "./CustomNavlink";
import ProSidebarLink from "../../views/product/ProSidebarLink";
import { Skeleton } from "@material-ui/lab";
import i18n from "i18next";
import { sidebarItems } from "../../constants/sidebarConstant";
import styles from "./sidebar.style";

const SidebarPagesLinks = ({
  setOpen,
  siteListData,
  product_type,
  onHandleShowTrial,
  onHandleShowPlans,
  path,
  isactivePath,
  onClickHandler,
  classes,
  activeLinkColor,
  loggedInUser,
  language,
  logOut,
  setSelectedLanguage,
  changeLanguage,
}) => {
  const updateMenuName = (val) => {
    // Get loggedIn user name
    let getUserName =
      loggedInUser && loggedInUser.user_profile
        ? `${loggedInUser.user_profile.first_name} ${loggedInUser.user_profile.last_name}`
        : null;

    switch (val) {
      case "MyProfile":
        return getUserName;
      default:
        return val;
    }
  };

  const myProfileFunction = () => {
    // Get loggedIn user name
    let getUserName =
      loggedInUser && loggedInUser.user_profile
        ? `${loggedInUser.user_profile.first_name.charAt(
            0
          )}${loggedInUser.user_profile.last_name.charAt(0)}`
        : null;

    return (
      <div
        className={classes.sidebarOpenProfileStyles}
        style={{
          background: path === "/MyProfile/" ? "#ffffff" : "#8D95A2",
          color: path === "/MyProfile/" ? "#8D95A2" : "#fff",
        }}
      >
        {getUserName}
      </div>
    );
  };

  return (
    <>
      <Grid container xs className={classes.sideBarHeight}>
        <div
          style={{
            marginLeft: setOpen ? "8px" : "",
          }}
        >
          {siteListData ? (
            product_type === "Pro" ? (
              <Typography
                className={classes.proLabel}
                style={{
                  marginTop: !setOpen && "-6px",
                  marginLeft: setOpen && "-130px",
                }}
              >
                PRO PLAN
              </Typography>
            ) : (
              <ProSidebarLink
                setOpen={setOpen}
                onHandleShowTrial={onHandleShowTrial}
                onHandleShowPlans={onHandleShowPlans}
              />
            )
          ) : (
            <Skeleton
              variant="text"
              height={30}
              width={70}
              style={{
                marginLeft: !setOpen ? "15px" : "10px",
                backgroundColor: "grey",
              }}
            />
          )}

          {sidebarItems.map((item) => {
            const roleType = sessionStorage.getItem("userRole");
            if (
              (item.title === "Users" || item.title === "Configurations") &&
              roleType === "client:user"
            ) {
              return "";
            }

            // handle users and configurations page visiblility
            let isLoggedWithUser = false;
            let isLoggedWithSiteAdmin = false;
            if (
              roleType === "client:users" &&
              (item.title === "Users" || item.title === "Configurations")
            ) {
              return (isLoggedWithUser = true);
            }

            if (item.title === "Configurations" && roleType === "site:admin") {
              return (isLoggedWithSiteAdmin = true);
            }

            return (
              <>
                {item.title !== "Language" ? (
                  <div key={item.title}>
                    {(!isLoggedWithUser || !isLoggedWithSiteAdmin) && (
                      <CustomNavlink
                        siteListData={siteListData}
                        className={
                          item.title === "Site"
                            ? path.includes(item.path) ||
                              path.includes(item.inspectionPath)
                              ? classes.linkStylesActive
                              : classes.linkStylesForSites
                            : path.includes(item.path)
                            ? item.title === "logout"
                              ? classes.sidebarmenu
                              : classes.linkStylesActive
                            : classes.linkStyles
                        }
                        outerDiv={classes.defSidebar}
                        name={updateMenuName(item.title)}
                        path={
                          item.title === "Anomalies"
                            ? `${item.path}/${
                                siteListData &&
                                siteListData.length &&
                                siteListData[0].plant_id
                              }`
                            : item.title === "Log Out"
                            ? ""
                            : item.path
                        }
                        isactivePath={
                          path
                            ? path.includes(item.path) ||
                              (item.title === "Site" &&
                                path.includes(item.inspectionPath))
                            : item.title === "logout"
                            ? ""
                            : isactivePath
                        }
                        onClickHandler={
                          item.title === "Log Out" ? logOut : onClickHandler
                        }
                        setOpen={setOpen}
                        styles={{ color: activeLinkColor }}
                        Icon={
                          item.title === "MyProfile" ? (
                            myProfileFunction()
                          ) : (
                            <img
                              src={
                                item.title === "Log Out"
                                  ? item.icon && item.icon.active
                                  : path.includes(item.path) ||
                                    (item.title === "Site" &&
                                      path.includes(item.inspectionPath))
                                  ? item.icon && item.icon.active
                                  : item.icon && item.icon.inActive
                              }
                              alt={item.title}
                            />
                          )
                        }
                        title={i18n.t(item.title)}
                      />
                    )}
                  </div>
                ) : item.title === "Language" ? (
                  <ChangeLanguageComp
                    setSelectedLanguage={setSelectedLanguage}
                    changeLanguage={changeLanguage}
                    language={language}
                    setOpen={setOpen}
                    classes={classes}
                    item={item}
                    path={path}
                  />
                ) : (
                  ""
                )}
              </>
            );
          })}
        </div>
      </Grid>
    </>
  );
};

export default withStyles(styles)(SidebarPagesLinks);