/* eslint-disable quotes */

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";

import { Bar } from "react-chartjs-2";
import DownloadIcon from "../../../../components/assets/img/Vector.svg";
import ProBlur from "../../../product/ProBlur";
import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import i18n from "i18next";
import { withTranslation } from "react-i18next";

// import { useLocation } from "react-router-dom";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  PointElement,
  Tooltip,
  Legend
);

let newLabel = [];
let newDataset = [];

//anomalies category bar chart
class StackColumnChart extends React.Component {
  state = {
    allData: null,
    allLabel: null,
    labels: null,
    datasets: null,
    constData: null,
  };

  //Create chart data on the initial render
  componentDidMount() {
    // const location = useLocation();

    localStorage.setItem("path", window.location.pathname.includes("sites"));
    this.callChartData();
  }

  //Update chart data on the subsequent renders
  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.chartData, this.props.chartData)) {
      this.callChartData();
    }
    newLabel = [];
    newDataset = [];

    if (prevProps.language !== this.props.language) {
      this.state.allData.labels.map((label) => {
        const newVal = i18n.t(label);
        newLabel.push(newVal);
        return newLabel;
      });

      this.state.constData.map((dataset) => {
        const newVal = {
          backgroundColor: "",
          data: [],
          hoverBackgroundColor: "",
          label: "",
          maxBarThickness: "",
        };
        newVal.backgroundColor = dataset.backgroundColor;
        newVal.data = dataset.data;
        newVal.hoverBackgroundColor = dataset.hoverBackgroundColor;
        newVal.label = i18n.t(dataset.label);
        newVal.maxBarThickness = dataset.maxBarThickness;
        newDataset.push(newVal);
        return newDataset;
      });

      // skipcq Avoid usage of setState in componentDidUpdate JS-0443
      this.setState({ labels: newLabel, datasets: newDataset });
    }
  }

  //Handles chart data creation
  callChartData() {
    // if (prevProps.getChartData !== this.props.getChartData) {
    if (this.props.chartData) {
      const allLabel = [];

      const allChartLoop = this.props.chartData;
      const chartType = this.props.dataType;

      let defectData = {};
      let defectColor = {};

      //Chart labels
      if (chartType === "MWp") {
        allChartLoop.site_anomalies.forEach(({ defect_type_list }) => {
          defect_type_list.forEach(({ name, color }) => {
            defectData = {
              ...defectData,
              [name]: [],
            };
            defectColor = {
              ...defectColor,
              [name]: color,
            };
          });
        });

        //Chart data processing
        allChartLoop.site_anomalies.forEach(
          ({ defect_type_list, plant_name, section_name }) => {
            if (plant_name) allLabel.push(plant_name);
            else allLabel.push(section_name);

            const sortedList = _.orderBy(defect_type_list, ["name"], ["asc"]);
            Object.keys(defectData).forEach((defName) => {
              let count = 0;
              if (
                sortedList.find((defData) => {
                  return defData.name === defName;
                })
              ) {
                sortedList.forEach((defData) => {
                  if (defData.name === defName) count = defData.count;
                });
              }

              const newData = [...defectData[defName], count];
              defectData = {
                ...defectData,
                [defName]: newData,
              };
            });
          }
        );
      } else if (chartType === "Status") {
        allChartLoop &&
          allChartLoop.site_status &&
          allChartLoop.site_status.map((site) => {
            return site.status_metrics.forEach((item) => {
              if (item.name === "pending" || item.name === "Pending")
                item.name = "Pending";
              else if (
                item.name === "inprogress" ||
                item.name === "In Progress"
              )
                item.name = "In Progress";
              else if (item.name === "resolved" || item.name === "Resolved")
                item.name = "Resolved";
              else if (item.name === "notfound" || item.name === "Not Found")
                item.name = "Not Found";
              else item.name = "False +Ve";
            });
          });
        allChartLoop &&
          allChartLoop.site_status &&
          allChartLoop.site_status.forEach(({ status_metrics }) => {
            status_metrics &&
              status_metrics.forEach(({ name, color }) => {
                defectData = {
                  ...defectData,
                  [name]: [],
                };
                if (color !== null) {
                  defectColor = {
                    ...defectColor,
                    [name]: color,
                  };
                }
              });
          });

        allChartLoop &&
          allChartLoop.site_status &&
          allChartLoop.site_status.forEach(
            ({ status_metrics, plant_name, section_name }) => {
              if (plant_name) allLabel.push(plant_name);
              else allLabel.push(section_name);

              const sortedMetrics = _.orderBy(
                status_metrics,
                ["name"],
                ["asc"]
              );
              Object.keys(defectData).forEach((defName) => {
                let count = 0;
                if (
                  sortedMetrics.find((defData) => {
                    return defData.name === defName;
                  })
                ) {
                  sortedMetrics.forEach((defData) => {
                    if (defData.name === defName) count = defData.count;
                  });
                }

                const newData = [...defectData[defName], count];
                defectData = {
                  ...defectData,
                  [defName]: newData,
                };
              });
            }
          );
      } else if (chartType === "SectionAnomalies") {
        allChartLoop.section_anomalies.forEach(({ defect_type_list }) => {
          defect_type_list.forEach(({ name, color }) => {
            defectData = {
              ...defectData,
              [name]: [],
            };
            if (color !== null) {
              defectColor = {
                ...defectColor,
                [name]: color,
              };
            }
          });
        });

        allChartLoop &&
          allChartLoop.section_anomalies &&
          allChartLoop.section_anomalies.forEach(
            ({ defect_type_list, plant_name, section_name }) => {
              if (plant_name) allLabel.push(plant_name);
              else allLabel.push(section_name);

              const sortedList = _.orderBy(defect_type_list, ["name"], ["asc"]);
              Object.keys(defectData).forEach((defName) => {
                let count = 0;
                if (
                  sortedList.find((defData) => {
                    return defData.name === defName;
                  })
                ) {
                  sortedList.forEach((defData) => {
                    if (defData.name === defName) count = defData.count;
                  });
                }

                const newData = [...defectData[defName], count];
                defectData = {
                  ...defectData,
                  [defName]: newData,
                };
              });
            }
          );
      } else if (chartType === "SectionStatus") {
        allChartLoop &&
          allChartLoop.section_status &&
          allChartLoop.section_status.map((section) => {
            return section.status_metrics.forEach((item) => {
              if (item.name === "pending" || item.name === "Pending")
                item.name = "Pending";
              else if (
                item.name === "inprogress" ||
                item.name === "In Progress"
              )
                item.name = "In Progress";
              else if (item.name === "resolved" || item.name === "Resolved")
                item.name = "Resolved";
              else if (item.name === "notfound" || item.name === "Not Found")
                item.name = "Not Found";
              else item.name = "False +Ve";
            });
          });
        allChartLoop.section_status.forEach(({ status_metrics }) => {
          status_metrics.forEach(({ name, color }) => {
            defectData = {
              ...defectData,
              [name]: [],
            };
            if (color !== null) {
              defectColor = {
                ...defectColor,
                [name]: color,
              };
            }
          });
        });

        allChartLoop &&
          allChartLoop.section_status &&
          allChartLoop.section_status.forEach(
            ({ status_metrics, plant_name, section_name }) => {
              if (plant_name) allLabel.push(plant_name);
              else allLabel.push(section_name);

              const sortedMetrics = _.orderBy(
                status_metrics,
                ["name"],
                ["asc"]
              );
              Object.keys(defectData).forEach((defName) => {
                let count = 0;
                if (
                  sortedMetrics.find((defData) => {
                    return defData.name === defName;
                  })
                ) {
                  sortedMetrics.forEach((defData) => {
                    if (defData.name === defName) count = defData.count;
                  });
                }

                const newData = [...defectData[defName], count];
                defectData = {
                  ...defectData,
                  [defName]: newData,
                };
              });
            }
          );
      }

      const dataset = Object.entries(defectData).map((anomaly) => {
        return {
          label: anomaly[0],
          backgroundColor: defectColor[anomaly[0]],
          hoverBackgroundColor: defectColor[anomaly[0]],
          data: anomaly[1],
          maxBarThickness: 40,
        };
      });
      const currState = {
        labels: allLabel,
        fontSize: "15px",
        datasets: dataset,
      };

      this.setState({
        allData: currState,
        allLabel,
        constData: dataset,
      });

      currState.labels.map((label) => {
        const newVal = i18n.t(label);
        newLabel.push(newVal);
        return newLabel;
      });

      currState.datasets.map((getdataset) => {
        const newVal = {
          backgroundColor: "",
          data: [],
          hoverBackgroundColor: "",
          label: "",
          maxBarThickness: "",
        };
        newVal.backgroundColor = getdataset.backgroundColor;
        newVal.data = getdataset.data;
        newVal.hoverBackgroundColor = getdataset.hoverBackgroundColor;
        newVal.label = i18n.t(getdataset.label);
        newVal.maxBarThickness = getdataset.maxBarThickness;
        newDataset.push(newVal);
        return newDataset;
      });

      this.setState({ labels: newLabel, datasets: newDataset });

      //Download Chart Image
      document.getElementById("download").addEventListener("click", () => {
        /*Get image of canvas element*/
        const url_base64jp = document
          .getElementById("barChart")
          .toDataURL("image/jpg");
        /*get download button (tag: <a></a>) */
        const downloadButton = document.getElementById("download");
        /*insert chart image url to download button (tag: <a></a>) */
        downloadButton.href = url_base64jp;
      });
    }
  }

  //Handles update of chart width on window resize
  addWidth = () => {
    if (this.state.allLabel && window.innerWidth <= 760) {
      if (this.state.allLabel.length > 15) return 1000;
      else if (this.state.allLabel.length > 8) return 700;
      else if (this.state.allLabel.length > 5) return 500;

      return 356;
    }
    return false;
  };

  //Renders the stacked column chart
  render() {
    //Chart styles
    const styles = {
      iconBg: {
        border: "1px solid #DDE5EC",
        boxSizing: "border-box",
        borderRadius: 8,
        padding: "7px 9px",
        position: "absolute",
        right: 15,
        top: 15,
        width: 33,
        height: 33,
        zIndex: 2,
        visibility: `${
          this.state.allData &&
          this.state.allData.datasets.length &&
          this.props.product &&
          this.props.product.product_type !== "Basic"
            ? "visible"
            : "hidden"
        }`,
      },
    };

    //For setting custom tooltip position.
    Tooltip.positioners.myCustomPositioner = function (items) {
      const pos = Tooltip.positioners.average(items);
      return {
        x:
          pos.x > 750 && window.innerWidth > 1050
            ? 880
            : pos.x > 630 && window.innerWidth < 1050
            ? 620
            : window.innerWidth < 450
            ? 130
            : pos.x,
        y: pos.y,
        xAlign: "center",
        yAlign: "bottom",
      };
    };

    //tooltip card
    const getOrCreateTooltip = (chart) => {
      let tooltipEl = chart.canvas.parentNode.querySelector("div");

      if (!tooltipEl) {
        //tooltip element
        tooltipEl = document.createElement("div");
        tooltipEl.style.background = "#FFFFFF";
        tooltipEl.style.borderRadius = "5px";
        tooltipEl.style.border = "1px solid #E4EAF5";
        tooltipEl.style.boxShadow = "0px 1px 2px rgba(0, 0, 0, 0.05)";
        tooltipEl.style.color = "black";
        tooltipEl.style.opacity = 1;
        tooltipEl.style.pointerEvents = "none";
        tooltipEl.style.position = "absolute";
        tooltipEl.style.transform = "translate(-50%, 0)";
        tooltipEl.style.transition = "all .1s ease";
        tooltipEl.style.width = "300px";
        tooltipEl.style.maxWidth = "350px";
        tooltipEl.style.marginLeft = "50px";

        const table = document.createElement("table");
        table.style.margin = "0px";

        tooltipEl.appendChild(table);
        chart.canvas.parentNode.appendChild(tooltipEl);
      }

      return tooltipEl;
    };

    //custom tooltip handler
    const externalTooltipHandler = (context) => {
      // Tooltip Element
      const { chart, tooltip } = context;
      const tooltipEl = getOrCreateTooltip(chart);

      // Hide if no tooltip
      if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
      }

      // Set Text
      if (tooltip.body) {
        const titleLines = tooltip.title || [];
        const bodyLines = tooltip.body.map((b) => b.lines);

        const tableHead = document.createElement("thead");

        //set title of the tooltip
        titleLines.forEach((title) => {
          const tr = document.createElement("tr");
          tr.style.borderWidth = 0;

          const th = document.createElement("th");
          th.style.borderWidth = 0;
          const text = document.createTextNode(title);

          th.appendChild(text);
          tr.appendChild(th);
          tableHead.appendChild(tr);
        });

        //created table body
        const tableBody = document.createElement("tbody");

        //getting table data
        bodyLines.forEach((body, i) => {
          const bodySplit = body[0]?.toString().split("-");
          const colors = tooltip.labelColors[i];

          //span and style
          const span = document.createElement("span");
          span.style.borderRadius = "50%";
          span.style.background = colors.backgroundColor;
          span.style.borderColor = colors.borderColor;
          span.style.borderWidth = "2px";
          span.style.marginRight = "10px";
          span.style.height = "6px";
          span.style.width = "6px";
          span.style.display = "inline-block";

          //table row and style
          const tr = document.createElement("tr");
          tr.style.backgroundColor = "white";
          tr.style.borderWidth = 0;
          tr.style.marginBottom = 10;
          tr.style.width = "300px";

          //data element and styles
          const td = document.createElement("td");
          td.style.borderWidth = 0;
          td.style.fontFamily = "Montserrat";
          td.style.fontStyle = "normal";
          td.style.fontWeight = 500;
          td.style.fontSize = "12px";
          td.style.lineHeight = "18px";
          td.style.color = "#536471";
          td.style.width = "300px";
          td.style.marginTop = "20px";

          if (body[0]) {
            const text = document.createTextNode(bodySplit[0]);

            //value element and style
            const value = document.createElement("span");
            value.textContent = bodySplit[1];
            value.style.textAlign = "right";
            value.style.marginBottom = "-50px";
            value.style.color = "#536471";
            value.style.fontFamily = "Montserrat";
            value.style.fontStyle = "normal";
            value.style.fontWeight = 500;
            value.style.fontSize = "12px";
            value.style.lineHeight = "18px";

            td.appendChild(span);
            td.appendChild(text);
            tr.appendChild(td);
            tr.appendChild(value);
            tableBody.appendChild(tr);
          }
        });

        const tableRoot = tooltipEl.querySelector("table");

        // Remove old children
        while (tableRoot.firstChild) {
          tableRoot.firstChild.remove();
        }

        // Add new children
        tableRoot.appendChild(tableHead);
        tableRoot.appendChild(tableBody);
      }

      const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

      // Display, position, and set styles for font
      tooltipEl.style.opacity = 1;
      tooltipEl.style.left = positionX + tooltip.caretX + "px";
      tooltipEl.style.top = positionY + tooltip.caretY + "px";
      tooltipEl.style.font = tooltip.options.bodyFont.string;
      tooltipEl.style.padding =
        tooltip.options.padding + "px " + tooltip.options.padding + "px";
    };

    // const location = useLocation();

    //bar chart options
    const options = {
      animation: {
        duration: 0,
      },
      maintainAspectRatio: false,
      events: ["mouseout", "mousemove"],
      title: {
        display: true,
        fontSize: 12,
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
          grid: {
            drawTicks: false,
          },
          border: {
            display: false,
            dash: [0, 9],
          },
          ticks: {
            font: {
              family: "Montserrat",
              style: "normal",
              color: "#536471",
              size: "11px",
              weight: 500,
              lineHeight: "15px",
            },
            maxRotation: 30,
            minRotation: 30,
            drawOnChartArea: true,
          },
        },
        y: {
          stacked: true,
          border: {
            dash: [8, 4],
            display: false,
            color: "#E4EAF5",
          },
          ticks: {
            font: {
              family: "Montserrat",
              style: "normal",
              color: "#536471",
              size: "11px",
              weight: 500,
              lineHeight: "15px",
            },
          },
          title: {
            display: true,
            text: window.location.pathname.includes("sites")
              ? this.props.dataType === "SectionAnomalies"
                ? i18n.t("Anomalies")
                : i18n.t("Anomaly Status")
              : this.props.dataType === "MWp"
              ? i18n.t("Anomalies/MWp")
              : i18n.t("Anomaly Status"),
            font: {
              family: "Montserrat",
              style: "normal",
              color: "#536471",
              size: "11px",
              weight: 500,
              lineHeight: "15px",
            },
          },
        },
      },
      plugins: {
        datalabels: {
          display: false,
        },
        legend: {
          display: true,
          position: "bottom",
          labels: {
            usePointStyle: true,
            borderWidth: 1,
            pointRadius: 1,
            font: {
              family: "Montserrat",
              style: "normal",
              color: "#536471",
              size: "11px",
              weight: 500,
              lineHeight: "15px",
            },
            boxHeight: 4.5,
            pointStyleWidth: 6,
          },
        },
        tooltip: {
          yAlign: "bottom",
          mode: "index",
          titleColor: "#536471",
          titleFontSize: 12,
          titleFontFamily: "Montserrat",
          titleFontStyle: "normal",
          fullWidth: true,
          tooltipFontSize: 10,
          titleMarginBottom: 15,
          titleSpacing: 25,
          titleAlign: "center",
          titleFontColor: "#536471",
          bodyAlign: "left",
          bodyFontSize: 12,
          bodySpacing: 15,
          bodyFontFamily: "Montserrat",
          bodyFontColor: "#536471",
          yPadding: 15,
          xPadding: 20,
          cornerRadius: 5,
          caretSize: 10,
          caretX: 20,
          borderColor: "#ccc",
          borderWidth: 1,
          footerSpacing: 6,
          backgroundColor: "white",
          enabled: false,
          usePointStyle: true,
          position: "myCustomPositioner",
          external: externalTooltipHandler,

          callbacks: {
            label: function (context) {
              if (context.parsed.y !== 0) {
                return `${context.dataset.label}-${context.parsed.y}`;
              }
            },
          },
        },
      },
    };

    //rendering stack column for internal benchmark and anomaliesVsSection in sites.
    return (
      <div style={{ minWidth: this.addWidth() }}>
        <a
          id="download"
          download="ChartImage.jpg"
          href="#downloadChartImg"
          style={styles.iconBg}
          title="Download Charts"
        >
          <img src={DownloadIcon} alt="downloadChartImg" />
        </a>
        <ProBlur
          onHandleShowPlans={this.props.onHandleShowPlans}
          text="Compare your sites performance using premium filters IEC CoA, Brands & Models, Panel Type, Inspection Type and Anomaly Status."
          data-intercom-target="internal-benchmark"
        >
          {this.state.allData && (
            <Bar
              id="barChart"
              data={{
                labels: this.state.labels,
                datasets: this.state.datasets,
                fontSize: this.state.allData.fontsize,
              }}
              height={450}
              options={options}
            />
          )}
        </ProBlur>
      </div>
    );
  }
}

//Calling redux to get information about the product (basic/pro) and the selected language
const mapStateToProps = (state) => {
  return {
    product: state.product,
    language: state.language.selectedLanguage,
  };
};

export default connect(mapStateToProps)(withTranslation()(StackColumnChart));
