import { Button, Divider, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Trans, withTranslation } from "react-i18next";

import Config from "../../../config";
import CustomTooltip from "../customStyle/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import NewLoader from "../../../layout/newLoader";
import _ from "lodash";
import { allRequestHandler } from "../../../api";
import i18n from "i18next";

const requestUrl = Config.hostName;

//Inspection details
function InspectionDetails(props) {
  const [details, setDetails] = useState(null);
  const [current, setCurrent] = useState(0);

  //Update details on the change of inspection
  useEffect(() => {
    if (details && details.inspection_list && details.inspection_list.length)
      setCurrent(details.inspection_list.length - 1);
  }, [details]);

  //get inspection details
  async function getData() {
    const getInspList = await allRequestHandler({
      requestType: "GET",
      requestUrl: `${requestUrl}${props.reference}?days=${props.duration}`,
    });
    const sortDate = _.sortBy(getInspList.inspection_list, "inspection_date");
    const sortedObj = { inspection_list: sortDate };
    setDetails(sortedObj);
  }

  //Get inspection data
  useEffect(() => {
    getData();
  }, [props.duration]);

  const { classes } = props;

  return (
    <div className={classes.inspDetailsBox}>
      <div className={classes.inspDateHeader}>
        {details && details.inspection_list
          ? details.inspection_list.map((insp, index) => {
              return (
                <span key={insp.inspection_date}>
                  <Button
                    onClick={() => {
                      setCurrent(index);
                    }}
                    className={classes.dataTab}
                    style={{ color: current === index && "#5B4DD3" }}
                  >
                    {Intl.DateTimeFormat("en-US", {
                      day: "2-digit",
                      month: "short",
                      year: "numeric",
                    }).format(new Date(insp.inspection_date))}
                  </Button>
                  <div
                    style={{
                      height: "2px",
                      background: current === index ? "#5B4DD3" : "#fff",
                    }}
                  />
                </span>
              );
            })
          : null}
      </div>

      <Grid
        container
        alignItems="center"
        dividers
        className={props.classes.headerContent2}
      >
        <Grid item xs={3} className={props.classes.left}>
          <Typography className={classes.heavy}>
            <Trans>Anomaly Name</Trans>
          </Typography>
        </Grid>
        <Grid item xs className={props.classes.right}>
          <Grid container alignItems="center">
            <Typography className={classes.heavy}>
              <Trans>Anomalies</Trans>
            </Typography>
            <CustomTooltip
              title={i18n.t(
                "The total number of identified defects contributing towards energy and revenue losses."
              )}
            >
              <InfoIcon style={{ color: "#C7CEDB" }} />
            </CustomTooltip>
          </Grid>
        </Grid>
        <Grid item xs className={props.classes.right}>
          <Grid container alignItems="center">
            <Typography className={classes.heavy}>
              <Trans>Affected DC</Trans>
            </Typography>
            <CustomTooltip
              title={i18n.t(
                "Affected DC (Power loss) is the total power that is not contributing towards energy generation due to the associated anomaly."
              )}
            >
              <InfoIcon style={{ color: "#C7CEDB" }} />
            </CustomTooltip>
          </Grid>
        </Grid>
        <Grid item xs className={props.classes.right}>
          <Grid container alignItems="center">
            <Typography className={classes.heavy}>
              <Trans>Affected DC %</Trans>
            </Typography>
            <CustomTooltip
              title={i18n.t(
                "Percentage of total DC capacity that is not contributing towards energy generation due to the associated anomaly."
              )}
            >
              <InfoIcon style={{ color: "#C7CEDB" }} />
            </CustomTooltip>
          </Grid>
        </Grid>

        <Grid item xs className={props.classes.right}>
          <Typography className={classes.heavy}>
            <Trans>Energy Loss</Trans>
          </Typography>
        </Grid>
        <Grid item xs className={props.classes.right}>
          <Typography className={classes.heavy}>
            <Trans>Revenue Loss</Trans>
          </Typography>
        </Grid>
        <Grid item xs className={props.classes.right}>
          <Typography className={classes.heavy}>
            <Trans>Anomalies Resolved</Trans>
          </Typography>
        </Grid>
      </Grid>
      <Divider />

      {details?.inspection_list?.[current]?.anomaly_list ? (
        details.inspection_list[current].anomaly_list.map((list) => {
          return (
            <React.Fragment key={list.anomaly_name}>
              <Grid container dividers className={props.classes.mainContent}>
                <Grid item xs={3} className={props.classes.left}>
                  <Typography>
                    <Trans>{list.anomaly_name}</Trans>
                  </Typography>
                </Grid>
                <Grid item xs className={props.classes.right}>
                  <div style={{ marginRight: "66px" }}>
                    <Typography>
                      {Intl.NumberFormat("en").format(list.anomalies_count)}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs className={props.classes.right}>
                  <div style={{ marginRight: "56px" }}>
                    <Typography>
                      {list.affected_dc ? (
                        <>{props.convertLoss(list.affected_dc, "power")}</>
                      ) : (
                        <>0KWp</>
                      )}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs className={props.classes.right}>
                  <div style={{ marginRight: "42px" }}>
                    <Typography>
                      {list.affected_dc_percentage ? (
                        <span>{list.affected_dc_percentage}</span>
                      ) : (
                        <span>0</span>
                      )}
                      %
                    </Typography>
                  </div>
                </Grid>

                <Grid item xs className={props.classes.right}>
                  <Typography>
                    {list.energy_loss ? (
                      <span>
                        {props.convertLoss(list.energy_loss, "energy")}
                      </span>
                    ) : (
                      <span>--</span>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs className={props.classes.right}>
                  <Typography>
                    {list.revenue_loss ? (
                      <>
                        {props.currency}
                        {Intl.NumberFormat("en").format(
                          list.revenue_loss.toFixed(2)
                        )}
                      </>
                    ) : (
                      <>--</>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs className={props.classes.right}>
                  <Typography>
                    {list.anomalies_resolved_count ? (
                      <>
                        {Intl.NumberFormat("en").format(
                          list.anomalies_resolved_count
                        )}
                      </>
                    ) : (
                      <>0</>
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
            </React.Fragment>
          );
        })
      ) : (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ minHeight: 400 }}
        >
          <NewLoader />
        </Grid>
      )}

      {details?.inspection_list?.[current]?.anomaly_list ? (
        <Grid container dividers className={classes.mainContent2}>
          <Grid item xs={3} className={props.classes.left}>
            <Typography className={classes.heavy}>
              <Trans>Total</Trans>
            </Typography>
          </Grid>
          <Grid item xs className={props.classes.right}>
            <div style={{ marginRight: "66px" }}>
              <Typography className={classes.heavy}>
                {Intl.NumberFormat("en").format(
                  details.inspection_list[current].total_anomalies_count
                )}
              </Typography>
            </div>
          </Grid>
          <Grid item xs className={props.classes.right}>
            <div style={{ marginRight: "56px" }}>
              <Typography className={classes.heavy}>
                {details.inspection_list[current].total_affected_dc ? (
                  <>
                    {props.convertLoss(
                      details.inspection_list[current].total_affected_dc,
                      "power"
                    )}
                  </>
                ) : (
                  <>--</>
                )}
              </Typography>
            </div>
          </Grid>
          <Grid item xs className={props.classes.right}>
            <div style={{ marginRight: "42px" }}>
              <Typography className={classes.heavy}>
                {details.inspection_list[
                  current
                ].total_affected_dc_percentage.toFixed(2)}
                %
              </Typography>
            </div>
          </Grid>

          <Grid item xs className={props.classes.right}>
            {/*varies with duration */}
            <Typography className={classes.heavy}>
              {details.inspection_list[current].total_energy_loss ? (
                <>
                  {props.convertLoss(
                    details.inspection_list[current].total_energy_loss,
                    "energy"
                  )}
                </>
              ) : (
                <>--</>
              )}
            </Typography>
          </Grid>
          <Grid item xs className={props.classes.right}>
            <Typography>
              {details.inspection_list[current].total_revenue_loss ? (
                <div className={classes.heavy}>
                  {props.currency} &nbsp;
                  {Intl.NumberFormat("en").format(
                    details.inspection_list[current].total_revenue_loss.toFixed(
                      2
                    )
                  )}
                </div>
              ) : (
                <>--</>
              )}
            </Typography>
          </Grid>
          <Grid item xs className={props.classes.right}>
            <Typography className={classes.heavy}>
              {Intl.NumberFormat("en").format(
                details.inspection_list[current].total_anomalies_resolved
              )}
            </Typography>
            {details.inspection_list[current]
              .total_anomalies_resolved_percent && (
              <Typography className={classes.greenPercent}>
                {details.inspection_list[
                  current
                ].total_anomalies_resolved_percent.toFixed(2)}{" "}
                %
              </Typography>
            )}
          </Grid>

          <Divider />
        </Grid>
      ) : null}
    </div>
  );
}

export default withTranslation()(InspectionDetails);
