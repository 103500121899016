import {
  AppBar,
  Card,
  CardContent,
  Divider,
  Grid,
  Hidden,
  Snackbar,
  Toolbar,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, { Component } from "react";
import { Trans, withTranslation } from "react-i18next";

import AnomaliesChartCard from "../common/anomaliesChartCard";
import Config from "../../config";
import DownloadReport from "./downloadReport";
import InspectionTable from "./InspectionTable";
import InternalBenchmark from "./InternalBenchmark";
import MetadataBox from "../common/MetadataBox/index";
import MuiAlert from "@material-ui/lab/Alert";
import { Navigate } from "react-router-dom";
import PowerLoss from "./powerLoss";
import ReactGA from "react-ga";
import { SET_SELECTEDLANGUAGE } from "../../constants/actionTypes";
import _ from "lodash";
import { allRequestHandler } from "../../api";
import { connect } from "react-redux";
import styles from "./dashboard.styles";

const requestUrl = Config.hostName;

//dashboard page
class Dashboard extends Component {
  state = {
    anomalies_metrics: null,
    inspections_table: null,
    maxAnomalies: 0,
    anomalies_metrics_filter_options: [],
    losses_filter_options: [],
    showSnackbar: false,
    powerLossData: null,
  };

  //These functions are called on the first render of the page
  componentDidMount() {
    // For Google Analytics
    ReactGA.pageview(window.location.pathname);
    try {
      // Default Download Report
      // const filterSite = this.props.userDetails;
      // this.setState({
      //   downloadList: filterSite && filterSite.plant_list,
      // });
    } catch (error) {
      this.props.history.push("/");
    }

    //On entering dashboard page, call api to get required data
    if (this.props.dashboardUrl) {
      this.callApi();
    }

    //On entering dashboard page, call api to get required data
    this.getLossesData();
  }

  //These functions are called whenever the props or the states are updated
  componentDidUpdate(prevProps) {
    if (
      !_.isEqual(this.props.dashboardUrl, prevProps.dashboardUrl) &&
      this.props.dashboardUrl
    )
      this.callApi();
  }

  //API call to get the loss recovered chart-data
  getLossesData = async () => {
    const lossChartData = await allRequestHandler({
      requestType: "GET",
      requestUrl: `${requestUrl}/api/dashboard/charts/losses_recovered`,
    });
    if (lossChartData) {
      this.setState({
        powerLossData: lossChartData,
        losses_filter_options: lossChartData.filter_options,
      });
    }
  };

  //API call to get the data for the dashboard graphs and tables
  callApi = () => {
    let apiData = 0;
    this.props.dashboardUrl.map(async (loopCall) => {
      if (loopCall.name === "portfolio_benchmark") {
        loopCall.href_list.map(async (by) => {
          apiData = await allRequestHandler({
            requestType: "GET",
            requestUrl: requestUrl + by.href,
          });

          if (apiData && apiData.status && apiData.status === 401) {
            sessionStorage.setItem("jwtToken", "");
            this.setState({ redirect: true });
          }

          this.setState({
            [by.name]: apiData,
            [`${by.name}_href`]: by.href,
            [`${by.name}_filter_options`]: apiData?.filter_options
              ? apiData.filter_options
              : [],
          });
        });
      }
      if (loopCall.name === "anomalies") {
        loopCall.href_list.map(async (by) => {
          apiData = await allRequestHandler({
            requestType: "GET",
            requestUrl: requestUrl + by.href,
          });
          if (apiData && apiData.status && apiData.status === 401) {
            sessionStorage.setItem("jwtToken", "");
            this.setState({ redirect: true });
          }
          this.setState({
            [`${by.name}_top`]: apiData,
            [`${by.name}_top_href`]: by.href,
            [`${by.name}_top_filter_options`]: apiData.filter_options
              ? apiData.filter_options
              : [],
          });
        });
      }

      if (loopCall.href) {
        apiData = await allRequestHandler({
          requestType: "GET",
          requestUrl: requestUrl + loopCall.href,
        });

        if (apiData && apiData.status && apiData.status === 401) {
          sessionStorage.setItem("jwtToken", "");
          this.setState({ redirect: true });
        }
        this.setState(
          {
            [loopCall.name]: apiData,
            [`${loopCall.name}_href`]: loopCall.href,
            [`${loopCall.name}_filter_options`]: apiData.filter_options
              ? apiData.filter_options
              : [],
          },
          () => {
            //Anomalies Progress bar
            if (this.state.inspections_table) {
              const allCount = [];
              this.state.inspections_table.inspection_list.map((insp) => {
                return allCount.push(insp.anomalies.count);
              });

              this.setState({
                maxAnomalies: Math.ceil(Math.max(...allCount) / 100) * 100,
              });
            }
          }
        );
      }
    });
  };

  //Handles the filters on loss recovered chart
  applyLossesFilter = async (
    defect_types,
    inspection_type,
    module_type,
    severity_types,
    status_types,
    iec_category,
    plant_names,
    temperature_range,
    anomaly_has,
    action
  ) => {
    const data = {
      defect_types,
      inspection_type,
      module_type,
      severity_types,
      status_types,
      iec_category,
      plant_names,
      temperature_range,
      anomaly_has,
    };

    const res = await allRequestHandler({
      requestType: action ? "GET" : "POST",
      requestData: data,
      requestUrl: `${requestUrl}/api/dashboard/charts/losses_recovered`,
    });

    if (res && res.chart_data.length === 0)
      this.setState({ showSnackbar: true, powerLossData: res });
    else this.setState({ powerLossData: res });
  };

  //Handles the language change (English, Spanish, Japanese)
  handleChange = (event) => {
    const newlang = event.target.value;
    this.props.setSelectedLanguage(newlang);
    this.props.i18n.changeLanguage(newlang);
  };

  //This renders ths dashboard page that contains the graphs and tables
  render() {
    const { classes } = this.props;
    const userDetails = this.state.portfolio_metadata;

    if (this.state.redirect) return <Navigate replace to="/" />;

    return (
      <>
        <AppBar position="static" className={classes.appbar}>
          <Toolbar style={{ marginLeft: `${this.props.toggleMenu && "30px"}` }}>
            <Typography variant="h6" color="inherit" className={classes.title}>
              <Trans>Dashboard</Trans>
            </Typography>
            <div style={styles.portfolio}>
              <Trans>Portfolio</Trans>
            </div>
          </Toolbar>
        </AppBar>

        <div
          className={classes.outerPadd}
          style={{
            display:
              this.props.showPlans || this.props.showTrial ? "none" : "block",
          }}
        >
          <div style={{ padding: "12px" }}>
            <MetadataBox
              userDetails={userDetails}
              anomaliesSeverityData={this.state.anomalies_by_severity_top}
              anomaliesStatusData={this.state.anomalies_by_status_top}
              anomaliesIECData={this.state.anomalies_by_iec_top}
              onHandleShowPlans={this.props.onHandleShowPlans}
            />
            <Hidden smDown>
              <Grid container>
                <Grid
                  className={`${classes.pad12}`}
                  item
                  md={12}
                  style={{ width: "-webkit-fill-available" }}
                >
                  <PowerLoss
                    data={this.state.powerLossData}
                    applyLossesFilter={this.applyLossesFilter}
                    compName={"8000px"}
                    filterOptions={this.state.losses_filter_options}
                    component={"dashboard"}
                    metadata={userDetails}
                  />
                </Grid>
              </Grid>
            </Hidden>

            <Grid container className={classes.inspectionContainer}>
              <Grid item md={12} classes={{ root: classes.scrollBar }}>
                <Card style={styles.card} classes={{ root: classes.scrollBar }}>
                  <CardContent style={styles.contentMob}>
                    <Typography style={styles.chartHeading}>
                      <Trans>All Inspections</Trans>
                    </Typography>
                    <Divider />

                    <InspectionTable
                      inspections_table={this.state.inspections_table}
                      maxAnomalies={this.state.maxAnomalies}
                    />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item sm={8} xs={12} className={classes.pad12}>
                <AnomaliesChartCard
                  anomalies_metrics={this.state.anomalies_metrics}
                  anomalies_metrics_href={this.state.anomalies_metrics_href}
                  anomalies_metrics_filter_options={
                    this.state.anomalies_metrics_filter_options
                  }
                  onHandleShowPlans={this.props.onHandleShowPlans}
                  compName="dashboard"
                  cardHeight={525}
                  chartHeight={400}
                />
              </Grid>

              <Grid
                item
                md={4}
                sm={4}
                className={classes.pad12}
                data-intercom-target="download-report-card"
              >
                <DownloadReport
                  navigate={this.props.navigate}
                  portfolio_metadata={this.state.portfolio_metadata}
                  callSnackbar={(msg, value) => {
                    return this.props.callSnackbar(msg, value);
                  }}
                  siteListData={this.props.siteListData}
                  reportObj={(e) => this.props.reportObj(e)}
                />
              </Grid>
            </Grid>

            <Grid container style={{ overflow: "auto" }}>
              <Grid
                className={[classes.pad12, classes.internBenchContainer].join(
                  " "
                )}
                item
                md={12}
              >
                <InternalBenchmark
                  product={this.props.product}
                  anomalies_by_status={this.state.anomalies_by_status}
                  anomalies_per_mwp={this.state.anomalies_per_mwp}
                  anomalies_per_mwp_href={this.state.anomalies_per_mwp_href}
                  anomalies_by_status_href={this.state.anomalies_by_status_href}
                  anomalies_per_mwp_filter_options={
                    this.state.anomalies_per_mwp_filter_options
                  }
                  anomalies_by_status_filter_options={
                    this.state.anomalies_by_status_filter_options
                  }
                  onHandleShowPlans={this.props.onHandleShowPlans}
                />
              </Grid>
            </Grid>
          </div>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={this.state.showSnackbar}
          autoHideDuration={6000}
          onClose={() => {
            return this.setState({ showSnackbar: false });
          }}
        >
          <MuiAlert elevation={6} variant="filled" severity="info">
            {"No Data!"}
          </MuiAlert>
        </Snackbar>
      </>
    );
  }
}

//Calling redux to get the information about the user (pro/basic) and the selected language
const mapStateToProps = (state) => {
  return {
    product: state.product,
    language: state.language.selectedLanguage,
  };
};

//Defining redux action to change the language of the app
const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedLanguage: (value) => {
      return dispatch({ type: SET_SELECTEDLANGUAGE, payload: value });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation("translations")(Dashboard)));
