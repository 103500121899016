const getRoleList = (loggedInUserType, selectedValue) => {

  //if logged in as a site admin but want to show general admin as disabled but not in menu item.
  if (loggedInUserType === "site:admin" && selectedValue === "General Admin") {
    return ["General Admin", "Site Admin", "User"];
  }

  //return items based on logged in user.
  switch (loggedInUserType) {
    case "client:admin":
      return ["General Admin", "Site Admin", "User"];
    case "site:admin":
      return ["Site Admin", "User"];
    default:
      return ["User"];
  }
};

export default getRoleList;
