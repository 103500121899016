import { Grid, Typography, withStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import {
  closeUploadAction,
  updateAllFilesAwsParamsAction,
} from "../../../reducers/bulkUpload";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import FailedIcon from "../../../assets/img/failed.svg";
import SuccessIcon from "../../../assets/img/success.svg";
import { URLS } from "../../../constants";
import { allRequestHandler } from "../../../api";
import { callSnackbarAction } from "../../../reducers/snackbar";
import { connect } from "react-redux";
import styles from "../defectMap.style";

//Bulk upload component
const UploadBlock = ({
  classes,
  bulkUpload,
  closeUploadAction,
  updateAllFilesAwsParamsAction,
  callSnackbarAction,
}) => {
  const [showUpload, setShowUpload] = useState(true);
  const [allXhr, setAllXhr] = useState([]);
  const uploadBlockRef = useRef(null);
  const uploadStatuses = [];

  //Handles the closing of upload files dialog
  const closeUploadBlock = () => {
    const yetToUpload = bulkUpload.allFilesAwsParams.filter((param) => {
      return param.status !== "success" && param.retryCount < 4;
    });
    if (yetToUpload.length > 0) {
      // skipcq Detected the use of alert, confirm and prompt JS-0052
      const confirm = window.confirm(
        "Files not uploaded will be cancelled, while other Anomalies data will still be uploaded to the application. Do you want to cancel the upload?"
      );
      if (confirm) {
        closeUploadAction();
        window.location.reload();
      }
    } else {
      closeUploadAction();
    }
  };

  //Handles the cancelation of file uploads
  const cancelUpload = () => {
    // skipcq Detected the use of alert, confirm and prompt JS-0052
    const confirm = window.confirm(
      "Your images/files upload will be cancelled, while your Anomalies data will still be uploaded to the application. Do you want to cancel the upload?"
    );
    if (confirm) {
      const xhrs = allXhr.filter((item) => {
        return item.xhr.readyState < 4;
      });
      if (xhrs.length > 0) {
        (async () => {
          await Promise.all(
            xhrs.map((xhr) => {
              return xhr.func();
            })
          );
          callSnackbarAction("Files upload cancelled");
        })();
      }
    }
  };

  //handle upload success status
  const uploadSuccess = () => {
    const failedStatuses = uploadStatuses.filter((status) => {
      return !status;
    });

    if (uploadStatuses.length > 0 && failedStatuses.length === 0) {
      const interval = setInterval(() => {
        if (uploadStatuses.length === bulkUpload.allFilesAwsParams.length) {
          callSnackbarAction(
            "files uploaded successfully. Please refresh page to continue...",
            "success"
          );
          // skipcq Detected the use of alert, confirm and prompt JS-0052
          const confirm = window.confirm(
            "files uploaded successfully. Refresh to continue..."
          );
          if (confirm) {
            window.location.reload();
          }
          clearInterval(interval);
        }
        if (failedStatuses.length > 0) {
          clearInterval(interval);
        }
      }, 5000);
    }
  };

  //Handles the upload of files to the AWS server
  const uploadFileToAws = async (aws_params, xhr) => {
    let file = bulkUpload.allFilesRedux.filter((f) => {
      return f.file_name === aws_params.file_name;
    })[0]?.file;
    file = await fetch(file);
    file = await file.blob();
    const data = new FormData();
    data.append("key", aws_params.fields.key);
    data.append("Content-Type", aws_params.fields["Content-Type"]);
    data.append("policy", aws_params.fields.policy);
    data.append("acl", "public-read");
    data.append("success_action_status", "201");
    data.append("x-amz-algorithm", aws_params.fields["x-amz-algorithm"]);
    data.append("x-amz-credential", aws_params.fields["x-amz-credential"]);
    data.append("x-amz-date", aws_params.fields["x-amz-date"]);
    data.append("x-amz-signature", aws_params.fields["x-amz-signature"]);
    data.append("file", file, aws_params.file_name);

    xhr.addEventListener("readystatechange", async () => {
      const readyState = document.readyState;
      if (readyState === 4) {
        if (xhr.status === 200 || xhr.status === 201) {
          // since this is a patch call, it returns nothing.
          // the error function passed as argument helps to return any possible error.
          const imgUp = await allRequestHandler(
            {
              requestType: "PATCH",
              requestUrl: URLS.BULK_UPLOAD_IMAGE_PATCH(
                aws_params.defect_id,
                aws_params.attachment_id
              ),
            },
            false,
            false,
            (error) => {
              return error;
            }
          );
          if (imgUp) {
            // skipcq Variable used before definition JS-0129
            callBack(aws_params, "failed");
          } else {
            // skipcq Variable used before definition JS-0129
            callBack(aws_params, "success");
          }
        } else {
          // skipcq Variable used before definition JS-0129
          callBack(aws_params, "failed");
        }
      }
    });
    xhr.onabort = () => {
      // skipcq Variable used before definition JS-0129
      callBack(aws_params, "cancelled");
    };

    xhr.open("POST", aws_params.url);
    xhr.send(data);
  };

  //Callback function for file upload cancelation or failed
  const callBack = (awsParam, status) => {
    if (status === "success") {
      uploadStatuses.push(true);
      updateAllFilesAwsParamsAction({
        attachmentId: awsParam.attachment_id,
        status: "success",
      });
    }
    if (status === "failed") {
      uploadStatuses.push(false);
      updateAllFilesAwsParamsAction({
        attachmentId: awsParam.attachment_id,
        status: "failed",
        retry: () => {
          updateAllFilesAwsParamsAction({
            attachmentId: awsParam.attachment_id,
            status: "inprogress",
          });
          const xhr = new XMLHttpRequest();
          uploadFileToAws(awsParam, xhr);
        },
      });
    }
    if (status === "cancelled") {
      uploadStatuses.push(false);
      updateAllFilesAwsParamsAction({
        attachmentId: awsParam.attachment_id,
        status: "cancelled",
      });
    }
  };

  //Handles multiple file upload
  const multiFileUpload = () => {
    const yetToUpload = bulkUpload.allFilesAwsParams
      .filter((param) => {
        return param.status !== "success" && param.retryCount < 4;
      })
      .map((param) => {
        const xhr = new XMLHttpRequest();
        return {
          aws_params: param,
          uploadFunc: uploadFileToAws(param, xhr),
          xhr,
        };
      });
    const allXhrRequest = yetToUpload.map((req) => {
      return {
        aws_params: req.aws_params,
        xhr: req.xhr,
        func: () => {
          return req.xhr.abort();
        },
      };
    });
    setAllXhr(allXhrRequest);

    if (yetToUpload.length > 0) {
      for (let i = 0; i < yetToUpload.length; i += 5) {
        setTimeout(async () => {
          const window = i + 5;
          if (window < yetToUpload.length) {
            await Promise.all(
              yetToUpload.slice(i, window).map((item) => {
                return item.uploadFunc;
              })
            );
          } else {
            await Promise.all(
              yetToUpload.slice(i, yetToUpload.length - 1).map((item) => {
                return item.uploadFunc;
              })
            );
            // skipcq Invalid variable assignments using await or yield JS-0040
            i = yetToUpload.length - 1;
            // popup on successful image upload
            uploadSuccess();
          }
        }, 7000);
      }
    }
  };

  //These functions run at the initial render of the component
  useEffect(() => {
    //Calling multiple file upload function
    (() => {
      multiFileUpload();
    })();

    //Handling file upload dialog
    const handler = (event) => {
      if (uploadBlockRef && uploadBlockRef.current) {
        if (!uploadBlockRef.current.contains(event.target)) {
          setShowUpload(false);
        }
      }
    };

    //Added event listener to handle clicks
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  return (
    <Grid
      className={classes.uploadBlockContainer}
      style={{
        bottom: showUpload ? 1 : 20,
      }}
      ref={uploadBlockRef}
    >
      {showUpload ? (
        <>
          <Grid className={classes.uploadBlockBlock}>
            <Typography
              className={classes.titleText}
              style={{ color: "white" }}
            >
              UPLOADING
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span
                onClick={() => {
                  return setShowUpload(!showUpload);
                }}
                style={{ color: "white" }}
                role="button"
                tabIndex={"0"}
                aria-hidden="true"
              >
                {showUpload ? (
                  <ArrowDropUpIcon style={{ cursor: "pointer" }} />
                ) : (
                  <ArrowDropDownIcon style={{ cursor: "pointer" }} />
                )}
              </span>
              <CloseIcon
                style={{ marginLeft: "25px", color: "white" }}
                fontSize="medium"
                className={classes.closeIcon}
                onClick={() => {
                  closeUploadBlock();
                }}
              />
            </div>
          </Grid>
          <Grid
            style={{
              height: "36px",
              background: "#F0F4FA",
              padding: "0px 22px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              className={classes.titleText}
              style={{ color: "#536471" }}
            >
              Updating {bulkUpload.allFilesAwsParams.length} anomalies data
            </Typography>
            {bulkUpload.allFilesAwsParams.filter((item) => {
              return item.status === "inprogress";
            }).length > 0 && (
              <Typography
                className={classes.titleText}
                style={{ color: "#0084FF", cursor: "pointer" }}
                onClick={cancelUpload}
                id={"gtm_cancelProgressUploadBulk"}
              >
                Cancel Upload
              </Typography>
            )}
          </Grid>
          <Grid
            style={{
              height: "72vh",
              overflowY: "scroll",
              border: "1px solid #F0F4FA",
              background: "#FFFFFF",
            }}
          >
            {bulkUpload.allFilesAwsParams.length > 0 &&
              bulkUpload.allFilesAwsParams.map((file) => {
                return (
                  <div
                    style={{
                      minHeight: "45px",
                      borderBottom: "1px solid #EFF3FB",
                      padding: "0px 22px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    key={file.defect_name}
                  >
                    <div
                      style={{
                        flex: 0.8,
                        display: "flex",
                      }}
                    >
                      <Typography
                        className={classes.titleText}
                        style={{ width: 60 }}
                      >
                        # {file.defect_name}
                      </Typography>
                      <Typography className={classes.titleText}>
                        {file.file_name}
                      </Typography>
                    </div>
                    <div>
                      {file.status === "success" ? (
                        <img src={SuccessIcon} alt="suceessIcon" />
                      ) : file.status === "cancelled" ? (
                        <div style={{ display: "flex" }}>
                          <Typography
                            className={classes.titleText}
                            style={{
                              color: "red",
                              marginRight: 10,
                            }}
                          >
                            Cancelled
                          </Typography>
                          <img src={FailedIcon} alt="failedIcon" />
                        </div>
                      ) : file.status === "failed" ? (
                        <div style={{ display: "flex" }}>
                          {file.retryCount < 4 ? (
                            <Typography
                              className={classes.titleText}
                              style={{
                                color: "#0084FF",
                                marginRight: 10,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                return file.retryFunc();
                              }}
                            >
                              Retry
                            </Typography>
                          ) : (
                            <Typography
                              className={classes.titleText}
                              style={{
                                color: "red",
                                marginRight: 10,
                              }}
                            >
                              Failed
                            </Typography>
                          )}

                          <img src={FailedIcon} alt="failedIcon" />
                        </div>
                      ) : (
                        <CircularProgress
                          style={{ color: "#0084ff" }}
                          size={20}
                        />
                      )}
                    </div>
                  </div>
                );
              })}
          </Grid>
        </>
      ) : (
        <Grid
          style={{
            height: "56px",
            background: "#1F043F",
            borderRadius: "4px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0px 22px",
          }}
        >
          <Typography className={classes.titleText} style={{ color: "white" }}>
            UPLOADING
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span
              onClick={() => {
                return setShowUpload(!showUpload);
              }}
              style={{ color: "white" }}
              role="button"
              tabIndex={"-1"}
              aria-hidden="true"
            >
              {showUpload ? (
                <ArrowDropUpIcon style={{ cursor: "pointer" }} />
              ) : (
                <ArrowDropDownIcon style={{ cursor: "pointer" }} />
              )}
            </span>
            <CloseIcon
              style={{ marginLeft: "25px", color: "white" }}
              fontSize="medium"
              className={classes.closeIcon}
              onClick={() => {
                closeUploadBlock();
              }}
            />
          </div>
        </Grid>
      )}
    </Grid>
  );
};

//updating bulkupload state
const mapStateToProps = (state) => {
  return {
    bulkUpload: state.bulkUpload,
  };
};

export default connect(mapStateToProps, {
  closeUploadAction,
  updateAllFilesAwsParamsAction,
  callSnackbarAction,
})(withStyles(styles)(UploadBlock));
