import MuiAlert from "@material-ui/lab/Alert";
import React from "react";
import { Snackbar } from "@material-ui/core";
import { clearSnackbarAction } from "../../reducers/snackbar";
import { connect } from "react-redux";

//snackbar alert
// skipcq Found invalid variable declaration JS-0123
const SnackbarAlert = ({ snackbar, clearSnackbarAction }) => {
  //To close snackbar
  const closeSnackbar = () => {
    clearSnackbarAction();
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      autoHideDuration={3000}
      // skipcq Prefer event handler naming conventions in JSX JS-0411
      open={snackbar.handleSnackbar}
      onClose={closeSnackbar}
      style={{ zIndex: 20000 }}
    >
      <MuiAlert elevation={6} variant="filled" severity={snackbar.severity}>
        {snackbar.displayMsg}
      </MuiAlert>
    </Snackbar>
  );
};

//updating snackbar in redux
const mapStateToProps = (state) => {
  return {
    snackbar: state.snackbar,
  };
};

export default connect(mapStateToProps, { clearSnackbarAction })(SnackbarAlert);
