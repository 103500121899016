export default {
  bgCount: {
    backgroundColor: "#fff8e5",
    padding: 10,
    fontSize: 12,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
    color: "#a87d30",
  },
  downloadMob: {
    "@media only screen and (max-width: 760px)": {
      paddingTop: 4,
      paddingBottom: 4,
    },
  },
  select: {
    width: "100%",
    borderRadius: "6px",
    "&::before": {
      borderBottom: "none",
    },
    "&:hover:before": {
      borderBottom: "none",
    },
    "&:selection:before": {
      borderBottom: "none",
    },
  },
  downloadMenu: {
    background: "#FFFFFF",
    border: "1px solid #F0F4FA",
    boxShadow: "0px 4px 12px rgba(78, 89, 109, 0.05)",
    borderRadius: "5px",
  },
  selectBlock: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "12px",
    color: "#536471",
    "& div": {
      marginTop: 2,
      marginBottom: 4,
      marginLeft: 2,
      height: 16,
      "@media only screen and (max-width: 760px)": {
        marginTop: 0,
      },
    },
    "@media only screen and (max-width: 760px)": {
      height: 36,
    },
  },
  blockList: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "12px",
    color: "#536471",
    backgroundColor: "#FFFFFF !important",
    "&:hover": {
      backgroundColor: "#F0F4FA !important",
    },
  },
  dropDownIcon: {
    display: "none",
  },
  dropDownOutlined: {
    padding: 8,
  },
  tooltipInfo: {
    maxWidth: "149px",
    color: "#ffffff",
    background: "#08001D",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
    borderRadius: "5px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "11px",
    lineHeight: "16px",
  },
  anomalieTooltipArrow: {
    color: "#08001D",
  },
};
