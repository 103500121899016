import {
  AppBar,
  Breadcrumbs,
  Grid,
  Hidden,
  Snackbar,
  Typography,
} from "@material-ui/core";
import { Link, Navigate } from "react-router-dom";

import Config from "../../config";
import CustomDropdown from "../common/customStyle/Dropdown";
import CustomToggleSwitch from "../common/customStyle/ToggleSwitch";
import InspTab from "./InspTab";
import MuiAlert from "@material-ui/lab/Alert";
import NewLoader from "../../layout/newLoader";
import React from "react";
import RequestInsp from "./RequestInsp";
import SiteDetailsTab from "./SiteDetailsTab";
import { Trans } from "react-i18next";
import _ from "lodash";
import { allRequestHandler } from "../../api";
import { connect } from "react-redux";
import moment from "moment";
import styles from "./siteSummary.style";
import { withStyles } from "@material-ui/core/styles";

const requestUrl = Config.hostName;

//site summary page
class SiteSummary extends React.Component {
  constructor() {
    super();
    this.state = {
      tabValue: "Inspections",
      site_metadata: null,
      anomalies_metrics: null,
      selectedInspDate: null,
      site_details: null,
      updateSiteDetailsUrl: null,
      anomalies_metrics_filter_options: [],
      showSnackbar: false,
      requestInsp: false,
      lossChartData: [],
      lossFilterOptions: null,
    };
  }

  //these functions are called on the first render of the component
  componentDidMount() {
    //Do API calls to get all the data required for the site summary page
    this.callApi(this.props.url);
    this.getLossChartData();
  }

  //these functions are called when the component props and states are updated
  // skipcq Invalid lifecycle parameter name JS-D012
  componentDidUpdate(prevState) {
    //Get correct data when the inspection date is changed
    if (
      prevState.selectedInspDate !== this.state.selectedInspDate &&
      !this.state.selectedInspDate &&
      this.state.site_metadata
    ) {
      const sortInspDate = _.orderBy(
        // skipcq Avoid using this.state inside this.setState() JS-0435
        this.state.site_metadata.inspection_list,
        "inspection_date",
        "desc"
      );
      // skipcq Avoid usage of setState in componentDidUpdate JS-0443
      this.setState({
        selectedInspDate: sortInspDate[0]?.inspection_date,
      });
    }

    //Get the correct data when site metadata is updated
    if (prevState.site_metadata !== this.state.site_metadata) {
      if (
        this.state.site_metadata &&
        this.state.site_metadata.inspection_list &&
        this.state.selectedInspDate === null
      ) {
        const inspDate = _.orderBy(
          // skipcq Avoid using this.state inside this.setState() JS-0435
          this.state.site_metadata?.inspection_list,
          "inspection_date",
          "desc"
        );
        // skipcq Avoid usage of setState in componentDidUpdate JS-0443
        this.setState({ selectedInspDate: inspDate[0]?.inspection_date });
      }
    }
  }

  //API call to get all the data required for the revenue and power loss chart
  getLossChartData = async () => {
    const url = this.props.url;
    const id = url.substring(url.lastIndexOf("/") + 1);

    const lossChartData = await allRequestHandler({
      requestType: "GET",
      requestUrl: `${requestUrl}/api/sites/${id}/charts/losses_recovered`,
    });

    if (lossChartData) {
      this.setState({
        lossChartData,
        lossFilterOptions: lossChartData?.filter_options,
      });
    }
  };

  //Handles the filters on the power loss chart
  applyLossesFilter = async (
    defect_types,
    inspection_type,
    module_type,
    severity_types,
    status_types,
    iec_category,
    plant_names,
    temperature_range,
    anomaly_has
  ) => {
    const url = this.props.url;
    const id = url.substring(url.lastIndexOf("/") + 1);

    const data = {
      defect_types,
      module_type,
      severity_types,
      status_types,
      iec_category,
      temperature_range,
      anomaly_has,
    };
    const res = await allRequestHandler({
      requestType: "POST",
      requestData: data,
      requestUrl: `${requestUrl}/api/sites/${id}/charts/losses_recovered`,
    });

    if (res && res.chart_data.length === 0)
      this.setState({ showSnackbar: true, lossChartData: res });
    else this.setState({ lossChartData: res });
  };

  //API call to get all the data required for the site summary page
  callApi = async (urlString, siteDetails = "no") => {
    const getAllUrl = await allRequestHandler({
      requestType: "GET",
      requestUrl: requestUrl + urlString,
    });
    // On session timeout redirect
    if (getAllUrl && getAllUrl.status && getAllUrl.status === 401) {
      sessionStorage.setItem("jwtToken", "");
      this.setState({ redirect: true });
    } else {
      let apiData = 0;
      getAllUrl &&
        getAllUrl.sites_href_list.map(async (loopCall) => {
          if (loopCall.name === "section_benchmark" && siteDetails === "no") {
            loopCall?.href_list.map(async (by) => {
              apiData = await allRequestHandler({
                requestType: "GET",
                requestUrl: requestUrl + by.href,
              });
              this.setState({
                [by.name]: apiData,
                [`${by.name}_href`]: by.href,
                [`${by.name}_filter_options`]: apiData?.filter_options
                  ? apiData?.filter_options
                  : [],
              });
            });
          }
          if (loopCall.name === "anomalies" && siteDetails === "no") {
            loopCall?.href_list.map(async (by) => {
              apiData = await allRequestHandler({
                requestType: "GET",
                requestUrl: requestUrl + by.href,
              });
              this.setState({
                [`${by.name}_top`]: apiData,
                [`${by.name}_top_href`]: by.href,
                [`${by.name}_top_filter_options`]: apiData?.filter_options
                  ? apiData?.filter_options
                  : [],
              });
            });
          }
          if (
            siteDetails === "no" &&
            typeof loopCall.href === "string" &&
            loopCall.name !== "inspection_comparison"
          ) {
            apiData = await allRequestHandler({
              requestType: "GET",
              requestUrl: requestUrl + loopCall.href,
            });
            if (apiData) {
              this.setState({
                [loopCall.name]: apiData,
                [`${loopCall.name}_href`]: loopCall.href,
                [`${loopCall.name}_filter_options`]: apiData?.filter_options
                  ? apiData?.filter_options
                  : [],
              });
            }
          } else if (siteDetails === "yes") {
            if (
              loopCall.name === "site_details" ||
              loopCall.name === "site_metadata"
            ) {
              apiData = await allRequestHandler({
                requestType: "GET",
                requestUrl: requestUrl + loopCall.href,
              });
              this.setState({
                [loopCall.name]: apiData,
                [`${loopCall.name}_href`]: loopCall.href,
                [`${loopCall.name}_filter_options`]: apiData?.filter_options
                  ? apiData?.filter_options
                  : [],
              });
            }
          }
          if (loopCall.name === "site_details")
            this.setState({ updateSiteDetailsUrl: loopCall.href });
        });
    }
  };

  //Handles tab change on the site summary page (Inspections - Site Details)
  handleChange = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };

  //Handles default date selection
  redirectInspDate = (url) => {
    this.setState({
      selectedInspDate: url.inspection_date,
      requestInsp: false,
    });
    this.callApi(url.inspection_href);
  };

  //Handles the filters on the Anomalies V/C Sections chart
  applyFilter = async (
    defect_types,
    inspection_type,
    module_type,
    severity_types,
    status_types,
    iec_category,
    section_names,
    temperature_range,
    anomaly_has
  ) => {
    const data = {
      defect_types,
      module_type,
      severity_types,
      status_types,
      iec_category,
      section_names,
      temperature_range,
      anomaly_has,
    };
    const [res, resp] = await Promise.all([
      allRequestHandler({
        requestType: "POST",
        requestData: data,
        requestUrl: requestUrl + this.state.anomalies_href,
      }),
      allRequestHandler({
        requestType: "POST",
        requestData: data,
        requestUrl: requestUrl + this.state.anomalies_by_status_href,
      }),
    ]);
    if (res && res.section_anomalies && res.section_anomalies.length === 0)
      this.setState({ showSnackbar: true, anomalies: res });
    else this.setState({ anomalies: res });

    if (resp && resp.section_status && resp.section_status.length === 0)
      this.setState({ showSnackbar: true, anomalies_by_status: resp });
    else this.setState({ anomalies_by_status: resp });
  };

  //API call to get the inspection details
  callSiteDetails = () => {
    this.callApi(this.props.url, "yes");
  };

  //Handles the inspection requests
  requestInsp = () => {
    this.setState({ requestInsp: true });
  };

  //Handles the filter chips selected
  selectedChips = (value) => {
    this.setState({
      selectedChips: value,
    });
  };

  //Handles the filter chips selected on the Anomalies V/S Sections chart
  selectedAnomaliesChips = (value) => {
    this.setState({
      selectedAnomaliesChips: value,
    });
  };

  //Handles the changing of the inspection date
  handleInspDate = (event) => {
    const filterInspdate =
      this.state.site_metadata &&
      this.state.site_metadata.inspection_list.find((inspDate) => {
        return inspDate.inspection_date === event.target.value;
      });

    this.setState({ selectedInspDate: event.target.value });
    this.redirectInspDate(filterInspdate);
  };

  //This renders the site summary page
  render() {
    const { classes } = this.props;
    const inspectionDateMenuItems = [];
    const toggleObj = [
      {
        value: "Inspections",
        name: "Inspections",
        cssPlace: "Start",
      },
      {
        value: "Site Details",
        name: "Site Details",
        cssPlace: "End",
      },
    ];

    // On session timeout redirect
    if (this.state.redirect) return <Navigate replace to="/" />;
    if (this.state.inspectionList) {
      this.state.inspectionList.map((inspDate) => {
        return inspectionDateMenuItems.push({
          value: inspDate.section_inspection_date,
          name: moment(inspDate.section_inspection_date).format("D MMM, YYYY"),
        });
      });
    }

    if (this.state.site_metadata && this.state.site_metadata.inspection_list) {
      this.state.site_metadata &&
        _.orderBy(
          this.state.site_metadata.inspection_list,
          "inspection_date",
          "desc"
        ).map((data) => {
          return inspectionDateMenuItems.push({
            value: data.inspection_date,
            name: moment(data.inspection_date).format("D MMM, YYYY"),
          });
        });
    }
    return (
      <>
        <AppBar position="static" className={classes.mobileHeader}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            className={classes.outerHeader}
            style={{
              marginLeft: `${this.props.toggleMenu ? "30px" : "0px"}`,
              width: "-webkit-fill-available",
            }}
          >
            <Grid item className={classes.firstHeader} md={5}>
              <Hidden smDown>
                <Breadcrumbs>
                  <span>
                    <Link to="/sites" className={classes.breadName}>
                      <Trans>Sites</Trans>
                    </Link>
                  </span>
                  <span className={classes.breadName}>
                    <Trans>Inspections</Trans>
                  </span>
                </Breadcrumbs>
              </Hidden>

              <Grid container alignItems="center">
                <Typography variant="h6" color="inherit" style={styles.title}>
                  {this.state.site_metadata &&
                    this.state.site_metadata.plant_name}
                </Typography>
                <Typography className={classes.siteLocation}>
                  {this.state.site_metadata &&
                    this.state.site_metadata.location}
                </Typography>
              </Grid>
            </Grid>
            <Grid item md={7} sm={12} xs={12}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Grid container justifyContent="flex-start">
                    <CustomToggleSwitch
                      value={this.state.tabValue}
                      onChange={this.handleChange}
                      btnGroup={toggleObj}
                      intercom={"site-details-tab"}
                    />
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    className={classes.mobileDropDown}
                    container
                    alignItems="flex-end"
                    justifyContent="flex-end"
                  >
                    <CustomDropdown
                      selectValue={this.state.selectedInspDate}
                      onHandleSelectChange={(e) => {
                        return this.handleInspDate(e);
                      }}
                      data-intercom-target="inspections-date-card"
                      menuItems={inspectionDateMenuItems}
                    />{" "}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AppBar>

        <Grid
          className={classes.outerBoxPadding}
          container
          style={{
            display:
              this.props.showPlans || this.props.showTrial ? "none" : "block",
          }}
        >
          {this.state.tabValue === "Inspections" ? (
            <Grid item xs>
              {!this.state.requestInsp ? (
                <InspTab
                  applyLossesFilter={this.applyLossesFilter}
                  lossChartData={this.state.lossChartData}
                  lossFilterOptions={this.state.lossFilterOptions}
                  applyLossesDateFilter={this.applyLossesDateFilter}
                  metadataDetails={this.state.site_metadata}
                  anomalies_by_severity_top={
                    this.state.anomalies_by_severity_top
                  }
                  anomalies_by_status_top={this.state.anomalies_by_status_top}
                  anomalies_by_iec_top={this.state.anomalies_by_iec_top}
                  anomalies={this.state.anomalies}
                  anomalies_by_status={this.state.anomalies_by_status}
                  anomalies_metrics={this.state.anomalies_metrics}
                  anomalies_metrics_filter_options={
                    this.state.anomalies_metrics_filter_options
                  }
                  anomalies_metrics_href={this.state.anomalies_metrics_href}
                  anomalies_filter_options={this.state.anomalies_filter_options}
                  anomalies_by_status_filter_options={
                    this.state.anomalies_by_status_filter_options
                  }
                  applyBenchmarkFilter={this.applyFilter}
                  redirectInspDate={(data) => {
                    return this.redirectInspDate(data);
                  }}
                  onHandleShowPlans={this.props.onHandleShowPlans}
                  selectedFilterChips={this.selectedChips}
                  selectedChips={this.state.selectedChips}
                  selectedAnomaliesFilterChips={this.selectedAnomaliesChips}
                  selectedAnomaliesChips={this.state.selectedAnomaliesChips}
                />
              ) : (
                <RequestInsp />
              )}
            </Grid>
          ) : (
            <Grid item xs={12}>
              {this.state.site_details ? (
                <SiteDetailsTab
                  site_details={this.state.site_details}
                  callSiteDetails={() => {
                    return this.callSiteDetails();
                  }}
                  updateSiteDetailsUrl={this.state.updateSiteDetailsUrl}
                  callSnackbar={(msg, sev) => {
                    return this.props.callSnackbar(msg, sev);
                  }}
                />
              ) : (
                <Grid container justifyContent="center">
                  <NewLoader />
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={this.state.showSnackbar}
          autoHideDuration={6000}
          onClose={() => {
            return this.setState({ showSnackbar: false });
          }}
        >
          <MuiAlert elevation={6} variant="filled" severity="info">
            {"No Data!"}
          </MuiAlert>
        </Snackbar>
      </>
    );
  }
}

//Calling redux to get the information about the language selected for the app
const mapStateToProps = (state) => {
  return {
    language: state.language.selectedLanguage,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(SiteSummary));
