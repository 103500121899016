import "./index.css";

import {
  Drawer,
  Grid,
  IconButton,
  Typography,
  withStyles,
} from "@material-ui/core";
import { URLS, version } from "../../constants";

import ClientLogo from "../../views/common/clientlogo/index";
import MenuIcon from "@material-ui/icons/Menu";
import React from "react";
import SidebarPagesLinks from "./sidebarPagesLinks";
import { Skeleton } from "@material-ui/lab";
import UploadBlock from "../../views/defectMap/bulkUpload/UploadBlock";
import { allRequestHandler } from "../../api";
import { closeUploadAction } from "../../reducers/bulkUpload";
import clsx from "clsx";
import { connect } from "react-redux";
import dbLogo from "../../assets/img/zeitviewLogo.svg";
import { resetProductStatus } from "../../reducers/product";
import { setSelectedLanguage } from "../../reducers/language";
import styles from "./sidebar.style";
import { withTranslation } from "react-i18next";

//Header component
class Header extends React.Component {
  //Initializing states
  constructor(props) {
    super(props);
    this.state = {
      setOpen: false,
      redirect: false,
      isHovering: false,
      activeLinkColor: null,
      activeLinks: {
        dashboard: true,
        site: false,
        anomalies: false,
        reports: false,
        activity: false,
        users: false,
        configurations: false,
        profile: false,
      },
    };
  }

  //These functions are called on the initial render of the component
  componentDidMount() {
    //Handles bulkupload processes
    window.onbeforeunload = function (e) {
      const yetToUpload = this.props.bulkUpload.allFilesAwsParams.filter(
        (param) => {
          return param.status !== "success" && param.retryCount < 4;
        }
      );
      if (yetToUpload.length > 0) {
        e.preventDefault();
        if (e) {
          e.returnValue =
            "File upload in progress and would be cancelled. Continue?";
        }
        return "File upload in progress and would be cancelled. Continue?";
      }
      return null;
    }.bind(this);
    this.props.closeUploadAction();
    this.props.setSelectedLanguage(this.props.language);
    this.props.i18n.changeLanguage(this.props.language);
    this.props.setToggleMenu(true);
  }

  //This function is called when the component is updated
  componentWillUnmount() {
    //Remove any pending files in upload
    window.onbeforeunload = null;
  }

  //Opens the sidebar
  handleDrawerOpen = () => {
    this.props.setToggleMenu(false);
    this.setState({ setOpen: true });
  };

  //Closes the sidebar
  handleDrawerClose = () => {
    this.props.setToggleMenu(true);
    this.setState({ setOpen: false });
  };

  //Handles logout and check for any upload in the progress
  handleLogout = () => {
    const yetToUpload = this.props.bulkUpload.allFilesAwsParams.filter(
      (param) => {
        return param.status !== "success" && param.retryCount < 4;
      }
    );

    const logout = async () => {
      this.props.closeUploadAction();
      this.setState({ redirect: true });

      await allRequestHandler({
        requestType: "POST",
        requestUrl: URLS.LOGOUT,
        requestData: "",
      });
      sessionStorage.setItem("jwtToken", "");
      this.props.resetProductStatus();
      this.props.navigate("/");
    };

    if (yetToUpload.length > 0) {
      // skipcq Detected the use of alert, confirm and prompt JS-0052
      const confirm = window.confirm(
        "File upload in progress and would be cancelled. Continue?"
      );
      if (confirm) {
        logout();
      }
    } else {
      logout();
    }
  };

  changeColor = (value) => {
    let activeOptions = {
      dashboard: false,
      site: false,
      anomalies: false,
      reports: false,
      activity: false,
      users: false,
      configurations: false,
      profile: false,
    };

    if (value === "dashboard") {
      activeOptions.dashboard = true;
    } else if (value === "site") {
      activeOptions.site = true;
    } else if (value === "anomalies") {
      activeOptions.anomalies = true;
    } else if (value === "reports") {
      activeOptions.reports = true;
    } else if (value === "activity") {
      activeOptions.activity = true;
    } else if (value === "users") {
      activeOptions.users = true;
    } else if (value === "configurations") {
      activeOptions.configurations = true;
    } else if (value === "profile") {
      activeOptions.profile = true;
    }

    //set updated object to state
    this.setState({
      activeOptions,
    });
  };

  //render left sidebar menu.
  render() {
    const { classes } = this.props;
    const path = window.location.pathname;

    const initial =
      this.props.loggedInUser && this.props.loggedInUser.user_profile
        ? this.props.loggedInUser.user_profile.client_name
            .split(" ")
            .map((n, i, a) => {
              return i === 0 || i + 1 === a.length ? n[0] : null;
            })
            .join("")
        : null;
    const logo =
      this.props.loggedInUser && this.props.loggedInUser.user_profile
        ? this.props.loggedInUser.user_profile.client_logo
        : null;

    return (
      <>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={this.handleDrawerOpen}
          edge="start"
          className={clsx(
            classes.menuButton,
            this.state.setOpen && classes.hide
          )}
        >
          <MenuIcon color="#181A1C" fontSize="large" />
        </IconButton>
        <Drawer
          className={this.state.setOpen ? classes.drawer : classes.drawerNone}
          variant="persistent"
          anchor="left"
          open={this.state.setOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {/* sidebar when drawer open */}

          <Grid
            container
            direction="column"
            justifyContent="space-between"
            className={classes.sidebarOpen}
          >
            <Grid item>
              <Grid container className={classes.drawerHeader}>
                <Grid item xs>
                  <Grid container>
                    {logo ? (
                      <img src={logo} alt="client" width="40" height="40" />
                    ) : this.props.siteListData ? (
                      <div>
                        <Typography
                          style={{
                            marginLeft: this.state.setOpen ? "22px" : "",
                          }}
                          className={classes.clientinitial}
                        >
                          {initial}
                        </Typography>
                      </div>
                    ) : (
                      <Skeleton
                        variant="text"
                        width={42}
                        height={70}
                        className={classes.sidebarOpenSkeleton}
                      />
                    )}
                    <Grid item xs className={classes.flexmargin}>
                      <ClientLogo userDetails={this.props.userDetails} />
                    </Grid>
                  </Grid>
                </Grid>
                <IconButton onClick={this.handleDrawerClose}>
                  <MenuIcon fontSize="large" color="#181A1C" />
                </IconButton>
              </Grid>

              <Grid item>
                <SidebarPagesLinks
                  setOpen={this.state.setOpen}
                  siteListData={this.props.siteListData}
                  product_type={this.props.product.product_type}
                  onHandleShowTrial={this.props.onHandleShowTrial}
                  onHandleShowPlans={this.props.onHandleShowPlans}
                  isactivePath={path === "/dashboard"}
                  onClickHandler={() => this.changeColor("dashboard")}
                  path={path}
                  classes={this.props}
                  activeLinkColor={this.state.activeLinkColor}
                  loggedInUser={this.props.loggedInUser}
                  language={this.props.language}
                  logOut={() => {
                    return this.handleLogout(true);
                  }}
                  setSelectedLanguage={this.props.setSelectedLanguage}
                  changeLanguage={this.props.i18n.changeLanguage}
                />
              </Grid>
            </Grid>
            <Grid
              container
              className={classes.logoDisplay}
              alignItems="center"
              direction="column"
              justifyContent="flex-end"
            >
              <Grid>
                <img className={classes.zeitviewLogo} src={dbLogo} alt="" />
              </Grid>
              <Grid
                container
                direction="column"
                alignItems="center"
                className={classes.appVersion}
              >
                <Typography className={classes.versionText}>
                  {version}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Drawer>

        {/* sidebar after drawer closed */}

        <Grid
          container
          direction="column"
          justifyContent="space-between"
          style={{
            width: this.state.setOpen ? 0 : 100,
          }}
          className={classes.sideberCloseStyle}
        >
          <Grid item>
            <Grid className={classes.drawerClosedHeader}>
              <span>
                {this.props.siteListData ? (
                  <div className={classes.initialOpen}>
                    <Typography className={classes.clientinitial}>
                      {initial}
                    </Typography>
                  </div>
                ) : (
                  <Skeleton
                    variant="text"
                    width={42}
                    height={70}
                    className={classes.sidebarOpenSkeleton2}
                  />
                )}
              </span>
            </Grid>

            <Grid item>
              <SidebarPagesLinks
                setOpen={this.state.setOpen}
                siteListData={this.props.siteListData}
                product_type={this.props.product.product_type}
                onHandleShowTrial={this.props.onHandleShowTrial}
                onHandleShowPlans={this.props.onHandleShowPlans}
                isactivePath={path === "/dashboard"}
                onClickHandler={() => this.changeColor("dashboard")}
                path={path}
                classes={this.props}
                activeLinkColor={this.state.activeLinkColor}
                loggedInUser={this.props.loggedInUser}
                language={this.props.language}
                logOut={() => {
                  return this.handleLogout(true);
                }}
                setSelectedLanguage={this.props.setSelectedLanguage}
                changeLanguage={this.props.i18n.changeLanguage}
              />
            </Grid>
          </Grid>

          <Grid className="logo_display">
            <p className={classes.Version}>{version}</p>
          </Grid>
        </Grid>

        {this.props.bulkUpload.showUploadBlock &&
          this.props.bulkUpload.allFilesAwsParams.length > 0 && (
            <Grid item>
              <UploadBlock />
            </Grid>
          )}
      </>
    );
  }
}

//updating states in redux
const mapStateToProps = (state) => {
  return {
    product: state.product,
    bulkUpload: state.bulkUpload,
    language: state.language.selectedLanguage,
  };
};

export default connect(mapStateToProps, {
  resetProductStatus,
  closeUploadAction,
  setSelectedLanguage,
})(withStyles(styles)(withTranslation("translations")(Header)));
