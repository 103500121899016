const styles = {
  filterSidebar: {
    width: 140,
    "@media only screen and (max-width: 600px)": {
      width: 120,
    },
  },
  comStyle: {
    fontFamily: "Montserrat",
    fontWeight: "500",
    paddingLeft: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
    color: "inherit",
    "@media only screen and (max-width: 600px)": {
      paddingLeft: 12,
    },
  },
  filterDialogContent: {
    padding: "0",
    display: "flex",
    flexDirection: "row",
    overflow: "auto",
  },
  chipStyle: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    display: "flex",
    alignItems: "center",
    color: "#536471",
  },
  dialogChips: {
    display: "flex",
    flexDirection: "row",
    alignContent: "flex-start",
  },
  filterChip: {
    margin: 5,
    height: 32,
    borderRadius: 5,
    border: "solid 1px #e4eaf5",
    backgroundColor: "#fff",
    color: "#536471",
  },
  tabLabel: {
    textTransform: "capitalize",
    outline: "none",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    lineHeight: "20px",
    color: "#536471",
  },
  card: {
    border: "1px solid #F0F4FA",
    boxShadow: "0px 2px 4px rgba(78, 89, 109, 0.15)",
    borderRadius: "8px",
    "@media only screen and (max-width: 430px)": {
      height: 340,
      flexWrap: "nowrap",
      width: "84vw",
      marginLeft: 10,
    },
    "@media only screen and (max-width: 590px && min-width : 430px)": {
      marginLeft: 120,
    },
    width: "370px",
    overflow: "auto",
    marginLeft: 20,
    padding: 0,
  },

  contentMob: {
    paddingBottom: 12,
    "@media only screen and (max-width: 600px)": {
      padding: "12px 8px",
    },
  },
  subText: {
    fontSize: 12,
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "20px",
    letterSpacing: "normal",
    color: "#21282d",
    marginBottom: 5,
    marginLeft: 5,
  },
  checkLabel: {
    fontSize: 12,
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "20px",
    letterSpacing: "normal",
    color: "#536471",
  },
  indicatorColor: {
    background: "#0084FF",
  },
  checkboxLabel: {
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "20px",
    letterSpacing: "0px",
    marginBottom: "6px",
  },
  checkboxChecked: {
    borderRadius: "4px",
    color: "#0084FF",
  },
  checkboxNotChecked: {
    borderRadius: "4px",
    color: "#8C9AA4",
  },
  apply: {
    background: "linear-gradient(266.69deg, #59AFFF 0.23%, #0882F3 99.28%)",
    color: "#FFF",
    fontFamily: "Montserrat",
    borderRadius: "8px",
    boxShadow: 0,
  },
  clear: {
    background: "#FFFFFF",
    border: "1px solid #DDE5EC",
    boxSizing: "border-box",
    borderRadius: "8px",
  },
  paddAlign: {
    paddingRight: "10px",
    paddingBottom: "10px",
    position: "sticky",
    bottom: "0",
    background: "#FFFFFF",
    "@media only screen and (max-width: 600px)": {
      width: "320px",
    },
    "@media only screen and (max-width: 400px)": {
      width: "260px",
    },
  },
  selectedTab: {
    textTransform: "capitalize",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    lineHeight: "20px",
    outline: "none",
    color: "#08001D",
  },
  checkboxPrimary: {
    color: "#08001D",
  },
  chipEnable: {
    margin: "5px 10px 5px 0px",
    background: "rgba(0, 132, 255, 0.05)",
    border: "1px solid #0084FF",
    borderRadius: "8px",
    fontWeight: "500",
    fontStyle: "normal",
  },
  chipDisable: {
    margin: "5px 10px 5px 0px",
    border: "1px solid #f0f4fa",
    borderRadius: "8px",
    fontWeight: "500",
    fontStyle: "normal",
  },
  btnImg: {
    height: 16,
    width: 16,
    marginTop: 0,
    marginLeft: 4,
    marginRight: 4,
  },
  fltBtn: {
    color: "#545B65",
    borderRadius: "8px",
    background: "rgba(91, 77, 211, 0.1)",
    border: "2px solid blue",
    fontSize: 12,
    "&:focus": {
      outline: "none !important",
    },
  },
  disableFltBtn: {
    color: "#536471",
    borderRadius: "8px",
    background: "rgba(0, 132, 255, 0.05)",
    fontSize: 12,
  },
  Box: {
    marginBottom: "12px",
    "@media only screen and (max-width: 600px)": {
      marginBottom: "74px",
    },
    "@media only screen and (max-width: 400px)": {
      marginBottom: "74px",
    },
    marg: {
      marginTop: "14px",
      "@media only screen and (max-width: 400px)": {
        marginTop: "-70px",
      },
    },
  },
};
export default styles;
