import {
  CardContent,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@material-ui/core";
import { Trans, withTranslation } from "react-i18next";

import NewLoader from "../../layout/newLoader";
import ProBlur from "../product/ProBlur";
import React from "react";
import SearchBar from "../common/customStyle/searchBar";
import Uncategorized from "../../assets/img/uncategorized.svg";
import { connect } from "react-redux";
import falsepositiveImg from "../../assets/img/falsePositive.svg";
import i18n from "i18next";
import iec1 from "../../assets/img/iec1.svg";
import iec2 from "../../assets/img/iec2.svg";
import iec3 from "../../assets/img/iec3.svg";
import minorImg from "../../assets/img/Subtract.svg";
import notfoundImg from "../../assets/img/notfound.svg";
import pendingImg from "../../assets/img/pending.svg";
import progressImg from "../../assets/img/progress.svg";
import resolvedImg from "../../assets/img/resolved.svg";
import styles from "./defectMap.style";
import subtractImg from "../../assets/img/Moderate.svg";
import vectorImg from "../../assets/img/Vector.svg";
import { withStyles } from "@material-ui/core/styles";

//Anomalies tables
class AnomaliesTable extends React.Component {
  state = {
    // submit: null,
    // foundErrors: null,
    searchTerm: "",
    defectData: this.props.defectData ? this.props.defectData : null,
    // confirmSubmitWithErrors: false,
    order: "asc",
    orderBy: "defect_no",
    numOfDefectItems: this.props.defectData ? this.props.defectData.length : 0,
    startIndex: 0,
    // checkedDefects: new Set(),
    // checked: false,
  };

  // componentDidMount = () => {
  //   if (this.props.defectData) {
  //     this.setState({
  //       defectData: this.props.defectData,
  //       numOfDefectItems: this.props.defectData.length,
  //     });
  //   }
  // };

  //These functions are called when the component updates
  componentDidUpdate(prevProps) {
    if (prevProps.defectData !== this.props.defectData) {
      // skipcq Avoid usage of setState in componentDidUpdate JS-0443
      this.setState({
        defectData: this.props.defectData,
        numOfDefectItems: this.props.defectData.length,
        startIndex: 0,
      });
    }
  }

  //Handles the input in the search bar
  searchHandler = (value) => {
    this.setState({ searchTerm: value });
  };

  //Handles the navigation to the next/previous defect
  handleNavigation = (direction) => {
    if (direction === "next") {
      this.setState((prevState) => {
        return {
          startIndex: prevState.startIndex + 10,
        };
      });
    } else if (direction === "prev") {
      this.setState((prevState) => {
        return {
          startIndex: prevState.startIndex - 10,
        };
      });
    }
  };

  //Get the sorting based on the ascending or descending order
  getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => {
          return this.descendingComparator(a, b, orderBy, order);
        }
      : (a, b) => {
          return -this.descendingComparator(a, b, orderBy, order);
        };
  };

  //Handles the sorting request
  handleSortRequest = (cellId) => {
    const isAsc = this.state.order === "asc" && this.state.orderBy === cellId;
    this.setState({ order: isAsc ? "desc" : "asc", orderBy: cellId });
  };

  //Handles sorting in descending order
  descendingComparator = (a, b, orderBy) => {
    if (!b[orderBy] || b[orderBy] < a[orderBy]) {
      return -1;
    } else if (!a[orderBy] || b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  //Algorithm for an stable sorting
  stableSort = (array, comparator) => {
    let stabilizedThis = [];
    if (this.state.searchTerm === "" || this.state.searchTerm.length === 0) {
      stabilizedThis = array.map((el, index) => {
        return [el, index];
      });
    } else {
      stabilizedThis = array
        .filter((row) => {
          return (
            (row.properties.defect_no &&
              row.properties.defect_no
                .toString()
                .includes(this.state.searchTerm.toLowerCase())) ||
            (row.properties.defect_type_name &&
              row.properties.defect_type_name
                .toLowerCase()
                .includes(this.state.searchTerm.toLowerCase()))
          );
        })
        .map((el, index) => {
          return [el, index];
        });
    }
    if (this.state.numOfDefectItems !== stabilizedThis.length) {
      this.setState({ numOfDefectItems: stabilizedThis.length, startIndex: 0 });
    }

    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0].properties, b[0].properties);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis
      .filter((el, index) => {
        return (
          index >= this.state.startIndex && index < this.state.startIndex + 10
        );
      })
      .map((el) => {
        return el[0];
      });
  };

  //Renders the anomalies table
  render() {
    const { classes } = this.props;

    return (
      <Grid container style={{ overflow: "auto" }}>
        <Grid item md={12} className={classes.tablePadd}>
          <Grid className={classes.tableCard}>
            <CardContent
              style={styles.contentMob}
              // style={{ padding: 0, marginBottom: "10px" }}
            >
              <Grid item className={classes.toolBar}>
                <SearchBar
                  keyword={this.state.searchTerm}
                  placeholder={i18n.t("Search Anomaly number or name")}
                  setKeyword={this.searchHandler}
                />
              </Grid>
              <Divider />
              <div style={{ maxHeight: "68vh", overflow: "auto" }}>
                <ProBlur
                  onHandleShowPlans={this.props.handleShowPlans}
                  text="A table view of sites anomalies details, listing anomalies of sites across plant levels"
                >
                  <Table
                    stickyHeader
                    className={classes.table}
                    aria-label="simple table"
                  >
                    <TableHead data-intercom-target="anomalies-table-heading">
                      <TableRow style={{ background: "#FBFCFF" }}>
                        <TableCell
                          classes={{ stickyHeader: classes.stickTable }}
                          className={classes.tableHeader}
                        >
                          <TableSortLabel
                            classes={{ root: classes.tableHead }}
                            active={this.state.orderBy === "defect_no"}
                            direction={
                              this.state.orderBy === "defect_no"
                                ? this.state.order
                                : "asc"
                            }
                            onClick={() => {
                              return this.handleSortRequest("defect_no");
                            }}
                          >
                            <Trans>Anomaly No.</Trans>
                          </TableSortLabel>
                        </TableCell>
                        <TableCell className={classes.tableHeader}>
                          <Trans>Name</Trans>
                        </TableCell>
                        <TableCell className={classes.tableHeader}>
                          <Trans>Coordinates</Trans>
                        </TableCell>
                        <TableCell
                          classes={{ stickyHeader: classes.stickTable }}
                          className={classes.tableHeader}
                        >
                          <TableSortLabel
                            classes={{ root: classes.tableHead }}
                            active={
                              this.state.orderBy === "actual_module_temperature"
                            }
                            direction={
                              this.state.orderBy === "actual_module_temperature"
                                ? this.state.order
                                : "asc"
                            }
                            onClick={() => {
                              return this.handleSortRequest(
                                "actual_module_temperature"
                              );
                            }}
                          >
                            <Trans>T1 (Actual)</Trans>
                          </TableSortLabel>
                        </TableCell>
                        <TableCell
                          classes={{ stickyHeader: classes.stickTable }}
                          className={classes.tableHeader}
                        >
                          <TableSortLabel
                            classes={{ root: classes.tableHead }}
                            active={
                              this.state.orderBy === "hotspot_temperature"
                            }
                            direction={
                              this.state.orderBy === "hotspot_temperature"
                                ? this.state.order
                                : "asc"
                            }
                            onClick={() => {
                              return this.handleSortRequest(
                                "hotspot_temperature"
                              );
                            }}
                          >
                            <Trans>T2 (Hotspot)</Trans>
                          </TableSortLabel>
                        </TableCell>
                        <TableCell
                          classes={{ stickyHeader: classes.stickTable }}
                          className={classes.tableHeader}
                        >
                          <TableSortLabel
                            classes={{ root: classes.tableHead }}
                            active={this.state.orderBy === "delta_temperature"}
                            direction={
                              this.state.orderBy === "delta_temperature"
                                ? this.state.order
                                : "asc"
                            }
                            onClick={() => {
                              return this.handleSortRequest(
                                "delta_temperature"
                              );
                            }}
                          >
                            ∆T (T 2 – T1)
                          </TableSortLabel>
                        </TableCell>
                        <TableCell className={classes.tableHeader}>
                          IEC CoA
                        </TableCell>
                        <TableCell className={classes.tableHeader}>
                          <Trans>Severity</Trans>
                        </TableCell>
                        <TableCell className={classes.tableHeader}>
                          <Trans>Status</Trans>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {this.state.defectData ? (
                      <TableBody>
                        {this.state.defectData
                          ? this.stableSort(
                              this.state.defectData,
                              this.getComparator(
                                this.state.order,
                                this.state.orderBy
                              )
                            ).map((row) => {
                              return (
                                <TableRow
                                  key={row.properties.defect_id}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    return this.props.showDefectSidebar(row);
                                  }}
                                >
                                  <TableCell
                                    className={classes.tableText}
                                    style={{ textAlign: "center" }}
                                  >
                                    #{row.properties.defect_no}
                                  </TableCell>
                                  <TableCell className={classes.tableText}>
                                    <Trans>
                                      {row.properties.defect_type_name}
                                    </Trans>
                                  </TableCell>
                                  <TableCell
                                    className={classes.tableText}
                                    style={{
                                      whiteSpace: "normal",
                                      wordWrap: "break-word",
                                    }}
                                    align="justify"
                                  >
                                    {row.properties.module_coordinates
                                      ? row.properties.module_coordinates
                                      : "-"}
                                  </TableCell>
                                  <TableCell
                                    className={classes.tableText}
                                    align="justify"
                                  >
                                    {row.properties.actual_module_temperature
                                      ? row.properties.actual_module_temperature
                                      : "-"}
                                  </TableCell>
                                  <TableCell
                                    className={classes.tableText}
                                    align="justify"
                                  >
                                    {row.properties.hotspot_temperature
                                      ? row.properties.hotspot_temperature
                                      : "-"}
                                  </TableCell>
                                  <TableCell
                                    className={classes.tableText}
                                    align="justify"
                                  >
                                    {row.properties.actual_module_temperature &&
                                    row.properties.hotspot_temperature
                                      ? (
                                          row.properties.hotspot_temperature -
                                          row.properties
                                            .actual_module_temperature
                                        ).toFixed(1)
                                      : "-"}
                                  </TableCell>
                                  <TableCell
                                    className={classes.tableText}
                                    align="justify"
                                  >
                                    {row.properties.iec_category ? (
                                      <>
                                        {row.properties.iec_category ===
                                        "IEC 1" ? (
                                          <img
                                            alt="iec"
                                            style={{ marginRight: 5 }}
                                            src={iec1}
                                          />
                                        ) : row.properties.iec_category ===
                                          "IEC 2" ? (
                                          <img
                                            alt="iec"
                                            style={{ marginRight: 5 }}
                                            src={iec2}
                                          />
                                        ) : row.properties.iec_category ===
                                          "IEC 3" ? (
                                          <img
                                            alt="iec"
                                            style={{ marginRight: 5 }}
                                            src={iec3}
                                          />
                                        ) : (
                                          <img
                                            alt="iec"
                                            style={{ marginRight: 5 }}
                                            src={Uncategorized}
                                          />
                                        )}
                                        <Trans>
                                          {row.properties.iec_category}
                                        </Trans>
                                      </>
                                    ) : (
                                      "-"
                                    )}
                                  </TableCell>
                                  <TableCell
                                    className={classes.tableText}
                                    align="justify"
                                  >
                                    {row.properties.severity_type ? (
                                      <>
                                        {row.properties.severity_type ===
                                        "Critical" ? (
                                          <img
                                            alt="sevImg"
                                            className={classes.btnImg}
                                            src={vectorImg}
                                          />
                                        ) : row.properties.severity_type ===
                                          "Moderate" ? (
                                          <img
                                            alt="sevImg"
                                            style={{ marginLeft: 0 }}
                                            className={[
                                              classes.btnImg,
                                              classes.modeIcon,
                                            ].join(" ")}
                                            src={subtractImg}
                                          />
                                        ) : (
                                          <img
                                            alt="sevImg"
                                            className={classes.btnImg}
                                            src={minorImg}
                                          />
                                        )}{" "}
                                        <Trans>
                                          {row.properties.severity_type}
                                        </Trans>{" "}
                                      </>
                                    ) : (
                                      "-"
                                    )}
                                  </TableCell>
                                  <TableCell
                                    className={classes.tableText}
                                    align="justify"
                                  >
                                    {row.properties.status ? (
                                      <>
                                        {row.properties.status === "pending" ||
                                        row.properties.status === "Pending" ? (
                                          <img
                                            alt="statusImg"
                                            className={classes.btnImg}
                                            src={pendingImg}
                                          />
                                        ) : row.properties.status ===
                                            "falsepositive" ||
                                          row.properties.status ===
                                            "False +Ve" ? (
                                          <img
                                            alt="statusImg"
                                            className={classes.btnImg}
                                            src={falsepositiveImg}
                                          />
                                        ) : row.properties.status ===
                                            "resolved" ||
                                          row.properties.status ===
                                            "Resolved" ? (
                                          <img
                                            alt="statusImg"
                                            className={classes.btnImg}
                                            src={resolvedImg}
                                          />
                                        ) : row.properties.status ===
                                            "inprogress" ||
                                          row.properties.status ===
                                            "In Progress" ? (
                                          <img
                                            alt="statusImg"
                                            className={classes.btnImg}
                                            src={progressImg}
                                          />
                                        ) : (
                                          <img
                                            alt="statusImg"
                                            className={classes.btnImg}
                                            src={notfoundImg}
                                          />
                                        )}
                                        <Trans>
                                          {row.properties.status ===
                                            "pending" ||
                                          row.properties.status === "Pending"
                                            ? (row.properties.status =
                                                "Pending")
                                            : row.properties.status ===
                                                "inprogress" ||
                                              row.properties.status ===
                                                "In Progress"
                                            ? (row.properties.status =
                                                "In Progress")
                                            : row.properties.status ===
                                                "resolved" ||
                                              row.properties.status ===
                                                "Resolved"
                                            ? (row.properties.status =
                                                "Resolved")
                                            : row.properties.status ===
                                                "notfound" ||
                                              row.properties.status ===
                                                "Not Found"
                                            ? (row.properties.status =
                                                "Not Found")
                                            : (row.properties.status =
                                                "False +Ve")}
                                        </Trans>
                                      </>
                                    ) : (
                                      "-"
                                    )}
                                  </TableCell>
                                </TableRow>
                              );
                            })
                          : null}
                      </TableBody>
                    ) : (
                      <TableBody>
                        <TableRow>
                          <TableCell />
                          {window.innerWidth > 520 ? <TableCell /> : null}
                          <TableCell />
                          <TableCell>
                            <NewLoader />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </ProBlur>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "20px 0",
                }}
              >
                {this.state.startIndex > 0 && (
                  <Typography
                    className={`${classes.titleText} ${classes.selectLink}`}
                    onClick={() => {
                      return this.handleNavigation("prev");
                    }}
                  >
                    <Trans>load previous</Trans> 10
                  </Typography>
                )}

                <Typography
                  style={{ margin: " 0 1rem" }}
                  className={classes.titleText}
                >
                  <Trans>showing</Trans> {this.state.startIndex + 1} -{" "}
                  {this.state.numOfDefectItems > this.state.startIndex + 10
                    ? this.state.startIndex + 10
                    : this.state.numOfDefectItems}{" "}
                  <Trans>of</Trans>{" "}
                  {this.state.numOfDefectItems && this.state.numOfDefectItems}{" "}
                  <Trans>items.</Trans>
                </Typography>
                {this.state.defectData &&
                  this.state.startIndex + 10 < this.state.numOfDefectItems && (
                    <Typography
                      className={`${classes.titleText} ${classes.selectLink}`}
                      onClick={() => {
                        return this.handleNavigation("next");
                      }}
                    >
                      <Trans>load next</Trans> 10
                    </Typography>
                  )}
              </div>
            </CardContent>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

//calling redux to get selected language
const mapStateToProps = (state) => {
  return {
    language: state.language.selectedLanguage,
  };
};

export default connect(mapStateToProps)(
  withStyles(styles)(withTranslation("translations")(AnomaliesTable))
);
