const styles = {
  width500: {
    minWidth: 600,
    maxWidth: 630,
    margin: 0,
    top: 0,
    minHeight: "100vh",
    bottom: 0,
    "@media only screen and (max-width: 1024px)": {
      minWidth: "100%",
      marginTop: 0,
      height: "100%",
    },
  },
  title: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 14,
    textTransform: "capitalize",
    color: "#21282D",
    paddingLeft: 20,
    alignSelf: "center",
  },
  userTitle: {
    fontFamily: "Montserrat",
    fontSize: 20,
    fontWeight: 500,
    marginTop: "3px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.2,
    color: "#21282d",
  },
  userNames: {
    fontFamily: "Montserrat",
    fontWeight: "500",
  },
  subHeader: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    lineHeight: "20px",
    textTransform: "capitalize",
    color: "#21282D",
  },
  headerTxt: {
    fontStyle: "normal",
    fontSize: 12,
    lineHeight: "20px",
    textTransform: "capitalize",
    color: "#7E909D",
    fontFamily: "Montserrat",
    fontWeight: 400,
  },
  swipDrawer: {
    minWidth: 600,
    marginTop: 0,
    height: "100%",
  },
  avatarName: {
    fontFamily: "Montserrat",
    color: "#7E909D",
    background: "#F4F7FC",
    borderRadius: 25,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    lineHeight: "18px",
    width: 30,
    height: 30,
    marginLeft: 26,
  },
  userValue: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    lineHeight: "28px",
    textTransform: "capitalize",
    color: "#536471",
  },
  siteListOuter: {
    border: "1px solid #E4EAF5",
    borderRadius: 5,
    width: 400,
    padding: 0,
    margin: "8px 0 15px",
  },
  siteListInner: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    borderBottom: "1px solid #E4EAF5",
    fontSize: 12,
    lineHeight: "20px",
    textTransform: "capitalize",
    color: "#7E909D",
    height: 40,
    "&:last-child": {
      borderBottom: "unset",
    },
  },
  siteListLocation: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    lineHeight: "20px",
    color: "#536471",
    marginRight: 8,
  },
  passwordInfo: {
    maxWidth: "149px",
    color: "#ffffff",
    background: "#08001D",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
    borderRadius: "5px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "11px",
    lineHeight: "16px",
  },
  danger: {
    color: "#F84343",
    fontWeight: 700,
    fontFamily: "Montserrat",
    fontSize: "12px",
    lineHeight: "20px",
  },
  success: {
    color: "#5ADB84",
    fontWeight: 700,
    fontFamily: "Montserrat",
    fontSize: "12px",
    lineHeight: "20px",
  },
  dividerUpdate: {
    marginTop: "0 !important",
    marginBottom: "1rem !important",
  },
  dInBlock: {
    display: "inline-block!important",
  },
  gridUser: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "50px",
  },
  mr30: {
    marginRight: "30px",
  },
  mb20: {
    marginBottom: "20px",
  },
  UserListCard: {
    margin: "0px 0px 5px 0px",
    backgroundColor: "white",
    borderRadius: 5,
    paddingLeft: 25,
    minHeight: 50,
  },
  focusVisible: {
    "&:hover": {
      backgroundColor: "#F4F4FB",
    },
  },
  textStyle: {
    fontFamily: "Montserrat",
    fontWeight: 500,
  },
  plantNameTitle: {
    color: "#181A1C",
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: "20px",
    fontStyle: "normal",
    lineHeight: "40px",
  },
  avtar: {
    textTransform: "uppercase",
    padding: 4,
    background: "#f4f7fc",
    borderRadius: 10,
  },
  root: {
    marginTop: "1px",
    marginBottom: 20,
    padding: 0,
  },
  paper: {
    width: 200,
    height: 230,
    overflow: "auto",
  },
  button: {},
  siteAccessList: {
    width: "100%",
    borderRadius: "5px",
    border: "solid 1px #e4eaf5",
    paddingBottom: "4px",
    color: "#7e909d",
    borderBottom: "solid 1px #e4eaf5",
    fontFamily: "Montserrat",
    fontWeight: 500,
  },
  siteAccessListTitle: {
    fontSize: 12,
    fontWeight: 600,
    paddingLeft: 16,
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
    color: "#536471",
    margin: 0,
    padding: 0,
  },
  siteAccessListDesc: {
    fontSize: 12,
    fontWeight: "normal",
    paddingLeft: 16,
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
    color: "#7e909d",
  },
  siteAccessSite: {
    padding: 0,
    minHeight: 50,
    borderBottom: "solid 1px #e4eaf5",
  },
  header: {
    paddingLeft: 16,
    display: "flex",
    alignItems: "center",
    justifyContent: "column",
    minHeight: 30,
    backgroundColor: "#FBFCFF",
    color: "#7e909d",
    borderBottom: "solid 1px #e4eaf5",
  },
  siteAccess: {
    height: 210,
    padding: 0,
    margin: 0,
    overflow: "auto",
  },
  siteAccess2: {
    textAlign: "center!important",
    width: "30px",
    height: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "15px",
    marginBottom: 18,
  },
  siteAccess3: {
    marginTop: 3,
    textAlign: "center",
    width: "30px",
    height: "30px",
    borderRadius: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  user1: {
    height: 50,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: "25px",
  },
  users2: {
    backgroundColor: "white !important",
    color: "black",
    boxShadow: "none",
  },
  userAdd2: {
    marginBottom: "20px",
    marginTop: "10px",
  },

  userEmail: {
    marginTop: "16px",
    height: "50px",
    fontSize: "1rem",
    wordWrap: "break-word",
    marginRight: "5px",
    marginLeft: "5px",
  },

  userId: {
    wordWrap: "break-word",
    fontFamily: "Montserrat",
    fontWeight: "500",
  },

  // site access and deletion
  siteAccessCard: {
    maxWidth: "570px",
    maxHeight: "308px",
    borderRadius: "8px",
    border: "1px solid var(--gray-100, #E6EBF0)",
    overflow: "scroll",
    overflowX: "hidden",
  },

  rowTitle: {
    flexWrap: "nowrap",
    height: "30px",
    backgroundColor: " var(--gray-25, #F8F9FA)",
    padding: "8px 12px 8px 12px",
    borderBottom: "1px solid var(--gray-100, #E6EBF0)",
  },

  titleStyle: {
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "20px",
    color: "var(--gray-400, #6D7683)",
  },

  siteDetails: {
    flexWrap: "nowrap",
    padding: "16px 12px 16px 12px",
    borderBottom: "1px solid var(--gray-100, #E6EBF0)",
  },

  plantInfo: {
    width: "180px",
    height: "36px",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },

  dropdownAndUserMargin: {
    marginLeft: "20px",
  },

  plantName: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "20px",
    color: "var(--gray-900, #181A1C)",
  },

  normalText: {
    fontFamily: "Montserrat",
    fontSize: "11px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "16px",
    color: "var(--gray-400, #6D7683)",
    width: "200px",
  },

  plantLocation: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    fontFamily: "Montserrat",
    fontSize: "11px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "16px",
    color: "var(--gray-400, #6D7683)",
  },

  select: {
    color: "red",
    backgroundColor: "red",
  },

  viewOptionRole: {
    padding: "1px 8px",
    borderRadius: "6px",
    backgroundColor: "var(--purple-50, #F4F4FB)",
  },

  // users list card

  textUserList: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "20px",
    color: "#545B65",
  },

  firstLastName: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "20px",
    color: "#545B65",
    width: "120px",
  },

  emailUser: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "20px",
    color: "#545B65",
    width: "120px",
  },

  nameAvtar: {
    width: "30px",
    height: "30px",
    borderRadius: "40px",
    background: "#F0F3F7",
    marginRight: "8px",
  },
  avtarText: {
    fontFamily: "Montserrat",
    fontSize: "11px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "16px",
    color: "#545B65",
  },
  descriptionUsersRole: {
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "20px",
    color: "#545B65",
    whiteSpace: "wrap",
  },

  roleDrop: {
    marginLeft: "25px",
  },
};

export default styles;
