import "./newLoader.scss";

import React from "react";

//loading spinner while rendering data
const NewLoader = () => {
  return (
    <div id="container">
      <div id="loader"></div>
    </div>
  );
};

export default NewLoader;
