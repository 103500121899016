import {
  Button,
  Card,
  CardContent,
  Grid,
  Hidden,
  Typography,
} from "@material-ui/core";
import { Trans, withTranslation } from "react-i18next";

import Config from "../../../config";
import CustomTooltip from "../customStyle/Tooltip";
import Ham from "../../../assets/img/hamb.svg";
import InfoIcon from "@material-ui/icons/Info";
import LossViewer from "../revenueloss/revenue";
import NewLoader from "../../../layout/newLoader";
import React from "react";
import acIcon from "../../../assets/img/acIcon.png";
import { allRequestHandler } from "../../../api";
import { connect } from "react-redux";
import dcIcon from "../../../assets/img/dcIcon.png";
import i18n from "i18next";
import powerIcon from "../../../assets/img/powerIcon.png";
import { revenueCardTooltip } from "../../../constants/tooltipDetails";
import revenueIcon from "../../../assets/img/revenueIcon.png";
import styles from "../../dashboard/dashboard.styles";
import utilCurrencyConverter from "../../../utils/currencyValConverter";
import { withStyles } from "@material-ui/core/styles";

const requestUrl = Config.hostName;

//Card header component
class CardTitle extends React.Component {
  state = {
    lossOpen: false,
    lossValue: null,
  };

  //To fetch metadata
  async getData() {
    let setUrl = "";
    if (this.props.userDetails && this.props.userDetails.plant_id) {
      setUrl = `/api/sites/${this.props.userDetails.plant_id}/metadata`;
    } else {
      setUrl = "/api/dashboard/metadata";
    }

    const value = await allRequestHandler({
      requestType: "GET",
      requestUrl: requestUrl + setUrl,
    });

    this.props.getMetadata(value);

    if (value) {
      const lossValue = value.revenue_loss;
      this.setState({ lossValue });
    }
  }

  //Opens the dialog box
  handleOpenDialog = () => {
    this.setState({ lossOpen: true });
  };

  //Formats the large numbers
  convertNumber = (value) => {
    const splitVal = value.split(" ");
    const modifiedValue = value.split(" ")[1].split(",").join("");
    return `${splitVal[0]} ${utilCurrencyConverter(modifiedValue)}`;
  };

  updateMetaIcon = (val) => {
    switch (val) {
      case "DC Capacity":
        return dcIcon;
      case "AC Capacity":
        return acIcon;
      case "Power Loss":
        return powerIcon;
      default:
        return revenueIcon;
    }
  };

  //Renders the card header
  render() {
    const { classes } = this.props;

    return (
      <>
        <Card className={classes.cardOuter}>
          <CardContent classes={{ root: classes.pad16LCB }}>
            <Grid container alignItems="center">
              <Hidden xsDown>
                <Grid item sm={3}>
                  {/* Analysed icons togglling on card */}
                  <img
                    width="40px"
                    height="40px"
                    src={this.updateMetaIcon(this.props.value)}
                  />
                </Grid>
              </Hidden>

              <Grid item sm={9}>
                <Grid container>
                  <Typography className={classes.selectedName}>
                    <Trans>{this.props.value}</Trans>
                  </Typography>
                  {/* Tooltip icon for Revenue Loss card */}
                  {this.props.value === "Revenue Loss/yr" && (
                    <CustomTooltip title={i18n.t(revenueCardTooltip)}>
                      <InfoIcon className={classes.chartInfo} />
                    </CustomTooltip>
                  )}
                </Grid>

                {this.props.count ? (
                  <Grid container justifyContent="space-between">
                    {/* Displaying count values on card */}
                    <Typography className={classes.cardTitleValue}>
                      {this.props.value === "Revenue Loss/yr"
                        ? this.convertNumber(this.props.count)
                        : this.props.count}
                    </Typography>

                    {/* Power/Revenue Loss click icon from card */}
                    {(this.props.value === "Power Loss" ||
                      this.props.value === "Revenue Loss/yr") && (
                      <Hidden xsDown>
                        <img
                          onClick={this.handleOpenDialog}
                          alt="cardImg1"
                          style={{ height: "20px", cursor: "pointer" }}
                          src={Ham}
                        />
                      </Hidden>
                    )}
                  </Grid>
                ) : this.props.value === "Revenue Loss/yr" ? (
                  <Hidden xsDown>
                    <Button
                      className={classes.calBtn}
                      onClick={this.handleOpenDialog}
                    >
                      <Trans>Calculate</Trans>
                    </Button>
                  </Hidden>
                ) : (
                  <NewLoader />
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        {this.state.lossOpen ? (
          <LossViewer
            open={this.state.lossOpen}
            closeDialog={(e) => {
              if (e) {
                this.getData();
              }
              this.setState({ lossOpen: false });
            }}
            userDetails={this.props.userDetails}
            metadata={() => {
              this.getData();
            }}
          />
        ) : null}
      </>
    );
  }
}

//Calling redux to get information about the product (basic/pro) and the selected language
const mapStateToProps = (state) => {
  return {
    language: state.language.selectedLanguage,
  };
};

export default connect(mapStateToProps)(
  withStyles(styles)(withTranslation()(CardTitle))
);
