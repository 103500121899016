import {
  AppBar,
  Button,
  FormControl,
  Grid,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import Config, { trackId } from "../../config";

import { Navigate } from "react-router-dom";
import NewLoader from "../../layout/newLoader";
import React from "react";
import ReactGA from "react-ga";
import SearchBar from "../common/customStyle/searchBar";
import SiteCard from "./SiteCard";
import SortIcon from "../../assets/img/sort.svg";
import { Trans } from "react-i18next";
import { allRequestHandler } from "../../api";
import { connect } from "react-redux";
import i18n from "i18next";
import shortid from "shortid";
import styles from "./sites.style";
import { withStyles } from "@material-ui/core/styles";
import UsersListForSiteAccess from "./usersListForSitesAccess";

const requestUrl = Config.hostName;
ReactGA.initialize(trackId);
const plantColors = [
  { background: "#FFF8E4", letter: "#FFC101" },
  { background: "#E6F3FF", letter: "#0084FF" },
  { background: "#E8FFEF", letter: "#5ADB84" },
  { background: "#f0dff5", letter: "#A235C1" },
  { background: "#f7dfe4", letter: "#F94C75" },
  { background: "#e5dff8", letter: "#684BDC" },
];

//sites page
class Sites extends React.Component {
  state = {
    siteListData: this.props.siteListData ? this.props.siteListData : null,
    searchBy: "",
    sortBy: "RecentIns",
    sortResult: null,
    redirect: false,
    showFilter: true,
    isUsersButtonClicked: false,
    siteCardDataForUsers: null,
  };

  //These functions are called on the first render of the component
  componentDidMount() {
    // For Google Analytics
    ReactGA.pageview(window.location.pathname);

    //Do an API call at the render to get the site list data
    if (this.props.sitesUrl) this.callApi();
  }

  //These functions are called when the component props or state is updated
  componentDidUpdate(prevProps) {
    if (
      prevProps.siteListData !== this.props.siteListData &&
      this.props.siteListData
    ) {
      // skipcq Avoid usage of setState in componentDidUpdate JS-0443
      this.setState({ siteListData: this.props.siteListData });
    }

    if (prevProps.sitesUrl !== this.props.sitesUrl) this.callApi();
  }

  //Handles the API call to get the site list data
  callApi = async () => {
    const sitesData = await allRequestHandler({
      requestType: "GET",
      requestUrl: requestUrl + this.props.sitesUrl.href,
    });

    if (sitesData && sitesData.status && sitesData.status === 401) {
      sessionStorage.setItem("jwtToken", "");
      this.setState({ redirect: true });
    } else {
      sitesData &&
        sitesData.site_list.map((site) => {
          return (
            site.plant_image === null &&
            (site.plant_image = plantColors[Math.floor(Math.random() * 6)])
          );
        });
      if (sitesData && sitesData.site_list) {
        this.setState({ siteListData: sitesData.site_list });
        this.setState({
          sortResult: sitesData.site_list.sort((a, b) => {
            return new Date(b.inspection_date) - new Date(a.inspection_date);
          }),
        });
      }
    }
  };

  //Handles the input from search by site name field
  searchSpace = (value) => {
    this.setState({ searchBy: value });
  };

  //Handles the input from "sort by" dropdown
  handleOption = (e, mobValue) => {
    let sortKey = e.target.value;
    //For mobile view on select it will sort
    if (!sortKey) sortKey = mobValue;

    this.setState({ sortBy: sortKey });
    this.sortSpace(sortKey);

    if (!e.target.value) this.handleClose();
  };

  //Handles the dropdown menu
  handleMenu = (e) => {
    this.setState({ anchorEl: e.currentTarget });
  };

  //Handles the closing of dropdown menu
  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  //Handles the sorting of site list data based on the "sort by" dropdown value
  sortSpace = (sortKey) => {
    if (sortKey === "RecentCod") {
      this.setState((prevState) => {
        return {
          sortResult: prevState.siteListData.sort((a, b) => {
            return (
              new Date(b.commissioning_date) - new Date(a.commissioning_date)
            );
          }),
        };
      });
    } else if (sortKey === "OldestCod") {
      this.setState((prevState) => {
        return {
          sortResult: prevState.siteListData.sort((a, b) => {
            return (
              new Date(a.commissioning_date) - new Date(b.commissioning_date)
            );
          }),
        };
      });
    } else if (sortKey === "OldestIns") {
      this.setState((prevState) => {
        return {
          sortResult: prevState.siteListData.sort((a, b) => {
            return new Date(a.inspection_date) - new Date(b.inspection_date);
          }),
        };
      });
    } else if (sortKey === "RecentIns") {
      this.setState((prevState) => {
        return {
          sortResult: prevState.siteListData.sort((a, b) => {
            return new Date(b.inspection_date) - new Date(a.inspection_date);
          }),
        };
      });
    }
  };

  siteCardDataForUsers = (data) => {
    this.setState({
      siteCardDataForUsers: data,
    });
  };

  //This page renders the site list data in the form of cards
  render() {
    const { classes } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    // On session timeout redirect
    if (this.state.redirect) return <Navigate replace to="/" />;

    return (
      <>
        {!this.state.isUsersButtonClicked ? (
          <Grid container className={classes.sitePage}>
            <AppBar position="static" className={classes.headerApp}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  className={classes.title}
                  style={{ marginLeft: `${this.props.toggleMenu && "30px"}` }}
                >
                  <Trans>Sites</Trans>
                </Typography>
              </Grid>
            </AppBar>
            <Grid container direction="column">
              <Grid container className={classes.toolBar}>
                <SearchBar
                  keyword={this.state.searchBy}
                  placeholder={i18n.t("Search by site name, location")}
                  setKeyword={this.searchSpace}
                />
                <Hidden smDown>
                  <Grid
                    className={classes.sortBar}
                    data-intercom-target="sites-inspection-date-filter"
                    onClick={() => this.setState({ showFilter: true })}
                  >
                    <FormControl className={classes.formControl}>
                      <Select
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                          classes: { paper: classes.paperStyle },
                        }}
                        value={this.state.sortBy}
                        onChange={this.handleOption}
                        displayEmpty
                        className={classes.selectEmpty}
                        disableUnderline
                      >
                        <MenuItem
                          ListItemClasses={{
                            root: classes.lists,
                            selected: classes.selectedList,
                          }}
                          value={"RecentIns"}
                          className={classes.MenuItem}
                        >
                          <Trans>Inspection Date: Recent First</Trans>
                        </MenuItem>
                        <MenuItem
                          ListItemClasses={{
                            root: classes.lists,
                            selected: classes.selectedList,
                          }}
                          value={"OldestIns"}
                          className={classes.MenuItem}
                        >
                          <Trans>Inspection Date: Oldest First</Trans>
                        </MenuItem>
                        <MenuItem
                          ListItemClasses={{
                            root: classes.lists,
                            selected: classes.selectedList,
                          }}
                          value={"RecentCod"}
                          className={classes.MenuItem}
                        >
                          <Trans>COD: Recent First</Trans>
                        </MenuItem>
                        <MenuItem
                          ListItemClasses={{
                            root: classes.lists,
                            selected: classes.selectedList,
                          }}
                          value={"OldestCod"}
                          className={classes.MenuItem}
                        >
                          <Trans>COD: Oldest First</Trans>
                        </MenuItem>
                      </Select>
                      <IconButton
                        disabled
                        type="sort"
                        aria-label="sort"
                        className={classes.sortIcon}
                      >
                        <img src={SortIcon} alt="Sort" />
                      </IconButton>
                    </FormControl>
                  </Grid>
                </Hidden>

                <Hidden smUp>
                  {this.state.showFilter && (
                    <div>
                      <Button
                        aria-controls="fade-menu"
                        aria-haspopup="true"
                        className={classes.sortIcon}
                        onClick={(e) => {
                          return this.handleMenu(e);
                        }}
                      >
                        <img src={SortIcon} alt="Sort" />
                      </Button>
                      <Menu
                        id="fade-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={open}
                        onClose={() => {
                          return this.handleClose();
                        }}
                      >
                        <MenuItem
                          onClick={(e) => {
                            return this.handleOption(e, "RecentIns");
                          }}
                        >
                          <Trans>Inspection Date: Recent First</Trans>
                        </MenuItem>
                        <MenuItem
                          onClick={(e) => {
                            return this.handleOption(e, "OldestIns");
                          }}
                        >
                          <Trans>Inspection Date: Oldest First</Trans>
                        </MenuItem>
                        <MenuItem
                          onClick={(e) => {
                            return this.handleOption(e, "OldestIns");
                          }}
                        >
                          <Trans>COD: Recent First</Trans>
                        </MenuItem>
                        <MenuItem
                          onClick={(e) => {
                            return this.handleOption(e, "OldestIns");
                          }}
                        >
                          <Trans>COD: Oldest First</Trans>
                        </MenuItem>
                      </Menu>
                    </div>
                  )}
                </Hidden>
              </Grid>
              <Grid>
                {this.state.sortResult ? (
                  <Grid container className={classes.cards}>
                    {!this.state.searchBy
                      ? this.state.sortResult.map((siteData) => {
                          return (
                            <SiteCard
                              siteCardDataForUsers={(data) => {
                                this.props.siteCardDataForUsers(data);
                                this.setState({
                                  siteCardDataForUsers: data,
                                });
                              }}
                              isUsersButtonClicked={(data) => {
                                this.setState({
                                  isUsersButtonClicked: data,
                                });
                              }}
                              list={this.state.sortResult}
                              key={shortid.generate()}
                              siteCardData={siteData}
                              siteListData={this.state.siteListData}
                              background={
                                typeof siteData.plant_image === "object" &&
                                siteData.plant_image !== null
                                  ? siteData.plant_image.background
                                  : ""
                              }
                              letter={
                                typeof siteData.plant_image === "object" &&
                                siteData.plant_image !== null
                                  ? siteData.plant_image.letter
                                  : ""
                              }
                              callSiteList={() => {
                                return this.callApi();
                              }}
                              callSnackbar={(msg, value) => {
                                return this.props.callSnackbar(msg, value);
                              }}
                            />
                          );
                        })
                      : this.state.sortResult
                          .filter((site) => {
                            return (
                              (site.location !== null &&
                                site.location
                                  .toLowerCase()
                                  .includes(
                                    this.state.searchBy.toLowerCase()
                                  )) ||
                              (site.plant_name !== null &&
                                site.plant_name
                                  .toLowerCase()
                                  .includes(this.state.searchBy.toLowerCase()))
                            );
                          })
                          .map((siteData) => {
                            return (
                              <SiteCard
                                key={shortid.generate()}
                                siteCardData={siteData}
                                background={
                                  typeof siteData.plant_image === "object" &&
                                  siteData.plant_image !== null
                                    ? siteData.plant_image.background
                                    : ""
                                }
                                letter={
                                  typeof siteData.plant_image === "object" &&
                                  siteData.plant_image !== null
                                    ? siteData.plant_image.letter
                                    : ""
                                }
                                siteCardDataForUsers={(data) => {
                                  this.props.siteCardDataForUsers(data);
                                  this.setState({
                                    siteCardDataForUsers: data,
                                  });
                                }}
                                isUsersButtonClicked={(data) => {
                                  this.setState({
                                    isUsersButtonClicked: data,
                                  });
                                }}
                                callSiteList={() => {
                                  return this.callApi();
                                }}
                                callSnackbar={(msg, value) => {
                                  return this.props.callSnackbar(msg, value);
                                }}
                              />
                            );
                          })}
                  </Grid>
                ) : (
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    className={classes.spinMobileView}
                    style={{ height: "calc(100vh - 300px)" }}
                  >
                    <NewLoader />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <UsersListForSiteAccess
            isBackButtonClicked={(data) => {
              this.setState({
                isUsersButtonClicked: !data,
              });
            }}
            usersListData={this.state.siteCardDataForUsers}
          />
        )}
      </>
    );
  }
}

//Calling redux to get the information about the selected language
const mapStateToProps = (state) => {
  return {
    language: state.language.selectedLanguage,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(Sites));