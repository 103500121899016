export default {
  title: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 20,
    lineHeight: "24px",
    color: "#08001D",
    "@media (max-width: 760px)": {
      marginLeft: 30,
    },
    "@media (max-width: 959px)": {
      marginLeft: 30,
    },
  },
  headerApp: {
    backgroundColor: "white",
    boxShadow: "none",
    minHeight: 72,
    justifyContent: "center",
    padding: "0 24px",
    zIndex: 0,
    "@media (max-width: 760px)": {
      minHeight: 56,
    },
  },
  sitePage: {
    justifyContent: "center",
    "@media (max-width: 760px)": {
      justifyContent: "center",
    },
  },
  spinMobileView: {
    "@media (max-width:375px)": {
      marginLeft: "-30%",
    },
    "@media (max-width: 550px)": {
      marginLeft: "-20%",
    },
  },
  cards: {
    margin: "0 auto",
    maxWidth: 1160,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "left",
    padding: "0 34px",
    "@media (max-width: 1400px)": {
      maxWidth: "100%",
    },
    "@media (max-width: 399px)": {
      maxWidth: "100%",
      padding: 0,
    },
  },
  toolBar: {
    margin: "24px auto",
    padding: "0px 40px 0px 40px",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-between",
    maxWidth: 1160,
    "@media (max-width: 600px)": {
      padding: "0 12px",
    },
    "@media (max-width: 1257px)": {
      margin: "24px 0",
      padding: "0 0",
    },
  },
  input: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "20px",
    border: "none",
    width: "83%",
    marginLeft: "17.04px",
  },
  iconButton: {
    marginRight: "10px",
    width: "16px",
    height: "16px",
    color: "#A8B9C6",
    alignSelf: "center",
  },
  searchBar: {
    width: "340px",
    height: "40px",
    background: "#FFFFFF",
    border: "1px solid #DDE5EC",
    borderRadius: "8px",
    display: "flex",
    alignContent: "center",
    justifyContent: "space-between",
  },
  sortBar: {
    marginRight: 12,
    borderSizing: "borderBox",
    "@media (max-width: 700px)": {
      marginTop: "10px",
      marginLeft: "50px",
    },
  },
  formControl: {
    width: "310px",
    height: "40px",
    background: "#FFFFFF",
    border: "1px solid #DDE5EC",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
  selectEmpty: {
    position: "relative",
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "12px",
    color: "#08001D",
    width: "100%",
    inlineSize: "310px",
    overflow: "hidden",
    height: "15px",
    "& svg": {
      display: "none",
    },
    "& div": {
      height: "28px",
      alignItems: "center",
      display: "flex",
      backgroundColor: "rgba(0,0,0,0)",
      color: "#08001D",
    },
    "& :focus": {
      backgroundColor: "rgba(0,0,0,0)",
    },
  },
  sortIcon: {
    position: "absolute",
    right: "-4px",
  },
  MenuItem: {
    backgroundColor: "rgba(0,0,0,0)",
    width: "310px",
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "12px",
    color: "#536471",
  },
  lists: {
    color: "#536471",
    "&:hover": {
      backgroundColor: "#F0F3F7",
    },
  },
  selectedList: {
    color: "#181A1C",
    backgroundColor: "#FFFFFF !important",
  },
  paperStyle: {
    border: "1px solid #DDE5EC",
    boxShadow: "0px 4px 12px rgba(78, 89, 109, 0.05)",
    borderRadius: "8px",
    marginLeft: "-2px",
  },
  tourButton: {
    letterSpacing: "normal",
    fontWeight: 500,
    background: "linear-gradient(266.69deg, #59AFFF 0.23%, #0882F3 99.28%)",
    fontFamily: "Montserrat",
    color: "#FFFFFF",
  },
};
