import { Grid, Typography } from "@material-ui/core";

import React from "react";
import { Trans } from "react-i18next";
import { statusList } from "../../../../constants/statusList";
import styles from "./stackchart.styles";
import { withStyles } from "@material-ui/core/styles";

const LegendView = (props) => {
  // Filter name for Status
  const displayStatusName = (name) => {
    if (props.dataType === "Status") {
      let filterName = statusList.filter((obj) => {
        return obj.value === name;
      })[0];
      if (filterName) return filterName.name;
    } else return name;
  };

  return (
    <Grid container justifyContent="center" style={{ flexWrap: "nowrap" }}>
      <Grid item xs style={{ minWidth: 100 }}>
        <Typography style={styles.severityText}>
          <Trans>Total</Trans>
        </Typography>
        <Typography style={styles.totalText}>
          {props.total_anomalies}
        </Typography>
      </Grid>

      <Grid container style={styles.legendOuter}>
        {/* Toggle legend data depending on switch selection Status/Severity/IEC */}
        {props.updateLegendData &&
          props.updateLegendData.map((data, index) => {
            return (
              <Grid item key={index} style={{ minWidth: 110 }}>
                <Grid container alignItems="center">
                  <span
                    style={{
                      ...styles.severityBox,
                      backgroundColor: data && data.color,
                    }}
                  />
                  <Typography style={styles.severityText}>
                    <Trans>{displayStatusName(data && data.name)}</Trans>
                  </Typography>
                </Grid>
                <Grid container alignItems="center">
                  <Typography style={styles.subHeader}>
                    {data && data.percentage}%
                  </Typography>{" "}
                  <Typography style={styles.subText}>
                    ({data && data.count})
                  </Typography>
                </Grid>
              </Grid>
            );
          })}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(LegendView);
