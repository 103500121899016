import { Trans, withTranslation } from "react-i18next";

import ProTrialCount from "./img/proTrialCount.svg";
import React from "react";
import { connect } from "react-redux";
import styles from "./product.style";
import { withStyles } from "@material-ui/core";

//To handle pro or basic user
const ProSidebarLink = ({
  classes,
  setOpen,
  onHandleShowTrial,
  onHandleShowPlans,
  product,
}) => {
  return (
    product &&
    product.product_type !== "Pro" &&
    (setOpen ? (
      <div className={classes.proSideLink}>
        {product.product_type !== "Basic" && (
          <div
            onClick={() => {
              return onHandleShowTrial(true);
            }}
            role="button"
            tabIndex={"0"}
            aria-hidden="true"
          >
            {product?.remaining_days && (
              <img
                src={ProTrialCount}
                alt="ProTrialCount"
                style={{ marginRight: "5px" }}
              />
            )}
            <span
              className={classes.proLink}
              style={{ textTransform: "uppercase" }}
            >
              <Trans>
                {product?.remaining_days ? "Pro Trial" : "Pro Trial Expired"}
              </Trans>
            </span>
          </div>
        )}
        <span
          className={[classes.proLink, classes.upgradePlan].join(" ")}
          onClick={() => {
            return onHandleShowPlans(true);
          }}
          data-intercom-target="view-product-plans"
          role="button"
          tabIndex={"-1"}
          aria-hidden="true"
        >
          <Trans>Upgrade</Trans>
        </span>
      </div>
    ) : (
      <div
        className={classes.proSideLink}
        style={{
          justifyContent: "flex-start",
          marginTop: "16px",
          paddingLeft: 12,
        }}
      >
        {product?.remaining_days && (
          <div
            onClick={() => {
              return onHandleShowTrial(true);
            }}
            tabIndex={"-2"}
            aria-hidden="true"
          >
            <img
              src={ProTrialCount}
              alt="ProTrialCount"
              width="16"
              height="16"
              style={{ margin: "0 26px" }}
            />
          </div>
        )}
        <span
          className={classes.proLink}
          onClick={() => {
            return onHandleShowPlans(true);
          }}
          style={{
            marginLeft: !product?.remaining_days ? "10px" : "0",
            display: product.product_type === "Basic" ? "flex" : "none",
          }}
          data-intercom-target="view-product-plans"
          role="button"
          tabIndex={"-3"}
          aria-hidden="true"
        >
          <Trans>Upgrade</Trans>
        </span>
      </div>
    ))
  );
};

//Calling redux to get the product information (pro/basic)
const mapStateToProps = (state) => {
  return {
    product: state.product,
  };
};

export default connect(mapStateToProps)(
  withStyles(styles)(withTranslation()(ProSidebarLink))
);
