import { Grid, Paper, Typography } from "@material-ui/core";
import { Trans, withTranslation } from "react-i18next";

import Config from "../../config";
import { Navigate } from "react-router-dom";
import NewLoader from "../../layout/newLoader";
import React from "react";
import { SET_ACTIVITY } from "../../constants/actionTypes";
import _ from "lodash";
import { allRequestHandler } from "../../api";
import { connect } from "react-redux";
import i18n from "i18next";
import moment from "moment";
import shortid from "shortid";
import styles from "./Activity.style";
import { withStyles } from "@material-ui/core/styles";

const requestUrl = Config.hostName;

//Activity page
class Activity extends React.Component {
  activityRef;
  intersectionObserver;

  //Initialize the state variables
  constructor() {
    super();
    this.activityRef = React.createRef();
    this.state = {
      allActivity: [],
      isLoader: true,
      page: 0,
      loadMore: false,
    };

    //Load more activity when user scrolls to the end of the page
    this.intersectionObserver = new IntersectionObserver((entries) => {
      const ratio = entries[0].intersectionRatio;
      if (ratio > 0) {
        this.setState((prevState) => {
          return {
            page: prevState.page + 10,
          };
        }, this.fetchMoreActivity);
      }
    });
  }

  //API call to fetch more activity
  async fetchMoreActivity() {
    this.setState({
      loadMore: true,
    });
    const { page } = this.state;

    try {
      const getActivity = await allRequestHandler({
        requestType: "GET",
        requestUrl: `${requestUrl}/api/activities?start=${page}&limit=10`,
      });

      // On session timeout redirect
      if (getActivity && getActivity.status && getActivity.status === 401) {
        sessionStorage.setItem("jwtToken", "");
        this.setState({ redirect: true });
      } else {
        if (getActivity) {
          this.setState((prevState) => {
            return {
              allActivity: _.orderBy(
                [...prevState.allActivity, ...getActivity.activities_list],
                "activity_time",
                "desc"
              ),
              isLoader: false,
              loadMore: false,
            };
          });
        }
      }
    } catch (error) {
      console.log("error:", error);
    }
  }

  //These functions are called when the component is mounted (at the initial render)
  componentDidMount() {
    //Check if the user is at the bottom of the page and fetch more activity
    this.intersectionObserver.observe(this.activityRef.current);
    this.fetchMoreActivity();
  }

  //Handles the click event on the activity card and opens the anomalies page with the selected defect
  selectActivity = (value) => {
    this.props.setActivity(value);
    localStorage.setItem("sectionId", value.section_id);
    if (value.activity_type === "comment")
      localStorage.setItem("CommentsOn", true);
    else if (value.activity_type === "defect_status")
      localStorage.setItem("defectStatus", true);

    this.props.navigate(`/defects/${value.plant_id}`, { replace: true });
  };

  //Handles the date to be displayed
  handleDate = (act) => {
    const date = this.currentDate(act);
    if (this.d === date) return false;

    this.d = date;
    return true;
  };

  //Get the current date
  currentDate = (act) => {
    const date = moment(act.activity_time, "YYYYMMDD").fromNow();
    const date2 = moment(act.activity_time).format("DD MMM YYYY");
    const temp = date.split(" ");
    if (temp[1] === "hours") return "Today";
    if (temp[0] === "1") return "Yesterday";

    return date2;
  };

  //Renders the activity page
  render() {
    this.d = "date";
    const { classes } = this.props;

    // On session timeout redirect
    if (this.state.redirect) return <Navigate replace to="/" />;

    return (
      <>
        <Grid container alignItems="center" className={classes.header}>
          <Grid item xs>
            <Typography
              className={classes.title}
              style={{ marginLeft: `${this.props.toggleMenu && "30px"}` }}
            >
              <Trans>Activity</Trans>
            </Typography>
          </Grid>
        </Grid>

        {this.state.isLoader ? (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ height: "100%" }}
          >
            <NewLoader />
          </Grid>
        ) : this.state.allActivity && this.state.allActivity.length ? (
          this.state.allActivity.map((act) => {
            return (
              <React.Fragment key={shortid.generate()}>
                {this.handleDate(act) && (
                  <Grid item xs={3}>
                    <Paper className={classes.paper1}>
                      {" "}
                      {this.currentDate(act)}
                    </Paper>
                  </Grid>
                )}

                {(act.activity_type === "comment" ||
                  act.activity_type === "defect_status") && (
                  <Grid container direction="column" style={{ width: "750px" }}>
                    <Grid
                      onClick={() => {
                        return this.selectActivity(act);
                      }}
                      container
                      wrap="nowrap"
                      className={classes.item}
                    >
                      <Grid item className={classes.data}>
                        {moment.utc(act.activity_time).local().format("LT")}
                      </Grid>
                      <Grid
                        item
                        style={{
                          display: "flex",
                          alignItems: "center",
                          paddingLeft: 30,
                        }}
                      >
                        <div className={classes.initNames}>
                          {act.first_name.charAt(0)}
                          {act.last_name.charAt(0)}
                        </div>
                      </Grid>

                      <Grid item xs={8} className={classes.data1}>
                        <strong>
                          {" "}
                          {act.first_name} {act.last_name}{" "}
                        </strong>{" "}
                        {act.description.split(" ").map((data) => {
                          if (data === "#defect_name")
                            return (
                              <span
                                key={shortid.generate()}
                                style={{
                                  color: "#5B4DD3",
                                  fontWeight: 600,
                                }}
                              >
                                {" "}
                                #{`${act.defect_name} `}{" "}
                              </span>
                            );

                          return <span key={data}>{`${i18n.t(data)} `}</span>;
                        })}
                        <Grid item className={classes.name}>
                          {act.plant_name}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </React.Fragment>
            );
          })
        ) : (
          <Typography className={classes.noActivity}>
            <Trans>No Activity Found</Trans>
          </Typography>
        )}
        <div ref={this.activityRef} />
        {!this.state.isLoader && this.state.loadMore ? (
          <Grid container justifyContent="center">
            <NewLoader />
          </Grid>
        ) : (
          ""
        )}
      </>
    );
  }
}

//Calling the redux action to get the fetchActivity function
const mapStateToProps = (state) => {
  return {
    fetchActivity: state.fetchActivity,
  };
};

//Initializing redux to set the activity
const mapDispatchToProps = (dispatch) => {
  return {
    setActivity: (value) => {
      return dispatch({ type: SET_ACTIVITY, payload: value });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(Activity)));
