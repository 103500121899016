import { Trans, withTranslation } from "react-i18next";

import { Button } from "@material-ui/core";
import ProLabel from "../common/ProLabel";
import React from "react";

//Pro user button
const ProButton = ({ onHandleShowPlans }) => {
  return (
    <Button
      variant="contained"
      style={{
        color: "white",
        background: "linear-gradient(266.69deg, #59AFFF 0.23%, #0882F3 99.28%)",
      }}
      onClick={onHandleShowPlans}
    >
      <span>
        <Trans>Upgrade To</Trans>
      </span>
      <ProLabel marginRight="0" marginLeft="5px" show />
    </Button>
  );
};

export default withTranslation()(ProButton);
