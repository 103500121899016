import { Card, CardContent, Grid, Typography } from "@material-ui/core";

import CardTitle from "./cardTitleNew";
import CustomToggleSwitch from "../customStyle/ToggleSwitch";
import CustomTooltip from "../customStyle/Tooltip";
import HorizontalBarChart from "../charts/stackchart/horizontalBarChart";
import InfoIcon from "@material-ui/icons/Info";
import NewLoader from "../../../layout/newLoader";
import ProButton from "../../product/ProButton";
import React from "react";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import { horizontalBarTooltip } from "../../../constants/tooltipDetails";
import powerEnergyConverter from "../../../utils/powerEnergyConverter";
import styles from "./MetadataBox.style";
import { toggleObj } from "../../../constants/dashboardConstants";
import { withStyles } from "@material-ui/core/styles";

//class to handle metadata
class MetadataBox extends React.Component {
  state = {
    selectedMode: "Severity",
    userDetails: this.props.userDetails,
  };

  //Handles the chart change
  handleChartChange = (e, newData) => {
    if (newData !== null) this.setState({ selectedMode: newData });
  };

  handleUpdatedMetadata = (data) => {
    this.setState({
      userDetails: data,
    });
  };

  //renders the metadata box
  render() {
    const { classes } = this.props;

    const convertedPowerLoss = powerEnergyConverter(
      "power",
      this.state.userDetails
        ? this.state.userDetails.power_loss
        : this.props.userDetails?.power_loss
    );

    const selectedAnomalies =
      this.state.selectedMode === "Status" ||
      this.state.selectedMode === "Severity"
        ? this.state.selectedMode === "Status"
          ? this.props.anomaliesStatusData
          : this.props.anomaliesSeverityData
        : this.props.anomaliesIECData;

    const metaValues = [
      {
        name: "DC Capacity",
        count: this.state.userDetails?.dc_capacity
          ? `${this.state.userDetails.dc_capacity.toFixed(2)} MWp`
          : this.props.userDetails?.dc_capacity
          ? `${this.props.userDetails.dc_capacity.toFixed(2)} MWp`
          : "-",
      },
      {
        name: "AC Capacity",
        count: this.state.userDetails?.ac_capacity
          ? `${this.state.userDetails.ac_capacity.toFixed(2)} MW`
          : this.props.userDetails?.ac_capacity
          ? `${this.props.userDetails.ac_capacity.toFixed(2)} MW`
          : "-",
      },
      {
        name: "Power Loss",
        count: this.state.userDetails?.power_loss
          ? `${convertedPowerLoss}`
          : this.props.userDetails?.power_loss
          ? `${convertedPowerLoss}`
          : "-",
      },
      {
        name: "Revenue Loss/yr",
        count: this.state.userDetails?.revenue_loss
          ? this.state.userDetails.revenue_loss
          : this.props.userDetails?.revenue_loss
          ? this.props.userDetails.revenue_loss
          : false,
      },
    ];

    return (
      <Grid container>
        <Grid item style={{ maxWidth: 490 }}>
          <Grid container justifyContent="space-between">
            {/* All meta card component */}
            {metaValues.map((obj, index) => {
              return (
                <React.Fragment key={index}>
                  <CardTitle
                    value={obj.name}
                    count={obj.count}
                    userDetails={this.props.userDetails}
                    getMetadata={(e) => this.handleUpdatedMetadata(e)}
                  />
                </React.Fragment>
              );
            })}
          </Grid>
        </Grid>

        <Grid item xs style={{ padding: 10 }}>
          <Card style={styles.sevCard}>
            <CardContent style={styles.cardInner}>
              <Grid container alignItems="center">
                <Grid item xs>
                  <Typography style={styles.sevTxt}>
                    <Trans>Anomalies</Trans>
                  </Typography>
                </Grid>

                <CustomToggleSwitch
                  value={this.state.selectedMode}
                  onChange={this.handleChartChange}
                  btnGroup={toggleObj}
                />
                <CustomTooltip
                  placement="bottom"
                  title={horizontalBarTooltip}
                  classes={{
                    tooltip: classes.iecTooltip,
                  }}
                >
                  <InfoIcon className={classes.chartInfo} />
                </CustomTooltip>
              </Grid>

              {selectedAnomalies ? (
                this.props.product?.product_type === "Basic" &&
                this.state.selectedMode === "Status" ? (
                  <Grid container>
                    <HorizontalBarChart
                      getChartData={selectedAnomalies}
                      dataType={this.state.selectedMode}
                    />
                    <Grid item className={classes.proPlace}>
                      <ProButton
                        onHandleShowPlans={this.props.onHandleShowPlans}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container>
                    <Grid item xs={12} style={{ width: 300 }}>
                      <HorizontalBarChart
                        getChartData={selectedAnomalies}
                        dataType={this.state.selectedMode}
                      />
                    </Grid>
                  </Grid>
                )
              ) : (
                <Grid
                  container
                  justifyContent="center"
                  style={{ marginTop: "25px" }}
                >
                  <NewLoader />
                </Grid>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

//Calling redux to get information about the product (basic/pro) and the selected language
const mapStateToProps = (state) => {
  return {
    product: state.product,
    language: state.language.selectedLanguage,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(MetadataBox));