import { NavLink, useNavigate } from "react-router-dom";

import CustomTooltip from "../../views/common/customStyle/Tooltip";
import React from "react";
import { Trans } from "react-i18next";
import { callSnackbarAction } from "../../reducers/snackbar";
import { store } from "../../store";
import styles from "./sidebar.style";
import { withStyles } from "@material-ui/core";

function CustomNavlink({
  path,
  onClickHandler,
  setOpen,
  styles,
  title,
  classes,
  Icon,
  isactivePath,
  id,
  outerDiv,
  className,
  name,
  siteListData,
}) {
  const navigate = useNavigate();

  const handleDataLoad = () => {
    if (!siteListData && title === "Anomalies") {
      store.dispatch(
        callSnackbarAction("Anomalies data is loading...", "warning")
      );
      navigate("/dashboard");
    }
  };

  return (
    <div onClick={handleDataLoad} className={outerDiv && outerDiv}>
      <NavLink to={path} onClick={onClickHandler} className={classes.margbot}>
        {setOpen ? (
          <div
            className={
              isactivePath
                ? classes.sidebarmenuactiveSummary
                : classes.sidebarmenuactive
            }
            id={id && id}
            style={styles && styles}
          >
            {Icon}
            <span className={className}>
              <Trans>{name}</Trans>
            </span>
          </div>
        ) : (
          <div>
            <CustomTooltip
              title={title}
              placement="right"
              arrow
              classes={{
                tooltip: classes.sidebarTooltip,
                arrow: classes.tooltipArrow,
              }}
            >
              <div
                className={
                  isactivePath
                    ? classes.sidebarclosedactive
                    : classes.sidebarclosed
                }
              >
                {Icon}
              </div>
            </CustomTooltip>
          </div>
        )}
      </NavLink>
    </div>
  );
}

export default withStyles(styles)(CustomNavlink);
