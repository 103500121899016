import { Navigate, Route, Routes } from "react-router-dom";
import React, { useState } from "react";

import Activity from "../../views/activity/Activity";
import Dashboard from "../../views/dashboard";
import DefectMap from "../../views/defectMap";
import ForgotPassword from "../../views/login/ForgotPassword";
import Help from "../../views/help";
import InspComp from "../../views/inspectonsComp/inspComp";
import Login from "../../views/login/Login";
import MyProfile from "../../views/myProfile";
import PageNotFound from "../../views/common/pageNotFound";
import Reports from "../../views/reports";
import ResetPassword from "../../views/login/resetPassword";
import Settings from "../../views/settings";
import SiteSummary from "../../views/siteSummary";
import Sites from "../../views/sites";
import User from "../../views/users/User";
import UsersListForSitesAccess from "../../views/sites/usersListForSitesAccess";

const Routing = ({
  path,
  validateUser,
  handleResetData,
  loginUrl,
  cookieStorage,
  callSnackbar,
  userDetails,
  siteListData,
  sitesUrl,
  loggedInUser,
  toggleMenu,
  siteId,
  navigate,
  moduleDefectChart,
  showPlans,
  showTrial,
  dashboardUrl,
  onHandleShowPlans,
  url,
  userTimeOut,
  allSites,
  getUser,
  callLoggedInUser,
  loginUrlDash,
  userDetailsInDefectMap,
  userDetailsForLoggedIn,
  Redirect,
}) => {
  const [reportObj, setReportObj] = useState(null);
  const [usersListData, setUsersListData] = useState(null);

  const handleReportObject = (e) => {
    setReportObj(e);
  };

  return (
    <>
      <Routes>
        <React.Fragment>
          {path !== "/reset-password" &&
            path !== "/PageNotFound" &&
            path !== "/ForgotPassword" &&
            path !== "/sites" &&
            !path.includes("/inspectionComp/") &&
            path !== "/dashboard" &&
            path !== "/users" &&
            !path.includes("/api/routes/sites/") &&
            !path.includes("/defects/") &&
            path !== "/" &&
            path !== "/reports" &&
            path !== "/settings" &&
            path !== "/activity/" &&
            path !== "/MyProfile/" && (
              <Route element={<Navigate replace to="/PageNotFound" />} />
            )}

          {Redirect &&
            path !== "/reset-password" &&
            path !== "/PageNotFound" && (
              <Route element={<Navigate replace to="/PageNotFound" />} />
            )}

          {/* Dashboard page */}
          <Route
            path="/dashboard"
            element={
              <Dashboard
                navigate={navigate}
                userDetails={userDetails}
                loggedInUser={loggedInUser}
                siteListData={siteListData}
                moduleDefectChart={moduleDefectChart}
                callSnackbar={callSnackbar}
                showPlans={showPlans}
                showTrial={showTrial}
                loginUrlDash={loginUrlDash}
                dashboardUrl={dashboardUrl}
                toggleMenu={toggleMenu}
                onHandleShowPlans={onHandleShowPlans}
                reportObj={(e) => handleReportObject(e)}
              />
            }
          />

          {/* Sites page */}
          <Route
            path="/sites"
            element={
              <Sites
                siteCardDataForUsers={(data) => setUsersListData(data)}
                userDetails={userDetails}
                siteListData={siteListData}
                sitesUrl={sitesUrl}
                loggedInUser={loggedInUser}
                callSnackbar={callSnackbar}
                toggleMenu={toggleMenu}
              />
            }
          />

          {/* Users List */}
          <Route
            element={
              <UsersListForSitesAccess
                navigate={navigate}
                usersListData={usersListData}
              />
            }
          />

          {/* Sites summary page */}
          <Route
            path="/api/routes/sites/:siteId"
            element={
              <SiteSummary
                userDetails={userDetails}
                moduleDefectChart={moduleDefectChart}
                loggedInUser={loggedInUser}
                callSnackbar={callSnackbar}
                showPlans={showPlans}
                showTrial={showTrial}
                toggleMenu={toggleMenu}
                onHandleShowPlans={onHandleShowPlans}
                url={url}
              />
            }
          />

          {/* Anomalies page */}
          <Route
            path="/defects/:siteId"
            element={
              <DefectMap
                siteId={siteId}
                loggedInUser={loggedInUser}
                userDetails={userDetailsInDefectMap}
                callSnackbar={callSnackbar}
                toggleMenu={toggleMenu}
                onHandleShowPlans={onHandleShowPlans}
                url={url}
              />
            }
          />

          {/* Inspection comparison page */}
          <Route
            path="/inspectionComp/:siteId"
            element={
              siteListData && (
                <InspComp
                  siteListData={siteListData}
                  userDetails={userDetails}
                  loggedInUser={loggedInUser}
                  toggleMenu={toggleMenu}
                  siteId={siteId}
                  navigate={navigate}
                />
              )
            }
          />

          {/* Reports page */}
          <Route
            path="/reports"
            element={
              <Reports
                navigate={navigate}
                loggedInUser={loggedInUser}
                toggleMenu={toggleMenu}
                callSnackbar={callSnackbar}
                userTimeOut={userTimeOut}
                onHandleShowPlans={onHandleShowPlans}
                allSites={allSites}
                reportObj={reportObj}
              />
            }
          />

          {/* Activity page */}
          <Route
            path="/Activity"
            element={
              <Activity
                userDetails={userDetailsForLoggedIn}
                getUser={getUser}
                toggleMenu={toggleMenu}
                navigate={navigate}
              />
            }
          />

          {/* Users page */}
          <Route
            path="/users"
            element={
              <User
                userDetails={userDetailsForLoggedIn}
                toggleMenu={toggleMenu}
              />
            }
          />

          {/* Settings page */}
          <Route
            path="/settings"
            element={
              <Settings
                userDetails={userDetailsForLoggedIn}
                toggleMenu={toggleMenu}
              />
            }
          />

          {/* My profile page */}
          <Route
            path="/MyProfile"
            element={
              <MyProfile
                userDetails={userDetailsForLoggedIn}
                loggedInUser={loggedInUser}
                callLoggedInUser={callLoggedInUser}
                callSnackbar={callSnackbar}
                toggleMenu={toggleMenu}
              />
            }
          />

          {/* Login page */}
          <Route
            path={"/"}
            element={
              <Login
                validateUser={validateUser}
                handleResetData={handleResetData}
                loginUrl={loginUrl}
                cookieStorage={cookieStorage}
              />
            }
          />

          {/* Reset password page */}
          <Route
            path="/reset-password"
            element={
              <ResetPassword
                callSnackbar={(msg) => {
                  return this.callSnackbar(msg);
                }}
              />
            }
          />

          {/* Forgot password page */}
          <Route path="/ForgotPassword" element={<ForgotPassword />} />

          {/* Help */}
          <Route
            path="/Help"
            element={
              <Help userDetails={userDetails} loggedInUser={loggedInUser} />
            }
          />

          {/* Page not found route */}
          <Route path="/PageNotFound" element={<PageNotFound />} />
        </React.Fragment>
      </Routes>
    </>
  );
};

export default Routing;