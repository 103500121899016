import {
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  Grid,
  Hidden,
  List,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { Trans, withTranslation } from "react-i18next";
import { URLS, statusList } from "../../constants";

import Comments from "./Comments";
import Details from "./Details";
import { Navigate } from "react-router-dom";
import NextImage from "../../assets/img/nextImg.svg";
import PanelDetails from "./PanelDetails";
import PrevImage from "../../assets/img/prevImg.svg";
import ProLabel from "../common/ProLabel";
import ProofUpload from "./ProofUpload";
import React from "react";
import SearchBar from "../common/customStyle/searchBar";
import SideListView from "./sideListView";
import _ from "lodash";
import { allRequestHandler } from "../../api";
import cross from "../../assets/img/cross.png";
import falsepositiveImg from "../../assets/img/falsePositive.svg";
import iec1 from "../../assets/img/iec1.svg";
import iec2 from "../../assets/img/iec2.svg";
import iec3 from "../../assets/img/iec3.svg";
import minorImg from "../../assets/img/Subtract.svg";
import moderateIcon from "../../assets/img/moderateicon.svg";
import notfoundImg from "../../assets/img/notfound.svg";
import pendingImg from "../../assets/img/pending.svg";
import progressImg from "../../assets/img/progress.svg";
import resolvedImg from "../../assets/img/resolved.svg";
import styles from "./defectMap.style";
import { t } from "i18next";
import uncategorized from "../../assets/img/uncategorized.svg";
import vectorImg from "../../assets/img/Vector.svg";
import { withStyles } from "@material-ui/core/styles";

//Defect details view
class DefectDetailsView extends React.Component {
  // listRef;
  intersectionObserver;

  //Initialize state
  constructor() {
    super();
    // this.listRef = React.createRef();
    this.state = {
      selectedDefect: null,
      tabValue: 0,
      defectData: null,
      searchDefect: null,
      filterStatusValue: "All",
      href_list: null,
      proofDialog: false,
      // inspArray: "",
    };
    // this.intersectionObserver = new IntersectionObserver((entries) => {
    // const ratio = entries[0].intersectionRatio;
    // if (ratio > 0) {
    // this.setState(
    //   (prevState) => ({
    //     page: prevState.page + 10,
    //   }),
    //   this.fetchMoreActivity
    // );
    // }
    // });
  }

  //These functions are called on the initial render of the component
  componentDidMount() {
    // this.intersectionObserver.observe(this.listRef.current);
    if (localStorage.getItem("CommentsOn") === "true") {
      // skipcq Avoid using setState in componentDidMount JS-0442
      this.setState({ tabValue: 2 });
      localStorage.setItem("CommentsOn", false);
    } else {
      //Setting tab value to 0 (ANomalies Details Tab)
      // skipcq Avoid using setState in componentDidMount JS-0442
      this.setState({ tabValue: 0 });
    }
    // this.setState({
    //   defectData: this.props.defectData,
    // });
  }

  //These functions are called whenever the component updates
  componentDidUpdate(prevProps) {
    //If selected defect is changed, hit api to get defect details of new selected defect
    if (
      this.props.selectedDefect &&
      prevProps.selectedDefect !== this.props.selectedDefect
    ) {
      this.callDetailsApi();
    }

    if (prevProps.defectData !== this.props.defectData) {
      // skipcq Avoid usage of setState in componentDidUpdate JS-0443
      this.setState({
        defectData: this.props.defectData,
      });
    }

    if (
      this.props.isDataUpload 
    ) {
      this.callDetailsApi();
      this.props.bulkDataUpload(false);
    }

    // Display list if selectedSection is changed
    if (prevProps.selectedSection !== this.props.selectedSection) {
      // skipcq Avoid usage of setState in componentDidUpdate JS-0443
      this.setState({
        selectedDefect: null,
        filterStatusValue: "All",
        searchDefect: null,
      });
      this.props.removeSelectedDefect("removeMarker");
    }
  }

  //API call to get defect details
  callDetailsApi = async () => {
    let fetchDetails = 0;
    if (!this.props.isDigitized) {
      fetchDetails = await allRequestHandler({
        requestType: "GET",
        requestUrl:
          URLS.ANOMALY_DETAILS + this.props.selectedDefect.defect_id.toString(),
      });
    } else {
      fetchDetails = await allRequestHandler({
        requestType: "GET",
        requestUrl: `${
          URLS.ANOMALY_DETAILS
        }${this.props.selectedDefect.defect_id.toString()}/panels/${this.props.selectedDefect.panel_id.toString()}`,
      });
    }

    // On session timeout redirect
    // if (fetchDetails && fetchDetails.status && fetchDetails.status === 401) {
    //   sessionStorage.setItem("jwtToken", "");
    //   this.setState({ redirect: true });
    // }

    if (fetchDetails) {
      const filterDetail = fetchDetails.anomaly_details.filter((getData) => {
        // if (getData.inspection_date !== this.props.selectedInspectionDate) {
        //   getData.inspection_date = this.props.selectedInspectionDate;
        // }
        return getData.inspection_date === this.props.selectedInspectionDate;
      })[0];

      if (filterDetail) {
        this.setState({
          // inspArray: fetchDetails.anomaly_details,
          selectedDefect: filterDetail,
          href_list: fetchDetails.href_list,
        });
      }
    }
  };

  //Locally update the defect details to show changes in the view
  updateSelectedDefect = (value) => {
    // skipcq Avoid using this.state inside this.setState() JS-0435
    const localDefects = this.state.defectData;
    const indexToUpdate = localDefects.findIndex((defect) => {
      return defect.properties.defect_no === value.defect_no;
    });
    localDefects[indexToUpdate].properties = value;

    this.setState({ selectedDefect: value, defectData: localDefects });
    this.props.updateDuplicateProperties(value);
  };

  //Handles the navigation (prev/next) of the selected defects
  handleNavigation = (value) => {
    this.props.handleNavigation(this.state.selectedDefect, value);
  };

  //Handle change in the input fields
  handleChange = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };

  //Handles the status change
  handleStatus = async (e, id, oldStatus) => {
    if (e.target.value === "falsepositive") {
      //If false positive upload proof dialog
      this.setState({ proofDialog: true, defectId: id });
    } else {
      if (oldStatus !== e.target.value && oldStatus !== "falsepositive") {
        await allRequestHandler({
          requestType: "PATCH",
          requestUrl: `${URLS.GET_COMMENT_LIST}${id}/status/${e.target.value}`,
          requestData: "",
        });

        this.updateStatusLabel(id, e.target.value);

        this.setState((prevState) => {
          return {
            defectData: prevState.defectData.map((x) => {
              if (x.properties.defect_id === id) {
                x.properties.status = e.target.value;
              }
              return x;
            }),
          };
        });

        this.props.updateMap(e.target.value, id);
      }
    }
  };

  //Locally update the status of the updated defect to show changes in the view
  updateStatusLabel = (id, value) => {
    // Update status from defect details
    if (this.state.selectedDefect) {
      // skipcq Avoid using this.state inside this.setState() JS-0435
      const oldDefect = this.state.selectedDefect;
      oldDefect.status = value;
      this.setState({ selectedDefect: oldDefect });
      this.props.callDefects(oldDefect);
    } else {
      this.props.updateListLocally(id, value);
    }
  };

  //Handles the click on the defect list
  clickDefect = (value) => {
    this.props.showDefectSidebar(value);
    this.props.removeSelectedDefect(value);
  };

  //Handle filters
  handleFilterStatus = (event) => {
    if (event.target.value === "All")
      this.setState({
        defectData: _.orderBy(
          this.props.allData,
          "[properties][defect_no]",
          "asc"
        ),
        filterStatusValue: "All",
      });
    else {
      const filterList =
        this.props.allData &&
        this.props.allData.length &&
        this.props.allData.filter((def) => {
          return def.properties.status === event.target.value;
        });

      this.setState({
        filterStatusValue: event.target.value,
        defectData: _.orderBy(filterList, "[properties][defect_no]", "asc"),
      });
    }
  };

  //Close the defect sidebar and remove the highlight marker from the map
  closeDetailsView = () => {
    if (this.props.selectedView === "table") this.props.closeTableSidebar();
    else {
      this.props.removeSelectedDefect("removeMarker");
      this.setState({ selectedDefect: null });
    }
  };

  //Closes the proof upload dialog
  closeProofDialog = () => {
    this.setState({ proofDialog: false });
  };

  //Handles the search input
  setSearchDefect = (value) => {
    this.setState({ searchDefect: value });
  };

  //Renders the defect sidebar
  render() {
    const { classes } = this.props;
    // Search defect by name
    let defectList = this.state.defectData;
    if (defectList && defectList.length && this.state.searchDefect)
      defectList =
        defectList &&
        defectList.length &&
        defectList.filter((def) => {
          return (
            def.properties.defect_no
              .toString()
              .toLocaleLowerCase()
              .indexOf(this.state.searchDefect.toLocaleLowerCase()) !== -1
          );
        });

    // On session timeout redirect
    if (this.state.redirect) return <Navigate replace to="/" />;

    //Updated status issue
    let filterStatusName = "pending";
    if (this.state.selectedDefect) {
      if (
        this.state.selectedDefect.status === "resolved" ||
        this.state.selectedDefect.status === "Resolved"
      )
        filterStatusName = "resolved";
      else if (
        this.state.selectedDefect.status === "falsepositive" ||
        this.state.selectedDefect.status === "False +Ve"
      )
        filterStatusName = "falsepositive";
      else if (
        this.state.selectedDefect.status === "notfound" ||
        this.state.selectedDefect.status === "Not Found"
      )
        filterStatusName = "notfound";
      else if (
        this.state.selectedDefect.status === "inprogress" ||
        this.state.selectedDefect.status === "In Progress"
      )
        filterStatusName = "inprogress";
      // else filterStatusName = "pending";
    }

    return (
      <>
        {this.state.selectedDefect ? (
          <>
            <Grid container alignItems="center">
              <Grid item xs className={classes.defSideHead}>
                <Grid
                  style={{
                    borderLeft: `3px solid ${this.state.selectedDefect.color_code}`,
                    padding: "4px",
                    paddingLeft: 10,
                  }}
                  container
                  alignItems="center"
                >
                  <Typography className={classes.faultNo} component={"div"}>
                    {" "}
                    #{this.state.selectedDefect.defect_no}{" "}
                  </Typography>

                  <Typography
                    container="true"
                    style={{ alignItems: "center" }}
                    className={classes.dot}
                  />

                  <Typography
                    className={classes.anomalyCoordinate}
                    component={"div"}
                  >
                    {this.state.selectedDefect.module_coordinates
                      ? this.state.selectedDefect.module_coordinates
                      : "-"}
                  </Typography>

                  <Typography container="true" className={classes.dot} />

                  <Typography
                    className={classes.anomalyLabel}
                    component={"div"}
                  >
                    {this.state.selectedDefect.label
                      ? this.state.selectedDefect.label
                      : "-"}
                  </Typography>

                  <Typography container="true" className={classes.dot} />

                  <Typography className={classes.anomalyId} component={"div"}>
                    {this.state.selectedDefect &&
                    this.state.selectedDefect.string_reference
                      ? this.state.selectedDefect.string_reference
                      : "-"}
                  </Typography>

                  <Grid container>
                    <Typography
                      container="true"
                      className={classes.detailTitle}
                      component={"div"}
                    >
                      <Trans>
                        {this.state.selectedDefect.defect_type_name}
                      </Trans>

                      <Grid className={classes.SmallDot} />

                      {/* iec_icon added */}
                      {this.state.selectedDefect &&
                      this.state.selectedDefect.iec_category === "IEC 1" ? (
                        <img
                          alt="iecImg"
                          className={classes.btnImg}
                          src={iec1}
                          style={{ marginLeft: "13.6px" }}
                        />
                      ) : this.state.selectedDefect &&
                        this.state.selectedDefect.iec_category === "IEC 2" ? (
                        <img
                          alt="iecImg"
                          className={classes.btnImg}
                          src={iec2}
                          style={{ marginLeft: "13.6px" }}
                        />
                      ) : this.state.selectedDefect &&
                        this.state.selectedDefect.iec_category === "IEC 3" ? (
                        <img
                          alt="iecImg"
                          className={classes.btnImg}
                          src={iec3}
                          style={{ marginLeft: "13.6px" }}
                        />
                      ) : (
                        <img
                          alt="iecImg"
                          className={classes.btnImg}
                          src={uncategorized}
                          style={{ marginLeft: "13.6px" }}
                        />
                      )}

                      {/* Severity icon added */}
                      {this.state.selectedDefect &&
                      this.state.selectedDefect.severity_type === "Critical" ? (
                        <img
                          alt="sevImg"
                          className={classes.btnImg}
                          src={vectorImg}
                        />
                      ) : this.state.selectedDefect &&
                        this.state.selectedDefect.severity_type ===
                          "Moderate" ? (
                        <img
                          alt="sevImg"
                          className={[classes.btnImg, classes.modeIcon].join(
                            " "
                          )}
                          src={moderateIcon}
                        />
                      ) : (
                        <img
                          alt="sevImg"
                          className={classes.btnImg}
                          src={minorImg}
                        />
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              {this.props.selectedView !== "table" && (
                <Hidden smDown>
                  <Grid item style={{ marginRight: 10 }}>
                    <Grid container>
                      {/* Update Status from defect details */}
                      <div
                        style={{ cursor: "Pointer", marginRight: 10 }}
                        onClick={() => {
                          return this.handleNavigation("Back");
                        }}
                        role="button"
                        tabIndex={"0"}
                        aria-hidden="true"
                      >
                        <img
                          alt="prevImg"
                          src={PrevImage}
                          width={32}
                          height={32}
                        />
                      </div>

                      <div
                        style={{ cursor: "Pointer" }}
                        onClick={() => {
                          return this.handleNavigation("Next");
                        }}
                        role="button"
                        tabIndex={"-1"}
                        aria-hidden="true"
                      >
                        <img
                          alt="nextImg"
                          src={NextImage}
                          width={32}
                          height={32}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Hidden>
              )}
              <Grid item style={{ width: 50 }}>
                <img
                  src={cross}
                  width={45}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    return this.closeDetailsView();
                  }}
                  alt="close"
                  aria-hidden="true"
                />
              </Grid>
            </Grid>

            <Divider />

            <Tabs
              classes={{
                indicator: classes.indicatorColor,
              }}
              textColor="primary"
              aria-label="simple tabs example"
              value={this.state.tabValue}
              onChange={this.handleChange}
              variant="fullWidth"
              style={{ borderBottom: "1px solid #E4EAF5", marginTop: "1px" }}
            >
              <Tab
                style={
                  this.state.tabValue === 0
                    ? styles.selectedTab
                    : styles.tabLabel
                }
                label={t("Anomaly")}
                data-intercom-target="defect-details"
              />
              <Tab
                style={
                  this.state.tabValue === 1
                    ? styles.selectedTab
                    : styles.tabLabel
                }
                label={t("Panel Details")}
                data-intercom-target="defect-panel-details"
              />
              <Tab
                style={
                  this.state.tabValue === 2
                    ? styles.selectedTab
                    : styles.tabLabel
                }
                label={t("Comment")}
                data-intercom-target="defect-comment"
              />
              <div className={classes.commentProLabel}>
                <ProLabel marginRight="0" marginLeft="0" show />
              </div>
            </Tabs>

            {this.props.selectedDefect && this.state.tabValue === 0 ? (
              <Details
                selectedDefect={this.state.selectedDefect}
                href_list={this.state.href_list}
                onHandleDropdownStatus={this.handleStatus}
                statusList={statusList}
                filterStatusName={filterStatusName}
                isDigitized={this.props.isDigitized}
                handleStatus={(id) => {
                  this.setState({ proofDialog: true, defectId: id });
                }}
                selectedInspectionDate={this.props.selectedInspectionDate}
              />
            ) : this.state.tabValue === 1 ? (
              <PanelDetails
                href_list={this.state.href_list}
                selectedDefect={this.state.selectedDefect}
                callSnackbar={(msg, sev) => {
                  return this.props.callSnackbar(msg, sev);
                }}
                selectedSiteName={this.props.selectedSiteName}
                loggedInUser={this.props.loggedInUser}
                isDigitized={this.props.isDigitized}
                updateSelectedDefect={(value) => {
                  this.updateSelectedDefect(value);
                }}
              />
            ) : (
              this.state.tabValue === 2 && (
                <Comments
                  selectedDefect={this.state.selectedDefect}
                  href_list={this.state.href_list}
                  updateDefect={(def) => {
                    return this.props.updateDefect(def);
                  }}
                  isDigitized={this.props.isDigitized}
                  dataUpdated={this.props.dataUpdated}
                />
              )
            )}
          </>
        ) : (
          <>
            <Grid
              container
              alignItems="center"
              style={{ padding: "10px 20px 5px" }}
            >
              <SearchBar
                keyword={this.state.searchDefect ? this.state.searchDefect : ""}
                placeholder={t("Search anomaly number...")}
                setKeyword={this.setSearchDefect}
              />
              <Hidden only={["xs", "sm"]}>
                <Typography className={classes.titleText} component={"div"}>
                  {" "}
                  <Trans>Status</Trans> :{" "}
                </Typography>
                <FormControl variant="outlined">
                  <Select
                    value={this.state.filterStatusValue}
                    MenuProps={{
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      classes: { paper: classes.blockMenu },
                    }}
                    className={classes.selectBlock}
                    onChange={(e) => {
                      e.stopPropagation();
                      this.handleFilterStatus(e);
                    }}
                    style={{ height: 40 }}
                  >
                    <MenuItem
                      value="All"
                      ListItemClasses={{
                        root: classes.blockList,
                        selected: classes.selectedList,
                      }}
                    >
                      <Trans>All</Trans>
                    </MenuItem>
                    {statusList.map((s) => {
                      return (
                        <MenuItem
                          key={s.value}
                          value={s.value}
                          style={{ zIndex: 100 }}
                          ListItemClasses={{
                            root: classes.blockList,
                            selected: classes.selectedList,
                          }}
                        >
                          <Typography
                            component={"div"}
                            className={classes.plantName}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {s.value === "pending" ? (
                              <img
                                alt="statusImg"
                                className={classes.btnImg}
                                src={pendingImg}
                              />
                            ) : s.value === "falsepositive" ? (
                              <img
                                alt="statusImg"
                                className={classes.btnImg}
                                src={falsepositiveImg}
                              />
                            ) : s.value === "resolved" ? (
                              <img
                                alt="statusImg"
                                className={classes.btnImg}
                                src={resolvedImg}
                              />
                            ) : s.value === "inprogress" ? (
                              <img
                                alt="statusImg"
                                className={classes.btnImg}
                                src={progressImg}
                              />
                            ) : (
                              <img
                                alt="statusImg"
                                className={classes.btnImg}
                                src={notfoundImg}
                              />
                            )}{" "}
                            <Trans>{s.name}</Trans>
                          </Typography>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Hidden>
            </Grid>

            <Typography className={classes.secListHeader} component={"div"}>
              {" "}
              <Trans>{this.props.selectedSectionName}</Trans>{" "}
            </Typography>
            <List className={classes.bgGray} style={{ paddingBottom: "0px" }}>
              <SideListView
                defectList={defectList}
                handleStatus={() => {
                  return this.handleStatus;
                }}
                showDefectSidebar={(value) => {
                  return this.props.showDefectSidebar(value);
                }}
                removeSelectedDefect={(value) => {
                  return this.props.removeSelectedDefect(value);
                }}
              />
              {/* <div ref={this.listRef}></div> */}
            </List>
          </>
        )}

        {/* On select of falsepositive open proof dialog */}
        <Dialog
          open={this.state.proofDialog}
          onClose={() => {
            return this.closeProofDialog();
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent style={{ padding: 0 }}>
            <ProofUpload
              closeProofDialog={() => {
                return this.closeProofDialog();
              }}
              defectId={this.state.defectId && this.state.defectId}
              updateListLocally={(id, status) => {
                return this.updateStatusLabel(id, status);
              }}
            />
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

export default withStyles(styles)(withTranslation()(DefectDetailsView));
