import { Grid, IconButton, InputBase } from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import SearchIcon from "../../../assets/img/search.svg";
import { withStyles } from "@material-ui/core/styles";

//searchbar styles
const styles = () => {
  return {
    outerSearch: {
      width: 240,
      height: 36,
      margin: "0 12px",
      background: "#FFFFFF",
      border: "1px solid #DDE5EC",
      borderRadius: "8px",
      display: "flex",
      alignContent: "center",
      justifyContent: "space-between",
    },
    iconBtn: {
      marginRight: 5,
      marginLeft: "20px",
      width: "10px",
      height: "16px",
      color: "#A8B9C6",
      alignSelf: "center",
    },
    inputBase: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "20px",
      border: "none",
      padding: "8px",
      width: "83%",
      paddingBottom: "3px",
    },
  };
};

//custom search bar
const searchBar = (props) => {
  const { classes } = props;

  return (
    <Grid
      item
      data-intercom-target="search-anomaly1"
      className={classes.outerSearch}
    >
      <IconButton
        disabled
        type="submit"
        aria-label="search"
        size="small"
        variant="text"
        className={classes.iconBtn}
      >
        <span>
          <img src={SearchIcon} alt="searchIcon" />
        </span>
      </IconButton>
      <InputBase
        value={props.keyword}
        classes={{
          root: classes.inputBase,
        }}
        name="input"
        onChange={(e) => {
          return props.setKeyword(e.target.value);
        }}
        placeholder={props.placeholder}
      />
      {props.keyword !== null && props.keyword !== "" && (
        <IconButton
          aria-label="toggle password visibility"
          onClick={() => {
            props.setKeyword("");
          }}
          style={{ padding: 8, outline: "none" }}
        >
          <CloseIcon />
        </IconButton>
      )}
    </Grid>
  );
};

export default withStyles(styles)(searchBar);
