/* eslint-disable react/no-unused-prop-types */

import React, { Component } from "react";
import {
  BarcodePicker as ScanditSDKBarcodePicker,
  configure,
} from "scandit-sdk";

import PropTypes from "prop-types";
import { barcodeKey } from "../../../config";

// Configure the library and activate it with a license key
const configurationPromise = configure(barcodeKey, {
  engineLocation: "https://cdn.jsdelivr.net/npm/scandit-sdk@5.x",
});
// .catch((error) => {
//   alert(error);
// });

//JS Styles
const style = {
  position: "absolute",
  top: "0",
  bottom: "0",
  left: "0",
  right: "0",
  margin: "auto",
  maxWidth: "1280px",
  maxHeight: "80%",
};

//barcode scan
class BarcodePicker extends Component {
  //Default prop types for barcode scanner
  static propTypes = {
    visible: PropTypes.bool,
    playSoundOnScan: PropTypes.bool,
    vibrateOnScan: PropTypes.bool,
    scanningPaused: PropTypes.bool,
    guiStyle: PropTypes.string,
    videoFit: PropTypes.string,
    scanSettings: PropTypes.shape,
    enableCameraSwitcher: PropTypes.bool,
    enableTorchToggle: PropTypes.bool,
    enableTapToFocus: PropTypes.bool,
    enablePinchToZoom: PropTypes.bool,
    accessCamera: PropTypes.bool,
    camera: PropTypes.shape,
    cameraSettings: PropTypes.shape,
    targetScanningFPS: PropTypes.number,
    onScan: PropTypes.func,
    onError: PropTypes.func,
  };

  //Initialize states
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  //These functions are called on the initial render of the component
  componentDidMount() {
    //Handles promise for barcode scanner and sets the barcode picker
    configurationPromise.then(() => {
      ScanditSDKBarcodePicker.create(this.ref.current, this.props).then(
        (barcodePicker) => {
          this.barcodePicker = barcodePicker;
          if (this.props.onScan !== null) {
            barcodePicker.on("scan", this.props.onScan);
          }
          if (this.props.onError !== null) {
            barcodePicker.on("scanError", this.props.onError);
          }
        }
      );
    });
  }

  //These functions are called when the component is unmounted
  componentWillUnmount() {
    //Remove the barcode picker from the DOM obn the component unmount
    if (this.barcodePicker !== null) {
      this.barcodePicker.destroy();
    }
  }

  //These functions are called when the component is updated
  componentDidUpdate(prevProps) {
    // These are just some examples of how to react to some possible property changes
    if (
      JSON.stringify(prevProps.scanSettings) !==
      JSON.stringify(this.props.scanSettings)
    ) {
      //Update barcode picker settings
      this.barcodePicker.applyScanSettings(this.props.scanSettings);
    }

    if (prevProps.visible !== this.props.visible) {
      this.barcodePicker.setVisible(this.props.visible);
    }
  }

  //Renders the barcode picker
  render() {
    return <div ref={this.ref} style={style} />;
  }
}

export default BarcodePicker;
