import { createTheme } from "@material-ui/core";

//theme provider
const theme = createTheme({
  palette: {
    primary: {
      main: "#5B4DD3",
    },
  },
  overrides: {
    MuiCollapse: {
      hidden: {
        visibility: "unset",
        minHeight: "130px !important",
      },
    },
    MuiInput: {
      underline: {
        "&::before": {
          borderBottom: "none",
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: "capitalize",
        margin: 5,
        "&: focus": {
          outline: "none",
        },
      },
      label: {
        fontSize: 12,
      },
    },
    MuiSelect: {
      root: {
        // border: '1px solid #DBDFE7',
        backgroundColor: "#ffffff",
        padding: "6px 26px 6px 8px",
        fontSize: 12,
        fontWeight: 500,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.67,
        letterSpacing: "normal",
        color: "#536471",
      },
      icon: {
        color: "#A8B9C6",
      },
      select: {
        "&:focus": {
          backgroundColor: "#fff",
        },
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: 12,
        fontWeight: 500,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.67,
        letterSpacing: "normal",
        color: "#536471",
      },
    },
    MuiDrawer: {
      paper: {
        marginTop: 72,
        height: "calc(100vh - 72px)",
      },
    },
    MuiAlert: {
      filledError: {
        backgroundColor: "#fff6f5",
        color: "#c91808",
        borderRadius: 10,
      },
      filledInfo: {
        backgroundColor: "#f3f7ff",
        color: "#427be4",
        borderRadius: 10,
      },
      filledWarning: {
        backgroundColor: "#fff8e5",
        color: "#a87d30",
        borderRadius: 10,
      },
    },
    MuiTypography: {
      body1: {
        fontFamily: "Montserrat",
      },
    },
    MuiInputBase: {
      root: {
        border: "1px solid #fff",
        fontFamily: "Montserrat",
      },
      input: {
        color: "#08001D",
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 6,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: "#E4EAF5",
      },
    },
    MuiListItem: {
      gutters: {
        paddingLeft: 10,
        paddingRight: 10,
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: "1px solid #E4EAF5",
      },
    },
    MuiCard: {
      root: {
        boxShadow: "0px 4px 12px rgba(78, 89, 109, 0.05)",
        borderRadius: 8,
      },
    },
  },
});

export default theme;
