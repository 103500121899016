import ClipLoader from "react-spinners/ClipLoader";
import React from "react";

//loader to use while rendering
class Spinner extends React.Component {
  //render loader
  render() {
    return (
      <div
        style={{
          marginTop: this.props.marginTop ? this.props.marginTop : "25%",
          textAlign: "center",
        }}
      >
        <ClipLoader
          size={this.props.size ? this.props.size : 80}
          color={"#7363FB"}
        />
      </div>
    );
  }
}

export default Spinner;
