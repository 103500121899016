import React, { useState } from "react";

import DownloadPopup from "./popups/DownloadPopup";
import ProLabel from "../common/ProLabel";
import SwiperDetails from "./SwiperDetails";
import styles from "./reports.style";
import { withStyles } from "@material-ui/core/styles";

//report download type
const DownloadType = ({
  classes,
  type,
  reportType,
  plantList,
  callSnackbar,
  handleShowPlans,
  getReportHistory,
  loggedInUser,
  isDownloading,
  generateReport,
  handleReportDetails,
  anomalies,
}) => {
  const [showDownloadPopup, setShowDownloadPopup] = useState(false);
  const warrantyProLabel = (
    <span className={classes.warrantyProLabel}>
      <ProLabel />
    </span>
  );
  //report download type
  return (
    <>
      {reportType.type === "warranty_claim_report" && (
        <SwiperDetails
          proLabel={warrantyProLabel}
          type={type}
          classes={classes}
          setShowDownloadPopup={setShowDownloadPopup}
        />
      )}
      {reportType.type !== "warranty_claim_report" && reportType !== "" && (
        <SwiperDetails
          type={type}
          classes={classes}
          setShowDownloadPopup={setShowDownloadPopup}
        />
      )}
      {showDownloadPopup && (
        <DownloadPopup
          reportDetails={(e) => handleReportDetails(e)}
          type={type}
          reportType={reportType}
          plantList={plantList}
          hideDownloadPopup={() => {
            return setShowDownloadPopup(false);
          }}
          isDownloading={isDownloading}
          generateReport={generateReport}
          callSnackbar={callSnackbar}
          handleShowPlans={handleShowPlans}
          getReportHistory={getReportHistory}
          loggedInUser={loggedInUser}
          anomalies={anomalies}
        />
      )}
    </>
  );
};

export default withStyles(styles)(DownloadType);