import iec1Icon from "../../../assets/img/iec1.svg";
import iec2Icon from "../../../assets/img/iec2.svg";
import iec3Icon from "../../../assets/img/iec3.svg";
import uncategorisedIcon from "../../../assets/img/uncategorized.svg";

const data = [
  { category: "Category", description: "Description", color: "" },
  {
    category: "IEC1",
    description: "Healthy module, no abnormalities",
    img: iec1Icon,
  },
  {
    category: "IEC2",
    description: "Thermal abnormality, medium risk",
    img: iec2Icon,
  },
  {
    category: "IEC3",
    description: "Safety relevant thermal abnormality, high risk",
    img: iec3Icon,
  },
  {
    category: "Uncategorised",
    description: "Unclassified abnormalities under IEC 62446-3 ",
    img: uncategorisedIcon,
  },
];

export default data;
