import { Grid, Typography } from "@material-ui/core";

import DownloadType from "./DownloadType";
import NewLoader from "../../layout/newLoader";
import React from "react";
import { Trans } from "react-i18next";
import { downloadTypes } from "../../constants";
import styles from "./reports.style";
import { withStyles } from "@material-ui/core/styles";

//To handle report download
const DownloadReports = ({
  classes,
  callSnackbar,
  plantList,
  reportTypes,
  handleShowPlans,
  getReportHistory,
  loggedInUser,
  isDownloading,
  generateReport,
  handleReportDetails,
  anomalies,
}) => {
  //Get the format to download the report
  const typeResolver = (item) => {
    return downloadTypes[item.type] && downloadTypes[item.type];
  };

  //This renders the download reports page
  return (
    <Grid
      className={classes.downloadSection}
      data-intercom-target="download-reports-section"
    >
      <Typography className={classes.subtitle}>
        <Trans>DOWNLOAD REPORTS (PDF)</Trans>
      </Typography>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        lg={12}
        sm={11}
        item
        className={classes.flexWrap}
      >
        {reportTypes && reportTypes.length ? (
          reportTypes.map((reportType) => {
            const type = typeResolver(reportType);
            return (
              <React.Fragment key={reportType.type}>
                {/* #TODO inspection report removed */}
                {reportType.type !== "inspection_report" &&
                  reportType.type !== "warranty_claim_report" &&
                  reportType.type !== "" && (
                    <Grid
                      item
                      lg={4}
                      md={6}
                      xs={10}
                      sm={6}
                      style={{ padding: 0 }}
                      className={classes.gridgap}
                    >
                      <DownloadType
                        handleReportDetails={(e) => handleReportDetails(e)}
                        isDownloading={isDownloading}
                        generateReport={generateReport}
                        type={type}
                        reportType={reportType}
                        plantList={plantList}
                        callSnackbar={callSnackbar}
                        handleShowPlans={handleShowPlans}
                        getReportHistory={getReportHistory}
                        loggedInUser={loggedInUser}
                        anomalies={anomalies}
                      />
                    </Grid>
                  )}
              </React.Fragment>
            );
          })
        ) : (
          <Grid className={classes.spinContainer}>
            <NewLoader />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(DownloadReports);