import { Grid, withStyles } from "@material-ui/core";
import React, { useState } from "react";
import { URLS, plans } from "../../constants";

import CloseIcon from "@material-ui/icons/Close";
import MsgSentPopup from "./MsgSentPopup";
import Product from "./Product";
import ProductBg from "./img/productBg.svg";
import { Trans } from "react-i18next";
import { allRequestHandler } from "../../api";
import styles from "./product.style";

//To show pro plan
const ViewPlans = ({ classes, onHandleShowPlans }) => {
  const [msgSent, setMsgSent] = useState(false);

  //request pro user
  const contactSalesHandler = async (type) => {
    if (type === "Pro") {
      try {
        await allRequestHandler({
          requestType: "POST",
          requestUrl: URLS.REQUEST_PRO,
          requestData: "",
        });
        setMsgSent(true);
      } catch (error) {
        // handle error
      }
    }
  };

  //display msg after successful request
  const msgSentHandler = () => {
    setMsgSent(false);
    onHandleShowPlans(false);
  };

  return (
    <Grid
      container
      className={classes.popupContainer}
      direction="column"
      wrap="nowrap"
    >
      <div container style={{ width: "100%", background: "white" }}>
        <Grid
          container
          alignItems="center"
          direction="column"
          style={{
            height: "353px",
            color: "white",
            backgroundImage: `url(${ProductBg}), linear-gradient(180deg, #412558 0.19%, #390F5E 80.46%)`,
          }}
          wrap="nowrap"
        >
          <div style={{ marginBottom: "50px" }}>
            <CloseIcon
              fontSize="large"
              className={classes.closeIcon}
              onClick={() => {
                return onHandleShowPlans(false);
              }}
              style={{
                position: "absolute",
                right: "20px",
                top: "20px",
                color: "white",
              }}
            />
          </div>
          <div className={classes.headTitle}>
            <Trans>Plans</Trans>
          </div>
          <p className={classes.headParagraph}>
            <Trans>Unlock more opportunities with DroneBase PRO plan</Trans>
          </p>
        </Grid>
        <Grid style={{ height: 900, width: "100%" }}>
          <Grid container className={classes.productContainer}>
            {plans &&
              plans.length > 0 &&
              plans.map((plan) => {
                return (
                  <Product
                    key={"proPlan"}
                    contactSalesHandler={contactSalesHandler}
                    plan={plan}
                  />
                );
              })}
          </Grid>
        </Grid>
      </div>
      {msgSent && <MsgSentPopup msgSentHandler={msgSentHandler} />}
    </Grid>
  );
};

export default withStyles(styles)(ViewPlans);
