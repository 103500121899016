import { Divider, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Trans, withTranslation } from "react-i18next";

import Config from "../../../config";
import CustomButton from "../customStyle/Button";
import CustomTextField from "../customStyle/TextField";
import CustomToggleSwitch from "../customStyle/ToggleSwitch";
import CustomTooltip from "../customStyle/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DownTri from "../../../assets/img/downTri.svg";
import DownloadIcon from "../../../assets/img/downloadloss.svg";
import InfoIcon from "@material-ui/icons/Info";
import InspectionDetails from "./inspectionDetails";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import NewLoader from "../../../layout/newLoader";
import UpTri from "../../../assets/img/upTri.svg";
import { allRequestHandler } from "../../../api";
import { connect } from "react-redux";
import cross from "../../../assets/img/cross.svg";
import i18n from "i18next";
import { powerRevenueDuration } from "../../../constants/dashboardConstants";
import styles from "./loss.styles";
import { withStyles } from "@material-ui/core/styles";

const requestUrl = Config.hostName;

//Styles
const titleStyles = () => {
  return {
    root: {
      margin: 0,
      padding: 0,
      minHeight: 60,
      maxHeight: 60,
      flexGrow: 1,
    },
    dialogTitle: {
      position: "absolute",
      maxHeight: "20px",
      left: "30px",
      top: "20px",
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "18px",
      color: "#08001D",
    },
  };
};

//Styled component
const DialogTitle = withStyles(titleStyles)((props) => {
  const { children, classes, onClose, sendPatch, classes2 } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography className={classes.dialogTitle}>{children}</Typography>

      <div className={classes2.titleBtns}>
        <span style={{ marginRight: 20 }}>
          <CustomButton text={"Update"} onClick={sendPatch} />
        </span>
        <img
          src={cross}
          width={20}
          style={{ cursor: "pointer", marginRight: 20 }}
          onClick={() => {
            return onClose();
          }}
          alt="close"
          aria-hidden="true"
        />
      </div>
    </MuiDialogTitle>
  );
});

//revenueloss table
const RevenueLoss = (props) => {
  const { classes } = props;

  const [lossDetails, setLossDetails] = useState([]); //loss details
  const [update, setUpdate] = useState(false); //patch request object
  const [dropDown, setDropDown] = useState(null); // dropdown to view site inspection details
  const [duration, setDuration] = useState(365); // toggel between dates
  const [currency, setCurrency] = useState(null);
  const [valueLoader, setValueLoader] = useState(true);

  //get revenue details
  async function getData(value) {
    const plantId = props?.userDetails?.plant_id;
    const urlName = plantId
      ? `/api/sites/${plantId}/loss_details`
      : "/api/dashboard/loss_details";
    const noDays = value || duration;

    const response = await allRequestHandler({
      requestType: "GET",
      requestUrl: `${requestUrl}${urlName}?days=${noDays}`,
    });

    if (!response) {
      return;
    }

    const { plants_list, currency } = response;
    const sortedPlantList = plants_list.sort((a, b) =>
      a.plant_name.localeCompare(b.plant_name)
    );

    setValueLoader(false);
    setLossDetails(sortedPlantList);
    setCurrency(currency);
  }

  useEffect(() => {
    // to load loss details of sites
    getData();
  }, [update]);

  //for downloading files
  const downloadFile = async () => {
    const csvName =
      props.userDetails && props.userDetails.plant_id
        ? `/api/sites/${props.userDetails.plant_id}/loss_details/csv`
        : "/api/dashboard/loss_details/csv";

    const link = await allRequestHandler({
      requestType: "GET",
      requestUrl: `${requestUrl}${csvName}?days=${duration}`,
    });

    if (link) window.open(link.downloads_url);
  };

  //close revenue loss dialog
  const closeDialog = () => {
    props.closeDialog();
  };

  //Send patch details
  const sendPatch = async () => {
    const filterPatchData = lossDetails.map((plant) => {
      return {
        plant_id: plant.plant_id,
        yield: plant.yield,
        tariff: plant.tariff,
      };
    });
    const temp = lossDetails;
    setLossDetails([]);
    try {
      const patchUrl = await allRequestHandler({
        requestType: "PATCH",
        requestUrl: `${requestUrl}/api/dashboard/loss_details`,
        requestData: {
          plants_list: filterPatchData, //sending update object as patch
        },
      });

      if (patchUrl) {
        setUpdate(!update);
        props.metadata();
      }
    } catch (error) {
      setLossDetails(temp);
      console.log("error:", error);
    }
  };

  //Handle change in input fields
  const handleChange = (event, index, field) => {
    const localLoss = [...lossDetails];
    localLoss[index][field] = parseFloat(event.target.value);
    setLossDetails(localLoss);
  };

  //function to handle dropdown
  const handleDropDown = (index) => {
    dropDown !== index ? setDropDown(index) : setDropDown(null);
  };

  //function to change date
  const dateChange = (e, value) => {
    setValueLoader(true);
    setDuration(value);
    getData(value);
  };

  //loss conversion
  const convertLoss = (value, field) => {
    const changeUnit = field === "energy" ? "Wh" : "Wp";
    const formatter = new Intl.NumberFormat("en", { maximumFractionDigits: 4 });

    if (value < 1000) {
      return `${formatter.format(value)} ${changeUnit}`;
    } else if (value < 1000000) {
      return `${formatter.format(value / 1000)} M${changeUnit}`;
    } else {
      return `${formatter.format(value / 1000000)} G${changeUnit}`;
    }
  };

  //number conversion
  const convertNumber = (value) => {
    let absValue = Math.abs(Number(value));
    const suffixes = ["", "K", "M", "B"];
    let suffixIndex = 0;

    while (absValue >= 1000 && suffixIndex < suffixes.length - 1) {
      absValue /= 1000;
      suffixIndex++;
    }

    return `${absValue.toFixed(2)}${suffixes[suffixIndex]}`;
  };

  return (
    <Dialog
      style={{ minWidth: 500 }}
      aria-labelledby="customized-dialog-title"
      open={props.open}
      fullScreen
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={closeDialog}
        sendPatch={sendPatch}
        classes2={classes}
      >
        <Trans>Power & Revenue Loss</Trans>
      </DialogTitle>
      <Divider />

      <Grid container alignItems="center" style={{ minHeight: 84 }}>
        <div style={{ marginRight: 16 }}>
          <CustomToggleSwitch
            value={duration}
            onChange={dateChange}
            btnGroup={powerRevenueDuration}
          />
        </div>
        <CustomButton
          text={i18n.t("Download .Csv")}
          onClick={downloadFile}
          btnIcon={DownloadIcon}
          type="cancel"
          margin={8}
        />
      </Grid>

      <div style={{ overflow: "auto" }}>
        <MuiDialogContent
          className={[classes.siteList, classes.siteListContainer].join(" ")}
        >
          <Grid
            container
            style={{ flexWrap: "nowrap" }}
            alignItems="center"
            className={classes.headerContent}
          >
            <Grid
              item
              xs={2}
              className={classes.left}
              style={{ maxWidth: 200 }}
            >
              <Typography className={classes.highlight}>
                <Trans>Sites</Trans>
              </Typography>
            </Grid>
            <Grid
              item
              xs
              className={classes.middle}
              style={{
                margin: props.language === "sp" ? "0px 0px 0px 175px" : "",
              }}
            >
              <Typography className={classes.highlight}>
                <Trans>Inspections</Trans>
              </Typography>
            </Grid>
            <Grid
              item
              xs
              className={[classes.right, classes.flexWrap].join(" ")}
              style={{
                margin: props.language === "sp" ? "0px 32px 0px 32px" : "",
              }}
            >
              <Grid container alignItems="center">
                <Grid item xs>
                  <Typography className={classes.highlight}>
                    <Trans>Initial</Trans> <br /> <Trans>Power Loss</Trans>
                  </Typography>
                </Grid>
                <CustomTooltip
                  title={i18n.t(
                    "The total power of your DC capacity which is not contributing towards energy generation on the day of inspection."
                  )}
                >
                  <InfoIcon className={classes.infoIcon} />
                </CustomTooltip>
              </Grid>
            </Grid>
            <Grid
              item
              xs
              className={[classes.right, classes.flexWrap].join(" ")}
              style={{
                margin: props.language === "sp" ? "0px 32px 0px 32px" : "",
              }}
            >
              <Typography className={classes.highlight}>
                <Trans>Current</Trans> <br /> <Trans>Power Loss</Trans>
              </Typography>
              <CustomTooltip
                title={i18n.t(
                  "Current power loss from your site due to all unresolved Anomalies. Resolve Anomalies to see a reduction in your Power Loss."
                )}
              >
                <InfoIcon className={classes.infoIcon} />
              </CustomTooltip>
            </Grid>
            <Grid
              item
              xs
              className={[classes.right, classes.flexWrap].join(" ")}
              style={{
                margin: props.language === "sp" ? "0px 32px 0px 32px" : "",
              }}
            >
              <Typography className={classes.highlight}>
                <Trans>Yield</Trans> (kWh/kWp <br /> <Trans>per year</Trans>)
              </Typography>
              <CustomTooltip
                title={
                  <div>
                    {" "}
                    <Trans>
                      Yield is the ratio between total annual energy produced
                      and the solar installed capacity.
                      <br /> Yield (kWh/kWp per year) = annual plant output
                      (kWh) / installed capacity (kWp)
                    </Trans>
                  </div>
                }
              >
                <InfoIcon className={classes.infoIcon} />
              </CustomTooltip>
            </Grid>
            <Grid
              item
              xs
              className={[classes.right, classes.flexWrap].join(" ")}
              style={{
                margin: props.language === "sp" ? "0px 10px 0px -20px" : "",
              }}
            >
              <Typography className={classes.highlight}>
                <Trans>Estimated</Trans> <br /> <Trans>Energy Loss</Trans>
              </Typography>
              <CustomTooltip
                title={i18n.t(
                  "Total energy that could have been produced in the selected period if there were no defects on modules."
                )}
              >
                <InfoIcon className={classes.infoIcon} />
              </CustomTooltip>
            </Grid>
            <Grid
              item
              xs
              className={[classes.right, classes.flexWrap].join(" ")}
              style={{
                margin: props.language === "sp" ? "0px 32px 0px 32px" : "",
              }}
            >
              <Typography className={classes.highlight}>
                <Trans>Energy Recovered</Trans>
              </Typography>
              <CustomTooltip
                title={i18n.t(
                  "Energy recovered after resolving anomalies. Resolve anomalies to increase energy generation from your site."
                )}
              >
                <InfoIcon className={classes.infoIcon} />
              </CustomTooltip>
            </Grid>
            <Grid
              item
              xs
              className={[classes.right, classes.flexWrap].join(" ")}
              style={{
                margin: props.language === "sp" ? "0px 32px 0px 50px" : "",
              }}
            >
              <Grid container alignItems="center">
                <Typography className={classes.highlight}>
                  <Trans>Feed in Tariff/kWh</Trans>
                </Typography>
                <CustomTooltip
                  title={i18n.t(
                    "Enter the selling price of your energy to calculate total revenue losses due to defects at your site."
                  )}
                >
                  <InfoIcon className={classes.infoIcon} />
                </CustomTooltip>
              </Grid>
            </Grid>
            <Grid
              item
              xs
              className={[classes.right, classes.flexWrap].join(" ")}
              style={{
                margin: props.language === "sp" ? "0px 32px 0px 80px" : "",
              }}
            >
              <Typography className={classes.highlight}>
                <Trans>Estimated</Trans> <br /> <Trans>Revenue Loss</Trans>
              </Typography>
              <CustomTooltip
                title={i18n.t(
                  "Total revenue loss that will incur from your individual solar site for the chosen period if no Anomalies are resolved."
                )}
              >
                <InfoIcon className={classes.infoIcon} />
              </CustomTooltip>
            </Grid>
            <Grid item xs className={classes.right}>
              <Grid
                container
                alignItems="center"
                wrap="nowrap"
                style={{
                  margin: props.language === "sp" ? "0px 20px 0px 32px" : "",
                }}
                className={classes.revenueRecovered}
              >
                <Typography className={classes.highlight}>
                  <Trans>Revenue Recovered</Trans>
                </Typography>
                <CustomTooltip
                  title={i18n.t(
                    "Revenue that is generated from your site after Anomaly resolution. Resolve Anomalies to increase revenue generation."
                  )}
                >
                  <InfoIcon className={classes.infoIcon} />
                </CustomTooltip>
              </Grid>
            </Grid>
          </Grid>

          <div>
            {lossDetails?.lossDetails.length ? (
              lossDetails.map((plant, index) => {
                return (
                  <React.Fragment key={plant.plant_id}>
                    <Grid
                      container
                      alignItems="center"
                      onClick={() => {
                        handleDropDown(index);
                      }}
                      className={[
                        classes.gridContainer,
                        classes.lossDetails,
                      ].join(" ")}
                    >
                      <Grid
                        item
                        xs={2}
                        className={classes.left}
                        style={{ maxWidth: 200 }}
                      >
                        <span style={{ marginRight: 8 }}>
                          {" "}
                          {dropDown === index ? (
                            <img alt="upImg" src={UpTri} />
                          ) : (
                            <img alt="downImg" src={DownTri} />
                          )}
                        </span>
                        <div className={classes.flexColumn}>
                          <Typography className={classes.text2}>
                            {" "}
                            {plant.plant_name}
                          </Typography>
                          <Typography className={classes.text3}>
                            {plant.location}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs className={classes.middle}>
                        <Typography className={classes.text2}>
                          {plant.inspection_count}
                          {plant.inspection_count > 1 ? (
                            <>
                              {" "}
                              <Trans>Inspections</Trans>
                            </>
                          ) : (
                            <>
                              {" "}
                              <Trans>Inspection</Trans>
                            </>
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs className={classes.right}>
                        <Typography
                          className={classes.text2}
                          style={{ fontWeight: "700" }}
                        >
                          {plant.initial_power_loss
                            ? convertLoss(plant.initial_power_loss, "power")
                            : "--"}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs
                        className={
                          props.language === "sp"
                            ? classes.forSpanishLangRight
                            : classes.right
                        }
                      >
                        <Typography className={classes.text1}>
                          {plant.power_loss ? (
                            <>{convertLoss(plant.power_loss, "power")}</>
                          ) : (
                            <>--</>
                          )}
                        </Typography>
                        {plant.power_loss_percent && (
                          <Typography className={classes.redPercent}>
                            {plant.power_loss_percent.toFixed(2)} %
                          </Typography>
                        )}
                      </Grid>
                      <Grid
                        item
                        xs
                        className={
                          props.language === "sp"
                            ? classes.forSpanishLangRightYield
                            : classes.right
                        }
                        onClick={(e) => {
                          return e.stopPropagation();
                        }}
                        style={{ paddingLeft: 20 }}
                      >
                        <CustomTextField
                          type="number"
                          value={plant.yield}
                          handleChange={(e) => {
                            return handleChange(e, index, "yield");
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs
                        className={
                          props.language === "sp"
                            ? classes.forSpanishLangRightYield
                            : classes.right
                        }
                      >
                        {!valueLoader ? (
                          <Typography className={classes.text1}>
                            {plant.energy_loss ? (
                              <>{convertLoss(plant.energy_loss, "energy")}</>
                            ) : (
                              <>--</>
                            )}
                          </Typography>
                        ) : (
                          <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                          >
                            <NewLoader />
                          </Grid>
                        )}
                      </Grid>

                      <Grid
                        item
                        xs
                        className={
                          props.language === "sp"
                            ? classes.forEnergyRecovered
                            : classes.right
                        }
                      >
                        {!valueLoader ? (
                          <>
                            <Typography className={classes.text2}>
                              {plant.energy_recovered ? (
                                <>
                                  {convertLoss(
                                    plant.energy_recovered,
                                    "energy"
                                  )}
                                </>
                              ) : (
                                "--"
                              )}
                            </Typography>
                            {plant.energy_recovered_percent && (
                              <Typography className={classes.greenPercent}>
                                {plant.energy_recovered_percent.toFixed(2)} %
                              </Typography>
                            )}
                          </>
                        ) : (
                          <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                          >
                            <NewLoader />
                          </Grid>
                        )}
                      </Grid>

                      <Grid
                        item
                        xs
                        className={
                          props.language === "sp"
                            ? classes.forTariff
                            : classes.right
                        }
                      >
                        <Grid
                          container
                          className={classes.flexRow}
                          onClick={(e) => {
                            return e.stopPropagation();
                          }}
                          style={{ paddingLeft: 20 }}
                        >
                          <div className={classes.ip$}>{currency}</div>
                          <Grid item xs>
                            <CustomTextField
                              type="number"
                              value={plant.tariff}
                              handleChange={(e) => {
                                return handleChange(e, index, "tariff");
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs
                        className={
                          props.language === "sp"
                            ? classes.forLoss
                            : classes.right
                        }
                      >
                        {!valueLoader ? (
                          <Typography
                            className={classes.text1}
                            style={{ fontWeight: "700" }}
                          >
                            {plant.revenue_loss ? (
                              <>
                                {currency} {convertNumber(plant.revenue_loss)}
                              </>
                            ) : (
                              <>--</>
                            )}
                          </Typography>
                        ) : (
                          <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                          >
                            <NewLoader />
                          </Grid>
                        )}
                      </Grid>
                      <Grid
                        item
                        xs
                        className={
                          props.language === "sp"
                            ? classes.forRevRecov
                            : classes.right
                        }
                      >
                        {!valueLoader ? (
                          <>
                            <Typography className={classes.text1}>
                              {plant.revenue_recovered ? (
                                <>
                                  {currency}{" "}
                                  {convertNumber(plant.revenue_recovered)}
                                </>
                              ) : (
                                <>--</>
                              )}
                            </Typography>
                            {plant.revenue_recovered_percent && (
                              <Typography className={classes.greenPercent}>
                                {plant.revenue_recovered_percent.toFixed(2)} %
                              </Typography>
                            )}
                          </>
                        ) : (
                          <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                          >
                            <NewLoader />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>

                    {dropDown === index ? (
                      <div className={classes.inspD}>
                        <InspectionDetails
                          reference={plant.inspection_href.href}
                          classes={classes}
                          duration={duration}
                          currency={currency}
                          convertLoss={(value, field) => {
                            return convertLoss(value, field);
                          }}
                        />
                      </div>
                    ) : null}
                  </React.Fragment>
                );
              })
            ) : (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{ minHeight: 400 }}
              >
                <NewLoader />
              </Grid>
            )}
          </div>
        </MuiDialogContent>
      </div>
    </Dialog>
  );
};

//Calling redux to get information about the selected language
const mapStateToProps = (state) => {
  return {
    language: state.language.selectedLanguage,
  };
};

export default connect(mapStateToProps)(
  withStyles(styles)(withTranslation()(RevenueLoss))
);
