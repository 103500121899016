import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  withStyles,
} from "@material-ui/core";

import Config from "../../config";
import CustomClear from "../common/customClear";
import CustomFilterChip from "../common/customStyle/FilterChip";
import CustomToggleSwitch from "../common/customStyle/ToggleSwitch";
import EmptyCard from "../common/emptychart";
import Filter from "../common/filters/filters";
import FilterImg from "../../assets/img/filter2.svg";
import NewLoader from "../../layout/newLoader";
import OutsideClickHandler from "react-outside-click-handler";
import ProLabel from "../common/ProLabel";
import React from "react";
import StackColumnChart from "../common/charts/stackcolumn";
import { Trans } from "react-i18next";
import _ from "lodash";
import { allRequestHandler } from "../../api";
import { internalBenchToggleObj } from "../../constants/dashboardConstants";
import styles from "./dashboard.styles";

const requestUrl = Config.hostName;

//InternalBenchmark component
class InternalBenchmark extends React.Component {
  state = {
    filterBenchmarkChips: [],
    isFilteringBenchmark: false,
    selectedMode: "MWp",
    showFilter: false,
    anomalies_by_status: this.props.anomalies_by_status,
    anomalies_per_mwp: this.props.anomalies_per_mwp,
  };

  //This function is called whenever any props or state changes
  componentDidUpdate(prevProps) {
    if (
      prevProps.anomalies_by_status !== this.props.anomalies_by_status ||
      prevProps.anomalies_per_mwp !== this.props.anomalies_per_mwp
    ) {
      // skipcq Avoid usage of setState in componentDidUpdate JS-0443
      this.setState({
        anomalies_by_status: this.props.anomalies_by_status,
        anomalies_per_mwp: this.props.anomalies_per_mwp,
      });
    }
  }

  //Handles the filters applied on the internal benchmark chart
  applyFilter = async (
    defect_types,
    inspection_type,
    module_type,
    severity_types,
    status_types,
    iec_category,
    plant_names,
    temperature_range,
    anomaly_has,
    action
  ) => {
    const data = {
      defect_types,
      inspection_type,
      module_type,
      severity_types,
      status_types,
      iec_category,
      plant_names,
      temperature_range,
      anomaly_has,
    };

    const [res, resp] = await Promise.all([
      allRequestHandler({
        requestType: action ? "GET" : "POST",
        requestData: data,
        requestUrl: requestUrl + this.props.anomalies_per_mwp_href,
      }),
      allRequestHandler({
        requestType: action ? "GET" : "POST",
        requestData: data,
        requestUrl: requestUrl + this.props.anomalies_by_status_href,
      }),
    ]);
    if (res && res.site_anomalies && res.site_anomalies.length === 0)
      this.setState({ anomalies_per_mwp: res });
    else this.setState({ anomalies_per_mwp: res });
    if (resp && resp.site_status && resp.site_status.length === 0)
      this.setState({ anomalies_by_status: resp });
    else this.setState({ anomalies_by_status: resp });
  };

  //Removes all the filters applied on the internal benchmark chart
  handleClearFilter = async (action) => {
    this.setState({
      filterBenchmarkChips: [],
      showFilter: false,
      isFilteringBenchmark: true,
    });
    await this.applyFilter(
      [],
      "All",
      [],
      ["All"],
      ["All"],
      [],
      [],
      { start: 0, end: 100 },
      ["All"],
      action
    );
    this.setState({ isFilteringBenchmark: false });
  };

  //Handles the data change when the toggle switch is changed from Anomalies per MWp to Anomalies by Status or vice versa
  handleChartChange = (e, newData) => {
    if (newData !== null) this.setState({ selectedMode: newData });
  };

  //Removes the filter chip from the filter chip list and update the filters
  handleDeleteChip = (data, action) => {
    // skipcq Avoid using this.state inside this.setState() JS-0435
    const filterChips = this.state.filterBenchmarkChips.filter((item) => {
      return item.name !== data.name;
    });

    if (filterChips.length === 0) {
      return this.handleClearFilter(action);
    }

    this.setState({
      filterBenchmarkChips: filterChips,
    });

    data.delete(data.name);
    return;
  };

  //This page renders the internal benchmark chart and the filters associated with it
  render() {
    const { classes } = this.props;

    const selectedChart =
      this.state.selectedMode === "Status"
        ? this.state.anomalies_by_status
        : this.state.anomalies_per_mwp;

    let updatedSelectedChart = 0;
    let updatedChartData = {};

    if (selectedChart) {
      const sortByKey = "plant_name";
      let chartDataKey = "";

      if (this.state.selectedMode === "Status") {
        chartDataKey = "site_status";
      } else {
        chartDataKey = "site_anomalies";
      }

      updatedSelectedChart =
        selectedChart[chartDataKey] &&
        _.sortBy(selectedChart[chartDataKey], sortByKey, "asc");

      updatedChartData = {
        filter_options: selectedChart.filter_options,
      };

      if (updatedSelectedChart) {
        updatedChartData[chartDataKey] = updatedSelectedChart;
      }
    }

    return (
      <Card
        className={[
          classes.scrollBar,
          classes.card,
          classes.internalBenchOuter,
        ].join(" ")}
      >
        <CardContent style={styles.contentMob}>
          <Grid container>
            <Grid item xs className={classes.benchmarkBar}>
              <Typography className={classes.chartHeading}>
                <Trans>Internal Benchmark</Trans>
              </Typography>
              <div className={classes.proLabel}>
                <ProLabel marginRight="0" marginLeft="5px" show />
              </div>
              <Grid
                item
                style={{
                  display:
                    this.props.product &&
                    this.props.product.product_type === "Basic" &&
                    "none",
                }}
              >
                <div className={classes.togglebtgroup}>
                  <CustomToggleSwitch
                    value={this.state.selectedMode}
                    onChange={this.handleChartChange}
                    btnGroup={internalBenchToggleObj}
                    intercom={"internal-benchmark-status"}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid item id="forChart">
              <div className={classes.grid}>
                {this.state.filterBenchmarkChips.length > 0 ? (
                  <CustomClear
                    classes={classes}
                    Filter={() => this.handleClearFilter(true)}
                  />
                ) : null}
                <Button
                  onClick={() => {
                    return this.setState((prevState) => {
                      return {
                        showFilter: !prevState.showFilter,
                      };
                    });
                  }}
                  className={classes.filterButton}
                  variant="outlined"
                  data-intercom-target="internal-benchmark-filter"
                  style={{
                    display:
                      this.props.product &&
                      this.props.product.product_type === "Basic" &&
                      "none",
                  }}
                >
                  <Trans>Filters</Trans>
                  {this.state.filterBenchmarkChips.length > 0 ? (
                    <span className={classes.filter}>
                      {this.state.filterBenchmarkChips.length}
                    </span>
                  ) : (
                    <img
                      className={classes.image}
                      src={FilterImg}
                      alt="filter"
                    />
                  )}
                </Button>
              </div>
            </Grid>
          </Grid>
          <Divider />
          <div
            className={`${classes.pad12} ${classes.scrollBar} ${classes.internBenchCont}`}
            item="true"
            md={12}
          >
            <div className={classes.filterchip1}>
              <div className={classes.flexStyle}>
                {this.state.filterBenchmarkChips.map((data) => {
                  return data.type === "temperature_range" &&
                    data.value[0] === 0 &&
                    data.value[1] === 100 ? null : (
                    <CustomFilterChip
                      onDelete={() => {
                        return this.handleDeleteChip(data, true);
                      }}
                      type={data.type}
                      name={data.name}
                      lowerTempValue={data.value && data.value[0]}
                      higherTempValue={data.value && data.value[1]}
                    />
                  );
                })}
              </div>
            </div>

            <div className={classes.stackchart} style={{ overflowX: "auto" }}>
              {this.state.anomalies_by_status &&
              this.state.anomalies_per_mwp &&
              !this.state.isFilteringBenchmark ? (
                <StackColumnChart
                  dataType={this.state.selectedMode}
                  chartData={updatedChartData}
                  onHandleShowPlans={this.props.onHandleShowPlans}
                />
              ) : (
                <div
                  data-intercom-target="internal-benchmark"
                  className={classes.internalbenchmark}
                >
                  <NewLoader />
                </div>
              )}{" "}
              <OutsideClickHandler
                onOutsideClick={() =>
                  this.setState({
                    showFilter: false,
                  })
                }
              >
                {selectedChart && this.state.showFilter ? (
                  <div className={classes.showfilter}>
                    <Filter
                      filters={
                        selectedChart === this.state.anomalies_per_mwp
                          ? this.props.anomalies_per_mwp_filter_options
                          : this.props.anomalies_by_status_filter_options
                      }
                      filterChips={this.state.filterBenchmarkChips}
                      applyFilter={async (
                        defect_types,
                        inspection_type,
                        module_type,
                        severity_types,
                        status_types,
                        iec_category,
                        plant_names,
                        temperature_range,
                        anomaly_has
                      ) => {
                        this.setState({ isFilteringBenchmark: true });
                        await this.applyFilter(
                          defect_types,
                          inspection_type,
                          module_type,
                          severity_types,
                          status_types,
                          iec_category,
                          plant_names,
                          temperature_range,
                          anomaly_has
                        );
                        this.setState({ isFilteringBenchmark: false });
                      }}
                      onClearFilter={() => this.handleClearFilter(true)}
                      closeFilter={() => {
                        return this.setState({ showFilter: false });
                      }}
                      addFilterChips={(filterBenchmarkChips) => {
                        return this.setState({ filterBenchmarkChips });
                      }}
                    />
                  </div>
                ) : null}
              </OutsideClickHandler>
              {selectedChart &&
                this.state.selectedMode === "Status" &&
                selectedChart.site_status.length === 0 && (
                  <EmptyCard cardName="internal" compName="internal" />
                )}
              {selectedChart &&
                this.state.selectedMode === "MWp" &&
                selectedChart.site_anomalies.length === 0 && (
                  <EmptyCard cardName="internal" compName="internal" />
                )}
            </div>
          </div>
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles)(InternalBenchmark);
