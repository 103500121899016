const jp = {
  translations: {
    Dashboard: "ダッシュボード",
    Portfolio: "ポートフォリオ",
    "Dashboard Tour": "ダッシュボードツアー",
    "All Inspections": "すべての検査",
    "Download Reports": "レポートのダウンロード",
    Site: "地点",
    "Select Plant": "プラントを選択",
    "Report Type": "レポートタイプ",
    "Select Report": "レポートを選択",
    Download: "ダウンロード",
    "Anomalies/MWp": "異常/ MWp",
    "Anomaly Status": "異常状態",
    Filters: "フィルタ",
    "DC Capacity": "DC容量",
    "AC Capacity": "AC容量",
    "Power Loss": "電力損失",
    "Revenue Loss/yr": "年間収益損失",
    Anomalies: "異常",
    ANOMALIES: "異常",
    Status: "状態",
    Severity: "重大度",
    IEC: "IEC",
    Total: "合計",
    Pending: "保留中",
    Resolved: "解決済み",
    "In Progress": "進行中",
    "Not Found": "見つかりません",
    "False +ve": "False +ve",
    Critical: "致命的",
    Moderate: "適度",
    Minor: "マイナー",
    "IEC 1": "IEC 1",
    "IEC 2": "IEC 2",
    "IEC 3": "IEC 3",
    Uncategorized: "未分類",
    "LOSS RECOVERED": "損失が回復しました",
    Power: "電力",
    Revenue: "収益",
    All: "全て",
    "Anomaly Name": "異常名",
    "Anomalies Name": "異常名",
    "Revenue recovered is calculated only for Sites with yield & tariff values provided and for Anomalies with loss factor.":
      "回収された収益は、歩留まりと料金の値が提供されているサイトと、損失係数のある異常についてのみ計算されます。",
    "Loss Recovered": "回復した損失",
    "Internal Benchmark": "内部ベンチマーク",
    "Vegetation growth causing hotspots":
      "ホットスポットを引き起こす植生の成長",
    "Junction box highlighted module":
      "ジャンクションボックスで強調表示されたモジュール",
    "Multi cell hotspot and degraded module":
      "マルチセルホットスポットと劣化したモジュール",
    "Isolated / Underperforming string": "孤立した/パフォーマンスの低い文字列",
    "Short circuited module": "短絡モジュール",
    "Multi hotspot affected module":
      "マルチホットスポットの影響を受けるモジュール",
    "Isolated/Underperforming module": "分離/パフォーマンスの低いモジュール",
    "Soiling causing hotspots": "ホットスポットを引き起こす汚れ",
    "Wrongly inclined table": "間違って傾いたテーブル",
    "Hotspot affected modules": "ホットスポットの影響を受けるモジュール",
    "Foreign object causing hotspot": "ホットスポットの原因となる異物",
    "Severe Multicell Hotspot affected module":
      "重大なマルチセルホットスポットの影響を受けるモジュール",
    "Hotspot affected and cell mismatch module":
      "影響を受けるホットスポットとセルの不一致モジュール",
    "Missing module": "モジュールがありません",
    "Bypass diode activated module": "バイパスダイオード起動モジュール",
    "Isolated / Underperforming table": "孤立した/パフォーマンスの低いテーブル",
    "Isolated / Underperforming module": "分離/パフォーマンスの低いモジュール",
    "Hotspot affected module": "ホットスポットの影響を受けるモジュール",
    "Single hotspot affected module":
      "単一のホットスポットの影響を受けるモジュール",
    "Substring short circuited module": "サブストリング短絡モジュール",
    "Underperforming Module": "パフォーマンスの低いモジュール",
    "LA / TP shadow causing hotspots":
      "LA / TPシャドウがホットスポットを引き起こす",
    "Band Hotspot": "バンドホットスポット",
    "Cracked / Underperforming module":
      "ひびの入った/パフォーマンスの低いモジュール",
    "Suspected PID module": "疑わしいPIDモジュール",
    "Patch Hotspot": "パッチホットスポット",
    "Corner single cell hotspot affected module":
      "コーナーシングルセルホットスポットの影響を受けるモジュール",
    "Underperforming string": "パフォーマンスの低い文字列",
    "Wrongly inclined modules": "誤って傾斜したモジュール",
    "IEC Standards": "IEC規格",
    "Anomaly Has": "異常があります",
    Comments: "コメントコメント",
    "EL Image": "EL画像",
    "IV Curve Image": "IV曲線画像",
    "Handheld Image": "ハンドヘルド画像",
    "Temperature Difference (∆T)": "温度差（ΔT)",
    "Temperature Difference (∆T °C)": "温度差（ΔT°C",
    "temp. value will be used to filter defects. If STC not available, Site Condition temp. value will be used.":
      "温度値は欠陥をフィルタリングするために使用されます。 STCが利用できない場合、サイト条件温度。値が使用されます。",
    Clear: "クリア",
    Apply: "申し込み",
    "Power (KWp)": "電力（KWp)",
    "Power Loss Recovered": "回復した電力損失",
    "Anomalies Processed": "処理された異常",
    "Inspection Date": "点検日",
    "Inspection Type": "検査タイプ",
    // "Report Type": "レポートタイプ",
    "INTERNAL BENCHMARK": "内部ベンチマーク",
    "Site Details": "サイトの詳細",
    "Sites Tour": "サイトツアー",
    "Inspection Date : Recent First": "検査日：最近の最初",
    "Inspection Date : Oldest First": "検査日：最も古いものから",
    "COD : Recent First": "代金引換：最近の最初",
    "COD : Oldest First": "代金引換：最も古い最初",
    COD: "COD",
    "Add Image": "画像を追加",
    "Search by site name , location": "サイト名、場所で検索",
    Inspections: "検査",
    "View Anomalies": "異常を表示",
    Calculate: "計算する",
    "SITE LAYOUT": "サイトのレイアウト",
    "Commissioning Date": "試運転日",
    "Number of Panels": "パネル数",
    "Module Mounting Structure": "モジュール取り付け構造",
    "Panel Brands & Models": "パネルのブランドとモデル",
    "Inverter Brands & Models": "インバーターのブランドとモデル",
    "Feed in tariff": "固定価格買取制度",
    "Panel Maximum Power (Pmax)": "パネル最大電力（Pmax）",
    "Tilt Angle (Degree)": "傾斜角度（度）",
    "Photovoltaic power potential (Yield)": "太陽光発電ポテンシャル（収量）",

    "Sites/Inspections": "サイト/検査",
    "Invalid date": "無効な日付",
    Edit: "編集",
    "Ground mounted": "地上設置",
    Table: "テーブル",
    Sites: "サイト",
    Reports: "レポート",
    Activity: "アクティビティ",
    Users: "ユーザー",
    Configurations: "構成",
    "Powered by Airprobe": "Airprobeを搭載",
    "Log Out": "ログアウト",
    Map: "地図",
    Video: "ビデオ",
    Attachments: "添付ファイル",
    Thermal: "熱の",
    Handheld: "ハンドヘルド",
    "Actual Module Temperature": "実際のモジュール温度",
    "Hotspot Temperature": "ホットスポット温度",
    "Module Coordinate": "モジュール座標",
    "IEC Category": "IECカテゴリ",
    "Site Condition": "サイトの状態",
    Recommendations: "推奨事項",
    "Defective Panel": "欠陥のあるパネル",
    "Module Serial Number": "モジュールのシリアル番号",
    "Module Manufacturer Name": "モジュールメーカー名",
    "String ID": "文字列ID",
    "Module Wp": "モジュールWp",
    "Module Voc": "モジュールVoc",
    "Module Isc": "モジュールIsc",
    "String Current": "文字列現在",
    "Module Vmp": "モジュールVmp",
    " Imp": "モジュールImp",
    "Healthy Panel": "ヘルシーパネル",
    "Ref Module Wp": "参照モジュールWp",
    "Ref Module Voc": "参照モジュールVoc",
    "Ref Module Isc": "参照モジュールIsc",
    "Ref Module Vmp": "参照モジュールVmp",
    "Ref Module Imp": "参照モジュールImp",
    "Edit Panel Details": "パネルの詳細を編集",
    "Save Changes": "変更内容を保存",
    Discard: "破棄",
    "DOWNLOAD REPORTS (PDF)": "レポートのダウンロード（PDF）",
    // Reports: "レポート",
    "Report Tour": "レポートツアー",
    "Executive Report": "エグゼクティブレポート",
    "Includes high level data of site inspection":
      "サイト検査の高レベルのデータが含まれています",
    "Warranty Claim Report": "保証請求レポート",
    "Download anomalies with serial no. & manufacturer details":
      "シリアル番号の異常をダウンロードします。 ＆メーカー詳細",
    "Thermal Report": "サーマルレポート",
    "Detailed thermal report for all the anomalies":
      "すべての異常に関する詳細な熱レポート",
    "Anomalies CSV": "異常CSV",
    "Comma separated list of all anomalies with module co-ordinates":
      "モジュール座標を使用したすべての異常のコンマ区切りリスト",
    "Anomalies KML": "異常KML",
    "Download kml data for all anomalies":
      "すべての異常のkmlデータをダウンロードする",
    Portrait: "ポートレート",
    Landscape: "風景",
    "PDF Orientation": "PDFオリエンテーション",
    Language: "言語",
    English: "英語",
    Japanese: "日本",
    Spanish: "スペイン語",
    Cancel: "キャンセル",
    "Generate & Download": "生成とダウンロード",
    Sections: "セクション",
    "Report Name": "レポート名",
    "Created by": "によって作成された",
    "Date Created": "作成日",
    "REPORTS HISTORY": "レポートの歴史",
    "Search By Report Name": "レポート名で検索",
    "Reports Tour": "レポートツアー",
    "Generate Custom Report": "カスタムレポートの生成",
    Rename: "名前を変更",
    Delete: "消去",
    name: "名前",
    "load next 10": "次の10をロード",
    "Export As .PDF": ".PDFとしてエクスポート",
    "Thermal Analysis Document": "熱分析ドキュメント",
    "View Report": "レポートを表示",
    METRICS: "メートル法",
    "Anomaly Details": "異常の詳細",
    // Clear: "クリアー",
    Irradiation: "照射法",
    Back: "裏面",
    // Activity: "活動",
    "Add a new comment": "新しいコメントを追加する",
    "(Data storage upto 10GB/100MW)": "（最大10GB / 100MWのデータストレージ",
    "Post Comment": "コメントを投稿",
    "Upload an Image or File": "画像またはファイルをアップロードする",
    // Users: "ユーザーズ",
    "New User": "新しいユーザー",
    Id: "Id",
    Name: "名前",
    Email: "電子メイル",
    "Status ": "地位",
    Role: "役割",
    "Site Access": "サイトアクセス",
    View: "眺望",
    "Last Login": "前回のログイン",
    "Job Title": "職名",
    "User Details": "ユーザーの詳細",
    "User details": "ユーザーの詳細",
    "Edit User": "ユーザーの編集",
    "Delete User": "ユーザーの削除",
    "Update User": "ユーザーの更新",
    "First Name": "ファーストネーム",
    "Last Name": "苗字",
    Username: "ユーザー名",
    Submit: "送信",
    Active: "アクティブ",
    Inactive: "非活性",
    "All Sites": "すべてのサイト",
    "Allowed Sites": "許可されたサイト",
    "Search by name, email": "名前、メールで検索",
    // Configurations: "コンフィグレーション",
    "Rename Anomalies": "異常の名前を変更する",
    "Change Severity Of Anomalies": "異常の重大度を変更する",
    "Set Currency": "通貨を設定する",
    "Default Name": "デフォルト名",
    "Default Severity": "デフォルトの重大度",
    "Updated Severity": "更新された重大度",
    "This will overwrite defect names in the application and downloaded reports will have renamed defect names.":
      "これにより、アプリケーションの欠陥名が上書きされ、ダウンロードされたレポートの名前が欠陥名に変更されます。",
    "Changing Anomalies severity will update stats in the application with updated Anomalies severity.":
      "異常の重大度を変更すると、アプリケーションの統計が更新された異常の重大度で更新されます。",
    Currency: "通貨",
    "My Profile": "私のプロフィール",
    "Personal Information": "個人情報",
    PASSWORD: "パスワード",
    "Edit Profile": "プロファイル編集",
    "Change Password": "パスワードを変更する",
    "Current Password": "現在のパスワード",
    "New Password": "新しいパスワード",
    "Confirm Password": "パスワードを認証する",
    "Update Password": "パスワードの更新",
    Save: "保存する",
    // "Log Out": "ログアウト",
    "Log In": "ログインする",
    Password: "パスワード",
    "Forgot Password": "パスワードをお忘れですか",
    "Search by name, email...": "名前、メールアドレスで検索...",
    // "Powered by Airprobe": "Airprobeを搭載",
    // "Log Out": "ログアウト",
    Tata: "タタ",
    "Last 7 days": "過去7日間",
    "Last 30 days": "過去30日間",
    "Last 90 days": "過去90日間",
    "Last year": "去年",
    // "Thermal Report": "サーマルレポート",
    // "Warranty Claim Report": "保証請求レポート",
    // "Executive Report": "エグゼクティブレポート",
    "Anomalies kml": "異常情報kml",
    "Anomalies csv": "異常情報csv",
    // Status: "状態",
    Selected: "選択済み",
    "Inspection Date: Recent First": "検査日：最近の最初",
    "Inspection Date: Oldest First": "検査日：最も古いものから",
    "COD: Recent First": "代金引換：最近の最初",
    "COD: Oldest First": "代金引換：最も古い最初",
    "DC Capacity (MWp)": "DC容量（MWp",
    "AC Capacity (MW) :": "AC容量（MW）：",
    "Commissioning Date:": "試運転日：",
    "Lat / Long:": "緯度/経度：",
    "Module Mounting Structure:": "モジュールの取り付け構造：",
    "Number of PanelsDC Capacity (MWp)": "パネル数DC容量（MWp）",
    // "Panel Brands & Models": "パネルのブランドとモデル",
    "kWh/kWp per year": "年間kWh / kWp",
    Update: "アップデート",
    // "SITE LAYOUT": "サイトのレイアウト",
    Summer: "夏",
    Winter: "冬",
    "Roof Top": "ルーフトップ",
    "Single axis tracker": "単軸トラッカー",
    "Dome Shaped": "ドーム型",
    Seasonal: "季節",
    SUMMARY: "概要",
    // Selected: "選択済み",
    "8 Mar, 2021": "2021年3月8日",
    "Apply Filters": "フィルタを適用する",
    "Selected filters will show up here":
      "選択したフィルターがここに表示されます",
    "Filters Selected": "選択したフィルター",
    "The terminals of the modules are open circuited. Accordingly, check the dis- connectivity point along the cables.Some of the modules are operating at higher temperature than the normal modules. The reason could be cracks, external fallen bodies, non-uniform soiling or internal cell mismatch. Perform visual inspection of this modules. If no signs of external factors, IV tracing of the module is recommended to understand the electrical impact of fault if any.":
      "モジュールの端子は開回路です。したがって、ケーブルに沿った切断ポイントを確認してください。一部のモジュールは、通常のモジュールよりも高温で動作しています。その理由は、亀裂、外部の落下体、不均一な汚れ、または内部のセルの不一致である可能性があります。このモジュールの目視検査を実行します。外的要因の兆候がない場合は、障害の電気的影響を理解するために、モジュールのIVトレースをお勧めします",
    "First name": "ファーストネーム",
    "SECI 600": "SECI 600",
    "Damaged Module": "損傷モジュール",
    "Temp. Difference (∆T) – STC": "温度差（ΔT）– STC",
    "Temp. Difference (∆T) – Site Condition": "温度差（ΔT）–サイトの状態",
    "IEC Category:": "IECカテゴリ：",
    "Module Coordinate:": "モジュール座標：",
    Anomaly: "異常",
    "Panel Details": "パネルの詳細",
    Comment: "コメント",
    "Search anomaly number...": "異常番号を検索...",
    "File/Image UPLOAD": "ファイル/画像のアップロード",
    "Upload Images": "画像をアップロードする",
    "Upload Files": "ファイルのアップロード",
    Others: "その他",
    "Upload & Continue": "アップロードして続行",
    "MARK AS FALSE +VE": "FALSE + VEとしてマーク",
    "Marking anomaly False +ve will be verified by AirProbe thermal experts to help validate the fault. Upload a supporting document or image for verification.":
      "異常のマーキングFalse + veは、AirProbeの熱専門家によって検証され、障害の検証に役立ちます。確認のためにサポートドキュメントまたは画像をアップロードします",
    "FALSE + VE": "としてマーク",
    "File:": "ファイル：",
    "Cancel File": "ファイルをキャンセル",
    "No File added": "ファイルが追加されていません",
    "Write your comment...": "コメントを書いてください...",
    "Select file": "ファイルを選択",
    "Anomaly No.": "異常番号",
    Coordinates: "コーディネート",
    "T2 (Hotspot)": "T2（ホットスポット",
    "T1 (Actual)": "T1（実際",
    showing: "表示中",
    of: "の",
    "items.": "アイテム",
    "Search Anomaly number or name": "異常番号または名前を検索",
    "CSV Report": "CSVレポート",
    "KML Report": "KMLレポート",
    "Download Report": "レポートをダウンロード",
    "Re-center": "再センタリング",
    "Power Loss recovered": "電力損失回復",
    "Revenue Loss recovered": "収益損失回復",

    "Executive Report,": "エグゼクティブレポート",
    "∆T - Site Condition": "∆T-サイトの状態",
    "Anomaly Number": "異常番号",
    "IV Curve Data": "IV曲線データ",
    Section: "セクション",
    "Custom Reports": "カスタムレポート",
    "Search by site name, location": "サイト名、場所で検索",
    "Inspection Date:": "検査日:",
    Analysis: "解析",
    Flight: "フライト",
    Camera: "カメラ",
    Images: "画像",
    "Enter Yield and Tariff": "産出量と定価を入力",
    "No Anomalies resolved for the selected filters":
      "選択したフィルターに対する異常情報は解決されませんでした",
    "No data available for selected filters. Try removing some.":
      "選択したフィルターに対するデータはありません。いくつかを削除してみてください。",
    "See how your team recover revenue losses over time ":
      "チームの収益損失を時間ごとに回復する方法を確認します ",
    "Anomalies v/s Sections": "異常情報 vs セクション",
    "RGB View": "RGBビュー",
    "Thermal View": "サーマルビュー",
    Actual: "実際",
    Hotspot: "ホットスポット",
    "load previous": "前のロード",
    "load previous 10": "前のロード 10",
    "load next": "次のロード",
    "Atleast access one stie should be given to user":
      "少なくとも1つのstieへのアクセスをユーザーに与える必要があります",
    "Are you sure want to delete user ?":
      "ユーザーを削除してもよろしいですか？",
    "Renaming defects": "欠陥の名前を変更する",
    "Changing Anomalies Severity": "異常の重大度の変更",
    commented: "コメント",
    on: "の上",
    a: "a",
    defect: "欠陥",
    marked: "マークされた",
    as: "なので",
    pending: "保留中",
    inprogress: "進行中",

    falsePositive: "偽陽性",
    resolved: "解決しました",
    notfound: "見つかりません",
    "(Data storage upto 1GB/100MW)": "（最大1GB / 100MWのデータストレージ）",
    "IV Datasheet": "IVデータセット",
    "Upload a File": "ファイルをアップロードする",
    "Data storage upto": "データストレージ",
    "In this case one or more substrings inside the module (group 24 cells) are shorted. Shorting of bus bars, bypass diode shorting can result in this condition. It is recommended to perform the visual inspection of junction box for any deformation which might have resulted in the short-circuit, else IV tracing can provide details of electrical impact caused if any due to this defect.":
      "この場合、モジュール（グループ24セル）内の1つまたは複数のサブストリングがショートしていることになります。バスバーのショート、バイパスダイオードのショートがこの状態を引き起こします。端子ボックスの目視検査を行い、短絡の原因となるような変形がないかを確認することをお勧めします。また、IV測定を行うことで、この欠陥により発生した電気的影響の詳細を確認することができます。保証請求は、モジュールメーカーの条件とデータ要件に基づいて行うことができます。",
    "Single cell hotspots are caused due to cell mismatch. The root cause could be external (glass crack, non-uniform soiling, object shadows) or internal cell mismatch (manufacturing defects). The site recorded to have soiled modules causing multi cell hotspots over modules. Perform visual inspection of the modules. If no external factor observed, IV tracing of the modules can reflect the root cause of hotspot.":
      "ホットスポットは、モジュール内のセルの不整合により発生します。根本的な原因は、外部（ガラスの割れ、不均一な汚れ、物体の影）または内部のセルの不一致（製造上の欠陥）です。モジュールの目視検査を行います。外部要因が観察されない場合、モジュールの IV測定を行うことで、ホットスポットの根本原因を反映させることができます。",
    "Multi cell hotspots arise due to cell mismatch in the modules. The root cause could be external (glass crack, non-uniform soiling, object shadows) or internal cell mismatch (manufacturing defects). The site recorded to have soiled modules causing multi cell hotspots over modules. Perform visual inspection of the modules. If no external factor observed, IV tracing of the modules can reflect the root cause of hotspot":
      "ホットスポットは、モジュール内のセルの不整合により発生します。根本的な原因は、外部（ガラスの割れ、不均一な汚れ、物体の影）または内部のセルの不一致（製造上の欠陥）です。モジュールの目視検査を行います。外部要因が観察されない場合、モジュールの IV測定を行うことで、ホットスポットの根本原因を反映させることができます。",
    "Check for any visible fallen object or heavy soiling that could result in bypass diode activation. If no visible signs of fault cause, cell mismatch, solder interconnect issues could have caused the substring isolation. It is recommended to perform IV tracing of the modules indicating the operating point at which the diode bypasses the substring. 1/3rd of module power is lost when one bypass diode is activated. Note that the Voc might not necessarily be 1/3rd lower than rated.":
      "目に見える落下物やひどい汚れがないか、バイパスダイオードが作動する原因になっていないかを確認します。目に見える兆候がない場合は、ダイオードの定格が低い、セルの不整合、はんだの相互接続の問題が、サブストリングの分離の原因となっている可能性があります。モジュールの IV測定を行い、ダイオードがサブストリングをバイパスする動作ポイントを示すことをお勧めします。1 つのバイパス・ダイオードが作動すると、モジュール電力の 1/3 が失われます。必ずしも定格の 1/3 になるとは限らないため、Voc テストは推奨されないことに注意してください。この異常は、IVテストまたはハンドヘルドサーマルカメラ/スポット温度ガンを使用してのみ検証することができます。保証請求は、モジュールメーカーの条件とデータ要件に応じて開始することができます。",
    "Individual module’s electrical terminals are short circuited. No energy generation is contributed to system. Disconnect the shorting connection and module could be connected back to string. Long exposure of short-circuited modules to higher irradiations could lead to hotspots and accelerate the module degradation. Further missing of this module in energy generation impacts the system voltage. If the module terminals are connected check for junction box shape deforming. It indicates shorted bypass diodes or solder interconnect shorting issue.":
      "個々のモジュールの電気端子が、モジュールの内部または外部で短絡している。システムへのエネルギー生成はありません。モジュールが同じストリングの一部である場合、短絡している接続を特定して切断し、モジュールをストリングに接続し直すことができます。1つのモジュールが短絡しているように見える場合、そのモジュールのすべてのバイパス・ダイオードが損傷します。短絡したモジュールを強い光照射に長時間さらすと、ホットスポットが発生し、モジュールの劣化が加速します。さらに、1つの短絡したモジュールがストリング電圧に影響を与え、他のモジュールの性能が低下します。端子ボックスの形状が変形している場合は、バイパスダイオードが短絡しているか、はんだの相互接続が短絡していることを示しています。ストリングが短絡している場合、短絡箇所が解決されれば、モジュールを接続して動作に戻すことができます。モジュールが短絡している場合は、モジュールメーカーの条件やデータ要件に応じて、保証請求を行うことができます。",
    "In case of vegetation growth, the modules are affected by shadows. It leads to single/multi cell hotspots over it and bypass diode activation in some cases. Vegetation cutting activities in these areas is recommended to avoid hotspots over modules.":
      "植生が成長した場合、モジュールは影の影響を受けます。これは、シングル/マルチセルのホットスポット、モジュールのバイパスダイオードの起動、または影の範囲に基づいて完全なストリングの性能低下につながります。モジュール上のホットスポットを避けるために、これらの区域の植生伐採を推奨します。",
    "The junction boxes for these modules operate at higher temperature compared to normal operating ones. The root cause can be heat generation inside junction boxes due to irregular soldering or heat dissipation by under rated bypass diode. It is recommended to perform a visual inspection of the junction box for any deformation, else IV tracing can provide details of electrical impact caused if any due to this defect.":
      "これらのモジュールの端子ボックスは、通常の動作時に比べて高い温度で動作しています。根本的な原因は、不規則なはんだ付けによる端子ボックス内の発熱や、低定格のバイパスダイオードによる放熱です。端子ボックスに変形がないか目視で確認することをお勧めします。また、IV測定を行うことで、この欠陥が原因で発生した電気的影響の詳細を知ることができます。",
    "Modules are physically missing from the MMS. Uneven configuration of module numbers in string can cause variations in voltages and impact the plant generation.":
      "モジュールが架台(MMS)から物理的に欠落している。ストリング内のモジュール数の不均等な構成は、電圧の変動を引き起こし、プラントの発電に影響を与える可能性があります。",
    "These modules are affected by shadows of lightning arrester and transmission lines. This leads to single/multi cell hotspots over it and bypass diode activation in some cases.":
      "これらのモジュールは、避雷器や送電線の影の影響を受けます。これにより、シングル/マルチセルのホットスポットが発生し、場合によってはバイパスダイオードが作動します。これらのモジュールについては、年に一度、綿密な性能監視を行うことをお勧めします。",
    "Individual PV module is not electrically connected to the system or is underperforming (lower generation / degraded). The reason could be physical connections not connected to system or all the bypass diodes activated in the PV module. Perform visual inspection for any heavy soiling over module or any other external factors. Check for open module connections. If the module is electrically connected and string current is non-zero, all the bypass diodes of the module are active causing complete PV module to isolate from the system. Confirm the same by IV tracing.":
      "個々のPVモジュールがシステムに電気的に接続されていない、または性能が低下しています（発電量の低下/劣化）。その理由は、物理的にシステムに接続されていないか、またはPVモジュール内のすべてのバイパスダイオードが作動していることが考えられます。モジュールにひどい汚れやその他の外的要因がないか目視で確認してください。モジュールの接続が切れていないか確認します。モジュールが電気的に接続されており、ストリング電流がゼロでない場合、モジュールのすべてのバイパスダイオードが作動し、完全なPVモジュールがシステムから隔離されます。 IV測定で同じことを確認してください。モジュールが同じストリングに属している場合、ストリングは回路が開放状態になっています。MC4 コネクタの焼損やケーブルの断線などによる回路が開放状態があれば、それを確認します。Y字コネクタやサーキットブレーカーのヒューズチェックを推奨します。",
    "Few modules were observed to have single/multi cell hotspots due to foreign object fallen over it. It is recommended to clear the objects as the cell temperature can rise above 100°C absolute degrading the cell performance and back sheet quality.":
      "いくつかのモジュールでは、落下してきた異物によりシングル/マルチセルのホットスポットが発生していることが確認されました。セルの温度が明らかに100℃以上に上昇し、セルの性能やバックシートの品質を低下させる可能性があるため、異物を取り除くことが推奨されます。",
    "Few modules were observed to have hotspots due to bird drop over it and this can be visbily seen in the corresponding RGB image provided. It is recommended to clean the modules to avoid hotspots.":
      "いくつかのモジュールでは、鳥の糞によってホットスポットが発生していることが確認されました。ホットスポットが発生しないように、モジュールをクリーニングすることをお勧めします。",
    "Shadows from adjacent rows are causing non uniform irradiation over module. Depending on the shadow area, the modules can produce hotspots and also influence the string current for its series. It is recommended to perform annual shading analysis of the PV asset to quantify the shadow losses and take corrective measures.":
      "隣接する列からの影が、モジュール上の不均一な照射を引き起こしています。影の部分によっては、モジュールにホットスポットが発生し、そのシリーズのストリング電流にも影響を与えます。影の損失を定量化し、是正措置を取るために、太陽光発電資産のシェーディング分析を毎年行うことが推奨されます。",
    "Shadows from adjacent tables are causing non uniform irradiation over module. Depending on the shadow area, the modules can produce hotspots and also influence the string current for its series. It is recommended to perform annual shading analysis of the PV asset to quantify the shadow losses and take corrective measures.":
      "隣接するテーブルからの影により、モジュールへの照射が不均一になっています。影の部分によっては、モジュールにホットスポットが発生したり、直列のストリング電流に影響を与えたりします。影の損失を定量化し、是正措置を取るために、太陽光発電資産のシェーディング分析を毎年行うことが推奨されます。",
    "The front glass of the module is cracked. Multi cell hotspots occur over PV module. Cracked modules have reduced insulation resistance and can lead to frequent ground faults in humid, rainy conditions or during cleaning process. Identifying the ground fault cause and location involves switching off SMB’s leading to generation losses. Hence replacement of this modules with healthier ones is highly recommended to reduce the frequency and causes of ground fault occurrence.":
      "モジュールの前面ガラスが割れています。 PV モジュール上にマルチセルホットスポットが発生します。割れたモジュールは絶縁抵抗が低下しているため、湿度の高い雨の日や洗浄作業中に地絡が頻発します。地絡の原因と場所を特定するには、メインブレーカーの電源を切る必要があり、発電損失につながります。したがって、地絡の発生頻度と原因を減らすために、このモジュールを健全なものに交換することが強く推奨されます。",
    "Wrongly Inclined modules are not in the right position and will be underperforming as they won't receive the same amount of irradiation as other tables. These misalignments can be due to tracker issues. Aligning the table to right position will improve the performance of the table.":
      "誤って傾斜したモジュールは、期待された位置になく、他の正しくマウントされたモジュールと比較して、最適でない照射により性能が低下します。このようなずれは、モジュールが構造体から機械的に外れることや、太陽光照射方向の問題が原因となります。テーブルやモジュールを正しい位置に調整することで、テーブルの性能が向上します。",
    "Subtle temperature difference between normal cell and affected cell appear in the module specifically on the border area of the module. The thermal signature indicates possibility of Potential Induced Degradaion (PID). These modules are positioned in the extremes of the electrical string. Observe for any external factors such as soiling or foreign particles over the module surface. Further check for any visible patterns of degradations over cells such as crack, corrosion or browning. If no visual signatures recorded, the module has internal cell mismatch. The degradation can be quantified using IV tracing. Reduction in Voc value as compared to a healthy reference module indicates PID. Warranty claims can be initiated depending on terms and data requirement of the module manufacturer":
      "正常なセルと影響を受けたセルの間の微妙な温度差が、モジュール内の特にモジュールの境界部分に現れます。この温度差は、PID（Potential Induced Degradaion）の可能性を示しています。これらのモジュールは、電気ストリングの端部に配置されています。モジュールの表面に汚れや異物などの外的要因がないか観察します。さらに、亀裂、腐食、褐色化など、セル上の劣化のパターンが目に見えるかどうかを確認します。目に見える兆候が記録されていない場合、モジュールには内部セルの不整合があります。劣化はIV測定を用いて定量化することができます。健全な基準モジュールと比較してVoc値が減少している場合、PIDの可能性を示しています。モジュールメーカーの条件やデータ要件に応じて、保証請求を行うことができます。",
    "Subtle temperature difference between a normal cell and affected cell are recorded in some of the modules. Observe for any external factors such as soiling or foreign particles over the module surface. If not check for any visible patterns of degradations over cells such as crack, corrosion or browning. If no visual signatures recorded, the module has internal cell mismatch. The degradation can be quantified using IV tracing. Note that Voc and Isc testing only cannot conclude its underperformance. Warranty claims can be initiated depending on terms and data requirement of the module manufacturer":
      "いくつかのモジュールでは、正常なセルと影響を受けたセルの間に微妙な温度差が記録されています。モジュールの表面に汚れや異物などの外的要因がないか観察します。そうでない場合は、クラック、腐食、褐色化など、セル上の劣化の目に見えるパターンを確認します。視覚的なサインが記録されていない場合、モジュールには内部のセルのミスマッチがあります。劣化は、IV測定を用いて定量化することができます。なお、VocとIscのテストだけでは、性能不足を結論づけることはできません。モジュールメーカーの条件やデータ要件に応じて、保証請求を行うことができます。",
    "Visible soiling patterns recorded over PV modules. It results in hotspots over modules and underperformance of strings. Periodic cleaning of PV modules is recommended to avoid long time exposure and degradation of hotspot affected cells":
      "PVモジュールに目に見える汚れのパターンが記録されています。その結果、モジュール上にホットスポットが発生し、ストリングの性能が低下します。ホットスポットの影響を受けたセルが長時間さらされて劣化するのを避けるため、PVモジュールの定期的なクリーニングを推奨します。",
    "Warranty Claim Document": "保証請求書",
    "Anomaly categorisation": "異常の分類",
    "Based on severity (Impact on power loss):":
      "重大度に基づく（電力損失への影響）：",
    "Critical - High impact, priority 1": "クリティカル-影響が大きく、優先度1",
    "Moderate- Moderate impact, priority 2": "中程度-中程度の影響、優先度2",
    "Minor- Minor impact, priority 3": "マイナー-マイナーな影響、優先度3",
    "Based on IEC 62446-3 (Safety hazards):":
      "IEC 62446-3（安全上の問題）に基づく：",
    "Uncategorised - Unclassified anomaly": "未分類-未分類の異常",
    "IEC 1 - Healthy module, no abnormalities":
      "IEC 1-正常なモジュール、異常なし",
    "IEC 2 - Thermal abnormality, medium risk": "IEC 2-熱異常、中リスク",
    "IEC 3 - Safety relevant thermal abnormality, high risk":
      "IEC 3-安全関連の熱異常、高リスク",

    "Enter Yield and Feed in tariff to calculate energy and revenue loss":
      "エネルギーと収益の損失を計算するには、固定価格買取制度に利回りと固定価格買取制度を入力します",

    "Anomalies processed includes false +ve, resolved  and not Found":
      "処理された異常には、false + ve、解決済み、および見つかりませんが含まれます",

    "Categorised based on IEC 62446-3": "IEC62446-3に基づいて分類",
    "IEC Uncategorized - Unclassified abnormality under IEC 62446-3":
      "IEC未分類-IEC62446-3に基づく未分類の異常",

    Comparison: "比較",
    "Compare Inspections": "検査の比較",
    "Initial Power Loss": "初期電力損失",
    "Estimated Revenue Loss": "推定収益損失",
    "No. of Modules": "モジュール数",
    "Anomalies by Severity": "重大度による異常",
    "Section Health": "セクションヘルス",
    "Section health is based on critical anomalies resolved before next inpsection":
      "セクションの健全性は、次の挿入の前に解決された重大な異常に基づいています",
    Block1: "ブロック1",
    Block2: "ブロック2",
    Block3: "ブロック3",
    Block4: "ブロック4",
    "Anomalies Resolved": "解決された異常",
    Bad: "悪い",
    Poor: "貧しい",
    Good: "良い",
    Inspection: "検査",
    "BULK UPLOAD": "バルクアップロード",
    "Upload Data CSV": "データをCSVでアップロード",
    "Anomalies data import": "異常データのインポート",
    "Upload Images, Files and Folders":
      "画像、ファイル、フォルダーをアップロード",
    "Upload Images or Files": "画像またはファイルをアップロード",
    "Upload Folder": "フォルダーをアップロード",
    "How it works?": "どのように動作しますか？",
    "Download CSV report from the Anomalies/Reports page or":
      "異常/レポートページからCSVレポートをダウンロードするか、",
    "download a CSV template.": "CSVテンプレートをダウンロードします。",
    "Update anomaly details and add file names in the CSV file.":
      "異常の詳細を更新し、CSVファイルにファイル名を追加します。",
    "Upload CSV file with updated details.":
      "更新された詳細を含むCSVファイルをアップロードします。",
    "Upload images and files associated.":
      "関連付けられた画像とファイルをアップロードします。",
    "Review anomlay details, images names and file names for each anomaly.":
      "各異常の詳細、画像名、およびファイル名を確認します。",
    "Things to remember": "これらを覚えること",
    "False-positive anomalies cannot be updated using Bulk Upload, mark it individually in the Anomalies page along with proof.":
      "false-positive異常は、バルクアップロードを使用して更新できません。異常ページに異常をマークし、証拠を含めて。",
    "Anomaly details with the same fault number will be overwritten.":
      "同じ故障番号を持つ異常の詳細は、上書きされます。",
    "Only columns matching with anomaly details in system can be updated, additional columns will be ignored.":
      "システム内で異常の詳細と一致する列のみ更新できます。追加の列は無視されます。",
    "Watch a Video": "ビデオを見る",
    "to know more": "さらに知る",
    "Continue & Review Details": "続行して詳細を確認する",
    "Power & Revenue Loss": "電力と収益の損失",
    "Download .Csv": ".Csvをダウンロード",
    Estimated: "推定",
    "Energy Loss": "Energy Loss",
    Yield: "収率",
    "Energy Recovered": "回収されたエネルギー",
    "Feed in Tariff/kWh": "固定価格買取制度/ kWh",
    "Revenue Loss": "収益の損失",
    "Revenue Recovered": "収益は回復しました",
    Initial: "イニシャル",
    Current: "電流",
    "per year": "1年当たり",
    "The total power of your DC capacity which is not contributing towards energy generation on the day of inspection.":
      "検査当日の発電に寄与していないDC容量の総電力",
    "Current power loss from your site due to all unresolved Anomalies. Resolve Anomalies to see a reduction in your Power Loss.":
      "すべての未解決の異常によるサイトからの現在の電力損失。異常を解決して、電力損失の減少を確認します",
    "Yield is the ratio between total annual energy produced and the solar installed capacity.<br/> Yield (kWh/kWp per year) = annual plant output (kWh) / installed capacity (kWp)":
      "収量は、年間総エネルギー生産量と太陽光発電設備容量の比率です。<br/>収量（kWh / kWp /年）=年間プラント出力（kWh）/設備容量（kWp",
    "Total energy that could have been produced in the selected period if there were no defects on modules.":
      "モジュールに欠陥がなかった場合、選択した期間に生成された可能性のある総エネルギー",
    "Energy recovered after resolving anomalies. Resolve anomalies to increase energy generation from your site.":
      "異常を解消した後、エネルギーが回復しました。異常を解決して、サイトからのエネルギー生成を増やします",
    "Enter the selling price of your energy to calculate total revenue losses due to defects at your site.":
      "エネルギーの販売価格を入力して、サイトの欠陥による総収益損失を計算します。",
    "Total revenue loss that will incur from your individual solar site for the chosen period if no Anomalies are resolved.":
      "異常が解決されない場合に、選択した期間に個々のソーラーサイトから発生する総収益損失。",
    "Revenue that is generated from your site after Anomaly resolution. Resolve Anomalies to increase revenue generation.":
      "異常解決後にサイトから生成される収益。異常を解決して収益を増やします。",
    "Affected DC": "影響を受けるDC",
    "Affected DC %": "影響を受けるDC％",
    "The total number of identified defects contributing towards energy and revenue losses.":
      "エネルギーと収益の損失に寄与する特定された欠陥の総数。",
    "Affected DC (Power loss) is the total power that is not contributing towards energy generation due to the associated anomaly.":
      "影響を受けるDC（電力損失）は、関連する異常のためにエネルギー生成に寄与していない総電力です。",
    "Percentage of total DC capacity that is not contributing towards energy generation due to the associated anomaly.":
      "関連する異常のためにエネルギー生成に寄与していない総DC容量のパーセンテージ。",
    "Module Imp": "モジュールインプ",
    "Change Language": "言語を変更する",
    "String Id": "文字列ID",
    "Bird dropping causing hotspot": "ホットスポットを引き起こす鳥の落下",
    "Table containing soiled modules": "汚れたモジュールを含むテーブル",
    "Report Download": "レポートのダウンロード",
    "Site Name": "サイト名",
    "Layout View": "レイアウトビュー",
    "Cracked glass modules": "ひびの入ったガラスモジュール",
    "Inspection Report Guide": "検査報告書ガイド",
    "Clear Filters": "フィルターをクリア",
    "IV Image": "IV画像",
    Downloading: "ダウンロード",
    Admin: "管理者",
    Upgrade: "アップグレード",
    "Unlock more opportunities with AirProbe PRO plan":
      "AirProbePROプランでより多くの機会を開拓",
    Basic: "基本",
    Pro: "プロ",
    Starter: "スターター",
    "Contact Sales to Upgrade":
      "アップグレードするには営業担当者にお問い合わせください",
    "Access to webapp": "webappへのアクセス",
    "Download basic reports": "基本的なレポートをダウンロードする",
    "Navigation to defect": "欠陥へのナビゲーション",
    "Anomaly status": "異常状態",
    "Site data upload and comments": "サイトデータのアップロードとコメント",
    "Thermography Expertise": "サーモグラフィの専門知識",
    "Wide range of filters": "幅広いフィルター",
    "Intercom support and guided tours":
      "インターホンサポートとガイド付きツアー",
    "Web app support": "Webアプリのサポート",
    "Download custom reports": "カスタムレポートをダウンロードする",
    "Graph downloads": "グラフのダウンロード",
    "Warranty claim reports": "保証請求レポート",
    "Bulk upload - faster": "一括アップロード-より高速",
    "Tabular view for large data sets": "大規模なデータセットの表形式のビュー",
    "Internal and external benchmarking": "内部および外部のベンチマーク",
    "Unlimited users and external sharing": "無制限のユーザーと外部共有",
    "Extended data storage( 10GB / 100MW )":
      "拡張データストレージ（10GB / 100MW）",
    Plans: "予定",
    "Upgrade To": "へのアップグレード",
    "Compare your sites performance using premium filters IEC standards, Brands & Models, Panel Type, Inspection Type and Anomaly Status.":
      "プレミアムフィルターIEC規格、ブランドとモデル、パネルタイプ、検査タイプ、および異常ステータスを使用して、サイトのパフォーマンスを比較します。",
    "Upgrade Now": "今すぐアップグレード",
    "A table view of sites anomalies details, listing anomalies of sites across plant levels":
      "サイトの異常の詳細のテーブルビュー、プラントレベル全体のサイトの異常のリスト",
    "Pro Trial Expired": "プロトライアルの有効期限が切れました",
    "Pro Trial": "プロトライアル",
    downloading: "ダウンロード中",
    "Request Sent!": "とった！",
    "DroneBase sales team will reach out to you to setup your PRO plan.":
      "DroneBase営業チームがPROプランを設定するためにあなたに連絡します",
    "Got It!": "とった！",

    "REVIEW DETAILS": "詳細を確認する",
    "IV Data File": "IVデータファイル",
    "Serial No": "シリアル番号",
    "Panel Id": "パネルID",
    Manufacturer: "メーカー",
    "Module Isc(A)": "モジュールIsc（A）",
    "Ref Module Isc(A)": "参照モジュールIsc（A）",
    "Module Wp(W)": "モジュールWp（W）",
    "Ref Module Wp(W)": "参照モジュールWp（W）",
    "String Current(A)": "文字列Current（A",
    "Module Vmp(V)": "モジュールVmp（V",
    "Ref Module Vmp(V)": "参照モジュールVmp（V）",
    "Module Imp(A)": "モジュールImp（A)",
    "Ref Module Imp(A)": "参照モジュールImp（A)",
    "Module Voc(V)": "モジュールVoc（V)",
    "Start Upload": "アップロードを開始",
    cancel: "キャンセル",

    "File does not exist in attachments":
      "添付ファイルにファイルが存在しません",
    Discrepancy: "不一致",
    "Invalid Status": "無効なステータス",
    "All Sections": "すべてのセクション",
    "Are you sure you want to delete punch list":
      "パンチリストを削除してもよろしいですか",
    "Delete File": "ファイルを削除する",
    "Please select a metric field": "メトリックフィールドを選択してください",
    "No Data available for the current selection":
      "現在の選択に使用できるデータはありません",
    "There was an internal server error": "内部サーバーエラーが発生しました",

    Generate: "ファイル",
    File: "ファイル",
    "Generate Report": "レポートの生成",
  },
};

export default jp;
