import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  tooltip: {
    maxWidth: "220px",
    color: "#ffffff",
    background: "#08001D",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
    borderRadius: "5px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "11px",
    lineHeight: "16px",
    textAlign: "left",
    padding: 12,
    whiteSpace: "pre-line",
  },
  // arrow: {
  //   color: "#08001D",
  //   boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
  //   borderRadius: "5px",
  // },
  anomalieTooltipArrow: {
    color: "#08001D",
  },
};

//Take MUi Tooltip and add custom styles to it
const CustomTooltip = withStyles(styles)(Tooltip);

//Default props for the tooltip
CustomTooltip.defaultProps = {
  placement: "top-start",
  enterTouchDelay: 50,
};

export default CustomTooltip;
