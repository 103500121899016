//resize json
const constructResizeJSON = (url, width, height, fit) => {
  const parsedUrl = new URL(url);
  const path = parsedUrl.pathname.slice(1);
  const s3Bucket = parsedUrl.host.split(".")[0];
  const imageSize = {
    resize: {
      width,
      height,
      fit,
    },
  };
  return { bucket: s3Bucket, key: path, edits: imageSize };
};

//Image resizing URL
const resizedImageUrl = (url, width = 340, height = 240, fit = "fill") => {
  if (url === null) {
    return null;
  }
  const baseUrl = "https://d3d0yldnruaudl.cloudfront.net/resized_images-eu/";
  let imageResizeJson = JSON.stringify(
    constructResizeJSON(url, width, height, fit)
  );
  imageResizeJson = decodeURI(imageResizeJson);
  const base64EncodedJson = btoa(imageResizeJson);
  return baseUrl + base64EncodedJson;
};

export default resizedImageUrl;
