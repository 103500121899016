import { Button, Grid, Typography, withStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import DeletePopup from "./popups/DeletePopup";
import MenuDot from "../../assets/img/menuDot.svg";
import RenamePopup from "./popups/RenamePopup";
import { Trans } from "react-i18next";
import styles from "./reports.style";

//reports menu options (Rename, Delete and Export as PDF options)
const MenuOptions = ({
  classes,
  report,
  index,
  getReportHistory,
  callSnackbar,
  loggedInUser,
}) => {
  const [showMenu, toggleMenu] = useState(false);
  const [showRenamePopup, setShowRenamePopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const menuRef = useRef();

  //Adding event listener to close the menu when clicked outside
  useEffect(() => {
    //To handle reports menu
    const handler = (event) => {
      if (menuRef && menuRef.current) {
        if (!menuRef.current.contains(event.target)) {
          toggleMenu(false);
        }
      }
    };

    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  //Called on click of export as pdf. Download the report as pdf, close the menu and show snackbar with success message
  const downloadHandler = (url) => {
    window.open(url);
    toggleMenu(false);
    callSnackbar("Report exported successfully", "success");
  };

  //Handles the rename popup
  const handleRenamePopup = () => {
    setShowRenamePopup(!showRenamePopup);
    toggleMenu(false);
  };

  //Handles the delete popup
  const handleDeletePopup = () => {
    setShowDeletePopup(!showDeletePopup);
    toggleMenu(false);
  };

  //Renders the menu options on the click of menu dot on the reports list
  return (
    <>
      {report.status === "pending" ? (
        <Grid container justifyContent="space-around" direction="row">
          <CircularProgress
            classes={{ root: classes.progressIcon }}
            size={20}
          />
          <Typography
            className={[classes.titleText, classes.inProgTxt].join(" ")}
          >
            <Trans>Generating...</Trans>
          </Typography>
        </Grid>
      ) : report.status === "error" ? (
        <Grid container justifyContent="space-around" direction="row">
          <Typography className={classes.titleText} style={{ color: "red" }}>
            <Trans>failed</Trans>
          </Typography>
        </Grid>
      ) : (
        report.status === "success" && (
          <div
            ref={menuRef}
            className="targetDiv"
            data-intercom-target={index === 0 ? "first-report-menu" : "others"}
          >
            <Grid
              className={classes.menuDot}
              onClick={() => {
                return toggleMenu(!showMenu);
              }}
            >
              <img src={MenuDot} alt="MenuDot" />
            </Grid>
            {showMenu && (
              <Grid className={`${classes.menuOptions} targetMenu`}>
                {report.csv_report_url && (
                  <Button
                    className={classes.menuButton}
                    onClick={() => {
                      return downloadHandler(report.csv_report_url);
                    }}
                  >
                    <Trans>Export as .CSV</Trans>
                  </Button>
                )}
                {report.pdf_report_url && (
                  <Button
                    className={classes.menuButton}
                    classes={{
                      label: classes.btnLabel,
                    }}
                    onClick={() => {
                      return downloadHandler(report.pdf_report_url);
                    }}
                    id={`${loggedInUser.user_profile.client_name}-${report.plant_name}-${report.report_name}-ReportHistory-pageDownload`}
                  >
                    <Trans>Export As .PDF</Trans>
                  </Button>
                )}

                <Button
                  className={classes.menuButton}
                  onClick={handleRenamePopup}
                >
                  <Trans>Rename</Trans>
                </Button>
                <Button
                  className={classes.menuButton}
                  onClick={handleDeletePopup}
                >
                  <Trans>Delete</Trans>
                </Button>
              </Grid>
            )}
          </div>
        )
      )}
      {showRenamePopup && (
        <RenamePopup
          handleRenamePopup={handleRenamePopup}
          report={report}
          getReportHistory={getReportHistory}
          callSnackbar={callSnackbar}
        />
      )}
      {showDeletePopup && (
        <DeletePopup
          handleDeletePopup={handleDeletePopup}
          report={report}
          getReportHistory={getReportHistory}
          callSnackbar={callSnackbar}
        />
      )}
    </>
  );
};

export default withStyles(styles)(MenuOptions);
