import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  SwipeableDrawer,
  Typography,
} from "@material-ui/core";
import { Trans, withTranslation } from "react-i18next";

import CustomButton from "../common/customStyle/Button";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PropTypes from "prop-types";
import React from "react";
import UserUpdate from "./UserUpdate";
import ViewUserDetails from "./ViewUserDetails";
import { connect } from "react-redux";
import { deleteUser } from "../../actions/user";
import moment from "moment";
import styles from "./user.style";
import { withStyles } from "@material-ui/core/styles";

//view user details
class Sites extends React.Component {
  //Initialize states
  constructor(props) {
    super(props);
    this.state = {
      openMenu: false,
      confDialog: false,
      anchorEl: null,
      visible: false,
    };
  }

  //set the visibility of the drawer to true
  handleShowDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  //set the visibility of the drawer to false
  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  //Handles the opening of the menu
  handleClick = (event) => {
    this.setState({ openMenu: true, anchorEl: event.currentTarget });
  };

  //Handles the closing of the menu
  handleClose = () => {
    this.setState({ openMenu: false });
  };

  //Closes the dialog
  handleDialogClose = () => {
    this.setState((prevState) => {
      return {
        confDialog: !prevState.confDialog,
      };
    });
  };

  //Renders the USerComponent
  render() {
    const { classes, data } = this.props;
    const { t } = this.props;
    // If lastname has multiple words it will take last word
    let lname = data.last_name.split(" ");
    lname = lname[lname.length - 1];

    //get loggedIn user
    const loggedInUserType = localStorage.getItem("loginData");

    return (
      <>
        <div
          className={classes.UserListCard}
          style={{ backgroundColor: this.state.openMenu && "#F4F4FB" }}
        >
          <Grid container style={{ cursor: "pointer" }}>
            <Grid
              item
              xs={1}
              className={classes.gridUser}
              onClick={this.handleShowDrawer}
            >
              <span> {this.props.index}</span>
            </Grid>
            <Grid
              item
              xs={2}
              className={classes.gridUser}
              onClick={this.handleShowDrawer}
            >
              <span className={classes.textStyle}>
                <div
                  style={{
                    color: "#536471",
                  }}
                >
                  <span className={classes.avtar}>
                    {data.first_name[0]}
                    {lname[0]}
                  </span>{" "}
                  <span>
                    {data.first_name} {data.last_name}
                  </span>
                </div>
              </span>
            </Grid>
            <Grid item xs={2} onClick={this.handleShowDrawer}>
              <Typography className={classes.userEmail}>
                {data.email_id}
              </Typography>
            </Grid>
            <Grid
              item
              xs
              className={classes.gridUser}
              onClick={this.handleShowDrawer}
            >
              <span>
                {" "}
                {data.active ? (
                  <div className={classes.success}>{t("Active")}</div>
                ) : (
                  <div className={classes.danger}>{t("Inactive")}</div>
                )}
              </span>
            </Grid>
            <Grid
              item
              xs
              className={classes.gridUser}
              onClick={this.handleShowDrawer}
            >
              <span> {data.job_title ? data.job_title : "-"}</span>
            </Grid>
            <Grid
              item
              xs
              className={classes.gridUser}
              onClick={this.handleShowDrawer}
            >
              <span>
                {data.site_access_count}
                <Trans>Sites</Trans>{" "}
              </span>
            </Grid>
            <Grid
              item
              xs
              className={classes.gridUser}
              onClick={this.handleShowDrawer}
            >
              <span>
                {moment(new Date(data.created_on)).format("DD MMM YYYY")}
              </span>
            </Grid>
            <Grid item xs={1} className={classes.gridUser}>
              <div
                style={{ position: "relative" }}
                data-intercom-target="user-menu"
              >
                <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={(e) => {
                    return this.handleClick(e);
                  }}
                >
                  <MoreVertIcon />
                </IconButton>

                <Menu
                  id="long-menu"
                  anchorEl={this.state.anchorEl}
                  open={this.state.openMenu}
                  onClose={this.handleClose}
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                  className={classes.textStyle}
                >
                  <div style={{ width: 100 }}>
                    <MenuItem
                      ListItemClasses={{
                        root: classes.focusVisible,
                      }}
                      onClick={this.handleShowDrawer}
                    >
                      <Trans>View</Trans>
                    </MenuItem>

                    {/* site admin cannot edit and delete general admin. */}
                    {JSON.parse(loggedInUserType)?.role === "site:admin" &&
                    data?.role_type === "CLIENT_ADMIN" ? (
                      ""
                    ) : (
                      <MenuItem
                        style={{ maxHeight: 30 }}
                        ListItemClasses={{
                          root: classes.focusVisible,
                        }}
                      >
                        <UserUpdate
                          plantList={this.props.plantList}
                          usersList={this.props.usersList}
                          data={this.props.data}
                        />
                      </MenuItem>
                    )}

                    {JSON.parse(loggedInUserType)?.role === "site:admin" &&
                    data?.role_type === "CLIENT_ADMIN" ? (
                      ""
                    ) : (
                      <MenuItem
                        ListItemClasses={{
                          root: classes.focusVisible,
                        }}
                        onClick={() => {
                          return this.handleDialogClose();
                        }}
                        className={classes.danger}
                      >
                        <Trans>Delete</Trans>
                      </MenuItem>
                    )}
                  </div>
                </Menu>
              </div>
            </Grid>
          </Grid>
        </div>

        {/* Confirmation dialog for delete user */}
        <Dialog
          open={this.state.confDialog}
          onClose={() => {
            return this.handleDialogClose();
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <Trans>Delete User</Trans>
          </DialogTitle>
          <DialogContent style={{ minWidth: 400 }}>
            <Trans>Are you sure want to delete user ?</Trans>
          </DialogContent>
          <DialogActions>
            <CustomButton
              onClick={() => {
                return this.props.deleteUser(data.user_id);
              }}
              text={<Trans>Delete</Trans>}
            />
            <CustomButton
              onClick={() => {
                return this.handleDialogClose();
              }}
              type="cancel"
              text={<Trans>Cancel</Trans>}
            />
          </DialogActions>
        </Dialog>

        <SwipeableDrawer
          anchor="right"
          open={this.state.visible}
          onClose={() => {
            return this.onClose();
          }}
          onOpen={() => this.setState({ visible: true })}
          classes={{ paper: classes.swipDrawer }}
        >
          <ViewUserDetails
            plantList={this.props.plantList}
            usersList={this.props.usersList}
            key={this.props.key}
            userData={this.props.data}
            index={this.props.index}
            handleViewDrawerClose={this.onClose}
            deleteUser={(userId) => {
              return this.props.deleteUser(userId);
            }}
          />
        </SwipeableDrawer>
      </>
    );
  }
}

//Default Prop types
// skipcq static properties should be positioned JS-0472
Sites.propTypes = {
  deleteUser: PropTypes.func.isRequired,
};

//calling redux to get the users data and selected language
const mapStateToProps = (state) => {
  return {
    user: state.user,
    language: state.language.selectedLanguage,
  };
};
export default connect(mapStateToProps, {
  deleteUser,
})(withStyles(styles)(withTranslation()(Sites)));
