import { SET_SELECTEDLANGUAGE } from "../constants/actionTypes";

//Initial state of the reducer
const initialState = {
  selectedLanguage: "en",
};

//Create the reducer to set the selected language
// skipcq Default parameters should be defined at the last JS-0106
const selectedLanguage = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_SELECTEDLANGUAGE:
      return {
        ...state,
        selectedLanguage: payload,
      };
    default:
      return state;
  }
};

//Create and export the reducer to set the selected language
export const setSelectedLanguage = (value) => {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTEDLANGUAGE,
      payload: value,
    });
  };
};

export default selectedLanguage;
