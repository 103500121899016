import {
  AppBar,
  Grid,
  MenuItem,
  Toolbar,
  Typography,
  withStyles,
} from "@material-ui/core";
import { Trans, withTranslation } from "react-i18next";

import Alert from "../../layout/Alert";
import NewLoader from "../../layout/newLoader";
import PropTypes from "prop-types";
import React from "react";
import ReactGA from "react-ga";
import SearchBar from "../common/customStyle/searchBar";
import SortLogo from "../../assets/img/sortLogo.svg";
import UserAdd from "./UserAdd";
import UserComponent from "./UserComponent";
import { connect } from "react-redux";
import { getUsers } from "../../actions/user";
import i18n from "i18next";
import { trackId } from "../../config";
import userStyle from "./user.style";

ReactGA.initialize(trackId);
//user details
class Sites extends React.Component {
  //Initializing states
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      isAscending: true,
    };
  }

  //These functions are called on the initial render of the component
  componentDidMount() {
    // For Google Analytics
    ReactGA.pageview(window.location.pathname);

    //Get the user details on the initial render of the component
    this.props.getUsers();
  }

  //Handles the sorting of the users based on the name
  namecompare = (a, b) => {
    if (this.state.isAscending) {
      this.toggle(false);
      if (b.first_name > a.first_name) return -1;

      if (b.first_name < a.first_name) return 1;

      return 0;
    }
    this.toggle(true);
    if (b.first_name < a.first_name) return -1;
    if (b.first_name > a.first_name) return 1;

    return 0;
  };

  //calling function to Sort users based on their names
  nameSort = () => {
    const users = this.props.user.users;

    this.props.user.users = users.sort(this.namecompare);
  };

  //Handles the sorting of the users based on the email
  emailcompare = (a, b) => {
    if (this.state.isAscending) {
      this.toggle(false);

      if (b.email_id > a.email_id) {
        return -1;
      }
      if (b.email_id < a.email_id) {
        return 1;
      }
      return 0;
    }
    this.toggle(true);
    if (b.email_id < a.email_id) return -1;
    if (b.email_id > a.email_id) return 1;

    return 0;
  };

  //calling the function to sort users based on their emails
  emailSort = () => {
    const users = this.props.user.users;

    this.props.user.users = users.sort(this.emailcompare);
  };

  //Handles the sorting of the users based on the status (active/inactive)
  statuscompare = (a, b) => {
    if (this.state.isAscending) {
      this.toggle(false);

      if (b.active > a.active) {
        return -1;
      }
      if (b.active < a.active) {
        return 1;
      }
      return 0;
    }
    this.toggle(true);

    if (b.active < a.active) return -1;
    if (b.active > a.active) return 1;

    return 0;
  };

  //Calling function to sort users based on their status
  statusSort = () => {
    const users = this.props.user.users;

    this.props.user.users = users.sort(this.statuscompare);
  };

  //Handles the sorting of the users based on the last login date
  datecompare = (a, b) => {
    if (this.state.isAscending) {
      this.toggle(false);

      if (new Date(b.last_sign_in) > new Date(a.last_sign_in)) {
        return -1;
      }
      if (new Date(b.last_sign_in) < new Date(a.last_sign_in)) {
        return 1;
      }
      return 0;
    }
    this.toggle(true);
    if (new Date(b.last_sign_in) < new Date(a.last_sign_in)) return -1;
    if (new Date(b.last_sign_in) > new Date(a.last_sign_in)) return 1;

    return 0;
  };

  //Calling function to sort users based on their last login date
  dateSort = () => {
    const users = this.props.user.users;

    this.props.user.users = users.sort(this.datecompare);
  };

  //Handles ascending or descending order of the sorting
  toggle = (value) => {
    this.setState({ isAscending: value });
  };

  //Handles the change in title input
  setTitle = (value) => {
    this.setState({ title: value });
  };

  //Renders the users page
  render() {
    const { classes } = this.props;
    let users = this.props.user.users;
    users = users.filter((user) => {
      return (
        user.first_name
          .toLocaleLowerCase()
          .indexOf(this.state.title.toLocaleLowerCase()) !== -1 ||
        user.last_name
          .toLocaleLowerCase()
          .indexOf(this.state.title.toLocaleLowerCase()) !== -1 ||
        user.email_id
          .toLocaleLowerCase()
          .indexOf(this.state.title.toLocaleLowerCase()) !== -1
      );
    });
    return (
      <>
        <AppBar position="static" className={classes.users2}>
          <Toolbar>
            <Typography
              className={classes.userTitle}
              style={{ marginLeft: `${this.props.toggleMenu && "30px"}` }}
            >
              <Trans>Users</Trans>
            </Typography>

            <SearchBar
              keyword={this.state.title}
              setKeyword={this.setTitle}
              placeholder={i18n.t("Search by name, email...")}
            />

            <MenuItem style={{ padding: 0 }}>
              <UserAdd usersList={users} />
            </MenuItem>
          </Toolbar>
        </AppBar>
        <div>
          <div style={{ margin: "0 20px" }} className={classes.textStyle}>
            <Alert />
            <div className={classes.user1}>
              <Grid container justifyContent="flex-end">
                <Grid item xs={1}>
                  <Typography className={classes.userId}>
                    <Trans>Id</Trans>
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <span
                    onClick={() => {
                      return this.nameSort();
                    }}
                    role="button"
                    tabIndex={"0"}
                    aria-hidden="true"
                  >
                    <Trans>Name</Trans>
                    <img alt="img" src={SortLogo} />
                  </span>
                </Grid>
                <Grid item xs={2}>
                  <span
                    onClick={() => {
                      return this.emailSort();
                    }}
                    role="button"
                    tabIndex={"-1"}
                    aria-hidden="true"
                  >
                    <Trans>Email</Trans>
                    <img alt="img" src={SortLogo} />
                  </span>
                </Grid>
                <Grid item xs>
                  <span
                    onClick={() => {
                      return this.statusSort();
                    }}
                    role="button"
                    tabIndex={"-2"}
                    aria-hidden="true"
                  >
                    <Trans>Status</Trans>
                    <img alt="img" src={SortLogo} />
                  </span>
                </Grid>
                <Grid item xs>
                  <Trans>Role</Trans>
                </Grid>
                <Grid item xs>
                  <span>
                    <Trans>Site Access</Trans>
                  </span>
                </Grid>
                <Grid item xs>
                  <span
                    onClick={() => {
                      return this.dateSort();
                    }}
                    role="button"
                    tabIndex={"-3"}
                    onKeyDown={() => {
                      return this.dateSort();
                    }}
                    aria-hidden="true"
                  >
                    <Trans>Date Created</Trans>
                    <img alt="img" src={SortLogo} />
                  </span>
                </Grid>
                <Grid item xs={1}>
                  <span>
                    <Trans>Action</Trans>
                  </span>
                </Grid>
              </Grid>
            </div>
            {this.props.user.loading ? (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{ height: "calc(100vh - 200px)" }}
              >
                <NewLoader />
              </Grid>
            ) : (
              users.map((user, index) => {
                return (
                  <UserComponent
                    plantList={this.props.user}
                    usersList={users}
                    key={`${user.username}`}
                    data={user}
                    index={index + 1}
                  />
                );
              })
            )}
          </div>
        </div>
      </>
    );
  }
}

//Default props
// skipcq static properties should be positioned JS-0472
Sites.propTypes = {
  getUsers: PropTypes.func.isRequired,
};

//Calling redux to get the users list and selected language
const mapStateToProps = (state) => {
  return {
    user: state.user,
    language: state.language.selectedLanguage,
  };
};
export default connect(mapStateToProps, {
  getUsers,
})(withStyles(userStyle)(withTranslation()(Sites)));
