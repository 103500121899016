import { Grid, withStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import CookieBlock from "./CookieBlock";
import CookieListing from "./CookieListing";
import CookiePopOver from "./CookiePopOver";
import styles from "./cookieConsent.style";

//manage cookies used in the application
function CookieConsent({ classes, cookieStorage }) {
  const [showCookieConsent, setShowCookieConsent] = useState(false);
  const [showCookiePopOver, setShowCookiePopOver] = useState(false);
  const [selectedCookieList, setSelectedCookieList] = useState(null);
  const [selectedCookieSection, setSelectedCookieSection] = useState(null);
  const [showCookieListing, setShowCookieListing] = useState(false);
  const [userCookies, setUserCookies] = useState([]);
  const [preference, setPreference] = useState([
    { cookieName: "essentialCookies", value: true },
    { cookieName: "performanceCookies", value: false },
  ]);

  //show cookies popover
  const manageCookieHandler = (cookieConsent, cookiePopOver) => {
    setShowCookieConsent(cookieConsent);
    setShowCookiePopOver(cookiePopOver);
  };

  //get cookies list
  const getCookieListing = (cookieList, section) => {
    if (cookieList.length > 0) {
      manageCookieHandler(false, false);
      setSelectedCookieList(cookieList);
      setSelectedCookieSection(section);
      setShowCookieListing(true);
    }
  };

  //handle back arrow
  const backArrowHandler = () => {
    setShowCookieListing(false);
    manageCookieHandler(false, true);
  };

  //To handle change in cookies list
  const onChangeHandler = (cookieName, value) => {
    const exist = preference.filter((cookie) => {
      return cookie.cookieName === cookieName;
    });
    if (exist.length) {
      const newList = preference.map((cookie) => {
        if (cookie.cookieName === cookieName) {
          cookie.value = value;
        }
        return cookie;
      });
      setPreference(newList);
    } else {
      setPreference([...preference, { cookieName, value }]);
    }
  };

  //load cookies
  const loadAirProbeCookies = () => {
    const cookieStore = cookieStorage.getAirprobeCookies();
    if (cookieStore.cookies.length > 0) {
      setUserCookies([...userCookies, ...cookieStore.cookies]);
      setShowCookieConsent(false);
    } else {
      setUserCookies([]);
      setShowCookieConsent(true);
    }
    return cookieStore.cookies;
  };

  //save preferred cookies
  const savePreferenceHandler = () => {
    const performanceCookies = preference.filter((cookie) => {
      return cookie.cookieName === "performanceCookies";
    })[0];
    if (performanceCookies.value) {
      for (const cookie of preference) {
        cookieStorage.setItem(cookie.cookieName, cookie.value);
      }
    } else {
      for (const cookie of preference) {
        cookieStorage.setItem(cookie.cookieName, cookie.value, 31);
      }
    }
    loadAirProbeCookies();
  };

  //function to handle cookies
  const allowCookies = () => {
    for (const cookie of preference) {
      cookieStorage.setItem(cookie.cookieName, true);
    }
    loadAirProbeCookies();
  };

  useEffect(() => {
    loadAirProbeCookies();
  }, []);

  return (
    userCookies.length === 0 &&
    (showCookieConsent ? (
      <CookieBlock
        manageCookieHandler={() => {
          return manageCookieHandler(false, true);
        }}
        allowCookies={allowCookies}
      />
    ) : (
      <Grid className={classes.cookieConsent}>
        {showCookiePopOver && (
          <CookiePopOver
            manageCookieHandler={() => {
              return manageCookieHandler(true, false);
            }}
            getCookieListing={getCookieListing}
            onChangeHandler={onChangeHandler}
            savePreferenceHandler={savePreferenceHandler}
          />
        )}
        {!showCookiePopOver && showCookieListing && (
          <CookieListing
            selectedCookieList={selectedCookieList}
            selectedCookieSection={selectedCookieSection}
            manageCookieHandler={manageCookieHandler}
            closeCookieListing={() => {
              setShowCookieListing(false);
              manageCookieHandler(true, false);
            }}
            backArrowHandler={backArrowHandler}
            savePreferenceHandler={savePreferenceHandler}
          />
        )}
      </Grid>
    ))
  );
}

export default withStyles(styles)(CookieConsent);
