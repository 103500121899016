export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const GET_BASIC_DETAILS = "GET_BASIC_DETAILS";
export const GET_ACADEMIC_DETAILS = "GET_ACADEMIC_DETAILS";
export const DETAILS_ERROR = "DETAILS_ERROR";
export const CLEAR_DETAILS = "CLEAR_DETAILS";
export const CLEAR_ALERTS = "CLEAR_ALERTS";
export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILES = "GET_PROFILES";
export const GET_REPOS = "GET_REPOS";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const CLEAR_PROFILES = "CLEAR_PROFILES";
export const PROFILE_ERROR = "PROFILE_ERROR";
export const LOADING = "LOADING";
export const GET_SUCCESSFUL_TRANSACTIONS = "GET_SUCCESSFUL_TRANSACTIONS";
export const TRANSACTION_ERROR = "TRANSACTION_ERROR";
export const UPDATE_USER = "UPDATE_USER";
export const REMOVE_USER = "REMOVE_USER";
export const GET_USERS = "GET_USERS";
export const GET_SITEACCESS = "GET_SITEACCESS";
export const SET_ACTIVITY = "SET_ACTIVITY";
export const RESET_ACTIVITY = "RESET_ACTIVITY";
export const GET_PRODUCT_STATUS = "GET_PRODUCT_STATUS";
export const RESET_PRODUCT_STATUS = "RESET_PRODUCT_STATUS";
export const CALLSNACKBAR = "CALLSNACKBAR";
export const CLEARSNACKBAR = "CLEARSNACKBAR";
export const SETSHOWUPLOADBLOCK = "SETSHOWUPLOADBLOCK";
export const SETALLFILESAWSPARAMS = "SETALLFILESAWSPARAMS";
export const UPDATEALLFILESAWSPARAMS = "UPDATEALLFILESAWSPARAMS";
export const UPDATEUPLOADFAILED = "UPDATEUPLOADFAILED";
export const SETALLFILESFORUPLOAD = "SETALLFILESFORUPLOAD";
export const CLOSEUPLOADBLOCK = "CLOSEUPLOADBLOCK";
export const SETANOMALIESLIST = "SETANOMALIESLIST";
export const SETREQUESTOBJ = "SETREQUESTOBJ";
export const SETALLFILESREDUX = "SETALLFILESREDUX";
export const SETALLFILENAMES = "SETALLFILENAMES";
export const CANCELUPLOAD = "CANCELUPLOAD";
export const SET_SELECTEDLANGUAGE = "SET_SELECTEDLANGUAGE";
