import { Button } from "@material-ui/core";
import React from "react";
import { Trans } from "react-i18next";
import iec1 from "../../../assets/img/iec1.svg";
import iec2 from "../../../assets/img/iec2.svg";
import iec3 from "../../../assets/img/iec3.svg";
import minorImg from "../../../assets/img/Subtract.svg";
import subtractImg from "../../../assets/img/Moderate2.svg";
import uncategorized from "../../../assets/img/uncategorized.svg";
import vectorImg from "../../../assets/img/Vector.svg";
import { withStyles } from "@material-ui/core/styles";

//filterButton styles
const styles = () => {
  return {
    fltBtn: {
      color: "#536471",
      background: "#FFFFFF",
      border: "1px solid #E4EAF5",
      borderRadius: 5,
      fontSize: 12,
      marginLeft: 0,
      height: 32,
      paddingLeft: 6,
      paddingRight: 6,
      display: "flex",
      justifyContent: "flex-start",
      marginBottom: "16px",
    },
    btnLabel: {
      pointerEvents: "none",
    },
    sevCriticalIcon: {
      width: 10,
      marginTop: 0,
      marginLeft: 4,
      marginRight: 9,
    },
    sevModerateIcon: {
      width: 13.5,
      marginTop: -2,
      marginLeft: 4,
      marginRight: 7,
    },
    sevMinorIcon: {
      width: 12,
      marginTop: 0,
      marginLeft: 4,
      marginRight: 8,
    },
    sevText: {
      width: 75,
      textAlign: "left",
    },
    iecIcon: {
      width: 16,
      marginTop: 0,
      marginLeft: 2,
      marginRight: 5,
    },
    iecText: {
      width: 100,
      textAlign: "left",
    },
  };
};

//custom filter button
class CustomFilterButton extends React.Component {
  //Add severity icon as per the key provided
  addSeverityIcon = (value) => {
    const { classes } = this.props;
    const sevGetImage =
      value === "Critical"
        ? vectorImg
        : value === "Moderate"
        ? subtractImg
        : minorImg;

    return (
      <>
        <img
          alt="sevImg"
          className={
            value === "Critical"
              ? classes.sevCriticalIcon
              : value === "Moderate"
              ? classes.sevModerateIcon
              : classes.sevMinorIcon
          }
          src={sevGetImage}
        />
        <div className={classes.sevText}>
          <Trans>{value}</Trans>
        </div>
      </>
    );
  };

  //Add IEC icon as per the key provided
  addIecIcon = (value) => {
    const { classes } = this.props;
    const iecGetImage =
      value === "IEC 1"
        ? iec1
        : value === "IEC 2"
        ? iec2
        : value === "IEC 3"
        ? iec3
        : uncategorized;

    return (
      <>
        <img alt="iecImg" className={classes.iecIcon} src={iecGetImage} />
        <div className={classes.iecText}>
          <Trans>{value}</Trans>
        </div>
      </>
    );
  };

  //Add label icon as per the key provided
  addLabelIcon = (value) => {
    const { classes } = this.props;

    return (
      <>
        <div
          style={{
            height: "6px",
            width: "6px",
            background:
              value === "NEW"
                ? "#149B9E"
                : value === "PERSISTENT"
                ? "#8D95A2"
                : value === "TRANSITIONED"
                ? "#F26649"
                : "#FFCB05",
            borderRadius: "100%",
            marginRight: "8px",
          }}
        />
        <div className={classes.iecText} style={{ width: "85px" }}>
          <Trans>
            {value === "NEW"
              ? "New"
              : value === "PERSISTENT"
              ? "Persistent"
              : value === "TRANSITIONED"
              ? "Transitioned"
              : "Recurring"}
          </Trans>
        </div>
      </>
    );
  };

  //Renders custom labels for filter buttons
  render() {
    const { classes } = this.props;

    return (
      <Button
        size="small"
        variant="outlined"
        className={classes.fltBtn}
        onClick={this.props.onClick}
        style={
          this.props.selected
            ? { border: "1px solid #3FC0C2" }
            : { border: "1px solid #e4eaf5" }
        }
        id={this.props.gtmId}
        classes={{
          label: classes.btnLabel,
        }}
      >
        {this.props.type === "severity"
          ? this.addSeverityIcon(this.props.name)
          : this.props.type === "label"
          ? this.addLabelIcon(this.props.name)
          : this.addIecIcon(this.props.name)}
      </Button>
    );
  }
}

export default withStyles(styles)(CustomFilterButton);
