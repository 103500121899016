import { CALLSNACKBAR, CLEARSNACKBAR } from "../constants/actionTypes";

//Initial state of the reducer
const initialState = {
  handleSnackbar: false,
  displayMsg: "",
  severity: "",
};

//Create and export the reducer to set the snackbar information
export const callSnackbarAction = (msg, sev) => {
  return (dispatch) => {
    dispatch({
      type: CALLSNACKBAR,
      payload: { msg, sev: sev ? sev : "info" },
    });
  };
};

//Create and export the reducer to clear the snackbar information
export const clearSnackbarAction = () => {
  return (dispatch) => {
    dispatch({
      type: CLEARSNACKBAR,
    });
  };
};

//Define the reducer
// skipcq Default parameters should be defined at the last JS-0106
export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case CALLSNACKBAR:
      return {
        displayMsg: payload.msg,
        severity: payload.sev,
        handleSnackbar: true,
      };
    case CLEARSNACKBAR:
      return { displayMsg: "", severity: "", handleSnackbar: false };
    default:
      return state;
  }
};
