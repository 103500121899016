import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { Trans, useTranslation, withTranslation } from "react-i18next";

import CustomButton from "../common/customStyle/Button";
import DownloadReports from "./DownloadReports";
import MenuOptions from "./MenuOptions";
import NewLoader from "../../layout/newLoader";
import ProLabel from "../common/ProLabel";
import SearchBar from "../common/customStyle/searchBar";
import Spinner from "../../layout/Spinner";
import { URLS } from "../../constants";
import _ from "lodash";
import { allRequestHandler } from "../../api";
import moment from "moment";
import shortid from "shortid";
import styles from "./reports.style";
import { withStyles } from "@material-ui/core/styles";

//custom report component
const CustomReport = ({
  setShowCustomReport,
  reportHistory,
  getReportHistory,
  callSnackbar,
  plantList,
  reportTypes,
  classes,
  handleShowPlans,
  loggedInUser,
  reportObj,
  anomalies,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [startIndex, setStartIndex] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isLoader, setIsLoader] = useState(true);
  const { t } = useTranslation();
  const [reports, setReports] = useState([]);
  const reportsList = useRef();

  //filter custom report history
  useEffect(() => {
    const filteredReportHistory =
      reportHistory &&
      reportHistory.length &&
      reportHistory.filter((report) => {
        return report.report_name
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      });

    setReports(filteredReportHistory);
  }, [reportHistory]);

  //handle if report request is made from dashboard page
  useEffect(() => {
    if (reportObj && window.history.state === "/dashboard") {
      const res =
        reportHistory &&
        reportHistory.length &&
        reportHistory.filter((report) => {
          return report.report_name
            .toLowerCase()
            .includes(searchTerm.toLowerCase());
        });

      reportsList.current = res;

      if (reportsList.current) {
        handleReportReqFromDashboard(reportObj);
      }
    }
  }, [reportHistory]);

  // To show loader for 3 secs everytime
  useEffect(() => {
    setTimeout(() => {
      setIsLoader(false);
    }, 3000);
  }, []);

  //handle pagination (load next/previous 10 reports)
  const handleNavigation = (direction) => {
    if (direction === "next") {
      setStartIndex(startIndex + 10);
    } else if (direction === "prev") {
      setStartIndex(startIndex - 10);
    }
  };

  const handleReportReqFromDashboard = async (reportDetails) => {
    const test = {
      created_on: new Date().toJSON(),
      plant_name: "",
      report_name:
        reportDetails.report_type === "executive_report"
          ? "Executive Report"
          : "Thermal Analysis Document",
      report_type: reportDetails.report_type,
      section_name: "",
    };

    reportsList.current.push(test);

    setReports(reportsList.current);
    setIsDownloading(true);

    const getUrl = await allRequestHandler({
      requestType: "POST",
      requestUrl: URLS.REPORT_API,
      requestData: reportDetails,
    });

    if (getUrl && getUrl.download_url) {
      window.open(getUrl.download_url);
    }

    //to do
    const history = await allRequestHandler({
      requestType: "GET",
      requestUrl: `${URLS.REPORT_API}/history`,
    });

    if (history) {
      const filteredHistoryList =
        history &&
        history.reports_list.length &&
        history.reports_list.filter((report) => {
          return report.report_name
            .toLowerCase()
            .includes(searchTerm.toLowerCase());
        });

      setReports(filteredHistoryList);
      setIsDownloading(false);
    }

    //To avoid sideeffects
    window.history.pushState(null, null, null);
  };

  const handleReportDetails = (reportDetails) => {
    if (reportDetails && reports?.length) {
      reports.push(reportDetails);

      //To avoid sideeffects
      window.history.pushState(null, null, null);
    }
  };

  //This renders the custom reports page
  return (
    <>
      <DownloadReports
        handleReportDetails={(e) => handleReportDetails(e)}
        callSnackbar={callSnackbar}
        plantList={plantList}
        reportTypes={reportTypes}
        isDownloading={isDownloading}
        generateReport={setIsDownloading}
        handleShowPlans={handleShowPlans}
        getReportHistory={getReportHistory}
        loggedInUser={loggedInUser}
        anomalies={anomalies}
      />
      <Grid className={classes.reportHistory}>
        <Typography className={classes.subtitle} style={{ paddingLeft: 0 }}>
          <Trans>REPORTS HISTORY</Trans>
        </Typography>
        <Grid className={classes.reportTable}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className={classes.reportTableCont}
          >
            <SearchBar
              keyword={searchTerm}
              placeholder={t("Search By Report Name")}
              setKeyword={setSearchTerm}
            />

            <Grid data-intercom-target="generate-custom-report">
              <div className={classes.prolabelCont}>
                <div>
                  <ProLabel marginRight="5px" marginLeft="0" />
                </div>
                <CustomButton
                  onClick={() => {
                    return setShowCustomReport(false);
                  }}
                  text={<Trans>Generate Custom Report</Trans>}
                />
              </div>
            </Grid>
          </Grid>
          <Grid style={{ overflow: "scroll" }}>
            <Table className={classes.table} style={{ padding: "50px" }}>
              <TableHead>
                <TableRow>
                  <TableCell
                    className={classes.tableHeader}
                    style={{ paddingLeft: "24px" }}
                  >
                    <Trans>Report Name</Trans>
                  </TableCell>
                  <TableCell className={classes.tableHeader}>
                    <Trans>Site</Trans>
                  </TableCell>
                  <TableCell className={classes.tableHeader}>
                    <Trans>Sections</Trans>
                  </TableCell>
                  <TableCell className={classes.tableHeader}>
                    <Trans>Created by</Trans>
                  </TableCell>
                  <TableCell className={classes.tableHeader}>
                    <Trans>Date Created</Trans>
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {reports && reports.length
                  ? _.orderBy(reports, ["created_on"], ["desc"])
                      .filter((item, index) => {
                        return index >= startIndex && index < startIndex + 10;
                      })
                      .map((item, index) => {
                        return (
                          <TableRow
                            key={shortid.generate()}
                            className={classes.tableRow}
                          >
                            <TableCell
                              className={classes.tableItem}
                              style={{ paddingLeft: "24px" }}
                            >
                              <Trans>
                                {item.report_name ? item.report_name : "-"}
                              </Trans>
                            </TableCell>
                            <TableCell className={classes.tableItem}>
                              {item.plant_name ? item.plant_name : "-"}
                            </TableCell>
                            <TableCell className={classes.tableItem}>
                              {item.section_name ? item.section_name : "-"}
                            </TableCell>
                            <TableCell className={classes.tableItem}>
                              {item.created_by ? item.created_by : "-"}
                            </TableCell>
                            <TableCell className={classes.tableItem}>
                              {}
                              {item.created_on
                                ? moment(item.created_on).format("DD MMM YYYY")
                                : "-"}
                            </TableCell>
                            <TableCell
                              className={`${classes.tableItem} targetCell`}
                              style={{
                                paddingRight: "56px",
                                position: "relative",
                              }}
                            >
                              {isDownloading && index === 0 ? (
                                <div className={classes.InProgressLoader}>
                                  <Spinner marginTop=" " size="22" />
                                  <div className={classes.InProgressTxt}>
                                    Generating...
                                  </div>
                                </div>
                              ) : (
                                <MenuOptions
                                  report={item}
                                  getReportHistory={getReportHistory}
                                  callSnackbar={callSnackbar}
                                  index={index}
                                  loggedInUser={loggedInUser}
                                />
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })
                  : ""}
                <Grid>
                  {!isLoader && !reports ? (
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      className={classes.loader}
                    >
                      <Typography
                        style={{
                          fontFamily: "Montserrat",
                          fontStyle: "normal",
                          fontWeight: 500,
                          fontSize: "15px",
                          lineHeight: "20px",
                          color: "#545B65",
                        }}
                      >
                        Generate reports to see it in the list...
                      </Typography>
                    </Grid>
                  ) : (
                    !reports && (
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        className={classes.loader}
                      >
                        <NewLoader />
                      </Grid>
                    )
                  )}
                </Grid>
              </TableBody>
            </Table>
          </Grid>

          <div className={classes.filteredReport}>
            {startIndex > 0 && (
              <Typography
                className={`${classes.titleText} ${classes.selectLink}`}
                onClick={() => {
                  return handleNavigation("prev");
                }}
              >
                <Trans>load previous 10</Trans>
              </Typography>
            )}

            <Typography
              style={{ margin: " 0 1rem" }}
              className={classes.titleText}
            >
              <Trans>showing</Trans> {startIndex + 1} -{" "}
              {reports?.length > startIndex + 10
                ? startIndex + 10
                : reports?.length}{" "}
              <Trans>of</Trans> {reports?.length && reports?.length}{" "}
              <Trans>items.</Trans>
            </Typography>
            {reports && startIndex + 10 < reports?.length ? (
              <Typography
                className={`${classes.titleText} ${classes.selectLink}`}
                onClick={() => {
                  return handleNavigation("next");
                }}
              >
                <Trans>load next 10</Trans>
              </Typography>
            ) : (
              ""
            )}
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(styles)(withTranslation()(CustomReport));