const sp = {
  translations: {
    Dashboard: "Tablero",
    Portfolio: "portafolio",
    "Dashboard Tour": "Recorrido por el tablero",
    "All Inspections": "Todas las inspecciones",
    "Download Reports": "Descargar informes",
    Site: "Sitio",
    Sites: "Sitios",
    "Select Plant": "Seleccionar Planta",
    "Report Type": "Tipo de informe",
    "Select Report": "Seleccionar informe",
    Download: "Descargar",
    "Anomalies/MWp": "Anomalías / MWp",
    "Anomaly Status": "Estado de anomalía",
    Filters: "Filtros",
    "DC Capacity": "Capacidad DC",
    "AC Capacity": "Capacidad AC",
    "Power Loss": "Pérdida de potencia",
    "Revenue Loss/yr": "Pérdida de ingresos / año",
    ANOMALIES: "ANOMALIAS",
    "anomaly categorisation": "categorización de anomalías",
    Anomalies: "Anomalías",
    Status: "Estado",
    Severity: "Gravedad",
    IEC: "IEC",
    Total: "Total",
    Pending: "Pendiente",
    Resolved: "Resuelta",
    "In Progress": "En curso",
    "Not Found": "No encontrada",
    "False +ve": "Falso +ve",
    Critical: "Crítica",
    Moderate: "Moderada",
    Minor: "Menor",
    "IEC 1": "IEC 1",
    "IEC 2": "IEC 2",
    "IEC 3": "IEC 3",
    Uncategorized: "Sin categorizar",
    "LOSS RECOVERED": "PÉRDIDA RECUPERADA",
    "Loss Recovered": "Pérdida recuperada",
    Power: "Poder",
    Revenue: "Ingresos",
    All: "Todos",
    "Anomaly Name": "Nombre de anomalía",
    "Anomalies Name": "Nombre de anomalías",
    "Revenue recovered is calculated only for Sites with yield & tariff values provided and for Anomalies with loss factor.":
      "Los ingresos recuperados se calculan solo para Sitios con valores de rendimiento y tarifas proporcionados y para Anomalías con factor de pérdida.",
    "Vegetation growth causing hotspots":
      "El crecimiento de la vegetación causa puntos calientes",
    "Junction box highlighted module":
      "Módulo resaltado de la caja de conexiones",
    "Multi cell hotspot and degraded module":
      "Hotspot multicelda y módulo degradado",
    "Isolated / Underperforming string": "Cuerda aislada / de bajo rendimiento",
    "Short circuited module": "Módulo en cortocircuito",
    "Multi hotspot affected module":
      "Módulo afectado de múltiples puntos de acceso",
    "Isolated/Underperforming module": "Módulo aislado / de bajo rendimiento",
    "Soiling causing hotspots": "La suciedad provoca puntos calientes",
    "Wrongly inclined table": "Mesa mal inclinada",
    "Hotspot affected modules": "Módulos afectados por el hotspot",
    "Foreign object causing hotspot": "Objeto extraño que causa hotspot",
    "Severe Multicell Hotspot affected module":
      "Módulo afectado de Hotspot multicelular severo",
    "Hotspot affected and cell mismatch module":
      "Hotspot afectado y módulo de desajuste de celda",
    "Missing module": "Módulo faltante",
    "Bypass diode activated module": "Módulo activado por diodo de derivación",
    "Isolated / Underperforming table": "Tabla aislada / de bajo rendimiento",
    "Isolated / Underperforming module": "Módulo aislado / de bajo rendimiento",
    "Hotspot affected module": "Módulo afectado de hotspot",
    "Single hotspot affected module":
      "Módulo afectado de punto de acceso único",
    "Substring short circuited module": "Módulo en cortocircuito de subcadena",
    "Underperforming Module": "Módulo de bajo rendimiento",
    "LA / TP shadow causing hotspots":
      "La sombra de LA / TP causa puntos de acceso",
    "Band Hotspot": "Band Hotspot",
    "Cracked / Underperforming module":
      "Módulo agrietado / de bajo rendimiento",
    "Suspected PID module": "Módulo PID sospechoso",
    "Patch Hotspot": "Patch Hotspot",
    "Corner single cell hotspot affected module":
      "Módulo afectado de hotspot de celda única de esquina",
    "Underperforming string": "Cuerda de bajo rendimiento",
    "Wrongly inclined modules": "Módulos mal inclinados",
    "IEC Standards": "Normas IEC",
    "Anomaly Has": "Anomalía tiene",
    Comments: "Comentarios",
    "EL Image": "Imagen EL",
    "IV Curve Image": "Imagen de la curva IV",
    "Handheld Image": "Imagen de mano",
    "Temperature Difference (∆T)": "Diferencia de temperatura (∆T)",
    "Temperature Difference (∆T °C)": "Diferencia de temperatura (∆T ° C)",
    "temp. value will be used to filter defects. If STC not available, Site Condition temp. value will be used.":
      "temperatura El valor se utilizará para filtrar defectos. Si STC no está disponible, temperatura de la condición del sitio. se utilizará el valor.",
    Clear: "Clara",
    Apply: "Solicitar",
    "Power (KWp)": "potencia (KWp)",
    "Power Loss Recovered": "Pérdida de energía recuperada",
    "Anomalies Processed": "Anomalías procesadas",
    "Inspection Date": "Fecha de inspección",
    "Inspection Type": "Tipo de inspección",
    "INTERNAL BENCHMARK": "REFERENCIA INTERNA",
    "Internal Benchmark": "Benchmark interno",
    "Site Details": "Detalles del sitio",
    "Sites Tour": "Tour de sitios",
    "Inspection Date : Recent First": "Fecha de inspección: reciente primero",
    "Inspection Date : Oldest First":
      "Fecha de inspección: la más antigua primero",
    "COD : Recent First": "COD: Reciente primera",
    "COD : Oldest First": "COD: La más antigua primero",
    COD: "COD",
    "Add Image": "Añadir imagen",
    "Search by site name , location": "Buscar por nombre del sitio, ubicación",
    Inspections: "Inspecciones",
    "View Anomalies": "Ver anomalías",
    Calculate: "Calcular",
    "SITE LAYOUT": "PLANO DEL SITIO",
    "Commissioning Date": "Fecha de puesta en servicio",
    "Number of Panels": "Numero de Paneles",
    "Module Mounting Structure": "Estructura de montaje del módulo",
    "Panel Brands & Models": "Marcas y modelos de paneles",
    "Inverter Brands & Models": "Marcas y modelos de inversores",
    "Feed in tariff": "Alimentación en tarifa",
    "Panel Maximum Power (Pmax)": "Potencia máxima del panel (Pmax)",
    "Tilt Angle (Degree)": "Ángulo de inclinación (grados)",
    "Photovoltaic power potential (Yield)":
      "Potencial de energía fotovoltaica (rendimiento)",
    "Lat / Long": "Latitud longitud",
    "Sites/Inspections": "Sitios / Inspecciones",
    "Invalid date": "Fecha invalida",
    Edit: "Editar",
    "Ground mounted": "Montado en el suelo",
    Table: "Mesa",
    // Sites: "Sitios",
    Reports: "Informes",
    Activity: "Actividad",
    Users: "Usuarias",
    Configurations: "Configuraciones",

    "Log Out": "Cerrar sesión",

    Map: "Mapa",
    Video: "Video",
    Attachments: "Archivos adjuntos",
    Thermal: "Térmica",
    Handheld: "Mano",
    "Actual Module Temperature": "Temperatura real del módulo",
    "Hotspot Temperature": "Temperatura del punto de acceso",
    "Module Coordinate": "Coordinar módulo",
    "IEC Category": "Categoría IEC",
    "Site Condition": "Condición del sitio",
    Recommendations: "Recomendaciones",
    "Defective Panel": "Panel defectuoso",
    "Module Serial Number": "Número de serie del módulo",
    "Module Manufacturer Name": "Nombre del fabricante del módulo",
    "String ID": "ID de cadena",
    "Module Wp": "Módulo Wp",
    "Module Voc": "Módulo Voc",
    "Module Isc": " Módulo Isc",
    "String Current": "Corriente de cadena",
    "Module Vmp": " Módulo Vmp",
    "Module Imp": "Módulo Imp",
    "Healthy Panel": "Panel saludable",
    "Ref Module Wp": "Ref módulo Wp",
    "Ref Module Voc": "Ref módulo Voc",
    "Ref Module Isc": "Ref módulo Isc",
    "Ref Module Vmp": "Ref módulo Vmp",
    "Ref Module Imp": "Ref módulo Imp",
    "Edit Panel Details": "Editar detalles del panel",
    "Save Changes": "Guardar cambios",
    Discard: "Descarte",
    "DOWNLOAD REPORTS (PDF)": "DESCARGAR INFORMES (PDF)",
    "Report Tour": "Informe Tour",

    "Executive Report": "Reporte ejecutivo",
    "Includes high level data of site inspection":
      "Incluye datos de alto nivel de inspección del sitio.",
    "Warranty Claim Report": "Informe de reclamación de garantía",
    "Download anomalies with serial no. & manufacturer details":
      "Descarga anomalías con número de serie. & detalles del fabricante",
    "Thermal Report": "Informe Térmico",
    "Detailed thermal report for all the anomalies":
      "Informe térmico detallado de todas las anomalías",
    "Anomalies CSV": "Anomalías CSV",
    "Comma separated list of all anomalies with module co-ordinates":
      "Lista separada por comas de todas las anomalías con coordenadas de módulo",
    "Anomalies KML": "Anomalías KML",
    "Download kml data for all anomalies":
      "Descargar datos kml para todas las anomalías",
    Portrait: "Retrato",
    Landscape: "Paisaje",
    "PDF Orientation": "Orientación PDF",
    Language: "Idioma",
    English: "Inglesa",
    Japanese: "Japonesa",
    Spanish: "Española",
    Cancel: "Cancelar",
    "Generate & Download": "Generar y descargar",
    Sections: "Secciones",
    "REPORT NAME": "Reportar nombre",
    "Created by": "Creado por",
    "Date Created": "fecha de creacion",
    "REPORTS HISTORY": "HISTORIA DE INFORMES",
    "Search By Report Name": "Buscar por nombre de informe",
    "Reports Tour": "Tour de informes",
    "Generate Custom Report": "Generar informe personalizado",
    Rename: "Rebautizar",
    Delete: "Borrar",
    name: "nombre",
    "load next 10": "cargar 10 siguientes",
    "Export As .PDF": "Exportar como .PDF",
    "Thermal Analysis Document": "Documento de análisis térmico",
    "View Report": "Vista del informe",
    METRICS: "MÉTRICA",
    "Anomaly Details": "Detalles de la anomalía",
    Irradiation: "Irradiación",
    Back: "atrás",
    "Add a new comment": "Agregar un nuevo comentario",
    "(Data storage upto 10GB/100MW)":
      "(Almacenamiento de datos hasta 10GB / 100MW)",

    "Post Comment": "publicar comentario",
    "Upload an Image or File": "Cargar una imagen o archivo",
    "New User": "Nueva usuaria",
    Id: "Identificación",
    Name: "Nombre",
    Email: "Correo electrónico",
    "Status ": "Estado",
    Role: "Papel",
    "Site Access": "El acceso al sitio",
    View: "Vista",
    "Last Login": "Último acceso",
    "Job Title": "Título profesional",
    "User Details": "Detalles de usuario",
    "User details": "Detalles de usuario",
    "Edit User": "editar usuario",
    "Delete User": "Borrar usuario",
    "Update User": "Actualizar usuario",
    "First Name": "Primer nombre",
    "First name": "Primer nombre",
    "Last Name": "Apellido",
    Username: "Nombre de usuario",
    Submit: "Entregar",
    Active: "Activa",
    Inactive: "Inactiva",
    "All Sites": "Todos los sitios",
    "Allowed Sites": "Sitios permitidos",
    "Search by name, email": "Buscar por nombre, correo electrónico",
    "Rename Anomalies": "Cambiar el nombre de las anomalías",
    "Change Severity Of Anomalies": "Cambiar la gravedad de las anomalías",
    "Set Currency": "Establecer moneda",
    "Default Name": "Nombre predeterminado",
    "Default Severity": "Gravedad predeterminada",
    "Updated Severity": "Severidad actualizada",
    "This will overwrite defect names in the application and downloaded reports will have renamed defect names.":
      "Esto sobrescribirá los nombres de los defectos en la aplicación y los informes descargados tendrán nombres de defectos renombrados.",
    "Changing Anomalies severity will update stats in the application with updated Anomalies severity.":
      "Cambiar la gravedad de las anomalías actualizará las estadísticas de la aplicación con la gravedad de las anomalías actualizada.",
    Currency: "la moneda",
    "My Profile": "Mi perfil",
    "Personal Information": "informacion personal",
    PASSWORD: "CONTRASEÑA",
    "Edit Profile": "Editar perfil",
    "Change Password": "Cambia la contraseña",
    "Current Password": "contraseña actual",
    "New Password": "Nueva contraseña",
    "Confirm Password": "Confirmar contraseña",
    "Update Password": "Actualiza contraseña",
    Save: "Ahorrar",
    "Log In": "acceso",
    Password: "Contraseña",
    "Forgot Password": "Has olvidado tu contraseña",
    "Search by name, email...": "Buscar por nombre, correo electrónico ...",
    "The terminals of the modules are open circuited. Accordingly, check the dis- connectivity point along the cables.Some of the modules are operating at higher temperature than the normal modules. The reason could be cracks, external fallen bodies, non-uniform soiling or internal cell mismatch. Perform visual inspection of this modules. If no signs of external factors, IV tracing of the module is recommended to understand the electrical impact of fault if any.":
      "Los terminales de los módulos están en circuito abierto. En consecuencia, verifique el punto de desconexión a lo largo de los cables. Algunos de los módulos están operando a una temperatura más alta que los módulos normales. La razón podría ser grietas, cuerpos externos caídos, suciedad no uniforme o desajuste interno de las células. Realice una inspección visual de estos módulos. Si no hay signos de factores externos, se recomienda el rastreo IV del módulo para comprender el impacto eléctrico de la falla, si lo hubiera.",
    "Powered by Airprobe": "Desarrollado por Airprobe",
    Tata: "Tata",
    "Last 7 days": "Los últimos 7 días",
    "Last 30 days": "Últimos 30 días",
    "Last 90 days": "Últimos 90 días",
    "Last year": "El año pasado",

    "Anomalies kml": "Anomalías kml",
    "Anomalies csv": "Anomalías csv",
    Selected: "Seleccionada",
    "Damaged Module": "Módulo dañado",
    "Inspection Date: Recent First": "Fecha de inspección: reciente primero",
    "Inspection Date: Oldest First":
      "Fecha de inspección: la más antigua primero",
    "COD: Recent First": "COD: Reciente primera",
    "COD: Oldest First": "COD: La más antigua primero",
    "DC Capacity (MWp)": "Capacidad DC (MWp)",
    "AC Capacity (MW):": "Capacidad de CA (MW):",
    "Commissioning Date:": "Fecha de puesta en servicio:",
    "Lat / Long:": "Latitud longitud:",
    "Module Mounting Structure:": "Estructura de montaje del módulo:",
    "Number of PanelsDC Capacity (MWp)":
      "Número de paneles Capacidad de CC (MWp)",
    "kWh/kWp per year": "kWh / kWp por año",
    Update: "Actualizar",
    Summer: "Verano",
    Winter: "Invierno",
    "Roof Top": "Techo",
    "Single axis tracker": "Seguidor de un eje",
    "Dome Shaped": "Cúpula",
    Seasonal: "Estacional",
    SUMMARY: "RESUMEN",
    "8 Mar, 2021": "8 de marzo de 2021",
    "Apply Filters": "Aplicar filtros",
    "Selected filters will show up here":
      "Los filtros seleccionados se mostrarán aquí",
    "Filters Selected": "Filtros seleccionados",
    "Temp. Difference (∆T) – STC": "Temperatura. Diferencia (∆T) - STC",
    "Temp. Difference (∆T) – Site Condition":
      "Temperatura. Diferencia (∆T) - Condición del sitio",
    "IEC Category:": "Categoría IEC:",
    "Module Coordinate:": "Coordinación del módulo:",
    Anomaly: "Anomalía",
    "Panel Details": "Detalles del panel",
    Comment: "Comentario",
    "Search anomaly number...": "Buscar número de anomalía ...",
    "File/Image UPLOAD": "CARGA de archivo / imagen",
    "Upload Images": "Subir imágenes",
    "Upload Files": "Subir archivos",
    Others: "Otras",
    "Upload & Continue": "Subir y continuar",
    "MARK AS FALSE +VE": "Marcar como falsa + VE",
    "Marking anomaly False +ve will be verified by AirProbe thermal experts to help validate the fault. Upload a supporting document or image for verification.":
      "Los expertos térmicos de AirProbe verificarán la anomalía de marcado False + ve para ayudar a validar la falla. Cargue un documento de respaldo o una imagen para su verificación.",
    "FALSE + VE": "FALSO + VE",
    "File:": "Archivo:",
    "Cancel File": "Cancelar archivo",
    "No File added": "No se agregó ningún archivo",
    "Write your comment...": "Escribe tu comentario ...",
    "Select file": "Seleccione Archivo",
    "Anomaly No.": "Anomalía No.",
    Coordinates: "Coordenadas",
    "T2 (Hotspot)": "T2 (punto de acceso)",
    "T1 (Actual)": "T1 (real)",
    showing: "demostración",
    of: "de",
    "items.": "elementos.",
    "Search Anomaly number or name": "Buscar número o nombre de anomalía",
    "CSV Report": "Informe CSV",
    "KML Report": "Informe KML",
    "Download Report": "Descargar informe",
    "Re-center": "Volver a centrar",
    "Power Loss recovered": "Pérdida de energía recuperada",
    "Revenue Loss recovered": "Pérdida de ingresos recuperada",
    // Language: "Idioma",
    // "REPORTS HISTORY": "HISTORIA DE INFORMES",
    "Executive Report,": "Reporte ejecutivo,",
    "∆T - Site Condition": "∆T - Condición del sitio",
    "Anomaly Number": "Número de anomalía",
    "IV Curve Data": "Datos de la curva IV",
    Section: "Sección",
    "Custom Reports": "Reportes personalizados",
    "Search by site name, location": "Buscar por nombre de sitio, ubicación",
    "Inspection Date:": "Fecha de inspección:",
    Analysis: "Análisis",
    Flight: "Vuelo",
    Camera: "Cámara",
    Images: "Imágenes",
    "Enter Yield and Tariff": "Ingrese la rendimiento y tarifa",
    "No Anomalies resolved for the selected filters":
      "No hay anomalías resueltas para los filtros seleccionados",
    "No data available for selected filters. Try removing some.":
      "No hay datos disponibles para los filtros seleccionados. Intente quitar algunos.",
    "See how your team recover revenue losses over time ":
      "Mira cómo tu equipo recupera las pérdidas de ingresos en el tiempo ",
    "Anomalies v/s Sections": "Anomalías v/s Secciones",
    "RGB View": "Vista RGB",
    "Thermal View": "Vista térmica",
    Actual: "Actual",
    Hotspot: "Punto de calor",
    "load previous": "cargar anterior",
    "load next": "cargar siguiente",

    "Atleast access one stie should be given to user":
      "Al menos se le debe dar acceso a un stie al usuario",
    "Are you sure want to delete user ?":
      "¿Estás seguro de que deseas eliminar al usuario?",
    "Renaming defects": "Renombrar defectos",
    "Changing Anomalies Severity": "Cambio de la gravedad de las anomalías",
    commented: "comentó",
    on: "sobre",
    a: "una",
    defect: "defecto",
    marked: "marcada",
    as: "como",
    pending: "pendiente",
    inprogress: "en curso",
    falsePositive: "falsopositivo",
    resolved: "resuelta",
    notfound: "extraviada",
    "(Data storage upto 1GB/100MW)":
      "(Almacenamiento de datos hasta 1GB / 100MW)",
    "IV Datasheet": "IV Hoja de datos",
    "Upload a File": "Cargar un archivo",

    "Data storage upto": "Almacenamiento de datos hasta",
    "In this case one or more substrings inside the module (group 24 cells) are shorted. Shorting of bus bars, bypass diode shorting can result in this condition. It is recommended to perform the visual inspection of junction box for any deformation which might have resulted in the short-circuit, else IV tracing can provide details of electrical impact caused if any due to this defect.":
      "En este caso, una o más subcadenas dentro del módulo (grupo de 24 celdas) están en corto circuito. Esto puede ser consecuencia de corto circuito en las barras o en el diodo de derivación. Se recomienda verificar, mediante una inspección visual, que la caja de conexiones no esté deformada ya que eso podría haber causado el corto circuito; si así fuera, el trazado de la curva corriente-voltaje (IV) puede arrojar detalles del impacto eléctrico ocasionado, si lo hubiere, por este defecto. Los reclamos de garantía se pueden iniciar según los términos y requisitos de datos del fabricante del módulo.",
    "Single cell hotspots are caused due to cell mismatch. The root cause could be external (glass crack, non-uniform soiling, object shadows) or internal cell mismatch (manufacturing defects). The site recorded to have soiled modules causing multi cell hotspots over modules. Perform visual inspection of the modules. If no external factor observed, IV tracing of the modules can reflect the root cause of hotspot.":
      "Los puntos críticos se originan como consecuencia del desajuste de celdas en los módulos. La causa principal puede ser externa (vidrios rotos, acumulación de suciedad, sombras de objetos) o el desajuste interno de las celdas (defectos de fabricación). Verifique los módulos mediante una inspección visual. Si no presentan indicios de haber sido afectados por factores externos, mediante el trazado de la curva corriente-voltaje (IV) de los módulos se puede mostrar la causa que origina el punto crítico.",
    "Multi cell hotspots arise due to cell mismatch in the modules. The root cause could be external (glass crack, non-uniform soiling, object shadows) or internal cell mismatch (manufacturing defects). The site recorded to have soiled modules causing multi cell hotspots over modules. Perform visual inspection of the modules. If no external factor observed, IV tracing of the modules can reflect the root cause of hotspot":
      "Los puntos críticos se originan como consecuencia del desajuste de celdas en los módulos. La causa principal puede ser externa (vidrios rotos, acumulación de suciedad, sombras de objetos) o el desajuste interno de las celdas (defectos de fabricación). Verifique los módulos mediante una inspección visual. Si no presentan indicios de haber sido afectados por factores externos, mediante el trazado de la curva corriente-voltaje (IV) de los módulos se puede mostrar la causa que origina el punto crítico.",
    "Check for any visible fallen object or heavy soiling that could result in bypass diode activation. If no visible signs of fault cause, cell mismatch, solder interconnect issues could have caused the substring isolation. It is recommended to perform IV tracing of the modules indicating the operating point at which the diode bypasses the substring. 1/3rd of module power is lost when one bypass diode is activated. Note that the Voc might not necessarily be 1/3rd lower than rated.":
      "Compruebe si se observa algún objeto caído o suciedad excesiva que pueda haber ocasionado la activación del diodo de derivación. Si no se encuentran signos visibles, un bajo rendimiento nominal del diodo, un desajuste de celdas y problemas en las interconexiones soldadas podrían haber ocasionado el aislamento de la subcadena. Se recomienda realizar el trazado de la curva corriente-voltaje (IV) de los móduos e indicar el punto de funcionamiento en el que el diodo hace que la corriente fluya hacia la subcadena. Cuando se activa un diodo de derivación, se pierde un tercio (1/3) de la potencia del módulo. No se recomienda realizar la verificación del Voc ya que no necesariamente podría ser un tercio (1/3) más bajo que el valor nominal. Esta anomalía solo puede validarse utilizando la prueba de corriente-voltaje (IV) o una cámara o toma térmica de detección de temperatura. Los reclamos de garantía se pueden iniciar según los términos y requisitos de datos del fabricante del módulo.",
    "Individual module’s electrical terminals are short circuited. No energy generation is contributed to system. Disconnect the shorting connection and module could be connected back to string. Long exposure of short-circuited modules to higher irradiations could lead to hotspots and accelerate the module degradation. Further missing of this module in energy generation impacts the system voltage. If the module terminals are connected check for junction box shape deforming. It indicates shorted bypass diodes or solder interconnect shorting issue.":
      "Las terminales eléctricas de determinados módulos se encuentran en cortocircuito interno o externo al módulo. No se aporta generación de energía al sistema. Si los módulos forman parte de una misma cadena, identifique y desconecte la conexión del cortocircuito y así se podrán volver a conectar los módulos a la cadena. Si se observa que un módulo está en cortocircuito, todos los diodos de derivación de ese módulo estarán dañados. La exposición prolongada de módulos en cortocircuito a irradiaciones más altas podría generar puntos críticos y acelerar la degradación del módulo. Si el módulo continúa en cortocircuito, tiene un impacto sobre el voltaje de la cadena y hace que el rendimiento de los otros módulos sea inferior al esperado. La deformación de la caja de empalmes, si la hubiera, indica la presencia de un problema de cortocircuito en los diodos de derivación o en las interconexiones soldadas. En caso de que haya una cadena en cortocircuito, los módulos podrán ponerse nuevamente en funcionamiento una vez que se resuelva el punto de cortocircuito. En caso de que haya un módulo en cortocircuito, los reclamos de garantía se podrán iniciar según los términos y requisitos de datos del fabricante del módulo.",
    "In case of vegetation growth, the modules are affected by shadows. It leads to single/multi cell hotspots over it and bypass diode activation in some cases. Vegetation cutting activities in these areas is recommended to avoid hotspots over modules.":
      "En el caso de crecimiento de vegetación, los módulos se ven afectados por sombras. Eso ocasiona puntos críticos en una única celda o en múltiples celdas, o la activación del diodo de derivación del módulo o una disminución en el rendimiento de toda la cadena en base al alcance de la sombra. Se recomienda cortar la vegetación en esas áreas a fin de prevenir la formación de puntos críticos sobre los módulos.",
    "The junction boxes for these modules operate at higher temperature compared to normal operating ones. The root cause can be heat generation inside junction boxes due to irregular soldering or heat dissipation by under rated bypass diode. It is recommended to perform a visual inspection of the junction box for any deformation, else IV tracing can provide details of electrical impact caused if any due to this defect.":
      "Las cajas de conexiones de estos módulos funcionan a temperaturas más altas que las temperaturas normales de funcionamiento. Esto puede deberse a la generación de calor dentro de las cajas de conexiones como consecuencia de soldaduras irregulares o disipación del calor por diodo de derivación con bajo rendimiento. Se recomienda verificar, mediante una inspección visual, que la caja de empalme no esté deformada; si así fuera, el trazado de la curva intensidad-tensión (IV) puede arrojar detalles del impacto eléctrico ocasionado, si lo hubiere, por este defecto.",
    "Modules are physically missing from the MMS. Uneven configuration of module numbers in string can cause variations in voltages and impact the plant generation.":
      "Faltan módulos en la estructura de montaje de módulos solares (MMS: Module Mounting Structure) La configuración despareja del número de módulos en la cadena puede causar variaciones en los voltajes y tener un impacto sobre la generación de la central.",
    "These modules are affected by shadows of lightning arrester and transmission lines. This leads to single/multi cell hotspots over it and bypass diode activation in some cases.":
      "Estos módulos se ven afectados por las sombras de pararrayos o del tendido eléctrico. Así, se originan puntos críticos de una o múltiples celdas sobre el módulo y, en algunos casos, la activación del diodo de derivación. Se recomienda monitorear de cerca el rendimiento de estos módulos una vez por año.",
    "Individual PV module is not electrically connected to the system or is underperforming (lower generation / degraded). The reason could be physical connections not connected to system or all the bypass diodes activated in the PV module. Perform visual inspection for any heavy soiling over module or any other external factors. Check for open module connections. If the module is electrically connected and string current is non-zero, all the bypass diodes of the module are active causing complete PV module to isolate from the system. Confirm the same by IV tracing.":
      "Un módulo fotovoltaico individual no se encuentra conectado eléctricamente al sistema o su rendimiento es deficiente (generación más baja / disminuida). Eso puede deberse a que las conexiones físicas no se encuentran conectadas al sistema o todos los diodos de derivación están activados en el módulo fotovoltaico. Realice una inspección visual en búsqueda de suciedad excesiva sobre el módulo o cualquier otro factor externo. Verifique las conexiones del módulo abierto. Si el módulo está conectado eléctricamente y la corriente de la cadena es distinta a cero, todos los diodos de derivación del módulo están activos y hacen que el módulo fotovoltaico completo se aisle del sistema. Confírmelo mediante el trazado de la curva corriente-voltaje (IV). Si los módulos pertenecen a la misma cadena, la cadena está en circuito abierto. Verifique la presencia de un punto de circuito abierto, si lo hubiere, como consecuencia de haberse quemado o por desconexión de un cable. Se recomienda revisar los fusibles en los conectores Y y en la caja combinada de cadenas (SCB: String Combined Box).",
    "Few modules were observed to have single/multi cell hotspots due to foreign object fallen over it. It is recommended to clear the objects as the cell temperature can rise above 100°C absolute degrading the cell performance and back sheet quality.":
      "Se observó que algunos módulos tienen puntos críticos en una o en múltiples celdas debido a la caída de un objeto extraño sobre ellos. Se recomienda quitar los objetos ya que la temperatura de la celda puede elevarse, superar los 100°C y disminuir por completo el rendimiento de la celda y la calidad del registro de calidad.",
    "Few modules were observed to have hotspots due to bird drop over it and this can be visbily seen in the corresponding RGB image provided. It is recommended to clean the modules to avoid hotspots.":
      "Se observó que algunos módulos tienen puntos críticos como consecuencia de caída de aves sobre ellos y esto se visualiza en la correspondiente imagen RGB proporcionada. Se recomienda quitar los restos que se encuentren sobre los módulos para prevenir la formación de puntos críticos.",
    "Shadows from adjacent rows are causing non uniform irradiation over module. Depending on the shadow area, the modules can produce hotspots and also influence the string current for its series. It is recommended to perform annual shading analysis of the PV asset to quantify the shadow losses and take corrective measures.":
      "Sombras provenientes de filas adyacentes causan irradiación no uniforme sobre el módulo. Según la sombra, los módulos pueden presentar puntos críticos y también influir sobre la corriente de la cadena para su serie. Se recomienda realizar un análisis de sombreado anual del recurso fotovoltaico para medir las pérdidas por sombra y tomar las medidas correctivas necesarias.",
    "Shadows from adjacent tables are causing non uniform irradiation over module. Depending on the shadow area, the modules can produce hotspots and also influence the string current for its series. It is recommended to perform annual shading analysis of the PV asset to quantify the shadow losses and take corrective measures.":
      "Sombras provenientes de mesas adyacentes causan irradiación no uniforme sobre el módulo. Según la sombra, los módulos pueden desarrollar puntos críticos y también influir sobre la corriente para su serie. Se recomienda realizar un análisis de sombreado anual del recurso fotovoltaico para medir las pérdidas por sombra y tomar las medidas correctivas necesarias.",
    "The front glass of the module is cracked. Multi cell hotspots occur over PV module. Cracked modules have reduced insulation resistance and can lead to frequent ground faults in humid, rainy conditions or during cleaning process. Identifying the ground fault cause and location involves switching off SMB’s leading to generation losses. Hence replacement of this modules with healthier ones is highly recommended to reduce the frequency and causes of ground fault occurrence.":
      "El vidrio frontal del módulo se encuentra rayado. Se producen puntos críticos en múltiples celdas sobre el módulo fotovoltaico. Los módulos rayados redujeron su resistencia al aislamiento y pueden tener como consecuencia fallas a tierra frecuentes en condiciones meteorológicas de humedad y lluvia o durante el proceso de limpieza. Para identificar la causa y la ubicación de la falla a tierra es necesario desconectar el conector SMB que ocasionó las pérdidas en la generación. Así, es muy recomendable reemplazar estos módulos con otros que estén funcionando correctamente a fin de reducir la frecuencia y las causas de las fallas a tierra.",
    "Wrongly Inclined modules are not in the right position and will be underperforming as they won't receive the same amount of irradiation as other tables. These misalignments can be due to tracker issues. Aligning the table to right position will improve the performance of the table.":
      "Los módulos con mala inclinación no se encuentran en la posición que se espera y su rendimiento será deficiente debido a que la irradiación que reciben no es óptima en comparación con la de otros módulos bien montados. Estos desalineamientos pueden deberse a problemas con el desmontaje mecánico del módulo de la estructura o el rastreador. Alinear la mesa o el módulo y colocarlo en la posición adecuada mejorará el rendimiento de la mesa.",
    "Subtle temperature difference between normal cell and affected cell appear in the module specifically on the border area of the module. The thermal signature indicates possibility of Potential Induced Degradaion (PID). These modules are positioned in the extremes of the electrical string. Observe for any external factors such as soiling or foreign particles over the module surface. Further check for any visible patterns of degradations over cells such as crack, corrosion or browning. If no visual signatures recorded, the module has internal cell mismatch. The degradation can be quantified using IV tracing. Reduction in Voc value as compared to a healthy reference module indicates PID. Warranty claims can be initiated depending on terms and data requirement of the module manufacturer":
      "Se observa una sutil diferencia de temperatura entre una celda normal y la celda afectada en el módulo, específicamente en el borde del módulo. Las señales térmicas indican la posible presencia de una degradación potencial inducida (PID: Potential Induced Degradation). Estos módulos se encuentran ubicados en los extremos de la cadena eléctrica. Compruebe que no haya factores externos tales como suciedad o partículas extrañas sobre la superficie del módulo. Continúe verificando que no haya muestras visibles de degradación sobre la celda, como rayaduras, corrosión u oxidación. Si no se registran signos visuales, el módulo tiene un desajuste interno de las celdas. La degradación puede medirse utilizando el trazado de la curva corriente-voltaje (IV). Una disminución en el valor del voltaje de circuito abierto (Voc), si se compara el módulo con un módulo de referencia en buenas condiciones, es un indicador de degradación potencial inducida (PID). Los reclamos de garantía se pueden iniciar según los términos y requisitos de datos del fabricante del módulo.",
    "Subtle temperature difference between a normal cell and affected cell are recorded in some of the modules. Observe for any external factors such as soiling or foreign particles over the module surface. If not check for any visible patterns of degradations over cells such as crack, corrosion or browning. If no visual signatures recorded, the module has internal cell mismatch. The degradation can be quantified using IV tracing. Note that Voc and Isc testing only cannot conclude its underperformance. Warranty claims can be initiated depending on terms and data requirement of the module manufacturer":
      "Se registra una sutil diferencia de temperatura entre una celda normal y la celda afectada en algunos de los módulos. Compruebe que no haya factores externos tales como suciedad o partículas extrañas sobre la superficie del módulo. Si no los hubiere, verifique que no haya muestras visibles de degradación sobre las celdas, tales como rajaduras, corrosión u oxidación. Si no se registran signos visuales, el módulo tiene un desajuste interno de las celdas. La degradación puede medirse utilizando el trazado de la curva corriente-voltaje (IV). Tenga en cuenta que las pruebas de voltaje a circuito abierto (Voc) e intensidad en corto circuito (Isc) no son suficientes para definir los motivos del rendimiento deficiente. Los reclamos de garantía se pueden iniciar según los términos y requisitos de datos del fabricante del módulo.",
    "Visible soiling patterns recorded over PV modules. It results in hotspots over modules and underperformance of strings. Periodic cleaning of PV modules is recommended to avoid long time exposure and degradation of hotspot affected cells":
      "Se registran muestras visibles de suciedad sobre los módulos fotovoltaicos. Esto ocasiona puntos críticos sobre los módulos y el rendimiento deficiente de las cadenas. Se recomienda la limpieza periódica de los módulos fotovoltaicos a fin de prevenir largos períodos de exposición y la degradación de las celdas afectadas por puntos críticos.",
    "Warranty Claim Document": "Documento de Reclamo de Garantía",

    "Anomaly categorisation": "categorización",
    "Based on severity (Impact on power loss):":
      "Según la gravedad (impacto en la pérdida de energía):",
    "Critical - High impact, priority 1": "Crítica - Alto impacto, prioridad 1",
    "Moderate- Moderate impact, priority 2":
      "Moderado- Impacto moderado, prioridad 2",
    "Minor- Minor impact, priority 3": "Impacto menor-menor, prioridad 3",
    "Based on IEC 62446-3 (Safety hazards):":
      "Basado en IEC 62446-3 (Riesgos de seguridad):",
    "Uncategorised - Unclassified anomaly":
      "Sin categoría: anomalía sin clasificar",
    "IEC 1 - Healthy module, no abnormalities":
      "IEC 1 - Módulo sano, sin anomalías",
    "IEC 2 - Thermal abnormality, medium risk":
      "IEC 2: anomalía térmica, riesgo medio",
    "IEC 3 - Safety relevant thermal abnormality, high risk":
      "IEC 3: anomalías térmicas relevantes para la seguridad, alto riesgo",

    "Enter Yield and Feed in tariff to calculate energy and revenue loss":
      "Ingrese el rendimiento y la alimentación en la tarifa para calcular la energía y la pérdida de ingresos",

    "Anomalies processed includes false +ve, resolved  and not Found":
      "Las anomalías procesadas incluyen falso + ve, resuelto y no encontrado",

    "Categorised based on IEC 62446-3": "Categorizado según IEC 62446-3",
    "IEC Uncategorized - Unclassified abnormality under IEC 62446-3":
      "IEC sin categorizar: anomalía no clasificada según IEC 62446-3",

    Comparison: "Comparación",
    "Compare Inspections": "Comparar inspecciones",
    "Initial Power Loss": "Pérdida de energía inicial",
    "Estimated Revenue Loss": "Pérdida de ingresos estimada",
    "No. of Modules": "No. de módulos",
    "Anomalies by Severity": "Anomalías por gravedad",
    "Section Health": "Sección Salud",
    "Section health is based on critical anomalies resolved before next inpsection":
      "La salud de la sección se basa en anomalías críticas resueltas antes de la próxima inpsección.",
    Block1: "Bloque1",
    Block2: "Bloque2",
    Block3: "Bloque3",
    Block4: "Bloque4",
    "Anomalies Resolved": "Anomalías resueltas",
    Bad: "Mala",
    Poor: "Pobre",
    Good: "Buena",
    Inspection: "Inspección",
    "BULK UPLOAD": "CARGA EN MASA",
    "Upload Data CSV": "Subir datos CSV",
    "Anomalies data import": "Importación de datos de anomalías",
    "Upload Images, Files and Folders": "Subir imágenes, archivos y carpetas",
    "Upload Images or Files": "Subir imágenes o archivos",
    "Upload Folder": "Subir carpeta",
    "How it works?": "¿Cómo funciona?",
    "Download CSV report from the Anomalies/Reports page or":
      "Descargar informe CSV de la página Anomalías/Informes o",
    "download a CSV template.": "descargar una plantilla CSV.",
    "Update anomaly details and add file names in the CSV file.":
      "Actualizar detalles de anomalía y agregar nombres de archivo en el archivo CSV.",
    "Upload CSV file with updated details.":
      "Subir archivo CSV con detalles actualizados.",
    "Upload images and files associated.":
      "Subir imágenes y archivos asociados.",
    "Review anomlay details, images names and file names for each anomaly.":
      "Revisar detalles de anomalía, nombres de imágenes y nombres de archivo para cada anomalía.",
    "Things to remember": "Cosas que recordar",
    "False-positive anomalies cannot be updated using Bulk Upload, mark it individually in the Anomalies page along with proof.":
      "Anomalías falsos positivos no pueden ser actualizadas usando carga en masa, marcarla individualmente en la página Anomalías junto con la prueba.",
    "Anomaly details with the same fault number will be overwritten.":
      "Detalles de anomalía con el mismo número de falla se sobrescribirán.",
    "Only columns matching with anomaly details in system can be updated, additional columns will be ignored.":
      "Solo columnas coincidentes con detalles de anomalía en el sistema pueden ser actualizadas, columnas adicionales serán ignoradas.",
    "Watch a Video": "Ver un vídeo",
    "to know more": "para saber más",
    "Continue & Review Details": "Continuar y revisar detalles",

    "Power & Revenue Loss": "Pérdida de energía e ingresos",
    "Download .Csv": "Descarga .Csv",
    Estimated: "Estimada",
    "Energy Loss": "Pérdida de energía",
    Yield: "Producir",
    "Energy Recovered": "Energía recuperada",
    "Feed in Tariff/kWh": "Alimentación en tarifa / kWh",
    "Revenue Loss": "Pérdida de ingresos",
    "Revenue Recovered": "Ingresos recuperados",
    Initial: "Inicial",
    Current: "Actual",
    "per year": "por año",
    "The total power of your DC capacity which is not contributing towards energy generation on the day of inspection.":
      "La potencia total de su capacidad de CC que no contribuye a la generación de energía el día de la inspección.",
    "Current power loss from your site due to all unresolved Anomalies. Resolve Anomalies to see a reduction in your Power Loss.":
      "Pérdida de energía actual en su sitio debido a todas las anomalías no resueltas. Resuelva las anomalías para ver una reducción en su pérdida de energía.",
    "Yield is the ratio between total annual energy produced and the solar installed capacity.<br/> Yield (kWh/kWp per year) = annual plant output (kWh) / installed capacity (kWp)":
      "El rendimiento es la relación entre la energía total anual producida y la capacidad solar instalada. <br/> Rendimiento (kWh / kWp por año) = producción anual de la planta (kWh) / capacidad instalada (kWp)",
    "Total energy that could have been produced in the selected period if there were no defects on modules.":
      "Energía total que podría haberse producido en el período seleccionado si no hubiera defectos en los módulos.",
    "Energy recovered after resolving anomalies. Resolve anomalies to increase energy generation from your site.":
      "Energía recuperada tras resolver anomalías. Resuelva anomalías para aumentar la generación de energía de su sitio.",
    "Enter the selling price of your energy to calculate total revenue losses due to defects at your site.":
      "Ingrese el precio de venta de su energía para calcular las pérdidas totales de ingresos debido a defectos en su sitio.",
    "Total revenue loss that will incur from your individual solar site for the chosen period if no Anomalies are resolved.":
      "Pérdida total de ingresos que incurrirá en su sitio solar individual durante el período elegido si no se resuelven las anomalías.",
    "Revenue that is generated from your site after Anomaly resolution. Resolve Anomalies to increase revenue generation.":
      "Ingresos que se generan en su sitio después de la resolución de la anomalía. Resolver anomalías para aumentar la generación de ingresos.",
    "Affected DC": "DC afectado",
    "Affected DC %": "DC afectado%",
    "The total number of identified defects contributing towards energy and revenue losses.":
      "El número total de defectos identificados que contribuyen a las pérdidas de energía e ingresos.",
    "Affected DC (Power loss) is the total power that is not contributing towards energy generation due to the associated anomaly.":
      "La CC afectada (pérdida de potencia) es la potencia total que no contribuye a la generación de energía debido a la anomalía asociada.",
    "Percentage of total DC capacity that is not contributing towards energy generation due to the associated anomaly.":
      "Porcentaje de la capacidad total de CC que no contribuye a la generación de energía debido a la anomalía asociada.",
    "Change Language": "Cambiar idioma",
    "String Id": "ID de cadena",
    "Bird dropping causing hotspot": "Caída de pájaros que causa hotspot",
    "Table containing soiled modules": "Mesa que contiene módulos sucios",
    "load previous 10": "Cargar las 10 anteriores",
    "Report Download": "Descargar informe",
    "Site Name": "Nombre del sitio",
    "Layout View": "Vista de diseño",
    "Cracked glass modules": "Módulos de vidrio agrietado",
    "Inspection Report Guide": "Guía de informes de inspección",
    "Clear Filters": "Limpiar filtros",
    "IV Image": "IV Imagen",
    Downloading: "Descargando",
    Admin: "Administradora",
    Upgrade: "Ascender de categoría",
    "Unlock more opportunities with AirProbe PRO plan":
      "Desbloquee más oportunidades con el plan AirProbe PRO",
    Basic: "Básica",
    Pro: "Pro",
    Starter: "Arrancadora",
    "Contact Sales to Upgrade": "Comuníquese con Ventas para actualizar",
    "Access to webapp": "Acceso a la aplicación web",
    "Download basic reports": "Descargar informes básicos",
    "Navigation to defect": "Navegación al defecto",
    "Anomaly status": "Estado de anomalía",
    "Site data upload and comments": "Carga de datos del sitio y comentarios",
    "Thermography Expertise": "Experiencia en termografía",
    "Wide range of filters": "Amplia gama de filtros",
    "Intercom support and guided tours":
      "Soporte de intercomunicador y visitas guiadas",
    "Web app support": "Soporte de aplicaciones web",
    "Download custom reports": "Descarga informes personalizados",
    "Graph downloads": "Descargas de gráficos",
    "Warranty claim reports": "Informes de reclamaciones de garantía",
    "Bulk upload - faster": "Carga masiva - más rápida",
    "Tabular view for large data sets":
      "Vista tabular para grandes conjuntos de datos",
    "Internal and external benchmarking": "Internal and external benchmarking",
    "Unlimited users and external sharing":
      "Usuarios ilimitados y uso compartido externo",
    "Extended data storage( 10GB / 100MW )":
      "Almacenamiento de datos extendido (10GB / 100MW)",
    Plans: "Planes",
    "Upgrade To": "Actualizar a",
    "Compare your sites performance using premium filters IEC standards, Brands & Models, Panel Type, Inspection Type and Anomaly Status.":
      "Compare el rendimiento de sus sitios utilizando filtros premium estándares IEC, marcas y modelos, tipo de panel, tipo de inspección y estado de anomalía.",
    "Upgrade Now": "Actualizar ahora",
    "A table view of sites anomalies details, listing anomalies of sites across plant levels":
      "Una vista de tabla de los detalles de las anomalías de los sitios, que enumera las anomalías de los sitios en todos los niveles de la planta",
    "Pro Trial Expired": "Prueba Pro caducada",
    "Pro Trial": "Prueba profesional",

    "Table Containing Soiled Modules": "Mesa que contiene módulos sucios",

    downloading: "Descargando",
    "Request Sent!": "¡Solicitud enviada!",
    "DroneBase sales team will reach out to you to setup your PRO plan.":
      "El equipo de ventas de DroneBase se comunicará con usted para configurar su plan PRO.",
    "Got It!": "¡Entendido!",

    "REVIEW DETAILS": "REVISAR DETALLES",
    "IV Data File": "Archivo de datos IV",
    "Serial No": "Número de serie",
    "Panel Id": "Identificación del pane",
    Manufacturer: "Fabricante",
    "Module Isc(A)": "Módulo Isc(A)",
    "Ref Module Isc(A)": "Ref Módulo Isc(A)",
    "Module Wp(W)": "Módulo Wp(W)",
    "Ref Module Wp(W)": "Ref Módulo Wp(W)",
    "String Current(A)": "Corriente de cadena (A)",
    "Module Vmp(V)": "Módulo Vmp(V)",
    "Ref Module Vmp(V)": "Ref Módulo Vmp(V)",
    "Module Imp(A)": "Módulo Imp(A)",
    "Ref Module Imp(A)": "Ref Módulo Imp(A)",
    "Module Voc(V)": "Módulo Voc(V)",
    "Start Upload": "Iniciar la subida",
    cancel: "cancelar",

    "File does not exist in attachments":
      "El archivo no existe en los archivos adjuntos",
    Discrepancy: "Discrepancia",
    "Invalid Status": "Estado inválido",
    "All Sections": "Todas las secciones",
    "Are you sure you want to delete punch list":
      "¿Estás segura de que quieres eliminar la lista de perforaciones",
    "Delete File": "Borrar archivo",
    "Please select a metric field": "Seleccione un campo de métrica",
    "No Data available for the current selection":
      "No hay datos disponibles para la selección actual",
    "There was an internal server error": "Hubo un error interno del servidor",

    "O&M": "O y M",
    "In-progress": "En-progreso",
    "Options to generate a wide range of reports (Executive report, thermal report, anomalies CSV, anomalies KML).":
      "Opciones para generar una amplia gama de informes (informe ejecutivo, informe térmico, anomalías CSV, anomalías KML).",
    "Gather onsite panel level data such as handheld RGB images, thermal images and IV reports. Verify and validate the anomaly resolution processes using comments.":
      "Recopilar datos de nivel de panel en sitio tales como imágenes RGB de mano, imágenes térmicas y informes IV. Verificar y validar los procesos de resolución de anomalías utilizando comentarios.",
    "Utilize thermography insights acquired by scanning over 16 GW+ of assets across the globe to leverage your asset performance and anomaly resolution.":
      "Utilice las perspectivas de termografía adquiridas por escaneo sobre 16 GW+ de activos a través de todo el mundo para fortalecer su rendimiento de activo y resolución de anomalías.",
    "Prioritize defect resolution at the site by using in-app filters with a variety of filter options (Anomaly names, Severity, IEC category, Anomaly status, ΔT).":
      "Prioritice la resolución de defectos en el sitio utilizando filtros en-app con una variedad de opciones de filtro (nombres de anomalía, gravedad, categoría IEC, estado de anomalía, ΔT).",
    "24x7 conversational support to resolve all your issues with Intercom’s live chats, self-service tools and proactive product tours.":
      "Soporte 24x7 conversacional para resolver todos sus problemas con las charlas en vivo de Intercom, herramientas de servicio autónomo y visitas guiadas de producto.",
    "Generate custom reports using a wide range of filters to meet specific internal reporting requirements.":
      "Generar informes personalizados utilizando una amplia gama de filtros para cumplir con los requerimientos de informes internos específicos.",
    "Generate highly customizable insurance and warranty claim reports to meet the requirements of almost all manufacturers.":
      "Generar informes de reclamación de seguros y garantías personalizables para cumplir los requerimientos de casi todos los fabricantes.",
    "Reduce time, effort and onsite field data acquisition dependency on the internet with CSV file upload functionality.":
      "Reducir el tiempo, esfuerzo y la adquisición de datos en el sitio con la función de subida de archivos CSV.",
    "Easily manage multiple defects using a tabular view to filter and work with large sets of defects.":
      "Facilita la gestión de múltiples defectos utilizando una vista tabular para filtrar y trabajar con grandes conjuntos de defectos.",
    "Compare performance & health of multiple sites within your portfolio using the internal benchmarking tool to analyze the right O&M strategies.":
      "Comparar el rendimiento y la salud de múltiples sitios dentro de su portafolio utilizando la herramienta de medida interna para analizar las estrategias O y M correctas.",
    "Post Commissioning": "Post-comisión",
    "Underperforming table": "Tabla de bajo rendimiento",
    "Single hotspot": "Hotspot único",
    "Foreign object": "Objeto extranjero",

    Generate: "Generar",
    File: "Archivo",
    "Generate Report": "Generar informe",
    Filter: "Filtro",
    "Bulk Upload": "Subida masiva",

    "Serial Number": "Número de serie",
    "Manufacturer Name": "Nombre del Fabricante",
    "Module Power (Wp)": "Potencia del módulo (Wp)",
    "Open Circuit Voltage (Voc)": "Voltaje de circuito abierto (Voc)",
    "Short Circuit Current (Isc)": "Corriente de cortocircuito (Isc)",
    "String Current (I Str)": "Corriente de cadena (I Str)",

    "Standard Test Condition (ΔT)": "Condición de prueba estándar (ΔT)",
    "Site Condition (ΔT)": "Condición del sitio (ΔT)",
    "Actual Module Temp.": "Temperatura real del módulo.",
    "Irradiation (W/m²)": "Irradiación (W/m²)",
    "Anomaly Category": "Categoría de anomalía",

    "Lat/Long": "Latitud longitud",

    "Anomaly Recommendations": "Recomendaciones de anomalías",

    "Scan Panel Barcode": "Escanear código de barras del panel",
  },
};

export default sp;
