import {
  Divider,
  FormControl,
  Hidden,
  MenuItem,
  Select,
} from "@material-ui/core";
import React, { Component } from "react";
import { Trans, withTranslation } from "react-i18next";

import CustomTooltip from "../common/customStyle/Tooltip";
import { URLS } from "../../constants";
import { allRequestHandler } from "../../api";
import downloadBtn from "../../assets/img/downloadBtn.svg";
import reportImg from "../../assets/img/report.svg";
import styles from "./downloadFile.style";
import { withStyles } from "@material-ui/core/styles";

//Download file component
class DownloadFile extends Component {
  state = {
    selectedReport: "all",
    filterCount: 0,
    tooltipOpen: false,
    allDefectsId: null,
    menuOpen: "",
  };

  // These functions are called whenever the component props or state changes
  componentDidUpdate(prevProps) {
    //Set the selected report option to all when the site is changed
    if (prevProps.reportSite !== this.props.reportSite)
      this.setState({ selectedReport: "all" });

    //Apply the selected filters
    if (
      prevProps.displayFilters !== this.props.displayFilters &&
      this.props.displayFilters
    ) {
      let count = 0;
      const reportData = [];
      this.props.displayFilters.map((f, index) => {
        return (
          f && reportData.push(this.props.displayFilters[index].defect_type_id)
        );
      });
      count = reportData.length;
      if (this.props.filters.isCommented) count++;
      if (this.props.filters.severity) count++;
      if (this.props.filters.status) count++;

      this.setState({ filterCount: count });
    }

    //Handles the data change
    if (this.props.allData && prevProps.allData !== this.props.allData) {
      const allDefectsId = this.props.allData.map(({ properties }) => {
        return properties.defect_id;
      });
      this.setState({ allDefectsId });
    }
  }

  //Handles the report download
  handleDownload = async (event) => {
    //For filtered CSV download
    if (event.target.value === "csv") {
      const csv = await allRequestHandler({
        requestType: "POST",
        requestUrl: `${URLS.CUSTOM_REPORT}${this.props.sectionInspectionId}/reports/csv`,
        requestData: { defect_ids_list: this.props.defect_ids_list },
      });
      if (csv) window.open(csv.download_url);
      return;
    }

    //For filtered KML download
    if (event.target.value === "kml") {
      const kml = await allRequestHandler({
        requestType: "POST",
        requestUrl: `${URLS.CUSTOM_REPORT}${this.props.sectionInspectionId}/reports/kml`,
        requestData: { defect_ids_list: this.props.defect_ids_list },
      });
      if (kml) window.open(kml.download_url);
      return;
    }

    //Only for filtered download files
    if (event.target.value === "thermal") {
      const reportData = [];
      this.props.displayFilters.map((f, index) => {
        return (
          f && reportData.push(this.props.displayFilters[index].defect_type_id)
        );
      });

      const getUrl = await allRequestHandler({
        requestType: "POST",
        requestUrl: `${URLS.CUSTOM_REPORT}${this.props.sectionId}/reports/${event.target.value}`,
        requestData: {
          defect_types_list: reportData,
        },
      });
      window.open(getUrl.download_url);
      return;
    }

    //For default CSV download
    if (event.target.value === "csv_report") {
      const csv = await allRequestHandler({
        requestType: "POST",
        requestUrl: `${URLS.CUSTOM_REPORT}${this.props.sectionInspectionId}/reports/csv`,
        requestData: { defect_ids_list: [] },
      });
      if (csv) window.open(csv.download_url);
      return;
    }

    //For default KML download
    if (event.target.value === "kml_report") {
      const kml = await allRequestHandler({
        requestType: "POST",
        requestUrl: `${URLS.CUSTOM_REPORT}${this.props.sectionInspectionId}/reports/kml`,
        requestData: { defect_ids_list: this.state.allDefectsId },
      });
      if (kml) window.open(kml.download_url);
      return;
    }

    //Download the file with the selected parameters
    if (this.props.downloadPage) {
      this.setState({ selectedReport: event.target.value });
      this.props.selectedReport(event.target.value);
    } else {
      const addUrl = `${this.props.fileObj[0]}/${this.props.fileObj[1]}`;

      const getFileUrl = await allRequestHandler({
        requestType: "GET",
        requestUrl: `${URLS.FILE_DOWNLOAD}${addUrl}/downloads/${event.target.value}`,
      });

      if (getFileUrl && getFileUrl.file_url) {
        window.open(getFileUrl.file_url);
      }
    }
  };

  //Renders the download file component
  render() {
    // debugger
    const { classes, fileAvailable } = this.props;
    const { tooltipOpen } = this.state;
    const { t } = this.props;
    return (
      fileAvailable && (
        <CustomTooltip
          arrow
          classes={{
            tooltip: classes.tooltip,
            arrow: classes.anomalieTooltipArrow,
          }}
          placement="top-start"
          title={t("Download Report")}
          open={tooltipOpen}
        >
          <FormControl
            variant={this.props.downloadPage ? "filled" : "outlined"}
            style={{ maxWidth: 40 }}
          >
            <Select
              value={this.state.selectedReport}
              variant="outlined"
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                classes: { paper: classes.downloadMenu },
              }}
              classes={{
                root: classes.downloadMob,
                icon: classes.dropDownIcon,
              }}
              className={classes.selectBlock}
              style={{ width: 40 }}
              onChange={(e) => {
                return this.handleDownload(e);
              }}
              onMouseEnter={() => {
                this.state.menuOpen
                  ? this.setState({ tooltipOpen: false })
                  : this.setState({ tooltipOpen: true });
              }}
              onMouseLeave={() => {
                return this.setState({ tooltipOpen: false });
              }}
              onOpen={() => {
                return this.setState({ menuOpen: true, tooltipOpen: false });
              }}
              onClose={() => {
                return this.setState({ menuOpen: false });
              }}
            >
              <MenuItem value="all" disabled style={{ display: "none" }}>
                {this.props.pageName ? (
                  <Trans>Select Report</Trans>
                ) : (
                  <>
                    <Hidden only={["xs", "sm"]}>
                      <img alt="reportImg" src={reportImg} />
                    </Hidden>
                    <Hidden mdUp>
                      <div
                        style={{
                          marginRight: "8px",
                        }}
                      >
                        <img src={downloadBtn} alt="" />
                      </div>
                    </Hidden>
                  </>
                )}
              </MenuItem>

              {/* Customized Report START */}
              {this.props.filters && (
                <div value="111" className={classes.bgCount}>
                  {this.state.filterCount}
                  <Trans>Filter selected.</Trans>
                  <br />
                  Downloaded reports will have filtered data.
                </div>
              )}

              {this.props.filters && (
                <MenuItem
                  value="csv"
                  ListItemClasses={{ root: classes.blockList }}
                  id={`${this.props.gtmId}-csv-Anomalies-pageDownload`}
                >
                  {" "}
                  <Trans>Filtered CSV Report </Trans>
                </MenuItem>
              )}

              {this.props.filters && (
                <MenuItem
                  value="kml"
                  ListItemClasses={{ root: classes.blockList }}
                  id={`${this.props.gtmId}-kml-Anomalies-pageDownload`}
                >
                  {" "}
                  <Trans>Filtered KML Report </Trans>
                </MenuItem>
              )}

              {this.props.filters && <Divider style={{ height: 4 }} />}
              {/* Customized Report END */}

              {this.props.displayFilters && this.state.filterCount > 0 && (
                <div style={{ color: "#7e909d", fontSize: 12, padding: 12 }}>
                  <Trans>Downloads</Trans>
                </div>
              )}

              <MenuItem
                value="csv_report"
                ListItemClasses={{ root: classes.blockList }}
                id={`${this.props.gtmId}-csv-Anomalies-pageDownload`}
              >
                {" "}
                <Trans>CSV Report</Trans>
              </MenuItem>
              <MenuItem
                value="kml_report"
                ListItemClasses={{ root: classes.blockList }}
                id={`${this.props.gtmId}-kml-Anomalies-pageDownload`}
              >
                {" "}
                <Trans>KML Report</Trans>
              </MenuItem>

              {fileAvailable.executive_report_docx && (
                <MenuItem
                  value="executive_report_docx"
                  ListItemClasses={{ root: classes.blockList }}
                  id={`${this.props.gtmId}-Executive-Anomalies-pageDownload`}
                >
                  {" "}
                  <Trans>Executive Report Docs</Trans>
                </MenuItem>
              )}
              {fileAvailable.executive_report_pdf && (
                <MenuItem
                  value="executive_report_pdf"
                  ListItemClasses={{ root: classes.blockList }}
                  id={`${this.props.gtmId}-PlantExecutive-Anomalies-pageDownload`}
                >
                  {" "}
                  <Trans>Plant Executive Summary</Trans>
                </MenuItem>
              )}
              {fileAvailable.thermal_analysis_excel && (
                <MenuItem
                  value="thermal_analysis_excel"
                  ListItemClasses={{ root: classes.blockList }}
                  id={`${this.props.gtmId}-ThermalExcel-Anomalies-pageDownload`}
                >
                  {" "}
                  <Trans>Thermal Analysis Report</Trans>
                </MenuItem>
              )}
              {fileAvailable.block_report && (
                <MenuItem
                  value="block_report"
                  ListItemClasses={{ root: classes.blockList }}
                  id={`${this.props.gtmId}-Block-Anomalies-pageDownload`}
                >
                  {" "}
                  <Trans>Block Report</Trans>
                </MenuItem>
              )}
              {fileAvailable.thermal_report && (
                <MenuItem
                  value="thermal_report"
                  ListItemClasses={{ root: classes.blockList }}
                  id={`${this.props.gtmId}-ThermalAnalysis-Anomalies-pageDownload`}
                >
                  {" "}
                  <Trans>Thermal Analysis Report </Trans>
                </MenuItem>
              )}
              {fileAvailable.defect_images && (
                <MenuItem
                  value="defect_images"
                  ListItemClasses={{ root: classes.blockList }}
                  id={`${this.props.gtmId}-SectionDefectsImages-Anomalies-pageDownload`}
                >
                  {" "}
                  <Trans>Section Defects Images </Trans>
                </MenuItem>
              )}
              {fileAvailable.defects_images && (
                <MenuItem
                  value="defects_images"
                  ListItemClasses={{ root: classes.blockList }}
                  id={`${this.props.gtmId}-PlantDefectsImages-Anomalies-pageDownload`}
                >
                  {" "}
                  <Trans>Plant Defects Images </Trans>
                </MenuItem>
              )}
              {fileAvailable.inspection_report_guide && (
                <MenuItem
                  value="inspection_report_guide"
                  ListItemClasses={{ root: classes.blockList }}
                  id={`${this.props.gtmId}-Inspection-Anomalies-pageDownload`}
                >
                  {" "}
                  <Trans>Inspection Report Guide</Trans>{" "}
                </MenuItem>
              )}
              {fileAvailable.plant_defects_csv && (
                <MenuItem
                  value="plant_defects_csv"
                  ListItemClasses={{ root: classes.blockList }}
                  id={`${this.props.gtmId}-PlantDefectsCSV-Anomalies-pageDownload`}
                >
                  {" "}
                  <Trans>Plant Defects CSV </Trans>
                </MenuItem>
              )}
              {fileAvailable.plant_defects_kml && (
                <MenuItem
                  value="plant_defects_kml"
                  ListItemClasses={{ root: classes.blockList }}
                  id={`${this.props.gtmId}-PlantDefectsKML-Anomalies-pageDownload`}
                >
                  {" "}
                  <Trans>Plant Defects KML </Trans>
                </MenuItem>
              )}
              {fileAvailable.plant_thermal_report_url && (
                <MenuItem
                  value="plant_thermal_report_url"
                  ListItemClasses={{ root: classes.blockList }}
                  id={`${this.props.gtmId}-ThermalAnalysis-Anomalies-pageDownload`}
                >
                  {" "}
                  <Trans>Thermal Analysis Report </Trans>
                </MenuItem>
              )}
              {fileAvailable.plant_inspection_report && (
                <MenuItem
                  value="plant_inspection_report"
                  ListItemClasses={{ root: classes.blockList }}
                  id={`${this.props.gtmId}-PlantInspection-Anomalies-pageDownload`}
                >
                  {" "}
                  <Trans>Plant Inspection Report </Trans>
                </MenuItem>
              )}
              {fileAvailable.plant_thermal_report && (
                <MenuItem
                  value="plant_thermal_report"
                  ListItemClasses={{ root: classes.blockList }}
                  id={`${this.props.gtmId}-PlantThermal-Anomalies-pageDownload`}
                >
                  {" "}
                  <Trans>Plant Thermal Report </Trans>
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </CustomTooltip>
      )
    );
  }
}

export default withStyles(styles)(withTranslation()(DownloadFile));
