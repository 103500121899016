export default {
  pad12: {
    padding: 12,
    width: "-webkit-fill-available",
  },
  toggleRoot: {
    height: 30,
    width: 60,
    background: "#F0F4FA",
    borderRadius: 8,
  },
  toggleBtn: {
    height: 30,
    width: 30,
  },
  iecTooltip: {
    maxWidth: 300,
  },
  sevTxt: {
    fontFamily: "Montserrat",
    fontWeight: "600",
    fontStyle: "normal",
    fontSize: "14px",
    textTransform: "uppercase",
    color: "#536471",
    display: "flex",
    alignItems: "center",
  },
  sevCard: {
    borderRadius: 8,
    boxShadow: "0px 2px 10px rgba(78, 89, 109, 0.05)",
    minHeight: 170,
    height: "100%",
  },
  stackChart: {
    maxHeight: "80px",
    minHeight: "75px",
    "& canvas": {
      maxHeight: "80px",
      minHeight: "75px",
    },
    "@media (max-width: 600px)": {
      "& canvas": {
        maxHeight: "45px",
        minHeight: "40px",
      },
    },
  },
  anomaliesChart: {
    padding: "16px 16px 8px 16px !important",
  },
  benchmarkToggle: {
    border: "1px solid #0084FF",
    width: "152px",
    height: "32px",
    borderRadius: "8px",
    display: "flex",
    textAlign: "center",
    color: "#536471",
    justifyContent: "space-between",
  },
  anomaliesBtn: {
    border: "1px solid #0084FF",
    height: "32px",
    width: "76px !important",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 4px 12px rgba(78, 89, 109, 0.05)",
    borderRadius: "8px",
    outline: "none",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: "#536471",
    "&:hover": {
      backgroundColor: "#FFFFFF",
    },
    "&:focus": {
      backgroundColor: "#FFFFFF",
      outline: "none",
    },
  },
  anomaliesLabel: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "20px",
    textTransform: "none",
  },
  anomaliesSeveritySelected: {
    border: "1px solid #0084FF",
    width: "76px !important",
    height: "32px",
    backgroundColor: "#0084FF !important",
    color: "#FFFFFF !important",
    alignSelf: "center",
    outline: "none !important",
  },
  anomaliesStatusSelected: {
    border: "1px solid #0084FF",
    borderTopRightRadius: "0px !important",
    borderBottomRightRadius: "0px !important",
    width: "76px !important",
    height: "32px",
    backgroundColor: "#0084FF !important",
    borderRadius: "8px !important",
    color: "#FFFFFF !important",
    alignSelf: "center",
    outline: "none !important",
  },
  anomaliesIECSelected: {
    border: "1px solid #0084FF",
    borderTopLeftRadius: "0px !important",
    borderBottomLeftRadius: "0px !important",
    width: "76px !important",
    height: "32px",
    backgroundColor: "#0084FF !important",
    borderRadius: "8px !important",
    color: "#FFFFFF !important",
    alignSelf: "center",
    outline: "none !important",
  },
  alignClass: {
    "@media (max-width: 1024px)": {
      display: "inline-block",
      width: "200%",
    },
    "@media (max-width: 959px)": {
      display: "inline-block",
      width: "100%",
    },
  },
  chartInfo: {
    marginLeft: 5,
    position: "relative",
    color: "#C7CEDB",
  },
  proPlace: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    zIndex: "2",
  },
  cardInner: {
    height: "100%",
    padding: "14px 20px",
  },
};
