import { Button, Grid, Typography, withStyles } from "@material-ui/core";

import MsgSentImg from "./img/msgSentImg.svg";
import React from "react";
import { Trans } from "react-i18next";
import styles from "./product.style";

//To handle request for pro plan
const ViewPlans = ({ msgSentHandler }) => {
  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        background: "rgba(0,0,0,0.5)",
        zIndex: 100000,
      }}
    >
      <div
        style={{
          width: "464px",
          height: "350px",
          background: "white",
          boxShadow: "0px 4px 12px rgba(78, 89, 109, 0.05)",
          borderRadius: "8px",
          position: "relative",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "48%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img src={MsgSentImg} alt="msgSentImg" style={{ width: "161px" }} />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "8px 72px",
          }}
        >
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "18px",
              lineHeight: "20px",
              color: "#1D2D4",
            }}
          >
            <Trans>Request Sent!</Trans>
          </Typography>
          <p
            style={{
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "24px",
              color: "#536471",
              textAlign: "center",
            }}
          >
            <Trans>
              DroneBase sales team will reach out to you to setup your PRO plan.
            </Trans>
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            style={{
              color: "white",
              background:
                "linear-gradient(266.69deg, #3FC0C2 0.23%, #3FC0C2 99.28%)",
            }}
            onClick={msgSentHandler}
          >
            <Trans>Got It!</Trans>
          </Button>
        </div>
      </div>
    </Grid>
  );
};

export default withStyles(styles)(ViewPlans);
