import { Component, React } from "react";
import { Typography, withStyles } from "@material-ui/core";

import CustomButton from "../common/customStyle/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { URLS } from "../../constants";
import { allRequestHandler } from "../../api";
import styles from "./defectMap.style";

//scanner request option
class FilterDialog extends Component {
  handleDrawer = () => {
    this.props.closeEmailDialog();
  };

  sendEmail = async () => {
    try {
      await allRequestHandler({
        requestType: "POST",
        requestUrl: URLS.REQUEST_BARCODE,
        requestData: "",
      });
      this.handleDrawer();
    } catch (error) {
      console.log("Error");
    }
  };

  //render scanner request dialog
  render() {
    const { classes } = this.props;

    return (
      <Dialog
        fullWidth={true}
        style={{ width: "100%" }}
        onClose={() => {
          return this.handleDrawer();
        }}
        aria-labelledby="customized-dialog-title"
        open={this.props.open}
      >
        <MuiDialogTitle id="customized-dialog-title">
          <Typography className={classes.popUpTitle}>
            Scanner Request
          </Typography>
        </MuiDialogTitle>

        <MuiDialogContent dividers>
          <Typography className={classes.detailTxt}>
            The Barcode Scanner is a trial feature and the trial period has
            ended. <br />
            <br />
            To continue efficient serial ID collection with our fast and
            accurate Barcode Scanner, send us a request now!
          </Typography>
        </MuiDialogContent>

        <MuiDialogActions>
          <CustomButton
            text="Send Request"
            onClick={() => {
              return this.sendEmail();
            }}
          />

          <CustomButton
            text="Cancel"
            type="cancel"
            onClick={() => {
              return this.handleDrawer();
            }}
          />
        </MuiDialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(FilterDialog);
