import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import { Grid } from "@mui/material";
import { useState } from "react";
import getRoleList from "./roleSelect";

const styles = () => {
  return {
    root: {
      backgroundColor: "var(--purple-50, #F4F4FB)",
      "&:focus": {
        backgroundColor: "var(--purple-50, #F4F4FB)",
        borderRadius: "6px",
      },
    },
    select: {
      color: "var(--gray-900, #181A1C)",
      borderRadius: "6px",
      fontWeight: 600,
      justifyContent: "center",
    },
    icon: {
      color: "black",
      marginTop: "3px",
      marginRight: "3px",
    },
    itemsList: {
      width: "150px",
      color: "#08001D",
      fontFamily: "Montserrat",
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      "&:hover": {
        backgroundColor: "#F4F4FB",
      },
    },
    selected: {
      backgroundColor: "#F4F4FB",
      color: "black",

      // color used for selected element
      "&:focus": {
        backgroundColor: "#E6EBF0",
      },
      "&:hover": {
        backgroundColor: "#F4F4FB !important",
      },
    },
    disableDropdown: {
      color: "#BEC5D2",
      borderRadius: "6px",
      fontWeight: 600,
    },
    Menu: {
      background: "#FFFFFF",
      border: "1px solid #F0F4FA",
      boxShadow: "0px 4px 12px rgba(78, 89, 109, 0.05)",
      borderRadius: "5px",
    },
    viewOptionRole: {
      minWidth: "40px",
      maxWidth: "130px",
      height: "20px",
      color: "#181A1C",
      fontFamily: "Montserrat",
      fontWeight: 600,
      padding: "1px 8px",
      borderRadius: "6px",
      backgroundColor: "var(--purple-50, #F4F4FB)",
    },
  };
};

const RoleSelectDropdown = ({
  variant,
  classes,
  selectedValue,
  isViewOption,
  selectedRoleDisplayInView,
  loggedInUserType,
  returnSelectedRole,
  plantDetails,
  isToggle,
  isEditOption,
  notFound,
  isCheckedForAllPlants,
  isGeneralAdminSelected,
  isSitesAccessUsersList,
  getElementPresentInPlantList,
  isNewUserCreation,
}) => {
  const [selectedRole, setSelectedRole] = useState(selectedValue);
  const [filteredRoleBasedOnLoggedUser, setFilteredRoleBasedOnLoggedUser] =
    useState([]);

  //Make default role as User when all option is select or deselect in client:admin.
  useEffect(() => {
    if (
      isNewUserCreation &&
      loggedInUserType === "client:admin" &&
      (isCheckedForAllPlants === false || isCheckedForAllPlants === true)
    ) {
      setSelectedRole("User");
    }
  }, [isCheckedForAllPlants]);

  // get filtered roles based on logged in user
  useEffect(() => {
    setSelectedRole(selectedValue);
    const rolesList = getRoleList(
      loggedInUserType,
      !isToggle ? selectedValue : null
    );
    setFilteredRoleBasedOnLoggedUser(rolesList);
  }, [selectedValue]);

  //pass changed roles data to parent component and set selected role
  const handleRoleSelect = (event) => {
    setSelectedRole(event.target.value);
    returnSelectedRole(
      !isToggle
        ? {
            role: event.target.value,
            plant_id: plantDetails?.plant_id,
            isChecked: plantDetails?.isChecked,
          }
        : {
            role: event.target.value,
          }
    );
  };

  return (
    <FormControl>
      {!isViewOption ? (
        <Select
          classes={{
            root: classes.root,
            select:
              isEditOption &&
              plantDetails.isChecked &&
              loggedInUserType === "site:admin" &&
              !getElementPresentInPlantList?.isChecked &&
              getElementPresentInPlantList?.role === "CLIENT_USER"
                ? classes.disableDropdown
                : (isEditOption &&
                    plantDetails.role === "CLIENT_USER" &&
                    plantDetails.isChecked &&
                    loggedInUserType === "site:admin" &&
                    !notFound) ||
                  (isEditOption &&
                    plantDetails.role === "SITE_ADMIN" &&
                    plantDetails.isChecked &&
                    loggedInUserType === "site:admin" &&
                    !notFound)
                ? classes.disableDropdown
                : isCheckedForAllPlants && isGeneralAdminSelected
                ? classes.disableDropdown
                : plantDetails?.isChecked
                ? classes.select
                : isSitesAccessUsersList
                ? isToggle
                  ? classes.select
                  : classes.disableDropdown
                : classes.disableDropdown,
            icon: classes.icon,
          }}
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            classes: {
              paper: classes.Menu,
            },
          }}
          disableUnderline
          disabled={
            isEditOption &&
            plantDetails.isChecked &&
            loggedInUserType === "site:admin" &&
            !getElementPresentInPlantList?.isChecked &&
            getElementPresentInPlantList?.role === "CLIENT_USER"
              ? true
              : (isEditOption &&
                  plantDetails.role === "CLIENT_USER" &&
                  plantDetails.isChecked &&
                  loggedInUserType === "site:admin" &&
                  !notFound) ||
                (isEditOption &&
                  plantDetails.role === "SITE_ADMIN" &&
                  plantDetails.isChecked &&
                  loggedInUserType === "site:admin" &&
                  !notFound)
              ? true
              : (isEditOption &&
                  plantDetails.role === "CLIENT_USER" &&
                  plantDetails.isChecked &&
                  loggedInUserType === "site:admin" &&
                  notFound) ||
                (isEditOption &&
                  plantDetails.role === "SITE_ADMIN" &&
                  plantDetails.isChecked &&
                  loggedInUserType === "site:admin" &&
                  notFound)
              ? false
              : isCheckedForAllPlants && isGeneralAdminSelected
              ? true
              : plantDetails?.isChecked
              ? false
              : isSitesAccessUsersList
              ? isToggle
                ? false
                : true
              : true
          }
          variant={variant}
          value={selectedRole}
          onChange={(e) => handleRoleSelect(e)}
        >
          {filteredRoleBasedOnLoggedUser.map((item) => {
            return (
              <MenuItem
                value={item}
                key={item}
                classes={{
                  root: classes.itemsList,
                  selected: classes.selected,
                }}
              >
                <Grid container justifyContent={"space-between"}>
                  {item}
                </Grid>
              </MenuItem>
            );
          })}
        </Select>
      ) : (
        <Grid container alignItems="center" className={classes.viewOptionRole}>
          {selectedRoleDisplayInView === "CLIENT_USER"
            ? "User"
            : selectedRoleDisplayInView === "SITE_ADMIN"
            ? "Site Admin"
            : "General Admin"}
        </Grid>
      )}
    </FormControl>
  );
};

export default withStyles(styles)(RoleSelectDropdown);
