import AnomaliesCsv from "./assets/img/anomaliesCsv.svg";
import Config from "./config";
import ExecReport from "./assets/img/execReport.svg";
import FullReport from "./assets/img/fullReport.svg";
import KmlReport from "./assets/img/kmlReport.svg";
import ThermalReport from "./assets/img/thermalReport.svg";
import WarrantyReport from "./assets/img/warrantyReport.svg";

// dev deploy only
// const requestUrl = "https://dev.airprobe.app"
// Staging deploy
// const requestUrl = "https://staging-api.airprobe.app"

const requestUrl = Config.hostName;

export const version = "v7.0";

export const URLS = {
  LOGIN: `${requestUrl}/api/auth/login`,
  LOGOUT: `${requestUrl}/api/auth/logout`,
  CHANGE_PASSWORD: `${requestUrl}/api/auth/change-password`,
  FORGOT_PASSWORD: `${requestUrl}/api/auth/forgot-password`,
  VERIFY_TOKEN: `${requestUrl}/api/auth/forgot-password/verify`,
  RESET_PASSWORD: `${requestUrl}/api/auth/reset-password`,
  SECTION_MAP: `${requestUrl}/api/analysis/sections/`,
  DEFECT_SECTION: `${requestUrl}/api/sections/`,
  ANOMALY_DETAILS: `${requestUrl}/api/anomalies/`,
  DEFECT_PLANT: `${requestUrl}/api/plants/`,
  DASHBOARD_METADATA: `${requestUrl}/api/dashboard/metadata`,
  ANOMALIES_BY_SEVERITY: `${requestUrl}/api/dashboard/anomalies_by_severity`,
  ANOMALIES_METRICS: `${requestUrl}/api/dashboard/anomalies_metrics`,
  INSPECTIONS: `${requestUrl}/api/dashboard/inspections`,
  BENCHMARK: `${requestUrl}/api/dashboard/benchmark`,
  SITE_LIST_DATA: `${requestUrl}/api/sites`,
  SITE_SUMMARY_METADATA: `${requestUrl}/api/site_summary/`,
  SITE_DEFECTS_CHART: `${requestUrl}/api/dashboard/sites_defect_chart`,
  MODULES_DEFECT_CHART: `${requestUrl}/api/dashboard/modules_defect_chart`,
  FILE_DOWNLOAD: `${requestUrl}/api/`,
  GET_COMMENT_LIST: `${requestUrl}/api/defects/`,
  UPLOAD_COMMENT: `${requestUrl}/api/uploads/comments`,
  UPLOAD_FILE: `${requestUrl}/api/comments`,
  UPLOAD_PROOF: `${requestUrl}/api/attachments`,
  USER_PROFILE: `${requestUrl}/api/user_profile`,
  CUSTOME_DEFECT_DICTIONARY: `${requestUrl}/api/defects/custom_defect_dictionary`,
  UPDATE_DEFECT_NAME: `${requestUrl}/api/defects/set_custom_defect_name`,
  UPDATE_DEFECT_SEVERITY: `${requestUrl}/api/defects/set_custom_defect_severity`,
  EDIT_USER: `${requestUrl}/api/user_profile/edit_details`,
  CUSTOM_REPORT: `${requestUrl}/api/section_inspections/`,
  //GET_ACTIVITY: requestUrl + "/api/activities",
  GET_PANEL_DETAILS: `${requestUrl}/api/defects/`,
  GET_CURRENCY: `${requestUrl}/api/configurations/currency`,
  REPORT_API: `${requestUrl}/api/reports`,
  PRODUCT_STATUS: `${requestUrl}/api/client/product/status`,
  REQUEST_PRO: `${requestUrl}/api/client/product/email`,
  REQUEST_BARCODE: `${requestUrl}/api/client/barcode/email`,
  BULK_UPLOAD_CSV: `${requestUrl}/api/attachments/aws_params`,
  GET_ANOMALIES_LIST: (section_inspection_id) => {
    return `${requestUrl}/api/section_inspection/${section_inspection_id}/anomalies/csv_to_json`;
  },
  BULK_UPLOAD_FILES_AWS_PARAMS: (section_inspection_id) => {
    return `${requestUrl}/api/section_inspection/${section_inspection_id}/anomalies`;
  },
  BULK_UPLOAD_IMAGE_PATCH: (defect_id, attachment_id) => {
    return `${requestUrl}/api/anomalies/${defect_id}/attachments/${attachment_id}`;
  },
  ALL_USER: `${requestUrl}/api/users`,
};

export const statusList = [
  {
    name: "Pending",
    value: "pending",
  },
  {
    name: "Resolved",
    value: "resolved",
  },
  {
    name: "In Progress",
    value: "inprogress",
  },
  {
    name: "Not Found",
    value: "notfound",
  },
  {
    name: "False +ve",
    value: "falsepositive",
  },
];

export const cardHeader = [
  {
    name: "Sites",
    value:
      "M7.816 0l-.332 2.637h4.999L12.15 0H7.816zm-1.01 0l-.331 2.637H1.6l.229-1.305C1.964.564 2.682 0 3.524 0h3.283zm-5.38 3.632L.534 8.72H5.71l.64-5.088H1.426zM.38 9.6l-.358 2.037c-.169.964.637 1.839 1.695 1.839H5.11L5.598 9.6H.38zm10.605 3.876h2.862L13.359 9.6H6.608l-.488 3.876h2.862v2.717h2.003v-2.717zm3.871 0h3.427c1.057 0 1.864-.875 1.694-1.839L19.62 9.6h-5.252l.488 3.876zm4.61-4.756l-.892-5.088h-4.957l.64 5.088h5.209zm-1.067-6.083l-.229-1.305C18.036.564 17.318 0 16.476 0H13.16l.332 2.637H18.4zm-11.04.995h5.25l.64 5.088h-6.53l.64-5.088zM4.976 17.187c0-.549.446-.994.995-.994h8.025a.993.993 0 110 1.989H5.97a.994.994 0 01-.995-.995z",
    color: "#005AFF",
    borderColor: "#dde9ff",
    bgColor: "#f2f7ff",
  },
  {
    name: "AC Capacity",
    value:
      "M12.3577 2C13.5519 2 14.5705 2.7513 14.9668 3.80699C15.1609 4.32405 15.5914 4.78592 16.1436 4.78592C16.6959 4.78592 17.1545 4.33325 17.0405 3.79287C16.5833 1.62624 14.6604 0 12.3577 0C9.71454 0 7.57182 2.14273 7.57182 4.78592C7.57182 6.32453 6.32453 7.57183 4.78592 7.57183C3.59178 7.57183 2.57313 6.82053 2.17685 5.76484C1.98276 5.24778 1.55228 4.78592 1 4.78592C0.447715 4.78592 -0.0108693 5.23858 0.10316 5.77896C0.56035 7.94559 2.48322 9.57183 4.78592 9.57183C7.4291 9.57183 9.57183 7.4291 9.57183 4.78592C9.57183 3.2473 10.8191 2 12.3577 2Z",
    color: "rgb(143,0,255)",
    borderColor: "#f1e7ff",
    bgColor: "#f7f1ff",
  },
  {
    name: "DC Capacity",
    value: "M5 0H3v3H0v2h3v3h2V5h3V3H5V0zm7 3h8v2h-8V3z",
    color: "#15C284",
    borderColor: "#cdfaea",
    bgColor: "#e0fdf3",
  },
  {
    name: "Power Loss",
    value:
      "M2.4275 0H4.5723C5.26626 0 5.74925 0.68956 5.51209 1.34174L4.88791 3.05826C4.65075 3.71044 5.13374 4.4 5.8277 4.4H5.92106C6.67882 4.4 7.16126 5.20998 6.80035 5.87628L2.58576 13.6571C2.4713 13.8684 2.2503 14 2.00999 14C1.59311 14 1.28237 13.6156 1.36972 13.208L2.14563 9.58705C2.27655 8.97611 1.81081 8.4 1.186 8.4C0.570348 8.4 0.106719 7.83973 0.221915 7.23495L1.44516 0.812888C1.535 0.341235 1.94737 0 2.4275 0Z",
    color: "#FF9F1D",
    borderColor: "#ffefd9",
    bgColor: "#fff7e9",
  },
  {
    name: "Anomalies Modules",
    value:
      "M3 0C1.343 0 0 1.343 0 3v10c0 1.657 1.343 3 3 3h10c1.657 0 3-1.343 3-3V3c0-1.657-1.343-3-3-3H3zM2 3c0-.552.448-1 1-1h4.533v5.533H2V3zm0 5.867V13c0 .552.448 1 1 1h4.533V8.867H2zM8.867 14H13c.552 0 1-.448 1-1V8.867H8.867V14zM14 7.533V3c0-.552-.448-1-1-1H8.867v5.533H14z",
    color: "#FF4D4D",
    borderColor: "#ffdfdf",
    bgColor: "#fff0f0",
  },
  {
    name: "Revenue Loss/yr",
    value: "M1.5 1L6.5 5.5L1 8L8.5 13.5M8.5 13.5L8 10M8.5 13.5L5 14",
    color: "#78A5C8",
    borderColor: "#DDE5EC",
    bgColor: "#ffffff",
    fill: "none",
    stroke: "#5B4DD3",
    strokeWidth: "2",
    strokeLinecap: "round",
    strokeLinejoin: "round",
  },
];

export const uploadFileOptions = [
  {
    name: "Handheld",
    value: "handheld_image",
  },
  {
    name: "EL Image",
    value: "el_image",
  },
  {
    name: "IV Datasheet",
    value: "iv_curve_data",
  },
  {
    name: "Upload a File",
    value: "file",
  },
];

export const imageOptions = [
  {
    name: "Comments",
    value: "comments",
  },
  {
    name: "EL Image",
    value: "el_image",
  },
  {
    name: "IV Curve Image",
    value: "iv_curve_data",
  },
  {
    name: "Handheld Image",
    value: "handheld_image",
  },
];

export const allMonths = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const tiltAngle = [
  {
    angle: 0,
    season: "Summer",
  },
  {
    angle: 0,
    season: "Winter",
  },
];

export const iFormData = {
  plant_id: null,
  section_id: null,
  anomaly_type_list: null,
  plant_inspection_id: null,
  temperature_range: { start: 0, end: 100 },
  panel_details: {
    panel_id: false,
    string_id: false,
    manufacturer: false,
    defect_panel_voltage: false,
    defect_panel_current: false,
    defect_module_power: false,
    healthy_panel_voltage: false,
    healthy_panel_current: false,
    healthy_module_power: false,
    string_current: false,
    // defect_panel_temperature: false,
    // healthy_panel_temperature: false,
    defect_max_power_current: false,
    defect_max_power_voltage: false,
    healthy_max_power_current: false,
    healthy_max_power_voltage: false,
  },
  anomaly_details: {
    anomaly_number: false,
    anomaly_type: false,
    lat_long: false,
    module_coordinates: false,
    hotspot_temperature: false,
    actual_module_temperature: false,
    delta_temperature: false,
    severity: false,
    status: false,
    iec_category: false,
    stc_delta_temperature: false,
    // stc_actual_module_temperature: false,
    // stc_hotspot_temperature: false,
    irradiation: false,
  },
  attachments: {
    thermal_image: false,
    rgb_image: false,
    el_image: false,
    iv_data: false,
    handheld_image: false,
    comment: false,
  },
};

export const customReportTableProps = [
  { key: "anomaly_number", name: "Anomaly No." },
  { key: "anomaly_type", name: "Anomaly Name" },
  { key: "module_coordinates", name: "Coordinates" },
  { key: "lat_long", name: "Lat / Long" },
  { key: "manufacturer", name: "Module Manufacturer Name" },
  { key: "hotspot_temperature", name: "T 2 (Hotspot)" },
  { key: "actual_module_temperature", name: "T1 (Actual)" },
  { key: "delta_temperature", name: "∆T - Site Condition" },
  { key: "stc_delta_temperature", name: "∆T - STC" },
  { key: "severity", name: "Severity" },
  { key: "status", name: "Status" },
  {
    key: [
      "thermal_image",
      "rgb_image",
      "el_image",
      "iv_data",
      "handheld_image",
    ],
    name: "Attachments",
  },
  { key: "comment", name: "Comments" },
  { key: "iec_category", name: "IEC CoA" },
  { key: "panel_id", name: "Module Serial Number" },
  { key: "string_id", name: "String Id" },
  { key: "healthy_panel_voltage", name: "Ref Module Voc" },
  { key: "defect_panel_voltage", name: "Module Voc" },
  { key: "healthy_panel_current", name: "Ref Module Isc" },
  { key: "defect_panel_current", name: "Module Isc" },
  { key: "defect_module_power", name: "Module Wp" },
  { key: "healthy_module_power", name: "Ref Module Wp" },
  { key: "defect_max_power_current", name: "Module Imp" },
  { key: "defect_max_power_voltage", name: "Module Vmp" },
  { key: "healthy_max_power_current", name: "Ref Module Imp" },
  { key: "healthy_max_power_voltage", name: "Ref Module Vmp" },

  // { key: "healthy_panel_temperature", name: "Healthy Panel Temperature" },
  { key: "string_current", name: "String Current" },
  // { key: "stc_delta_temperature", name: "STC Delta Temperature" },
  /* {
    key: "stc_actual_module_temperature",
    name: "STC Actual Module Temperature",
  }, */
  // { key: "stc_hotspot_temperature", name: "STC Hotspot Temperature" },
  { key: "irradiation", name: "Irradiation" },
];

export const downloadTypes = {
  executive_report: {
    title: "Executive Report",
    text: "Includes high level data of site inspection",
    img: ExecReport,
  },
  warranty_claim_report: {
    title: "Warranty Claim Report",
    text: "Download anomalies with serial no. & manufacturer details",
    img: WarrantyReport,
  },
  thermal_report: {
    title: "Thermal Report",
    text: "Detailed thermal report for all the anomalies",
    img: ThermalReport,
  },
  anomalies_csv: {
    title: "Anomalies CSV",
    text: "Comma separated list of all anomalies with module co-ordinates",
    img: AnomaliesCsv,
  },
  anomalies_kml: {
    title: "Anomalies KML",
    text: "Download kml data for all anomalies",
    img: KmlReport,
  },
  inspection_report: {
    title: "Full Inspection Report",
    text: "Details inspection report including anomalies annotations, power loss estimates, recommendations etc.",
    img: FullReport,
  },
};

export const plans = [
  {
    title: "Basic",
    buttonText: "Starter",
    features: [
      { title: "Access to webapp", checked: true },
      {
        title: "Download basic reports",
        checked: true,
        tooltipText:
          "Options to generate a wide range of reports (Executive report, thermal report, anomalies CSV, anomalies KML).",
      },
      { title: "Navigation to defect", checked: true },
      { title: "Anomaly status", checked: true },
      {
        title: "Site data upload and comments",
        checked: true,
        tooltipText:
          "Gather onsite panel level data such as handheld RGB images, thermal images and IV reports. Verify and validate the anomaly resolution processes using comments.",
      },
      {
        title: "Thermography Expertise",
        checked: true,
        tooltipText:
          "Utilize thermography insights acquired by scanning over 16 GW+ of assets across the globe to leverage your asset performance and anomaly resolution.",
      },
      {
        title: "Wide range of filters",
        checked: true,
        tooltipText:
          "Prioritize defect resolution at the site by using in-app filters with a variety of filter options (Anomaly names, Severity, IEC CoA, Anomaly status, ΔT).",
      },
      // {
      //   title: "Intercom support and guided tours",
      //   checked: true,
      //   tooltipText:
      //     "24x7 conversational support to resolve all your issues with Intercom’s live chats, self-service tools and proactive product tours.",
      // },
      { title: "Web app support", checked: true },
      {
        title: "Download custom reports",
        checked: false,
        tooltipText:
          "Generate custom reports using a wide range of filters to meet specific internal reporting requirements.",
      },
      {
        title: "Graph downloads",
        checked: false,
      },
      {
        title: "Warranty claim reports",
        checked: false,
        tooltipText:
          "Generate highly customizable insurance and warranty claim reports to meet the requirements of almost all manufacturers.",
      },
      {
        title: "Bulk upload - faster",
        checked: false,
        tooltipText:
          "Reduce time, effort and onsite field data acquisition dependency on the internet with CSV file upload functionality.",
      },
      {
        title: "Tabular view for large data sets",
        checked: false,
        tooltipText:
          "Easily manage multiple defects using a tabular view to filter and work with large sets of defects.",
      },
      {
        title: "Internal and external benchmarking",
        checked: false,
        tooltipText:
          "Compare performance & health of multiple sites within your portfolio using the internal benchmarking tool to analyze the right O&M strategies.",
      },
      {
        title: "Unlimited users and external sharing",
        checked: false,
      },
      { title: "Extended data storage( 10GB / 100MW )", checked: false },
    ],
  },
  {
    title: "Pro",
    buttonText: "Contact Sales to Upgrade",
    features: [
      { title: "Access to webapp", checked: true },
      {
        title: "Download basic reports",
        checked: true,
        tooltipText:
          "Options to generate a wide range of reports (Executive report, thermal report, anomalies CSV, anomalies KML).",
      },
      { title: "Navigation to defect", checked: true },
      { title: "Anomaly status", checked: true },
      {
        title: "Site data upload and comments",
        checked: true,
        tooltipText:
          "Gather onsite panel level data such as handheld RGB images, thermal images and IV reports. Verify and validate the anomaly resolution processes using comments.",
      },
      {
        title: "Thermography Expertise",
        checked: true,
        tooltipText:
          "Utilize thermography insights acquired by scanning over 16 GW+ of assets across the globe to leverage your asset performance and anomaly resolution.",
      },
      {
        title: "Wide range of filters",
        checked: true,
        tooltipText:
          "Prioritize defect resolution at the site by using in-app filters with a variety of filter options (Anomaly names, Severity, IEC CoA, Anomaly status, ΔT).",
      },
      // {
      //   title: "Intercom support and guided tours",
      //   checked: true,
      //   tooltipText:
      //     "24x7 conversational support to resolve all your issues with Intercom’s live chats, self-service tools and proactive product tours.",
      // },
      { title: "Web app support", checked: true },
      {
        title: "Download custom reports",
        checked: true,
        tooltipText:
          "Generate custom reports using a wide range of filters to meet specific internal reporting requirements.",
      },
      {
        title: "Graph downloads",
        checked: true,
      },
      {
        title: "Warranty claim reports",
        checked: true,
        tooltipText:
          "Generate highly customizable insurance and warranty claim reports to meet the requirements of almost all manufacturers.",
      },
      {
        title: "Bulk upload - faster",
        checked: true,
        tooltipText:
          "Reduce time, effort and onsite field data acquisition dependency on the internet with CSV file upload functionality.",
      },
      {
        title: "Tabular view for large data sets",
        checked: true,
        tooltipText:
          "Easily manage multiple defects using a tabular view to filter and work with large sets of defects.",
      },
      {
        title: "Internal and external benchmarking",
        checked: true,
        tooltipText:
          "Compare performance & health of multiple sites within your portfolio using the internal benchmarking tool to analyze the right O&M strategies.",
      },
      {
        title: "Unlimited users and external sharing",
        checked: true,
      },
      { title: "Extended data storage( 10GB / 100MW )", checked: true },
    ],
  },
];

export const cookies = [
  {
    isActive: true,
    cookieName: "essentialCookies",
    cookieTitle: "Essential Cookies",
    cookieText:
      "These cookies are necessary for the website to function and cannot be switched off in our systems.",
    cookieList: [
      {
        title: "Sentry",
        name: "Sentry (script.sentry-report.com)",
        description:
          "Used for performance monitoring and error logging, it helps us to monitor, fix application crashes in real-time.",
        host: "https://sentry.io/privacy/",
        duration: "",
        type: "Third Party",
        category: "Essential Cookies",
      },
      {
        title: "Intercom",
        name: "Intercom (script.intercom.com)",
        description:
          "Used for providing conversation support to our users, it helps us to connect with our users for launching new products, onboarding, and resolving their issues.",
        host: " https://www.intercom.com/legal/privacy",
        duration: "7 days",
        type: "Third Party",
        category: "Essential Cookies",
      },
    ],
  },
  {
    isActive: false,
    cookieName: "performanceCookies",
    cookieTitle: "Performance Cookies",
    cookieText:
      "Performance cookies collect and report session information anonymously to help us understand how users interact with our application",
    cookieList: [
      {
        title: "Google Analytics",
        name: "Google Analytics",
        description:
          "Used for tracking application activities such as session duration, pages visited, etc. it helps us to monitor our application usage.",
        host: "https://policies.google.com/privacy?hl=en-US",
        duration: "2 years",
        type: "Third Party",
        category: "Performance Cookies",
      },
      {
        title: "Hotjar",
        name: "Hotjar",
        description:
          "Used for session recording and heatmaps for tracking activities on our application helps us improve our application usability.",
        host: "https://www.hotjar.com/legal/policies/privacy/",
        duration: "365 days",
        type: "Third Party",
        category: "Performance Cookies",
      },
    ],
  },
];
