import { makeStyles } from "@material-ui/core/styles";
export default makeStyles(() => {
  return {
    OpenPopUp: {
      width: 500,
      minHeight: 250,
      "@media only screen and (max-width: 760px)": {
        width: "100%",
        minWidth: 320,
      },
    },
    head: {
      fontFamily: "Montserrat !important",
      padding: 0,
      fontSize: "12px",
      paddingBottom: "8px",
    },
    upload: {
      fontSize: "12px",
      fontWeight: 600,
      fontFamily: "Montserrat",
    },
    paddAlign: {
      padding: "0px 10px 10px 0px",
    },
    marTB20: {
      margin: 16,
    },
    fileInputField: {
      border: "1px solid #C7CEDB",
      borderRadius: "8px",
      marginBottom: 5,
      padding: "0 8px",
    },
    titleText: {
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      fontFamily: "Montserrat",
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "20px",
      color: "#536471",
    },
    popUpTitle: {
      fontSize: "14px",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "24px",
      letterSpacing: "normal",
      color: "#536471",
      fontFamily: "Montserrat",
      fontWeight: "600",
    },
    closeIcon: {
      cursor: "pointer",
      color: "#536471",
    },
    addIcon: {
      paddingTop: 28,
      marginLeft: "20px",
      width: "75px",
      height: 100,
      "@media only screen and (max-width: 760px)": {
        paddingTop: 0,
      },
    },
    paperDialog: {
      margin: 0,
    },
  };
});
