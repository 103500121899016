import { Divider, Grid, Typography, withStyles } from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import CustomButton from "../../views/common/customStyle/Button";
import ManageCookie from "./ManageCookie";
import React from "react";
import { cookies } from "../../constants";
import styles from "./cookieConsent.style";
import zeitviewLogo from "../../assets/img/zeitviewLogo.svg";

//cookie popup
function CookiePopOver({
  classes,
  manageCookieHandler,
  getCookieListing,
  onChangeHandler,
  savePreferenceHandler,
}) {
  return (
    <Grid className={classes.cookiePopUpContainer}>
      <Grid
        className={classes.header}
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <img src={zeitviewLogo} alt="Dronebase logo" width="110" />
        <CloseIcon
          fontSize="large"
          className={classes.closeIcon}
          onClick={manageCookieHandler}
        />
      </Grid>
      <Divider />
      <Grid className={classes.bodySection}>
        <Grid className={classes.privacyPolicySection}>
          <Typography className={classes.titleText}>
            Privacy Preferences
          </Typography>
          <p className={classes.subTitleText} style={{ color: "#536471" }}>
            DroneBase platform uses cookies to store and retrieve session
            information on your browser to improve your website experience and
            to run our application effectively. We Do not use cookies to store
            any Personally Identifiable Information (PII), show ads or for
            marketing purposes. We use cookies only to detect errors on our
            application and to understand application activity for product
            improvement. We value your privacy and have provided options for you
            to choose and consent to us for using cookies on our application.
            Click on different category headings to find out more and change our
            default settings. However, blocking some types of cookies may impact
            your experience of the site and the services we are able to offer.
          </p>
          {/* <a
            href="!#"
            className={classes.subTitleText}
            style={{
              color: "#536471",
            }}
          >
            View Privacy Policy
          </a> */}
        </Grid>
        <Grid className={classes.managCookiesSection}>
          <Typography
            className={classes.subTitleText}
            style={{
              fontWeight: "bold",
              marginTop: "16px",
              marginBottom: "8px",
            }}
          >
            Manage Cookies
          </Typography>
          {cookies &&
            cookies.length > 0 &&
            cookies.map((item) => {
              return (
                <ManageCookie
                  key={item.cookieList.cookieName}
                  item={item}
                  manageCookieHandler={manageCookieHandler}
                  getCookieListing={() => {
                    return getCookieListing(item.cookieList, item.cookieTitle);
                  }}
                  onChangeHandler={onChangeHandler}
                />
              );
            })}
        </Grid>
      </Grid>
      <Divider />
      <Grid className={classes.footer}>
        <CustomButton
          variant="outlined"
          onClick={savePreferenceHandler}
          text="Save My Preferences"
          style={{
            marginLeft: "12px",
            background: "#5B4DD3",
            color: "#FFFFFF",
            border: "none",
          }}
        />
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(CookiePopOver);
