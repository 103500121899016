import "./index.css";

import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import { Integrations } from "@sentry/tracing";
import React from "react";
import TagManager from "react-gtm-module";
import { createRoot } from "react-dom/client";
import { hotjar } from "react-hotjar";
import i18n from "./translation/i18n.js";
import { init } from "@sentry/react";
import { unregister } from "./serviceWorker";
import { version } from "./constants";

const urlName = window.location.hostname;

//for managing cookies
const cookieManager = () => {
  let tagManagerArgs = {
    gtmId: "",
  };
  let hotjarId = null;

  if (urlName === "airprobe.app") {
    tagManagerArgs = {
      gtmId: "GTM-KLHVZNW",
    };
    hotjarId = 1896887;
  } else if (urlName === "demo.airprobe.app") {
    tagManagerArgs = {
      gtmId: "GTM-K9QWLWZ",
    };
    hotjarId = 1892183;
  } else if (urlName === "staging.airprobe.app") {
    tagManagerArgs = {
      gtmId: "GTM-N4H897M",
    };
  }

  hotjar.initialize(
    hotjarId,
    (function (h, o, t, j, a, r) {
      let fifthParam = a;
      let lastParam = r;
      h.hj =
        h.hj ||
        function () {
          // skipcq Require rest parameters instead of arguments JS-0244
          (h.hj.q = h.hj.q || []).push(arguments);
        };
      h._hjSettings = { hjid: hotjarId, hjsv: 6 };
      fifthParam = o.getElementsByTagName("head")[0];
      lastParam = o.createElement("script");
      lastParam.async = 1;
      lastParam.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      fifthParam.appendChild(lastParam);
    })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=")
  );

  TagManager.initialize(tagManagerArgs);
};

const cookieStorage = {
  getAll: () => {
    const cookies = document.cookie
      .split("; ")
      .map((cookie) => {
        return cookie.split("=");
      })
      .reduce((acc, [key, value]) => {
        return { ...acc, [key]: value };
      }, {});
    return cookies;
  },

  getItem: (key) => {
    const cookies = cookieStorage.getAll();
    return cookies[key];
  },

  setItem: (key, value, days = 365) => {
    const date = new Date();
    date.setTime(date.getTime() + days * 60 * 60 * 24 * 1000);
    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `${key}=${value};${expires};path=/`;
  },

  getAirprobeCookies: () => {
    const essentialCookies = cookieStorage.getItem("essentialCookies");
    const performanceCookies = cookieStorage.getItem("performanceCookies");
    const cookies = [];
    if (essentialCookies) cookies.push({ essentialCookies });
    if (performanceCookies) cookies.push({ performanceCookies });
    return { cookies, essentialCookies, performanceCookies };
  },

  enablePerformanceCookies: () => {
    cookieManager();
  },

  disablePerformanceCookies: () => {
    const cookies = cookieStorage.getAll();
    for (const key of Object.keys(cookies)) {
      // [""].map((domain) => {
      if (
        !key.startsWith("intercom") &&
        !["essentialCookies", "performanceCookies"].includes(key)
      ) {
        return (document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`);
      }
      // });
    }
    return false;
  },
};

let env = "staging";
let tracesSampleRate = 0.05;
if (urlName === "airprobe.app") {
  env = "production";
  tracesSampleRate = 0.7;
} else if (urlName === "demo.airprobe.app") {
  env = "dev.airprobe.app";
  tracesSampleRate = 0.2;
}
// else if (urlName === "staging.airprobe.app") {
//   env = "staging";
//   tracesSampleRate = 0.05;
// }

// Sentry Implementation
init({
  dsn: "https://e6323c3fbecc40d0a9b03cd913c67836@o324603.ingest.sentry.io/1827728",
  release: { version },
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate,
  environment: env,
  initialScope: {
    tags: { "my-tag": "web_view" },
  },
  beforeSend: (event, hint) => {
    const error = hint.originalException;
    if (window.location.hostname === "localhost") {
      return null;
    } else if (
      // Filtering unnecessary errors
      error &&
      error.message &&
      error.message.match(/database unavailable/i)
    ) {
      event.fingerprint = ["database-unavailable"];
    }
    return event;
  },
});

const root = createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <I18nextProvider i18n={i18n}>
      <App cookieStorage={cookieStorage} />
    </I18nextProvider>
  </BrowserRouter>
);

unregister();
