import { Breadcrumbs, Grid, Typography } from "@material-ui/core";
import { Trans, withTranslation } from "react-i18next";

import AnomaliesCategoryBar from "../defectMap/compare Insp charts/anomaliesCategoryBar";
import Box from "@mui/material/Box";
import ComparisonTable from "./ComparisonTable";
import Config from "../../config";
import CustomButton from "../common/customStyle/Button";
import HorizontalSevBarChart from "./horizontalSevBarChart";
import { Navigate } from "react-router-dom";
import NewLoader from "../../layout/newLoader";
import React from "react";
import RevenueAndPowerChart from "../defectMap/compare Insp charts/revenueAndPower";
import _ from "lodash";
import { allRequestHandler } from "../../api";
import backIcon from "../../assets/img/backIcon.svg";
import moment from "moment";
import styles from "./inspectionsComp.style";
import { withStyles } from "@material-ui/core/styles";

const requestUrl = Config.hostName;

//Inspection comparison
class InspectionComp extends React.Component {
  state = {
    anomalyComparisonData: "",
    allPlantData: null,
    allDefectList: null,
    loading: true,
    showStats: true,
    revenueLossTrigger: false,
    yieldValue: null,
    tariff: null,
    popupToggle: false,
    isNavigate: false,
    siteData: null,
    currency: null,
  };

  //These functions are called when the component is mounted (on the initial render)
  componentDidMount() {
    //Get the power an& revenue loss data and yield-tariff data on the initial render
    // #TODO remove demo json while deploying
    this.getLossData();
    this.getYieldTariffData();
  }

  //These functions are called when the component is updated
  componentDidUpdate() {
    //Get the power an& revenue loss data and yield-tariff data on the component update
    if (this.state.revenueLossTrigger === true) {
      this.getLossData();
      this.getYieldTariffData();
    }
  }

  //API call to get the yield and tariff data
  getYieldTariffData = async () => {
    const data = await allRequestHandler({
      requestType: "GET",
      requestUrl: `${requestUrl}/api/sites/${this.props.siteId}/site_details`,
    });

    if (data) {
      delete data.mounting_options;
      delete data.upload_href;
      const updatedData = data;
      this.setState({
        siteData: updatedData,
        yieldValue: updatedData.pv_out,
        tariff: updatedData.tariff_data.power_tariff,
      });
    }
  };

  //API call to get the power and revenue loss data
  getLossData = async () => {
    const getData = await allRequestHandler({
      requestType: "GET",
      requestUrl: `${requestUrl}/api/sites/${this.props.siteId}/anomaly_comparison`,
    });

    let allDefectList = [];

    //Push the defect details in the allDefectList array
    if (getData) {
      getData.label_comparison.forEach((labelComparison) => {
        labelComparison.label_list = _.sortBy(
          labelComparison.label_list,
          "name"
        );
      });
      
      const currency = getData?.anomaly_inspection_list.flatMap((inspection) =>
        inspection.anomaly_list.map((anomaly) => anomaly.currency)
      );
      
      allDefectList = _.sortBy(allDefectList, "name");

      this.setState({
        anomalyComparisonData: getData,
        loading: false,
        currency: currency[0],
        allDefectList,
        revenueLossTrigger: false,
        allPlantData: _.orderBy(
          getData.anomaly_inspection_list,
          "inspection_date",
          "asc"
        ),
      });
    }
  };

  //Renders the inspection comparison page
  render() {
    const { classes } = this.props;

    const headerDetails =
      this.props.siteListData &&
      this.props.siteId &&
      this.props.siteListData.find((x) => {
        return x.plant_id === this.props.siteId;
      });

    if (this.state.isNavigate)
      return <Navigate to={`/defects/${headerDetails.plant_id}`} />;

    if (this.state.loading)
      return (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: "100%" }}
        >
          <NewLoader />
        </Grid>
      );

    return (
      <>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          className={classes.headerHeight}
          style={{ overflowX: "auto" }}
        >
          <Grid item>
            <CustomButton
              type="bgNoneWOHover"
              btnIcon={backIcon}
              style={{
                ...styles.backBtn,
                marginLeft: `${this.props.toggleMenu ? "24px" : "0px"}`,
              }}
              onClick={() => {
                return this.props.navigate(-1);
              }}
            />
          </Grid>
          <Grid
            item
            xs
            className={classes.outerHeader}
            style={{ marginLeft: `${this.props.toggleMenu ? "8px" : "8px"}` }}
          >
            <Breadcrumbs className={classes.breadRow}>
              <span>
                <a
                  href="#button"
                  onClick={() => this.setState({ isNavigate: true })}
                  role="button"
                  tabIndex={"0"}
                  aria-hidden="true"
                >
                  Anomalies: #{headerDetails.plant_name}
                </a>
              </span>
              <span>Inspection Comparison</span>
            </Breadcrumbs>

            <Typography className={[classes.title, classes.marMob].join(" ")}>
              <Trans>Compare Inspections</Trans>
            </Typography>
          </Grid>
        </Grid>
        <div className={classes.yieldValueBox}>
          {this.state.yieldValue === null || this.state.tariff === null ? (
            <Grid
              container
              alignItems="center"
              className={classes.yieldBtnContainer}
            >
              <Grid item>
                Add Yield and Feed in tariff to calculate energy and revenue
                losses.
              </Grid>
              <Grid item>
                <CustomButton
                  text="Add Yield and Feed in Tariff"
                  style={{
                    ...styles.yieldPopup,
                  }}
                  onClick={() => {
                    return this.setState({ popupToggle: true });
                  }}
                />
              </Grid>
            </Grid>
          ) : (
            ""
          )}

          <div className={classes.AnomalyNrevenueChart}>
            <Box>
              <Grid
                style={{
                  padding: "24px 24px 24px 24px",
                }}
                container
                justifyContent="center"
                spacing={3}
              >
                <Grid item xs={6}>
                  <RevenueAndPowerChart
                    siteId={this.props.siteId}
                    siteData={this.state.siteData}
                    currency={this.state.currency}
                    anomalyComparisonData={this.state.anomalyComparisonData}
                    revenueLossTrigger={this.state.revenueLossTrigger}
                    setRevenueLossTrigger={(value) => {
                      return this.setState({ revenueLossTrigger: value });
                    }}
                    popupToggle={this.state.popupToggle}
                    setPopupToggle={(value) => {
                      return this.setState({ popupToggle: value });
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <AnomaliesCategoryBar
                    anomalyComparisonData={this.state.anomalyComparisonData}
                  />
                </Grid>
              </Grid>
            </Box>

            <div className={classes.severityHeadBox}>
              <div className={classes.severityHeadTxt}>
                ANOMALIES BY SEVERITY
              </div>
            </div>

            <Grid container style={{ padding: 20 }} justifyContent="center">
              <Grid item xs={3}>
                <Grid className={classes.sevHeader}>
                  <Grid className={classes.anomalyDivider} />
                  <Typography className={classes.anomalyHeadText}>
                    <Trans>Anomalies</Trans>
                  </Typography>
                </Grid>
              </Grid>

              <Grid item xs={9}>
                <Grid container className={classes.cardLoop}>
                  {this.state.allPlantData &&
                    this.state.allPlantData.map((data) => {
                      return (
                        <Grid
                          key={data.inspection_date}
                          item
                          xs={this.state.allPlantData.length === 2 ? 6 : 4}
                        >
                          <Grid
                            container
                            alignItems="center"
                            justifyContent="center"
                            className={classes.inspDateContainer}
                          >
                            Inspection:{" "}
                            {moment(data.inspection_date).format("D MMM, YYYY")}
                          </Grid>

                          <Grid
                            container
                            alignItems="center"
                            justifyContent="center"
                            className={classes.sevChart}
                            style={{ height: 147 }}
                          >
                            <HorizontalSevBarChart
                              getTotalDefectedModules={
                                data.total_defected_modules
                              }
                              getChartData={data.severity_list}
                              showStats={this.state.showStats}
                              chartType="Severity"
                            />
                          </Grid>
                        </Grid>
                      );
                    })}
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ paddingBottom: "4px" }}>
                <ComparisonTable
                  plantData={this.state.allPlantData}
                  defectList={this.state.allDefectList}
                  siteId={this.props.siteId}
                  revenueLossTrigger={this.state.revenueLossTrigger}
                  setRevenueLossTrigger={(value) => {
                    return this.setState({ revenueLossTrigger: value });
                  }}
                  popupToggle={this.state.popupToggle}
                  setPopupToggle={(value) => {
                    return this.setState({ popupToggle: value });
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(withTranslation()(InspectionComp));