import {
  Grid,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  withStyles,
} from "@material-ui/core";
import {
  cellValues,
  inspTableCellObj,
} from "../../constants/dashboardConstants";

import { Link } from "react-router-dom";
import NewLoader from "../../layout/newLoader";
import React from "react";
import { Trans } from "react-i18next";
import i18n from "i18next";
import moment from "moment";
import shortid from "shortid";
import styles from "./dashboard.styles";

//Inspection table
class InspectionTable extends React.Component {
  state = {
    order: "desc",
    orderBy: "inspection_date",
    shouldAnimate: true,
  };

  //Called the sorting function with the required parameters
  getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => {
          return this.descendingComparator(a, b, orderBy, order);
        }
      : (a, b) => {
          return -this.descendingComparator(a, b, orderBy, order);
        };
  };

  //Handles sorting of table data
  handleSortRequest = (cellId) => {
    this.setState({ shouldAnimate: false });
    const isAsc = this.state.order === "asc" && this.state.orderBy === cellId;
    this.setState({ order: isAsc ? "desc" : "asc", orderBy: cellId });
  };

  //Defined the function to sort the data based on the given inputs
  descendingComparator = (a, b, orderBy) => {
    if (orderBy === "inspection_date") {
      const d1 = moment(a[orderBy]);
      const d2 = moment(b[orderBy]);
      if (d1 > d2) return -1;
      else if (d2 > d1) return 1;
      return 0;
    }

    if (!b[orderBy] || b[orderBy] < a[orderBy]) {
      return -1;
    } else if (!a[orderBy] || b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  //Defined the function to sort the data based on the number of anomalies
  stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => {
      return [el, index];
    });
    stabilizedThis.sort((a, b) => {
      let order = 0;
      if (this.state.orderBy === "count")
        order = comparator(a[0].anomalies, b[0].anomalies);
      else order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => {
      return el[0];
    });
  };

  createTableCells = (data, cellValues) => {
    const { classes } = this.props;

    return cellValues.map((cellValue) => {
      return (
        <TableCell key={shortid.generate()}>
          {cellValue.link ? (
            <Link to={data.site_href}>
              <span style={styles.linkTxt}>{data[cellValue.value]}</span>
            </Link>
          ) : (
            <span style={styles[cellValue.style]}>
              {cellValue.value === "dc_capacity" ? (
                `${data[cellValue.value]} MWp`
              ) : cellValue.value === "inspection_date" ? (
                moment(data[cellValue.value]).format("D MMM, YYYY")
              ) : cellValue.value === "anomalies.count" ? (
                <Grid container style={styles.extraBoldTxt}>
                  <LinearProgress
                    classes={{
                      root: classes.linearProgress,
                      bar: this.state.shouldAnimate
                        ? classes.linearProgressColor
                        : classes.shouldNotAnimate,
                    }}
                    variant="determinate"
                    value={
                      (data.anomalies.count / this.props.maxAnomalies) * 100
                    }
                  />
                  <Grid item className={classes.countUI}>
                    {data.anomalies.count}
                  </Grid>
                </Grid>
              ) : (
                i18n.t(data[cellValue.value])
              )}
            </span>
          )}
        </TableCell>
      );
    });
  };

  //This renders the inspection table that is displayed on the dashboard
  render() {
    const { classes, inspections_table } = this.props;
    return (
      <div className={classes.InspTableContainer}>
        <Table stickyHeader>
          <TableHead data-intercom-target="all-inspection-heading">
            <TableRow>
              {inspTableCellObj.map((data, id) => {
                return (
                  <TableCell
                    key={id}
                    style={inspTableCellObj[0] && styles.tableHead}
                    classes={id > 0 && { stickyHeader: classes.stickTable }}
                  >
                    <TableSortLabel
                      classes={{ root: classes.tableHead }}
                      active={this.state.orderBy === data.orderBy}
                      direction={
                        this.state.orderBy === data.orderBy
                          ? this.state.order
                          : "asc"
                      }
                      onClick={() => {
                        return this.handleSortRequest(data.orderBy);
                      }}
                    >
                      <Trans>{data.Value}</Trans>
                    </TableSortLabel>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          
          <TableBody>
            {inspections_table ? (
              this.stableSort(
                inspections_table.inspection_list,
                this.getComparator(this.state.order, this.state.orderBy)
              ).map((data) => {
                return (
                  <TableRow hover key={shortid.generate()}>
                    {this.createTableCells(data, cellValues)}
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell
                  align="center"
                  style={{ textAlign: "center" }}
                  colSpan={6}
                >
                  <Grid
                    container
                    style={{ minHeight: 300 }}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <NewLoader />
                  </Grid>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    );
  }
}

export default withStyles(styles)(InspectionTable);
