import { FormControl, IconButton } from "@material-ui/core";

import OutlinedInput from "@material-ui/core/OutlinedInput";
import React from "react";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { styles } from "./myProfile.style";
import { withStyles } from "@material-ui/core/styles";

function CustomFormControl({
  passwordState,
  value,
  id,
  endadornmentHandler,
  onChangeHandler,
  classes
}) {
  return (
    <FormControl
      className={(classes.margin, classes.textField)}
      variant="outlined"
    >
      <OutlinedInput
        style={{ height: "50px", marginLeft: 20 }}
        id="outlined-adornment-password"
        type={passwordState ? "text" : "password"}
        value={value}
        onChange={onChangeHandler}
        endadornment={
          <IconButton id={id} onClick={endadornmentHandler} edge="end">
            {passwordState ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        }
      />
    </FormControl>
  );
}

export default withStyles(styles)(CustomFormControl);
