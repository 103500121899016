import React, { useEffect, useState } from "react";

import Dropdown from "../common/customStyle/CustomDropdown";
import LayersArrowIcon from "../../assets/img/layersArrow.svg";
import LayersIcon from "../../assets/img/layersDDBtn.svg";
import layoutIcon from "../../assets/img/layoutIcon.svg";
import rgbIcon from "../../assets/img/rgbIcon.png";
import styles from "./defectMap.style";
import thermalIcon from "../../assets/img/thermalIcon.png";
import { withStyles } from "@material-ui/core";

//map base layer
const BaseLayers = (props) => {
  const [selectedBaseMap, setSelectedBaseMap] = useState(null);

  const { classes } = props;

  //for baseMap and filtering tile layers.
  const setBaseMap = () => {
    const baseNames = {};
    let baseNameTitle = {};
    let rgbMapUrl = [];
    let layoutMapUrl = [];
    let thermalMapUrl = [];
    let imageMapUrls = "";

    //For raster tile layers
    if (props.tiling_metadata.raster_url_list.length) {
      props.tiling_metadata.raster_url_list.filter((base) => {
        if (base.rgb_tile_layer) {
          baseNames.rgb = true;
          return rgbMapUrl.push(base.rgb_tile_layer);
        } else if (base.layout_tile_layer) {
          baseNames.layout = true;
          return layoutMapUrl.push(base.layout_tile_layer);
        } else if (base.thermal_tile_layer) {
          baseNames.thermal = true;
          return thermalMapUrl.push(base.thermal_tile_layer);
        }
      });

      // get all layers in single object

      // mapUrl = baseNames.rgb
      //   ? props.allRasterLayer.rgb_tile_layer
      //   : baseNames.thermal
      //   ? props.allRasterLayer.thermal_tile_layer
      //   : props.allRasterLayer.layout_tile_layer;

      baseNameTitle = baseNames.rgb
        ? "rgb"
        : baseNames.thermal
        ? "thermal"
        : "layout";

      setSelectedBaseMap(baseNameTitle);
      if (baseNameTitle === "rgb") {
        props.baseLayerId(rgbMapUrl);
      } else if (baseNameTitle === "layout") {
        props.baseLayerId(layoutMapUrl);
      } else {
        props.baseLayerId(thermalMapUrl);
      }
    } else {
      // For image layers
      if (props.mapUrl.rgb_map_image_url) baseNames.rgb = true;
      else if (props.mapUrl.thermal_map_image_url) baseNames.thermal = true;
      else if (props.mapUrl.layout_map_image_url) baseNames.layout = true;
      imageMapUrls = baseNames.rgb
        ? props.mapUrl.rgb_map_image_url
        : baseNames.thermal
        ? props.mapUrl.thermal_map_image_url
        : props.mapUrl.layout_map_image_url;

      baseNameTitle = baseNames.rgb
        ? "rgb"
        : baseNames.thermal
        ? "thermal"
        : "layout";

      setSelectedBaseMap(baseNameTitle);
      props.baseLayerId(imageMapUrls);
    }
  };

  //Call setBaseMap function on load
  useEffect(() => {
    setBaseMap();
  }, []);

  //Count the number of base layers available for the selected site
  const viewsCount = () => {
    const rasterViewsCount =
      props.allRasterLayer &&
      Object.values(props.allRasterLayer).filter((item) => {
        return item.length;
      }).length;
    const mapUrlViewsCount =
      props.mapUrl &&
      Object.values(props.mapUrl).filter((item) => {
        return item !== null;
      }).length;

    return { rasterViewsCount, mapUrlViewsCount };
  };

  //To access map view
  const clickMapView = (value) => {
    // let apiKey = "mapUrl";
    // if (props.is_tiling) apiKey = "allRasterLayer";
    let mapUrl = [];

    // Set selected Base layer name and mapbox id
    if (props.is_tiling) {
      // if (value === "rgb") props.baseLayerId(props[apiKey].rgb_tile_layer);
      // else if (value === "layout")
      //   props.baseLayerId(props[apiKey].layout_tile_layer);
      // else props.baseLayerId(props[apiKey].thermal_tile_layer);
      props.tiling_metadata.raster_url_list.filter((base) => {
        if (value === "rgb") {
          mapUrl.push(base.rgb_tile_layer);
          return;
        } else if (value === "layout") {
          mapUrl.push(base.layout_tile_layer);
          return;
        } else if (value == "thermal") {
          mapUrl.push(base.thermal_tile_layer);
          return;
        }
      });

      props.baseLayerId(mapUrl);
    } else {
      if (value === "rgb") props.baseLayerId(props.mapUrl.rgb_map_image_url);
      else if (value === "layout")
        props.baseLayerId(props.mapUrl.layout_map_image_url);
      else props.baseLayerId(props.mapUrl.thermal_map_image_url);
    }

    setSelectedBaseMap(value);
  };

  //Set the base layer for the selected site
  return props.is_tiling &&
    props.allRasterLayer &&
    viewsCount().rasterViewsCount > 1 ? (
    <Dropdown
      width="52px"
      height="36px"
      background="#fff"
      border="1px solid #F0F4FA 8px"
      padding="10px"
      closeOnClick
      position="absolute"
      top={!props.isMobile ? "8px" : "104px"}
      left={!props.isMobile ? "92px" : ""}
      right={props.isMobile ? "10px" : ""}
      outerMenuClass={
        viewsCount().rasterViewsCount < 3
          ? classes.dropdownMenu2
          : classes.dropdownMenu
      }
      buttonLabel={
        <div className={classes.dropdownBtn}>
          <img src={LayersIcon} alt="layers" />
          <img src={LayersArrowIcon} alt="dropdown" />
        </div>
      }
      menuItems={
        <div className={classes.dropdownDiv}>
          <p style={{ color: "#8D95A2", padding: "0px 16px" }}>SITE OVERLAY</p>

          {props.allRasterLayer.layout_tile_layer &&
          props.allRasterLayer.layout_tile_layer.length ? (
            <div
              className={classes.menuItem}
              onClick={() => {
                clickMapView("layout");
              }}
              style={{
                color: selectedBaseMap === "layout" ? "#5B48FB" : "#1D2D47",
              }}
              role="button"
              tabIndex={"0"}
              aria-hidden="true"
            >
              <img src={layoutIcon} alt="layout" />
              <p>Layout Drawing</p>
            </div>
          ) : (
            ""
          )}

          {props.allRasterLayer.thermal_tile_layer &&
          props.allRasterLayer.thermal_tile_layer.length ? (
            <div
              className={classes.menuItem}
              onClick={() => {
                clickMapView("thermal");
              }}
              style={{
                color: selectedBaseMap === "thermal" ? "#5B48FB" : "#1D2D47",
              }}
              role="button"
              tabIndex={"-1"}
              aria-hidden="true"
            >
              <img
                src={thermalIcon}
                style={{ width: "16px", height: "16px" }}
                alt="thermal"
              />
              <p>Thermal Orthomosaic</p>
            </div>
          ) : (
            ""
          )}

          {props.allRasterLayer.rgb_tile_layer &&
          props.allRasterLayer.rgb_tile_layer.length ? (
            <div
              className={classes.menuItem}
              onClick={() => {
                clickMapView("rgb");
              }}
              style={{
                color: selectedBaseMap === "rgb" ? "#5B48FB" : "#1D2D47",
              }}
              role="button"
              tabIndex={"-2"}
              aria-hidden="true"
            >
              <img
                src={rgbIcon}
                style={{ width: "16px", height: "16px" }}
                alt="rgb"
              />
              <p>RGB Orthomosaic</p>
            </div>
          ) : (
            ""
          )}
        </div>
      }
    />
  ) : !props.is_tiling && viewsCount().mapUrlViewsCount > 1 ? (
    <Dropdown
      width="52px"
      height="36px"
      background="#fff"
      border="1px solid #F0F4FA 8px"
      padding="10px"
      closeOnClick
      position="absolute"
      top={!props.isMobile ? "8px" : "104px"}
      left={!props.isMobile ? "92px" : ""}
      right={props.isMobile ? "10px" : ""}
      outerMenuClass={classes.dropdownMenu}
      buttonLabel={
        <div className={classes.dropdownBtn}>
          <img src={LayersIcon} alt="layers" />
          <img src={LayersArrowIcon} alt="dropdown" />
        </div>
      }
      menuItems={
        <div className={classes.dropdownDiv}>
          <p style={{ color: "#8D95A2", padding: "0px 16px" }}>SITE OVERLAY</p>
          {props.mapUrl.layout_map_image_url &&
          (props.mapUrl.thermal_map_image_url ||
            props.mapUrl.rgb_map_image_url) ? (
            <div
              className={classes.menuItem}
              onClick={() => {
                clickMapView("layout");
              }}
              style={{
                color: selectedBaseMap === "layout" ? "#5B48FB" : "#1D2D47",
              }}
              role="button"
              tabIndex={"-3"}
              aria-hidden="true"
            >
              <img src={layoutIcon} alt="Layout Layer" />
              <p>Layout Drawing</p>
            </div>
          ) : (
            ""
          )}

          {props.mapUrl.thermal_map_image_url &&
          (props.mapUrl.layout_map_image_url ||
            props.mapUrl.rgb_map_image_url) ? (
            <div
              className={classes.menuItem}
              onClick={() => {
                clickMapView("thermal");
              }}
              style={{
                color: selectedBaseMap === "thermal" ? "#5B48FB" : "#1D2D47",
              }}
              role="button"
              tabIndex={"-4"}
              aria-hidden="true"
            >
              <img
                src={thermalIcon}
                style={{ width: "16px", height: "16px" }}
                alt="Thermal Layer"
              />
              <p>Thermal Orthomosaic</p>
            </div>
          ) : (
            ""
          )}

          {props.mapUrl.rgb_map_image_url &&
          (props.mapUrl.thermal_map_image_url ||
            props.mapUrl.layout_map_image_url) ? (
            <div
              className={classes.menuItem}
              onClick={() => {
                clickMapView("rgb");
              }}
              style={{
                color: selectedBaseMap === "rgb" ? "#5B48FB" : "#1D2D47",
              }}
              role="button"
              tabIndex={"-5"}
              aria-hidden="true"
            >
              <img
                src={rgbIcon}
                style={{ width: "16px", height: "16px" }}
                alt="RGB Layer"
              />
              <p>RGB Orthomosaic</p>
            </div>
          ) : (
            ""
          )}
        </div>
      }
    />
  ) : null;
};

export default withStyles(styles)(BaseLayers);
