export default {
  root1: {
    flexGrow: 1,
    padding: 64,
  },
  paper1: {
    backgroundColor: "white",
    width: 500,
    height: 420,
    boxShadow: "none",
  },
  forgotPassText: {
    float: "right",
    color: "#7363FB",
    textDecoration: "underline",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    marginTop: 10,
  },
  submit1: {
    margin: "2px 0 8px",
    fontSize: "16px",
    fontWeight: "bold",
    textTransform: "capitalize",
  },
  imgLogo: {
    position: "relative",
    top: "-10px",
    width: 200,
    "@media (max-width: 599px)": {
      position: "relative",
      top: -10,
      left: "4%",
      width: 200,
    },
    "@media (max-width: 399px)": {
      position: "relative",
      top: -10,
      left: "4%",
      width: 200,
    },
  },
  loginText: {
    fontSize: "20px",
    fontWeight: 700,
    fontFamily: "Montserrat",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 4,
    letterSpacing: "normal",
    color: "#21282d",
    marginTop: -20,
  },
  loginBtn: {
    margin: "20px 0",
    backgroundColor: "#fff",
    background: "linear-gradient(266.69deg,#5B4DD3 0.23%,#5B4DD3 99.28%)",
    boxShadow: "unset",
    borderRadius: "8px",
    width: "100%",
    height: "44px",
    textTransform: "uppercase",
    fontFamily: "Montserrat",
  },
  btnLoginLabel: {
    fontWeight: 600,
    fontSize: 14,
  },
  illustrationImg: {
    maxWidth: "100%",
    maxHeight: "100vh",
  },
  passText: {
    fontSize: 13,
    fontWeight: 500,
    fontStyle: "normal",
    fontFamily: "Montserrat",
    color: "#21282d",
    marginBottom: "-2%",
  },
  resetBtn: {
    margin: "20px 0",
    backgroundColor: "#fff",
    background: "linear-gradient(266.69deg, #5B4DD3 0.23%, #5B4DD3 99.28%)",
    boxShadow: "0px 2px 2px rgba(0,132,255,0.2)",
    borderRadius: "8px",
    width: "100%",
  },
  mobBox: {
    "@media (max-width: 1024px)": {
      marginTop: 125,
      padding: 10,
    },
    "@media (max-width: 600px)": {
      marginTop: 25,
      padding: 10,
    },
  },
  loginlabel: {
    color: "#21282d",
    fontWeight: 500,
    fontSize: 13,
    fontStyle: "normal",
    marginBottom: 0,
    marginTop: "15px",
  },
  loginInput: {
    "& input": {
      fontFamily: "Montserrat !important",
      fontSize: 12,
      fontStyle: "normal",
      fontWeight: 500,
      color: "#21282d",
      margin: "5px 2px 5px 2px",
    },
  },
  versionTxt: {
    position: "relative",
    top: "-19%",
    left: "51%",
    color: "#0000008a",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    "@media (max-width: 760px)": {
      left: "62%",
      position: "relative",
    },
  },
  loginOuter: {
    "@media (max-width : 650px)": {
      padding: 10,
    },
  },

  //web to mobile screen css.
  imgParent: {
    height: "380px",
    border: "1px solid white",
  },

  backgroundTrans: {
    background: "white",
    padding: "64px 0px 72px 0px",
  },

  solarImg: {
    "@media (max-width : 650px)": {
      width: "100vw",
    },
    borderColor: "white",
  },

  backgroundParent: {
    backgroundColor: "white",
    height: "100%",
  },

  zeitviewLogoMobile: {
    marginBottom: "40px",
  },

  playStoreOptions: {
    marginTop: "40px",
  },

  redirectText: {
    width: "311px",
    fontFamily: "Montserrat !important",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    color: "#2D343E",
    lineHeight: "24px",
    textAlign: "center",
  },
};
