const styles = {
  defcetNo: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHieght: "20px",
    color: "#1D2D47",
    marginBottom: "4px",
  },

  defectName: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHieght: "20px",
    color: "#1D2D47",
    marginBottom: "8px",
  },

  status: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "20px",
    color: "#1D2D47",
  },

  statusOuterBox: {
    width: "100px",
    height: "32px",
    border: "3px solid",
    borderColor: "#EFF0F2",
    borderRadius: "7px",
    marginBottom: "10px",
  },

  statusImg: {
    width: "18px",
    height: "18px",
    marginRight: "5px",
  },

  line: {
    width: "4px",
    height: "auto",
    minHeight: "127px",
    borderRadius: "50px",
    marginRight: "8px",
    marginLeft: "8px",
    marginBottom: "8px",
  },

  dot: {
    width: "4px",
    height: "4px",
    fontWeight: 500,
    fontSize: "12px",
    lineHieght: "20px",
    backgroundColor: "#C5C9D0",
    borderRadius: "10px",
    marginTop: "8px",
    marginLeft: "5px",
    marginRight: "5px",
  },
};

export default styles;
