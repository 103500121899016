import { RESET_ACTIVITY, SET_ACTIVITY } from "../constants/actionTypes";

//Initial state of the redux
const initialState = {
  activity: null,
};

//Create and export the reducer to set the activity
export const setActivity = (state = initialState) => {
  return (dispatch) => {
    dispatch({
      type: SET_ACTIVITY,
      payload: state,
    });
  };
};

//Create and export the reducer to get the activity
// skipcq Default parameters should be defined at the last JS-0106
export const fetchActivity = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case RESET_ACTIVITY:
      return {
        ...state,
        activity: null,
      };
    case SET_ACTIVITY:
      return {
        ...state,
        activity: payload,
      };
    default:
      return state;
  }
};
