import React from "react";
import { Trans } from "react-i18next";
import { Typography } from "@mui/material";

function CustomClear({ classes, Filter }) {
  return (
    <Typography className={classes.cancelBtn} onClick={Filter}>
      <Trans>Clear</Trans>
    </Typography>
  );
}

export default CustomClear;
