import {
  Card,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  Typography,
  withStyles,
} from "@material-ui/core";

import CustomButton from "../common/customStyle/Button";
import CustomDropdown from "../common/customStyle/Dropdown";
import CustomReportDownload from "../common/customStyle/ReportDownload";
import DownloadIcon from "../../assets/img/whiteDownload.svg";
import { Navigate } from "react-router-dom";
import React from "react";
import { Trans } from "react-i18next";
import _ from "lodash";
import { callSnackbarAction } from "../../reducers/snackbar";
import downloadImg from "../../assets/img/download.svg";
import i18n from "i18next";
import reportListItems from "../common/customStyle/reportType.json";
import { store } from "../../store";
import styles from "./dashboard.styles";

//To handle report downloads
class DownloadReport extends React.Component {
  state = {
    reportSite: null,

    selectedReport: reportListItems.report_list[0].value,
    reportDialog: false,
    selectedPlant: null,
    selectedReportName: reportListItems.report_list[0].name,
    selectedReportFormat: reportListItems.report_list[0].type,
    redirect: false,
    isDownloading: false,
  };

  //These functions are called whenever any props or state changes
  componentDidUpdate(prevProps) {
    //Update the plant and the ste details
    if (
      this.props.siteListData &&
      this.props.portfolio_metadata &&
      prevProps.portfolio_metadata !== this.props.portfolio_metadata &&
      !this.state.reportSite
    ) {
      const sortedPortFolioMeta = _.sortBy(
        this.props.portfolio_metadata?.plant_list,
        "plant_name"
      );
      this.setState({
        reportSite: sortedPortFolioMeta[0].plant_name,

        selectedPlant: this.props.siteListData[0],
      });
    }
  }

  //Handles site details for the selected plant
  downloadSite = (event) => {
    const filterPlant = this.props.siteListData.find((pId) => {
      return pId.plant_name === event.target.value;
    });

    this.setState({
      selectedReport: reportListItems.report_list[0].value,
      reportSite: event.target.value,
      selectedPlant: filterPlant,
      selectedReportName: null,
      selectedReportFormat: null,
    });
  };

  //Handles the download of report based on the selected report type, plant, site, language and format
  downloadReport = async (value, initialValue) => {
    let { selectedPlant } = this.state;

    if (!selectedPlant) {
      selectedPlant = initialValue;
    }

    //redirect to reports page
    this.props.navigate("/reports");

    const reportObj = {
      plant_id: selectedPlant?.plant_id,
      section_id: null,
      report_type: this.state.selectedReport,
      orientation: value.orientation,
      plant_inspection_id: selectedPlant?.inspection_list[0]?.plant_inspection_id,
      language: value.language,
    };

    this.props.reportObj(reportObj);

    window.history.pushState("/dashboard", null, null);
  };

  //Handles the dialog box to select the report type, language, format and other options
  handleDialog = () => {
    this.setState({ isDownloading: true });
    if (this.state.selectedReport) {
      const filterFormat = reportListItems.report_list.find((d) => {
        return d.value === this.state.selectedReport;
      });
      if (filterFormat.type === "pdf") this.setState({ reportDialog: true });
      else {
        const otherValue = {
          orientation: "portrait",
          language: "english",
        };
        this.downloadReport(otherValue);
      }
    } else this.props.callSnackbar("Please select Site and Report Type");
  };

  //This renders the download report page
  render() {
    const { classes } = this.props;

    let siteListItems = [];
    // Dropdown object for Site
    if (this.props.portfolio_metadata) {
      this.props.portfolio_metadata.plant_list.map((objSite) => {
        return siteListItems.push({
          name: objSite.plant_name,
          value: objSite.plant_name,
          id: objSite.plant_name,
        });
      });

      siteListItems = _.sortBy(siteListItems, "name");
    }

    //To get selected value
    const getSelectValue = () => {
      if (siteListItems.length > 6) {
        return this.state.reportSite
          ? this.state.reportSite
          : siteListItems[0]?.value;
      }

      return this.state.reportSite ? this.state.reportSite : "";
    };

    if (this.state.redirect) return <Navigate replace to="/reports" />;

    return (
      <Card style={styles.carddownload}>
        <CardContent style={{ ...styles.contentMob, padding: 0 }}>
          <Typography style={styles.chartHeading}>
            <Trans>Download Reports</Trans>
          </Typography>
          <Divider />
          <Grid container style={{ height: 500 }}>
            <Grid item xs={12} className={classes.padRL10}>
              <Typography className={classes.reportText}>
                <Trans>Site</Trans>
              </Typography>
              <CustomDropdown
                defaultValue={siteListItems[0]?.value}
                selectValue={getSelectValue()}
                onHandleSelectChange={(e) => {
                  this.downloadSite(e);
                }}
                menuItems={siteListItems}
                fullWidth
              />

              <Typography className={classes.reportText}>
                <Trans>Report Type</Trans>
              </Typography>
              <CustomDropdown
                selectValue={
                  this.state.selectedReport ? this.state.selectedReport : "all"
                }
                onHandleSelectChange={(e) => {
                  const index = reportListItems.report_list.findIndex(
                    (item) => {
                      return item.value === e.target.value;
                    }
                  );

                  this.setState({
                    selectedReport: e.target.value,
                    selectedReportName: reportListItems.report_list[index].name,
                    selectedReportFormat:
                      reportListItems.report_list[index].type,
                  });
                }}
                menuItems={reportListItems.report_list}
                fullWidth
              />
            </Grid>
            <Grid
              item
              xs={12}
              className={classes.padRL10}
              style={{ marginTop: "14px" }}
            >
              <CustomButton
                text={
                  this.state.isDownloading
                    ? `${i18n.t("Downloading")}...`
                    : i18n.t("Download")
                }
                disabled={this.state.isDownloading}
                onClick={() => {
                  return this.handleDialog();
                }}
                btnIcon={DownloadIcon}
                margin="8px"
              />
              <CustomReportDownload
                reportDialog={this.state.reportDialog}
                closeReportDialog={() => {
                  this.setState({ reportDialog: false, isDownloading: false });
                }}
                downloadReportHandler={(v) => {
                  store.dispatch(
                    callSnackbarAction(
                      "Reports generation is in progress...",
                      "warning"
                    )
                  );
                  return this.downloadReport(v, this.props.siteListData[0]);
                }}
                siteName={
                  this.state.reportSite
                    ? this.state.reportSite
                    : siteListItems[0]?.value
                }
                reportType={this.state.selectedReportName}
                reportFormat={this.state.selectedReportFormat}
              />
            </Grid>
            <Grid
              item
              xs={12}
              className={classes.pad10}
              style={{ marginTop: "-14px" }}
            >
              <CardMedia component="img" image={downloadImg} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles)(DownloadReport);
