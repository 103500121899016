import { Grid, Typography, withStyles } from "@material-ui/core";

import ProKeyImg from "./img/prokey.svg";
import React from "react";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import styles from "./product.style";

//Pro plan blur
const ProBlur = ({ classes, children, product, text, onHandleShowPlans }) => {
  // NOTE: product is parsed from react-redux connect
  //Blur the pro content for basic users
  return (
    <Grid>
      <Grid
        style={{
          filter:
            product?.product_type === "Basic" ? "blur(20px)" : "blur(0px)",
        }}
      >
        {children}
      </Grid>
      {product?.product_type === "Basic" && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className={classes.proUpgrade}>
            <img
              src={ProKeyImg}
              alt="proKeyImg"
              style={{ marginRight: "16px" }}
            />
            <div>
              <Typography className={classes.proTypography}>
                <Trans>{text}</Trans>
              </Typography>
              <Typography
                className={classes.proTypographyLink}
                onClick={() => {
                  return onHandleShowPlans(true);
                }}
              >
                <Trans>Upgrade Now</Trans>
              </Typography>
            </div>
          </div>
        </div>
      )}
    </Grid>
  );
};

//updating current product state
const mapStateToProps = (state) => {
  return {
    product: state.product,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(ProBlur));
