const styles = {
  title: {
    fontFamily: "Montserrat",
    fontSize: 20,
    fontWeight: 700,
    marginTop: "3px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.2,
    color: "#21282d",
    "@media only screen and (max-width: 600px)": {
      marginLeft: 35,
    },
    "@media only screen and (max-width: 768px)": {
      marginLeft: 45,
    },
  },
  appbar: {
    backgroundColor: "white",
    color: "black",
    boxShadow: "none",
  },
  button: {
    letterSpacing: "normal",
    fontWeight: 500,
    background: "linear-gradient(266.69deg, #59AFFF 0.23%, #0882F3 99.28%)",
    fontFamily: "Montserrat",
    color: "#FFFFFF",
  },
  portfolio: {
    fontFamily: "Montserrat",
    background: "#F0F4FA",
    color: "#21282d",
    fontWeight: 500,
    fontStretch: "normal",
    padding: "3px",
    paddingTop: "3px",
    fontStyle: "normal",
    letterSpacing: "normal",
    borderRadius: "8px",
    height: "24px",
    minWidth: "74px",
    textAlign: "center",
    marginLeft: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  chartHeading: {
    fontFamily: "Montserrat",
    fontWeight: "600",
    fontStyle: "normal",
    fontSize: "14px",
    textTransform: "uppercase",
    color: "#536471",
    height: 64,
    display: "flex",
    alignItems: "center",
    paddingLeft: 16,
    "@media only screen and (max-width: 600px)": {
      height: 50,
    },
  },
  InspTableContainer: {
    maxHeight: 400,
    overflow: "auto",
  },
  togglebtgroup: {
    display: "flex",
    alignItems: "center",
    marginTop: "15px",
    "@media only screen and (max-width: 600px)": {
      marginLeft: 16,
      marginTop: "-5px",
    },
  },
  powerTogglebt: {
    display: "flex",
    alignItems: "center",
    marginTop: 10,
  },
  contentMob: {
    paddingBottom: 12,
    padding: 0,
    position: "relative",
    "@media only screen and (max-width: 600px)": {
      padding: "12px 8px",
    },
  },
  grid: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    paddingRight: "54px",
  },
  filter: {
    width: "21px",
    height: "21px",
    borderRadius: "8px",
    background: "#5B4DD3",
    marginLeft: "8px",
    color: "white",
  },
  internalbenchmark: {
    width: "100%",
    height: 450,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  powerLossCard: {
    borderRadius: 8,
    boxShadow: "0px 4px 12px rgba(78, 89, 109, 0.05)",
    height: "540px",
    minHeight: "500px",
  },
  card: {
    height: "100%",
    borderRadius: 8,
    boxShadow: "0px 4px 12px rgba(78, 89, 109, 0.05)",
  },
  cardCount: {
    fontStyle: "normal",
    fontWeight: 600,
    lineWeight: "20px",
    color: "#08001D",
  },
  stackchart: {
    overflow: "hidden",
    minWidth: 356,
    padding: 12,
  },
  showfilter: {
    position: "absolute",
    right: "26px",
    top: "50px",
    zIndex: 1,
    "@media only screen and (max-width: 400px)": {
      top: "150px",
    },
    "@media only screen and (max-width: 600px)": {
      top: "150px",
    },
    "@media only screen and (max-width: 508px)": {
      left: "-2px",
      right: "auto",
    },
  },
  selectedName: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "20px",
    color: "#536471",
  },
  filterchip1: {
    margin: "0px 12px",
    display: "block",
    whiteSpace: "nowrap",
    overflow: "auto",
    "@media screen and (max-width: 760px)": {
      margin: 0,
    },
  },
  severityBox: {
    height: 8,
    display: "inline-block",
    width: 8,
    borderRadius: "50%",
  },
  severityBoxes: {
    height: 6,
    display: "inline-block",
    width: 6,
    borderRadius: "50%",
  },
  sevCount: {
    fontSize: 16,
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.25,
    letterSpacing: "normal",
    color: "#21282d",
  },
  cancelBtn: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    marginRight: "8px",
    color: "#5B4DD3",
    cursor: "pointer",
  },
  severityText: {
    margin: "0 5px",
    fontFamily: "Montserrat",
    fontSize: 12,
    fontWeight: 500,
    color: "#536471",
    fontStyle: "normal",
  },
  reportText: {
    margin: "16px 2px 2px",
    fontFamily: "Montserrat",
    fontSize: 12,
    fontWeight: 500,
    color: "#536471",
    fontStyle: "normal",
  },
  padRL10: {
    padding: "0 10px",
  },
  pad16LCB: {
    "&:last-child": {
      paddingBottom: "19px",
    },
  },
  pad12: {
    padding: 12,
  },
  pad10: {
    padding: 10,
  },
  noDividerTable: {
    border: 0,
    padding: "9px 0px",
  },
  reportBtn: {
    background: "linear-gradient(266.69deg, #59AFFF 0.23%, #0882F3 99.28%)",
    maxWidth: "151px",
    color: "#FFF",
    fontFamily: "Montserrat",
  },
  tableHead: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "12px",
    fontStyle: "normal",
    color: "#536471",
    backgroundColor: "#fff",
    "& svg": {
      opacity: 0.2,
    },
  },
  revenueInfo: {
    maxWidth: "149px",
    color: "#ffffff",
    background: "#08001D",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
    borderRadius: "5px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "11px",
    lineHeight: "16px",
  },
  linkTxt: {
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontStyle: "normal",
    color: "#5B4DD3",
    fontWeight: 600,
    cursor: "pointer",
  },
  boldTxt: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "12px",
    fontStyle: "normal",
    color: "#21282d",
  },
  extraBoldTxt: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "12px",
    fontStyle: "normal",
    color: "#21282d",
    position: "relative",
  },
  cardSpace: {
    padding: 10,
  },
  select: {
    borderRadius: "8px",
    "&::before": {
      borderBottom: "none",
    },
    "&:hover:before": {
      borderBottom: "none",
    },
    "&:selection:before": {
      borderBottom: "none",
    },
    position: "relative",
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "12px",
    color: "#08001D",
    width: "100%",
    "& div": {
      height: "28px",
      alignItems: "center",
      display: "flex",
      backgroundColor: "rgba(0,0,0,0)",
      color: "#08001D",
    },
    "& :focus": {
      backgroundColor: "rgba(0,0,0,0)",
    },
    "& svg": {
      color: "#08001D",
    },
    "& fieldset": {
      borderColor: "#DDE5EC !important",
    },
  },
  outerReport: {
    padding: 35,
  },
  scrollBar: {
    overflow: "auto",
  },
  spacing: {
    padding: "12px 24px",
  },
  linearProgress: {
    height: 18,
    width: 280,
    backgroundColor: "#F4F4FB",
    borderRadius: 4,
  },
  linearProgressColor: {
    backgroundColor: "#5B4DD3",
  },
  shouldNotAnimate: {
    backgroundColor: "#0084FF",
    transition: "none",
  },
  countUI: {
    position: "absolute",
    left: 0,
    right: 0,
    textAlign: "center",
  },
  brdrBtmNone: {
    borderBottom: "none",
  },
  anomaliesTable: {
    alignSelf: "center",
    maxHeight: 430,
    overflowX: "hidden",
    overflow: "auto",
    padding: 10,
    width: "-webkit-fill-available",
    "@media only screen and (max-width: 959px)": {
      display: "none",
    },
  },

  innerPaddSkeleton: {
    display: "flex",
    justifyContent: "space-between",
    gap: -20,
    "@media only screen and (max-width: 1000px)": {
      display: "flex",
      flexDirection: "column",
    },
  },
  skelRow: {
    "@media only screen and (max-width: 1000px)": {
      display: "flex",
      justifyContent: "center",
      gap: 10,
    },
  },
  medaDataMobView: {
    "@media only screen and (max-width: 1000px)": {
      display: "block",
      justifyContent: "center",
      gap: 10,
    },
  },
  skelCol: {
    display: "flex",
    flexDirection: "column",
  },
  outerPadd: {
    maxWidth: 1200,
    height: "100%",
    overflowX: "hidden",
    overflowY: "auto",
    margin: "auto",
    "@media only screen and (max-width: 1400px)": {
      width: "-webkit-fill-available",
      position: "fixed",
    },
    "@media only screen and (max-width: 760px)": {
      padding: 0,
    },
  },
  internBenchContainer: {
    width: "-webkit-fill-available",
    overflow: "hidden",
    marginBottom: "90px",
  },
  inspectionContainer: {
    maxHeight: 464,
    marginBottom: 12,
    padding: "0 12px",
  },
  stickTable: {
    backgroundColor: "#fff",
  },
  sevCard: {
    borderRadius: 8,
    boxShadow: "0px 14px 12px rgba(78, 89, 109, 0.05)",
    minHeight: 170,
  },
  benchmarkToggle: {
    border: "1px solid #0084FF",
    marginLeft: "24px",
    width: "fit-content",
    height: "32px",
    borderRadius: "8px",
    display: "flex",
    textAlign: "center",
    color: "#536471",
    justifyContent: "space-between",
    "@media only screen and (max-width: 600px)": {
      marginLeft: "16px",
    },
  },
  benchmarkBar: {
    display: "flex",
    "@media only screen and (max-width: 600px)": {
      flexWrap: "wrap",
    },
    "@media only screen and (max-width: 430px)": {
      display: "flex",
      flexDirection: "column",
    },
  },
  benchmarkBtn: {
    width: "fit-content",
    whiteSpace: "nowrap",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 4px 12px rgba(78, 89, 109, 0.05)",
    borderRadius: "8px",
    outline: "none",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: "#536471",
    border: "none",
    "&:hover": {
      backgroundColor: "#FFFFFF",
    },
    "&:focus": {
      backgroundColor: "#FFFFFF",
      outline: "none",
    },
  },
  benchmarkLabel: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "20px",
    textTransform: "none",
  },
  benchmarkMwpSelected: {
    borderTopRightRadius: "0px !important",
    borderBottomRightRadius: "0px !important",
    height: "32px",
    backgroundColor: "#0084FF !important",
    borderRadius: "8px !important",
    color: "#FFFFFF !important",
    alignSelf: "center",
    border: "none",
    outline: "none !important",
  },
  benchmarkStatusSelected: {
    borderTopLeftRadius: "0px !important",
    borderBottomLeftRadius: "0px !important",
    height: "32px",
    backgroundColor: "#0084FF !important",
    borderRadius: "8px !important",
    color: "#FFFFFF !important",
    alignSelf: "center",
    border: "none",
    outline: "none !important",
  },
  toggleRoot: {
    height: 30,
    width: 60,
    background: "#F0F4FA",
    borderRadius: 8,
  },
  toggleBtn: {
    height: 30,
    width: 30,
  },
  toggleLabel: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 14,
    lineHeight: "20px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    textTransform: "uppercase",
    color: "#536471",
  },
  inspHeader: {
    fontFamily: "Montserrat",
    fontWeight: "600",
    fontStyle: "normal",
    fontSize: "14px",
    textTransform: "uppercase",
    color: "#536471",
    height: 64,
    display: "flex",
    alignItems: "center",
    paddingLeft: 24,
  },
  sevTxt: {
    fontFamily: "Montserrat",
    fontWeight: "600",
    fontStyle: "normal",
    fontSize: "14px",
    textTransform: "uppercase",
    color: "#536471",
    display: "flex",
    alignItems: "center",
  },
  filterButton: {
    background: "#FFFFFF",
    border: " 1px solid #DDE5EC",
    boxSizing: "border-box",
    borderRadius: "8px",
    marginLeft: "16px",
    "&:focus": {
      outline: "none !important",
    },
  },
  image: {
    marginLeft: "8px",
  },
  filterChip: {
    background: "rgba(0, 132, 255, 0.05)",
    borderRadius: "8px",
    border: 0,
    color: "#536471",
    padding: "15px 4px",
    margin: "3px 10px 3px 0px",
    fontWeight: 500,
  },
  btnImg: {
    marginRight: "2px",
    marginTop: "-3px",
  },
  revenueTooltip: {
    maxWidth: 150,
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 11,
    lineHeight: "16px",
    color: "#FFFFFF",
    margin: 12,
  },
  infoIcon: {
    color: "#C7CEDB",
    width: 18,
    height: 18,
  },
  anomaliesChartBox: {
    "@media only screen and (max-width: 600px)": {
      "& div": {
        height: "300px !important",
      },
    },
  },
  defectTypesBox: {
    display: "flex",
    flexWrap: "wrap",
    padding: "0px 50px 18px 50px",
    justifyContent: "center",
    "@media only screen and (max-width: 959px)": {
      display: "none",
    },
  },
  spinnerMedia: {
    "@media only screen and (minWidth: 959px)": {
      display: "none",
    },
  },
  defectTypes: {
    margin: "6px",
    fontFamily: "Montserrat",
    lineHeight: "16px",
  },
  infoIconStyle: {
    color: "#C7CEDB",
    cursor: "pointer",
    marginLeft: "3px",
    "@media only screen and (min-width: 960px)": {
      display: "none",
    },
  },
  anomaliesTooltip: {
    padding: "10px 10px 10px 16px",
    position: "absolute",
    top: 40,
    right: 28,
    zIndex: 1,
    maxHeight: 400,
    backgroundColor: "#fff",
    width: "auto",
    borderRadius: "5px",
    border: "1px solid #E4EAF5",
    boxShadow: "0px 4px 12px rgba(78, 89, 109, 0.05)",
    overflow: "auto",
    flexWrap: "nowrap",
    "@media only screen and (max-width: 600px)": {
      maxHeight: 300,
    },
  },
  MenuItem: {
    backgroundColor: "rgba(0,0,0,0) !important",
    width: "auto",
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "12px",
    color: "#536471",
  },
  lists: {
    "&:hover": {
      backgroundColor: "#F0F4FA !important",
    },
  },
  selectedList: {
    color: "#0084FF",
    backgroundColor: "#FFFFFF !important",
  },
  paperStyle: {
    border: "1px solid #DDE5EC",
    boxShadow: "0px 4px 12px rgba(78, 89, 109, 0.05)",
    borderRadius: "8px",
    marginLeft: "-2px",
    "@media only screen and (max-width: 600px)": {
      overflow: "auto",
      flexWrap: "nowrap",
      maxHeight: 300,
    },
  },
  inlineTitle: {
    "@media only screen and (max-width: 400px)": {
      "& button": {
        marginLeft: "2px !important",
        marginRight: "2px !important",
      },
    },
  },
  carddownload: {
    height: "525px",
    borderRadius: 8,
    boxShadow: "0px 2px 10px rgba(78, 89, 109, 0.05)",
  },
  cardTitleValue: {
    top: "calc(50% - 20px/2)",
    fontfamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "20px",
    color: "#08001D",
  },
  chartInfo: {
    marginLeft: 5,
    position: "relative",
    color: "#C7CEDB",
  },
  cardOuter: {
    width: 220,
    margin: 12,
    "@media only screen and (max-width: 760px)": {
      margin: "12px 0",
      width: "48%",
    },
  },
  internalBenchOuter: {
    minHeight: "500px",
    overflowX: "hidden",
  },
  internBenchCont: {
    width: "-webkit-fill-available",
    overflowX: "hidden",
  },
  flexStyle: {
    display: "flex",
    flexWrap: "wrap",
  },
  proLabel: {
    position: "absolute",
    top: 10,
    transform: "rotate(-35deg)",
  },
  calBtn: {
    padding: "6px 12px",
    height: "20px",
    color: "white",
    background: "#5B4DD3",
  },
  infoIconDark: {
    color: "#8C9AA4",
    background: "white",
    borderRadius: "100%",
    fontSize: 19,
    marginLeft: 5,
  },
  infoIconLight: {
    color: "white",
    background: "#8C9AA4",
    borderRadius: "100%",
    fontSize: 13,
    marginLeft: 12,
    marginRight: 6,
  },
  flexWrap: {
    display: "flex",
    flexWrap: "wrap",
  },
  chartDes: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    lineHeight: "20px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: "#8C9AA4",
    margin: "5px 0",
  },
  dateMenuContainer: {
    width: "auto",
    paddingRight: "10px",
  },
};

export default styles;
