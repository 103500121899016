import { Grid, Typography } from "@material-ui/core";

import { Trans } from "react-i18next";

function SwiperDetails({ type, classes, proLabel, setShowDownloadPopup }) {
  return (
    <Grid
      container
      className={classes.swiper}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      onClick={() => {
        return setShowDownloadPopup(true);
      }}
    >
      {proLabel && proLabel}
      <Grid>
        <img className={classes.swiperImage} src={type.img} alt="swiperImage" />
      </Grid>
      <Grid>
        <Typography className={classes.swiperTitle}>
          <Trans>{type.title}</Trans>
        </Typography>
        <Typography className={classes.swiperText}>
          <Trans>{type.text}</Trans>
        </Typography>
      </Grid>
    </Grid>
  );
}

export default SwiperDetails;
