import { Typography } from "@material-ui/core";
import { Trans } from "react-i18next";

export const horizontalBarTooltip = (
  <>
    <Typography style={{ fontSize: 14, fontFamily: "Montserrat" }}>
      <Trans>Anomaly categorisation</Trans>
    </Typography>
    <Typography style={{ fontSize: 12, fontFamily: "Montserrat" }}>
      <Trans>Based on severity (Impact on power loss):</Trans>
      <Typography style={{ marginTop: "5px" }}>
        <Trans>Critical - High impact, priority 1</Trans>
        <br />
        <Trans>Moderate- Moderate impact, priority 2</Trans>
        <br />
        <Trans>Minor- Minor impact, priority 3</Trans>
      </Typography>
      <br />
      <Trans>Classes of Abnormalities Based on IEC 62446-3 (Safety hazards):</Trans>
      <br />
      <Typography style={{ marginTop: "5px" }}>
        <Trans>Uncategorised - Unclassified anomaly</Trans>
        <br />
        <Trans>IEC 1 - Healthy module, no abnormalities</Trans>
        <br />
        <Trans>IEC 2 - Thermal abnormality, medium risk</Trans>
        <br />
        <Trans>IEC 3 - Safety relevant thermal abnormality, high risk</Trans>
      </Typography>
    </Typography>
  </>
);

export const revenueCardTooltip =
  "Enter Yield and Feed in tariff to calculate energy and revenue loss";
