import "mapbox-gl/dist/mapbox-gl.css";
import "./hoverPopup/hoverPopup.css";

import { Grid, Hidden, Typography, withStyles } from "@material-ui/core";
import Map, {
  GeolocateControl,
  Layer,
  Marker,
  NavigationControl,
  Popup,
  Source,
} from "react-map-gl";
import React, { useEffect, useRef, useState } from "react";

import BaseLayers from "./baseLayers";
import CustomButton from "../common/customStyle/Button";
import CustomTooltip from "../common/customStyle/Tooltip";
import HoverPopupWithImg from "./hoverPopup/hoverPopupWithImg";
import { Link } from "react-router-dom";
import ProLabel from "../common/ProLabel";
import { Trans } from "react-i18next";
import { URLS } from "../../constants";
import _ from "lodash";
import { allRequestHandler } from "../../api";
import compareIcon from "../../assets/img/compare.svg";
import i18n from "i18next";
import infoIcon from "../../assets/img/infoIcon.svg";
import lightView from "../../assets/img/light_view.jpg";
import { mapToken } from "../../config";
import mapboxgl from "mapbox-gl";
import recenterIcon from "../../assets/img/recenterIcon.svg";
import satelliteView from "../../assets/img/satellite_view.jpg";
import styles from "./defectMap.style";

// import border from "../../assets/img/markerPng.png"

// prettier-ignore
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const MAPBOX_TOKEN = mapToken;

//component to show polygon on maps
const PaginationPolygon = (props) => {
  const [mouseMoveEvent, setMouseMoveEvent] = useState(null);
  // const [mouseMoveFeatures, setMouseMoveFeatures] = useState(null);
  const [panelData, setPanelData] = useState(null);
  const [mapStyle, setMapStyle] = useState(
    "mapbox://styles/airprobe/cklxlkolw3py017o5w9bxjt63"
  );
  const [showTooltip, setShowTooltip] = useState(null);
  const [geojsoData, setGeojsoData] = useState(null);
  const [baseLayerId, setBaseLayerId] = useState([]);
  const userLocation = useState({
    latitude: null,
    longitude: null,
  });
  const [selectedPanel, setSelectedPanel] = useState(null);

  const [viewPort, setViewPort] = useState({
    longitude: null,
    latitude: null,
    minZoom: 12,
  });
  const [mapTrigger, setMapTrigger] = useState(false);

  const mapRef = useRef(null);

  const { classes } = props;

  const geolocateControlRef = React.useCallback((ref) => {
    if (ref) {
      // Activate as soon as the control is loaded
      ref.trigger();
    }
  }, []);

  useEffect(() => {
    //Change the latitude and longitude of the map according to the coordinates received from the parent component
    if (props.mapCoords) {
      setViewPort({
        longitude: props.mapCoords.lng,
        latitude: props.mapCoords.lat,
        zoom: 18,
        includeGeometry: true,
        minZoom: 13,
        maxZoom: 20,
      });
    }
  }, [props.mapCoords]);

  const centerSelectedPanel = () => {
    if (selectedPanel && mapRef.current) {
      const coordinates = selectedPanel.geometry.coordinates[0][0];

      mapRef.current.getMap().flyTo({
        center: coordinates,
        speed: 0.4, // Make the flying slow
        curve: 1, // Change the speed at which it zooms out
        essential: true, // This animation is considered essential with respect to prefers-reduced-motion
        zoom: 20,
      });
    }
  };

  useEffect(() => {
    //Update geojson data
    setGeojsoData(props.geojsoData);
  }, [props.geojsoData]);

  useEffect(() => {
    //Add highlight marker and fly to the defect marker when new defect is selected
    if (props.selectedDefect && geojsoData && geojsoData.length) {
      const panelToHighlight = geojsoData.find((x) => {
        return (
          x.properties.defect_id === props.selectedDefect.defect_id &&
          x.properties.panel_id === props.selectedDefect.panel_id
        );
      });

      setSelectedPanel(panelToHighlight);
      //To center selected marker
      centerSelectedPanel();
    }
  }, [props.selectedDefect, selectedPanel]);

  //To apply selected filters on map
  const applyFilter = () => {
    if (props.filters) {
      const { filters } = props;
      let filteredDefects = props.allData;
      const filteredPoints = [];

      let defectFilter = new Set();

      if (filters.defectsFilter.length > 0) {
        let tempData = [];
        defectFilter = filters.defectsFilter.map(({ defect_type_name }) => {
          return defect_type_name;
        });

        defectFilter.forEach((val) => {
          const data = filteredDefects.filter((x) => {
            return x.properties.defect_type_name === val;
          });
          tempData = [...tempData, ...data];
        });

        filteredDefects = tempData;
      } else {
        let tempData = [];
        defectFilter = props.filterFaultType.map(({ defect_type_name }) => {
          return defect_type_name;
        });
        defectFilter.forEach((val) => {
          const data = filteredDefects.filter((x) => {
            return x.properties.defect_type_name === val;
          });
          tempData = [...tempData, ...data];
        });
        filteredDefects = tempData;
      }
      if (filters.severity) {
        const serverityFilter = [filters.severity];
        filteredDefects = filteredDefects.filter((x) => {
          return x.properties.severity_type === serverityFilter[0];
        });
      } else {
        let tempData = [];
        const serverityFilter = ["Critical", "Moderate", "Minor"];
        serverityFilter.forEach((val) => {
          const data = filteredDefects.filter((x) => {
            return x.properties.severity_type === val;
          });
          tempData = [...tempData, ...data];
        });
        filteredDefects = tempData;
      }
      if (filters.status && filters.status.length > 0) {
        let tempData = [];
        const statusFilter = filters.status.map((stat) => {
          return stat;
        });
        statusFilter.forEach((val) => {
          const data = filteredDefects.filter((x) => {
            return (
              x.properties.status.split(" ").join("").toLowerCase() === val
            );
          });
          tempData = [...tempData, ...data];
        });
        filteredDefects = tempData;
      } else {
        let tempData = [];
        const statusFilter = [
          "pending",
          "notfound",
          "inprogress",
          "falsepositive",
          "resolved",
        ];
        statusFilter.forEach((val) => {
          const data = filteredDefects.filter((x) => {
            return x.properties.status.toLowerCase() === val;
          });
          tempData = [...tempData, ...data];
        });
        filteredDefects = tempData;
      }
      if (filters.iec_category) {
        const iecFilter = [
          filters.iec_category ? filters.iec_category : "Uncategorized",
        ];
        filteredDefects = filteredDefects.filter((x) => {
          return x.properties.iec_category === iecFilter[0];
        });
      } else {
        let tempData = [];
        const iecFilter = ["IEC 1", "IEC 2", "IEC 3", "Uncategorized"];
        iecFilter.forEach((val) => {
          const data = filteredDefects.filter((x) => {
            return x.properties.iec_category === val;
          });
          tempData = [...tempData, ...data];
        });
        filteredDefects = tempData;
      }

      if (filters.label) {
        const labelFilter = [filters.label];
        filteredDefects = filteredDefects.filter((x) => {
          return x.properties.label === labelFilter[0];
        });
      } else {
        let tempData = [];
        const labelFilter = ["NEW", "PERSISTENT", "TRANSITIONED", "RECURRING"];
        labelFilter.forEach((val) => {
          const data = filteredDefects.filter((x) => {
            return x.properties.label === val;
          });
          tempData = [...tempData, ...data];
        });
        filteredDefects = tempData;
      }

      if (!filters.is_commented) {
        let tempData = [];
        const commentedFilter = [true, false];
        commentedFilter.forEach((val) => {
          const data = filteredDefects.filter((x) => {
            return x.properties.is_commented === val;
          });
          tempData = [...tempData, ...data];
        });
        filteredDefects = tempData;
      } else {
        const commentedFilter = [true];
        filteredDefects = filteredDefects.filter((x) => {
          return x.properties.is_commented === commentedFilter[0];
        });
      }

      // Image upload Filter
      if (!filters.el_image) {
        let tempData = [];
        const elImageFilter = [true, false];
        elImageFilter.forEach((val) => {
          const data = filteredDefects.filter((x) => {
            return x.properties.el_image === val;
          });
          tempData = [...tempData, ...data];
        });
        filteredDefects = tempData;
      } else {
        const elImageFilter = [true];
        filteredDefects = filteredDefects.filter((x) => {
          return x.properties.el_image === elImageFilter[0];
        });
      }

      if (!filters.handheld_image) {
        let tempData = [];
        const handheldImageFilter = [true, false];
        handheldImageFilter.forEach((val) => {
          const data = filteredDefects.filter((x) => {
            return x.properties.handheld_image === val;
          });
          tempData = [...tempData, ...data];
        });
        filteredDefects = tempData;
      } else {
        const handheldImageFilter = [true];
        filteredDefects = filteredDefects.filter((x) => {
          return x.properties.handheld_image === handheldImageFilter[0];
        });
      }

      if (!filters.iv_curve_data) {
        let tempData = [];
        const ivCurveDataFilter = [true, false];
        ivCurveDataFilter.forEach((val) => {
          const data = filteredDefects.filter((x) => {
            return x.properties.iv_curve_data === val;
          });
          tempData = [...tempData, ...data];
        });
        filteredDefects = tempData;
      } else {
        const ivCurveDataFilter = [true];
        filteredDefects = filteredDefects.filter((x) => {
          return x.properties.iv_curve_data === ivCurveDataFilter[0];
        });
      }

      //Filter Function
      if (filters.tempValue) {
        // If temp value is tru filter delta temp anomalies
        let tempData = [];
        const data = filteredDefects.filter((f) => {
          return (
            f.properties.delta_temperature &&
            f.properties.delta_temperature >= filters.tempValue[0] &&
            f.properties.delta_temperature <= filters.tempValue[1]
          );
        });
        tempData = [...tempData, ...data];
        filteredDefects = tempData;
      }

      filteredDefects.map((val) => {
        filteredPoints.push(val.properties.defect_no);
        return filteredPoints;
      });

      mapRef.current
        .getMap()
        .setFilter("panelPolygon", [
          "all",
          ["in", "defect_no", ...filteredPoints],
        ]);
      mapRef.current
        .getMap()
        .setFilter("panelwidth", [
          "all",
          ["in", "defect_no", ...filteredPoints],
        ]);

      props.updateChipFilter();
    } else {
      mapRef.current.getMap().setFilter("panelPolygon", null);
      mapRef.current.getMap().setFilter("panelwidth", null);
    }
  };

  useEffect(() => {
    // On every anomalies update from filter
    if (mapRef.current && mapRef.current.getMap().isStyleLoaded()) {
      applyFilter();
    } else {
      let timer1 = setTimeout(() => setMapTrigger(!mapTrigger), 100);
      return () => {
        clearTimeout(timer1);
      };
    }
  }, [props.filters, props.chipFilter, mapTrigger]);

  const closeAllActions = () => {
    // If mouse is not hovered on Marker close popup
    setMouseMoveEvent(null);
  };

  const handleMouseMove = (event) => {
    // get hoverd Markers layer from Map which has 'Point' as id
    const getHoverMarker =
      mapRef.current &&
      mapRef.current.getMap().queryRenderedFeatures(event.point, {
        layers: ["panelPolygon"],
      });

    // On hover of Marker display popup with image and anomaly details
    if (getHoverMarker && getHoverMarker.length) {
      setMouseMoveEvent(event);
      // setMouseMoveFeatures(getHoverMarker)
      if (mapRef.current && mapRef.current?.getMap().style._loaded) {
        //added cursor as pointer while displaying hover popup
        mapRef.current.getMap().on("mouseenter", "panelPolygon", () => {
          mapRef.current.getMap().getCanvas().style.cursor = "pointer";
        });
        mapRef.current.getMap().on("mouseleave", "panelPolygon", () => {
          mapRef.current.getMap().getCanvas().style.cursor = "grab";
          //avoiding popup stuck after mouse leave.
          setMouseMoveEvent(null);
        });
        // Filter Marker details from all Anomalies list
        const filterHoverMarker = props.mapDuplicateData.filter((x) => {
          return (
            x.properties.panel_id === getHoverMarker[0].properties.panel_id
          );
        })[0];
        setPanelData(filterHoverMarker);
      }
    } else {
      // If mouse is not hovered on Marker close popup
      closeAllActions();
    }
  };

  //After map reloads adding the marker images and getting the anomaly details
  const mapLoad = () => {
    const map = mapRef.current.getMap();

    // Load all markers on maps
    if (mapRef.current && mapRef.current.getMap().style._loaded) {
      // map.loadImage(markerImg, (error, image) => {
      //   if (error) throw error;
      //   map.addImage('custom-marker', image, { sdf: true });
      //   map.addImage('marker-border', image, { sdf: true });
      // });

      if (props.is_tiling) {
        baseLayerId.map((getMapId) => {
          map.moveLayer(`${getMapId}layer`, "panelPolygon");
          map.moveLayer("panelwidth");
          if (selectedPanel) {
            map.moveLayer("panelHighlightBorder");
          }
          return true;
        });
      } else {
        map.moveLayer("baseMapLayer", "panelPolygon");
        map.moveLayer("panelPolygon");
        map.moveLayer("panelwidth");
        if (selectedPanel) {
          map.moveLayer("panelHighlightBorder");
        }
      }

      map.moveLayer("panelPolygon");
      map.moveLayer("panelwidth");
      if (selectedPanel) {
        map.moveLayer("panelHighlightBorder");
      }
    }
    // applyFilter();
    // mapFilters();
  };

  //function to handle onclick marker event
  const onClickMarkerEvent = async (event) => {
    if (mapRef.current) {
      const markerClicked = mapRef.current
        .getMap()
        .queryRenderedFeatures(event.point, {
          layers: ["panelPolygon"],
        });

      // If clicked on marker, hit api and pass data back to index file
      if (markerClicked.length) {
        let fetchDetails = await allRequestHandler({
          requestType: "GET",
          requestUrl: `${
            URLS.ANOMALY_DETAILS
          }${markerClicked[0].properties.defect_id.toString()}/panels/${markerClicked[0].properties.panel_id.toString()}`,
        });
        if (
          fetchDetails &&
          fetchDetails.anomaly_details &&
          fetchDetails.anomaly_details.length
        ) {
          fetchDetails = _.sortBy(
            fetchDetails.anomaly_details,
            "inspection_date"
          );
          // const modifyObj = {
          //   properties: fetchDetails.anomaly_details[0],
          // };

          //filtering the selected defect here to display in details tab
          const filteredSelectedDefect = fetchDetails.find((item) => {
            return item.defect_no === markerClicked[0].properties.defect_no;
          });

          const dataToSend = {
            properties: filteredSelectedDefect,
          };

          // Pass selectedAnomaly data back to index page
          props.showDefectSidebar(dataToSend);
        }
      }
    }
  };

  //Count the number of base layers received from API
  const viewsCount = () => {
    const rasterViewsCount =
      props.allRasterLayer &&
      Object.values(props.allRasterLayer).filter((item) => {
        return item.length;
      }).length;
    const mapUrlViewsCount =
      props.mapUrl &&
      Object.values(props.mapUrl).filter((item) => {
        return item !== null;
      }).length;
    return { rasterViewsCount, mapUrlViewsCount };
  };

  //To display tooltip
  const displayTooltip = () => {
    setShowTooltip(!showTooltip);
  };

  //map recenter
  const mapFlyToCenter = () => {
    const map = mapRef.current.getMap();
    if (geojsoData) {
      map.flyTo({
        center: [
          geojsoData[0].geometry.coordinates[0][0][0],
          geojsoData[0].geometry.coordinates[0][0][1],
        ],
        zoom: 17,
        essential: true,
      });
    }
  };

  //To change map styles
  const stylesChange = () => {
    if (mapRef.current.isStyleLoaded()) {
      if (mapStyle === "mapbox://styles/airprobe/cklxlkolw3py017o5w9bxjt63") {
        setMapStyle("mapbox://styles/airprobe/ckk8aszni0qoc18qln5op6x2y");
      } else {
        setMapStyle("mapbox://styles/airprobe/cklxlkolw3py017o5w9bxjt63");
      }
    }
  };

  //To handle unnecessary errors after style change
  const handleStyleChange = () => {
    if (mapRef.current) {
      const map = mapRef.current.getMap();
      map.moveLayer("panelPolygon");
      map.moveLayer("panelwidth");
      if (selectedPanel) {
        map.moveLayer("panelHighlightBorder");
      }
    }
  };

  return (
    viewPort.latitude &&
    viewPort.longitude && (
      <Grid className={classes.mapArea}>
        <Map
          // {...viewPort}
          // onMove={(e) => {
          //   setViewPort(e.viewState);
          // }}
          onRender={(event) => event.target.resize()}
          initialViewState={viewPort}
          mapStyle={mapStyle}
          mapboxAccessToken={MAPBOX_TOKEN}
          ref={mapRef}
          onLoad={mapLoad}
          onMouseMove={handleMouseMove}
          onClick={onClickMarkerEvent}
          onMouseOut={closeAllActions}
          onStyleData={handleStyleChange}
          onTouchStart={onClickMarkerEvent}
          interactiveLayerIds={["panelPolygon", "panelwidth"]}
        >
          <Grid>
            <NavigationControl
              style={{ marginBottom: window.innerWidth <= 960 ? "65px" : "" }}
              position="bottom-left"
            />

            <GeolocateControl
              ref={geolocateControlRef}
              positionOptions={{ enableHighAccuracy: true }}
              trackUserLocation
              position="bottom-left"
            />

            {/* <GeolocateControl
              position="bottom-left"
              positionOptions={{ enableHighAccuracy: true }}
              trackUserLocation={true}
              // showAccuracyCircle={false}
              showUserHeading={true}
              onTrackUserLocationStart={(e) => {
                console.log("tracking started", e);
                setViewPort((prevState) => {
                  return { ...prevState, ...e.coords };
                });
              }}
              onGeolocate={(e) => {
                if (mapRef.current) {
                  mapRef.current.getMap().flyTo({
                    center: [e.coords.longitude, e.coords.latitude],
                    rotateTo:
                      e.coords.speed && e.coords.speed > 0.1
                        ? e.coords.heading
                        : viewPort.bearing
                        ? viewPort.bearing
                        : 0,
                    speed: 0.4, // make the flying slow
                    curve: 1, // change the speed at which it zooms out
                    essential: true, // this animation is considered essential with respect to prefers-reduced-motion
                  });
                }
                setViewPort((preVal) => {
                  return {
                    ...preVal,
                    bearing:
                      e.coords.speed && e.coords.speed > 0.1
                        ? e.coords.heading
                        : preVal.bearing
                        ? preVal.bearing
                        : 0,
                    latitude: e.coords.latitude,
                    longitude: e.coords.longitude,
                  };
                });
                return setUserLocation({
                  latitude: e.coords.latitude,
                  longitude: e.coords.longitude,
                });
              }}
              onTrackUserLocationEnd={(e) => {
                console.log("tracking ended", e);
                setViewPort((prevState) => {
                  return { ...prevState, ...e.coords };
                });
              }}
            ></GeolocateControl> */}
          </Grid>

          <button
            className={classes.styleChangeBtn}
            onClick={(e) => stylesChange(e)}
            style={{
              cursor: "pointer",
              backgroundImage:
                mapStyle ===
                "mapbox://styles/airprobe/cklxlkolw3py017o5w9bxjt63"
                  ? `url(${lightView})`
                  : `url(${satelliteView})`,
            }}
            type="button"
          />

          {/* On hover of Markers displaying popup with Anomaly details and Thermal image */}
          {mouseMoveEvent && panelData && (
            <Popup
              longitude={mouseMoveEvent.lngLat.lng}
              latitude={mouseMoveEvent.lngLat.lat}
              closeButton={false}
            >
              <HoverPopupWithImg panelData={panelData.properties} />
            </Popup>
          )}

          {/* Base Layers */}

          {props.is_tiling &&
            baseLayerId &&
            baseLayerId.length &&
            baseLayerId.map((getMapId) => {
              return (
                <React.Fragment key={getMapId}>
                  <Source
                    id={`${getMapId}lay`}
                    type="raster"
                    url={`mapbox://${getMapId}`}
                  />
                  <Layer
                    id={`${getMapId}layer`}
                    type="raster"
                    source={`${getMapId}lay`}
                  />
                </React.Fragment>
              );
            })}

          {!props.is_tiling && baseLayerId && (
            <React.Fragment key={1}>
              <Source
                id="myImageSource"
                type="image"
                url={baseLayerId}
                coordinates={[
                  [
                    props.mapCoordinate && props.mapCoordinate.west,
                    props.mapCoordinate && props.mapCoordinate.north,
                  ],
                  [
                    props.mapCoordinate && props.mapCoordinate.east,
                    props.mapCoordinate && props.mapCoordinate.north,
                  ],
                  [
                    props.mapCoordinate && props.mapCoordinate.east,
                    props.mapCoordinate && props.mapCoordinate.south,
                  ],
                  [
                    props.mapCoordinate && props.mapCoordinate.west,
                    props.mapCoordinate && props.mapCoordinate.south,
                  ],
                ]}
              />
              <Layer
                id="myImageLayer"
                type="raster"
                source="myImageSource"
                paint={{ "raster-opacity": 1 }}
              />
            </React.Fragment>
          )}

          {/* Display markers layer on map */}
          {geojsoData && geojsoData.length && (
            <>
              <Source
                id="polygonData"
                type="geojson"
                data={{
                  type: "FeatureCollection",
                  features: geojsoData,
                }}
              />

              <Layer
                id="panelPolygon"
                type="fill"
                source="polygonData"
                paint={{
                  "fill-color": "transparent",
                  "fill-outline-color": {
                    type: "identity",
                    property: "color_code",
                  },
                  "fill-opacity": 0.5,
                }}
              />

              <Layer
                id="panelwidth"
                type="line"
                source="polygonData"
                paint={{
                  "line-color": {
                    type: "identity",
                    property: "color_code",
                  },
                  "line-width": 2,
                }}
              />
            </>
          )}

          {props.toggleDefectSidebar && selectedPanel && props.selectedDefect && (
            <>
              <Source
                id="panelHighlight"
                type="geojson"
                data={{
                  type: "FeatureCollection",
                  features: [selectedPanel],
                }}
              />

              <Layer
                id="panelHighlightLayer"
                type="fill"
                source="panelHighlight"
                paint={{
                  "fill-color": "transparent",
                  "fill-outline-color": "#800000",
                  "fill-opacity": 0.5,
                }}
              />

              <Layer
                id="panelHighlightBorder"
                type="line"
                source="panelHighlight"
                paint={{
                  "line-color": "#800000",
                  "line-width": 3,
                }}
              />
            </>
          )}

          {userLocation && userLocation.latitude && userLocation.longitude && (
            <Marker
              longitude={userLocation.longitude}
              latitude={userLocation.latitude}
            >
              <div
                style={{
                  width: "0px",
                  height: "0px",
                  opacity: "0.8",
                  borderTop: "30px solid #1DA1F2",
                  borderRight: "30px solid transparent",
                  borderLeft: "30px solid transparent",
                  borderBottom: "30px solid transparent",
                  borderTopLeftRadius: "30px",
                  borderTopRightRadius: "30px",
                  borderBottomLeftRadius: "30px",
                  borderBottomRightRadius: "30px",
                }}
              />
            </Marker>
          )}

          <div style={{ display: "flex", alignItems: "center" }}>
            {/* <InfoIcon
            onClick={() =>displayTooltip()}
            className={classes.infoIcon}
          /> */}
            <CustomButton
              type="cancel"
              style={{
                position: "absolute",
                left: 0,
                top: 8,
                width: "36px",
                height: "36px",
                minWidth: 0,
                minHeight: 0,
                border: "1px solid #F0F4FA 8px",
              }}
              btnIcon={infoIcon}
              onClick={displayTooltip}
              // margin={5}
            />
            <Hidden only={["xs"]}>
              <div style={{ position: "relative" }}>
                <CustomTooltip
                  classes={{ tooltip: classes.recenterTooltip }}
                  placement="top-start"
                  title={i18n.t("Recenter")}
                >
                  <div>
                    <CustomButton
                      type="cancel"
                      style={{
                        position: "absolute",
                        left: 42,
                        top: 8,
                        width: "36px",
                        height: "36px",
                        minWidth: 0,
                        minHeight: 0,
                        border: "1px solid #F0F4FA 8px",
                      }}
                      btnIcon={recenterIcon}
                      onClick={mapFlyToCenter}
                      // margin={5}
                    />
                  </div>
                </CustomTooltip>
              </div>
            </Hidden>
          </div>
          <Hidden smUp>
            <div
              className={classes.recenterIconmobile}
              onClick={mapFlyToCenter}
              role="button"
              tabIndex={"0"}
              aria-hidden="true"
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.4636 13.5L11.0303 11.0667M5.44444 1H2.77778C2.30628 1 1.8541 1.1873 1.5207 1.5207C1.1873 1.8541 1 2.30628 1 2.77778V5.44444M17 5.44444V2.77778C17 2.30628 16.8127 1.8541 16.4793 1.5207C16.1459 1.1873 15.6937 1 15.2222 1H12.5556M12.5556 17H15.2222C15.6937 17 16.1459 16.8127 16.4793 16.4793C16.8127 16.1459 17 15.6937 17 15.2222V12.5556M1 12.5556V15.2222C1 15.6937 1.1873 16.1459 1.5207 16.4793C1.8541 16.8127 2.30628 17 2.77778 17H5.44444M12.0748 8.55556C12.0748 10.5192 10.4829 12.1111 8.5192 12.1111C6.55551 12.1111 4.96362 10.5192 4.96362 8.55556C4.96362 6.59188 6.55551 5 8.5192 5C10.4829 5 12.0748 6.59188 12.0748 8.55556Z"
                  stroke="#536471"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </Hidden>

          <BaseLayers
            allRasterLayer={props.allRasterLayer}
            tiling_metadata={props.tiling_metadata}
            is_tiling={props.is_tiling}
            mapUrl={props.mapUrl}
            baseLayerId={(v) => {
              return setBaseLayerId(v);
            }}
          />

          {showTooltip && (
            <Grid direction="column" container className={classes.toolBox}>
              {props.filterFaultType &&
                props.filterFaultType.length &&
                props.filterFaultType.map((fault) => {
                  return (
                    <Grid
                      container
                      alignItems="center"
                      style={{ margin: "4px 8px" }}
                      key={fault.defect_type_name}
                    >
                      <div
                        style={{
                          height: 6,
                          width: 6,
                          borderRadius: "50%",
                          marginRight: 10,
                          backgroundColor: fault.color_code,
                        }}
                      />
                      <Typography className={classes.titleText}>
                        <Trans>{fault.defect_type_name}</Trans> -{" "}
                        {fault.module_count}
                      </Typography>
                    </Grid>
                  );
                })}
            </Grid>
          )}
          <Hidden smDown>
            {props.allSectionList &&
              (props.product?.product_type === "Basic"
                ? props.allSectionList.inspection_list.length > 1 && (
                    <div
                      style={{
                        position: "absolute",
                        top: "8px",
                        left:
                          (props.allRasterLayer &&
                            viewsCount().rasterViewsCount > 1) ||
                          (props.mapUrl && viewsCount().mapUrlViewsCount > 1)
                            ? "150px"
                            : "90px",
                      }}
                    >
                      <CustomButton
                        intercom="compare-inspections-button"
                        btnIcon={compareIcon}
                        type={"cancel"}
                        onClick={() => {
                          return props.onHandleShowPlans(true);
                        }}
                        style={{
                          width: "36px",
                          height: "36px",
                          minHeight: "0px",
                          minWidth: "0px",
                          background: "#5B4DD3",
                          border: "none",
                        }}
                      />
                      <div className={classes.proCompare}>
                        <ProLabel marginRight="0" marginLeft="10px" show />
                      </div>
                    </div>
                  )
                : props.allSectionList.inspection_list.length > 1 && (
                    <Link
                      to={{
                        pathname: `/inspectionComp/${props.allSectionList?.plant_id}`,
                        state: { component: "MAP" },
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          top: "8px",
                          left:
                            (props.allRasterLayer &&
                              viewsCount().rasterViewsCount > 1) ||
                            (props.mapUrl && viewsCount().mapViewsCount > 1)
                              ? "150px"
                              : "90px",
                        }}
                      >
                        <CustomButton
                          intercom="compare-inspections-button"
                          btnIcon={compareIcon}
                          type={"cancel"}
                          style={{
                            width: "36px",
                            height: "36px",
                            minHeight: "0px",
                            minWidth: "0px",
                            background: "#5B4DD3",
                            border: "none",
                          }}
                        />
                        <div className={classes.proCompare}>
                          <ProLabel marginRight="0" marginLeft="10px" show />
                        </div>
                      </div>
                    </Link>
                  ))}
          </Hidden>
        </Map>
      </Grid>
    )
  );
};

export default withStyles(styles)(PaginationPolygon);