import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";

import Config from "../../config";
import IconButton from "@material-ui/core/IconButton";
import { Link } from "react-router-dom";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React from "react";
import RemoveImgIcon from "../../assets/img/removeImg.svg";
import { Trans } from "react-i18next";
import UploadImg from "../../assets/img/uploadImg.svg";
import { allRequestHandler } from "../../api";
import { connect } from "react-redux";
import moment from "moment";
import styles from "./SiteCard.style";
import { withStyles } from "@material-ui/core/styles";

const requestUrl = Config.hostName;

//site card
class SiteCard extends React.Component {
  state = {
    downloadMenu: false,
    anchorEl: null,
    openMenu: false,
    manageVisible: window.innerWidth <= 760 ? "Visible" : null,
    isUsersButtonClicked: false,
    siteCardDataForUsers: null,
  };

  //handle the click on the download icon on the card
  handleDownload = (event) => {
    this.setState({ downloadMenu: true, anchorEl: event.currentTarget });
  };

  //Closes the dropdown menu on the card
  handleCloseDropdown = () => {
    if (window.innerWidth <= 760) {
      this.setState({ downloadMenu: false, openMenu: false });
    } else {
      this.setState({
        downloadMenu: false,
        openMenu: false,
        manageVisible: null,
      });
    }
  };

  //API call to download the site
  redirectSite = async (data) => {
    const downUrl = await allRequestHandler({
      requestType: "GET",
      requestUrl: requestUrl + data.href,
    });

    window.open(downUrl.file_url);
    this.handleCloseDropdown();
  };

  //Handles the update of the site image on the card
  uploadFileChange = async (event, value) => {
    this.handleCloseDropdown();
    const files = event.target.files[0];
    if (files) {
      if (files.size < 10485760) {
        const fileObj = {
          file_name: files.name,
          file_type: files.type,
        };

        if (files.type !== "image/jpeg" && files.type !== "image/png") {
          return this.props.callSnackbar(
            "Please select a png or jpeg image file",
            "warning"
          );
        }

        try {
          const getUrl = await allRequestHandler({
            requestType: "POST",
            requestUrl: requestUrl + value.upload_href,
            requestData: fileObj,
          });

          if (getUrl) {
            const data = new FormData();
            data.append("key", getUrl.aws_param_list.fields.key);
            data.append("Content-Type", files.type);
            data.append("policy", getUrl.aws_param_list.fields.policy);
            data.append("acl", "public-read");
            data.append("success_action_status", "201");
            data.append("x-amz-algorithm", "AWS4-HMAC-SHA256");
            data.append(
              "x-amz-credential",
              getUrl.aws_param_list.fields["x-amz-credential"]
            );
            data.append(
              "x-amz-date",
              getUrl.aws_param_list.fields["x-amz-date"]
            );
            data.append(
              "x-amz-signature",
              getUrl.aws_param_list.fields["x-amz-signature"]
            );
            data.append("file", files, getUrl.aws_param_list.file_name);
            const xhr = new XMLHttpRequest();

            xhr.open("POST", getUrl.aws_param_list.url);
            xhr.send(data);

            //PATCH image
            await allRequestHandler({
              requestType: "PATCH",
              requestUrl: requestUrl + value.patch_href,
              requestData: {
                plant_image_url:
                  getUrl.aws_param_list.url + getUrl.aws_param_list.fields.key,
              },
            });

            this.props.callSiteList();
            this.props.callSnackbar(
              "Image file uploaded successfully",
              "success"
            );
          }
        } catch (error) {
          this.props.callSnackbar(
            "There was a problem while uploading file.",
            "warning"
          );
        }
      } else
        this.props.callSnackbar(
          "File size should be less than 10MB.",
          "warning"
        );
    }

    return false;
  };

  //Handles the dropdown menu to update or remove the site image
  handleDropdown = (event) => {
    const anchorEl = event.currentTarget;
    this.setState((preVal) => {
      return {
        openMenu: !preVal.openMenu,
        anchorEl,
      };
    });
  };

  //Handles the removal of the site image on the card
  removeFile = async (value) => {
    this.handleCloseDropdown();
    try {
      await allRequestHandler({
        requestType: "PATCH",
        requestUrl: requestUrl + value.patch_href,
        requestData: {
          plant_image_url: null,
        },
      });
      this.props.callSiteList();
      this.props.callSnackbar("Image file successfully removed", "success");
    } catch (error) {
      this.props.callSnackbar(
        "There was a problem while removing file.",
        "warning"
      );
    }
  };

  //Handles the visibility of the icon
  handleIcon = () => {
    this.setState({ manageVisible: "visible" });
  };

  //render site card
  render() {
    const { classes, siteCardData, background, letter } = this.props;
    const PlantImage = `${siteCardData.plant_image}`;

    return (
      <Card className={classes.root}>
        <CardMedia
          className={classes.media}
          image={PlantImage}
          title={siteCardData.plant_name}
          style={{
            backgroundColor: background,
          }}
        >
          <div
            className={classes.uploadOuter}
            style={{ visibility: this.state.manageVisible }}
          >
            {/* Image Dropdown */}
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={(e) => {
                this.handleDropdown(e);
                this.handleIcon();
              }}
              classes={{ root: classes.moreIcon }}
            >
              <MoreVertIcon />
            </IconButton>

            <Menu
              id="long-menu"
              anchorEl={this.state.anchorEl}
              variant="menu"
              keepMounted
              open={this.state.openMenu}
              onClose={this.handleCloseDropdown}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              classes={{ paper: classes.paperStyle }}
              getContentAnchorEl={null}
            >
              <MenuItem
                ListItemClasses={{
                  root: classes.listAddImg,
                }}
                key={"Add"}
              >
                <label
                  className={classes.addImgLabel}
                  htmlFor={siteCardData.plant_name}
                >
                  <img
                    alt="img"
                    className={classes.uploadIcon}
                    src={UploadImg}
                  />
                  <Trans>Add Image</Trans>
                </label>
              </MenuItem>
              {typeof siteCardData.plant_image === "string" && (
                <MenuItem
                  ListItemClasses={{
                    root: classes.lists,
                  }}
                  key={"Remove"}
                  onClick={() => {
                    return this.removeFile(siteCardData);
                  }}
                >
                  <img
                    alt="img"
                    style={{ marginRight: 8 }}
                    src={RemoveImgIcon}
                  />
                  <Trans>Remove Image</Trans>
                </MenuItem>
              )}
            </Menu>

            <input
              style={{ display: "none" }}
              accept=".png, .jpg"
              id={siteCardData.plant_name}
              type="file"
              onChange={(e) => {
                this.uploadFileChange(e, siteCardData);
              }}
            />
          </div>
        </CardMedia>
        {typeof siteCardData.plant_image !== "string" ? (
          <Typography
            className={classes.plantLetter}
            style={{
              backgroundColor: letter,
            }}
          >
            {siteCardData.plant_name !== null
              ? siteCardData.plant_name.charAt(0)
              : ""}
          </Typography>
        ) : null}
        <Link to={siteCardData.site_href}>
          <CardContent className={classes.cardContentStyle}>
            <Typography className={classes.plantName}>
              <Trans>{siteCardData.plant_name}</Trans>
            </Typography>
            <Typography className={classes.plantLocation}>
              {siteCardData.location !== null ? siteCardData.location : " -"}
            </Typography>
          </CardContent>
          <CardContent className={classes.dateBlock}>
            <Grid style={{ width: "100px" }}>
              <Typography className={classes.dateHeader}>
                <Trans>COD:</Trans>
              </Typography>
              <Typography className={classes.dates}>
                {siteCardData.commissioning_date
                  ? moment(siteCardData.commissioning_date).format("ll")
                  : " -"}
              </Typography>
            </Grid>
            <Grid style={{ marginLeft: "50px" }}>
              <Typography className={classes.dateHeader}>
                <Trans>Inspection Date:</Trans>
              </Typography>
              <Typography className={classes.dates}>
                {siteCardData.inspection_date
                  ? moment(siteCardData.inspection_date).format("ll")
                  : " -"}
              </Typography>
            </Grid>
          </CardContent>
        </Link>
        {/* </CardActionArea> */}
        <ButtonGroup
          className={classes.buttonsGroup}
          variant="text"
          size="large"
          aria-label="large text primary button group"
        >
          <Link to={siteCardData.site_href}>
            <Button
              style={{
                padding:
                  this.props.language === "sp" || !siteCardData.site_users_href
                    ? "0px"
                    : "25px",
                maxWidth: this.props.language === "sp" ? "150px" : "130px",
                height: "44px",
                paddingRight: siteCardData.site_users_href ? "" : "35px",
              }}
              className={classes.button3}
              data-intercom-target="site-details"
            >
              <span className={classes.buttonSpan}>
                <Trans>Site Details</Trans>
              </span>
            </Button>
          </Link>
          <Link to={`/defects/${siteCardData.plant_id}`}>
            <Button
              className={
                siteCardData.site_users_href ? classes.buttons : classes.button2
              }
            >
              <span
                className={classes.buttonSpan}
                style={{ color: this.state.openMenu && "#5B4DD3" }}
              >
                <Trans>Anomalies</Trans>
              </span>
            </Button>
          </Link>
          {siteCardData.site_users_href && (
            <Button
              className={classes.buttons}
              onClick={() => {
                this.setState({
                  isUsersButtonClicked: true,
                });
                this.props.isUsersButtonClicked(true);
                this.props.siteCardDataForUsers(this.props.siteCardData);
              }}
            >
              <span
                className={classes.buttonSpan}
                style={{
                  color: this.state.openMenu && "#5B4DD3",
                }}
              >
                <Trans>Users</Trans>
              </span>
            </Button>
          )}
          {/* <Button
            className={classes.buttons}
            onClick={(e) => {
              return this.handleDownload(e);
            }}
            disabled={siteCardData.downloads_list.length === 0}
            style={{ width: siteCardData.site_users_href ? "" : 130 }}
          >
            <img src={DownloadIcon} alt="Download" style={{ width: "16px" }} />
          </Button> */}
          <Menu
            id="long-menu"
            anchorEl={this.state.anchorEl}
            variant="menu"
            keepMounted
            open={this.state.downloadMenu}
            onClose={this.handleCloseDropdown}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
            classes={{ paper: classes.paperStyle }}
          >
            {siteCardData.downloads_list.map((data) => {
              return (
                <MenuItem
                  ListItemClasses={{
                    root: classes.lists,
                  }}
                  key={data.name}
                  onClick={() => {
                    return this.redirectSite(data);
                  }}
                >
                  {data.name}
                </MenuItem>
              );
            })}
          </Menu>
        </ButtonGroup>
      </Card>
    );
  }
}

//Calling redux to get the selected language for the app
const mapStateToProps = (state) => {
  return {
    language: state.language.selectedLanguage,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(SiteCard));
