import { Box, Divider, Grid, Typography } from "@material-ui/core";
import React, { useRef, useState } from "react";

import ClearIcon from "@material-ui/icons/Clear";
import CloseIcon from "@material-ui/icons/Close";
import Config from "../../config";
import CustomButton from "../common/customStyle/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import EL from "../../assets/img/EL.svg";
import IconButton from "@material-ui/core/IconButton";
import { Trans } from "react-i18next";
import { allRequestHandler } from "../../api";
import fileImg from "../../assets/img/fileImg.svg";
import handledImg from "../../assets/img/handheld.svg";
import ivDataImg from "../../assets/img/ivDataImg.svg";
import upload from "../../assets/img/upload.svg";
import useStyles from "./AnomalyPopUp.style";

const requestUrl = Config.hostName;

//To upload files and folder
export default function CustomizedDialogs({ updateDefectDetails, defectId }) {
  const hiddenFileInput = useRef(null);

  const [open, setOpen] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [attachmentDetails, setAttachmentDetails] = useState([]);
  const [type, setType] = useState();
  // const [url, setUrl] = useState();

  //Handles the opening of the dialog box on click
  const handleClickOpen = () => {
    setOpen(true);
  };

  //Handles the closing of the dialog box
  const handleClose = () => {
    setOpen(false);
    setImageList([]);
  };

  //Handle the file type
  const handleClick = (imgType) => {
    setType(imgType);
    // setUrl(type + "_url");
    hiddenFileInput.current.click();
  };

  //Remove an image from the list
  const remove = (index) => {
    const temp = [...imageList];
    temp.splice(index, 1);
    setImageList(temp);
  };

  //Handles the file upload
  const handleFileInput = async (e) => {
    const files = e.target.files[0];

    const fileObj = [
      {
        file_name: files.name.split(" ").join("_").trim(),
        content_type: files.type,
        content_length: files.size,
        attachment_type: type,
      },
    ];

    try {
      const addAttach = await allRequestHandler({
        requestType: "POST",
        requestUrl: `${requestUrl}/api/attachments/aws_params`,
        requestData: { attachment_list: fileObj },
      });

      const prevList = imageList;
      prevList.push(addAttach.aws_params[0].file_name);
      setImageList(prevList);

      const data = new FormData();
      data.append("key", addAttach.aws_params[0].fields.key);
      data.append(
        "Content-Type",
        addAttach.aws_params[0].fields["Content-Type"]
      );
      data.append("policy", addAttach.aws_params[0].fields.policy);
      data.append("acl", "public-read");
      data.append("success_action_status", "201");
      data.append(
        "x-amz-algorithm",
        addAttach.aws_params[0].fields["x-amz-algorithm"]
      );
      data.append(
        "x-amz-credential",
        addAttach.aws_params[0].fields["x-amz-credential"]
      );
      data.append("x-amz-date", addAttach.aws_params[0].fields["x-amz-date"]);
      data.append(
        "x-amz-signature",
        addAttach.aws_params[0].fields["x-amz-signature"]
      );
      data.append("file", files, addAttach.aws_params[0].file_name);

      const xhr = new XMLHttpRequest();

      xhr.open("POST", addAttach.aws_params[0].url);
      xhr.send(data);
      const temp = [...attachmentDetails];
      temp.push(addAttach.aws_params[0]);

      setAttachmentDetails(temp);
    } catch (error) {
      console.log("error:", error);
    }
  };

  //Handles the false +ve proof upload
  const uploadProofUtil = async (attachmentDetailVal) => {
    try {
      const patchData = await allRequestHandler({
        requestType: "PATCH",
        requestUrl: `${requestUrl}/api/anomalies/${defectId}/attachments/${attachmentDetailVal.attachment_id}`,
      });

      if (patchData === "") {
        updateDefectDetails();
        setAttachmentDetails([]);
      }
    } catch {
      console.log("Error in uploading");
    }
  };

  //To upload proof
  const uploadProof = () => {
    attachmentDetails.forEach((value) => {
      uploadProofUtil(value);
    });
    handleClose();
  };

  //Renders the anomalies popup
  const classes = useStyles();
  return (
    <>
      <div className={classes.addIcon}>
        <IconButton onClick={handleClickOpen}>
          <img src={upload} alt="upload" />
        </IconButton>
      </div>

      <Dialog
        onClose={handleClose}
        open={open}
        classes={{ paper: classes.paperDialog }}
      >
        <div className={classes.OpenPopUp}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            style={{ padding: 20, borderBottom: "1px solid #E4EAF5" }}
          >
            <Typography className={classes.popUpTitle}>
              {" "}
              <Trans>File/Image UPLOAD</Trans>{" "}
            </Typography>
            <CloseIcon
              fontSize="large"
              className={classes.closeIcon}
              onClick={handleClose}
            />
          </Grid>

          <div style={{ padding: 20 }}>
            {imageList && !imageList.length ? (
              <>
                <DialogTitle className={classes.head}>
                  <div className={classes.upload}>
                    <Trans>Upload Images</Trans>
                  </div>
                </DialogTitle>

                <CustomButton
                  variant="outlined"
                  onClick={() => {
                    return handleClick("el_image");
                  }}
                  btnIcon={EL}
                  text="EL Image"
                  margin={6}
                  style={{
                    background: "#F0F4FA",
                    color: "#536471",
                    border: "none",
                  }}
                />

                <CustomButton
                  variant="outlined"
                  onClick={() => {
                    return handleClick("handheld_image");
                  }}
                  btnIcon={handledImg}
                  text="Handheld"
                  style={{
                    marginLeft: "12px",
                    background: "#F0F4FA",
                    color: "#536471",
                    border: "none",
                  }}
                  margin={6}
                />

                <DialogTitle
                  id="customized-dialog-title"
                  className={classes.head}
                >
                  <div className={classes.upload}>
                    <Trans>Upload Files</Trans>
                  </div>
                </DialogTitle>
                <CustomButton
                  variant="outlined"
                  onClick={() => {
                    return handleClick("iv_curve_data");
                  }}
                  btnIcon={ivDataImg}
                  text="iv_data"
                  margin={6}
                  style={{
                    background: "#F0F4FA",
                    color: "#536471",
                    border: "none",
                  }}
                />

                <CustomButton
                  variant="outlined"
                  onClick={() => {
                    return handleClick("others");
                  }} //#ToFix
                  btnIcon={fileImg}
                  text="Others"
                  style={{
                    marginLeft: "28px",
                    background: "#F0F4FA",
                    color: "#536471",
                    border: "none",
                  }}
                  margin={6}
                />
              </>
            ) : (
              <div style={{ marginTop: 20 }}>
                {imageList &&
                  imageList.map((name) => {
                    return (
                      <Grid
                        key={name}
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        className={classes.fileInputField}
                      >
                        <Box
                          component="div"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Typography className={classes.titleText}>
                            <Trans>{name}</Trans>
                          </Typography>
                        </Box>
                        <IconButton
                          onClick={(i) => {
                            return remove(i);
                          }}
                        >
                          <ClearIcon />
                        </IconButton>
                      </Grid>
                    );
                  })}
              </div>
            )}
          </div>
          <input
            type="file"
            accept=".pdf, .csv, .doc, .docx, .png, .jpg, .jpeg, .zip, .rar"
            id="fileUpload"
            ref={hiddenFileInput}
            style={{ display: "none" }}
            onChange={handleFileInput}
          />
        </div>
        <Divider style={{ marginBottom: "12px" }} />
        <Grid
          container
          className={classes.paddAlign}
          style={{ justifyContent: "flex-end" }}
        >
          <CustomButton
            variant="outlined"
            onClick={handleClose}
            text="Cancel"
            type="cancel"
          />
          <CustomButton onClick={uploadProof} text="Upload & Continue" />
        </Grid>

        {/* <FileUpload/>  */}
      </Dialog>
    </>
  );
}
