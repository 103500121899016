import {
  Container,
  Divider,
  Grid,
  Typography,
  withStyles,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import CustomButton from "../../common/customStyle/Button";
import React from "react";
import { Trans } from "react-i18next";
import { URLS } from "../../../constants";
import { allRequestHandler } from "../../../api";
import styles from "../reports.style";

//delete popover
const DeletePopup = ({
  classes,
  handleDeletePopup,
  report,
  getReportHistory,
  callSnackbar,
}) => {
  //To handle report deletion
  const deleteReportHandler = async () => {
    try {
      await allRequestHandler({
        requestType: "DELETE",
        requestUrl: `${URLS.REPORT_API}/${report.report_id}`,
      });
      handleDeletePopup();
      getReportHistory();
      callSnackbar("Report deleted successfully", "success");
    } catch (error) {
      handleDeletePopup();
      callSnackbar("There was a error", "error");
    }
  };

  return (
    <Grid container className={classes.popupContainer} direction="column">
      <Container className={classes.dltPopupInner}>
        <Grid
          container
          justifyContent="space-between"
          className={classes.popupItem}
          direction="column"
        >
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.paddAlign}
          >
            <Typography className={classes.popUpTitle}>
              <Trans>Delete File</Trans>
            </Typography>
            <CloseIcon
              fontSize="large"
              className={classes.closeIcon}
              onClick={handleDeletePopup}
            />
          </Grid>
          <Divider />
          <Grid container style={{ padding: "1rem 0" }}>
            <Grid item xs={12} md={12} className={classes.dltTitleContainer}>
              <Typography className={classes.titleText}>
                <Trans>Are you sure you want to delete</Trans>
                {report.report_name.length > 22 ? <br /> : null}
                {report.report_name}
              </Typography>
            </Grid>
          </Grid>

          <Divider className={classes.marTB20} />
          <Grid
            container
            className={classes.paddAlign}
            justifyContent="flex-end"
            style={{ paddingRight: 24 }}
          >
            <CustomButton
              variant="outlined"
              type={"cancel"}
              text={<Trans>Cancel</Trans>}
              onClick={handleDeletePopup}
            />
            <CustomButton
              text={<Trans>Delete</Trans>}
              onClick={deleteReportHandler}
              type={"redDelete"}
            />
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

export default withStyles(styles)(DeletePopup);
