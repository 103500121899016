import {
  Card,
  CardContent,
  Divider,
  Grid,
  Tab,
  Table,
  TableCell,
  TableRow,
  Tabs,
  Typography,
} from "@material-ui/core";

import NewLoader from "../../layout/newLoader";
import React from "react";
import { Trans } from "react-i18next";
import i18n from "i18next";
import styles from "./siteSummary.style";
import { withStyles } from "@material-ui/core/styles";

//Inspection summary card
class InspectionSummary extends React.Component {
  state = {
    tabValue: 0,
  };

  //Handles the tab change (ANalysis - Flight)
  handleChange = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };

  //This renders the summary card
  render() {
    const { classes } = this.props;
    return (
      <Card style={styles.card}>
        <CardContent style={styles.contentMob}>
          <Typography style={styles.chartHeading}>
            <Trans>SUMMARY</Trans>
          </Typography>
          <Divider />
          <Tabs
            classes={{
              indicator: classes.indicatorColor,
            }}
            textColor="primary"
            aria-label="simple tabs example"
            value={this.state.tabValue}
            onChange={this.handleChange}
            variant="fullWidth"
            style={{ borderBottom: "1px solid #E4EAF5" }}
          >
            <Tab
              style={
                this.state.tabValue === 0 ? styles.selectedTab : styles.tabLabel
              }
              label={i18n.t("Analysis")}
            />
            <Tab
              style={
                this.state.tabValue === 1 ? styles.selectedTab : styles.tabLabel
              }
              label={i18n.t("Flight")}
            />
          </Tabs>

          {this.state.tabValue === 0 ? (
            <Grid container className={classes.summaryBox}>
              {this.props.metadataDetails ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.props.metadataDetails.executive_summary,
                  }}
                />
              ) : (
                <NewLoader />
              )}
            </Grid>
          ) : (
            <Grid container className={classes.summaryBox}>
              <Table className={classes.table}>
                <TableRow>
                  <TableCell className={classes.tableItem}>
                    <Trans>Camera</Trans>
                    <span>:</span>
                  </TableCell>
                  <TableCell className={classes.tableItem}>
                    <Trans>
                      {this.props.metadataDetails.camera_make === null
                        ? "-"
                        : this.props.metadataDetails.camera_make}
                    </Trans>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tableItem}>
                    <Trans>Images</Trans>
                    <span>:</span>
                  </TableCell>
                  <TableCell className={classes.tableItem}>
                    <Trans>
                      {this.props.metadataDetails.images_available === null
                        ? "-"
                        : this.props.metadataDetails.images_available}
                    </Trans>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tableItem}>
                    <Trans>GSD:</Trans>
                  </TableCell>
                  <TableCell className={classes.tableItem}>
                    {this.props.metadataDetails.gsd === null
                      ? "-"
                      : this.props.metadataDetails.gsd}
                  </TableCell>
                </TableRow>
              </Table>
            </Grid>
          )}
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles)(InspectionSummary);
