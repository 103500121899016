export default {
  downButton: {
    background: "#FFFFFF",
    border: "1px solid #DDE5EC",
    boxSizing: "border-box",
    borderRadius: 8,
    marginLeft: 20,
    boxShadow: "none",
    outline: "none",
    "&:focus": {
      outline: "none",
    },
    "&:hover": {
      backgroundColor: "#ffffff",
    },
    "@media only screen and (max-width: 760px)": {
      marginLeft: 6,
      height: 34,
    },
  },
  paperStyle: {
    border: "1px solid #DDE5EC",
    boxShadow: "0px 4px 12px rgba(78, 89, 109, 0.05)",
    borderRadius: "8px",
  },
  lists: {
    backgroundColor: "rgba(0,0,0,0)",
    color: "#536471",
    width: "240px",
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "12px",
    "&:hover": {
      backgroundColor: "#F0F4FA",
    },
  },
};
