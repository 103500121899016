import { Grid, Typography, withStyles } from "@material-ui/core";

import BreadCrumbImg from "./breadcrumb.svg";
import CustomButton from "../../views/common/customStyle/Button";
import React from "react";
import styles from "./cookieConsent.style";

//Handle cookies
function CookieBlock({ classes, manageCookieHandler, allowCookies }) {
  return (
    <Grid className={classes.consentContainer}>
      <Grid className={classes.breadCrumb}>
        <img src={BreadCrumbImg} alt="BreadCrumbImg" />
      </Grid>
      <Grid className={classes.consentSection}>
        <Typography className={[classes.titleText, classes.mobilePadding].join("")}>
          We use Cookies
        </Typography>
        <Typography className={[classes.subTitleText, classes.mobilePadding].join("")}>
          DroneBase uses cookies to collect session information for improving
          your website experience.
        </Typography>
        <div className={classes.buttonSection}>
          <CustomButton
            variant="outlined"
            onClick={manageCookieHandler}
            text="Manage Cookies"
            style={{
              background: "#FFFFFF",
              color: "#536471",
              border: "1px solid #DDE5EC",
            }}
          />
          <CustomButton
            variant="outlined"
            onClick={allowCookies}
            text="Allow Cookies"
            style={{
              marginLeft: "12px",
              background: "#5B4DD3",
              color: "#FFFFFF",
              border: "none",
            }}
          />
        </div>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(CookieBlock);
