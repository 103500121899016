/* eslint-disable no-case-declarations */

import {
  Container,
  Divider,
  Grid,
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { Trans, useTranslation, withTranslation } from "react-i18next";
import {
  setAllFilesAwsParamsAction,
  setAllFilesReduxAction,
  setAnomaliesListAction,
  setRequestObjAction,
  setShowUploadBlockAction,
} from "../../../reducers/bulkUpload";

import BackArrow from "../../../assets/img/backarrowNew.svg";
import CloseIcon from "@material-ui/icons/Close";
import CustomButton from "../../common/customStyle/Button";
import CustomDropdown from "../../common/customStyle/Dropdown";
import CustomTooltip from "../../common/customStyle/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import { URLS } from "../../../constants";
import { allRequestHandler } from "../../../api";
import { connect } from "react-redux";
import i18n from "i18next";
import styles from "../defectMap.style";
import { withStyles } from "@material-ui/core/styles";

//review anomaly popover after uploading data
const ReviewAnomaly = ({
  classes,
  bulkUpload,
  onHandleReviewAnomalyPopup,
  onHandleBackToImportData,
  setAllFilesAwsParamsAction,
  setAnomaliesListAction,
  setShowUploadBlockAction,
  sectionInspectionId,
  bulkDataUpload
}) => {
  const [anomaliesList, setAnomaliesList] = useState(bulkUpload.anomaliesList);
  const [editRowIdx, setEditRowIdx] = useState(-1);
  const { t } = useTranslation();

  //handle review anomaly popup
  const handlePopup = () => {
    onHandleReviewAnomalyPopup();
  };

  //redirect to import data
  const handleBack = () => {
    onHandleBackToImportData();
  };

  //To start editing
  const startEditing = (id) => {
    setEditRowIdx(id);
  };
  let statusMenuItems = [];
  statusMenuItems = [
    { name: i18n.t("Pending"), value: "pending" },
    { name: i18n.t("Resolved"), value: "resolved" },
    { name: i18n.t("In Progress"), value: "inprogress" },
    { name: i18n.t("Not Found"), value: "notfound" },
  ];

  //Handles input validation for anomalies
  const validator = (list, value, key, id) => {
    const statusList = [
      "pending",
      "inprogress",
      "falsepositive",
      "notfound",
      "resolved",
    ];
    switch (key) {
      // Status validation
      case "status": {
        if (value && !statusList.includes(value)) {
          const newAnomalies = list.map((row, index) => {
            if (index === id) {
              return {
                ...row,
                errors: {
                  ...row.errors,
                  [key]: <Trans>Invalid Status</Trans>,
                },
              };
            }
            return row;
          });
          return newAnomalies;
        }

        const newAnomaliesTwo = list.map((row, index) => {
          if (index === id && row.errors && row.errors[key])
            delete row.errors[key];

          return row;
        });
        return newAnomaliesTwo;
      }
      //Not a number validation
      case "defect_panel_current":
      case "defect_module_power":
      case "healthy_module_power":
      case "defect_panel_voltage":
      case "defect_max_power_current":
      case "defect_max_power_voltage":
      case "healthy_panel_current":
      case "healthy_panel_voltage":
      case "healthy_max_power_current":
      case "healthy_max_power_voltage":
      case "string_current": {
        if (
          (value && typeof parseInt(value, 10) !== "number") ||
          value < 0 ||
          value > 999
        ) {
          const newAnomalies = list.map((row, index) => {
            if (index === id) {
              if (value < 0 || value > 999) {
                return {
                  ...row,
                  errors: {
                    ...row.errors,
                    [key]: "Values should be within the limit of 0-999",
                  },
                };
              }
              return {
                ...row,
                errors: {
                  ...row.errors,
                  [key]: "Needs to be number",
                },
              };
            }

            return row;
          });
          return newAnomalies;
        }
        const newAnomalies1 = list.map((row, index) => {
          if (index === id && row.errors && row.errors[key])
            delete row.errors[key];

          return row;
        });
        return newAnomalies1;
      }
      //Image Validation
      case "handheld_image":
      case "el_image":
      case "iv_data": {
        if (value && !bulkUpload.allFilenames.includes(value.trim())) {
          const newAnomalies = list.map((row, index) => {
            if (index === id) {
              return {
                ...row,
                errors: {
                  ...row.errors,
                  [key]: <Trans>File does not exist in attachments</Trans>,
                },
              };
            }
            return row;
          });
          return newAnomalies;
        }
        const newAnomalies2 = list.map((row, index) => {
          if (index === id && row.errors && row.errors[key]) {
            delete row.errors[key];
          }

          return row;
        });
        return newAnomalies2;
      }
      default:
        return list;
    }
  };

  //handle defect types, status and image types
  const handleType = (key) => {
    switch (key) {
      case "status":
      case "handheld_image":
      case "el_image":
      case "iv_data":
        return "text";
      case "defect_panel_current":
      case "defect_module_power":
      case "defect_panel_voltage":
      case "defect_max_power_current":
      case "defect_max_power_voltage":
      case "healthy_panel_current":
      case "healthy_panel_voltage":
      case "healthy_max_power_current":
      case "healthy_max_power_voltage":
      case "healthy_module_power":
      case "string_current":
        return "number";
      default:
        return null;
    }
  };

  //handle update in anomalies list
  const handleChange = (e, keyName, id) => {
    const { value } = e.target;
    let newAnomalies = anomaliesList.map((row, index) => {
      return index === id ? { ...row, [keyName]: value } : row;
    });
    newAnomalies = validator(newAnomalies, value, keyName, id);
    setAnomaliesListAction(newAnomalies);
    setAnomaliesList(newAnomalies);
  };

  //stop editing
  const stopEditing = () => {
    setEditRowIdx(-1);
  };

  //To handle errors
  const errorList = () => {
    const errorLists = new Array();
    anomaliesList.map((item) => {
      return errorLists.push(item.errors);
    });
    const err = new Array();
    errorLists.map((i) => {
      return i && err.push(Object.keys(i));
    });
    let len = 0;
    err.map((item) => {
      return (len = len + item.length);
    });
    return len;
  };
  const TableColumns = [
    { name: "Status", key: "status" },
    { name: "Handheld", key: "handheld_image" },
    { name: "EL Image", key: "el_image" },
    { name: "IV Data File", key: "iv_data" },
    { name: "Serial No", key: "string_reference" },
    { name: "Panel Id", key: "panel_reference" },
    { name: "Manufacturer", key: "panel_manufacturer" },
    { name: "Module Isc(A)", key: "defect_panel_current" },
    { name: "Module Wp(W)", key: "defect_module_power" },
    { name: "Module Voc(V)", key: "defect_panel_voltage" },
    { name: "Module Imp(A)", key: "defect_max_power_current" },
    { name: "Module Vmp(V)", key: "defect_max_power_voltage" },
    { name: "Ref Module Isc(A)", key: "healthy_panel_current" },
    { name: "Ref Module Wp(W)", key: "healthy_module_power" },
    { name: "Ref Module Voc(V)", key: "healthy_panel_voltage" },
    { name: "Ref Module Imp(A)", key: "healthy_max_power_current" },
    { name: "Ref Module Vmp(V)", key: "healthy_max_power_voltage" },
    { name: "String Current(A)", key: "string_current" },
  ];

  //for modification of status name
  const statusFormat = (status) => {
    let stat = status;
    switch (status) {
      case "pending":
        stat = i18n.t("Pending");
        break;
      case "inprogress":
        stat = i18n.t("In Progress");
        break;
      case "notfound":
        stat = i18n.t("Not Found");
        break;
      case "resolved":
        stat = i18n.t("Resolved");
        break;
      case "falsepositive":
        stat = i18n.t("False  +ve");
        break;
      default:
        stat = status;
    }
    return stat;
  };

  //function to handle data in a row
  const row = (anomaly, id, tablerow, startEdit, editIdx, handleChanges) => {
    const currentlyEditing = editIdx === id;
    return (
      <TableRow key={id}>
        <TableCell align="left" className={classes.tableCell}>
          <Grid className={classes.tableText} style={{ marginLeft: "20px" }}>
            #{anomaly && anomaly.defect_no}
          </Grid>
        </TableCell>
        {tablerow.map((y) => {
          return (
            <TableCell
              key={y.key}
              align="left"
              className={classes.tableCell}
              style={
                currentlyEditing
                  ? { background: "#FBFCFF", overflow: "visible" }
                  : { background: "#FFF" }
              }
            >
              <Grid style={{ display: "flex" }}>
                {currentlyEditing ? (
                  y.key === "status" ? (
                    anomaly[y.key] !== "falsepositive" ? (
                      <CustomDropdown
                        selectValue={anomaly[y.key]}
                        onHandleSelectChange={(e) => {
                          return handleChanges(e, y.key, id);
                        }}
                        menuItems={statusMenuItems.map((item) => {
                          return {
                            ...item,
                            id: item.value,
                          };
                        })}
                        fullWidth
                        height={30}
                        borderRadius={4}
                      />
                    ) : (
                      <Grid className={classes.tableInput}>
                        {statusFormat(anomaly[y.key])}
                      </Grid>
                    )
                  ) : anomaly.status !== "falsepositive" ? (
                    <OutlinedInput
                      style={{
                        height: "30px",
                        width: "120px",
                        background: "#FFF",
                        borderRadius: "4px",
                      }}
                      className={classes.tableText}
                      name={y.key}
                      type={handleType(y.key)}
                      value={anomaly && anomaly[y.key]}
                      onChange={(e) => {
                        return handleChanges(e, y.key, id);
                      }}
                    />
                  ) : (
                    <Grid className={classes.tableInput} />
                  )
                ) : (
                  <Grid
                    className={
                      anomaly.status !== "falsepositive"
                        ? [classes.tableInput, classes.addBorder].join(" ")
                        : classes.tableInput
                    }
                    onClick={() => {
                      return startEdit(id);
                    }}
                  >
                    {y.key === "status"
                      ? statusFormat(anomaly && anomaly[y.key])
                      : anomaly && anomaly[y.key]}
                  </Grid>
                )}
                {anomaly.errors && Object.keys(anomaly.errors).includes(y.key) && (
                  <Typography>
                    <CustomTooltip title={anomaly.errors[y.key]}>
                      <InfoIcon
                        style={{ marginTop: 5 }}
                        className={classes.errorIcon}
                      />
                    </CustomTooltip>
                  </Typography>
                )}
              </Grid>
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  //Handles AWS validation for files
  const getAwsParamsForFiles = async () => {
    const obj = {
      file_list: bulkUpload.requestObj.file_list,
      anomalies_list: anomaliesList,
    };
    const awsParamsForFiles = await allRequestHandler({
      requestType: "PUT",
      requestUrl: URLS.BULK_UPLOAD_FILES_AWS_PARAMS(sectionInspectionId),
      requestData: obj,
    });

    if (awsParamsForFiles) {
      if (awsParamsForFiles.aws_params_list?.length > 0) {
        const allFilesAwsParams = awsParamsForFiles.aws_params_list.map(
          (file) => {
            return {
              ...file,
              status: "inprogress",
              retryFunc: () => {
                /**/
              },
              retryCount: 0,
            };
          }
        );
        setAllFilesAwsParamsAction(allFilesAwsParams);
        setShowUploadBlockAction(true);
      }
      onHandleReviewAnomalyPopup();
    }
    bulkDataUpload(true);
  };

  return (
    <Grid className={classes.popupContainer}>
      <Container>
        <Grid
          className={classes.popupItem}
          style={{ maxWidth: "1172px", maxHeight: "73vh" }}
        >
          <Grid
            container
            justifyContent="space-between"
            style={{ display: "flex", flexDirection: "row", padding: "15px" }}
            onClick={() => {
              stopEditing(1);
            }}
          >
            <Typography
              className={classes.popUpTitle}
              id={"gtm_backReviewBulk"}
            >
              <img
                alt="backImg"
                src={BackArrow}
                onClick={() => {
                  handleBack();
                }}
                style={{ padding: "0 10px", cursor: "pointer" }}
                id={"gtm_backReviewBulk"}
                aria-hidden="true"
              />
              <Trans>REVIEW DETAILS</Trans>{" "}
            </Typography>
            <CloseIcon
              fontSize="large"
              className={classes.closeIcon}
              onClick={() => {
                handlePopup();
              }}
            />
          </Grid>
          <Divider />
          <Grid
            item
            xs={12}
            md={12}
            style={{ height: "55vh", overflow: "scroll" }}
          >
            <Table
              stickyHeader
              className={classes.table}
              aria-label="simple table"
            >
              <TableHead
                onClick={() => {
                  stopEditing(1);
                }}
              >
                <TableRow>
                  <TableCell
                    className={classes.tableText}
                    style={{
                      background: "#FBFCFF",
                      borderRight: "1px solid #E4EAF5",
                    }}
                  >
                    <Trans>Anomaly No.</Trans>
                  </TableCell>
                  {TableColumns.map((column) => {
                    return (
                      <TableCell
                        className={classes.tableText}
                        style={{
                          background: "#FBFCFF",
                          borderRight: "1px solid #E4EAF5",
                        }}
                        key={column.name}
                      >
                        <Trans>{column.name}</Trans>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {anomaliesList?.length > 0 &&
                  anomaliesList.map((anomaly, id) => {
                    return row(
                      anomaly,
                      id,
                      TableColumns,
                      startEditing,
                      editRowIdx,
                      handleChange,
                      stopEditing
                    );
                  })}
              </TableBody>
            </Table>
          </Grid>
          <Divider className={classes.marTB20} />
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              paddingRight: "30px",
              height: "50px",
            }}
            onClick={() => {
              stopEditing(1);
            }}
          >
            {errorList() > 0 && (
              <>
                <InfoIcon
                  className={classes.errorIcon}
                  style={{ marginRight: 4 }}
                />
                <Typography className={classes.tableText}>
                  {errorList()} <Trans>Discrepancy</Trans>
                </Typography>
              </>
            )}
            <CustomButton
              type="cancel"
              onClick={() => {
                handlePopup();
              }}
              text={t("cancel")}
              id={"gtm_cancelReviewBulk"}
            />
            <CustomButton
              variant="contained"
              className={classes.reviewButton}
              text={t("Start Upload")}
              onClick={() => {
                getAwsParamsForFiles();
              }}
              id={"gtm_uploadReviewBulk"}
            />
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

//updating bulkupload and language data in redux
const mapStateToProps = (state) => {
  return {
    bulkUpload: state.bulkUpload,
    language: state.language.selectedLanguage,
  };
};

export default connect(mapStateToProps, {
  setShowUploadBlockAction,
  setAllFilesAwsParamsAction,
  setAnomaliesListAction,
  setRequestObjAction,
  setAllFilesReduxAction,
})(withStyles(styles)(withTranslation()(ReviewAnomaly)));
