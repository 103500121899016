import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Trans, withTranslation } from "react-i18next";
import {
  dataFilterItems,
  toggleObj2,
} from "../../constants/dashboardConstants";

import CustomClear from "../common/customClear";
import CustomDropDown from "../common/customStyle/Dropdown";
import CustomFilterChip from "../common/customStyle/FilterChip";
import CustomToggleSwitch from "../common/customStyle/ToggleSwitch";
import CustomTooltip from "../common/customStyle/Tooltip";
import EmptyCard from "../common/emptychart";
import Filter from "../common/filters/filters";
import FilterImg from "../../assets/img/filter2.svg";
import InfoIcon from "@material-ui/icons/Info";
import NewLoader from "../../layout/newLoader";
import OutsideClickHandler from "react-outside-click-handler";
import PowerLossChart from "../common/charts/powerlosschart";
import _ from "lodash";
import { callSnackbarAction } from "../../reducers/snackbar";
import i18n from "i18next";
import moment from "moment";
import shortid from "shortid";
import { store } from "../../store";
import styles from "./dashboard.styles";

//power loss component
const PowerLoss = ({
  classes,
  data,
  applyLossesFilter,
  filterOptions,
  component,
  compName,
  metadata,
}) => {
  const [showFilter, setShowFilter] = useState(false);
  const [selectedMode, setSelectedMode] = useState("power");
  const [dashboardFilterChips, setDashboardFilterChips] = useState([]);
  const [dateFilter, setDateFilter] = useState("all");
  const [chartData, setChartData] = useState([]);
  const [isFiltering, setIsFiltering] = useState(false);
  const [allChartData, setAllChartData] = useState([]);
  const [lastWeekLossData, setLastWeekLossData] = useState([]);
  const [lastMonthLossData, setLastMonthLossData] = useState([]);
  const [lastYearLossData, setLastYearLossData] = useState([]);
  const [last90DayData, setLast90DayData] = useState([]);

  //This function is called whenever any props or state changes. (Currently monitoring "data" prop)
  useEffect(() => {
    const last7DayStart = moment().startOf("day").subtract(1, "week");
    const currentDay = moment().endOf("day").subtract(1, "day");
    const lastMonthThisDay = moment().startOf("day").subtract(1, "month");
    const lastYearThisDay = moment().startOf("day").subtract(1, "year");
    const last90DayThisDay = moment().startOf("day").subtract(3, "month");

    const filterData = (data, startDate, endDate) => {
      return _.filter(data && data.chart_data, (each) => {
        const datearray = each && each.date && each.date.split("/");
        const newdate = `${datearray[1]}/${datearray[0]}/${datearray[2]}`;
        return moment(newdate).isBetween(startDate, endDate);
      });
    };

    const filterFunctions = {
      year: (data) => {
        return {
          ...data,
          chart_data: filterData(data, lastYearThisDay, currentDay),
        };
      },
      "7days": (data) => {
        return {
          ...data,
          chart_data: filterData(data, last7DayStart, currentDay),
        };
      },
      "30days": (data) => {
        return {
          ...data,
          chart_data: filterData(data, lastMonthThisDay, currentDay),
        };
      },
      "90days": (data) => {
        return {
          ...data,
          chart_data: filterData(data, last90DayThisDay, currentDay),
        };
      },
    };

    setAllChartData(data);
    setLastWeekLossData({
      ...data,
      chart_data: filterFunctions["7days"](data).chart_data,
    });
    setLastMonthLossData({
      ...data,
      chart_data: filterFunctions["30days"](data).chart_data,
    });
    setLastYearLossData({
      ...data,
      chart_data: filterFunctions["year"](data).chart_data,
    });
    setLast90DayData({
      ...data,
      chart_data: filterFunctions["90days"](data).chart_data,
    });

    dateFilter === "year"
      ? setChartData(lastYearLossData)
      : dateFilter === "7days"
      ? setChartData(lastWeekLossData)
      : dateFilter === "30days"
      ? setChartData(lastMonthLossData)
      : dateFilter === "90days"
      ? setChartData(last90DayData)
      : setChartData(data);
  }, [data]);

  //This function is called when the date filter is changed
  const handleDateFilter = (e) => {
    const value = e.target.value;
    setDateFilter(e.target.value);

    if (value === "7days") {
      setChartData(lastWeekLossData);
    } else if (value === "30days") {
      setChartData(lastMonthLossData);
    } else if (value === "year") {
      setChartData(lastYearLossData);
    } else if (value === "90days") {
      setChartData(last90DayData);
    } else {
      setChartData(allChartData);
    }
  };

  //Handles the data change when the toggle is changed from power loss to revenue loss and vice versa
  const chartChange = (e, newData) => {
    if (newData !== null) setSelectedMode(newData);
  };

  //Remove all the filters
  const clearFilter = async (action) => {
    setDashboardFilterChips([]);
    setShowFilter(false);
    setIsFiltering(true);
    await applyLossesFilter(
      [],
      "All",
      [],
      ["All"],
      ["All"],
      [],
      [],
      { start: 0, end: 100 },
      ["All"],
      action
    );
    setIsFiltering(false);
  };

  const handleDeleteChip = (dataItem, action) => {
    setDashboardFilterChips((prevChips) => {
      return prevChips.filter((item) => item.name !== dataItem.name);
    });
    if (dashboardFilterChips.length === 1) {
      return clearFilter(action);
    }
    dataItem.delete(dataItem.name);
  };

  // function for sorting data wrt to date
  const sortDates = (chartDataVal) => {
    if (!chartDataVal) {
      return [];
    }
    return chartDataVal.chart_data.sort((a, b) => {
      const firstParam = a.date.split("/").reverse().join("-");
      const secondParam = b.date.split("/").reverse().join("-");
      return secondParam.localeCompare(firstParam);
    });
  };

  //Renders the powerloss chart that displays the power loss and revenue loss data
  return (
    <Card
      className={classes.powerLossCard}
      style={{ margin: component === "sites" ? "0 24px" : 0 }}
    >
      <CardContent style={styles.contentMob}>
        <Grid container>
          <Grid item xs className={classes.benchmarkBar}>
            <Typography style={styles.chartHeading}>
              <Trans>Loss Recovered</Trans>
            </Typography>
            <Grid item style={{ marginTop: 23 }}>
              <CustomTooltip
                title={i18n.t(
                  "Anomalies processed includes false +ve, resolved  and not Found"
                )}
              >
                <InfoIcon
                  style={{
                    ...styles.infoIconDark,
                  }}
                />
              </CustomTooltip>
            </Grid>
            <div
              className={classes.powerTogglebt}
              style={{ marginLeft: "-2px" }}
            >
              <CustomToggleSwitch
                value={selectedMode}
                onChange={chartChange}
                btnGroup={toggleObj2}
              />
            </div>
          </Grid>
          <Grid item>
            <div
              className={[classes.grid, classes.dateMenuContainer].join(" ")}
            >
              <CustomDropDown
                selectValue={dateFilter}
                onHandleSelectChange={handleDateFilter}
                menuItems={dataFilterItems}
                minWidth={100}
              />
              {dashboardFilterChips.length > 0 ? (
                <CustomClear classes={classes} Filter={clearFilter} />
              ) : null}
              <Button
                onClick={() => {
                  return setShowFilter(!showFilter);
                }}
                className={classes.filterButton}
                variant="outlined"
                data-intercom-target="powerloss-chart"
              >
                <Trans>Filters</Trans>
                {dashboardFilterChips.length > 0 ? (
                  <span className={classes.filter}>
                    {dashboardFilterChips.length}
                  </span>
                ) : (
                  <img className={classes.image} src={FilterImg} alt="filter" />
                )}
              </Button>
            </div>
          </Grid>
        </Grid>

        <Typography className={classes.chartDes}>
          <InfoIcon
            style={{
              ...styles.infoIconLight,
            }}
          />
          <Trans>
            Revenue recovered is calculated only for Sites with yield & tariff
            values provided and for Anomalies with loss factor.
          </Trans>
        </Typography>
        <Divider />

        <div
          className={`${classes.pad12} ${classes.scrollBar}`}
          item="true"
          md={12}
          style={{ width: "-webkit-fill-available" }}
          data-intercom-target="powerloss-chart"
        >
          <div className={classes.filterchip1}>
            <div className={classes.flexWrap}>
              {dashboardFilterChips
                .filter((dataItem) => {
                  return !(
                    dataItem.type === "temperature_range" &&
                    dataItem.value[0] === 0 &&
                    dataItem.value[1] === 100
                  );
                })
                .map((dataItem) => (
                  <CustomFilterChip
                    key={shortid.generate()}
                    onDelete={() => handleDeleteChip(dataItem, true)}
                    type={dataItem.type}
                    name={dataItem.name}
                    lowerTempValue={dataItem.value && dataItem.value[0]}
                    higherTempValue={dataItem.value && dataItem.value[1]}
                  />
                ))}
            </div>
          </div>

          <div className={classes.stackchart}>
            {selectedMode === "power" ? (
              chartData?.chart_data &&
              chartData.chart_data.length !== 0 &&
              !isFiltering ? (
                <PowerLossChart
                  chartType={selectedMode}
                  chartData={sortDates(chartData)}
                  data={chartData ? chartData : null}
                  compName={compName}
                />
              ) : (
                <div className={classes.internalbenchmark}>
                  <NewLoader />
                </div>
              )
            ) : chartData?.chart_data &&
              chartData.chart_data.length !== 0 &&
              !isFiltering &&
              metadata &&
              metadata.revenue_loss ? (
              <PowerLossChart
                chartType={selectedMode}
                chartData={chartData.chart_data}
                data={chartData ? chartData : null}
                compName={compName}
              />
            ) : (
              <div className={classes.internalbenchmark}>
                <NewLoader />
              </div>
            )}
            <OutsideClickHandler
              onOutsideClick={() => {
                return setShowFilter(false);
              }}
            >
              {showFilter && (
                <div className={classes.showfilter}>
                  <Filter
                    status
                    filters={filterOptions}
                    applyFilter={async (
                      defect_types,
                      inspection_type,
                      module_type,
                      severity_types,
                      status_types,
                      iec_category,
                      plant_names,
                      temperature_range,
                      anomaly_has
                    ) => {
                      if (!metadata) {
                        return store.dispatch(
                          callSnackbarAction(
                            "Loading is in progress try after sometime"
                          )
                        );
                      } else {
                        setIsFiltering(true);

                        await applyLossesFilter(
                          defect_types,
                          inspection_type,
                          module_type,
                          severity_types,
                          status_types,
                          iec_category,
                          plant_names,
                          temperature_range,
                          anomaly_has
                        );

                        setIsFiltering(false);
                      }
                    }}
                    filterChips={dashboardFilterChips}
                    closeFilter={() => {
                      return setShowFilter(false);
                    }}
                    addFilterChips={(filterChips) => {
                      setDashboardFilterChips(filterChips);
                    }}
                    onClearFilter={() => clearFilter(true)}
                  />
                </div>
              )}
            </OutsideClickHandler>
            {chartData?.chart_data?.length === 0 &&
              selectedMode === "power" && (
                <EmptyCard
                  compName="powerloss"
                  type="power"
                  cardName="internal"
                  userDetails={metadata}
                />
              )}
            {chartData?.chart_data?.length === 0 &&
              selectedMode === "revenue" &&
              metadata &&
              metadata.revenue_loss && (
                <EmptyCard
                  compName="powerloss"
                  type="revenue"
                  cardName="internal"
                  statement="No Anomalies resolved for the selected filters"
                  button={false}
                  userDetails={metadata}
                />
              )}

            {chartData?.chart_data?.length !== 0 &&
              selectedMode === "revenue" &&
              metadata &&
              !metadata.revenue_loss && (
                <EmptyCard
                  compName="powerloss"
                  type="revenue"
                  cardName="internal"
                  statement="See how your team recover revenue losses over time "
                  button
                  userDetails={metadata}
                />
              )}

            {chartData?.chart_data?.length === 0 &&
              selectedMode === "revenue" &&
              metadata &&
              !metadata.revenue_loss && (
                <EmptyCard
                  compName="powerloss"
                  type="revenue"
                  cardName="internal"
                  statement="See how your team recover revenue losses over time "
                  button
                  userDetails={metadata}
                />
              )}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(withTranslation()(PowerLoss));
