import {
  FormControl,
  MenuItem,
  Popper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import { Trans } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";

//dropdown styles
const styles = () => {
  return {
    itemSelect: {
      height: 36,
      marginRight: 20,
      "@media only screen and (max-width: 600px)": {
        minWidth: "auto",
      },
    },
    itemMenu: {
      fontFamily: "Montserrat",
      fontWeight: 500,
      fontSize: "12px",
      height: 36,
      margin: 0,
      "& div": {
        display: "flex",
        color: "#08001D",
        padding: "8px 26px 8px 8px",
      },
      "@media only screen and (max-width: 720px)": {
        margin: 0,
        minWidth: "auto",
      },
    },
    Menu: {
      background: "#FFFFFF",
      border: "1px solid #F0F4FA",
      boxShadow: "0px 4px 12px rgba(78, 89, 109, 0.05)",
      borderRadius: "5px",
    },
    itemsList: {
      color: "#08001D",
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      "&:hover": {
        backgroundColor: "#F0F4FA",
      },
    },
    selectedList: {
      color: "#5B4DD3 !important",
      backgroundColor: "#FFFFFF !important",
    },
    itemName: {
      fontSize: 12,
      fontWeight: 500,
      fontFamily: "Montserrat",
      letterSpacing: "normal",
      lineHeight: "16px",
    },
    secondItem: {
      letterSpacing: "normal",
      fontFamily: "Montserrat",
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "16px",
      opacity: 0.6,
    },
    lists: {
      "&:hover": {
        backgroundColor: "#F0F4FA !important",
      },
    },
    inputRoot: {
      padding: "0px !important",
      minWidth: "inherit",
      background: "white",
      color: "#08001D",
      fontFamily: "Montserrat",
      fontWeight: 500,
      fontSize: "12px",
    },
    popper: {
      width: "fit-content",
    },
  };
};

//custom dropdown
class CustomDropdown extends React.Component {
  //Renders a custom styled dropdown menu
  render() {
    const {
      classes,
      name,
      menuItems,
      defaultValue,
      variant,
      fullWidth,
      selectValue,
      onHandleSelectChange,
      secondItemName,
      minWidth,
      ignoreMenuLength,
    } = this.props;

    const menuNum = menuItems?.length;

    //Autofill the dropdown with the default value
    const autoCompleteOnchangeHandler = (e, item) => {
      if (item && item.value) {
        const event = {
          target: {
            value: item?.value,
            name: name ? name : null,
          },
        };
        onHandleSelectChange(event);
      }
    };

    //Custom styles
    const CustomPopper = function (props) {
      return fullWidth ? (
        // skipcq Avoid using spreading operator for JSX props JS-0428
        <Popper {...props} />
      ) : (
        // skipcq Avoid using spreading operator for JSX props JS-0428
        <Popper {...props} style={styles.popper} placement="bottom-start" />
      );
    };

    //Trim the long strings to fit the dropdown width and add "..." at the end
    const truncateString = function (str, length = 100) {
      const ending = "...";
      if (str.length >= length) {
        return str.substring(0, length - ending.length) + ending;
      }

      return str;
    };

    return (
      <FormControl
        variant={variant}
        className={classes.itemSelect}
        style={{
          width: fullWidth ? "100%" : "fit-content",
          minWidth: minWidth ? minWidth : "inherit",
        }}
      >
        {menuNum > 6 && ignoreMenuLength ? (
          <Autocomplete
            // eslint-disable-next-line react/jsx-no-bind
            PopperComponent={CustomPopper}
            autoComplete
            autoHighlight
            closeIcon={null}
            options={menuItems}
            classes={{
              inputRoot: classes.inputRoot,
            }}
            getOptionLabel={(item) => {
              return truncateString(item.name, 21);
            }}
            defaultValue={
              menuItems.filter((item) => {
                return item.value === selectValue;
              })[0]
            }
            value={
              menuItems.filter((item) => {
                return item.value === selectValue;
              })[0]
            }
            renderOption={(item) => {
              return (
                <div className={classes.itemsList}>
                  <Typography
                    className={classes.itemName}
                    style={{ marginRight: 5 }}
                  >
                    <Trans>{item.name}</Trans>
                  </Typography>
                  {secondItemName && (
                    <Typography className={classes.secondItem}>
                      <Trans>{item.location}</Trans>
                    </Typography>
                  )}
                </div>
              );
            }}
            onChange={autoCompleteOnchangeHandler}
            renderInput={(params) => {
              return (
                <TextField
                  // skipcq comment above line
                  {...params}
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              );
            }}
          />
        ) : (
          <Select
            value={selectValue ? String(selectValue) : defaultValue}
            MenuProps={{
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              classes: { paper: classes.Menu },
            }}
            onChange={(e) => {
              return onHandleSelectChange(e);
            }}
            className={classes.itemMenu}
            name={name ? name : null}
          >
            {defaultValue && (
              <MenuItem
                className={classes.MenuItem}
                value=""
                disabled
                ListItemClasses={{
                  root: classes.lists,
                  selected: classes.selectedList,
                }}
              >
                <Trans>{defaultValue}</Trans>
              </MenuItem>
            )}

            {menuItems &&
              menuItems.map((item) => {
                return (
                  <MenuItem
                    value={item.value}
                    key={item.value}
                    ListItemClasses={{
                      root: classes.itemsList,
                      selected: classes.selectedList,
                    }}
                  >
                    <Typography
                      className={classes.itemName}
                      style={{ marginRight: 5 }}
                    >
                      <Trans>{item.name}</Trans>
                    </Typography>
                    {secondItemName && (
                      <Typography className={classes.secondItem}>
                        <Trans> {item.location} </Trans>
                      </Typography>
                    )}
                  </MenuItem>
                );
              })}
          </Select>
        )}
      </FormControl>
    );
  }
}

//default props for the dropdown component
// skipcq static properties should be positioned JS-0472
CustomDropdown.defaultProps = {
  variant: "outlined",
  secondItemName: false,
  defaultValue: false,
  fullWidth: false,
  minWidth: false,
  ignoreMenuLength: true,
};

export default withStyles(styles)(CustomDropdown);
