export default {
  backBtnContainer: {
    display: "flex",
    marginTop: "-2%",
    paddingBottom: "8%",
  },
  imgLogo: {
    position: "relative",
    top: "-50px",
    width: 200,
    "@media (max-width: 599px)": {
      position: "relative",
      top: -2,
      left: "1%",
      width: 200,
    },
    "@media (max-width: 399px)": {
      position: "relative",
      top: -2,
      left: "1%",
      width: 200,
    },
  },
  loginText: {
    fontSize: 20,
    fontWeight: 700,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#21282d",
    marginTop: "-20px",
  },
  sendBtn: {
    margin: "20px 0",
    backgroundColor: "#fff",
    background: "linear-gradient(266.69deg,#5B4DD3 0.23%,#5B4DD3 99.28%)",
    boxShadow: "0px 2px 2px rgba(0,132,255,0.2)",
    borderRadius: "8px",
    width: "100%",
    height: "44px",
    textTransform: "uppercase",
    fontFamily: "Montserrat",
  },
  btnLoginLabel: {
    color: "#FFFFFF",
    fontWeight: 600,
    fontSize: 14,
  },
  loginInput: {
    "& input": {
      fontFamily: "Montserrat !important",
      fontSize: 12,
      fontStyle: "normal",
      fontWeight: 500,
      color: "#21282d",
      margin: "5px 2px 5px 2px",
    },
  },
  versionTxt: {
    position: "relative",
    top: "-50px",
    left: "51%",
    color: "#0000008a",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    "@media (max-width: 760px)": {
      top: "-5px",
      left: "53%",
      position: "relative",
    },
  },
  mobBox: {
    marginTop: 100,
    "@media (max-width: 1024px)": {
      marginTop: 125,
      padding: 10,
    },
    "@media (max-width: 600px)": {
      marginTop: 25,
      padding: 10,
    },
  },
  illustrationImg: {
    maxWidth: "100%",
    maxHeight: "100vh",
  },
  backLabel: {
    margin: 0,
    fontSize: "13px",
    fontWeight: 500,
    color: "#21282d",
    fontStyle: "normal",
  },
};
