import Checkbox from "../../views/common/customStyle/CheckBox";
import CustomDropdown from "../../views/common/customStyle/CustomDropdown";
import CustomTooltip from "../../views/common/customStyle/Tooltip";
import React from "react";
import { Trans } from "react-i18next";
import i18n from "i18next";
import styles from "./sidebar.style";
import { withStyles } from "@material-ui/core";

const languages = [
  {
    name: "English – EN",
    value: "en",
  },
  {
    name: "Español – ES",
    value: "sp",
  },
];

const ChangeLanguageComp = ({
  setSelectedLanguage,
  changeLanguage,
  language,
  setOpen,
  classes,
  item,
  path,
}) => {
  const handleChange = (e, item) => {
    setSelectedLanguage(item.value);
    changeLanguage(item.value);
  };

  //common checkbox component
  const languageMenuItems = () => {
    return (
      <div>
        <p className={classes.outerMenuHeading}>
          <Trans>Change Language</Trans>
        </p>
        {languages.map((item) => {
          return (
            <div key={item.name}>
              <Checkbox
                roundIcon
                darkText
                label={item.name}
                checked={language === item.value}
                onChange={(e) => {
                  return handleChange(e, item);
                }}
              />
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className={classes.languageLinkStyles}>
      {setOpen ? (
        <CustomDropdown
          buttonLabel={
            <div className={classes.sidebarmenuactive}>
              <img src={item.icon.active} />
              <span
                className={
                  path === "/language"
                    ? classes.linkStylesActiveBottom
                    : classes.linkStylesBottom
                }
              >
                <Trans>Language</Trans>
                <Trans> : {language === "en" ? "English" : "Spanish"}</Trans>
              </span>
            </div>
          }
          outerMenuClass={classes.outerMenu}
          closeOnClick
          menuItems={languageMenuItems()}
        />
      ) : (
        <div className={classes.languageTooltipOption}>
          <CustomDropdown
            buttonLabel={
              <CustomTooltip
                title={i18n.t(
                  language === "en" ? "Language : English" : "Language :Spanish"
                )}
                placement="right"
                arrow
                classes={{
                  tooltip: classes.sidebarTooltip,
                  arrow: classes.tooltipArrow,
                }}
              >
                <div
                  className={
                    path === "/language"
                      ? classes.sidebarclosedactive
                      : classes.sidebarclosed
                  }
                >
                  <img src={item.icon.active} />
                </div>
              </CustomTooltip>
            }
            outerMenuClass={classes.outerMenuClosedSB}
            closeOnClick
            menuItems={languageMenuItems()}
          />
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(ChangeLanguageComp);
