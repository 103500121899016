import "./index.css";

import React, { Component } from "react";

import VideoItem from "./videoItem";
import { styles } from "./Help.style";
import { withStyles } from "@material-ui/core/styles";

//Render the video list
class VideoList extends Component {
  //renders the the list of videoItems (UI only)
  render() {
    const { videos, onVideoSelect } = this.props;
    const videoList = videos.map((video) => {
      return (
        <VideoItem key={video} video={video} onVideoSelect={onVideoSelect} />
      );
    });
    return videoList;
  }
}
export default withStyles(styles)(VideoList);
