import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Trans, withTranslation } from "react-i18next";
import {
  siteInspectionTableHeader,
  siteInspectionTotal,
} from "../../../constants/dashboardConstants";
import Config from "../../../config";
import CustomTooltip from "../customStyle/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import NewLoader from "../../../layout/newLoader";
import _ from "lodash";
import { allRequestHandler } from "../../../api";
import currencValConverter from "../../../utils/currencyValConverter";
import i18n from "i18next";
import powerEnergyConverter from "../../../utils/powerEnergyConverter";
import powerValConverter from "../../../utils/powerEnergyConverter";
import shortid from "shortid";

const requestUrl = Config.hostName;

//Inspection details
function InspectionDetails(props) {
  const [inspDetails, setInspDetails] = useState(null);
  const [current, setCurrent] = useState(0);

  // Update details on the change of inspection
  useEffect(() => {
    if (inspDetails && inspDetails.length) {
      setCurrent(inspDetails.length - 1);
    }
  }, [inspDetails]);

  //Get inspection data
  useEffect(() => {
    getData();
  }, [props.duration]);

  //get inspection details
  const getData = async () => {
    const getInspList = await allRequestHandler({
      requestType: "GET",
      requestUrl: `${requestUrl}${props.reference}?days=${props.duration}`,
    });
    const sortDate = _.sortBy(getInspList.inspection_list, "inspection_date");
    setInspDetails(sortDate);
  };

  const updateTextData = (field, val) => {
    switch (field) {
      case "Anomaly Name":
      case "Anomalies":
      case "Anomalies Resolved":
        return val ? val : "0";
      case "Affected DC %":
        return val ? val + " %" : "0";
      case "Revenue Loss":
        return val ? props.currency + val : "0";
      case "Affected DC":
        return val ? powerValConverter("power", val) : "-";
      case "Energy Loss":
        return val ? powerValConverter("energy", val) : "-";
      default:
        return val ? val.toFixed(2) : "0";
    }
  };

  const { classes } = props;

  return (
    <>
      {/* <TableCell colSpan={10}> */}

      {/* </TableCell> */}

      <TableCell colSpan="10">
        <Table
          aria-label="inspection table"
          style={{ background: "#fff", borderRadius: 10 }}
        >
          <TableHead>
            <TableRow>
              <div className={classes.inspDateHeader}>
                {inspDetails &&
                  inspDetails.map((insp, index) => {
                    return (
                      <span
                        key={insp.inspection_date}
                        style={{ minWidth: 120, textAlign: "center" }}
                      >
                        <Button
                          onClick={() => {
                            setCurrent(index);
                          }}
                          className={classes.dataTab}
                          style={{ color: current === index && "#5B4DD3" }}
                        >
                          {Intl.DateTimeFormat("en-US", {
                            day: "2-digit",
                            month: "short",
                            year: "numeric",
                          }).format(new Date(insp.inspection_date))}
                        </Button>
                        <div
                          style={{
                            height: "2px",
                            background: current === index ? "#5B4DD3" : "#fff",
                          }}
                        />
                      </span>
                    );
                  })}
              </div>
            </TableRow>

            <TableRow style={{ background: "#FBFCFF" }}>
              {siteInspectionTableHeader &&
                siteInspectionTableHeader.map((headerLoop, index) => {
                  return (
                    <TableCell key={index} className={classes.inspTableHeader}>
                      <Grid
                        container
                        justifyContent={"flex-start"}
                        className={classes.heavy}
                      >
                        <Trans> {headerLoop.name} </Trans>

                        {headerLoop.tooltipTxt && (
                          <CustomTooltip
                            title={<div>{i18n.t(headerLoop.tooltipTxt)}</div>}
                          >
                            <InfoIcon className={classes.infoIcon} />
                          </CustomTooltip>
                        )}
                      </Grid>
                    </TableCell>
                  );
                })}
              <TableCell colSpan={3} style={{ minWidth: 100 }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* Display loader till API data load for inspection details */}
            {!inspDetails && (
              <TableRow>
                <TableCell colSpan={7}>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    style={{ minHeight: 400 }}
                  >
                    <NewLoader />
                  </Grid>
                </TableCell>
              </TableRow>
            )}
            {inspDetails?.[current]?.anomaly_list &&
              inspDetails[current]?.anomaly_list.map((bodyOuterLoop) => {
                return (
                  <>
                    <TableRow key={bodyOuterLoop.plant_id}>
                      {siteInspectionTableHeader.map((bodyInnerLoop, index) => {
                        return (
                          <TableCell
                            key={index}
                            style={{ padding: "10px 12px" }}
                          >
                            <Typography
                              className={classes.left}
                              style={{
                                textAlign:
                                  bodyInnerLoop.name == "Anomaly Name"
                                    ? "left"
                                    : "right",
                              }}
                            >
                              {updateTextData(
                                bodyInnerLoop.name,
                                bodyOuterLoop[bodyInnerLoop.value]
                              )}
                            </Typography>
                          </TableCell>
                        );
                      })}

                      <TableCell
                        colSpan={3}
                        style={{ minWidth: 100 }}
                      ></TableCell>
                    </TableRow>
                  </>
                );
              })}

            {/* Display Total values in last row */}
            {inspDetails &&
              siteInspectionTotal.map((totalLoop) => {
                return (
                  <TableCell
                    key={shortid.generate()}
                    style={{
                      padding: "10px 12px",
                    }}
                  >
                    <Typography
                      className={classes.heavy}
                      style={{
                        textAlign: "left",
                      }}
                    >
                      {totalLoop === "Total"
                        ? "Total"
                        : inspDetails[current][totalLoop] &&
                          totalLoop === "total_energy_loss"
                        ? powerEnergyConverter(
                            "energy",
                            inspDetails[current][totalLoop]
                          )
                        : inspDetails[current][totalLoop] &&
                          totalLoop === "total_revenue_loss"
                        ? `${props.currency} ${currencValConverter(
                            inspDetails[current][totalLoop]
                          )}`
                        : inspDetails[current][totalLoop] &&
                          totalLoop === "total_affected_dc"
                        ? powerEnergyConverter(
                            "power",
                            inspDetails[current][totalLoop]
                          )
                        : inspDetails[current][totalLoop] &&
                          totalLoop === "total_affected_dc_percentage"
                        ? `${inspDetails[current][totalLoop]} %`
                        : inspDetails[current][totalLoop] &&
                          (totalLoop === "total_anomalies_count" ||
                            totalLoop === "total_anomalies_resolved")
                        ? inspDetails[current][totalLoop]
                        : "0"}
                    </Typography>
                  </TableCell>
                );
              })}
          </TableBody>
        </Table>
      </TableCell>
    </>
  );
}
export default withTranslation()(InspectionDetails);