export default {
  btnImg: {
    height: 16,
    width: 16,
    marginTop: 0,
    marginLeft: 4,
    marginRight: 4,
  },
  fltBtn: {
    color: "#536471",
    borderRadius: "8px",
    background: "rgba(91, 77, 211, 0.1)",
    border: "1px solid #0084FF",
    fontSize: 12,
    "&:focus": {
      outline: "none !important",
    },
  },
  disableFltBtn: {
    color: "#536471",
    borderRadius: "8px",
    fontSize: 14,
    "&:focus": {
      outline: "none !important",
    },
  },
  selectBlock: {
    width: "135px",
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "12px",
    color: "#536471",
    height: "32px",
    marginTop: 4,
    "& div": {
      marginBottom: 3,
    },
  },
  blockMenu: {
    marginTop: "4px",
  },
  btnLabel: {
    pointerEvents: "none",
  },
  plantName: {
    marginTop: "5px",
    alignItems: "center",
    display: "flex",
  },
};
