export default {
  textContainer: {
    background: "linear-gradient(266.69deg, #FFBC11 0.23%, #FF9F1B 99.28%)",
    borderRadius: "4px",
    padding: "2px 5px 0 5px",
    color: "#FFFFFF",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "10px",
    lineHeight: "12px",
  },
};
