import "./index.css";

import { Divider, Grid, Paper } from "@material-ui/core";
import React, { Component } from "react";

import { styles } from "./Help.style";
import { withStyles } from "@material-ui/core/styles";

//To access video
class VideoItem extends Component {
  //Renders the video item (UI only)
  render() {
    const { classes, video, onVideoSelect } = this.props;

    return (
      video && (
        <Grid item xs={4}>
          <Paper className={classes.paper} onClick={onVideoSelect(video)}>
            <img
              src={video.thumbnail}
              alt="videoThumbnail"
              style={{
                height: "200px",
                width: "250px",
              }}
            />
            <div className={classes.papertitle}>{video.name}</div>
          </Paper>
          <Divider style={{ margin: "10px" }} />
        </Grid>
      )
    );
  }
}
export default withStyles(styles)(VideoItem);
