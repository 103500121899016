import "./downloadMenu.style.css";

import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";

import React, { Component } from "react";

import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { connect } from "react-redux";
import i18n from "i18next";

// skipcq Avoid using wildcard imports JS-C1003

am4core.useTheme(am4themes_animated);
am4core.options.queue = true;

//donut chart
class DonutChart extends Component {
  //Handles the chart creation
  createChart = ({ anomalies_list }) => {
    //Initialize the chart type
    const chart = am4core.create("chartdiv", am4charts.PieChart);
    const colorSet = new am4core.ColorSet();

    //Chart data processing
    const anomalyData = [];
    anomalies_list.forEach(({ color, count, name }) => {
      colorSet.list.push(new am4core.color(color));
      anomalyData.push({
        anomaly: i18n.t(name),
        count,
      });
    });

    chart.data = anomalyData;
    chart.innerRadius = am4core.percent(63);

    // Add and configure Series
    const pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "count";
    pieSeries.dataFields.category = "anomaly";
    pieSeries.tooltip.getFillFromObject = false;
    pieSeries.showOnInit = false;
    pieSeries.sequencedInterpolation = true;

    pieSeries.slices.template.stroke = am4core.color("#fff");
    pieSeries.tooltip.background.fill = am4core.color("#fff");

    pieSeries.slices.template.cursorOverStyle =
      am4core.MouseCursorStyle.pointer;

    pieSeries.tooltip.label.fill = am4core.color("ccc");
    pieSeries.tooltip.label.fontFamily = "Montserrat";
    pieSeries.tooltip.label.fontWeight = 600;
    pieSeries.slices.template.strokeWidth = 3;
    pieSeries.slices.template.strokeOpacity = 1;
    pieSeries.labels.template.disabled = true;
    pieSeries.slices.template.cornerRadius = 5;
    pieSeries.colors = colorSet;
    // This creates initial animation

    pieSeries.hiddenState.properties.opacity = 1;
    pieSeries.hiddenState.properties.endAngle = -90;
    pieSeries.hiddenState.properties.startAngle = -90;

    if (
      !this.props.product?.remaining_days &&
      this.props.product?.product_type !== "Pro"
    ) {
      return;
    }

    pieSeries.exporting.menu = new am4core.ExportMenu();
    pieSeries.exporting.menu.verticalAlign = "top";
    pieSeries.exporting.filePrefix = "Anomalies";
    pieSeries.exporting.menu.container =
      document.getElementById("anomaliesChart");
    pieSeries.exporting.menu.items = [
      {
        icon: "../../../../components/assets/img/Vector.png",
        type: "png",
      },
    ];

    //Add labels for the chart
    const label = pieSeries.createChild(am4core.Label);
    label.text = "{values.value.sum}";
    label.horizontalCenter = "middle";
    label.verticalCenter = "bottom";
    label.fontSize = 28;
    label.fontWeight = 600;
    label.fontFamily = "Montserrat";
    label.color = "#ccc";
    label.disabled = true;
    label.paddingLeft = "-40px";
    label.align = "center";

    const labela = pieSeries.createChild(am4core.Label);
    labela.text = "Anomalies";
    labela.horizontalCenter = "middle";
    labela.verticalCenter = "middle";
    labela.fontSize = 12;
    labela.color = "#536471";
    label.fontWeight = 600;
    label.fontFamily = "Montserrat";
    labela.disabled = true;
    labela.paddingTop = 20;
    labela.paddingLeft = "10px";

    chart.exporting.events.on("exportstarted", () => {
      label.disabled = false;
      labela.disabled = false;
    });

    chart.exporting.events.on("exportfinished", () => {
      labela.disabled = true;
      label.disabled = true;
    });

    //Add legends for the chart
    chart.legend = new am4charts.Legend();
    chart.legend.background.fill = am4core.color("#fff");
    chart.legend.width = 320;
    chart.legend.marginTop = "0";
    chart.legend.paddingTop = "0";
    chart.legend.marginBottom = "0";
    chart.legend.paddingBottom = "0";
    chart.legend.scale = 0.4;

    chart.legend.percentHeight = 100;
    chart.legend.contentWidth = 500;

    const legendContainer = am4core.create("legenddiv", am4core.Container);

    chart.legend.parent = legendContainer;
    if (navigator.userAgent.includes("Firefox")) {
      const legendDiv = document.querySelector("#legenddiv");
      if (legendDiv) {
        legendDiv.style.width = "100%";
        legendDiv.style.height = "1000px";
      }
      legendContainer.width = am4core.percent(100);
      legendContainer.height = am4core.percent(100);
      legendContainer.scale = 4;
    }

    chart.legend.valueLabels.template.align = "right";
    chart.legend.valueLabels.template.textAlign = "end";

    chart.legend.labels.template.maxWidth = 300;
    chart.legend.labels.template.truncate = false;
    chart.legend.labels.template.wrap = true;

    chart.legend.fontFamily = "Montserrat";
    chart.legend.fontSize = 11;
    chart.legend.color = "#536471";

    chart.legend.useDefaultMarker = true;
    const marker = chart.legend.markers.template.children.getIndex(0);
    marker.cornerRadius(12, 12, 12, 12);

    chart.exporting.extraSprites.push({
      sprite: legendContainer,
      position: "right",
      marginBottom: 30,
      marginTop:
        anomalyData.length < 6
          ? 600
          : anomalyData.length < 11
          ? 480
          : anomalyData.length < 16
          ? 360
          : anomalyData.length < 21
          ? 240
          : anomalyData.length < 26
          ? 120
          : 0,
    });

    const options = chart.exporting.getFormatOptions("png");
    options.minWidth = 800;
    options.minHeight = 800;
    options.maxWidth = 1500;
    options.maxHeight = 1500;
    options.scale = 8;
    chart.exporting.setFormatOptions("png", options);

    const markerTemplate = chart.legend.markers.template;
    markerTemplate.width = 5;
    markerTemplate.height = 5;
  };

  //Create chart on the initial render
  componentDidMount() {
    if (this.props.moduleDefectChart)
      this.createChart(this.props.moduleDefectChart);
  }

  //Update chart on the props change
  componentDidUpdate(prevProps) {
    if (
      this.props.moduleDefectChart !== prevProps.moduleDefectChart ||
      this.props.language !== prevProps.language
    ) {
      //Chart data processing
      this.createChart(this.props.moduleDefectChart);
      const anomalyData = [];
      this.props.moduleDefectChart.anomalies_list &&
        this.props.moduleDefectChart.anomalies_list.forEach(
          ({ defect_type_list }) => {
            defect_type_list &&
              defect_type_list.forEach(({ name, count }) => {
                anomalyData.push({
                  anomaly: i18n.t(name),
                  count,
                });
              });
          }
        );

      //Add functionality to the download button
      const downloadButton = document.querySelector(".amcharts-amexport-menu");
      if (downloadButton) {
        if (anomalyData.length === 0) {
          downloadButton.style.display = "none";
        } else {
          downloadButton.style.display = "block";
        }
      }
    }
  }

  //Renders the pie chart
  render() {
    return (
      <>
        <div
          id="chartdiv"
          style={{
            width: "100%",
            height: this.props.chartHeight ? this.props.chartHeight : "400px",
          }}
        />
        <div
          id="legenddiv"
          style={{ visibility: "hidden", position: "fixed" }}
        />
      </>
    );
  }
}

//Calling redux to get information about the product (basic/pro) and the selected language
const mapStateToProps = (state) => {
  return {
    product: state.product,
    language: state.language.selectedLanguage,
  };
};

export default connect(mapStateToProps)(DonutChart);
