const styles = {
  sevList: {
    minWidth: 70,
    borderRadius: 5,
    boxShadow: "0 1px 2px 0 #0000000c",
    border: "solid 1px #e4eaf5",
    backgroundColor: "#ffffff",
    height: 16,
    paddingTop: 4,
  },
  hidelg: {
    visibility: "hidden",
    "@media (max-width: 600px)": {
      display: "none",
    },
  },
  header: {
    backgroundColor: "white",
    height: 72,
    padding: "22px 20px 0 20px",
    boxShadow: "unset",
    borderBottom: "1px solid #E4EAF5",
  },
  title: {
    fontFamily: "Montserrat",
    fontSize: 20,
    fontWeight: 700,
    marginTop: "3px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.2,
    color: "#21282d",
  },
  tabLabel: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "18px",
    color: "#7E909D",
    textTransform: "capitalize",
    width: 215,
    whiteSpace: "nowrap",
  },
  cardBox: {
    margin: 20,
    background: "#FFFFFF",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
    borderRadius: 5,
  },
  padd20: {
    padding: 20,
  },
  tabHeader: {
    borderBottom: "1px solid #E4EAF5",
  },
  tableHead: {
    height: 30,
    background: "#F4F7FC",
    border: "1px solid #E4EAF5",
    borderRadius: 5,
  },
  tableCell: {
    padding: 0,
    height: 30,
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "20px",
    color: "#7E909D",
    paddingLeft: 20,
    fontFamily: "Montserrat",
  },
  bodyText: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 12,
    lineHeight: "20px",
    textTransform: "capitalize",
    color: "#536471",
    padding: "0 20px",
    height: 52,
    fontFamily: "Montserrat",
  },
  renameTxt: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "20px",
    color: "#21282D",
    marginBottom: 10,
    fontFamily: "Montserrat",
  },
  renameSub: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 13,
    lineHeight: "24px",
    color: "#7E909D",
    fontFamily: "Montserrat",
  },
  outerBox: {
    height: 210,
    background: "#F4F7FC",
    border: "1px solid #E4EAF5",
    borderRadius: 5,
    maxWidth: 225,
    marginLeft: 30,
    padding: "15px 20px",
  },
  saveBtn: {
    background: "linear-gradient(266.69deg, #5B4DD3 0.23%, #5B4DD3 99.28%)",
    boxShadow: "0px 2px 2px rgba(0, 132, 255, 0.2)",
    borderRadius: 8,
    fontFamily: "Montserrat",
    color: "#fff",
    minWidth: 48,
    height: 32,
    margin: 0,
    marginRight: 20,
    marginLeft: 10,
    fontSize: 12,
  },
  defTxt: {
    fontFamily: "Montserrat",
    color: "#21282D",
    fontSize: 12,
    background: "#FFFFFF",
    border: "1px solid #E4EAF5",
    borderRadius: 5,
    marginRight: 10,
    paddingLeft: 5,
  },
  formControl: {
    height: 34,
    background: "#FFFFFF",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  selectEmpty: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "12px",
    color: "#536471",
    height: 32,
    margin: 4,
    padding: "6px 0px",
  },
  MenuItem: {
    backgroundColor: "rgba(0,0,0,0)",
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "12px",
    color: "#536471",
  },
  lists: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "12px",
    color: "#536471",
    "&:hover": {
      backgroundColor: "#F0F4FA",
    },
  },
  selectedList: {
    color: "#5B4DD3",
    backgroundColor: "#FFFFFF !important",
  },
};

export default styles;
