const style = {
  button: {
    fontSize: "12px",
    borderRadius: "6px",
    height: "32px",
    color: "#536471",
    borderColor: "#e1e5e8",
    ":hover": {
      backgroundColor: "blue",
      color: "white",
    },
  },
  dreawid: {
    width: 600,
  },

  roundedBorder: {
    borderRadius: "20px",
    boxShadow: "0px 2px 10px rgba(78, 89, 109, 0.05)",
    backgroundColor: "#ffffff",
    height: "100%",
    border: "1px solid #E4EAF5",
    boxSizing: "border-box"
  },
  close: {
    cursor:"pointer",
    marginLeft: 150,
  },
  drawerpad: {
    paddingLeft: 30,
    paddingTop: 20,
  },
  drawerpad1: {
    marginTop: 10,
    marginBottom: 10,
  },
  inputit: {
    borderRadius: "6px",
    border: "1px solid #d7d2d2",
    fontSize: 12,
    width: 240,
    padding: "0 5px",
    fontFamily: "Montserrat", 
    fontWeight: 500
  },

  titleBtn: {
    fontSize: 12,
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.67,
    letterSpacing: "normal",
    color: "#536471",
    padding: "6px 9px",
  },
  primaryButton: {
    outline:"none",
    fontSize: "12px",
    borderRadius: "6px",
    boxShadow: "0 2px 4px 0 rgba(27, 97, 223, 0.1)",
    backgroundImage: "linear-gradient(to top, #1165ff, #1b61df)",
    lineHeight: 1.67,
    letterSpacing: "normal",
    fontWeight: 500,
    color: "#ffffff",
  },
  secondaryButton: {
    fontSize: "12px",
    borderRadius: "6px",
    boxShadow: "0 2px 4px 0 rgba(27, 97, 223, 0.1)",
    lineHeight: 1.67,
    letteSpacing: "normal",
  },
  deleteButton: {
    color: "red",
    fontSize: "12px",
    borderRadius: "6px",
    boxShadow: "0 2px 4px 0 rgba(27, 97, 223, 0.1)",
    lineHeight: 1.67,
    letterSpacing: "normal",
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.2,
    letterSpacing: "normal",
    color: "#21282d",
    marginLeft: 30,
    marginRight: 20,
    marginTop: 10,
  },
};
module.exports = style;
