import { Grid, Menu } from "@material-ui/core";
import React, { useState } from "react";

import { withStyles } from "@material-ui/core/styles";

//dropdown styles
const styles = () => {
  return {
    dropdownButton: {
      color: "#B3A0BA",
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "12px",
      lineHeight: "20px",
      cursor: "pointer",
      borderRadius: "8px",
      borderColor: "#DDE5EC",
    },

    dropdownDiv: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
      cursor: "pointer",
    },

    arrowUp: {
      transform: "rotate(180deg)",
    },

    menuItem: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "4px 12px",
    },
  };
};

//Custom dropdown component
const CustomDropdown = React.forwardRef((props, ref) => {
  CustomDropdown.displayName = "customDropdown";

  const [anchorEl, setAnchorEl] = useState(false);

  //Close the dropdown
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  React.useImperativeHandle(ref, () => {
    return {
      closeMenu() {
        handleMenuClose();
      },
    };
  });

  //Set the anchor element
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <div>
      <Grid
        container
        alignItems="center"
        aria-owns={"simple-menu"}
        aria-haspopup="true"
        onClick={handleMenu}
        className={props.classes.dropdownButton}
        style={{
          width: props.width,
          height: props.height,
          padding: props.padding,
          background: props.background,
          border: props.border,
          position: props.position,
          top: props.top,
          right: props.right,
          left: props.left,
          zIndex: props.zIndex,
        }}
      >
        <div
          className={props.classes.dropdownDiv}
          style={{
            justifyContent: props.justifyContent,
            width: props.width,
          }}
        >
          {props.buttonLabelImg && (
            <img src={props.buttonLabelImg} alt={"user"} />
          )}
          {props.buttonLabel}
          {/* not added alt key here it is disturbing sidebar */}
          <img src={props.icon} alt="" />
        </div>
      </Grid>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        classes={{
          paper: props.outerMenuClass,
        }}
      >
        {props.menuType === "simple" ? (
          <div
            onClick={() => {
              return handleMenuClose();
            }}
            className={props.menuDivClass}
            role="button"
            tabIndex={"0"}
            aria-hidden="true"
          >
            {props.menuItems.map((item) => {
              return props.containImages ? (
                <div
                  key={item.text}
                  className={props.classes.menuItem}
                  // skipcq Prefer event handler naming conventions in JSX JS-0411
                  onClick={item.onClick}
                  role="button"
                  tabIndex={"-1"}
                  aria-hidden="true"
                >
                  <p>{item.text}</p>
                  <img src={item.img} alt="img" />
                </div>
              ) : (
                <div
                  key={item.text}
                  // skipcq Prefer event handler naming conventions in JSX JS-0411
                  onClick={item.onClick}
                  role="button"
                  tabIndex={"-2"}
                  aria-hidden="true"
                >
                  <p>{item.text}</p>
                </div>
              );
            })}
          </div>
        ) : props.closeOnClick ? (
          <div
            onClick={handleMenuClose}
            role="button"
            tabIndex={"-3"}
            aria-hidden="true"
          >
            {props.menuItems}
          </div>
        ) : (
          <div>{props.menuItems}</div>
        )}
      </Menu>
    </div>
  );
});

export default withStyles(styles)(CustomDropdown);
