import {
  CLEAR_ALERTS,
  REMOVE_ALERT,
  SET_ALERT,
} from "../constants/actionTypes";

//Initial state of the reducer
const initialState = [];

//Create and export the reducer to set the alert information
// skipcq Default parameters should be defined at the last JS-0106
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_ALERT:
      return [payload];
    case REMOVE_ALERT:
      return state.filter((alert) => {
        return alert.id !== payload;
      });
    case CLEAR_ALERTS:
      return [];
    default:
      return state;
  }
}
