import TrialImage from "./img/trialImg.svg";

export default {
  popupContainer: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    height: "100vh",
    background: "#fff",
    zIndex: 100000,
    maxWidth: "100%",
    overflowX: "hidden",
  },
  popupItem: {
    background: "white",
    borderRadius: "8px",
    maxWidth: "450px",
    height: "345px",
    maxHeight: "410px",
  },
  paddAlign: {
    padding: "15px 10px 10px 30px",
  },
  closeIcon: {
    cursor: "pointer",
    color: "#536471",
  },
  headTitle: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "40px",
    lineHeight: "60px",
    color: "#FFFFFF",
    margin: 10,
  },
  headParagraph: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "20px",
    textAlign: "center",
    color: "#B3A0BA",
  },
  productContainer: {
    width: "768px",
    margin: "0 auto",
    position: "relative",
    top: "-150px",
    padding: 10,
    display: "flex",
    justifyContent: "space-between",
    wrap: "nowrap",
    "@media (max-width: 600px)": {
      width: "100%",
      paddingBottom: 64,
    },
  },
  product: {
    background: "#FFFFFF",
    border: "1px solid #DDE5EC",
    boxShadow: "0px 4px 12px rgba(78, 89, 109, 0.05)",
    borderRadius: "8px",
    width: "360px",
    height: "900px",
    marginRight: 20,
    "@media (max-width: 600px)": {
      margin: "10px 0",
    },
  },
  productHeadSection: {
    margin: "0 auto",
    height: "40%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "40px 0",
    wrap: "nowrap",
  },
  trialHeader: {
    height: "159.18px",
    backgroundImage: `url(${TrialImage})`,
    padding: "30px",
    borderRadius: "8px",
  },
  trialHeaderTitle: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "24px",
    textTransform: "uppercase",
    color: "#FFFFFF",
  },
  trialHeaderParagraph: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "24px",
    color: "#B3A0BA",
  },
  tooltiptext: {
    visibility: "hidden",
    fontFamily: "Montserrat",
    position: "absolute",
    top: "-120%",
    left: "120%",
    color: "#FFFFFF",
    backgroundColor: "#1F043F",
    width: "217px",
    lineHeight: "20px",
    fontSize: "12px",
    borderRadius: "5px",
    zIndex: "200",
    padding: "5px 8px",
  },
  productTooltip: {
    position: "relative",
    color: "#08001D",
    fontWeight: "500",
    fontFamily: "Montserrat",
    "&:hover": {
      "& span": {
        visibility: "visible !important",
      },
    },
  },
  featureDashedText: {
    fontSize: "14px",
    fontWeight: "600",
    textDecoration: "underline",
    textDecorationStyle: "dotted",
  },
  proUpgrade: {
    background: "#FFFFFF",
    boxShadow: "0px 4px 12px rgba(78, 89, 109, 0.1)",
    borderRadius: "8px",
    width: "50%",
    padding: 16,
    display: "flex",
    justifyContent: "space-between",
    "@media (max-width: 600px)": {
      width: "90%",
    },
  },
  proTypography: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "20px",
    color: "#536471",
  },
  proTypographyLink: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "20px",
    color: "#0084FF",
    cursor: "pointer",
  },
  upgradePlan: {
    "@media (max-width: 1200px)": {
      marginTop: "6px",
    },
  },
  proSideLink: {
    width: "235px",
    height: "36px",
    background: "none",
    paddingRight: 10,
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    backgroundColor: "rgba(255, 255, 255, 0.1)",
  },
  proLink: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "16px",
    color: "#C5C9D0",
    cursor: "pointer",
    fontSize: "14",
  },
  BlurDownloadReport: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
};
