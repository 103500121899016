import { Grid, Slider, Typography, withStyles } from "@material-ui/core";

import React from "react";
import { Trans } from "react-i18next";
import styles from "./temperatureRange.style";
import warningImg from "../../../assets/img/warning.svg";

//Temperature range
const TemperatureRange = ({
  classes,
  tempRange,
  onHandleSlider,
  showInstruction,
  moduleType,
  chartFilter,
}) => {
  //handle temperature input
  const tempInputHandler = (e, type) => {
    const value = e.target.value;
    const tempValue =
      type === "min" ? [value, tempRange[1]] : [tempRange[0], value];
    onHandleSlider(e, tempValue);
  };

  return (
    <Grid style={{ marginBottom: 14 }}>
      <Typography
        className={classes.subText}
        style={chartFilter ? { marginTop: 0 } : { marginTop: 25 }}
      >
        <Trans>Temperature Difference (∆T °C)</Trans>
      </Typography>
      <Grid className={moduleType === "Report" && classes.reportTemp}>
        <Grid
          className={
            moduleType === "Report"
              ? classes.reportTempmark
              : classes.otherTempmark
          }
          id={"gtm_tempFilter"}
        >
          <Slider
            track="inverted"
            defaultValue={[0, 100]}
            value={tempRange}
            classes={{
              root: classes.sliderLine,
              thumb: classes.sliderPoint,
              track: [classes.height10, classes.trackBgcolor].join(" "),
              rail: [classes.height10, classes.railBgcolor].join(" "),
            }}
            onChange={(event, value) => {
              onHandleSlider(event, value);
            }}
            className={
              moduleType === "Report"
                ? classes.reportTempmark
                : classes.otherTempmark
            }
            id={"gtm_tempSliderFilter"}
          />
        </Grid>
        <Grid
          container
          alignItems="center"
          style={chartFilter ? { marginTop: 16 } : { marginTop: "none" }}
        >
          <div className={classes.sliderBox} style={{ marginRight: "4px" }}>
            <input
              className={classes.tempInput}
              type="number"
              value={tempRange[0]}
              onChange={(e) => {
                return tempInputHandler(e, "min");
              }}
              id={"gtm_tempMinFilter"}
            />
          </div>{" "}
          <span className={classes.subText}> - </span>
          <div className={classes.sliderBox} style={{ marginLeft: "4px" }}>
            <input
              className={classes.tempInput}
              type="number"
              value={tempRange[1]}
              onChange={(e) => {
                return tempInputHandler(e, "max");
              }}
              id={"gtm_tempMaxFilter"}
            />
          </div>{" "}
          <span className={classes.checkLabel} style={{ marginLeft: "4px" }}>
            {" "}
            °C{" "}
          </span>
        </Grid>
      </Grid>

      {showInstruction && (
        <Grid className={classes.tempInfo}>
          <span
            style={
              chartFilter
                ? { display: "none" }
                : { display: "block", marginRight: 10 }
            }
          >
            <img alt="warnImg" src={warningImg} />
          </span>
          <Typography className={classes.tempInfoText}>
            <b>STC</b>{" "}
            <Trans>
              temp. value will be used to filter defects. If STC not available,
              Site Condition temp. value will be used.
            </Trans>
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};
export default withStyles(styles)(TemperatureRange);
