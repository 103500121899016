import {
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  SwipeableDrawer,
  Typography,
  withStyles,
} from "@material-ui/core";
import { Trans, withTranslation } from "react-i18next";

import Alert from "../../layout/Alert";
import CloseIcon from "@material-ui/icons/Close";
import CustomButton from "../common/customStyle/Button";
import CustomTextField from "../common/customStyle/TextField";
import PropTypes from "prop-types";
import React from "react";
import SimpleReactValidator from "simple-react-validator";
// import SiteAccess from "./SiteAccess";
import { URLS } from "../../constants";
import { allRequestHandler } from "../../api";
import { connect } from "react-redux";
import { getUsers } from "../../actions/user";
import { setAlert } from "../../actions/alert.actions";
import styles from "../../style";
import userStyles from "./user.style";
import MultSiteAccess from "./multSiteAccess";

//user update component
class UserUpdate extends React.Component {
  //Initializing states
  constructor(props) {
    super(props);
    this.state = {
      first_name: this.props.data.first_name,
      last_name: this.props.data.last_name,
      job_title: this.props.data.job_title,
      username: this.props.data.username,
      email_id: this.props.data.email_id,
      activeInString: this.props.data.active.toString(),
      site_access: [],
      active: this.props.data.active,
      role_type: "CLIENT_ADMIN",
      visible: false,
      emptySitesAccess: false,
    };
    this.validator = new SimpleReactValidator();
  }

  //Get the site access details of the user
  getSiteAccess = (value) => {
    //if not selected any site then show error msg.
    const isNotSelectedAnySite = value.some((item) =>
      Object.values(item).includes(true)
    );

    if (!isNotSelectedAnySite) {
      this.setState({
        emptySitesAccess: true,
      });
    } else {
      this.setState({
        emptySitesAccess: false,
      });
    }

    //set role_type according to
    const isGeneralAdminPresent = value.some(
      (item) => item["role"] === "CLIENT_ADMIN" && item.isChecked
    );

    const isSiteAdminPresent = value.some(
      (item) => item["role"] === "SITE_ADMIN" && item.isChecked
    );

    this.setState({
      site_access: value,
      role_type: isGeneralAdminPresent
        ? "CLIENT_ADMIN"
        : !isGeneralAdminPresent && isSiteAdminPresent
        ? "SITE_ADMIN"
        : "CLIENT_USER",
    });
  };

  //handle submit form for edit user
  handleSubmit = async (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.setState({ isUpdating: true });

      // get form data from react state object
      const {
        first_name,
        last_name,
        username,
        job_title,
        email_id,
        site_access,
        role_type,
        active,
      } = this.state;

      const sites = [];
      for (let i = 0; i < site_access.length; i++) {
        const site = site_access[i];
        if (site.isChecked) {
          sites.push({ plant_id: site.plant_id, role: site.role });
        }
      }

      const body = {
        first_name,
        last_name,
        username,
        email_id,
        job_title,
        active,
        role_type,
        site_access: sites,
      };

      try {
        const user = await allRequestHandler({
          requestType: "PUT",
          requestUrl: `${URLS.ALL_USER}/${this.props.data.user_id}`,
          requestData: body,
        });

        if (user) {
          this.setState({ isUpdating: false });
          this.props.getUsers();
          this.onClose();
        }
      } catch (err) {
        this.setState({ isUpdating: false });
        return;
      }
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  };

  //Set the visibility of the drawer to true
  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  //Set the visibility of the drawer to false
  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  //Renders the component to update the user details
  render() {
    const { classes } = this.props;

    return (
      <>
        {this.props.fromDrawer ? (
          <CustomButton
            onClick={() => {
              return this.setState({ visible: true });
            }}
            text={<Trans>Edit User</Trans>}
          />
        ) : (
          <p
            onClick={() => {
              return this.setState({ visible: true });
            }}
            aria-hidden="true"
            style={{ width: "100%" }}
          >
            <Trans>Edit</Trans>
          </p>
        )}

        <SwipeableDrawer
          classes={{ paper: classes.swipDrawer }}
          open={this.state.visible}
          onClose={() => {
            return this.onClose();
          }}
          onOpen={() => this.setState({ visible: true })}
          anchor="right"
        >
          <div>
            <Grid container alignItems="center" style={{ height: 70 }}>
              <Grid item xs>
                <Typography className={classes.title}>
                  <Trans>Update User</Trans>
                </Typography>
              </Grid>
              <Grid item style={{ paddingRight: 25 }}>
                <CloseIcon
                  onClick={() => {
                    return this.onClose();
                  }}
                  style={styles.close}
                />
              </Grid>
            </Grid>
            <Divider />
            <div style={{ padding: "15px 30px" }}>
              <Typography className={classes.subHeader}>
                <Trans>User details</Trans>
              </Typography>
              <Grid
                container
                style={{ marginTop: "10px" }}
                className={classes.mb20}
              >
                <Grid item md={5} className={classes.mr30}>
                  <CustomTextField
                    label={<Trans>First Name</Trans>}
                    value={this.state.first_name}
                    handleChange={(e) => {
                      return this.setState({ first_name: e.target.value });
                    }}
                    onFocus={() => {
                      return this.validator.showMessageFor("first_name");
                    }}
                  />
                  <div className={classes.danger}>
                    {this.validator.message(
                      "first_name",
                      this.state.first_name,
                      "required"
                    )}
                  </div>
                </Grid>
                <Grid item md={5} className={classes.mr30}>
                  <CustomTextField
                    label={<Trans>Last Name</Trans>}
                    value={this.state.last_name}
                    handleChange={(e) => {
                      return this.setState({ last_name: e.target.value });
                    }}
                    onFocus={() => {
                      return this.validator.showMessageFor("last_name");
                    }}
                  />
                  <div className={classes.danger}>
                    {this.validator.message(
                      "last_name",
                      this.state.last_name,
                      "required"
                    )}
                  </div>
                </Grid>
              </Grid>
              <Grid container className={classes.mb20}>
                <Grid item md={5} className={classes.mr30}>
                  <CustomTextField
                    label={<Trans>Username</Trans>}
                    value={this.state.username}
                    handleChange={(e) => {
                      return this.setState({ username: e.target.value });
                    }}
                    onFocus={() => {
                      return this.validator.showMessageFor("username");
                    }}
                    disabled={this.state.username ? true : false}
                  />
                  <div className={classes.danger}>
                    {this.validator.message(
                      "username",
                      this.state.username,
                      "required"
                    )}
                  </div>
                </Grid>
                <Grid item md={5} className={classes.mr30}>
                  <Typography className={classes.headerTxt}>
                    <Trans>Status</Trans>
                  </Typography>
                  <RadioGroup
                    row
                    aria-label="position"
                    name="position"
                    value={this.state.active}
                    onClick={(e) => {
                      return this.setState({
                        active: e.target.value === "active" ? true : false,
                      });
                    }}
                  >
                    <Grid container>
                      <Grid item>
                        <FormControlLabel
                          control={
                            <Radio
                              value="active"
                              checked={this.state.active ? true : false}
                              style={{
                                color: this.state.active
                                  ? "#5B4DD3"
                                  : "#8C9AA4",
                              }}
                            />
                          }
                          label={<Trans>Active</Trans>}
                          className={classes.headerTxt}
                        />
                      </Grid>
                      <Grid item>
                        <FormControlLabel
                          control={
                            <Radio
                              value="inactive"
                              checked={this.state.active ? false : true}
                              style={{
                                color:
                                  this.state.active === false
                                    ? "#5B4DD3"
                                    : "#8C9AA4",
                              }}
                            />
                          }
                          label={<Trans>Inactive</Trans>}
                          className={classes.headerTxt}
                        />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </Grid>
              </Grid>
              <Grid container className={classes.mb20}>
                <Grid item md={5} className={classes.mr30}>
                  <CustomTextField
                    label={<Trans>Email</Trans>}
                    value={this.state.email_id}
                    handleChange={(e) => {
                      return this.setState({ email_id: e.target.value });
                    }}
                    onFocus={() => {
                      return this.validator.showMessageFor("email_id");
                    }}
                    disabled={this.state.email_id ? true : false}
                  />
                  <div className={classes.danger}>
                    {this.validator.message(
                      "email_id",
                      this.state.email_id,
                      "required|email"
                    )}
                  </div>
                </Grid>
                <Grid item md={5} className={classes.mr30}>
                  <CustomTextField
                    label={<Trans>Job Title</Trans>}
                    value={this.state.job_title}
                    handleChange={(e) => {
                      return this.setState({ job_title: e.target.value });
                    }}
                    onFocus={() => {
                      return this.validator.showMessageFor("job_title");
                    }}
                  />

                  <div className={classes.danger}>
                    {this.validator.message(
                      "job_title",
                      this.state.job_title,
                      "required"
                    )}
                  </div>
                </Grid>
              </Grid>
              <Alert />
              <Divider className={classes.dividerUpdate} />
              <Typography className={classes.subHeader}>
                <Trans>Site Access</Trans>
              </Typography>
              <Grid container className={classes.mb20}>
                <Grid item md={12}>
                  {/* <SiteAccess
                    classes={classes}
                    sitesaccess={this.props.user.sitesAccess}
                    site_access={this.state.site_access}
                    getSiteAccess={this.getSiteAccess}
                  /> */}
                  <MultSiteAccess
                    plantList={this.props.plantList}
                    getSiteAccess={this.getSiteAccess}
                    data={this.props.data}
                    usersList={this.props.usersList}
                    isEditOption={true}
                    classes={classes}
                    site_access={[]}
                    sitesaccess={this.props.data.site_access}
                  />
                </Grid>
              </Grid>
              <div style={{ marginBottom: 4 }}>
                {this.state.emptySitesAccess ? (
                  <span className={classes.danger} style={{ marginBottom: 4 }}>
                    <Trans>
                      Atleast access one site should be given to user
                    </Trans>
                  </span>
                ) : null}
              </div>
              <Divider className={classes.dividerUpdate} />
              <CustomButton
                disabled={this.state.isUpdating}
                onClick={(e) => {
                  return this.handleSubmit(e);
                }}
                text={this.state.isUpdating ? "Loading" : <Trans>Submit</Trans>}
              />{" "}
              <CustomButton
                onClick={(e) => {
                  return this.onClose(e);
                }}
                type="cancel"
                text={<Trans>Cancel</Trans>}
              />
            </div>{" "}
          </div>
        </SwipeableDrawer>
      </>
    );
  }
}

//Default Prop types
// skipcq static properties should be positioned JS-0472
UserUpdate.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  setAlert: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  // user: PropTypes.any.isRequired,
};

//calling redux to get the users data and selected language
const mapStateToProps = (state) => {
  return {
    user: state.user,
    language: state.language.selectedLanguage,
  };
};
export default connect(mapStateToProps, {
  getUsers,
  setAlert,
})(withStyles(userStyles)(withTranslation()(UserUpdate)));
