import React, { Component } from "react";

//Error messages
class ErrorBoundary extends Component {
  //Initializing the states
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  //Get the error and update the state
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  //Renders the error message
  render() {
    if (this.state.hasError) {
      //render fallback UI
      return <h2>Something went wrong. Please login again</h2>;
    }
    //when there's not an error, render children untouched
    return this.props.children;
  }
}

export default ErrorBoundary;
