import { Grid, Typography } from "@material-ui/core";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { URLS, iFormData } from "../../constants";

import ArrowRight from "../../assets/img/arrowRight.svg";
import CustomReport from "./CustomReport";
import CustomReportCreate from "./CustomReportCreate";
import { Navigate } from "react-router-dom";
import ReactGA from "react-ga";
import { Trans } from "react-i18next";
import _ from "lodash";
import { allRequestHandler } from "../../api";
import { connect } from "react-redux";
import reportJsonData from "./downloadReport.json";
import styles from "./reports.style";
import { trackId } from "../../config";
import { withStyles } from "@material-ui/core/styles";

ReactGA.initialize(trackId);

//reports page
const Reports = ({
  classes,
  toggleMenu,
  loggedInUser,
  callSnackbar,
  onHandleShowPlans,
  allSites,
  reportObj,
}) => {
  const [showCustomReport, setShowCustomReport] = useState(true);
  const [reportHistory, setReportHistory] = useState([]);
  const [plantList, setPlantList] = useState([]);
  const [formData, setFormData] = useState(iFormData);
  const [tempRange, setTempRange] = useState([0, 100]);
  const [anomalies, setAnomalies] = useState([]);
  const [redirect, setRedirect] = useState(false);

  //Format an default generic report
  const assignGenericReport = () => {
    const plant_list = _.orderBy(allSites.site_list, "plant_name", "asc");
    setPlantList(plant_list);
    setFormData({
      ...formData,
      plant_id: plant_list[0].plant_id,
      section_id: null,
      plant_inspection_id: plant_list[0].inspection_list[0].plant_inspection_id,
    });
    setAnomalies(allSites.anomaly_type_list);
  };

  //To handle session timeout
  const userTimeOut = () => {
    sessionStorage.setItem("jwtToken", "");
    setRedirect(true);
  };

  //If status is pending call History API
  const checkFileStatus = (reportList) => {
    const checkStatus = reportList.find((data) => {
      return data.status === "pending";
    });

    setTimeout(() => {
      // skipcq Variable used before definition JS-0129
      if (checkStatus && checkStatus.status) getReportHistory();
    }, 10000);
  };

  //Get the report history
  const getReportHistory = async () => {
    try {
      const history = await allRequestHandler({
        requestType: "GET",
        requestUrl: `${URLS.REPORT_API}/history`,
      });
      if (history && history.status && history.status === 401) {
        userTimeOut();
      }
      setReportHistory(history.reports_list);

      if (history && history.reports_list && history.reports_list.length)
        checkFileStatus(history.reports_list);
    } catch (error) {
      // Handle error
    }
  };

  //Get report history synchronously on the page load
  useLayoutEffect(() => {
    // For Google Analytics
    ReactGA.pageview(window.location.pathname);
    getReportHistory();
    // getGenericReports();
  }, []);

  //Create generic report on the "allSites" prop change
  useEffect(() => {
    if (allSites) assignGenericReport();
  }, [allSites]);

  // On session timeout redirect
  if (redirect) return <Navigate replace to="/" />;

  //Renders the reports page
  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        className={classes.header}
      >
        {showCustomReport ? (
          <>
            <Typography
              className={classes.title}
              style={{ marginLeft: `${toggleMenu && "30px"}` }}
            >
              <Trans>Reports</Trans>
            </Typography>
          </>
        ) : (
          <Grid>
            <Typography className={classes.titleText}>
              <span>
                <Trans>Reports</Trans>
              </span>{" "}
              <span>
                <img alt="arrowImg" src={ArrowRight} />
              </span>{" "}
              <span>
                <Trans>Custom Reports</Trans>
              </span>
            </Typography>
            <Typography
              className={classes.title}
              style={{ marginLeft: `${toggleMenu && "30px"}` }}
            >
              <Trans>Generate Custom Report</Trans>
            </Typography>
          </Grid>
        )}
      </Grid>

      <Grid container>
        {showCustomReport ? (
          <CustomReport
            setShowCustomReport={setShowCustomReport}
            getReportHistory={getReportHistory}
            reportHistory={reportHistory}
            callSnackbar={callSnackbar}
            plantList={plantList}
            reportTypes={reportJsonData.report_types}
            userTimeOut={userTimeOut}
            onHandleShowPlans={onHandleShowPlans}
            loggedInUser={loggedInUser}
            reportObj={reportObj}
            anomalies={anomalies}
          />
        ) : (
          <CustomReportCreate
            setShowCustomReport={setShowCustomReport}
            callSnackbar={callSnackbar}
            getReportHistory={getReportHistory}
            formData={formData}
            setFormData={setFormData}
            anomalies={anomalies}
            plantList={plantList}
            userTimeOut={userTimeOut}
            onHandleShowPlans={onHandleShowPlans}
            tempRange={tempRange}
            setTempRange={setTempRange}
          />
        )}
      </Grid>
    </>
  );
};

//updating current language state
const mapStateToProps = (state) => {
  return {
    language: state.language.selectedLanguage,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(Reports));