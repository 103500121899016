import { AutoRotatingCarousel, Slide } from "material-auto-rotating-carousel";
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";

import AnomalyTableCreator from "./AnomalyTableCreator";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import CustomButton from "../common/customStyle/Button";
import CustomizedDialogs from "./AnomalyPopUp.js";
import { MapInteractionCSS } from "react-map-interaction";
import NewLoader from "../../layout/newLoader";
import NotImageIcon from "../../assets/img/otherIcon.svg";
import React from "react";
import { Trans } from "react-i18next";
import { URLS } from "../../constants";
import _ from "lodash";
import { allRequestHandler } from "../../api";
import anomalyTableData from "./anomalyTableData";
import { connect } from "react-redux";
import falsepositiveImg from "../../assets/img/falsePositive.svg";
import i18n from "i18next";
import notfoundImg from "../../assets/img/notfound.svg";
import pendingImg from "../../assets/img/pending.svg";
import progressImg from "../../assets/img/progress.svg";
import resizedImageUrl from "../../utils/imageResizing";
import resolvedImg from "../../assets/img/resolved.svg";
import shortid from "shortid";
import styles from "./defectMap.style";
import { withStyles } from "@material-ui/core/styles";
import zoomInIcon from "../../assets/img/zoomIn.svg";
import zoomOutIcon from "../../assets/img/zoomOut.svg";

//Anomalies details page
class Details extends React.Component {
  state = {
    selectedDefect: null,
    openImage: false,
    urls: [],
    // canZoom: false,
    areAnyRgb: false,
    areAnyThermal: false,
    allInspData: false,
  };

  // applyZoom = () => {
  //   this.setState({ canZoom: true });
  // };

  // removeZoom = () => {
  //   this.setState({ canZoom: false });
  // };

  //Fetch the details of the selected defect on the initial render
  componentDidMount() {
    if (this.props.selectedDefect) this.fetchDetailDefect();
  }

  //These functions are called whenever the component updates
  componentDidUpdate(prevProps, prevState) {
    //Get defect details of new defect when selected defect changes
    if (
      this.props.selectedDefect &&
      prevProps.selectedDefect !== this.props.selectedDefect
    ) {
      this.fetchDetailDefect();
    }

    if (
      this.state.selectedDefect &&
      prevState.selectedDefect !== this.state.selectedDefect
    ) {
      let thermalCount = 0;
      let rgbCount = 0;
      this.state.selectedDefect.map((val) => {
        if (val.thermal_image_url) thermalCount++;
        if (val.rgb_image_url) rgbCount++;
        return val;
      });
      // skipcq Avoid usage of setState in componentDidUpdate JS-0443
      this.setState({
        areAnyRgb: rgbCount > 0,
        areAnyThermal: thermalCount > 0,
      });
    }
  }

  //API call to get the details of the selected defect
  fetchDetailDefect = async () => {
    // let detailUrl =
    //   this.props.href_list &&
    //   this.props.href_list.length &&
    //   this.props.href_list.filter((url) => {
    //     return url.defect_details;
    //   })[0];
    let res = "";
    if (!this.props.isDigitized) {
      res = await allRequestHandler({
        requestType: "GET",
        requestUrl:
          URLS.ANOMALY_DETAILS + this.props.selectedDefect.defect_id.toString(),
      });
    } else {
      res = await allRequestHandler({
        requestType: "GET",
        requestUrl: `${
          URLS.ANOMALY_DETAILS
        }${this.props.selectedDefect.defect_id.toString()}/panels/${this.props.selectedDefect.panel_id.toString()}`,
      });
    }
    if (res && res.anomaly_details && res.anomaly_details.length) {
      const filterDetail = res.anomaly_details.filter((getData) => {
        return getData.inspection_date === this.props.selectedInspectionDate;
      });

      // Onchange of insp date side list will return valid defects details
      if (!filterDetail.length) {
        filterDetail.push(this.props.selectedDefect);
      }

      this.setState({
        selectedDefect: filterDetail,
        allInspData: _.sortBy(res.anomaly_details, "inspection_date"),
      });
    }
  };

  //Close the image window
  displayImageClose = () => {
    const urlArr = [];
    this.setState({ openImage: false, urls: urlArr });
  };

  //Open the image window
  displayImageOpen = (field, imgUrl) => {
    this.imageUrls(field, imgUrl);
  };

  //Get the image list and their urls
  imageUrls = (field, imgUrl) => {
    const urlArray = [];
    const defectLength = this.state.selectedDefect.length;

    if (this.state.selectedDefect && imgUrl === "thermal_image_url" && field) {
      this.state.selectedDefect.map((val) => {
        if (field !== val.thermal_image_url) {
          return urlArray.push(val.thermal_image_url);
        }
        return false;
      });
      urlArray.splice(1, 0, field);
    }

    if (this.state.selectedDefect && imgUrl === "rgb_image_url") {
      this.state.selectedDefect.map((val) => {
        if (field !== val.rgb_image_url) {
          return urlArray.push(val.rgb_image_url);
        }
        return false;
      });
      urlArray.splice(1, 0, field);
    }

    if (!imgUrl) {
      if (
        this.state.selectedDefect[defectLength - 1].thermal_image_url &&
        this.state.selectedDefect[defectLength - 1].thermal_image_url !== field
      ) {
        urlArray.push(
          this.state.selectedDefect[defectLength - 1].thermal_image_url
        );
      }

      if (
        this.state.selectedDefect[defectLength - 1].rgb_image_url &&
        this.state.selectedDefect[defectLength - 1].rgb_image_url !== field
      ) {
        urlArray.push(
          this.state.selectedDefect[defectLength - 1].rgb_image_url
        );
      }
    }

    if (
      this.state.selectedDefect[defectLength - 1].el_image &&
      this.state.selectedDefect[defectLength - 1].el_image !== field
    ) {
      if (!imgUrl) {
        urlArray.push(this.state.selectedDefect[defectLength - 1].el_image);
      }
    }

    if (
      this.state.selectedDefect[defectLength - 1].handheld_image &&
      this.state.selectedDefect[defectLength - 1].handheld_image !== field
    ) {
      if (!imgUrl) {
        urlArray.push(
          this.state.selectedDefect[defectLength - 1].handheld_image
        );
      }
    }
    if (
      this.state.selectedDefect[defectLength - 1].iv_curve_data &&
      this.state.selectedDefect[defectLength - 1].iv_curve_data !== field
    ) {
      if (!imgUrl) {
        urlArray.push(
          this.state.selectedDefect[defectLength - 1].iv_curve_data
        );
      }
    }

    if (!imgUrl) {
      urlArray.splice(1, 0, field);
    }

    this.setState({ urls: urlArray });
    this.setState({ openImage: true });
  };

  //Renders the defect details
  render() {
    const { classes } = this.props;
    let { selectedDefect } = this.state;
    if (!selectedDefect)
      selectedDefect = _.sortBy(this.props.selectedDefect, "inspection_date");
    const urls = this.state.urls;

    const checkExtension = /(\.jpg|\.jpeg|\.bmp|\.gif|\.png)$/i;

    return this.state.selectedDefect ? (
      <Grid item xs className={classes.detailArea}>
        {/* Proof upload button */}
        {this.props.selectedDefect.status === "falsepositive" &&
          !this.props.selectedDefect.is_fp_proof_available && (
            <>
              <Typography className={classes.detailText}>
                <Trans>False +ve Proof</Trans>
              </Typography>
              <Grid
                container
                justifyContent="center"
                direction="column"
                className={classes.proofArea}
              >
                <Typography className={classes.detailText}>
                  <Trans>
                    Anomalies marked False +ve without any proof will be changed
                    to pending. Please upload proof for False +ve anomalies.
                  </Trans>
                </Typography>
                <div style={{ marginTop: 8 }}>
                  <CustomButton
                    onClick={() => {
                      return this.props.handleStatus(
                        this.props.selectedDefect.defect_id,
                        "falsepositive"
                      );
                    }}
                    text="Upload Proof"
                    id={"gtm_uploadProofButtonDetails"}
                  />
                </div>
              </Grid>
            </>
          )}
        <Grid>
          <Typography className={classes.detailText}>Status</Typography>
          <div
            style={{ position: "relative", marginBottom: "24px" }}
            data-intercom-target="defect-status-filter"
          >
            <FormControl variant="outlined" style={{ zIndex: 100, right: 0 }}>
              <Select
                inputProps={
                  (this.props.selectedDefect.status === "falsepositive" ||
                    this.props.selectedDefect.status === "False +Ve") &&
                  this.props.selectedDefect.is_fp_proof_available
                    ? { disabled: true }
                    : null
                }
                // value={this.props.selectedDefect.status}
                value={this.props.filterStatusName}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  classes: { paper: classes.blockMenu },
                }}
                className={classes.selectBlock}
                onChange={(e) => {
                  e.stopPropagation();
                  this.props.onHandleDropdownStatus(
                    e,
                    this.props.selectedDefect.defect_id,
                    this.props.selectedDefect.status
                  );
                }}
              >
                {this.props.statusList.map((s) => {
                  return (
                    <MenuItem
                      key={shortid.generate()}
                      value={s.value}
                      style={{ zIndex: 100 }}
                      ListItemClasses={{
                        root: classes.blockList,
                        selected: classes.selectedList,
                      }}
                      data-intercom-target="defect-status-filter"
                      id={"gtm_detailStatus"}
                    >
                      <Grid container>
                        <Typography
                          style={{ marginTop: "4px" }}
                          className={classes.plantName}
                        >
                          {s.value === "pending" ? (
                            <img
                              alt="pendingImg"
                              className={classes.btnImg}
                              src={pendingImg}
                            />
                          ) : s.value === "falsepositive" ? (
                            <img
                              alt="falsepositiveImg"
                              className={classes.btnImg}
                              src={falsepositiveImg}
                            />
                          ) : s.value === "resolved" ? (
                            <img
                              alt="resolvedImg"
                              className={classes.btnImg}
                              src={resolvedImg}
                            />
                          ) : s.value === "inprogress" ? (
                            <img
                              alt="progressImg"
                              className={classes.btnImg}
                              src={progressImg}
                            />
                          ) : (
                            <img
                              alt="notfoundImg"
                              className={classes.btnImg}
                              src={notfoundImg}
                            />
                          )}
                        </Typography>
                        <Trans>{s.name}</Trans>{" "}
                      </Grid>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        </Grid>
        <Typography className={classes.detailText}>
          <Trans>Attachments</Trans>
        </Typography>
        <Grid container style={{ marginBottom: 40 }}>
          <Grid
            item
            xs={6}
            container
            justifyContent="space-between"
            alignItems="center"
            direction="column"
            style={{
              maxWidth: "33%",
            }}
          >
            <Grid item>
              <img
                onClick={() => {
                  return this.displayImageOpen(
                    selectedDefect[0].thermal_image_url
                  );
                }}
                className={classes.detailImg}
                // src={selectedDefect.image_url}
                src={resizedImageUrl(selectedDefect[0].thermal_image_url)}
                alt="img"
                aria-hidden="true"
              />
            </Grid>

            <Grid item>
              <span
                style={{
                  margin: 10,
                }}
                className={classes.detailSpan}
              >
                <Trans>Thermal</Trans>
              </span>
            </Grid>
          </Grid>

          {[
            "el_image",
            "rgb_image_url",
            "handheld_image",
            "iv_curve_data",
            "other_url",
          ].map((imgD) => {
            const imgSrc = selectedDefect[0][imgD];
            return (
              <React.Fragment key={shortid.generate()}>
                {selectedDefect[0][imgD] && (
                  <Grid
                    item
                    xs={6}
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    direction="column"
                    style={{
                      maxWidth: "33%",
                    }}
                  >
                    <Grid item>
                      {checkExtension.exec(selectedDefect[0][imgD]) ? (
                        <img
                          src={imgSrc}
                          onClick={() => {
                            return this.displayImageOpen(
                              selectedDefect[0][imgD]
                            );
                          }}
                          style={{ marginTop: "10px" }}
                          className={classes.detailImg}
                          alt="withImage"
                          aria-hidden="true"
                        />
                      ) : (
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href={selectedDefect[0][imgD]}
                        >
                          <div className={classes.notImageBox}>
                            <img alt="NotImageIcon" src={NotImageIcon} />
                          </div>
                        </a>
                      )}
                    </Grid>
                    <Grid item>
                      <span className={classes.detailSpan}>
                        <Trans>
                          {imgD === "el_image"
                            ? "EL Image"
                            : imgD === "rgb_image_url"
                            ? "RGB"
                            : imgD === "handheld_image"
                            ? "Handheld"
                            : imgD === "iv_curve_data"
                            ? "IV Data"
                            : "Others"}
                        </Trans>
                      </span>
                    </Grid>
                  </Grid>
                )}
              </React.Fragment>
            );
          })}

          <CustomizedDialogs
            updateDefectDetails={() => {
              return this.fetchDetailDefect();
            }}
            defectId={selectedDefect[0].defect_id}
            // defectId={selectedDefect[selectedDefect.length - 1].defect_id}
          />
        </Grid>
        <div
          className={classes.detailsBoxNew}
          style={{ overflowX: "auto", borderBottom: "none" }}
        >
          {anomalyTableData.map((item) => {
            return (
              <React.Fragment key={shortid.generate()}>
                {(this.state.allInspData.length > 1 ||
                  (this.state.allInspData.length === 1 && item.inSingle)) && (
                  <AnomalyTableCreator
                    selectedDefect={this.state.allInspData}
                    category={i18n.t(item.value)}
                    value={item.key}
                    type={item.type}
                    areAnyRgb={this.state.areAnyRgb}
                    areAnyThermal={this.state.areAnyThermal}
                    displayImageOpen={this.displayImageOpen}
                  />
                )}
              </React.Fragment>
            );

            // selectedDefect.length === 1 ? (
            //   item.inSingle ? (
            //     <AnomalyTableCreator
            //       selectedDefect={this.state.allInspData}
            //       category={i18n.t(item.value)}
            //       value={item.key}
            //       type={item.type}
            //       areAnyRgb={this.state.areAnyRgb}
            //       areAnyThermal={this.state.areAnyThermal}
            //     />
            //   ) : null
            // ) : (
            //   <AnomalyTableCreator
            //     selectedDefect={this.state.allInspData}
            //     category={item.value}
            //     value={item.key}
            //     type={item.type}
            //     areAnyRgb={this.state.areAnyRgb}
            //     areAnyThermal={this.state.areAnyThermal}
            //     displayImageOpen={this.displayImageOpen}
            //   />
            // );
          })}
        </div>

        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            alignItems: "center",
            overFlowX: "auto",
            // overflowX:
            //   this.state.selectedDefect &&
            //   this.state.selectedDefect.length > 1
            //     ? "auto"
            //     : "hidden",
          }}
        >
          {/* <Grid item container style={{ paddingTop: "50px" }}>
                <Grid container className={classes.detailsBox}>
                  <Grid item xs={6} style={{ paddingRight: "8px" }}>
                    <Trans></Trans>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={6}>
                <Grid item xs>
                  <Typography className={classes.detail}>
                    Inspection: 8 Dec 2019
                  </Typography>
                </Grid>
              </Grid> */}
          {/* <Grid item >
                <DetailsKeysColumn
                  areAnyRgb={this.state.areAnyRgb}
                  areAnyThermal={this.state.areAnyThermal}
                />
              </Grid> */}
          {/* <Grid item >
                <Grid>
                  {this.state.selectedDefect && (
                    <DetailsValuesCol
                      selectedDefect={this.state.selectedDefect}
                      areAnyRgb={this.state.areAnyRgb}
                      areAnyThermal={this.state.areAnyThermal}
                    />
                  )}
                </Grid>
              </Grid> */}
        </Grid>

        <Grid container className={classes.detailsBox}>
          <Grid item xs={6} style={{ padding: "10px 16px", maxWidth: "200px" }}>
            <Trans>Lat/Long</Trans>
          </Grid>
          <Grid item xs={6} style={{ padding: "0px" }}>
            <Grid container>
              <Grid item xs>
                <Typography
                  className={classes.detail}
                  style={{ padding: "0px 8px " }}
                >
                  {selectedDefect[0].latitude}
                  &#176; ,{selectedDefect[0].longitude}&#176;
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid className={classes.detailsBox} style={{ padding: "10px 16px" }}>
          <Typography
            className={classes.detailSpan}
            style={{
              marginTop: "15px",
              marginBottom: "0px",
              color: "#5B4DD3",
              fontWeight: 500,
            }}
          >
            <Trans>Anomaly Recommendations</Trans>
          </Typography>
          <Typography
            className={classes.detailTxt}
            style={{ marginTop: "0px" }}
          >
            {i18n.t(selectedDefect[selectedDefect.length - 1].recommendation)}
          </Typography>
        </Grid>
        <div style={{ position: "relative", width: "100%", height: "auto" }}>
          {urls.length ? (
            <AutoRotatingCarousel
              label
              autoplay={false}
              hideArrows={false}
              open={this.state.openImage}
              onClose={() => {
                return this.displayImageClose();
              }}
              onStart={() => {
                return this.displayImageClose();
              }}
              style={{ position: "absolute" }}
              ModalProps={{ className: classes.carouselRoot }}
              ButtonProps={{
                style: { borderRadius: "50%", color: "#616161" },
                startIcon: <CloseRoundedIcon style={{ fontSize: "50px" }} />,
                classes: { root: classes.closeButton },
              }}
            >
              {urls.map((url) => {
                return (
                  <React.Fragment key={shortid.generate()}>
                    <Typography className={classes.imageTitle}>
                      {url
                        .match(/([^/]*)\/*$/)[1]
                        .substring(url.match(/([^/]*)\/*$/)[1].length - 18)}
                    </Typography>
                    <Slide
                      media={
                        <div>
                          <MapInteractionCSS
                            showControls
                            controlsClass={classes.controlsDiv}
                            btnClass={classes.zoomButtons}
                            plusBtnContents={
                              <img
                                src={zoomInIcon}
                                alt="zoom in"
                                style={{ width: "24px", height: "24px" }}
                              />
                            }
                            minusBtnContents={
                              <img
                                src={zoomOutIcon}
                                alt="zoom out"
                                style={{ width: "24px", height: "24px" }}
                              />
                            }
                            minScale={0.5}
                            maxScale={20}
                          >
                            <div style={{ width: "640px", height: "512px" }}>
                              <img
                                style={{
                                  maxWidth: "100%",
                                  maxHeight: "100%",

                                  lineHeight: "normal",
                                  borderRadius: "8px",
                                  // marginTop: "80px",
                                }}
                                src={url}
                                alt=""
                                // onMouseEnter={() =>
                                //   this.setState({ canZoom: true })
                                // }
                                // onMouseLeave={() =>
                                //   this.setState({ canZoom: false })
                                // }
                              />
                            </div>
                          </MapInteractionCSS>
                        </div>
                      }
                      mediaBackgroundStyle={{
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgb(0,0,0,0)",
                        padding: "80px",
                      }}
                      style={{
                        backgroundColor: "rgb(0,0,0,0)",
                        display: "flex",
                        alignItems: "center",
                        width: "auto",
                        justifyContent: "center",
                      }}
                    />
                  </React.Fragment>
                );
              })}
            </AutoRotatingCarousel>
          ) : null}
        </div>
      </Grid>
    ) : (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100%" }}
      >
        <NewLoader />
      </Grid>
    );
  }
}

//calling redux to get the selected language
const mapStateToProps = (state) => {
  return {
    language: state.language.selectedLanguage,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(Details));
