export const styles = {
  hr: {
    marginTop: "0.5em",
    marginBottom: "0.5em",
    borderWidth: "1px",
  },
  width500: {
    minWidth: 350,
    maxWidth: 400,
    marginTop: "65px",
    "@media (max-width:320px)": {
      minWidth: 290,
      maxWidth: 320,
    },
  },
  backIcon: {
    cursor: "pointer",
    color: "#536471",
  },
  margin: {
    margin: 1,
  },
  textField: {
    width: "30ch",
  },
  boxArea: {
    padding: 20,
    "&> div": {
      marginBottom: 16,
    },
  },
  textUser: {
    margin: "10px 0",
  },
  MuiGrid: {
    flexGrow: 1,
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "flex-end",
    position: "relative",
    flexDirection: "column",
    marginLeft: "auto",
  },
  title: {
    fontFamily: "Montserrat",
    fontSize: 20,
    fontWeight: 700,
    marginTop: "3px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.2,
    color: "#21282d",
    "@media (max-width: 600px)": {
      marginLeft: 35,
    },
  },
  btn:{
    backgroundColor: "white",
    borderRadius: "5px",
    padding: "5px",
    borderColor: "#e4eaf5",
    marginTop: 20,
  },
  heading: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontStyle: "normal",
    fontSize: 14,
    textTransform: "uppercase",
    color: "#536471",
    display: "flex",
    alignItems: "center",
  },
  userLabel: {
    color: "#536471",
    fontSize: 12,
    fontStyle: "normal",
    fontFamily: "Montserrat",
    fontWeight: 500,
  },
  userSideLabel: {
    color: "#536471",
    fontSize: 12,
    fontStyle: "normal",
    fontFamily: "Montserrat",
    fontWeight: 500,
    padding: "5px 20px",
  },
  userData: {
    color: "#21282D",
    fontSize: 12,
    fontStyle: "normal",
    fontFamily: "Montserrat",
    fontWeight: 500,
  },
  btnSidebar: {
    color: "#fff",
    margin: 0,
    background: "linear-gradient(266.69deg, #5B4DD3 0.23%, #5B4DD3 99.28%)",
    boxShadow: "0px 2px 2px rgba(0, 132, 255, 0.2)",
    borderRadius: 8,
  },
  outerCard: {
    margin: 20,
    background: "white",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
    borderRadius: 5,
    width: 340,
    height: 400,
    "@media (max-width:600px)": {
      marginBottom: 20,
    },
  },
};
