import { Grid, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import AnomaliesCatBar from "../../common/charts/stackcolumn/anomaliesCatBar";
import CategoriesTooltipPopup from "./CategoriesTooltipPopup";
import Divider from "@mui/material/Divider";
import Modal from "@material-ui/core/Modal";
import infoIcon from "../../../assets/img/infoIcon.svg";

export const useStyles = makeStyles(() => {
  return {
    divider: {
      // Theme Color, or use css color in quote
      background: "#F0F4FA",
    },
  };
});

//anomalies category bar
const AnomaliesCategoryBar = (props) => {
  const [anomalyComparisonData, setAnomalyComparisonData] = useState();
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    //Setting chart data
    if (props.anomalyComparisonData) {
      setAnomalyComparisonData(props.anomalyComparisonData);
    }
  }, [props.anomalyComparisonData]);

  return (
    <>
      <Grid
        style={{
          // width: "574px",
          height: "420px",
          borderRadius: "8px",
          backgroundColor: "#FFFFFF",
        }}
      >
        <Grid
          style={{
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "14px",
            lineHeight: "24px",
            color: "#536471",
            paddingTop: "19px",
            paddingLeft: "24px",
            paddingBottom: "19px",
          }}
        >
          <div
            style={{ width: "210px", cursor: "pointer" }}
            onClick={() => {
              setShowTooltip(true);
            }}
            role="button"
            tabIndex={"0"}
            aria-hidden="true"
          >
            ANOMALIES BY CATEGORIES
            <span>
              <img
                alt="infoImg"
                src={infoIcon}
                style={{
                  marginLeft: "8px",
                  position: "relative",
                  top: "3px",
                }}
              />
            </span>
          </div>
        </Grid>
        <Divider sx={{ border: "1px solid #F0F4FA", bgcolor: "#F0F4FA" }} />
        <AnomaliesCatBar chartData={anomalyComparisonData} />
      </Grid>
      {showTooltip ? (
        <Modal open={showTooltip}>
          <div
            style={{
              width: "100vw",
              height: "100vh",
              background: "rgba(0, 0, 0, 0.2)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CategoriesTooltipPopup
              handleClosePopup={() => {
                setShowTooltip(false);
              }}
            />
          </div>
        </Modal>
      ) : (
        ""
      )}
    </>
  );
};

export default AnomaliesCategoryBar;
