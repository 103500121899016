import {
  CANCELUPLOAD,
  CLOSEUPLOADBLOCK,
  SETALLFILENAMES,
  SETALLFILESAWSPARAMS,
  SETALLFILESFORUPLOAD,
  SETALLFILESREDUX,
  SETANOMALIESLIST,
  SETREQUESTOBJ,
  SETSHOWUPLOADBLOCK,
  UPDATEALLFILESAWSPARAMS,
  UPDATEUPLOADFAILED,
} from "../constants/actionTypes";

//Initial state of the reducer
const initialState = {
  showUploadBlock: false,
  allFilesAwsParams: [],
  anomaliesList: [],
  requestObj: {},
  allFilesRedux: null,
  allFilenames: [],
};

//Export the reducer to show the upload block
export const setShowUploadBlockAction = (bool) => {
  return (dispatch) => {
    dispatch({
      type: SETSHOWUPLOADBLOCK,
      payload: bool,
    });
  };
};

//export the reducer to set the allFilesAwsParams
export const setAllFilesAwsParamsAction = (awsParams) => {
  return (dispatch) => {
    dispatch({
      type: SETALLFILESAWSPARAMS,
      payload: awsParams,
    });
  };
};

//Export the reducer to update the allFilesAwsParams
export const updateAllFilesAwsParamsAction = (payload) => {
  return (dispatch) => {
    if (payload.status === "success") {
      dispatch({
        type: UPDATEALLFILESAWSPARAMS,
        payload,
      });
    }
    if (payload.status === "failed") {
      dispatch({
        type: UPDATEUPLOADFAILED,
        payload,
      });
    }
    if (payload.status === "inprogress") {
      dispatch({
        type: UPDATEALLFILESAWSPARAMS,
        payload,
      });
    }
    if (payload.status === "cancelled") {
      dispatch({
        type: CANCELUPLOAD,
        payload,
      });
    }
  };
};

//Export the reducer to set all files for upload
export const setAllFilesForUploadAction = (awsParams) => {
  return (dispatch) => {
    dispatch({
      type: SETALLFILESFORUPLOAD,
      payload: awsParams,
    });
  };
};

//Export the reducer to close the upload block
export const closeUploadAction = () => {
  return (dispatch) => {
    dispatch({
      type: CLOSEUPLOADBLOCK,
      payload: false,
    });
  };
};

//Export the reducer to set the anomalies list
export const setAnomaliesListAction = (anomaliesList) => {
  return (dispatch) => {
    dispatch({
      type: SETANOMALIESLIST,
      payload: anomaliesList,
    });
  };
};

//Export the reducer to set the request object
export const setRequestObjAction = (requestObj) => {
  return (dispatch) => {
    dispatch({
      type: SETREQUESTOBJ,
      payload: requestObj,
    });
  };
};

//Export the reducer to the store all files for upload
export const setAllFilesReduxAction = (allFilesRedux) => {
  return (dispatch) => {
    dispatch({
      type: SETALLFILESREDUX,
      payload: allFilesRedux,
    });
  };
};

//Export the reducer to set all filenames
export const setAllFilenamesAction = (allFilenames) => {
  return (dispatch) => {
    dispatch({
      type: SETALLFILENAMES,
      payload: allFilenames,
    });
  };
};

//Defining the reducer
// skipcq Default parameters should be defined at the last JS-0106
const bulkUpload = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SETSHOWUPLOADBLOCK:
      return { ...state, showUploadBlock: payload };
    case SETALLFILESAWSPARAMS:
      return { ...state, allFilesAwsParams: payload };
    case UPDATEALLFILESAWSPARAMS:
      return {
        ...state,
        allFilesAwsParams: state.allFilesAwsParams.map((file) => {
          if (file.attachment_id === payload.attachmentId) {
            return { ...file, status: payload.status };
          }

          return file;
        }),
      };
    case UPDATEUPLOADFAILED:
      return {
        ...state,
        allFilesAwsParams: state.allFilesAwsParams.map((file) => {
          if (file.attachment_id === payload.attachmentId) {
            return {
              ...file,
              status: payload.status,
              retryFunc: payload.retry,
              retryCount: file.retryCount + 1,
            };
          }

          return file;
        }),
      };
    case CANCELUPLOAD:
      return {
        ...state,
        allFilesAwsParams: state.allFilesAwsParams.map((file) => {
          if (file.attachment_id === payload.attachmentId) {
            return {
              ...file,
              status: payload.status,
              retryCount: 4,
            };
          }

          return file;
        }),
      };
    case CLOSEUPLOADBLOCK:
      return {
        ...state,
        showUploadBlock: false,
        allFilesAwsParams: [],
      };
    case SETANOMALIESLIST:
      return {
        ...state,
        anomaliesList: payload,
      };
    case SETREQUESTOBJ:
      return {
        ...state,
        requestObj: payload,
      };
    case SETALLFILESREDUX:
      return {
        ...state,
        allFilesRedux: payload,
      };
    case SETALLFILENAMES:
      return {
        ...state,
        allFilenames: payload,
      };
    default:
      return state;
  }
};

export default bulkUpload;
