import "./index.css";

import { Grid, Paper } from "@material-ui/core";
import React, { Component } from "react";

import { styles } from "./Help.style";
import { withStyles } from "@material-ui/core/styles";

//video details
class VideoDetail extends Component {
  //Renders the video details (UI only)
  render() {
    const { classes, video } = this.props;

    return (
      video && (
        <Grid item xs={8}>
          <Paper className={classes.paper}>
            <iframe
              title={video.name}
              src={video.url}
              width="600"
              height="520"
              allow="fullscreen"
              loading="lazy"
              type="video/mp4"
              autoPlay
              frameBorder="0"
              sandbox
            />
            <div className={classes.papertitle}>
              <p>{video.name}</p>
            </div>
          </Paper>
        </Grid>
      )
    );
  }
}
export default withStyles(styles)(VideoDetail);
