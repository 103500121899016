import "../PopUp.css";

import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import React, { useRef, useState } from "react";
import {
  setAllFilenamesAction,
  setAllFilesReduxAction,
  setAnomaliesListAction,
  setRequestObjAction,
  setShowUploadBlockAction,
} from "../../../reducers/bulkUpload";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import CloseIcon from "@material-ui/icons/Close";
import CustomButton from "../../common/customStyle/Button";
import FolderType from "../../../assets/img/folderType.svg";
import FolderUpload from "../../../assets/img/folderUpload.svg";
import ImageType from "../../../assets/img/imageType.svg";
import ImgFilesUpload from "../../../assets/img/imgFilesUpload.svg";
import ModalVideo from "react-modal-video";
import ProButton from "../../product/ProButton";
import { Trans } from "react-i18next";
import { URLS } from "../../../constants";
import { allRequestHandler } from "../../../api";
import { connect } from "react-redux";
import fileInput from "../../../assets/img/fileInput.svg";
import i18n from "i18next";
import moment from "moment";
import styles from "../defectMap.style";
import { withStyles } from "@material-ui/core/styles";

//ImportDataPopup component
const ImportDataPopup = ({
  classes,
  product,
  onHandleImportDataPopup,
  onHandleReviewAnomalyPopup,
  linkToProductPage,
  sectionInspectionId,
  setAnomaliesListAction,
  setRequestObjAction,
  setAllFilesReduxAction,
  setAllFilenamesAction,
  callSnackbar,
}) => {
  const [open, setOpen] = useState(true);
  const [hasSelectedCsv, setHasSelectedCsv] = useState(false);
  const [csvAttachmentId, setCsvAttachmentId] = useState(null);
  const [uploadDataCsv, setUploadDataCsv] = useState(null);
  const [folderProps, setFolderProps] = useState([]);
  const [folderFiles, setFolderFiles] = useState([]);
  const [files, setFiles] = useState([]);
  const [videoModal, setVideoModal] = useState(false);
  const folderInputRef = useRef();
  const fileInputRef = useRef();

  //handling importdataPopup
  const handlePopup = () => {
    onHandleImportDataPopup();
  };

  //clearing uploaded data
  const handleClear = () => {
    setUploadDataCsv(null);
    setCsvAttachmentId(null);
    setHasSelectedCsv(false);
    setFiles([]);
    setFolderProps([]);
  };

  //uploading files to AWS
  const uploadFileToAws = (aws_params, file) => {
    if (aws_params && aws_params.length) {
      aws_params.forEach((item) => {
        const data = new FormData();
        data.append("key", item.fields.key);
        data.append("Content-Type", item.fields["Content-Type"]);
        data.append("policy", item.fields.policy);
        data.append("acl", "public-read");
        data.append("success_action_status", "201");
        data.append("x-amz-algorithm", item.fields["x-amz-algorithm"]);
        data.append("x-amz-credential", item.fields["x-amz-credential"]);
        data.append("x-amz-date", item.fields["x-amz-date"]);
        data.append("x-amz-signature", item.fields["x-amz-signature"]);
        data.append("file", file, item.file_name);

        const xhr = new XMLHttpRequest();

        xhr.open("POST", item.url);
        xhr.send(data);
      });
    }
  };

  //csv file input
  const handleCsvFileInput = async (e) => {
    const file = e.target.files[0];
    setUploadDataCsv(file);
    setHasSelectedCsv(true);
    const obj = {
      attachment_list: [
        {
          file_name: file.name,
          content_type: file.type,
          content_length: file.size,
          attachment_type: "bulk_upload_csv",
        },
      ],
    };
    // getting aws param id for csv file upload
    const get_aws_params = await allRequestHandler({
      requestType: "POST",
      requestUrl: URLS.BULK_UPLOAD_CSV,
      requestData: obj,
    });
    if (get_aws_params) {
      const attachmentId = get_aws_params.aws_params[0].attachment_id;
      setCsvAttachmentId(attachmentId);
      uploadFileToAws(get_aws_params.aws_params, file);
    }
  };

  //folder input
  const handleFolderInput = (e) => {
    const fileList = e.target.files;
    const folderName = fileList[1].webkitRelativePath.split("/")[0];
    const date = fileList[1].lastModifiedDate;
    setFolderProps({ name: folderName, length: fileList.length, date });
    setFolderFiles([{ name: folderName, files }]);
  };

  //files input
  const handleFilesInput = (e) => {
    const inputFiles = Array.from(e.target.files);
    setFiles(inputFiles);
  };

  //display files and folder
  const filesAndFolderDisplay = () => {
    const result = [];
    // adding folder to display
    if (folderProps.length > 0) {
      result.push({
        name: folderProps.name,
        length: folderProps.length,
        type: "folder",
        date: folderProps.date,
      });
    }

    // adding files to display
    files.forEach((item) => {
      result.push({
        name: item.name,
        type: item.type,
        date: item.lastModifiedDate,
      });
    });
    return result;
  };

  //remove file or folder
  const removeFileOrFolder = (name, type) => {
    if (type !== "folder") {
      const newFiles = files.filter((item) => {
        return item.name !== name;
      });
      setFiles(newFiles);
      fileInputRef.current.value = "";
    }
    if (type === "folder") {
      setFolderProps({});
      setFolderFiles([]);
      folderInputRef.current.value = "";
    }
  };

  //get file urls
  const getFileUrls = (fileArr) => {
    const filenames = [];
    const updateFileArr = fileArr
      .map((file) => {
        if (filenames.indexOf(file.name) === -1) {
          filenames.push(file.name);
          return {
            file_name: file.name,
            file: window.URL.createObjectURL(file),
          };
        }
        return null;
      })
      .filter((item) => {
        return item !== null;
      });
    return updateFileArr;
  };

  //fetching anomalies list data
  const fetchAnomaliesList = async () => {
    if (!csvAttachmentId) {
      callSnackbar("Please select CSV file", "error");
    } else {
      let fromFolders = [];
      folderFiles.forEach((item) => {
        fromFolders = [...fromFolders, ...item.files];
      });
      let allFiles = [...fromFolders, ...files];
      const allFilesRedux = getFileUrls(allFiles);
      const filenames = [];
      setAllFilenamesAction(filenames);
      allFiles = allFiles
        .map((file) => {
          if (filenames.indexOf(file.name) === -1) {
            filenames.push(file.name);
            return {
              file_name: file.name,
              content_type: file.type,
              content_length: file.size,
            };
          }
          return null;
        })
        .filter((item) => {
          return item !== null && item.content_type;
        });
      const obj = {
        attachment_id: csvAttachmentId,
        file_list: allFiles,
      };

      setRequestObjAction(obj);
      setAllFilesReduxAction(allFilesRedux);

      const anomaliesList = await allRequestHandler({
        requestType: "POST",
        requestUrl: URLS.GET_ANOMALIES_LIST(sectionInspectionId),
        requestData: obj,
      });

      if (anomaliesList) {
        setAnomaliesListAction(anomaliesList.anomalies_list);
        onHandleReviewAnomalyPopup();
      }
    }
  };

  //upload image
  const handleImageUpload = () => {
    fileInputRef.current.click();
  };

  //uploading folder
  const handleFolderUpload = () => {
    folderInputRef.current.click();
  };

  return (
    <Grid container className={classes.popupContainer} direction="column">
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          className={classes.popupItem}
          direction="column"
        >
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            style={{ padding: "12px 16px" }}
          >
            <Typography className={classes.popUpTitle}>
              <Trans>BULK UPLOAD</Trans>
            </Typography>
            <CloseIcon
              fontSize="large"
              className={classes.closeIcon}
              onClick={() => {
                handlePopup();
              }}
            />
          </Grid>
          <Divider />
          <Grid style={{ height: "80vh", overflowY: "scroll" }}>
            <Grid style={{ display: "flex" }}>
              <Grid
                style={{
                  width: "5%",
                  paddingTop: "17px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <div
                  style={{
                    width: 7,
                    height: 7,
                    borderRadius: "100%",
                    background: "#536471",
                  }}
                />
                <div
                  style={{
                    width: 1,
                    height: "192px",
                    background: "#d7dee2",
                    marginRight: 3,
                  }}
                />
                <div
                  style={{
                    width: 7,
                    height: 7,
                    borderRadius: "100%",
                    background: "#536471",
                  }}
                />
              </Grid>
              <Grid style={{ width: "95%" }}>
                <Grid container style={{ padding: "0rem 0" }}>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{ padding: "10px 20px", height: "150px" }}
                  >
                    <Typography
                      className={classes.titleText}
                      style={{ fontWeight: "bold" }}
                    >
                      <Trans>Upload Data CSV</Trans>
                    </Typography>
                    {hasSelectedCsv ? (
                      <Box
                        style={{
                          height: "50px",
                          alignSelf: "center",
                          marginTop: "30px",
                        }}
                      >
                        <Grid
                          container
                          justifyContent="space-between"
                          alignItems="center"
                          className={classes.fileInputField}
                        >
                          <Box
                            component="div"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <IconButton disabled>
                              <img src={fileInput} alt="fileInput" />
                            </IconButton>

                            <Typography className={classes.titleText}>
                              <Trans>Anomalies data import</Trans> –
                              {uploadDataCsv && uploadDataCsv.name}
                            </Typography>
                          </Box>

                          <Typography className={classes.titleText}>
                            <CloseIcon
                              style={{ marginRight: "10px" }}
                              fontSize="medium"
                              className={classes.closeIcon}
                              onClick={() => {
                                setHasSelectedCsv(false);
                                setCsvAttachmentId(null);
                              }}
                            />
                          </Typography>
                        </Grid>
                      </Box>
                    ) : (
                      <>
                        <label
                          component="div"
                          className={classes.dropField}
                          htmlFor="fileUpload"
                        >
                          <Typography className={classes.boxText}>
                            <Box
                              component="span"
                              className={`${classes.selectLink} ${classes.boxText}`}
                            >
                              <label
                                component="span"
                                className={`${classes.selectLink} ${classes.boxText}`}
                                htmlFor="fileUpload"
                              >
                                <Trans>
                                  Select file
                                  <span style={{ color: "#536471" }}>
                                    {" "}
                                    or drop a file here
                                  </span>
                                </Trans>
                              </label>
                            </Box>{" "}
                          </Typography>
                        </label>
                        <input
                          className={classes.fileInput}
                          accept=".csv"
                          id="fileUpload"
                          type="file"
                          onChange={(e) => {
                            handleCsvFileInput(e);
                          }}
                        />
                      </>
                    )}
                  </Grid>
                </Grid>

                <Grid container style={{ padding: "0rem 0" }}>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{ padding: "10px 20px", paddingTop: "60px" }}
                  >
                    <Typography
                      className={classes.titleText}
                      style={{ fontWeight: "bold" }}
                    >
                      <Trans>Upload Images, Files and Folders</Trans>
                    </Typography>
                    <Grid style={{ display: "flex", marginTop: "10px" }}>
                      <Button
                        size="small"
                        variant="outlined"
                        className={classes.bulkUploadButtons}
                        id={"gtm_uploadImagesBulk"}
                        onClick={() => {
                          return handleImageUpload();
                        }}
                      >
                        {/* <label
                            component="div"
                            htmlFor="uploadImagesorFiles"
                            style={{
                              width: "100%",
                              height: "100%",
                              marginBottom: 0,
                            }}
                          > */}
                        <img
                          src={ImgFilesUpload}
                          alt="imgFileUpload"
                          style={{ marginRight: "6px" }}
                        />
                        <Trans>Upload Images or Files</Trans>
                        {/* </label> */}
                      </Button>
                      <Button
                        size="small"
                        variant="outlined"
                        className={classes.bulkUploadButtons}
                        id={"gtm_uploadFolderBulk"}
                        onClick={() => {
                          return handleFolderUpload();
                        }}
                      >
                        {/* <label
                            component="div"
                            htmlFor="uploadFolder"
                            style={{
                              width: "100%",
                              height: "100%",
                              marginBottom: 0,
                            }}
                          > */}
                        <img
                          src={FolderUpload}
                          alt="folderUpload"
                          style={{ marginRight: "6px" }}
                        />
                        <Trans>Upload Folder</Trans>
                        {/* </label> */}
                      </Button>

                      <input
                        className={classes.fileInput}
                        multiple
                        accept="image/*,.csv"
                        id="uploadImagesorFiles"
                        type="file"
                        ref={fileInputRef}
                        onChange={handleFilesInput}
                      />
                      <input
                        className={classes.fileInput}
                        multiple
                        mozdirectory=""
                        directory=""
                        webkitdirectory=""
                        id="uploadFolder"
                        ref={folderInputRef}
                        type="file"
                        onChange={handleFolderInput}
                      />
                    </Grid>
                    <Grid
                      style={{
                        alignSelf: "center",
                        marginTop: "20px",
                      }}
                    >
                      {filesAndFolderDisplay().length > 0 && (
                        <Grid
                          container
                          justifyContent="space-between"
                          alignItems="center"
                          className={classes.filesAndFolderSelections}
                        >
                          {filesAndFolderDisplay().map((item) => {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  width: "100%",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  padding: "6px 15px",
                                  height: 44,
                                }}
                                key={item.name}
                              >
                                <div style={{ display: "flex" }}>
                                  {item.type === "folder" ? (
                                    <img src={FolderType} alt="folder type" />
                                  ) : item.type === "text/csv" ? (
                                    <img src={fileInput} alt="fileInput" />
                                  ) : (
                                    <img src={ImageType} alt="ImageType" />
                                  )}
                                  <Typography
                                    className={classes.titleText}
                                    style={{ margin: "0px 12px" }}
                                  >
                                    {i18n.t(item.name)}
                                  </Typography>
                                </div>
                                <div style={{ display: "flex" }}>
                                  {item.type === "folder" && item.length > 0 && (
                                    <Typography className={classes.titleText}>
                                      {item.length} of {item.length}
                                    </Typography>
                                  )}
                                  <Typography
                                    className={classes.titleText}
                                    style={{ margin: "0px 12px" }}
                                  >
                                    {moment(item.date).format(
                                      "DD-MM-YYYY HH:mm A"
                                    )}
                                  </Typography>
                                  <Typography className={classes.titleText}>
                                    <CloseIcon
                                      fontSize="medium"
                                      className={classes.closeIcon}
                                      onClick={() => {
                                        removeFileOrFolder(
                                          item.name,
                                          item.type
                                        );
                                      }}
                                    />
                                  </Typography>
                                </div>
                              </div>
                            );
                          })}
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              style={{ padding: "10px 25px", paddingTop: "0px" }}
            >
              <Typography
                className={classes.titleText}
                style={{ fontWeight: "bold" }}
              >
                <Trans>How it works?</Trans>
                <span
                  onClick={() => {
                    return setOpen(!open);
                  }}
                  role="button"
                  aria-hidden="true"
                >
                  {open ? (
                    <ArrowDropUpIcon style={{ cursor: "pointer" }} />
                  ) : (
                    <ArrowDropDownIcon style={{ cursor: "pointer" }} />
                  )}
                </span>
              </Typography>
              {open && (
                <div className={classes.dropdown}>
                  <ol style={{ paddingLeft: "16px" }}>
                    <li>
                      <Trans>
                        Download CSV report from the Anomalies/Reports page or
                      </Trans>
                      <Box component="span">
                        <a
                          href="https://dronebase-apac-development.s3.eu-central-1.amazonaws.com/random_data/bulk_upload_sample/SampleCSV.csv"
                          style={{
                            textDecoration: "underline",
                            color: "#5B4DD3",
                          }}
                          id={"gtm_templateCsvBulk"}
                        >
                          <Trans> download a CSV template.</Trans>
                        </a>
                      </Box>{" "}
                    </li>
                    <li>
                      <Trans>
                        Update anomaly details and add file names in the CSV
                        file.
                      </Trans>
                    </li>
                    <li>
                      <Trans>Upload CSV file with updated details.</Trans>
                    </li>
                    <li>
                      <Trans>Upload images and files associated.</Trans>
                    </li>
                    <li>
                      <Trans>
                        Review anomlay details, images names and file names for
                        each anomaly.
                      </Trans>
                    </li>
                  </ol>
                  <Typography className={classes.titleText}>
                    <Trans>Things to remember</Trans>
                  </Typography>
                  <ul style={{ paddingLeft: "24px" }}>
                    <li>
                      <Trans>
                        False-positive anomalies cannot be updated using Bulk
                        Upload, mark it individually in the Anomalies page along
                        with proof.
                      </Trans>
                    </li>
                    <li>
                      <Trans>
                        Anomaly details with the same fault number will be
                        overwritten.
                      </Trans>
                    </li>
                    <li>
                      <Trans>
                        Only columns matching with anomaly details in system can
                        be updated, additional columns will be ignored.
                      </Trans>
                    </li>
                    <Box component="span">
                      <ModalVideo
                        channel="youtube"
                        isOpen={videoModal}
                        videoId="g2G6Ah9dHT8"
                        onClose={() => {
                          return setVideoModal(false);
                        }}
                      />
                      <div
                        onClick={() => {
                          return setVideoModal(true);
                        }}
                        id={"gtm_videoBulk"}
                        style={{ color: "#5B4DD3" }}
                        aria-hidden="true"
                      >
                        <ArrowRightIcon style={{ marginLeft: "-20px" }} />{" "}
                        <Trans>Watch a Video</Trans>
                      </div>
                    </Box>{" "}
                    <Trans>to know more</Trans>
                  </ul>
                </div>
              )}
            </Grid>
          </Grid>

          <Divider className={classes.marTB20} />
          <Grid
            container
            style={{
              justifyContent: "flex-end",
              alignItems: "center",
              paddingRight: "30px",
              height: "8vh",
            }}
          >
            <CustomButton
              variant="outlined"
              onClick={() => {
                handleClear();
              }}
              text={i18n.t("Clear")}
              type="cancel"
              id={"gtm_clearButtonBulk"}
            />
            {product?.remaining_days || product?.product_type === "Pro" ? (
              <CustomButton
                onClick={() => {
                  fetchAnomaliesList();
                }}
                text={i18n.t("Continue & Review Details")}
                id={"gtm_submitButtonBulk"}
              />
            ) : (
              <ProButton onHandleShowPlans={linkToProductPage} />
            )}
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

//calling redux to get the product information (pro/basic) and selected language
const mapStateToProps = (state) => {
  return {
    product: state.product,
    language: state.language.selectedLanguage,
  };
};

export default connect(mapStateToProps, {
  setShowUploadBlockAction,
  setAnomaliesListAction,
  setRequestObjAction,
  setAllFilesReduxAction,
  setAllFilenamesAction,
})(withStyles(styles)(ImportDataPopup));
