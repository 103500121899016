const styles = {
  headerHeight: {
    height: 72,
    boxShadow: "0 2px 10px 0 #4e596d11",
    backgroundColor: "#fff",
    padding: "0 24px",
  },
  title: {
    fontSize: 20,
    fontWeight: 700,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "40px",
    letterSpacing: "normal",
    color: "#08001D",
    marginRight: 20,
  },
  cardName: {
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "24px",
    color: "#536471",
  },
  chartContainer: {
    background: "#fff",
    // overflowX: "auto",
    boxShadow: "0px 4px 12px rgba(78, 89, 109, 0.05)",
    borderRadius: "8px",
    marginBottom: "80px",
  },
  dropdownBox: {
    position: "absolute",
    right: "20px",
    top: "12px",
  },
  inspDateContainer: {
    height: "48px",
    marginTop: "0px",
    marginBottom: "1px",
    border: "1px solid #F0F4FA",
    borderTop: "none",
    background: "white",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "18px",
    color: "#536471",
  },
  inspDate: {
    borderBottom: "1px solid #F0F4FA",
    color: "#536471",
    lineHeight: "18px",
    textAlign: "center",
    width: "351px",
  },
  anomalyTxt: {
    borderBottom: "1px solid #F0F4FA",
    color: "#536471",
    lineHeight: "18px",
    padding: "14px 24px",
  },
  anomalyDivider: {
    height: "0px",
    width: "100%",
    marginTop: "47px",
    borderTop: "2px solid #F0F4FA",
  },
  anomalyHeadText: {
    marginTop: "65px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    color: "#536471",
    lineHeight: "18px",
    marginRight: "137px",
  },
  severityChartBox: {
    marginTop: 60,
    display: "flex",
    justifyContent: "space-between",
    gap: "20px",
  },
  status: {
    marginTop: 60,
    display: "flex",
    justifyContent: "space-between",
    inlineSize: "fit-content",
  },
  yieldBtnContainer: {
    background: "#FFF7D8",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
    padding: "4px 24px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "20px",
  },
  backBtn: {
    minWidth: "0px",
    height: "36px",
    width: "36px",
    borderRadius: "8px",
  },
  yieldPopup: {
    height: "22px",
    padding: "1px 4px",
    borderRadius: "4px",
    marginLeft: "8px",
  },
  AnomalyNrevenueChart: {
    maxWidth: "1172px",
    width: "100%",
    margin: "auto",
    textAlign: "center",
  },
  yieldValueBox: {
    width: "-webkit-fill-available",
    height: "100%",
    position: "fixed",
    overflowX: "hidden",
    overflowY: "auto",
  },
  percent: { color: "#08001d", fontWeight: 600 },
  flexColBox: {
    flexDirection: "column",
    width: "max-content",
  },
  blankBox: {
    borderBottom: "1px solid #F0F4FA",
    color: "#536471",
    lineHeight: "18px",
    padding: "14px 24px",
  },
  tableDivider: {
    display: "flex",
    border: "1px solid #F0F4FA",
    borderRight: "none",
  },
  chartInnerDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    height: "62px",
    padding: "19px 19px 19px 24px",
    position: "relative",
  },
  catToggleBox: {
    borderBottom: "1px solid #F0F4FA",
    color: "#536471",
    width: "351px",
  },
  revenueLossValue: {
    textAlign: "right",
    minWidth: "38px",
    fontSize: "14px",
  },
  titleBtn: {
    fontSize: 12,
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.67,
    letterSpacing: "normal",
    color: "#536471",
    padding: "6px 9px",
  },
  tableCategory: {
    height: "54px",
    width: "282px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "20px",
    color: "#1D2D47",
  },
  tableCategoryContainer: {
    display: "flex",
    gap: "8px",
    alignItems: "center",
    justifyContent: "center",
    padding: "14px 24px",
    width: "282px",
    height: "54px",
  },
  colorCircle: {
    height: "6px",
    width: "6px",
    borderRadius: "100%",
  },
  tableCategoryData: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "20px",
    color: "#1D2D47",
    textAlign: "left",
  },
  tableValue: {
    display: "flex",
    height: "54px",
    alignItems: "center",
    justifyContent: "space-between",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "24px",
    color: "#1D2D47",
    padding: "14px 17px 14px 31px",
  },
  cardTxt: {
    fontSize: 12,
    minHeight: 76,
    backgroundColor: "#fff",
    border: "1px solid #F0F4FA",
    borderTop: "unset",
    padding: "0 16px",
    alignItems: "center",
    display: "flex",
    fontWeight: 500,
    fontStyle: "normal",
    lineHeight: "20px",
    fontFamily: "Montserrat",
    color: "#08001D",
  },
  sectionTxt: {
    fontSize: 12,
    minHeight: 40,
    backgroundColor: "#fff",
    border: "1px solid #F0F4FA",
    borderTop: "unset",
    padding: "0 16px 0 20px",
    alignItems: "center",
    display: "flex",
    fontWeight: 500,
    fontStyle: "normal",
    lineHeight: "20px",
    fontFamily: "Montserrat",
    color: "#08001D",
  },
  sectionHead: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    lineHeight: "20px",
    textTransform: "uppercase",
    color: "#536471",
    padding: "15px 20px",
    backgroundColor: "#fff",
    border: "1px solid #F0F4FA",
  },
  cardValue: {
    padding: "0 16px",
    fontStretch: "normal",
    lineHeight: "20px",
    letterSpacing: "normal",
    fontSize: 18,
    minHeight: 76,
    backgroundColor: "#fff",
    border: "1px solid #F0F4FA",
    borderTop: "unset",
    alignItems: "center",
    display: "flex",
    color: "#08001D",
    fontStyle: "normal",
    fontWeight: 600,
    textTransform: "capitalize",
  },
  cardHead: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "24px",
    color: "#536471",
  },
  cardTitle: {
    fontSize: 14,
    fontWeight: 600,
    fontFamily: "Montserrat",
    fontStyle: "normal",
    lineHeight: "24px",
    color: "#21282D",
    textTransform: "uppercase",
  },
  cardBox: {
    backgroundColor: "#fff",
    minHeight: 80,
    border: "1px solid #F0F4FA",
    padding: "10px 0 0 20px",
    position: "relative",
    boxSizing: "border-box",
    borderRadius: "8px 8px 0px 0px",
  },
  sevChart: {
    minHeight: 90,
    fontSize: 14,
    backgroundColor: "#fff",
    border: "1px solid #F0F4FA",
    borderTop: "unset",
    padding: 23,
    position: "relative",
    marginBottom: "24px",
    "& canvas": {
      position: "absolute",
      marginTop: "-23px",
      borderRadius: "2px !important",
      maxHeight: "90px",
      minHeight: "89px",
    },
  },
  sevHeader: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    height: "194.8px",
    lineHeight: "20px",
    color: "#08001D",
    display: "flex",
    flexDirection: "column",
    borderBottom: "unset",
    backgroundColor: "#fff",
    marginBottom: "24px",
    borderBottomLeftRadius: "8px",
    borderRight: "1px solid #F0F4FA",
  },
  defHead: {
    fontSize: 14,
    backgroundColor: "#fff",
    border: "1px solid #F0F4FA",
    padding: "10px 0 0 10px",
    borderBottomLeftRadius: 20,
  },
  defData: {
    fontSize: 14,
    backgroundColor: "#fff",
    border: "1px solid #F0F4FA",
    padding: "10px 0 0 20px",
  },
  cardLoop: {
    flexWrap: "unset",
    overflow: "auto",
    "& :last-child": {
      "& :last-child": {
        borderBottomRightRadius: "8px",
      },
    },
  },
  cardSmall: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    lineHeight: "20px",
    color: "#536471",
    display: "flex",
    justifyContent: "space-between",
    padding: "2px 12px",
    alignItems: "center",
    textTransform: "uppercase",
    minHeight: 40,
  },
  defType: {
    fontStyle: "normal",
    lineHeight: "15px",
    letterSpacing: "normal",
    minHeight: 50,
    marginBottom: 10,
    fontSize: 12,
    alignItems: "center",
    display: "flex",
    color: "#08001D",
    fontWeight: 500,
    fontFamily: "Montserrat",
    padding: "0px 10px",
  },
  defValue: {
    fontStyle: "normal",
    lineHeight: "24px",
    letterSpacing: "normal",
    minHeight: 50,
    marginBottom: 10,
    fontSize: 14,
    alignItems: "center",
    display: "flex",
    color: "#08001D",
    fontWeight: 500,
    fontFamily: "Montserrat",
  },
  statsData: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "24px",
    textAlign: "right",
  },
  sevTxt: {
    margin: "0 5px",
    fontSize: 14,
    lineHeight: 2,
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
    color: "#536471",
  },
  severityBox: {
    height: 8,
    display: "inline-block",
    width: 8,
    borderRadius: "50%",
    marginRight: 8,
  },
  severityBoxes: {
    height: 6,
    display: "inline-block",
    width: 6,
    borderRadius: "50%",
    marginRight: 5,
  },
  arrowIcon: {
    position: "absolute",
    top: 26,
    left: -18,
    borderRadius: 26,
    padding: 4,
    border: "1px solid #dedede",
    backgroundColor: "#fff",
  },
  breadRow: {
    "& a": {
      fontSize: 12,
      color: "#8C9AA4",
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "20px",
    },
  },
  outerHeader: {
    "@media only screen and (max-width: 760px)": {
      marginLeft: 30,
    },
  },
  switchLabel: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    lineHeight: "20px",
    color: "#536471",
    marginLeft: "5px",
  },
  severityHeadBox: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "white",
    marginRight: "20px",
    marginLeft: "20px",
    marginBottom: "-18px",
    height: "62px",
    paddingRight: "40px",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
  },
  severityHeadTxt: {
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "24px",
    color: "#536471",
    marginLeft: "24px",
  },
  switchRoot: {
    display: "flex",
    marginLeft: "0px !important",
    marginTop: "5px",
  },
  severityText: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "20px",
    margin: "5px 0px",
    color: "#536471",
    whiteSpace: "nowrap",
  },
  txtIcon: {
    marginRight: 8,
    height: 24,
    width: 24,
  },
  tooltipTxt: {
    width: 180,
    fontSize: 11,
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
  },
  secValue: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "24px",
    display: "flex",
    alignItems: "center",
    color: "#08001D",
    backgroundColor: "#fff",
    borderLeft: "1px solid #F0F4FA",
    height: 40,
    padding: "0 20px",
    justifyContent: "space-between",
  },
  sevBarChart: {
    marginTop: 60,
    display: "flex",
    justifyContent: "space-between",
  },
  resolveTxt: {
    minHeight: 40,
    backgroundColor: "#fff",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    lineHeight: "20px",
    textTransform: "uppercase",
    color: "#536471",
    padding: "15px 20px",
    borderLeft: "1px solid #F0F4FA",
    borderTop: "1px solid #F0F4FA",
  },
  defColor: {
    height: 8,
    display: "inline-block",
    width: 8,
    borderRadius: "50%",
    marginRight: 3,
    minWidth: 8,
  },
  condition: {
    width: 51,
    height: 20,
    borderRadius: "40px !important",
    textAlign: "center",
    color: "#fff",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    lineHeight: "20px",
  },
  powerValue: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 18,
    lineHeight: "20px",
    color: "#08001D",
  },
  powerPercent: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "24px",
    color: "#08001D",
    marginLeft: 5,
  },
  revenueTxt: {
    fontStretch: "normal",
    lineHeight: "20px",
    letterSpacing: "normal",
    fontSize: 18,
    color: "#08001D",
    fontStyle: "normal",
    fontWeight: 600,
  },
  yearTxt: {
    textTransform: "initial",
    fontSize: 16,
    fontWeight: 500,
  },
};

export default styles;
