import { Menu, MenuItem } from "@material-ui/core";
import React, { Component } from "react";

import Config from "../../../config";
import CustomButton from "../customStyle/Button";
import DownloadIcon from "../../../components/assets/img/Vector.svg";
import { allRequestHandler } from "../../../api";
import styles from "./DownloadFileNewStyles";
import { withStyles } from "@material-ui/core/styles";

const requestUrl = Config.hostName;

//To download reprts file
class DownloadFile extends Component {
  state = {
    anchorEl: null,
    downloadMenu: false,
  };

  //Handles the report download menu
  handleDownload = (event) => {
    this.setState({ downloadMenu: true, anchorEl: event.currentTarget });
  };

  //Closes the report download menu
  handleCloseDropdown = () => {
    this.setState({ downloadMenu: false });
  };

  //Redirect site to download the report
  redirectSite = async (data) => {
    const downUrl = await allRequestHandler({
      requestType: "GET",
      requestUrl: requestUrl + data.href,
    });

    window.open(downUrl.file_url);
    this.handleCloseDropdown();
  };

  //Renders the report download menu
  render() {
    const { classes } = this.props;

    return (
      <>
        <CustomButton
          text={window.innerWidth >= 760 ? "Download Reports" : ""}
          onClick={(e) => {
            return this.handleDownload(e);
          }}
          btnIcon={DownloadIcon}
          type={"cancel"}
          margin={5}
        />
        <Menu
          id="long-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={this.state.downloadMenu}
          onClose={this.handleCloseDropdown}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
          classes={{ paper: classes.paperStyle }}
        >
          {this.props.downloadList.map((report) => {
            return (
              <MenuItem
                ListItemClasses={{
                  root: classes.lists,
                }}
                key={report.name}
                onClick={() => {
                  return this.redirectSite(report);
                }}
              >
                {report.name}
              </MenuItem>
            );
          })}
        </Menu>
      </>
    );
  }
}

export default withStyles(styles)(DownloadFile);
