import {
  Button,
  FormControl,
  Grid,
  IconButton,
  OutlinedInput,
  Snackbar,
  Typography,
  withStyles,
} from "@material-ui/core";

import MuiAlert from "@material-ui/lab/Alert";
import { Navigate } from "react-router-dom";
import React from "react";
import { URLS } from "../../constants";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { allRequestHandler } from "../../api";
import loginIllustration from "../../components/assets/img/login_Illustration_compressed.png";
import styles from "./login.style";
import zeitviewLogo from "../../assets/img/zeitviewLogo.svg";

//resetPassword page. This is the page that is displayed when the user clicks on the reset password link in the email.
class ResetPassword extends React.Component {
  state = {
    confirmPassword: "",
    showPassword: false,
    newPassword: "",
    showPasswordConfirm: false,
    displayMsg: null,
    useSnackbar: false,
    redirect: false,
    sev: "info",
  };

  //On entering the page, the token is verified. If the token is valid, the user is allowed to reset the password.
  async componentDidMount() {
    let authKey = window.location.href;
    authKey = authKey.split("taken=")[1];
    try {
      const verifyToken = await allRequestHandler({
        requestType: "FORGOT",
        requestUrl: URLS.VERIFY_TOKEN,
        auth: authKey,
      });
      if (!verifyToken) {
        // skipcq Avoid using setState in componentDidMount JS-0442
        this.setState({
          useSnackbar: true,
          displayMsg: "Invalid Token",
        });
      }
    } catch (error) {
      // skipcq Avoid using setState in componentDidMount JS-0442
      this.setState({
        redirect: true,
      });
    }
  }

  //Handling the input of the password and confirm password fields.
  handleSubmit = () => {
    const { newPassword, confirmPassword } = this.state;

    if (newPassword === "" || confirmPassword === "") {
      this.setState({
        useSnackbar: true,
        displayMsg: "Please fill all Details.",
      });
    } else if (newPassword !== confirmPassword) {
      this.setState({
        useSnackbar: true,
        displayMsg: "Passwords don't match",
      });
    } else if (newPassword.length <= 7) {
      this.setState({
        useSnackbar: true,
        displayMsg: "Minimum password length should be 8 ",
      });
    } else {
      this.resetPassword();
    }
  };

  //If the token is valid, and new password meet all the conditions, the user is allowed to reset the password.
  resetPassword = async () => {
    let authKey = window.location.href;
    authKey = authKey.split("taken=")[1];

    let ifPasswordSuccess = "";

    try {
      ifPasswordSuccess = await allRequestHandler({
        requestType: "RESET",
        requestUrl: URLS.RESET_PASSWORD,
        auth: authKey,
        requestData: {
          password: this.state.newPassword,
        },
      });
    } catch (error) {
      this.setState({
        useSnackbar: true,
        displayMsg: error.response.data.message.password[0],
        sev: "warning",
      });
      this.setState({
        redirect: false,
      });
      if (!this.state.redirect) {
        this.props.callSnackbar(this.state.displayMsg);
      }
    }
    if (ifPasswordSuccess) {
      this.setState({
        redirect: true,
      });
      this.props.callSnackbar("Your password has been changed successfully");
    }
  };

  //Closing the snackbar (warnings, info and errors alert )
  handleCloseSnackbar = () => {
    this.setState({ useSnackbar: false });
  };

  //Handling the input of the password and confirm password fields.
  handleChange = (event, prop) => {
    this.setState({ [prop]: event.target.value });
  };

  //Handling the visibility of the password field. (eye icon)
  handleClickShowPassword = () => {
    this.setState((prevState) => {
      return {
        showPassword: !prevState.showPassword,
      };
    });
  };

  //Handling the visibility of the confirm password field. (eye icon)
  handleClickConfirm = () => {
    this.setState((prevState) => {
      return {
        showPasswordConfirm: !prevState.showPasswordConfirm,
      };
    });
  };

  //This is the alert that is displayed when the user clicks on the reset password link in the email. It allows the user to reset their password.
  render() {
    const { classes } = this.props;

    if (this.state.redirect) {
      return <Navigate replace to="/" />;
    }

    return (
      <>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={3000}
          open={this.state.useSnackbar}
          onClose={this.handleCloseSnackbar}
        >
          <MuiAlert elevation={6} variant="filled" severity={this.state.sev}>
            {" "}
            {this.state.displayMsg}{" "}
          </MuiAlert>
        </Snackbar>

        <Grid container className={classes.rowC}>
          <Grid item xs={6} className={classes.root1}>
            <Grid
              container
              className="height100"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <img
                  className={classes.imgLogo}
                  src={zeitviewLogo}
                  alt="logo"
                />
                <Typography className={classes.loginText}>
                  {" "}
                  Reset Password{" "}
                </Typography>

                <form>
                  <Typography className={classes.passText}>
                    {" "}
                    Enter new password{" "}
                  </Typography>

                  <FormControl variant="outlined" fullWidth>
                    <OutlinedInput
                      id="newPassword"
                      style={{ height: 40, margin: "12px 0px 10px" }}
                      type={this.state.showPassword ? "text" : "password"}
                      value={this.state.newPassword}
                      onChange={(e) => {
                        this.handleChange(e, "newPassword");
                      }}
                      endAdornment={
                        <IconButton
                          onClick={this.handleClickShowPassword}
                          edge="end"
                        >
                          {this.state.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      }
                    />
                  </FormControl>

                  <Typography className={classes.passText}>
                    {" "}
                    Confirm password{" "}
                  </Typography>
                  <FormControl fullWidth variant="outlined">
                    <OutlinedInput
                      style={{ height: 40, margin: "12px 0px 10px" }}
                      id="confirmPassword"
                      type={
                        this.state.showPasswordConfirm ? "text" : "password"
                      }
                      value={this.state.confirmPassword}
                      onChange={(e) => {
                        this.handleChange(e, "confirmPassword");
                      }}
                      endAdornment={
                        <IconButton
                          onClick={this.handleClickConfirm}
                          edge="end"
                        >
                          {this.state.showPasswordConfirm ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      }
                    />
                  </FormControl>

                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.resetBtn}
                    onClick={() => {
                      return this.handleSubmit();
                    }}
                  >
                    Save
                  </Button>
                </form>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <img
              className={classes.illustrationImg}
              src={loginIllustration}
              alt="login_Design"
            />
          </Grid>
        </Grid>
      </>
    );
  }
}

export default withStyles(styles)(ResetPassword);
