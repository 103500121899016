export default {
  root: {
    maxWidth: 345,
    width: "340px",
    minHeight: "315px",
    maxHeight: "330px",
    background: "#FFFFFF",
    boxShadow: "0px 5.36585px 16.0976px rgba(78, 89, 109, 0.05)",
    borderRadius: 8,
    marginBottom: 24,
    marginLeft: 12,
    marginRight: 12,
    justifyContent: "center",
    position: "relative",
    transition: "transform 0.15s ease-in-out",
    "&:hover": {
      visibility: "visible",
      transform: "scale3d(1.05, 1.05, 1)",
    },
  },
  media: {
    height: 120,
    width: 340,
  },
  plantLetter: {
    position: "absolute",
    left: "136px",
    top: "26px",
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "40px",
    lineHeight: "60px",
    color: "#FFFFFF",
    borderRadius: "50%",
    width: "67px",
    height: "67px",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
  },
  plantName: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "18px",
    color: "#21282D",
    lineHeight: "20px",
    marginBottom: 4,
  },
  plantLocation: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    color: "#536471",
    lineHeight: "20px",
  },
  dateBlock: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 24,
    display: "flex",
  },
  dateHeader: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    color: "#536471",
    lineHeight: "20px",
  },
  dates: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    color: "#21282D",
    lineHeight: "20px",
  },
  buttonsGroup: {
    bottom: 0,
    width: "100%",
    height: "44px",
    position: "absolute",
    paddingTop: 0,
    paddingBottom: 0,
    display: "flex",
    justifyContent: "space-evenly",
    color: "#21282D",
    borderTop: "1px solid #F0F4FA",
    fontSize: "14px",
    "& a": {
      borderRight: "none !important",
    },
  },
  buttons: {
    padding: "12px 32px",
    lineHeight: "20px",
    margin: 0,
    display: "flex",
    borderRight: "1px solid #F0F4FA !important",
    borderRadius: "0px",
    "&:focus": {
      outline: "none !important",
      color: "#149B9E !important",
    },
    "&:hover": {
      backgroundColor: "#F0F4FA !important",
    },
    "&:focus span": {
      color: "#149B9E !important",
    },
    "&:disabled img": {
      opacity: "0.4",
    },
  },
  button2: {
    width: "40px",
    padding: "12px 32px",
    lineHeight: "20px",
    margin: 0,
    display: "flex",
    "&:focus": {
      outline: "none !important",
      color: "#149B9E !important",
    },
    "&:hover": {
      backgroundColor: "#F0F4FA !important",
    },
    "&:focus span": {
      color: "#149B9E !important",
    },
    "&:disabled img": {
      opacity: "0.4",
    },
  },
  button3: {
    borderRight: "1px solid #F0F4FA !important",
    borderRadius: "0px",
    padding: "12px 32px",
    lineHeight: "20px",
    margin: 0,
    display: "flex",
    "&:focus": {
      outline: "none !important",
      color: "#149B9E !important",
    },
    "&:hover": {
      backgroundColor: "#F0F4FA !important",
    },
    "&:focus span": {
      color: "#149B9E !important",
    },
    "&:disabled img": {
      opacity: "0.4",
    },
  },
  buttonSpan: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    color: "#21282D",
  },
  lists: {
    backgroundColor: "rgba(0,0,0,0)",
    color: "#536471",
    width: "240px",
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "12px",
    "&:hover": {
      backgroundColor: "#F0F4FA",
    },
  },
  paperStyle: {
    border: "1px solid #DDE5EC",
    boxShadow: "0px 4px 12px rgba(78, 89, 109, 0.05)",
    borderRadius: "8px",
  },
  uploadOuter: {
    background: "#fff",
    position: "absolute",
    right: 8,
    height: 40,
    width: 40,
    top: 8,
    borderRadius: 6,
    border: "1.34146px solid #DDE5EC",
    boxSizing: "border-box",
    visibility: "hidden",
  },
  uploadLabel: {
    width: 38,
    height: 38,
    marginBottom: 0,
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
  },
  addImgLabel: {
    width: "100%",
    height: "100%",
    cursor: "pointer",
    marginBottom: 0,
    display: "flex",
    alignItems: "center",
  },
  listAddImg: {
    backgroundColor: "rgba(0,0,0,0)",
    color: "#536471",
    width: 240,
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: 12,
    paddingTop: 0,
    paddingBottom: 0,
    height: 32,
    "&:hover": {
      backgroundColor: "#F0F4FA",
    },
  },
  uploadIcon: {
    width: 13,
    opacity: 0.7,
    marginRight: 8,
  },
  moreIcon: {
    paddingLeft: 9,
    paddingTop: 11,
    visibility: "visible",
  },
  cardContentStyle: {
    paddingLeft: "24px",
    paddingTop: "16px",
    paddingBottom: "16px",
  },
};
