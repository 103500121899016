import { ClickAwayListener } from "@material-ui/core";
import CustomButton from "../../common/customStyle/Button";
import React from "react";
import crossIcon from "../../../assets/img/popupCross.svg";
import data from "./data";

//Anomalies category tooltip
function CategoriesTooltipPopup(props) {
  return (
    <ClickAwayListener onClickAway={props.handleClosePopup}>
      <div
        style={{
          height: "460px",
          width: "869px",
          background: "#FFFFFF",
          boxShadow: "0px 4px 12px rgba(78, 89, 109, 0.05)",
          borderRadius: "8px",
          fontFamily: "Montserrat",
          fontStyle: "normal",
          boxSizing: "border-box",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "16px 32px",
            borderBottom: "1px solid #E4EAF5",
          }}
        >
          <p
            style={{
              color: "#536471",
              fontWeight: "600",
              fontSize: "14px",
              lineHeight: "24px",
              margin: 0,
              padding: 0,
            }}
          >
            ANOMALY CATEGORY
          </p>
          <img
            src={crossIcon}
            alt="close"
            style={{ cursor: "pointer" }}
            onClick={props.handleClosePopup}
            aria-hidden="true"
          />
        </div>

        <div
          style={{
            padding: "24px 32px 40px 32px",
            color: "#1D2D47",
            fontWeight: "500",
            fontSize: "14px",
            lineHeight: "24px",
            borderBottom: "1px solid #E4EAF5",
          }}
        >
          <div>
            Anomalies are compared with historical data of inspections and
            categorised as follows:-
          </div>
          <div style={{ border: "1px solid #E4EAF5", marginTop: "16px" }}>
            {data.map((value, i) => {
              return (
                <div
                  key={value.category}
                  style={{
                    display: "flex",
                    padding: "0px 24px",
                    height: i === 0 ? "40px" : "48px",
                    alignItems: "center",
                    background: i === 0 ? "#F0F4FA" : "#fff",
                    borderTop: i === 0 ? "none" : "1px solid #E4EAF5",
                  }}
                >
                  <div
                    style={{
                      width: "192px",
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    {i !== 0 && (
                      <div
                        style={{
                          width: "6px",
                          height: "6px",
                          borderRadius: "100%",
                          background: value.color,
                        }}
                      />
                    )}
                    <div>{value.category}</div>
                  </div>
                  <div>{value.description}</div>
                </div>
              );
            })}
          </div>
        </div>

        <div>
          <CustomButton
            onClick={props.handleClosePopup}
            text="Done"
            style={{
              height: "36px",
              width: "75px",
              marginTop: "14px",
              marginLeft: "397.5px",
            }}
          />
        </div>
      </div>
    </ClickAwayListener>
  );
}

export default CategoriesTooltipPopup;
