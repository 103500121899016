export default {
  card: {
    borderRadius: 8,
    height: "100%",
    boxShadow: "0px 2px 10px rgba(78, 89, 109, 0.05)",
  },
  contentMob: {
    paddingBottom: 12,
    "@media only screen and (max-width: 600px)": {
      padding: "12px 8px",
    },
  },
  simpleTxt: {
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "20px",
    letterSpacing: "0px",
    textAlign: "center",
    color: "#536471",
  },
  outerDiv: {
    position: "absolute",
    textAlign: "center",
    width: "500px",
    height: "250px",
    maxWidth: "80%",
    bottom: 10,
    left: 0,
    right: 0,
    margin: "auto",
  },
  cardContainer: {
    position: "absolute",
    textAlign: "center",
    width: "500px",
    height: "250px",
    maxWidth: "80%",
    bottom: 10,
    left: 0,
    right: 0,
    margin: "auto",
  },
};
