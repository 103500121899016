import { URLS } from "../constants";
import axios from "axios";
import { callSnackbarAction } from "../reducers/snackbar";
import { store } from "../store";

//snackbar to show the error
function callSnackbar(err, showSnackbar, internalErrorLogout, errHandlerFunc) {
  //To handle automatic logout after session timeout
  const handleAutomaticSessionLog = async () => {
    //session will expire only when user is logged in.
    if (window.location.pathname !== "/") {
      await allRequestHandler({
        requestType: "POST",
        requestUrl: URLS.LOGOUT,
        requestData: "",
      });
      sessionStorage.setItem("jwtToken", "");
      window.location.pathname = "/";
    }
  };
  if (err.response?.status && err.response?.status === 500) {
    showSnackbar &&
      store.dispatch(
        callSnackbarAction("There was an internal server error", "error")
      );
    if (internalErrorLogout) {
      sessionStorage.setItem("jwtToken", "");
      window.location.href = "/";
    }
  } else if (
    err.response?.message &&
    typeof err.response?.message !== "object"
  ) {
    showSnackbar &&
      store.dispatch(
        callSnackbarAction(
          `${
            err.response.message.length > 0
              ? err.response.message
              : "There was an error"
          }`,
          "error"
        )
      );
  } else if (
    typeof err?.response?.data.message !== "object" &&
    err?.response?.status === 400
  ) {
    showSnackbar &&
      store.dispatch(
        callSnackbarAction(
          `${
            err?.response.data?.message
              ? err?.response.data?.message
              : "There was an error"
          }`,
          "error"
        )
      );
  } else {
    if (err.response?.data?.message && window.location.href !== "/") {
      if (typeof err.response?.data?.message === "object") {
        const errorArr = Object.values(err.response.data.message);
        errorArr.forEach((errShow) => {
          if (errShow instanceof Array) {
            errShow.forEach((item) => {
              showSnackbar && store.dispatch(callSnackbarAction(item, "error"));
            });
          }
          showSnackbar && store.dispatch(callSnackbarAction(errShow, "error"));
        });
      } else {
        showSnackbar &&
          store.dispatch(
            callSnackbarAction(err.response?.data?.message, "error")
          );
        if (!err.response?.data?.message.includes("credentials")) {
          handleAutomaticSessionLog();
        }
      }
    } else {
      // window.location.href = "/PageNotFound";
    }
  }
  return errHandlerFunc(err);
}

//To fetch response for all the request types
export async function allRequestHandler(
  requestPayload,
  showSnackbar = true,
  internalErrorLogout = true,
  errHandlerFunc = () => {
    /**/
  }
) {
  switch (requestPayload.requestType) {
    case "GET": {
      const requestObject = {
        method: "get",
        url: requestPayload.requestUrl,
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
          // "Accept-Encoding": "gzip",
        },
      };
      try {
        const result = await axios(requestObject);
        return result.data;
      } catch (err) {
        return callSnackbar(
          err,
          showSnackbar,
          internalErrorLogout,
          errHandlerFunc
        );
      }
    }
    case "FORGOT": {
      const requestObject = {
        method: "get",
        url: requestPayload.requestUrl,
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${requestPayload.auth}`,
          // "Accept-Encoding": "gzip",
        },
      };
      try {
        const result = await axios(requestObject);
        return result.data;
      } catch (err) {
        return callSnackbar(
          err,
          showSnackbar,
          internalErrorLogout,
          errHandlerFunc
        );
      }
    }
    case "DELETE": {
      const requestObject = {
        method: "delete",
        url: requestPayload.requestUrl,
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
          // "Accept-Encoding": "gzip",
        },
      };
      try {
        const result = await axios(requestObject);
        return result.data;
      } catch (err) {
        return callSnackbar(
          err,
          showSnackbar,
          internalErrorLogout,
          errHandlerFunc
        );
      }
    }
    case "RESET": {
      const requestObject = {
        method: "post",
        url: requestPayload.requestUrl,
        data: requestPayload.requestData,
        headers: {
          authorization: `Bearer ${requestPayload.auth}`,
          // "Accept-Encoding": "gzip",
        },
      };
      requestObject.responseType = requestPayload.responseType
        ? requestPayload.responseType
        : null;
      try {
        const result = await axios(requestObject);
        return result.data;
      } catch (err) {
        return callSnackbar(
          err,
          showSnackbar,
          internalErrorLogout,
          errHandlerFunc
        );
      }
    }
    case "POST": {
      const requestObject = {
        method: "post",
        url: requestPayload.requestUrl,
        data: requestPayload.requestData,
        headers: {
          authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
          // "Accept-Encoding": "gzip",
        },
      };
      requestObject.responseType = requestPayload.responseType
        ? requestPayload.responseType
        : null;
      try {
        const result = await axios(requestObject);
        return result.data;
      } catch (err) {
        return callSnackbar(
          err,
          showSnackbar,
          internalErrorLogout,
          errHandlerFunc
        );
      }
    }
    case "PUT": {
      const requestObject = {
        method: "put",
        url: requestPayload.requestUrl,
        data: requestPayload.requestData,
        headers: {
          authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
          // "Accept-Encoding": "gzip",
        },
      };
      requestObject.responseType = requestPayload.responseType
        ? requestPayload.responseType
        : null;
      try {
        const result = await axios(requestObject);
        return result.data;
      } catch (err) {
        return callSnackbar(
          err,
          showSnackbar,
          internalErrorLogout,
          errHandlerFunc
        );
      }
    }
    case "PATCH": {
      const requestObject = {
        method: "patch",
        url: requestPayload.requestUrl,
        data: requestPayload.requestData,
        headers: {
          authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
          // "Accept-Encoding": "gzip",
        },
      };
      requestObject.responseType = requestPayload.responseType
        ? requestPayload.responseType
        : null;
      try {
        const result = await axios(requestObject);
        return result.data;
      } catch (err) {
        return callSnackbar(
          err,
          showSnackbar,
          internalErrorLogout,
          errHandlerFunc
        );
      }
    }
    case "LOGIN": {
      const requestObject = {
        method: "post",
        url: requestPayload.requestUrl,
        data: requestPayload.requestData,
        headers: {
          "Content-Type": "application/json",
          // "Accept-Encoding": "gzip",
        },
      };
      requestObject.responseType = requestPayload.responseType
        ? requestPayload.responseType
        : null;
      try {
        const result = await axios(requestObject);
        return result.data;
      } catch (err) {
        return callSnackbar(
          err,
          showSnackbar,
          internalErrorLogout,
          errHandlerFunc
        );
      }
    }
    default: {
      showSnackbar && callSnackbar("There was an error", "error");
      return {
        message: "Invalid HTTP Method",
        status: 400,
        url: requestPayload.requestUrl
          ? requestPayload.requestUrl
          : "URL NOT FOUND",
      };
    }
  }
}