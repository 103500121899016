import "./report.css";

import { FormControl, Grid, Typography, withStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";

import BackArrowImg from "../../assets/img/backArrow.svg";
import CustomButton from "../common/customStyle/Button";
import CustomCheckbox from "../common/customStyle/CheckBox";
import CustomDropdown from "../common/customStyle/Dropdown";
import CustomReportView from "./CustomReportView";
import DeleteBtn from "../../assets/img/deleteBtn.svg";
import { Multiselect } from "multiselect-react-dropdown";
import ProButton from "../product/ProButton";
import TemperatureRange from "../common/temperatureRange";
import { Trans } from "react-i18next";
import { URLS } from "../../constants";
import _ from "lodash";
import { allRequestHandler } from "../../api";
import { connect } from "react-redux";
import i18n from "i18next";
import moment from "moment";
import styles from "./reports.style";

//Create custom report
const CustomReportCreate = ({
  setShowCustomReport,
  callSnackbar,
  getReportHistory,
  classes,
  formData,
  anomalies,
  setFormData,
  userTimeOut,
  plantList,
  product,
  onHandleShowPlans,
  tempRange,
  setTempRange,
  language,
}) => {
  const [showGenerateReport, setShowGenerateReport] = useState(false);
  const [downloadHref, setDownloadHref] = useState([]);
  const [customAnomalyList, setCustomAnomalyList] = useState([]);
  const [selectedAnomalies, setSelectedAnomalies] = useState([]);
  // const [sectionName, setSectionName] = useState("All Sections");
  const [sectionName, setSectionName] = useState(null);
  const [selectedInspDate, setSelectedInspDate] = useState(null);
  const [startIndex, setStartIndex] = useState(0);
  const [translatedAnomalies, setTranslatedAnomalies] = useState([]);
  const [translatedSelectedAnomalies, setTranslatedSelectedAnomalies] =
    useState([]);
  // const [tempChanged, setTempChanged] = useState(false);
  const reportRef = useRef(null);
  const anomalyRef = useRef(null);

  //API call to get the custom report based on the parameters provided
  const getCustomReport = async () => {
    const requestBody = {
      ...formData,
      section_id: formData.section_id === null ? null : formData.section_id,
      // section_id: formData.section_id,
    };

    //Filter the report object based on the selected parameters
    const nonSelected = Object.entries(requestBody)
      .filter(([key]) => {
        return (
          key === "panel_details" ||
          key === "anomaly_details" ||
          key === "attachments"
        );
      })
      .map((item) => {
        return item[1];
      })
      .reduce((acc, item) => {
        const result = Object.values(item);
        const newAcc = [...acc, ...result];
        return newAcc;
      }, [])
      .filter((value) => {
        return value;
      });

    if (nonSelected.length === 0) {
      setCustomAnomalyList([]);
      callSnackbar("Please select a metric field", "warning");
    } else {
      const response = await allRequestHandler({
        requestType: "POST",
        requestUrl: `${URLS.REPORT_API}/custom`,
        requestData: requestBody,
      });
      if (response) {
        if (response && response.status === 401) {
          sessionStorage.setItem("jwtToken", "");
          userTimeOut();
        } else {
          setDownloadHref(response.href_list);
          setStartIndex(0);
          if (response.anomaly_list.length > 0) {
            setCustomAnomalyList(
              _.orderBy(response.anomaly_list, "anomaly_number", "asc")
            );

            //On click of view report scroll down
            window.scrollTo(0, document.body.scrollHeight);
          } else {
            setCustomAnomalyList([]);
            callSnackbar("No Data available for the current selection", "info");
          }
        }
      }
    }
  };
  const siteSelect = [];
  const sectionSelect = [];
  let inspDateList = [];

  //These functions are called whenever the props or state changes. Currently monitoring the language prop and selectedAnomalies state
  useEffect(() => {
    const anomaliesData = [];
    const selectedAnomaliesData = [];

    //Translate defect name based on the selected language and add defect_type_id in the object
    anomalies.map((data) => {
      const tempData = {};
      tempData.defect_name = i18n.t(data.defect_name);
      tempData.defect_type_id = data.defect_type_id;
      return anomaliesData.push(tempData);
    });

    //Translate defect name based on the selected language and add defect_type_id in the object
    selectedAnomalies.map((data) => {
      const tempData = {};
      tempData.defect_name = i18n.t(data.defect_name);
      tempData.defect_type_id = data.defect_type_id;
      return selectedAnomaliesData.push(tempData);
    });

    setTranslatedAnomalies(anomaliesData);
    setTranslatedSelectedAnomalies(selectedAnomaliesData);
  }, [language, selectedAnomalies]);

  //scroll to table viewport after generating report.
  useEffect(() => {
    if (customAnomalyList && customAnomalyList.length) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, [customAnomalyList]);

  useEffect(() => {
    if (!selectedInspDate) setSelectedInspDate(inspDateList[0]?.value);
  }, [inspDateList, plantList]);

  //Sort and push the site details in the siteSelect array
  if (plantList && plantList.length > 0) {
    _.orderBy(plantList, "plant_name", "asc").map((item) => {
      return siteSelect.push({
        name: item.plant_name,
        value: item.plant_id,
        id: item.plant_id,
      });
    });
  }

  //Update inspection date list
  if (plantList && plantList.length) {
    const filterInspList = plantList.find((filterDate) => {
      return filterDate.plant_id === formData.plant_id;
    });

    _.orderBy(filterInspList.inspection_list, ["inspection_date"], ["asc"]).map(
      (item) => {
        return inspDateList.push({
          name: moment(item.inspection_date).format("D MMM, YYYY"),
          value: item.plant_inspection_id,
          id: item.plant_inspection_id,
        });
      }
    );

    inspDateList = inspDateList.sort(
      (a, b) => new Date(b.name) - new Date(a.name)
    );
  }

  //Update section list
  if (formData.plant_id) {
    if (sectionSelect) {
      sectionSelect.push({
        name: "All Sections",
        value: "All Sections",
        id: null,
      });
    }

    _.orderBy(
      plantList.filter((selected) => {
        return selected.plant_id === formData.plant_id;
      })[0].section_list,
      (item) => {
        const string = item.section_name.split(" ");
        return parseInt(string[string.length - 1], 10);
      },
      ["asc"]
    ).map((site) => {
      return sectionSelect.push({
        name: site.section_name,
        value: site.section_id,
        id: site.section_id,
      });
    });
  }

  //select default value
  useEffect(() => {
    if (plantList) {
      setSectionName(sectionSelect[0].value);
    }
  }, [plantList]);

  //Set the default value for the section
  const setDefaultSectionId = (value) => {
    let sectionList = _.orderBy(
      plantList.filter((selected) => {
        return selected.plant_id === value;
      })[0].section_list,
      (item) => {
        const string = item.section_name.split(" ");
        return parseInt(string[string.length - 1], 10);
      },
      ["asc"]
    );
    // sectionList = [
    //   { section_id: null, section_name: "All Sections" },
    //   ...sectionList,
    // ];
    setSectionName(sectionList[0].section_name);
    return sectionList[0].section_id;
  };

  //Find the id of the selected section
  const sectionIdFinder = (value) => {
    const id = sectionSelect.filter((item) => {
      return item.value === value;
    });

    return id[0].id;
  };

  //Updating Inspection date
  const updateInspDate = (value) => {
    //Filter plants based on the selected inspection date
    const filterInsp = plantList.find((insp) => {
      return insp.plant_id === value;
    });

    inspDateList = filterInsp.inspection_list;
    inspDateList = inspDateList.sort(
      (a, b) => new Date(b.inspection_date) - new Date(a.inspection_date)
    );

    return inspDateList[0].plant_inspection_id;
  };

  //custom form handler
  const customFormHandler = (section, e) => {
    switch (section) {
      case "plant_id": {
        const section_id = setDefaultSectionId(e.target.value);
        const inspDate_id = updateInspDate(formData?.plant_id);
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
          section_id,
          plant_inspection_id: inspDate_id,
          anomaly_type_list: [],
        });
        setCustomAnomalyList([]);
        setSelectedInspDate(inspDate_id);
        break;
      }
      case "plant_inspection_id": {
        setFormData({
          ...formData,
          plant_inspection_id: updateInspDate(formData?.plant_id),
          anomaly_type_list: [],
        });
        setSelectedInspDate(e.target.value);
        break;
      }
      case "section_id": {
        setFormData({
          ...formData,
          plant_id: formData.plant_id,
          [e.target.name]: sectionIdFinder(e.target.value),
          anomaly_type_list: [],
          plant_inspection_id: updateInspDate(formData?.plant_id), 
        });
        setCustomAnomalyList([]);
        setSectionName(e.target.value);
        break;
      }
      case "panel_details": {
        setFormData({
          ...formData,
          panel_details: {
            ...formData.panel_details,
            [e.target.name]: e.target.checked,
          },
          plant_inspection_id: updateInspDate(formData?.plant_id), 
        });
        break;
      }
      case "anomaly_details": {
        setFormData({
          ...formData,
          anomaly_details: {
            ...formData.anomaly_details,
            [e.target.name]: e.target.checked,
          },
          plant_inspection_id: updateInspDate(formData?.plant_id), 
        });
        break;
      }
      case "attachments": {
        setFormData({
          ...formData,
          attachments: {
            ...formData.attachments,
            [e.target.name]: e.target.checked,
          },
          plant_inspection_id: updateInspDate(formData?.plant_id), 
        });
        break;
      }
      case "temperature_range": {
        setFormData({
          ...formData,
          temperature_range: { start: e[0], end: e[1] },
          plant_inspection_id: updateInspDate(formData?.plant_id), 
        });
        break;
      }
      default: {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
          plant_inspection_id: updateInspDate(formData?.plant_id), 
        });
      }
    }
  };  

  //Handle the temperature range slider
  const handleSlider = (event, value) => {
    if (value && value.length) {
      value[0] = Number(value[0]);
      value[1] = Number(value[1]);
    }
    setTempRange(value);
    customFormHandler("temperature_range", value);
  };

  //To handle back button
  const backButtonHandler = () => {
    setShowCustomReport(true);
    setShowGenerateReport(false);
    customFormHandler("temperature_range", [0, 100]);
  };

  //Filters the data based on the selected anomalies
  const selectedInput = (selectedList, selectedItem) => {
    const index = translatedAnomalies.findIndex((val) => {
      return val.defect_name === selectedItem.defect_name;
    });

    setSelectedAnomalies((prevVal) => {
      return [...prevVal, anomalies[index]];
    });
    const array = selectedList.map((elements) => {
      return elements.defect_type_id;
    });
    setFormData({ ...formData, anomaly_type_list: array });
  };

  //Removes an anomaly from the selected list
  const removeInput = (selectedList, removedItem) => {
    const index = translatedSelectedAnomalies.findIndex((val) => {
      return val.defect_name === removedItem.defect_name;
    });
    setSelectedAnomalies((preVal) => {
      return preVal.filter((val) => {
        return val.defect_type_id !== selectedAnomalies[index].defect_type_id;
      });
    });

    const array = selectedList.map((elements) => {
      return elements.defect_type_id;
    });
    setFormData({ ...formData, anomaly_type_list: array });
  };

  //To view report
  const viewReport = async () => {
    setShowGenerateReport(true);
    await getCustomReport();
    if (reportRef.current) {
      reportRef.current.scrollIntoView();
    }
  };

  //Clears the selections made for the custom report
  const clearSelection = (e) => {
    handleSlider(e, [0, 100]);
    setShowGenerateReport(false);
    setSelectedAnomalies([]);
    anomalyRef.current.resetSelectedValues();
    setFormData({
      anomaly_details: {
        actual_module_temperature: false,
        anomaly_number: false,
        anomaly_type: false,
        delta_temperature: false,
        hotspot_temperature: false,
        iec_category: false,
        irradiation: false,
        lat_long: false,
        module_coordinates: false,
        severity: false,
        status: false,
        stc_delta_temperature: false,
      },
      attachments: {
        el_image: false,
        handheld_image: false,
        iv_data: false,
        rgb_image: false,
        thermal_image: false,
        comment: false,
      },
      panel_details: {
        defect_module_power: false,
        defect_panel_current: false,
        defect_panel_voltage: false,
        healthy_module_power: false,
        healthy_panel_current: false,
        healthy_panel_voltage: false,
        defect_max_power_current: false,
        defect_max_power_voltage: false,
        healthy_max_power_current: false,
        healthy_max_power_voltage: false,
        manufacturer: false,
        panel_id: false,
        string_current: false,
        string_id: false,
      },
      anomaly_type_list: [],
      plant_id: formData.plant_id,
      section_id: formData.section_id,
      plant_inspection_id: formData.plant_inspection_id,
      temperature_range: {
        end: 100,
        start: 0,
      },
    });
  };

  //Renders the custom report form
  return (
    <>
      <Grid>
        <span style={{ marginLeft: "20px" }}>
          <CustomButton
            type="bgNone"
            btnIcon={BackArrowImg}
            text={<Trans>Back</Trans>}
            onClick={backButtonHandler}
          />
        </span>
      </Grid>
      <Grid className={classes.customContainer}>
        <Grid className={classes.customReportSection}>
          <Grid className={classes.editSection}>
            <Typography
              className={classes.subtitle}
              style={{ padding: 0, color: "#536471" }}
            >
              <Trans>Site Details</Trans>
            </Typography>
            <Grid xs={12} md={12}>
              <Grid container>
                <span className={classes.siteDropDetails}>
                  <Typography className={classes.titleText}>
                    <Trans>Site</Trans>
                  </Typography>

                  <CustomDropdown
                    selectValue={formData.plant_id}
                    onHandleSelectChange={(e) => {
                      return customFormHandler("plant_id", e);
                    }}
                    fullWidth
                    name="plant_id"
                    menuItems={siteSelect}
                  />
                </span>
                <span className={classes.sectionDropDetails}>
                  <Typography className={classes.titleText}>
                    <Trans>Section</Trans>
                  </Typography>

                  <CustomDropdown
                    selectValue={sectionName}
                    onHandleSelectChange={(e) => {
                      return customFormHandler("section_id", e);
                    }}
                    fullWidth
                    name="section_id"
                    menuItems={sectionSelect}
                    // defaultValue={sectionName}
                  />
                </span>

                <Grid item style={{ marginLeft: 24 }}>
                  <Typography className={classes.titleText}>
                    <Trans>Inspection</Trans>
                  </Typography>

                  <CustomDropdown
                    selectValue={selectedInspDate}
                    // selectValue={inspDateList[0].value}
                    onHandleSelectChange={(e) => {
                      return customFormHandler("plant_inspection_id", e);
                    }}
                    fullWidth
                    name="plant_inspection_id"
                    menuItems={inspDateList}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid className={classes.editSection}>
            <Typography
              className={[classes.subtitle, classes.FilterTxt].join(" ")}
            >
              <Trans>Metrics</Trans>
            </Typography>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              className={classes.metricsSection}
            >
              <Grid className={classes.metricsSubSection}>
                <Typography
                  className={[classes.titleText, classes.checkboxTitle].join(
                    " "
                  )}
                >
                  <Trans>Panel Details</Trans>
                </Typography>
                <Grid
                  container
                  direction="column"
                  style={{ whiteSpace: "nowrap" }}
                >
                  <CustomCheckbox
                    name="panel_id"
                    checked={formData.panel_details.panel_id}
                    onChange={(e) => {
                      return customFormHandler("panel_details", e);
                    }}
                    label={<Trans>Module Serial Number</Trans>}
                  />

                  <CustomCheckbox
                    name="string_id"
                    checked={formData.panel_details.string_id}
                    onChange={(e) => {
                      return customFormHandler("panel_details", e);
                    }}
                    label={<Trans>String Id</Trans>}
                  />

                  <CustomCheckbox
                    name="manufacturer"
                    checked={formData.panel_details.manufacturer}
                    onChange={(e) => {
                      return customFormHandler("panel_details", e);
                    }}
                    label={<Trans>Module Manufacturer Name</Trans>}
                  />

                  <CustomCheckbox
                    name="defect_module_power"
                    checked={formData.panel_details.defect_module_power}
                    onChange={(e) => {
                      return customFormHandler("panel_details", e);
                    }}
                    label={<Trans>Module Wp</Trans>}
                  />

                  <CustomCheckbox
                    name="defect_panel_voltage"
                    checked={formData.panel_details.defect_panel_voltage}
                    onChange={(e) => {
                      return customFormHandler("panel_details", e);
                    }}
                    label={<Trans>Module Voc</Trans>}
                  />

                  <CustomCheckbox
                    name="defect_panel_current"
                    checked={formData.panel_details.defect_panel_current}
                    onChange={(e) => {
                      return customFormHandler("panel_details", e);
                    }}
                    label={<Trans>Module Isc</Trans>}
                  />

                  <CustomCheckbox
                    name="defect_max_power_current"
                    checked={formData.panel_details.defect_max_power_current}
                    onChange={(e) => {
                      return customFormHandler("panel_details", e);
                    }}
                    label={<Trans>Module Imp</Trans>}
                  />

                  <CustomCheckbox
                    name="defect_max_power_voltage"
                    checked={formData.panel_details.defect_max_power_voltage}
                    onChange={(e) => {
                      return customFormHandler("panel_details", e);
                    }}
                    label={<Trans>Module Vmp</Trans>}
                  />

                  <CustomCheckbox
                    name="string_current"
                    checked={formData.panel_details.string_current}
                    onChange={(e) => {
                      return customFormHandler("panel_details", e);
                    }}
                    label={<Trans>String Current</Trans>}
                  />

                  <CustomCheckbox
                    name="healthy_module_power"
                    checked={formData.panel_details.healthy_module_power}
                    onChange={(e) => {
                      return customFormHandler("panel_details", e);
                    }}
                    label={<Trans>Ref Module Wp</Trans>}
                  />

                  <CustomCheckbox
                    name="healthy_panel_voltage"
                    checked={formData.panel_details.healthy_panel_voltage}
                    onChange={(e) => {
                      return customFormHandler("panel_details", e);
                    }}
                    label={<Trans>Ref Module Voc</Trans>}
                  />

                  <CustomCheckbox
                    name="healthy_panel_current"
                    checked={formData.panel_details.healthy_panel_current}
                    onChange={(e) => {
                      return customFormHandler("panel_details", e);
                    }}
                    label={<Trans>Ref Module Isc</Trans>}
                  />

                  <CustomCheckbox
                    name="healthy_max_power_current"
                    checked={formData.panel_details.healthy_max_power_current}
                    onChange={(e) => {
                      return customFormHandler("panel_details", e);
                    }}
                    label={<Trans>Ref Module Imp</Trans>}
                  />

                  <CustomCheckbox
                    name="healthy_max_power_voltage"
                    checked={formData.panel_details.healthy_max_power_voltage}
                    onChange={(e) => {
                      return customFormHandler("panel_details", e);
                    }}
                    label={<Trans>Ref Module Vmp</Trans>}
                  />
                </Grid>
              </Grid>
              <Grid className={classes.metricsSubSection}>
                <Typography
                  className={[classes.titleText, classes.checkboxTitle].join(
                    " "
                  )}
                >
                  <Trans>Anomaly Details</Trans>
                </Typography>
                <Grid className={classes.anomalyDetailsSection}>
                  <Grid
                    container
                    direction="column"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    <CustomCheckbox
                      name="anomaly_number"
                      checked={formData.anomaly_details.anomaly_number}
                      onChange={(e) => {
                        return customFormHandler("anomaly_details", e);
                      }}
                      label={<Trans>Anomaly Number</Trans>}
                    />

                    <CustomCheckbox
                      name="anomaly_type"
                      checked={formData.anomaly_details.anomaly_type}
                      onChange={(e) => {
                        return customFormHandler("anomaly_details", e);
                      }}
                      label={<Trans>Anomaly Name</Trans>}
                    />

                    <CustomCheckbox
                      name="lat_long"
                      checked={formData.anomaly_details.lat_long}
                      onChange={(e) => {
                        return customFormHandler("anomaly_details", e);
                      }}
                      label={<Trans>Lat/Long</Trans>}
                    />

                    <CustomCheckbox
                      name="module_coordinates"
                      checked={formData.anomaly_details.module_coordinates}
                      onChange={(e) => {
                        return customFormHandler("anomaly_details", e);
                      }}
                      label={<Trans>Coordinates</Trans>}
                    />

                    <CustomCheckbox
                      name="actual_module_temperature"
                      checked={
                        formData.anomaly_details.actual_module_temperature
                      }
                      onChange={(e) => {
                        return customFormHandler("anomaly_details", e);
                      }}
                      label={<Trans>T1 (Actual)</Trans>}
                    />

                    <CustomCheckbox
                      name="hotspot_temperature"
                      checked={formData.anomaly_details.hotspot_temperature}
                      onChange={(e) => {
                        return customFormHandler("anomaly_details", e);
                      }}
                      label={<Trans>T2 (Hotspot)</Trans>}
                    />

                    <CustomCheckbox
                      name="delta_temperature"
                      checked={formData.anomaly_details.delta_temperature}
                      onChange={(e) => {
                        return customFormHandler("anomaly_details", e);
                      }}
                      label={<Trans>∆T - Site Condition</Trans>}
                    />

                    <CustomCheckbox
                      name="stc_delta_temperature"
                      checked={formData.anomaly_details.stc_delta_temperature}
                      onChange={(e) => {
                        return customFormHandler("anomaly_details", e);
                      }}
                      label={"∆T - STC"}
                    />

                    <CustomCheckbox
                      name="severity"
                      checked={formData.anomaly_details.severity}
                      onChange={(e) => {
                        return customFormHandler("anomaly_details", e);
                      }}
                      label={<Trans>Severity</Trans>}
                    />

                    <CustomCheckbox
                      name="status"
                      checked={formData.anomaly_details.status}
                      onChange={(e) => {
                        return customFormHandler("anomaly_details", e);
                      }}
                      label={<Trans>Status</Trans>}
                    />
                  </Grid>
                  <Grid
                    container
                    direction="column"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    <CustomCheckbox
                      name="iec_category"
                      checked={formData.anomaly_details.iec_category}
                      onChange={(e) => {
                        return customFormHandler("anomaly_details", e);
                      }}
                      label={<Trans>IEC CoA</Trans>}
                    />

                    <CustomCheckbox
                      name="irradiation"
                      checked={formData.anomaly_details.irradiation}
                      onChange={(e) => {
                        return customFormHandler("anomaly_details", e);
                      }}
                      label={<Trans>Irradiation</Trans>}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid className={classes.metricsSubSection}>
                <Typography
                  className={[classes.titleText, classes.checkboxTitle].join(
                    " "
                  )}
                >
                  <Trans>Attachments</Trans>
                </Typography>
                <Grid
                  container
                  direction="column"
                  style={{ whiteSpace: "nowrap" }}
                >
                  <CustomCheckbox
                    name="thermal_image"
                    checked={formData.attachments.thermal_image}
                    onChange={(e) => {
                      return customFormHandler("attachments", e);
                    }}
                    label={<Trans>Thermal</Trans>}
                  />

                  <CustomCheckbox
                    name="el_image"
                    checked={formData.attachments.el_image}
                    onChange={(e) => {
                      return customFormHandler("attachments", e);
                    }}
                    label={<Trans>EL Image</Trans>}
                  />

                  <CustomCheckbox
                    name="iv_data"
                    checked={formData.attachments.iv_data}
                    onChange={(e) => {
                      return customFormHandler("attachments", e);
                    }}
                    label={<Trans>IV Curve Data</Trans>}
                  />

                  <CustomCheckbox
                    name="handheld_image"
                    checked={formData.attachments.handheld_image}
                    onChange={(e) => {
                      return customFormHandler("attachments", e);
                    }}
                    label={<Trans>Handheld</Trans>}
                  />

                  <CustomCheckbox
                    name="rgb_image"
                    checked={formData.attachments.rgb_image}
                    onChange={(e) => {
                      return customFormHandler("attachments", e);
                    }}
                    label={"RGB"}
                  />

                  <CustomCheckbox
                    name="comment"
                    checked={formData.attachments.comment}
                    onChange={(e) => {
                      return customFormHandler("attachments", e);
                    }}
                    label={"Comment"}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid className={classes.editSection}>
            <Typography
              className={[classes.subtitle, classes.FilterTxt].join(" ")}
            >
              <Trans>Filters</Trans>
            </Typography>
            <Grid xs={12}>
              <Grid
                container
                className={classes.flexRow}
                direction="row"
                justifyContent="flex-start"
              >
                <Typography
                  className={classes.titleText}
                  style={{ width: "100%" }}
                >
                  <Trans>Anomaly Name</Trans>
                </Typography>
                <span className={classes.formOuterDiv}>
                  <FormControl
                    className={[classes.inputLabel, classes.formInnerDiv].join(
                      " "
                    )}
                  >
                    <Multiselect
                      ref={anomalyRef}
                      options={translatedAnomalies}
                      style={{
                        searchBox: {
                          minHeight: "36px",
                          height: "auto",
                          padding: "3px",
                          float: "none",
                          width: "610px",
                        },
                        chips: {
                          width: "auto",
                          backgroundColor: "#5B4DD3",
                        },
                        inputField: {
                          float: "none",
                        },
                      }}
                      displayValue="defect_name"
                      onSelect={selectedInput}
                      onRemove={removeInput}
                      selectedValues={translatedSelectedAnomalies}
                    />
                  </FormControl>
                  <span style={{ marginLeft: "8px" }}>
                    <CustomButton
                      btnIcon={DeleteBtn}
                      type="deleteBtn"
                      marginRight="0"
                      onClick={() => {
                        setSelectedAnomalies([]);
                        anomalyRef.current.resetSelectedValues();
                        setFormData({ ...formData, anomaly_type_list: [] });
                      }}
                    />{" "}
                  </span>
                </span>
              </Grid>
            </Grid>
            <Grid xs={12} md={12}>
              <Grid
                container
                justifyContent="flex-start"
                className={classes.flexRow}
              >
                <Grid className={classes.tempOuter}>
                  <TemperatureRange
                    tempRange={tempRange}
                    onHandleSlider={handleSlider}
                    showInstruction
                    moduleType="Report"
                    chartFilter={false}
                  />
                </Grid>
                <Grid className={classes.dltBtnbox}>
                  <CustomButton
                    btnIcon={DeleteBtn}
                    type="deleteBtn"
                    marginRight="0"
                    onClick={(e) => {
                      handleSlider(e, [0, 100]);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid className={classes.editSection}>
            {product?.remaining_days || product?.product_type === "Pro" ? (
              <CustomButton
                variant="contained"
                onClick={viewReport}
                text={<Trans>View Report</Trans>}
              />
            ) : (
              <ProButton
                onHandleShowPlans={() => {
                  return onHandleShowPlans(true);
                }}
              />
            )}

            <CustomButton
              onClick={(e) => {
                return clearSelection(e);
              }}
              bgColor="#FFF"
              text={<Trans>Clear</Trans>}
              type="cancel"
            />
          </Grid>
        </Grid>
      </Grid>
      {showGenerateReport && customAnomalyList.length > 0 && (
        <CustomReportView
          reportRef={reportRef}
          customAnomalyList={customAnomalyList}
          downloadHref={downloadHref}
          setFormData={setFormData}
          formData={formData}
          customFormHandler={customFormHandler}
          backButtonHandler={backButtonHandler}
          getReportHistory={getReportHistory}
          callSnackbar={callSnackbar}
          startIndex={startIndex}
          setStartIndex={setStartIndex}
        />
      )}
    </>
  );
};

//Calling redux to get the selected language and product information (basic/pro)
const mapStateToProps = (state) => {
  return {
    product: state.product,
    language: state.language.selectedLanguage,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(CustomReportCreate));
