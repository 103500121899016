import { Card, CardContent, Typography } from "@material-ui/core";
import React, { Component } from "react";

import Config from "../../../config";
import CustomButtom from "../customStyle/Button";
import LossViewer from "../revenueloss/index";
import ManyFlt from "../../../assets/img/manyflt.svg";
import { Trans } from "react-i18next";
import { allRequestHandler } from "../../../api";
import { connect } from "react-redux";
import emptyPowerLoss from "../../../assets/img/emptyPowerLoss.svg";
import i18n from "i18next";
import styles from "./emptychart.styles";
import { withStyles } from "@material-ui/core/styles";

const requestUrl = Config.hostName;

//emptyCard component
class EmptyCard extends Component {
  state = {
    lossOpen: false,
  };

  //Opens the loss page
  handleOpenLossPage = () => {
    this.setState({ lossOpen: true });
  };

  //fetch metadata
  async getData() {
    let setUrl = "";
    if (this.props.userDetails && this.props.userDetails.plant_id) {
      setUrl = `/api/sites/${this.props.userDetails.plant_id}/metadata`;
    } else {
      setUrl = "/api/dashboard/metadata";
    }

    await allRequestHandler({
      requestType: "GET",
      requestUrl: requestUrl + setUrl,
    });
  }

  //Renders the empty card
  render() {
    const { classes } = this.props;
    return this.state.lossOpen ? (
      <LossViewer
        open={this.state.lossOpen}
        closeDialog={() => {
          this.setState({ lossOpen: false });
        }}
        userDetails={this.props.userDetails}
        metadata={() => {
          this.getData();
        }}
      />
    ) : (
      <div
        className={classes.cardContainer}
        style={{
          top: this.props.cardName === "internal" && "20%",
        }}
      >
        <Card className={classes.card}>
          <CardContent className={classes.contentMob}>
            {this.props.compName === "powerloss" ? (
              this.props.type === "revenue" ? (
                <>
                  <img
                    alt="lossImg"
                    style={{ marginBottom: "24px" }}
                    src={emptyPowerLoss}
                  />
                  <Typography
                    className={classes.simpleTxt}
                    style={{ marginBottom: 15 }}
                  >
                    {i18n.t(this.props.statement)}
                  </Typography>
                  {this.props.button && (
                    <CustomButtom
                      text={i18n.t("Enter Yield and Tariff")}
                      onClick={this.handleOpenLossPage}
                    />
                  )}
                </>
              ) : (
                <>
                  <img
                    alt="lossImg"
                    style={{ marginBottom: "24px" }}
                    src={emptyPowerLoss}
                  />
                  <Typography
                    className={classes.simpleTxt}
                    style={{ marginBottom: 15 }}
                  >
                    <Trans>
                      No Anomalies resolved for the selected filters
                    </Trans>
                  </Typography>
                </>
              )
            ) : (
              <>
                <img
                  style={{ marginBottom: "24px" }}
                  src={ManyFlt}
                  alt="Many Filtes"
                />
                <Typography className={classes.simpleTxt}>
                  <Trans>
                    No data available for selected filters. Try removing some.
                  </Trans>
                </Typography>
              </>
            )}
          </CardContent>
        </Card>
      </div>
    );
  }
}

//Calling redux to get information about the selected language
const mapStateToProps = (state) => {
  return {
    language: state.language.selectedLanguage,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(EmptyCard));
