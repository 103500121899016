import {
  Divider,
  FormControl,
  Grid,
  InputBase,
  Typography,
  withStyles,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import CustomButton from "../../common/customStyle/Button";
import React from "react";
import { Trans } from "react-i18next";
import styles from "../reports.style";

//custom popup to download reports
const DownloadCustomPopup = ({
  classes,
  setShowDownloadPopup,
  formData,
  customFormHandler,
  downloadCustomReport,
  callSnackbar,
  type,
}) => {
  //handle download popup to show or hide
  const handleDownloadPopup = () => {
    setShowDownloadPopup(false);
  };

  //generate report handler
  const handelGenerateReport = () => {
    if (!formData.report_name || formData.report_name.trim().length < 3) {
      return callSnackbar(
        "Report name must be atleast 3 character length",
        "warning"
      );
    }
    callSnackbar("Report generation in progress...", "info");
    downloadCustomReport(formData, type);
    handleDownloadPopup();
    return true;
  };

  return (
    <Grid container className={classes.popupContainer} direction="column">
      <Grid
        container
        justifyContent="space-between"
        className={classes.popupItem}
        direction="column"
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          className={classes.paddAlign}
        >
          <Typography className={classes.popUpTitle}>
            <Trans>REPORT NAME</Trans>
          </Typography>
          <CloseIcon
            fontSize="large"
            className={classes.closeIcon}
            onClick={handleDownloadPopup}
          />
        </Grid>
        <Divider />
        <Grid container style={{ padding: "1rem 0" }}>
          <Grid item xs={12} md={12} style={{ padding: "10px 30px" }}>
            <FormControl className={classes.inputLabel}>
              <Typography className={classes.titleText}>
                <Trans>Name</Trans>
              </Typography>
              <InputBase
                id="reportName"
                name="report_name"
                className={`${classes.customInput} ${classes.titleText}`}
                onChange={(e) => {
                  return customFormHandler("report_name", e);
                }}
                value={formData.report_name}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Divider className={classes.marTB20} />
        <Grid
          container
          justifyContent="flex-end"
          className={classes.paddAlign}
          style={{ paddingRight: "30px" }}
        >
          <CustomButton
            variant="outlined"
            type={"cancel"}
            text={<Trans>Cancel</Trans>}
            onClick={handleDownloadPopup}
          />
          <CustomButton
            text={<Trans>Generate Report</Trans>}
            onClick={handelGenerateReport}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(DownloadCustomPopup);
