export const toggleObj = [
  {
    value: "Status",
    name: "Status",
    cssPlace: "Start",
    proValue: true,
  },
  {
    value: "Severity",
    name: "Severity",
    cssPlace: "Middle",
  },
  {
    value: "IEC CoA",
    name: "IEC CoA",
    cssPlace: "End",
  },
];

export const powerRevenueDuration = [
  {
    value: 30,
    name: "30 D",
    cssPlace: "Start",
  },
  {
    value: 90,
    name: "90 D",
    cssPlace: "Middle",
  },
  {
    value: 180,
    name: "180 D",
    cssPlace: "Middle",
  },
  {
    value: 365,
    name: "365 D",
    cssPlace: "End",
  },
];

export const powerRevenueTableHeader = [
  {
    name: "Sites",
    tooltipTxt: null,
    value: "plant_name",
  },
  {
    name: "Inspections",
    tooltipTxt: null,
    value: "inspection_count",
  },
  {
    name: "Initial Power Loss",
    tooltipTxt:
      "The total power of your DC capacity which is not contributing towards energy generation on the day of inspection.",
    value: "initial_power_loss",
  },
  {
    name: "Current Power Loss",
    tooltipTxt:
      "Current power loss from your site due to all unresolved Anomalies. Resolve Anomalies to see a reduction in your Power Loss.",
    value: "power_loss",
  },
  {
    name: "Yield (kWh/kWp per year)",
    tooltipTxt:
      "Yield is the ratio between total annual energy produced and the solar installed capacity. Yield (kWh/kWp per year) = annual plant output (kWh) / installed capacity (kWp)",
    value: "yield",
  },
  {
    name: "Estimated Energy Loss",
    tooltipTxt:
      "Total energy that could have been produced in the selected period if there were no defects on modules.",
    value: "energy_loss",
  },
  {
    name: "Energy Recovered",
    tooltipTxt:
      "Energy recovered after resolving anomalies. Resolve anomalies to increase energy generation from your site.",
    value: "energy_recovered",
  },
  {
    name: "Feed in Tariff/kWh",
    tooltipTxt:
      "Enter the selling price of your energy to calculate total revenue losses due to defects at your site.",
    value: "tariff",
  },
  {
    name: "Estimated Revenue Loss",
    tooltipTxt:
      "Total revenue loss that will incur from your individual solar site for the chosen period if no Anomalies are resolved.",
    value: "revenue_loss",
  },
  {
    name: "Revenue Recovered",
    tooltipTxt:
      "Revenue that is generated from your site after Anomaly resolution. Resolve Anomalies to increase revenue generation.",
    value: "revenue_recovered",
  },
];

export const siteInspectionTableHeader = [
  {
    name: "Anomaly Name",
    tooltipTxt: null,
    value: "anomaly_name",
  },
  {
    name: "Anomalies",
    tooltipTxt:
      "The total number of identified defects contributing towards energy and revenue losses.",
    value: "anomalies_count",
  },
  {
    name: "Affected DC",
    tooltipTxt:
      "Affected DC (Power loss) is the total power that is not contributing towards energy generation due to the associated anomaly.",
    value: "affected_dc",
  },
  {
    name: "Affected DC %",
    tooltipTxt:
      "Percentage of total DC capacity that is not contributing towards energy generation due to the associated anomaly.",
    value: "affected_dc_percentage",
  },
  {
    name: "Energy Loss",
    tooltipTxt: null,
    value: "energy_loss",
  },
  {
    name: "Revenue Loss",
    tooltipTxt: null,
    value: "revenue_loss",
  },
  {
    name: "Anomalies Resolved",
    tooltipTxt: null,
    value: "anomalies_resolved_count",
  },
];

export const siteInspectionTotal = [
  "Total",
  "total_anomalies_count",
  "total_affected_dc",
  "total_affected_dc_percentage",
  "total_energy_loss",
  "total_revenue_loss",
  "total_anomalies_resolved",
];

export const inspTableCellObj = [
  {
    orderBy: "plant_name",
    Value: "Site",
  },
  {
    orderBy: "dc_capacity",
    Value: "DC Capacity",
  },
  {
    orderBy: "power_loss",
    Value: "Power Loss",
  },
  {
    orderBy: "count",
    Value: "Anomalies",
  },
  {
    orderBy: "inspection_date",
    Value: "Inspection Date",
  },
  {
    orderBy: "inspection_type",
    Value: "Inspection Type",
  },
];

export const cellValues = [
  { value: "plant_name", link: true, style: "linkTxt" },
  { value: "dc_capacity", link: false, style: "boldTxt" },
  { value: "power_loss", link: false, style: "boldTxt" },
  { value: "anomalies.count", link: false, style: "extraBoldTxt" },
  { value: "inspection_date", link: false, style: "boldTxt" },
  { value: "inspection_type", link: false, style: "boldTxt" },
];

export const toggleObj2 = [
  {
    value: "power",
    name: "Power",
    cssPlace: "Start",
  },
  {
    value: "revenue",
    name: "Revenue",
    cssPlace: "End",
  },
];

export const dataFilterItems = [
  {
    name: "All",
    value: "all",
    id: "all",
  },
  {
    name: "Last 7 days",
    value: "7days",
    id: "7days",
  },
  {
    name: "Last 30 days",
    value: "30days",
    id: "30days",
  },
  {
    name: "Last 90 days",
    value: "90days",
    id: "90days",
  },
  {
    name: "Last year",
    value: "year",
    id: "year",
  },
];
export const internalBenchToggleObj = [
  {
    value: "MWp",
    name: "Anomalies/MWp",
    cssPlace: "Start",
  },
  {
    value: "Status",
    name: "Anomaly Status",
    cssPlace: "End",
  },
];
