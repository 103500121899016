import {
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  SwipeableDrawer,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import { Trans, withTranslation } from "react-i18next";

import CloseIcon from "@material-ui/icons/Close";
import CustomButton from "../common/customStyle/Button";
import CustomTextField from "../common/customStyle/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import PropTypes from "prop-types";
import React from "react";
import RedInfo from "../../assets/img/RedInfo.svg";
import SimpleReactValidator from "simple-react-validator";
import { URLS } from "../../constants";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { allRequestHandler } from "../../api";
import { connect } from "react-redux";
import generator from "generate-password";
import { getUsers } from "../../actions/user";
import { setAlert } from "../../actions/alert.actions";
import styles from "../../style";
import userStyle from "./user.style";
import MultSiteAccess from "./multSiteAccess";

//add new user
class Sites extends React.Component {
  //Initialize states
  constructor(props) {
    super(props);
    this.state = {
      first_name: "",
      last_name: "",
      job_title: "",
      username: "",
      email_id: "",
      sites: [],
      active: true,
      role_type: "",
      visible: false,
      password: generator.generate({
        length: 10,
        uppercase: true,
        lowercase: true,
        symbols: true,
        numbers: true,
        strict: true,
      }),
      passwordValid: true,
      showPassword: true,
      siteData: null,
    };
    this.validator = new SimpleReactValidator({
      messages: {
        accepted: "Please enter a valid password",
      },
    });
  }

  //These functions are called when the component updates
  componentDidUpdate(prevProps) {
    //Update site data based on the site access
    if (this.props.user.sitesAccess !== prevProps.user.sitesAccess) {
      this.setState({
        siteData: this.props.user.sitesAccess,
      });
    }
  }

  //Handle the password change
  handlePassword = (e) => {
    const newPassword = e.target.value;
    this.setState({ password: newPassword });
    this.validatePassword(newPassword);
  };

  //Validate the new password
  validatePassword = (newPassword) => {
    const strongRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[ !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~])(?=.{8,30}$)/;
    if (strongRegex.test(newPassword)) {
      this.setState({ passwordValid: true });
    } else {
      this.setState({ passwordValid: false });
    }
  };

  //Handles show/hide password (eye icon)
  handleShowPassword = () => {
    this.setState((prevState) => {
      return {
        showPassword: !prevState.showPassword,
      };
    });
  };

  //Copy the password to clipboard
  copyPassword = () => {
    const el = this.textArea;
    el.select();
    document.execCommand("copy");
  };

  //Check the validity of all the form fields and update if valid
  async handleSubmit(e) {
    e.preventDefault();
    if (!this.state.sites.length) {
      this.props.setAlert("Please allow atleast 1 site access", "warning");
    } else if (this.validator.allValid()) {
      // get form data from react state object
      const {
        first_name,
        last_name,
        username,
        job_title,
        email_id,
        password,
        active,
        role_type,
        sites,
      } = this.state;

      const body = {
        first_name,
        last_name,
        username,
        email_id,
        password,
        job_title,
        active,
        role_type,
        site_access: sites,
      };

      //make user signup request

      try {
        const user = await allRequestHandler({
          requestType: "POST",
          requestUrl: URLS.ALL_USER,
          requestData: body,
        });

        if (user) {
          this.props.getUsers();
          this.handleClose();
          this.setState({
            password: generator.generate({
              length: 10,
              uppercase: true,
              lowercase: true,
              symbols: true,
              numbers: true,
              strict: true,
            }),
          });
        }
      } catch (err) {
        return;
      }
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  }

  //Set the drawer visibility to true
  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  //Set the drawer visibility to false
  handleClose = () => {
    this.setState({
      visible: false,
    });
  };

  //Handles the site access for the user
  getSiteAccess = (value) => {
    const site_access = [];
    for (let i = 0; i < value.length; i++) {
      const site = value[i];
      if (site.isChecked) {
        site_access.push({
          plant_id: site.plant_id,
          role: site.role,
        });
      }
    }

    //set role_type according to
    const isGeneralAdminPresent = site_access.some(
      (item) => item["role"] === "CLIENT_ADMIN"
    );

    const isSiteAdminPresent = site_access.some(
      (item) => item["role"] === "SITE_ADMIN"
    );

    this.setState({
      sites: site_access,
      role_type: isGeneralAdminPresent
        ? "CLIENT_ADMIN"
        : !isGeneralAdminPresent && isSiteAdminPresent
        ? "SITE_ADMIN"
        : "CLIENT_USER",
    });
  };

  //Renders the add user dialog
  render() {
    const { classes } = this.props;
    const { t } = this.props;

    return (
      <>
        <CustomButton
          onClick={() => {
            return this.setState({ visible: true });
          }}
          text={<Trans>New User</Trans>}
        />
        <SwipeableDrawer
          classes={{ paper: classes.width500 }}
          open={this.state.visible}
          anchor="right"
          onOpen={() => this.setState({ visible: true })}
          onClose={() => this.setState({ visible: false })}
        >
          <div>
            <Grid container alignItems="center" style={{ height: 70 }}>
              <Grid item xs>
                <Typography className={classes.title}>
                  {t("New User")}
                </Typography>
              </Grid>
              <Grid item style={{ paddingRight: 25 }}>
                <CloseIcon
                  onClick={this.handleClose}
                  onClose={this.handleClose}
                  style={styles.close}
                />
              </Grid>
            </Grid>
            <Divider />
            <div style={{ padding: "15px 30px" }}>
              <Typography className={classes.subHeader}>
                <Trans>User details</Trans>
              </Typography>
              <Grid container className={classes.userAdd2}>
                <Grid item md={5} className={classes.mr30}>
                  <Typography className={classes.headerTxt}>
                    <Trans>First Name</Trans>
                  </Typography>

                  <CustomTextField
                    value={this.state.first_name}
                    handleChange={(e) => {
                      return this.setState({ first_name: e.target.value });
                    }}
                    onFocus={() => {
                      return this.validator.showMessageFor("first_name");
                    }}
                  />
                  <div className={classes.danger}>
                    {this.validator.message(
                      "first_name",
                      this.state.first_name,
                      "required"
                    )}
                  </div>
                </Grid>
                <Grid item md={5}>
                  <Typography className={classes.headerTxt}>
                    <Trans>Last Name</Trans>
                  </Typography>

                  <CustomTextField
                    value={this.state.last_name}
                    handleChange={(e) => {
                      return this.setState({ last_name: e.target.value });
                    }}
                    onFocus={() => {
                      return this.validator.showMessageFor("last_name");
                    }}
                  />
                  <div className={classes.danger}>
                    {this.validator.message(
                      "last_name",
                      this.state.last_name,
                      "required"
                    )}
                  </div>
                </Grid>
              </Grid>
              <Grid container className={classes.mb20}>
                <Grid item md={5} className={classes.mr30}>
                  <Typography className={classes.headerTxt}>
                    <Trans>Username</Trans>
                  </Typography>
                  <CustomTextField
                    value={this.state.username}
                    handleChange={(e) => {
                      return this.setState({ username: e.target.value });
                    }}
                    onFocus={() => {
                      return this.validator.showMessageFor("username");
                    }}
                  />
                  <div className={classes.danger}>
                    {this.validator.message(
                      "username",
                      this.state.username,
                      "required"
                    )}
                  </div>
                </Grid>
                <Grid item md={5}>
                  <Typography className={classes.headerTxt}>
                    <Trans>Status</Trans>
                  </Typography>
                  <RadioGroup
                    row
                    aria-label="position"
                    name="position"
                    defaultValue="top"
                    value={this.state.active}
                    onClick={(e) => {
                      return this.setState({
                        active: e.target.value === "active" ? true : false,
                      });
                    }}
                  >
                    <Grid container>
                      <Grid item md={4} style={{ marginRight: "40px" }}>
                        <FormControlLabel
                          value="end"
                          control={
                            <Radio
                              value="active"
                              checked={this.state.active ? true : false}
                              style={{
                                color: this.state.active
                                  ? "#5B4DD3"
                                  : "#8C9AA4",
                              }}
                            />
                          }
                          label={<Trans>Active</Trans>}
                          classes={{ label: classes.headerTxt }}
                        />
                      </Grid>
                      <Grid item md={4}>
                        <FormControlLabel
                          value={false}
                          control={
                            <Radio
                              value="inactive"
                              checked={this.state.active ? false : true}
                              style={{
                                color:
                                  this.state.active === false
                                    ? "#5B4DD3"
                                    : "#8C9AA4",
                              }}
                            />
                          }
                          label={<Trans>Inactive</Trans>}
                          classes={{ label: classes.headerTxt }}
                        />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </Grid>
              </Grid>
              <Grid container className={classes.mb20}>
                <Grid item md={5} className={classes.mr30}>
                  <Typography className={classes.headerTxt}>
                    <Trans>Email</Trans>
                  </Typography>

                  <CustomTextField
                    value={this.state.email_id}
                    handleChange={(e) => {
                      return this.setState({ email_id: e.target.value });
                    }}
                    onFocus={() => {
                      return this.validator.showMessageFor("email_id");
                    }}
                  />
                  <div className={classes.danger}>
                    {this.validator.message(
                      "email_id",
                      this.state.email_id,
                      "required|email"
                    )}
                  </div>
                </Grid>
                <Grid item md={5}>
                  <Typography className={classes.headerTxt}>
                    <Trans>Job Title</Trans>
                  </Typography>

                  <CustomTextField
                    value={this.state.job_title}
                    handleChange={(e) => {
                      return this.setState({ job_title: e.target.value });
                    }}
                    onFocus={() => {
                      return this.validator.showMessageFor("job_title");
                    }}
                  />
                  <div className={classes.danger}>
                    {this.validator.message(
                      "job_title",
                      this.state.job_title,
                      "required"
                    )}
                  </div>
                </Grid>
              </Grid>
              <Grid item md={5} className={classes.mb20}>
                <Typography className={classes.headerTxt}>
                  <Trans>Password</Trans> &nbsp;
                  {!this.state.passwordValid && (
                    <Tooltip
                      classes={{ tooltip: classes.passwordInfo }}
                      placement="right-end"
                      title="Must contain at least one number, one special character, one uppercase and lowercase letter, at least 8 and maximum of 30 characters"
                    >
                      <img alt="infoImg" src={RedInfo} />
                    </Tooltip>
                  )}
                </Typography>

                <CustomTextField
                  inputRef={(textarea) => {
                    return (this.textArea = textarea);
                  }}
                  value={this.state.password}
                  handleChange={(e) => {
                    return this.handlePassword(e);
                  }}
                  onFocus={() => {
                    return this.validator.showMessageFor("password");
                  }}
                  type={this.state.showPassword ? "text" : "password"}
                  unit={
                    <InputAdornment position="end">
                      <IconButton
                        style={{ padding: 3 }}
                        aria-label="toggle password visibility"
                        onClick={this.handleShowPassword}
                      >
                        {this.state.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />

                <div className={classes.danger}>
                  {this.validator.message(
                    "password",
                    this.state.password,
                    "required"
                  )}
                </div>
                {this.state.password && (
                  <div className={classes.danger}>
                    {this.validator.message(
                      "password",
                      this.state.passwordValid,
                      "accepted"
                    )}
                  </div>
                )}
              </Grid>
              <Divider className={classes.dividerUpdate} />
              <Typography className={classes.subHeader}>
                <Trans>Site Access</Trans>
              </Typography>
              <Grid container>
                <Grid item md={12}>
                  {/* <SiteAccess
                    classes={classes}
                    site_access={[]}
                    sitesaccess={this.state.siteData}
                    getSiteAccess={this.getSiteAccess}
                  /> */}
                  <MultSiteAccess
                    isNewUserCreation={true}
                    classes={classes}
                    site_access={[]}
                    sitesaccess={this.state.siteData}
                    getSiteAccess={(sites) => this.getSiteAccess(sites)}
                    usersList={this.props.usersList}
                  />
                </Grid>
              </Grid>
              <div style={{ marginBottom: 4 }}>
                {!this.state.sites.length ? (
                  <span className={classes.danger} style={{ marginBottom: 4 }}>
                    <Trans>
                      Atleast access one site should be given to user
                    </Trans>
                  </span>
                ) : null}
              </div>
              <Divider className={classes.dividerUpdate} />
              <CustomButton
                disabled={this.state.sites.length ? false : true}
                onClick={(e) => {
                  return this.handleSubmit(e);
                }}
                btn="save"
                text={<Trans>Save</Trans>}
              />{" "}
              <CustomButton
                onClick={(e) => {
                  return this.handleClose(e);
                }}
                type="cancel"
                text={<Trans>Cancel</Trans>}
              />
            </div>{" "}
          </div>
        </SwipeableDrawer>
      </>
    );
  }
}

//Default prop types
// skipcq static properties should be positioned JS-0472
Sites.propTypes = {
  setAlert: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
};

//calling redux to get the users data and selected language
const mapStateToProps = (state) => {
  return {
    user: state.user,
    language: state.language.selectedLanguage,
  };
};
export default connect(mapStateToProps, {
  getUsers,
  setAlert,
})(withStyles(userStyle)(withTranslation()(Sites)));
