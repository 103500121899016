import {
  FormControl,
  FormLabel,
  InputAdornment,
  OutlinedInput,
  TextField,
} from "@material-ui/core";

import React from "react";
import { Trans } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";

//styling for textField
const styles = () => {
  return {
    formLabel: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 12,
      color: "#536471",
      display: "block",
      marginBottom: 4,
    },
    outlineInput: {
      height: 36,
      width: "100%",
      zIndex: 0,
    },
    txtField: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "500",
      color: "#08001D",
      padding: 10,
    },
    underline: {
      color: "black",
      "&::after": {
        transition: "none",
        border: "none",
      },
    },
    inputAdornment: {
      backgroundColor: "#F0F4FA",
      color: "#536471",
      "& .MuiTypography-colorTextSecondary-287": {
        color: "#536471",
      },
    },
  };
};

//common componenet for textField
class CustomTextField extends React.Component {
  //rendering textField
  render() {
    const { classes, hideInputProps, isOutlineInput } = this.props;

    const formInputProps = {
      variant: "outlined",
      error: this.props.error,
      helpertext: this.props.helperText,
      endAdornment: (
        <InputAdornment
          position="end"
          onClick={(e) => {
            return this.props.onClickIcon(e);
          }}
        >
          {this.props.unit}
        </InputAdornment>
      ),
      disabled: this.props.disabled ? true : false,
      onFocus: (e) => {
        return this.props.onFocus ? this.props.onFocus(e) : null;
      },
      type: this.props.type,
      value: this.props.value ? this.props.value : "",
      // skipcq: JS-0123
      InputProps: hideInputProps
        ? null
        : {
            classes: {
              input: classes.txtField,
              adornedEnd: classes.inputAdornment,
            },
          },
      id: this.props.gtmId,
      onChange: (e) => {
        return this.props.handleChange(e);
      },
      className: classes.outlineInput,
      style: {
        minHeight: this.props.BoxHeight ? this.props.BoxHeight : false,
        width: this.props.width,
      },
    };

    return (
      <>
        {this.props.label && (
          <FormLabel className={classes.formLabel}>
            <Trans>{this.props.label}</Trans>
          </FormLabel>
        )}
        <FormControl fullWidth={this.props.fullWidth} variant="outlined">
          {isOutlineInput ? (
            // skipcq Avoid using spreading operator for JSX props JS-0428
            <OutlinedInput {...formInputProps} />
          ) : (
            // skipcq Avoid using spreading operator for JSX props JS-0428
            <TextField
              //Disabled scroll on input field
              onWheel={(e) => {
                e.target.blur();
                e.stopPropagation();
              }}
              {...formInputProps}
            />
          )}
        </FormControl>
      </>
    );
  }
}

//Default props for textField
// skipcq static properties should be positioned JS-0472
CustomTextField.defaultProps = {
  gtmId: "gtmId",
  type: "string",
  BoxHeight: 36,
  fullWidth: true,
};

export default withStyles(styles)(CustomTextField);
