import {
  Button,
  Divider,
  FormControl,
  Grid,
  InputBase,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, { useState } from "react";

import CloseIcon from "@material-ui/icons/Close";
import { Trans } from "react-i18next";
import { URLS } from "../../../constants";
import { allRequestHandler } from "../../../api";
import styles from "../reports.style";

//rename popover
const RenamePopup = ({
  classes,
  handleRenamePopup,
  report,
  getReportHistory,
  callSnackbar,
}) => {
  const [reportName, setReportName] = useState(report.report_name);

  //rename report
  const renameReportHandler = async () => {
    if (!reportName || reportName.trim().length < 3) {
      return callSnackbar(
        "Report name must be atleast 3 character length",
        "warning"
      );
    }
    const requestBody = { report_name: reportName.trim() };
    try {
      await allRequestHandler({
        requestType: "PATCH",
        requestUrl: `${URLS.REPORT_API}/${report.report_id}`,
        requestData: requestBody,
      });
      handleRenamePopup();
      getReportHistory();
      callSnackbar("Report renamed successfully", "success");
    } catch (error) {
      handleRenamePopup();
      callSnackbar("There was an error", "error");
    }

    return false;
  };

  return (
    <Grid container className={classes.popupContainer} direction="column">
      <Grid
        container
        justifyContent="space-between"
        className={[classes.popupItem, classes.renamePopup].join(" ")}
        direction="column"
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          className={classes.paddAlign}
        >
          <Typography className={classes.popUpTitle}>
            <Trans>Rename</Trans>
          </Typography>
          <CloseIcon
            fontSize="large"
            className={classes.closeIcon}
            onClick={handleRenamePopup}
          />
        </Grid>
        <Divider />
        <Grid container style={{ padding: "1rem 0" }}>
          <Grid item xs={12} md={12} style={{ padding: "10px 30px" }}>
            <FormControl className={classes.inputLabel}>
              <Typography className={classes.titleText}>
                <Trans>name</Trans>
              </Typography>
              <InputBase
                id="reportName"
                name="reportName"
                className={classes.renameEditText}
                onChange={(e) => {
                  return setReportName(e.target.value);
                }}
                value={reportName}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Divider className={classes.marTB20} />
        <Grid
          container
          className={classes.paddAlign}
          justifyContent="flex-end"
          style={{ paddingRight: "30px" }}
        >
          <Button
            variant="outlined"
            style={{ borderRadius: 8 }}
            onClick={handleRenamePopup}
          >
            <Trans>Cancel</Trans>
          </Button>
          <Button
            variant="contained"
            className={classes.reviewButton}
            onClick={renameReportHandler}
          >
            <Trans>Save</Trans>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(RenamePopup);
