import leftArrow from "../../components/assets/img/leftArrow.svg";
import rightArrow from "../../components/assets/img/right.svg";

const styles = {
  height100: {
    height: "100%",
  },
  title: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 20,
    lineHeight: "34px",
    color: "#08001D",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    paddingRight: 10,
  },
  portfolio: {
    fontFamily: "Montserrat",
    background: "#F0F4FA",
    color: "#21282d",
    fontWeight: 500,
    fontStretch: "normal",
    paddingTop: "3px",
    fontStyle: "normal",
    letterSpacing: "normal",
    borderRadius: "8px",
    height: "24px",
    width: "74px",
    textAlign: "center",
    marginLeft: 10,
  },
  outerHeader: {
    padding: "6px 24px 0",
    height: 72,
    "@media only screen and (max-width: 760px)": {
      height: "auto",
    },
  },

  mobileHeader: {
    backgroundColor: "white",
    boxShadow: "0px 4px 12px rgba(78, 89, 109, 0.05)",
    height: "85px",
    "@media only screen and (max-width: 768px)": {
      height: "auto",
      paddingTop: 8,
      paddingBottom: 8,
    },
  },

  mobileToggle: {
    marginTop: "15px",
    "@media only screen and (max-width: 600px)": {
      marginTop: "-5px",
      marginLeft: "16px",
    },
  },

  mobileFilter: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    width: "auto",
    float: "right",
    paddingRight: 60,
  },

  mobileDropDown: {
    "@media only screen and (max-width: 430px)": {
      marginTop: "10px",
    },
    "@media only screen and (max-width: 330px)": {
      marginTop: "17px",
    },
  },
  viewAnomaly: {
    "@media only screen and (max-width: 330px)": {
      marginLeft: "150px",
    },
  },
  breadName: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    lineHeight: "20px",
    display: "flex",
    alignItems: "center",
    color: "#8C9AA4",
  },
  firstHeader: {
    "@media only screen and (max-width: 760px)": {
      marginLeft: 25,
    },
  },
  leftBox: {
    width: 170,
    maxWidth: 170,
    background: "#FFFFFF",
    boxShadow: "rgba(78, 89, 109, 0.05) 0px 4px 12px",
  },
  inspTxt: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 14,
    lineHeight: "20px",
    color: "#536471",
    textAlign: "center",
    marginTop: 16,
  },
  tilt: {
    "@media only screen and (max-width: 760px)": {
      display: "flex",
    },
  },
  paddBox: {
    padding: "24px 24px 0",
  },
  sevTxt: {
    fontFamily: "Montserrat",
    fontWeight: "600",
    fontStyle: "normal",
    fontSize: "14px",
    textTransform: "uppercase",
    color: "#536471",
    display: "flex",
    alignItems: "center",
  },
  chartHeading: {
    fontFamily: "Montserrat",
    fontWeight: "600",
    fontStyle: "normal",
    fontSize: "14px",
    textTransform: "uppercase",
    color: "#536471",
    height: 64,
    display: "flex",
    alignItems: "center",
    paddingLeft: 24,
  },
  pad12: {
    padding: 12,
  },
  chartTxt: {
    display: "grid",
    position: "absolute",
    top: "44%",
    left: 0,
    right: 0,
    lineHeight: 1.2,
  },
  chartHeaderTxt: {
    fontSize: "28px",
    color: "black",
    fontFamily: "Montserrat",
    fontWeight: 600,
    textAlign: "center",
  },
  chartSubHeaderTxt: {
    fontSize: "12px",
    fontFamily: "Montserrat",
    fontWeight: 500,
    color: "#536471",
    fontStyle: "normal",
    textAlign: "center",
  },
  anomaliesTable: {
    maxHeight: 370,
    overflowX: "hidden",
    overflow: "auto",
    padding: 12,
    "@media only screen and (max-width: 659px)": {
      display: "none",
    },
  },
  noDividerTable: {
    border: 0,
    padding: "9px 0px",
  },
  severityBox: {
    display: "inline-block",
    borderRadius: "50%",
    width: 6,
    height: 6,
    marginRight: 12,
  },
  sevCount: {
    fontSize: 16,
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.25,
    letterSpacing: "normal",
    color: "#21282d",
  },
  severityText: {
    margin: "0 5px",
    fontFamily: "Montserrat",
    fontSize: 12,
    fontWeight: 500,
    color: "#536471",
    fontStyle: "normal",
  },
  summaryBox: {
    height: 400,
    maxHeight: 330,
    overflowY: "auto",
    padding: 12,
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    lineHeight: "20px",
    color: "#536471",
  },
  proLabel: {
    position: "absolute",
    top: 10,
    transform: "rotate(-35deg)",
  },
  card: {
    borderRadius: 8,
    boxShadow: "0px 2px 10px rgba(78, 89, 109, 0.05)",
    height: "100%",
  },
  cardOuterDiv: {
    minHeight: "500px",
    overflowX: "hidden",
  },
  stackChartContainer: {
    minWidth: 356,
    padding: 12,
    overflow: "auto",
  },
  spin: {
    width: "100%",
    height: 450,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  cardInnerDiv: {
    dispaly: "flex",
    position: "relative",
    justifyContent: "center",
  },
  chips: {
    width: "21px",
    height: "21px",
    borderRadius: "8px",
    background: "#5B4DD3",
    marginLeft: "8px",
    color: "white",
  },
  contentMob: {
    paddingBottom: 12,
    padding: 0,
    "@media only screen and (max-width: 600px)": {
      padding: "12px 8px",
    },
  },
  inspBox: {
    background: "#FFFFFF",
    border: "1px solid #DDE5EC",
    boxSizing: "border-box",
    boxShadow: "0px 4px 12px rgba(78, 89, 109, 0.05)",
    borderRadius: 8,
    width: 120,
    height: 100,
    margin: "10px auto",
    cursor: "pointer",
    position: "relative",
  },
  inspDate: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 32,
    lineHeight: "32px",
    color: "#08001D",
  },
  inspYear: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    lineHeight: "20px",
    color: "#08001D",
  },
  reqInspTxt: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    lineHeight: "20px",
    color: "#8C9AA4",
    textAlign: "center",
  },
  reqInspBox: {
    background: "#FFFFFF",
    border: "1px dashed #DDE5EC",
    boxSizing: "border-box",
    boxShadow: "0px 4px 12px rgba(78, 89, 109, 0.05)",
    borderRadius: 8,
    width: 120,
    height: 100,
    margin: "10px auto",
    cursor: "pointer",
  },
  siteLocation: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    lineHeight: "20px",
    display: "flex",
    alignItems: "center",
    color: "#536471",
    // marginLeft: 15,
  },
  arrowIcon: {
    width: 6,
    position: "absolute",
    right: 12,
  },
  editSiteSummary: {
    margin: "0",
    padding: "0px 20px 0px 0px",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-between",
    maxWidth: 1160,
    alignItems: "center",
    "@media only screen and (max-width: 600px)": {
      flexWrap: "wrap",
    },
  },
  EditBtn: {
    background: "linear-gradient(266.69deg, #59AFFF 0.23%, #0882F3 99.28%)",
    boxShadow: "unset",
    borderRadius: 8,
    color: "#fff",
    margin: 0,
    top: "0px",
    float: "right",
  },
  anomBtn: {
    background: "linear-gradient(266.69deg, #59AFFF 0.23%, #0882F3 99.28%)",
    boxShadow: "0px 2px 2px rgba(0, 132, 255, 0.2)",
    borderRadius: 8,
    color: "#fff",
    margin: 0,
  },
  imgLayout: {
    background: "#FFFFFF",
    border: "1px solid #DDE5EC",
    boxSizing: "border-box",
    borderRadius: 8,
    margin: 12,
    overflow: "hidden",
    position: "relative",
    minHeight: "520px",
  },
  detailsBox: {
    margin: 15,
    background: "#FFFFFF",
    border: "1px solid #DDE5EC",
    borderRadius: 8,
    whiteSpace: "nowrap",
    "& > div": {
      minHeight: 40,
      borderBottom: "1px solid #DDE5EC",
      padding: "10px 20px",
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 12,
      lineHeight: "20px",
      color: "#536471",
      alignItems: "center",
      display: "flex",
      whiteSpace: "normal",
      "@media only screen and (max-width: 720px)": {
        padding: "10px 5px",
      },
    },
    "@media only screen and (max-width: 720px)": {
      whiteSpace: "normal",
      margin: 4,
    },
  },
  txtField: {
    fontWeight: 500,
    height: "35px",
    padding: "0 14px",
    color: "#536471",
    "@media only screen and (max-width: 720px)": {
      borderBottom: "0px solid black",
    },
  },
  imgIcon: {
    width: 24,
    height: 24,
  },
  brandChip: {
    background: "#F0F4FA",
    borderRadius: 5,
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    lineHeight: "20px",
    display: "flex",
    alignItems: "center",
    color: "#536471",
  },
  removeClose: {
    display: "none",
  },
  tabLabel: {
    textTransform: "capitalize",
    outline: "0 !important",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    lineHeight: "20px",
    color: "#08001D",
  },
  selectSpace: {
    paddingLeft: 0,
    color: "#08001D",
    "@media only screen and (max-width: 720px)": {
      borderBottom: "1px solid black",
    },
  },
  paddR0: {
    paddingRight: "0 !important",
  },
  txtFieldSelect: {
    height: "35px",
    padding: "0 14px",
    "@media only screen and (max-width: 720px)": {
      borderBottom: "1px solid black",
      height: 19,
      marginLeft: 4,
    },
  },
  autoChip: {
    borderRadius: 5,
    fontWeight: 500,
    color: "#536471",
    background: "#F2F9FF",
  },
  tabInd: {
    backgroundColor: "#0084FF",
  },
  filterChip: {
    background: "rgba(0, 132, 255, 0.05)",
    borderRadius: "8px",
    border: 0,
    color: "#536471",
    padding: "15px 4px",
    margin: "3px 10px 3px 0px",
    fontWeight: 500,
  },
  btnImg: {
    marginRight: "2px",
    marginTop: "-3px",
  },
  uploadOuter: {
    background: "#fff",
    position: "absolute",
    right: 8,
    height: 40,
    width: 40,
    top: 8,
    borderRadius: 6,
    border: "1.34146px solid #DDE5EC",
    boxSizing: "border-box",
  },
  uploadLabel: {
    width: 38,
    height: 38,
    marginBottom: 0,
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
  },
  datePicker: {
    "& div": {
      fontFamily: "Montserrat",
    },
    "& button:focus": {
      outline: "none",
    },
    "@media only screen and (max-width: 720px)": {
      borderBottom: "0px solid black",
    },
  },
  disInput: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    color: "#536471",
    fontFamily: "Montserrat",
    "&:before": {
      borderBottomStyle: "none !important",
    },
  },
  MenuItem: {
    backgroundColor: "rgba(0,0,0,0)",
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: 12,
    color: "#536471",
  },
  lists: {
    "&:hover": {
      backgroundColor: "#F0F4FA",
    },
  },
  btnBox: {
    padding: "24px 24px 0",
    "@media only screen and (max-width: 760px)": {
      padding: "10px 0 0 10px",
    },
  },
  inspMar: {
    marginRight: 6,
  },
  blockMenu: {
    background: "#FFFFFF",
    border: "1px solid #DDE5EC",
    boxShadow: "0px 4px 12px rgba(78, 89, 109, 0.05)",
    borderRadius: "8px",
  },
  selectBlock: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "12px",
    color: "#536471",
    height: "36px",
  },
  blockList: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "12px",
    color: "#536471",
    "&:hover": {
      backgroundColor: "#F0F4FA",
    },
  },
  selectedList: {
    color: "#0084FF !important",
    backgroundColor: "#FFFFFF !important",
  },
  outerPadd: {
    padding: "12px 12px 0",
    "@media only screen and (max-width: 760px)": {
      padding: 0,
    },
  },
  anoOuter: {
    padding: "0 12px",
    "@media only screen and (max-width: 760px)": {
      padding: 0,
    },
  },
  siteOuterPadd: {
    padding: 12,
    marginBottom: 68,
    "@media only screen and (max-width: 760px)": {
      padding: 0,
    },
  },
  defectTypes: {
    margin: "6px",
    fontFamily: "Montserrat",
    lineHeight: "16px",
  },
  severityBoxes: {
    height: 6,
    display: "inline-block",
    width: 6,
    borderRadius: "50%",
  },
  secCard: {
    padding: "0 12px",
    marginBottom: 80,
    overflow: "auto",
    "@media only screen and (max-width: 660px)": {
      padding: 0,
    },
  },
  infoIconStyle: {
    color: "#C7CEDB",
    cursor: "pointer",
    marginLeft: "3px",
    "@media only screen and (min-width: 660px)": {
      display: "none",
    },
  },
  siteDetailsTabs: {
    paddingTop: "14px",
    marginLeft: "-320px",
    "@media only screen and (max-width : 659px)": {
      justifyContent: "center",
    },
    "@media only screen and (max-width : 966px)": {
      marginLeft: 0,
      paddingTop: 0,
      marginBottom: 8,
    },
    "@media only screen and (max-width:1366px)": {
      marginLeft: "-100px",
    },
  },
  anomaliesTooltip: {
    padding: "10px 10px 10px 16px",
    position: "absolute",
    top: 40,
    right: 28,
    zIndex: 1,
    maxHeight: 400,
    backgroundColor: "#fff",
    width: "auto",
    borderRadius: "5px",
    border: "1px solid #E4EAF5",
    boxShadow: "0px 4px 12px rgba(78, 89, 109, 0.05)",
    overflow: "auto",
    flexWrap: "nowrap",
    "@media only screen and (max-width: 660px)": {
      maxHeight: 300,
    },
  },
  defectTypesBox: {
    display: "flex",
    flexWrap: "wrap",
    padding: "0px 50px 18px 50px",
    justifyContent: "center",
    "@media only screen and (max-width: 659px)": {
      display: "none",
    },
  },
  scrollBar: {
    overflow: "auto",
  },
  justifyCon: {
    "@media only screen and (max-width: 659px)": {
      justifyContent: "flex-start",
    },
  },
  closeButton: {
    "&:focus": {
      outline: "none !important",
    },
    "&:before": {
      position: "fixed",
    },
    zIndex: 1,
    backgroundColor: "rgb(0,0,0,0)",
    color: "#F0F4FA !important",
    right: "31px !important",
    opacity: 0.5,
    top: "17px !important",
    padding: "6px !important",
    width: "48px !important",
    minWidth: "48px !important",
    position: "fixed",
    "& span": {
      marginRight: "0px !important",
      marginLeft: "0px !important",
      width: "20px !important",
      height: "20px !important",
    },
    "&:hover": {
      backgroundColor: "rgb(0,0,0,0)",
    },
    "@media only screen and (max-width: 768px)": {},
  },
  carouselRoot: {
    backgroundColor: "rgb(8,0,29,0.9)",
    "& :nth-child(3)": {
      maxWidth: "unset !important",
      width: "100%",
      maxHeight: "100%",
      height: "100%",
      "@media only screen and (max-width: 768px)": {
        width: "100%",
      },
      "& :nth-child(2) + div": {
        position: "fixed",
        top: "0px",
        width: "100%",
        height: "72px",
        marginTop: "16px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 0,
        "& :first-child span": {
          display: "none",
        },
        "& :last-child span": {
          display: "none",
        },
        "& :first-child": {
          backgroundImage: `url(${leftArrow})`,
          backgroundRepeat: "no-repeat",
          left: "0px",
          position: "initial",
          top: "0px",
          backgroundColor: "rgb(0,0,0,0)",
          color: "#F0F4FA",
          height: "40px !important",
          backgroundPosition: "center",
          "&:focus": {
            outline: "none !important",
          },
        },
        "& :last-child": {
          backgroundImage: `url(${rightArrow})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: "rgb(0,0,0,0)",
          right: "0px",
          position: "initial",
          top: "0px",
          color: "#F0F4FA",
          height: "40px !important",
          backgroundPosition: "center",
          "&:focus": {
            outline: "none !important",
          },
        },
      },
    },
    "& div": {
      "&:first-child": {
        boxShadow: "none !important",
      },
    },
  },
  imageTitle: {
    position: "fixed",
    top: "37px",
    marginLeft: "20px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "24px",
    color: "#FFFFFF",
    width: "300px",
    maxWidth: "500px",
  },
  slide: {
    backgroundColor: "rgb(0,0,0,0)",
    display: "flex",
    alignItems: "center",
    width: "auto",
    justifyContent: "center",
  },
  layoutImg: {
    maxWidth: "100%",
    maxHeight: "80%",
    lineHeight: "normal",
    borderRadius: "8px",
    marginTop: "80px",
  },
  carouselContainer: {
    position: "relative",
    width: "100%",
    height: "auto",
  },
  benchmarkBar: {
    display: "flex",
    "@media only screen and (max-width: 600px)": {
      flexWrap: "wrap",
      display: "flex",
      flexDirection: "column",
    },
  },
  InspectionsTab: {
    border: "1px solid #0084FF",
    marginLeft: "24px",
    width: "322px",
    height: "32px",
    minHeight: "auto",
    borderRadius: "8px",
    display: "flex",
    textAlign: "center",
    color: "#536471",
    justifyContent: "space-between",
    "@media only screen and (max-width: 659px)": {
      width: "265px",
      marginLeft: "-2px",
    },
  },
  benchmarkToggle: {
    border: "1px solid #0084FF",
    marginLeft: "24px",
    width: "264px",
    height: "32px",
    borderRadius: "8px",
    display: "flex",
    textAlign: "center",
    color: "#536471",
    justifyContent: "space-between",
    "@media only screen and (max-width: 600px)": {
      marginLeft: "16px",
    },
  },
  benchmarkBtn: {
    width: "132px !important",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 4px 12px rgba(78, 89, 109, 0.05)",
    borderRadius: "8px",
    outline: "none",
    minHeight: "auto",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: "#536471",
    border: "none",
    "&:hover": {
      backgroundColor: "#FFFFFF",
    },
    "&:focus": {
      backgroundColor: "#FFFFFF",
      outline: "none",
    },
  },
  benchmarkLabel: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "20px",
    textTransform: "none",
  },
  benchmarkMwpSelected: {
    borderTopRightRadius: "0px !important",
    borderBottomRightRadius: "0px !important",
    width: "132px !important",
    minHeight: "auto",
    backgroundColor: "#0084FF !important",
    borderRadius: "8px !important",
    color: "#FFFFFF !important",
    alignSelf: "center",
    border: "none",
    outline: "none !important",
  },
  benchmarkStatusSelected: {
    borderTopLeftRadius: "0px !important",
    borderBottomLeftRadius: "0px !important",
    width: "132px !important",
    backgroundColor: "#0084FF !important",
    borderRadius: "8px !important",
    color: "#FFFFFF !important",
    alignSelf: "center",
    border: "none",
    outline: "none !important",
  },
  compBtn: {
    background: "#FFFFFF",
    border: "1px solid #DDE5EC",
    boxSizing: "border-box",
    borderRadius: 8,
    outline: "none",
    boxShadow: "none",
    marginLeft: 25,
    "&:focus": {
      outline: "none",
    },
    "&:hover": {
      backgroundColor: "#ffffff",
    },
    "@media only screen and (max-width: 760px)": {
      marginLeft: 6,
      height: 34,
    },
  },
  cardOuter: {
    margin: "24px auto",
    width: 550,
    boxShadow: "0px 2px 10px rgba(78, 89, 109, 0.05)",
    background: "#FFFFFF",
    borderRadius: 8,
  },
  reqTime: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 11,
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    textTransform: "uppercase",
    color: "#536471",
    marginRight: 32,
  },
  reqHeader: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 14,
    lineHeight: "24px",
    display: "flex",
    alignItems: "center",
    textTransform: "uppercase",
    color: "#536471",
    padding: "16px 30px",
  },
  cardInner: {
    background: "#FFFFFF",
    borderRadius: 8,
    border: "1px solid #DDE5EC",
    boxSizing: "border-box",
    padding: "8px 16px",
    boxShadow: "unset",
  },
  cardHeader: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    lineHeight: "20px",
    color: "#08001D",
  },
  cardSubHeader: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    lineHeight: "20px",
    color: "#536471",
  },
  cardLink: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    lineHeight: "20px",
    color: "#0084FF",
  },
  alignTimeline: {
    "&::before": {
      display: "none",
    },
  },
  selectedTab: {
    textTransform: "capitalize",
    outline: "none",
    color: "#08001D",
  },
  tableItem: {
    lineHeight: "20px",
    color: "#536471",
    fontSize: "12px",
    overflow: "auto",
    fontStyle: "normal",
    fontFamily: "Montserrat",
    fontWeight: "500",
  },
  table: {
    padding: "0 24px",
    height: "max-content",
  },
  filter: {
    position: "absolute",
    right: "30px",
    top: "50px",
    zIndex: 1,
    "@media only screen and (max-width: 600px)": {
      top: "145px",
      right: 5,
    },
  },
  proCompare: {
    top: 0,
    position: "absolute",
    transform: "rotate(-40deg)",
    left: "-10px",
  },
  outerChip: {
    padding: "5px 24px",
    overflow: "auto",
    display: "block",
    whiteSpace: "nowrap",
  },
  sitesTab: {
    marginRight: "50px",
  },
  indicatorColor: {
    background: "#7363FB",
  },
  outerBoxPadding: {
    maxWidth: 1200,
    height: "100%",
    overflowX: "hidden",
    overflowY: "auto",
    margin: "auto",
    padding: "12px 12px 60px 12px",
    "@media only screen and (max-width: 1400px)": {
      width: "-webkit-fill-available",
      height: "100%",
      position: "fixed",
    },
    "@media only screen and (max-width: 760px)": {
      padding: "12px 12px 60px 12px",
    },
  },
};

export default styles;
