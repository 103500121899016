import activityIcon from "../components/sidebar/img/activityIcon.svg";
import activityIconActive from "../components/sidebar/img/activityIconActive.svg";
import anomaliesIcon from "../components/sidebar/img/anomaliesIcon.svg";
import anomaliesIconActive from "../components/sidebar/img/anomaliesIconActive.svg";
import configurationIcon from "../components/sidebar/img/configurationIcon.svg";
import configurationsIconActive from "../components/sidebar/img/configurationsIconActive.svg";
import dashboardIcon from "../components/sidebar/img/dashboardIcon.svg";
import dashboardIconActive from "../components/sidebar/img/dashboardIconActive.svg";
import languageIcon from "../components/sidebar/img/languageIcon.svg";
import logoutImg from "../components/sidebar/img/logoutImg.svg";
import reportsIcon from "../components/sidebar/img/reportsIcon.svg";
import reportsIconActive from "../components/sidebar/img/reportsIconActive.svg";
import siteIcon from "../components/sidebar/img/siteIcon.svg";
import siteIconActive from "../components/sidebar/img/siteIconActive.svg";
import usersIcon from "../components/sidebar/img/usersIcon.svg";
import usersIconActive from "../components/sidebar/img/usersIconActive.svg";

export const sidebarItems = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: {
      active: dashboardIconActive,
      inActive: dashboardIcon,
    },
  },

  {
    title: "Site",
    path: "/sites",
    inspectionPath: "/inspectionComp",
    icon: {
      active: siteIconActive,
      inActive: siteIcon,
    },
  },

  {
    title: "Anomalies",
    path: "/defects",
    outerDiv: "defSidebar",
    icon: {
      active: anomaliesIconActive,
      inActive: anomaliesIcon,
    },
  },

  {
    title: "Reports",
    path: "/reports",
    icon: {
      active: reportsIconActive,
      inActive: reportsIcon,
    },
  },

  {
    title: "Activity",
    path: "/activity",
    icon: {
      active: activityIconActive,
      inActive: activityIcon,
    },
  },

  {
    title: "Users",
    path: "/users",
    icon: {
      active: usersIconActive,
      inActive: usersIcon,
    },
  },

  {
    title: "Configurations",
    path: "/settings",
    icon: {
      active: configurationsIconActive,
      inActive: configurationIcon,
    },
  },

  {
    title: "Language",
    path: "/language",
    icon: {
      active: languageIcon,
    },
  },

  {
    title: "MyProfile",
    path: "/MyProfile",
  },

  {
    title: "Log Out",
    icon: {
      active: logoutImg,
    },
  },
];
