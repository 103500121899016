import React, { useEffect, useState } from "react";
import { allRequestHandler } from "../../api";
import config from "../../config";
import {
  AppBar,
  Grid,
  Toolbar,
  Typography,
  withStyles,
} from "@material-ui/core";
import sortLogoUsersSitePage from "../../assets/img/sortLogoUsersSitePage.svg";
import styles from "../users/user.style";
import BackArrow from "../../assets/img/backArrowForUsersListPage.svg";
import UserListCard from "./userListCard";
import { nameSort, emailSort, statusSort } from "../users/sorts";

const requestUrl = config.hostName;

const UsersListForSitesAccess = ({
  usersListData,
  classes,
  isBackButtonClicked,
}) => {
  const [usersList, setUsersList] = useState([]);
  const [isAscending, setIsAscending] = useState(true);

  useEffect(() => {
    //TODO: handle page refresh
    fetchUsersListData();
  }, []);

  //fetch users list
  const fetchUsersListData = async () => {
    const usersList = await allRequestHandler({
      requestType: "GET",
      requestUrl: `${requestUrl}/${usersListData?.site_users_href}`,
    });

    if (usersList) {
      setUsersList(usersList.user_list);
    }
  };

  //returns a sorted array based on users first name.
  const handleNameSort = () => {
    const getSortUsersData = nameSort(usersList, isAscending);
    setUsersList(getSortUsersData.sortUsers);
    setIsAscending(getSortUsersData.isAscending);
  };

  //returns a sorted array based on users email.
  const handleEmailSort = () => {
    const getSortUsersData = emailSort(usersList, isAscending);
    setUsersList(getSortUsersData.sortUsers);
    setIsAscending(getSortUsersData.isAscending);
  };

  //returns a sorted array based on users status.
  const handleStatusSort = () => {
    const getSortUsersData = statusSort(usersList, isAscending);
    setUsersList(getSortUsersData.sortUsers);
    setIsAscending(getSortUsersData.isAscending);
  };

  return (
    <div>
      <AppBar position="static" className={classes.users2}>
        <Toolbar>
          <Grid alignItems="center" container style={{ marginLeft: "24px" }}>
            <img
              onClick={() => {
                isBackButtonClicked(true);
              }}
              src={BackArrow}
              style={{ marginRight: "24px", cursor: "pointer" }}
            />

            <Typography className={classes.plantNameTitle}>
              {usersListData?.plant_name}
            </Typography>
          </Grid>
        </Toolbar>
      </AppBar>

      <Grid
        style={{
          margin: "24px 30px 5px 30px",
          fontFamily: "Montserrat",
          fontWeight: 500,
        }}
      >
        <Grid container justifyContent="flex-end">
          <Grid item xs={1}>
            <Typography className={classes.userId}>Id</Typography>
          </Grid>
          <Grid item xs={2}>
            <span
              onClick={handleNameSort}
              role="button"
              tabIndex={"0"}
              aria-hidden="true"
            >
              <Grid container alignItems="center">
                Name
                <img
                  style={{ marginLeft: "4px", cursor: "pointer" }}
                  alt="img"
                  src={sortLogoUsersSitePage}
                />
              </Grid>
            </span>
          </Grid>
          <Grid item xs={2}>
            <span
              onClick={handleEmailSort}
              role="button"
              tabIndex={"-1"}
              aria-hidden="true"
            >
              <Grid container alignItems="center">
                Email
                <img
                  style={{ marginLeft: "4px", cursor: "pointer" }}
                  alt="img"
                  src={sortLogoUsersSitePage}
                />
              </Grid>
            </span>
          </Grid>
          <Grid item xs={0}>
            <span
              onClick={handleStatusSort}
              role="button"
              tabIndex={"-2"}
              aria-hidden="true"
            >
              <Grid container alignItems="center">
                Status
                <img
                  style={{ marginLeft: "4px", cursor: "pointer" }}
                  alt="img"
                  src={sortLogoUsersSitePage}
                />
              </Grid>
            </span>
          </Grid>

          <Grid style={{ marginLeft: "23px" }} item xs={0}>
            <span>Site Access</span>
          </Grid>
          <Grid style={{ marginLeft: "55px" }} item xs>
            <span role="button" tabIndex={"-3"} aria-hidden="true">
              User Role
            </span>
          </Grid>
          <Grid item xs>
            <Grid
              style={{
                marginRight: "60px",
              }}
            >
              Role Description
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {usersList.map((user, index) => {
        return (
          <div key={user.user_id}>
            <UserListCard
              index={index + 1}
              usersListData={usersListData}
              user={user}
            />
          </div>
        );
      })}
    </div>
  );
};

export default withStyles(styles)(UsersListForSitesAccess);
