import { Hidden } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import i18n from "i18next";
import styles from "./ProLabel.styles";
import { withStyles } from "@material-ui/core/styles";

//To show pro label
const ProLabel = (props) => {
  const styles = {
    marginRight: props.marginRight,
    marginLeft: props.marginLeft,
  };
  return props.show ? (
    <div style={styles} className={props.classes.textContainer}>
      {i18n.t(props.text)}
    </div>
  ) : (
    <Hidden smDown>
      <div style={styles} className={props.classes.textContainer}>
        {i18n.t(props.text)}
      </div>
    </Hidden>
  );
};

ProLabel.defaultProps = {
  text: "Pro",
};

//updating langugae state
const mapStateToProps = (state) => {
  return {
    language: state.language.selectedLanguage,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(ProLabel));
