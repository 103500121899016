import { Grid, TableCell, TableRow } from "@material-ui/core";

import React from "react";
import { Trans } from "react-i18next";

function CustomTableCell({ obj, classes, styles, key }) {
  return (
    <TableRow key={key}>
      <TableCell
        classes={{
          root: classes.noDividerTable,
        }}
      >
        <Grid container direction="column">
          <div>
            <span
              className={classes.severityBox}
              style={{
                backgroundColor: obj.color,
              }}
            />
            <span
              className={classes.severityText}
              style={{
                fontSize: "12px",
              }}
            >
              <Trans>{obj.name}</Trans>
            </span>
          </div>
        </Grid>
      </TableCell>
      <TableCell
        classes={{
          root: classes.noDividerTable,
        }}
      >
        <span className={classes.defCount}>{obj.count}</span>
      </TableCell>
      <TableCell
        classes={{
          root: classes.noDividerTable,
        }}
      >
        <span style={styles.severityText}>
          {obj.percentage}
          {"%"}
        </span>
      </TableCell>
    </TableRow>
  );
}

export default CustomTableCell;
