import { makeStyles } from "@material-ui/core/styles";
export default makeStyles(() => {
  return {
    root: {
      display: "grid",
      justifyContent: "center",
      paddingTop: "140px",
    },
    h1: {
      textAlign: "center",
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "800",
      fontSize: "112px",
      lineHeight: "137px",
      color: "#2A0950",
      flex: "none",
      order: 0,
      flexGrow: 0,
      margin: "16px 0px",
    },
    h2: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "25px",
      lineHeight: "30px",
      color: "#290950",
      flex: "none",
      order: 0,
      flexGrow: 0,
      margin: "24px 0px",
    },
  };
});
