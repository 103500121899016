const data = [
  {
    value: "",
    key: "inspection_date",
    type: "date",
    inSingle: true,
  },
  {
    value: "Standard Test Condition (ΔT)",
    key: "stc_delta_temperature",
    type: "temperature",
    inSingle: true,
  },
  {
    value: "Site Condition (ΔT)",
    key: "delta_temperature",
    type: "temperature",
    inSingle: true,
  },
  {
    value: "Actual Module Temp.",
    key: "actual_module_temperature",
    type: "temperature",
    inSingle: true,
  },
  {
    value: "Hotspot Temp.",
    key: "hotspot_temperature",
    type: "temperature",
    inSingle: true,
  },
  {
    value: "Irradiation (W/m²)",
    key: "irradiation",
    type: "normal",
    inSingle: true,
  },
  {
    value: "Anomaly Category",
    key: "label",
    type: "category",
    inSingle: true,
  },
  {
    value: "IEC CoA",
    key: "iec_category",
    type: "image",
    inSingle: true,
  },
  {
    value: "Severity",
    key: "severity_type",
    type: "image",
    inSingle: false,
  },
  {
    value: "Anomaly No.",
    key: "defect_no",
    type: "id",
    inSingle: false,
  },
  {
    value: "Anomaly",
    key: "defect_type_name",
    type: "anomaly",
    inSingle: false,
  },
  {
    value: "Status",
    key: "status",
    type: "status",
    inSingle: false,
  },
  {
    value: "Thermal",
    key: "thermal_image_url",
    type: "thermal",
    inSingle: false,
  },
  {
    value: "RGB",
    key: "rgb_image_url",
    type: "rgb",
    inSingle: false,
  },
];

export default data;
