import { persistor, store } from "../../store";

import Config from "../../config";
import { Grid } from "@material-ui/core";
import Hamburger from "./hamburger";
import { PersistGate } from "redux-persist/lib/integration/react";
import ProTrial from "../../views/product/ProTrial";
import { Provider } from "react-redux";
import React from "react";
import Routing from "./routing";
import Sidebar from "../sidebar/sidebar";
import SnackbarAlert from "./SnackbarAlert";
import { URLS } from "../../constants";
import ViewPlans from "../../views/product/ViewPlans";
import _ from "lodash";
import { allRequestHandler } from "../../api";
import { callSnackbarAction } from "../../reducers/snackbar";
import { getProductStatus } from "../../reducers/product";
import styles from "./header.style";
import { withStyles } from "@material-ui/core";

const requestUrl = Config.hostName;

//header
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetails: null,
      siteListData: null,
      moduleDefectChart: null,
      redirect: false,
      updateSideIcon: false,
      getUser: null,
      loggedInUser: null,
      loginUrl: null,
      dashboardUrl: null,
      sitesUrl: null,
      toggleMenu: false,
      showPlans: false,
      showTrial: false,
      productStatus: null,
      allSites: null,
      currentPathUpdate: null,
    };
  }

  handleUpdateShowPlans = (value) => {
    this.setState({ showPlans: value, showTrial: false });
  };

  handleShowTrial = (value) => {
    this.setState({ showTrial: value, showPlans: false });
  };

  //validating user
  componentDidMount() {
    localStorage.setItem("sectionId", null);
    if (
      sessionStorage.getItem("jwtToken") &&
      sessionStorage.getItem("jwtToken") !== null
    ) {
      try {
        this.validateUser(true);
      } catch (error) {
        // skipcq Avoid using setState in componentDidMount JS-0442
        this.setState({ redirect: true });
      }
    } else {
      // skipcq Avoid using setState in componentDidMount JS-0442
      this.setState({ redirect: true });
    }

    // Calling dashboard api without login
    const saveLogin = localStorage.getItem("loginData");
    const getToken = sessionStorage.getItem("jwtToken");
    if (saveLogin !== "undefined" && JSON.parse(saveLogin) && getToken) {
      this.passUrls(JSON.parse(saveLogin));
    }
  }

  setToggleMenu = (value) => {
    this.setState({ toggleMenu: value });
  };

  validateUser = async (value) => {
    if (value && sessionStorage.getItem("jwtToken") !== null) {
      try {
        const getSites = await allRequestHandler({
          requestType: "GET",
          requestUrl: URLS.SITE_LIST_DATA,
        });
        if (getSites && getSites.status && getSites.status === 401) {
          sessionStorage.setItem("jwtToken", "");
          this.setState({ redirect: true });
        }

        const loggedInUser = await allRequestHandler({
          requestType: "GET",
          requestUrl: URLS.USER_PROFILE,
        });
        if (loggedInUser) {
          // getting user product status on login
          store.dispatch(getProductStatus(loggedInUser?.user_profile?.product));
          this.setState({ productStatus: store.getState().product });
        }
        if (
          loggedInUser &&
          loggedInUser.status &&
          loggedInUser.status === 401
        ) {
          sessionStorage.setItem("jwtToken", "");
          this.setState({ redirect: true });
        }

        this.setState({
          siteListData: _.orderBy(getSites.site_list, "plant_name", "asc"),
          allSites: getSites,
          loggedInUser,
        });
        const cookieStore = this.props.cookieStorage.getAirprobeCookies();
        if (cookieStore.cookies.length === 0) {
          this.props.cookieStorage.setItem("essentialCookies", true);
          this.props.cookieStorage.setItem("performanceCookies", true);
        }
        if (cookieStore.performanceCookies === "true") {
          this.props.cookieStorage.enablePerformanceCookies();
        }
        if (cookieStore.performanceCookies === "false") {
          this.props.cookieStorage.disablePerformanceCookies();
        }
      } catch (error) {
        this.setState({ redirect: true });
      }
    }
  };

  updateSidebar = (props) => {
    // if defect page then sidebar icon is changed
    if (
      props.match.path !== "/defects/:siteId" ||
      localStorage.getItem("val1") === 2
    ) {
      this.setState((prevState) => {
        return {
          updateSideIcon: !prevState.updateSideIcon,
        };
      });
    }
  };

  callSnackbar = (msg, sev) => {
    store.dispatch(callSnackbarAction(msg, sev));
  };

  logoutMsg = () => {
    this.callSnackbar("You have logged out successfully.", "info");
  };

  callLoggedInUser = async () => {
    this.setState({
      loggedInUser: await allRequestHandler({
        requestType: "GET",
        requestUrl: URLS.USER_PROFILE,
      }),
    });
  };

  passUrls = async (value) => {
    const getAllUrl = await allRequestHandler({
      requestType: "GET",
      requestUrl: requestUrl + value.dashboard_href,
    });

    if (getAllUrl)
      this.setState({
        dashboardUrl: getAllUrl.dashboard_href_list,
        sitesUrl: getAllUrl.sites,
      });
  };

  handleResetData = () => {
    this.setState({ siteListData: null });
  };

  //routing for the app
  render() {
    const { classes } = this.props;
    const path = window.location.pathname;
    const pathItems = path.split("/");

    return (
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <SnackbarAlert />
          <Grid
            style={{
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Grid
              style={{
                display:
                  path === "/" ||
                  path === "/ForgotPassword" ||
                  path === "/reset-password"
                    ? "none"
                    : "block",
              }}
            >
              <Grid className={classes.DeskView}>
                {this.state.showPlans && (
                  <ViewPlans onHandleShowPlans={this.handleUpdateShowPlans} />
                )}
                {this.state.showTrial && (
                  <ProTrial onHandleShowTrial={this.handleShowTrial} />
                )}
                <Sidebar
                  // skipcq Avoid using spreading operator for JSX props JS-0428
                  {...this.props}
                  updateSideIcon={this.state.updateSideIcon}
                  siteListData={this.state.siteListData}
                  logoutMsg={() => {
                    return this.logoutMsg();
                  }}
                  loggedInUser={this.state.loggedInUser}
                  setToggleMenu={this.setToggleMenu}
                  userDetails={this.state.loggedInUser}
                />
              </Grid>
              <Grid className={classes.MobileView}>
                {this.state.siteListData && (
                  <Hamburger
                    logoutMsg={() => {
                      return this.logoutMsg();
                    }}
                    userDetails={this.state.loggedInUser}
                    showPlans={this.state.showPlans}
                    onHandleShowPlans={this.handleUpdateShowPlans}
                    siteListData={this.state.siteListData}
                  />
                )}
              </Grid>
            </Grid>

            <Grid style={{ width: "100%" }}>
              <Routing
                path={path}
                validateUser={(v) => {
                  return this.validateUser(v);
                }}
                handleResetData={() => {
                  return this.handleResetData();
                }}
                loginUrl={(value) => {
                  return this.passUrls(value);
                }}
                cookieStorage={this.props.cookieStorage}
                callSnackbar={(msg) => {
                  return this.callSnackbar(msg);
                }}
                userDetails={this.state.userDetails}
                userDetailsInDefectMap={
                  this.state.siteListData ? this.state.siteListData : null
                }
                userDetailsForLoggedIn={this.state.loggedInUser}
                siteListData={this.state.siteListData}
                sitesUrl={this.state.sitesUrl}
                loggedInUser={this.state.loggedInUser}
                toggleMenu={this.state.toggleMenu}
                siteId={pathItems[pathItems.length - 1]}
                navigate={this.props.navigate}
                moduleDefectChart={this.state.moduleDefectChart}
                showPlans={this.state.showPlans}
                showTrial={this.state.showTrial}
                loginUrlDash={this.state.loginUrl}
                dashboardUrl={this.state.dashboardUrl}
                onHandleShowPlans={this.handleUpdateShowPlans}
                url={path}
                userTimeOut={() => {
                  return this.setState({ redirect: true });
                }}
                allSites={this.state.allSites}
                getUser={this.state.getUser}
                callLoggedInUser={() => {
                  return this.callLoggedInUser();
                }}
                Redirect={this.state.redirect}
              />
            </Grid>
          </Grid>
        </PersistGate>
      </Provider>
    );
  }
}

export default withStyles(styles)(Header);
