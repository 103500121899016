import { Divider, Grid, Typography, withStyles } from "@material-ui/core";

import BackArrowImg from "./backArrow.svg";
import CloseIcon from "@material-ui/icons/Close";
import CookieView from "./CookieView";
import CustomButton from "../../views/common/customStyle/Button";
import React from "react";
import styles from "./cookieConsent.style";

//List of all the cookies used in the application
function CookieListing({
  classes,
  selectedCookieList,
  closeCookieListing,
  backArrowHandler,
  savePreferenceHandler,
  selectedCookieSection,
}) {
  return (
    <Grid className={classes.cookieListingContainer}>
      <Grid
        className={classes.header}
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ height: "60px" }}
      >
        <Typography
          className={classes.subTitleText}
          style={{ color: "#536471", cursor: "pointer" }}
          onClick={backArrowHandler}
        >
          <img src={BackArrowImg} style={{ marginRight: 6 }} alt="backArrow" />
          {selectedCookieSection} List
        </Typography>
        <CloseIcon
          fontSize="large"
          className={classes.closeIcon}
          onClick={closeCookieListing}
        />
      </Grid>
      <Divider />
      <Grid
        className={classes.bodySection}
        style={{ height: "500px", paddingTop: 30 }}
      >
        <Grid className={classes.managCookiesSection}>
          {selectedCookieList &&
            selectedCookieList.length > 0 &&
            selectedCookieList.map((item) => {
              return <CookieView item={item} key={item.duration} />;
            })}
        </Grid>
      </Grid>
      <Divider />
      <Grid className={classes.footer} style={{ height: "60px" }}>
        <CustomButton
          variant="outlined"
          onClick={savePreferenceHandler}
          text="Save My Preferences"
          style={{
            marginLeft: "12px",
            background: "#5B4DD3",
            color: "#FFFFFF",
            border: "none",
          }}
        />
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(CookieListing);
