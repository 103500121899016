import {
  Button,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";

import { Trans } from "react-i18next";
import falsepositiveImg from "../../../assets/img/falsePositive.svg";
import notfoundImg from "../../../assets/img/notfound.svg";
import pendingImg from "../../../assets/img/pending.svg";
import progressImg from "../../../assets/img/progress.svg";
import resolvedImg from "../../../assets/img/resolved.svg";
import { statusList } from "../../../constants";
import styles from "./anomalyStatus.style";
import { withStyles } from "@material-ui/core/styles";

//Anomaly status list
function AnomalyStatus({
  statusType,
  statusLabel,
  statusView,
  status,
  handleStatus,
  classes,
  defect,
  selectedStatus,
  gtmId,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef();

  useEffect(() => {
    //Add event listener when component is mounted and remove it when component is unmounted
    const handler = (event) => {
      if (selectRef && isOpen) {
        if (selectRef.current.contains(event.target)) {
          event.preventDefault();
          event.stopPropagation();
        } else {
          setIsOpen(false);
        }
      }
    };

    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, [isOpen]);

  //Use images based on the keys
  let updateImg = pendingImg;
  if (statusType === "resolved") updateImg = resolvedImg;
  else if (statusType === "falsepositive") updateImg = falsepositiveImg;
  else if (statusType === "notfound") updateImg = notfoundImg;
  else if (statusType === "inprogress") updateImg = progressImg;

  let filterStatusName = "";
  if (defect && defect.properties && defect.properties.status) {
    if (
      defect.properties.status === "resolved" ||
      defect.properties.status === "Resolved"
    )
      filterStatusName = "resolved";
    else if (
      defect.properties.status === "falsepositive" ||
      defect.properties.status === "False +Ve"
    )
      filterStatusName = "falsepositive";
    else if (
      defect.properties.status === "notfound" ||
      defect.properties.status === "Not Found"
    )
      filterStatusName = "notfound";
    else if (
      defect.properties.status === "inprogress" ||
      defect.properties.status === "In Progress"
    )
      filterStatusName = "inprogress";
    else filterStatusName = "pending";
  }

  return statusView === "buttonView" ? (
    <Button
      size="small"
      variant="outlined"
      style={{
        marginBottom: "10px",
        border:
          (status && status.includes(statusType)) ||
          (selectedStatus && selectedStatus === statusType)
            ? "1px solid #5B4DD3"
            : "1px solid #e4eaf5",
      }}
      classes={{
        root:
          (status && status.includes(statusType)) ||
          (selectedStatus && selectedStatus === statusType)
            ? classes.fltBtn
            : classes.disableFltBtn,
        label: classes.btnLabel,
      }}
      onClick={() => {
        handleStatus(statusType);
      }}
      id={gtmId}
    >
      <img
        style={{
          width: 16,
          marginTop: 0,
          marginLeft: 4,
          marginRight: 7,
        }}
        src={updateImg}
        alt={statusLabel}
      />

      <div
        style={{
          minWidth: 85,
          textAlign: "left",
        }}
      >
        <Trans>{statusLabel}</Trans>
      </div>
    </Button>
  ) : (
    <FormControl
      variant="outlined"
      style={{
        width: 150,
        height: 40,
        marginLeft: "auto",
      }}
      ref={selectRef}
    >
      <Select
        open={isOpen}
        native={false}
        onOpen={() => {
          setIsOpen(true);
        }}
        onClose={() => {
          setIsOpen(false);
        }}
        inputProps={
          (defect.properties.status === "falsepositive" ||
            defect.properties.status === "False +Ve") &&
          defect.properties.is_fp_proof_available
            ? { disabled: true }
            : null
        }
        value={filterStatusName}
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          classes: { paper: classes.blockMenu },
        }}
        onChange={(e) => {
          e.stopPropagation();
          e.preventDefault();
          handleStatus(
            e,
            defect.properties.defect_id,
            defect.properties.status
          );
        }}
        style={{
          width: 150,
          height: 40,
          marginLeft: "-10px",
          background: "white",
        }}
        classes={{
          icon: classes.iconMove,
          root: classes.padd0,
        }}
        className={classes.selectBlock}
      >
        {statusList.map((s) => {
          return (
            <MenuItem
              key={s.value}
              value={s.value}
              ListItemClasses={{
                root: classes.blockList,
                selected: classes.selectedList,
              }}
              data-intercom-target="defect-status-filter"
              id={"gtm_detailStatus"}
            >
              <Typography className={classes.plantName}>
                {s.value === "pending" ? (
                  <img
                    alt="statusImg"
                    className={classes.btnImg}
                    src={pendingImg}
                  />
                ) : s.value === "falsepositive" ? (
                  <img
                    alt="statusImg"
                    className={classes.btnImg}
                    src={falsepositiveImg}
                  />
                ) : s.value === "resolved" ? (
                  <img
                    alt="statusImg"
                    className={classes.btnImg}
                    src={resolvedImg}
                  />
                ) : s.value === "inprogress" ? (
                  <img
                    alt="statusImg"
                    className={classes.btnImg}
                    src={progressImg}
                  />
                ) : (
                  <img
                    alt="statusImg"
                    className={classes.btnImg}
                    src={notfoundImg}
                  />
                )}{" "}
                <Trans>{s.name}</Trans>{" "}
              </Typography>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

export default withStyles(styles)(AnomalyStatus);
