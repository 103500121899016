import { Button, Typography } from "@material-ui/core";

import React from "react";
import { Trans } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";

//styles for button
const styles = () => {
  return {
    btnCss: {
      background: "linear-gradient(266.69deg, #5B4DD3 0.23%, #5B4DD3 99.28%)",
      borderRadius: 8,
      fontFamily: "Montserrat",
      color: "#FFF",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 12,
      lineHeight: "20px",
      boxShadow: "unset",
      height: 36,
      padding: "4px 15px",
      margin: 0,
    },
    cancel: {
      background: "#FFFFFF",
      border: "1px solid #DDE5EC",
      boxSizing: "border-box",
      borderRadius: 8,
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 12,
      lineHeight: "20px",
      color: "#536471",
      marginLeft: 8,
      marginRight: 16,
    },
    bgNone: {
      background: "none",
      color: "#536471",
      padding: "0px 0px",
      lineHeight: "auto",
      borderRadius: 2,
    },
    bgNoneWOHover: {
      background: "none",
      color: "#536471",
      padding: "0px 0px",
      lineHeight: "auto",
      borderRadius: 2,
      "&:hover": {
        background: "none",
        color: "#536471",
        border: "none",
        boxShadow: "none",
      },
    },
    deleteBtn: {
      height: "36px",
      width: "36px",
      minWidth: "36px",
      background: "#F0F4FA",
      borderRadius: "8px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    redDelete: {
      background: "#F84343",
      borderRadius: 8,
    },
    btnLabel: {
      pointerEvents: "none",
    },
  };
};

//custom button
class CustomButton extends React.Component {
  //Renders a custom styled button
  render() {
    const { classes } = this.props;

    return (
      <Button
        disabled={this.props.disabled}
        variant={this.props.variant}
        className={[classes.btnCss, classes[this.props.type]].join(" ")}
        classes={{
          label: classes.btnLabel,
        }}
        onClick={this.props.onClick}
        style={this.props.style}
        startIcon={this.props.startIcon}
        id={this.props.id}
      >
        {this.props.btnIcon && (
          <img
            src={this.props.btnIcon}
            alt="filter"
            style={{ marginRight: this.props.margin ? this.props.margin : 0 }}
          />
        )}
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: 500,
            fontSize: "12px",
            lineHeight: "20px",
            color: this.props.btn === "save" && "#fff",
          }}
        >
          <Trans>{this.props.text}</Trans>
        </Typography>
      </Button>
    );
  }
}

//Default props
// skipcq static properties should be positioned JS-0472
CustomButton.defaultProps = {
  variant: "contained",
  type: "action",
  disabled: false,
  btnIcon: false,
  marginRight: 8,
};

export default withStyles(styles)(CustomButton);
