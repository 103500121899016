import { Button, Grid, Typography, withStyles } from "@material-ui/core";
import React, { useState } from "react";

import CsvDownload from "../../assets/img/csvDownload.svg";
import DownloadCustomPopup from "./popups/DownloadCustomPopup";
import PdfDownload from "../../assets/img/pdfDownload.svg";
import { Trans } from "react-i18next";
import styles from "./reports.style";

//download button
const CustomDownloadButton = ({
  type,
  formData,
  customFormHandler,
  downloadCustomReport,
  callSnackbar,
  classes,
}) => {
  const [showDownloadPopup, setShowDownloadPopup] = useState(false);

  //This renders the custom styled download button
  return (
    <Grid key={type}>
      <Button
        variant="outlined"
        className={classes.buttonOutlined}
        onClick={() => {
          return setShowDownloadPopup(true);
        }}
      >
        <img
          alt="csvImg"
          src={type === "csv" ? CsvDownload : PdfDownload}
          style={{ marginRight: 8 }}
        />
        <Typography className={classes.titleText} style={{ marginTop: 0 }}>
          <Trans>Generate</Trans> .{type} <Trans>File</Trans>
        </Typography>
      </Button>
      {showDownloadPopup && (
        <DownloadCustomPopup
          callSnackbar={callSnackbar}
          downloadCustomReport={downloadCustomReport}
          setShowDownloadPopup={setShowDownloadPopup}
          formData={formData}
          customFormHandler={customFormHandler}
          type={type}
        />
      )}
    </Grid>
  );
};

export default withStyles(styles)(CustomDownloadButton);
