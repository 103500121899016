export default {
  checkLabel: {
    fontSize: 12,
    fontWeight: 500,
    fontFamily: "Montserrat",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "20px",
    letterSpacing: "normal",
    color: "#536471",
  },
  subText: {
    margin: "0 5px 0 0",
    fontFamily: "Montserrat",
    fontSize: 12,
    fontWeight: 500,
    color: "#536471",
    fontStyle: "normal",
  },
  marT25: {
    marginTop: 25,
  },
  sliderBox: {
    height: 32,
    width: 56,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    fontSize: 12,
    fontWeight: "normal",
    borderRadius: 8,
  },
  tempInput: {
    width: "100%",
    height: "100%",
    border: "1px solid #DDE5EC",
    padding: "3px",
    borderRadius: 8,
    zIndex: 0,
  },
  sliderPoint: {
    height: 16,
    width: 16,
    backgroundColor: "#5B4DD3",
    marginLeft: -13,
    boxShadow: "rgb(235, 235, 235) 0px 2px 2px",
    pointerEvents: "none",
  },
  sliderLine: {
    height: 0,
    width: "289px",
  },
  height10: {
    height: 6,
    pointerEvents: "none",
  },
  trackBgcolor: {
    backgroundColor: "#5B4DD3 !important",
  },
  railBgcolor: {
    backgroundColor: "#E6EBF0 !important",
  },

  tempInfo: {
    display: "flex",
    flexDirection: "row",
    padding: 10,
    justifyContent: "space-between",
    alignItems: "center",
    background: "rgba(255, 193, 1, 0.1)",
    margin: "16px 0",
    borderRadius: 8,
  },
  tempInfoText: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "11px",
    lineHeight: "16px",
    alignItems: "center",
    color: "#536471",
  },
  reportTemp: {
    display: "flex",
    flexDirection: "row",
  },
  otherTempmark: {
    margin: "0 30px 0 5px",
    width: "90%",
    alignItems: "center",
    "@media screen and (max-width: 760px)": {
      width: "96%",
    },
  },
  reportTempmark: {
    margin: "0 16px 0 3px",
    width: "426px",
    alignItems: "center",
  },
};
