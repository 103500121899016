import { Switch, withStyles } from "@material-ui/core";

import React from "react";

const CustomSwitch = withStyles((theme) => {
  return {
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      "&$checked": {
        transform: "translateX(16px)",
        color: theme.palette.common.white,
        "& + $track": {
          backgroundColor: "#3FC0C2",
          opacity: 1,
          border: "none",
        },
      },
      "&$focusVisible $thumb": {
        color: "#8C9AA4",
        border: "6px solid #fff",
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      backgroundColor: "#8C9AA4",
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
  };
})(({ classes, onChangeHandler, cookieName, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableripple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      onChange={(e) => {
        return onChangeHandler(cookieName, e.target.checked);
      }}
      // skipcq Avoid using spreading operator for JSX props JS-0428
      {...props}
    />
  );
});

export default CustomSwitch;
