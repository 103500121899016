import { Grid, Typography } from "@material-ui/core";

import CustomButton from "../customStyle/Button";
import Image from "../../../assets/img/PageNotFound.svg";
import React from "react";
import useStyles from "./pageNotFound.style";

//To handle incorrect path or if something went wrong
const PageNotFound = () => {
  //redirect to login
  const routeChange = () => {
  };

  const classes = useStyles();
  return (
    <div
      style={{
        backgroundImage: `url(${Image})`,
        height: "100vh",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <div className={classes.root}>
        <Grid item xs={12}>
          <Typography className={classes.h1}>404</Typography>
          <Typography className={classes.h2}>Something is missing!</Typography>
          <div style={{ marginLeft: "74px" }}>
            <CustomButton text={"Back to Home"} onClick={routeChange} />
          </div>
        </Grid>
      </div>
    </div>
  );
};

export default PageNotFound;
