export const styles = {
  hr: {
    marginTop: "0.5em",
    marginBottom: "0.5em",
    borderWidth: "1px",
  },
  width500: {
    minWidth: 350,
    maxWidth: 400,
  },
  backIcon: {
    cursor: "pointer",
    color: "#536471",
  },
  margin: {
    margin: 1,
  },
  textField: {
    width: "30ch",
  },
  boxArea: {
    padding: 20,
  },
  textUser: {
    margin: "10px 0",
  },
  MuiGrid: {
    flexGrow: 1,
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "flex-end",
    position: "relative",
    flexDirection: "column",
    marginLeft: "auto",
  },
  title: {
    marginLeft: 30,
    marginTop: 10,
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: 20,
    textAlign: "center",
    color: "#f5f5f5",
  },
  align: {
    margin: 20,
  },
  search: {
    flexGrow: 1,
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "flex-start",
    position: "relative",
    flexDirection: "column",
    marginLeft: "100px",
    borderRadius: "4px",
    border: "1px solid #ebe6e6",
  },
  searchIcon: {
    padding: 2,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputInput: {
    alignItems: "center",
    paddingLeft: 30,
  },
  buttonls: {
    width: 250,
    display: "inlineflex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flexStart",
    position: "relative",
  },
};
