import {
  DialogContent,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Trans, withTranslation } from "react-i18next";
import {
  powerRevenueDuration,
  powerRevenueTableHeader,
} from "../../../constants/dashboardConstants";

import Config from "../../../config";
import CustomButton from "../customStyle/Button";
import CustomTextField from "../customStyle/TextField";
import CustomToggleSwitch from "../customStyle/ToggleSwitch";
import CustomTooltip from "../customStyle/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DownTri from "../../../assets/img/downTri.svg";
import DownloadIcon from "../../../assets/img/downloadloss.svg";
import InfoIcon from "@material-ui/icons/Info";
import InspectionDetails from "./newInspectionDetails";
import NewLoader from "../../../layout/newLoader";
import UpTri from "../../../assets/img/upTri.svg";
import { allRequestHandler } from "../../../api";
import { connect } from "react-redux";
import cross from "../../../assets/img/cross.svg";
import currencValConverter from "../../../utils/currencyValConverter";
import i18n from "i18next";
import powerValConverter from "../../../utils/powerEnergyConverter";
import styles from "./loss.styles";
import { withStyles } from "@material-ui/core/styles";

const requestUrl = Config.hostName;

const PowerRevenueLoss = (props) => {
  const { classes } = props;
  const [lossDetails, setLossDetails] = useState([]); //loss details
  const [updateToggle, setUpdateToggle] = useState(false); //patch request object
  const [duration, setDuration] = useState(365); // toggel between dates
  const [currency, setCurrency] = useState(null);
  const [dropDown, setDropDown] = useState(null); // dropdown to view site inspection details
  const [valueLoader, setValueLoader] = useState(true);

  useEffect(() => {
    // to load loss details of sites
    getApiData();
  }, [updateToggle]);

  //Hit API loss_details to display all data
  async function getApiData(value) {
    const urlName =
      props.userDetails && props.userDetails.plant_id
        ? `/api/sites/${props.userDetails.plant_id}/loss_details`
        : "/api/dashboard/loss_details";

    let noDays = duration;
    if (value) noDays = value;

    const getPlantData = await allRequestHandler({
      requestType: "GET",
      requestUrl: `${requestUrl}${urlName}?days=${noDays}`,
    });
    if (getPlantData) {
      const plantList = getPlantData.plants_list.sort((a, b) =>
        a.plant_name.localeCompare(b.plant_name)
      );
      setValueLoader(false);
      setLossDetails(plantList);
      setCurrency(getPlantData.currency);
    }
  }

  //Update powerLoss details
  const sendPatchRequest = async () => {
    const filterPatchData = lossDetails.map((plant) => {
      return {
        plant_id: plant.plant_id,
        yield: plant.yield,
        tariff: plant.tariff,
      };
    });
    const temp = lossDetails;
    setLossDetails([]);
    try {
      const patchUrl = await allRequestHandler({
        requestType: "PATCH",
        requestUrl: `${requestUrl}/api/dashboard/loss_details`,
        requestData: {
          plants_list: filterPatchData, //sending update object as patch
        },
      });

      if (patchUrl) {
        setUpdateToggle(!updateToggle);
        props.metadata();
      }
    } catch (error) {
      setLossDetails(temp);
      console.log("error:", error);
    }
  };

  //close revenue loss dialog
  const closeDialog = () => {
    props.closeDialog();
  };

  //function to change date
  const handleDuration = (e, value) => {
    setValueLoader(true);
    setDuration(value);
    getApiData(value);
  };

  const downloadCsvFile = async () => {
    const csvName =
      props.userDetails && props.userDetails.plant_id
        ? `/api/sites/${props.userDetails.plant_id}/loss_details/csv`
        : "/api/dashboard/loss_details/csv";

    const link = await allRequestHandler({
      requestType: "GET",
      requestUrl: `${requestUrl}${csvName}?days=${duration}`,
    });

    if (link) window.open(link.downloads_url);
  };

  const updateTextData = (field, val, index) => {
    switch (field) {
      case "Inspections":
        return val;
      case "Initial Power Loss":
      case "Current Power Loss":
        return val ? powerValConverter("power", val) : "--";
      case "Yield (kWh/kWp per year)":
      case "Feed in Tariff/kWh":
        return addCustomText(field, val, index);
      case "Estimated Energy Loss":
      case "Energy Recovered":
        return val ? powerValConverter("energy", val) : "--";
      case "Estimated Revenue Loss":
      case "Revenue Recovered":
        return val ? currency + " " + currencValConverter(val) : "--";
      default:
        return val;
    }
  };

  //function to handle dropdown
  const handleDropDown = (index) => {
    dropDown !== index ? setDropDown(index) : setDropDown(null);
  };

  //Handle change in input fields
  const handleInputChange = (event, index, field) => {
    const localLoss = [...lossDetails];
    localLoss[index][field] = event.target.value ? event.target.value : 0;
    setLossDetails(localLoss);
  };

  const addCustomText = (field, val, index) => {
    let filterField = field === "Yield (kWh/kWp per year)" ? "yield" : "tariff";
    return (
      <Grid container justifyContent="flex-end">
        <Grid
          item
          style={{ width: 100 }}
          onClick={(e) => {
            return e.stopPropagation();
          }}
        >
          <CustomTextField
            type="number"
            value={val}
            handleChange={(e) => {
              return handleInputChange(e, index, filterField);
            }}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <Dialog
      style={{ minWidth: 500 }}
      aria-labelledby="customized-dialog-title"
      open={props.open}
      fullScreen
    >
      <DialogTitle id="customized-dialog-title" className={classes.root}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography className={classes.titleTxt}>
            <Trans>Power & Revenue Loss</Trans>
          </Typography>

          <div className={classes.titleBtns}>
            <span style={{ marginRight: 20 }}>
              <CustomButton
                text={"Update"}
                onClick={sendPatchRequest}
                disabled={lossDetails && lossDetails.length ? false : true}
              />
            </span>
            <img
              src={cross}
              width={20}
              style={{ cursor: "pointer" }}
              onClick={() => {
                return closeDialog();
              }}
              alt="close"
              aria-hidden="true"
            />
          </div>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent classes={{ root: classes.dialogContentRoot }}>
        <Grid container alignItems="center" className={classes.dateBox}>
          <CustomToggleSwitch
            value={duration}
            onChange={handleDuration}
            btnGroup={powerRevenueDuration}
          />
          <CustomButton
            text={i18n.t("Download .Csv")}
            onClick={downloadCsvFile}
            btnIcon={DownloadIcon}
            type="cancel"
            margin={8}
          />
        </Grid>

        <Paper className={classes.paperRoot}>
          <Table aria-label="outer table">
            <TableHead>
              <TableRow className={classes.bgColor}>
                {powerRevenueTableHeader &&
                  powerRevenueTableHeader.map((headerLoop, index) => {
                    return (
                      <TableCell
                        style={{
                          textAlign:
                            headerLoop.name == "Sites" ? "left" : "right",
                          minWidth: headerLoop.name == "Sites" ? 250 : 140,
                          paddingLeft: headerLoop.name == "Sites" ? 32 : 8,
                        }}
                        key={index}
                        className={classes.tableHeader}
                      >
                        <Grid container>
                          <Grid item xs>
                            <Typography className={classes.highlight}>
                              {" "}
                              <Trans> {headerLoop.name} </Trans>{" "}
                            </Typography>
                          </Grid>
                          {headerLoop.tooltipTxt && (
                            <Grid item>
                              <CustomTooltip
                                title={
                                  <div>{i18n.t(headerLoop.tooltipTxt)}</div>
                                }
                              >
                                <InfoIcon className={classes.infoIcon} />
                              </CustomTooltip>
                            </Grid>
                          )}
                        </Grid>
                      </TableCell>
                    );
                  })}
              </TableRow>
            </TableHead>
            <TableBody>
              {lossDetails?.length ? (
                lossDetails.map((bodyOuterLoop, outerIndex) => {
                  return (
                    <>
                      <TableRow
                        key={bodyOuterLoop.plant_id}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleDropDown(outerIndex);
                        }}
                      >
                        {powerRevenueTableHeader.map((bodyInnerLoop, index) => {
                          return (
                            <TableCell
                              key={index}
                              style={{
                                paddingLeft:
                                  bodyInnerLoop.name == "Sites" ? 32 : 8,
                              }}
                              className={classes.padd12x8}
                            >
                              {valueLoader &&
                              (bodyInnerLoop.name == "Estimated Energy Loss" ||
                                bodyInnerLoop.name == "Energy Recovered" ||
                                bodyInnerLoop.name ==
                                  "Estimated Revenue Loss" ||
                                bodyInnerLoop.name == "Revenue Recovered") ? (
                                <Grid
                                  container
                                  justifyContent="center"
                                  alignItems="center"
                                >
                                  <NewLoader />
                                </Grid>
                              ) : (
                                <Typography
                                  className={classes.text2}
                                  style={{
                                    textAlign:
                                      bodyInnerLoop.name == "Sites"
                                        ? "left"
                                        : "right",
                                    fontWeight:
                                      bodyInnerLoop.value ==
                                        "initial_power_loss" ||
                                      bodyInnerLoop.value == "revenue_loss"
                                        ? 700
                                        : 500,
                                  }}
                                >
                                  {/* Dropdown icon toggle when user click on Site card */}
                                  <div className={classes.arrowCss}>
                                    {bodyInnerLoop.name == "Sites" &&
                                      (dropDown === outerIndex ? (
                                        <img alt="upImg" src={UpTri} />
                                      ) : (
                                        <img alt="downImg" src={DownTri} />
                                      ))}
                                  </div>

                                  {/* Displaying data inside table cell */}
                                  {updateTextData(
                                    bodyInnerLoop.name,
                                    bodyOuterLoop[bodyInnerLoop.value],
                                    outerIndex
                                  )}
                                  {bodyInnerLoop.name == "Inspections" &&
                                    (bodyOuterLoop.inspection_count > 1
                                      ? " Inspections"
                                      : " Inspection")}
                                </Typography>
                              )}
                              {/* For Location inside Site column */}
                              {bodyInnerLoop.name == "Sites" && (
                                <Typography className={classes.text3}>
                                  {bodyOuterLoop.location}
                                </Typography>
                              )}
                              {/* Add percentage for Power loss, Energy & Revenue Recovered */}
                              {bodyInnerLoop.name == "Current Power Loss"
                                ? bodyOuterLoop.power_loss_percent && (
                                    <Typography className={classes.redPercent}>
                                      {bodyOuterLoop.power_loss_percent.toFixed(
                                        2
                                      )}{" "}
                                      %
                                    </Typography>
                                  )
                                : bodyInnerLoop.name == "Energy Recovered"
                                ? bodyOuterLoop.energy_recovered_percent && (
                                    <Typography
                                      className={classes.greenPercent}
                                    >
                                      {bodyOuterLoop.energy_recovered_percent.toFixed(
                                        2
                                      )}{" "}
                                      %
                                    </Typography>
                                  )
                                : bodyInnerLoop.name == "Revenue Recovered"
                                ? bodyOuterLoop.revenue_recovered_percent && (
                                    <Typography
                                      className={classes.greenPercent}
                                    >
                                      {bodyOuterLoop.revenue_recovered_percent.toFixed(
                                        2
                                      )}{" "}
                                      %
                                    </Typography>
                                  )
                                : null}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                      {dropDown === outerIndex ? (
                        <TableRow style={{ background: "#F0F4FA" }}>
                          <InspectionDetails
                            reference={bodyOuterLoop.inspection_href.href}
                            classes={classes}
                            duration={duration}
                            currency={currency}
                          />
                        </TableRow>
                      ) : null}
                    </>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={10}>
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      style={{ minHeight: 400 }}
                    >
                      <NewLoader />
                    </Grid>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Paper>
      </DialogContent>
    </Dialog>
  );
};

//Calling redux to get information about the selected language
const mapStateToProps = (state) => {
  return {
    language: state.language.selectedLanguage,
  };
};

export default connect(mapStateToProps)(
  withStyles(styles)(withTranslation()(PowerRevenueLoss))
);
