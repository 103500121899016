import { Button, Grid, Typography, withStyles } from "@material-ui/core";
import { Trans, withTranslation } from "react-i18next";

import BasicImg from "./img/basicImg.svg";
import CheckImg from "./img/check.svg";
import HyphenImg from "./img/hyphen.svg";
import ProImg from "./img/proImg.svg";
import React from "react";
import i18n from "i18next";
import styles from "./product.style";

//Renders the product page
const Product = ({ classes, plan, contactSalesHandler }) => {
  //Renders the product page
  return (
    <Grid item xs={12} sm className={classes.product}>
      <div className={classes.productHeadSection}>
        <img
          src={plan.title === "Basic" ? BasicImg : ProImg}
          alt="basicOrProImg"
          style={{ height: "40%" }}
        />
        <Typography
          className={classes.headTitle}
          style={{ color: "#08001D", fontSize: "26px", margin: 0 }}
        >
          <Trans>{plan.title}</Trans>
        </Typography>
        <Button
          variant={`${plan.title === "Basic" ? "outlined" : "contained"}`}
          style={{
            color: `${plan.title === "Pro" ? "white" : "#8C9AA4"}`,
            border: `${plan.title === "Basic" ? "1px solid #C7CEDB" : "none"}`,
            width: `${plan.title === "Basic" ? "142px" : "208px"}`,
            Height: "40px",
            background: `${
              plan.title === "Pro"
                ? "linear-gradient(266.69deg, #3FC0C2 0.23%, #3FC0C2 99.28%)"
                : "white"
            }`,
            borderRadius: "8px",
          }}
          onClick={() => {
            return contactSalesHandler(plan.title);
          }}
        >
          <Trans>{plan.buttonText}</Trans>
        </Button>
      </div>
      <div style={{ background: "#FFFFFF" }}>
        {plan.features &&
          plan.features.length > 0 &&
          plan.features.map((feature) => {
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  paddingLeft: "30px",
                }}
                key={feature.title}
              >
                <img
                  src={`${feature.checked ? CheckImg : HyphenImg}`}
                  alt="hyphenImg"
                  style={{
                    marginRight: "10px",
                    opacity: `${feature.checked ? "1" : "0.9"}`,
                  }}
                />
                <span
                  className={classes.headTitle}
                  style={{
                    fontWeight: 500,
                    fontSize: "14px",
                    color: `${feature.checked ? "#08001D" : "grey"}`,
                    margin: "0",
                    lineHeight: "30px",
                  }}
                >
                  {feature.tooltipText ? (
                    <div className={classes.productTooltip}>
                      <span
                        style={{
                          fontSize: "14px",
                          color: `${feature.checked ? "#08001D" : "grey"}`,
                        }}
                      >
                        {" "}
                        <Trans>{feature.title}</Trans>{" "}
                      </span>
                      <span className={classes.tooltiptext}>
                        {i18n.t(feature.tooltipText) &&
                          `${i18n.t(feature.tooltipText)}`}
                      </span>
                    </div>
                  ) : (
                    <Trans>{feature.title}</Trans>
                  )}
                </span>
              </div>
            );
          })}
      </div>
    </Grid>
  );
};

export default withStyles(styles)(withTranslation()(Product));
