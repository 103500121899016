//currency value converter
const currencValConverter = (value) => {
  if (value > 999 && value < 1000000) {
    const result = `${(value / 1000).toFixed(2)}K`;
    return result;
  } else if (value >= 1000000 && value < 1000000000) {
    const result = `${(value / 1000000).toFixed(2)}M`;
    return result;
  } else if (value >= 1000000) {
    const result = `${(value / 1000000000).toFixed(2)}B`;
    return result;
  } else if (value < 900 && value > 0) {
    const result = value;
    return result;
  }

  return value;
};

export default currencValConverter;
