import { fetchActivity, setActivity } from "./activity";

import alert from "./alert";
import bulkUpload from "./bulkUpload";
import { combineReducers } from "redux";
import language from "./language";
import product from "./product";
import snackbar from "./snackbar";
import user from "./user";

//Initialize all the reducers
const reducers = combineReducers({
  user,
  alert,
  fetchActivity,
  setActivity,
  product,
  snackbar,
  bulkUpload,
  language,
});

export default reducers;
