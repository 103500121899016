const BE_URL = {
  hostName: process.env.REACT_APP_BE_URL,
  cookie_expiration: 7200,
};

// for Build
const config = BE_URL;

// export the default configuration
export default {
  ...config,
};

const urlName = window.location.hostname;

let setTrackId = "";
let token = "";
let setBarcodeKey = "";
if (urlName === "airprobe.app") {
  setTrackId = "UA-156058811-1";
  token =
    "pk.eyJ1IjoiYWlycHJvYmUiLCJhIjoiY2sxNjltbTM5MDZuYzNsbXI2bXF2ZDkxNSJ9._YATDkkbo5qkfB4wx4vc6w";
  setBarcodeKey =
    "AWjQ5z/pH1suKd4clyAclO0HPe0zPEMNeyhqyOk2RgTARLsV81mM8ER8LweBYdpMe0jIksx/Hak7eeE6NgHrqR9g4fNmSrfpiDA3zqp2YXcmXy/CIAYATEo1SvXfFf/3XkMOdqlE0GV8QXhi6IyZShWc967J9m0nIaR4l61lKmZCikOBYhNFPhCVCcXXWTA6qbJ1ZZFjGwDMEe+XcQJsHV3vtmt46CQnzMuiLAt9lOSZMngndUFOzOJ+Ju3gPmWXZY5sk+hFb4aslqu07OaaJqfg6pokJjoumAYRHYGVkL39JWXD78dc7qkaZRodmHoArk41pi3d8fvRqsevhpHSnatO+hMRr2SFDxjhOMWNrwj0u9yNipVovxkBC/44RB7OFD/2MiuHgIzDhiVNRlvDvSPPgQcIXKoc5LxicQCIH4zPe8hl5kYTCqv8+t7uaF41xNldM3XE+O1xPrI1w7nPH0RLB54U2H1cxk8QLiPsZXW7mf9mAI1OjUflITOE6C2TJJlZhRp7m157aMvTrA7Vnr0LR+AVD1UIRVZ8vfBtF2J+KRgNKz7aLtAgINJ5lgrG+152yOouyX3oXc0blvl+qbnxXx2+JyR6AiWBGb/+SOKKaB9y6ZfW3GC4s5uMR7rOVc35EC46v+RLY7HnEmpcgi4pygR1aUZlTAeCOCbxXCiGrhlRUTjszp2X860vqW6yEAauQjmqpZmIu1xAuy2PONDh9XKrOKwNqtTAMqH0Znkf9Rug4tHFXQG/2TwAWyOlhzLPvEhFJ7jOIeoO/pJQhgUoVYNW+XG3SV9hdTqHAYuUTGs=";
} else if (urlName === "demo.airprobe.app") {
  setTrackId = "UA-156058811-2";
  token =
    "pk.eyJ1IjoiYWlycHJvYmUiLCJhIjoiY2sxNjlsMmFlMDFxazNubGxuZjhoN3c3MiJ9.8kXZHVYzva_HGDBXp99vYQ";
  setBarcodeKey =
    "ATHgCDDpEPy2IbZGNz18VmwTvCL0BrRJ2GC5OXMhbDoUYs/eBlk53ldPDJqce8DDJhq3cn1CgQGMU+fTBE8Bi6pM7KBbIIa7c3AiBGxJtWSueTRLb2fhBU4Gt2lKIHozCR0pjiMO8wBShW7oWlfJa5+QU49bIjnvOLffa4T41lRpQCNrpPkinyCoeYlDzX2fVq3fcH75NmQEg3YazcgykLmr3hW477WJP0D9HR/8s1aOs0BhNygJivOJ83eIumnCl/QSUlzUgUoAAmk5wkkIKTUZXL7I2gXApidIXtSogqixfE07nUw9IyvSLJcepKtk5yqUymr8Z5J+ZCmoIvU5+Stgx5TwVgOreGZP7iQi5ezo01cJ8YS/HlOgdkIEZF4kpAtz+OCxFev46ooU1RINu0Pjvm0AUxyax3dfSjGHJ3CZPXvbV6KVvb5qhRAMV0plYOmC19p6H+0A+hQG19fzmWNERvSmC6CcKPVBB3RcLSBYaZpzJqiFSU5qaDVetFM/oP613kYQ+rGmXvvNtGNt4zY3h5haDJrr90K5cSio6gNvb4ThqME9hUYPZaaPGgg9z7yfpBTO2KjEphr+7Bs+RB2mTy7RTdM5J7IThFcQJFqQ3LxebF2WvrS7/rrtGJoikVXN/SaDxQl0NwtzuPOdsqQO/6OGhSnoiEjmrF63K0NytdlXcTKlMAR+fEnRRo3WYK4/sO8Rku4J/6CkDuf723yWcaoxyhcU6nxaVVhWMARI67iBA1CdYJ8XlpFIymFWleQInjacuBeAH7bRkbdQldZTSl8X+qYvN4guQxZYcHJ0SNewuV0B2ik=";
} else if (urlName === "staging.airprobe.app") {
  setTrackId = "UA-156058811-4";
  token =
    "pk.eyJ1IjoiYWlycHJvYmUiLCJhIjoiY2tkcmVqbDF2MDVqbzJ0b3FmeTcxcHFrZSJ9.YQR_ZeBEF43y8pV2KKvHcg";
  setBarcodeKey =
    "Aa2g+BLpMtjDJWNhbUU2ir8aLxUjNtOug2yyKUkFZmf3boyBlEOb+fRRz5MaYkYAuU+36mJFRJolfm4YiGc5fNZi8R8jfr8+0GFJHRM0Hpn+UU5sbCeSgoJwM3x2L2YmaxdL+4sK+hMFRXUlA42DEtZsPNhFQ5KgQrIcmVZXQqEpZMNeulhVu2b0RXSTyGFEYFeWlBII8OWoZEmbqQTz2axgR3p/VHYora0K/fh4tHaVe982P50Q+tRFYwQj0bBWYS7qay4CZEf9ABXA4XOevN+t3qED23rs7zqKkKI1h9YCfCXuVqp7xsJDPy/849Bc3r9wmgQmBIxhVooD5bRIxHBEAYfz9M3ZMYADmjQCSM729Powd5vYsQ/FOrXj2CeAf0BhXQtLNaMsuWEOzLf/eYdOVJ/cH64k+jeHjPQsyNXNNXCmxAMClgSeB2PdVeIcerSRWp2TRvKoL8peGpUu3CnT8dds+Tb/qfdoSPOSXL4HZPK9yvTylSDxpF1fKjCT+K5HBIyIEcOK/Rs1DRmt94MFoV/NTHKe9DFaFMwgcHaSWiFS9JzYO91A8NTuq6gXp2qGhueAeCbeNL93+YFKiWFqOmYNuFA7qP0hfLvZR7CHLLbvetFon6u38mYYVCz1YfQrg+lQbBK09hDm2Xf5AWCIOzB+E7ISWBeXe0FHD4qoW2P/w7Wo/QkrB+gUxuSEzAe8KdK8MAMbbeEMNjxavvGEIQc+cTCbp7FdIdf1Wxwf5hg0p0AaVAN5dkqv6VtbfURyfHcWoOP8C8L/xgsuOdzq2uEMv3bfcLAmBLddsfhomQ3QmVKq3fTZHL0=";
} else {
  setTrackId = "UA-156058811-3";
  token =
    "pk.eyJ1IjoiYWlycHJvYmUiLCJhIjoiY2tkcmVqbDF2MDVqbzJ0b3FmeTcxcHFrZSJ9.YQR_ZeBEF43y8pV2KKvHcg";
  setBarcodeKey =
    "Aa2g+BLpMtjDJWNhbUU2ir8aLxUjNtOug2yyKUkFZmf3boyBlEOb+fRRz5MaYkYAuU+36mJFRJolfm4YiGc5fNZi8R8jfr8+0GFJHRM0Hpn+UU5sbCeSgoJwM3x2L2YmaxdL+4sK+hMFRXUlA42DEtZsPNhFQ5KgQrIcmVZXQqEpZMNeulhVu2b0RXSTyGFEYFeWlBII8OWoZEmbqQTz2axgR3p/VHYora0K/fh4tHaVe982P50Q+tRFYwQj0bBWYS7qay4CZEf9ABXA4XOevN+t3qED23rs7zqKkKI1h9YCfCXuVqp7xsJDPy/849Bc3r9wmgQmBIxhVooD5bRIxHBEAYfz9M3ZMYADmjQCSM729Powd5vYsQ/FOrXj2CeAf0BhXQtLNaMsuWEOzLf/eYdOVJ/cH64k+jeHjPQsyNXNNXCmxAMClgSeB2PdVeIcerSRWp2TRvKoL8peGpUu3CnT8dds+Tb/qfdoSPOSXL4HZPK9yvTylSDxpF1fKjCT+K5HBIyIEcOK/Rs1DRmt94MFoV/NTHKe9DFaFMwgcHaSWiFS9JzYO91A8NTuq6gXp2qGhueAeCbeNL93+YFKiWFqOmYNuFA7qP0hfLvZR7CHLLbvetFon6u38mYYVCz1YfQrg+lQbBK09hDm2Xf5AWCIOzB+E7ISWBeXe0FHD4qoW2P/w7Wo/QkrB+gUxuSEzAe8KdK8MAMbbeEMNjxavvGEIQc+cTCbp7FdIdf1Wxwf5hg0p0AaVAN5dkqv6VtbfURyfHcWoOP8C8L/xgsuOdzq2uEMv3bfcLAmBLddsfhomQ3QmVKq3fTZHL0=";
}

export const trackId = setTrackId;
export const mapToken = token;
export const barcodeKey = setBarcodeKey;
