//power & energy value converter
const powerEnergyConverter = (field, value) => {
  const changeUnit = field === "energy" ? "Wh" : "Wp";

  if (value?.toString().split(".")[0].length <= 3) {
    return (
      Intl.NumberFormat("en").format(value?.toFixed(4)) + " k" + changeUnit
    );
  } else if (value?.toString().split(".")[0].length <= 6) {
    return (
      Intl.NumberFormat("en").format((value / 1000).toFixed(4)) +
      " M" +
      changeUnit
    );
  } else
    return (
      Intl.NumberFormat("en").format((value / 1000000).toFixed(4)) +
      " G" +
      changeUnit
    );
};

export default powerEnergyConverter;
