export default {
  sidebarTooltip: {
    color: "#ffffff",
    background: "#08001D",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
    borderRadius: "5px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "11px",
    lineHeight: "16px",
  },
  sideBarHeight: {
    height: "100%",
    "@media(max-height : 650px)": {
      overflowY: "auto",
      overflowX: "hidden",
    },
    marginTop: "-5px",
    // marginBottom: "-2px",
    justifyContent: "center",
    textAlign: "center",
    backgroundColor: "#FFFFFF",
  },
  margbot: {
    marginBottom: "5px",
  },
  margbotb: {
    marginBottom: "1px",
  },
  imgmarg: {
    marginLeft: "30px",
    marginTop: "12px",
  },
  initialOpen: {
    marginLeft: "28px",
    marginTop: "12px",
  },
  sidebarmenu: {
    display: "flex",
    alignItems: "center",
    width: "196px",
    height: "48px",
    padding: "0px 0px 0px 16px !important",
    borderRadius: "7px",
    paddingTop: "9px",
    paddingBottom: "11px",
    marginLeft: "12px",
    marginRight: "12px",
    cursor: "pointer",
  },
  sidebarmenuactive: {
    display: "flex",
    alignItems: "center",
    width: "196px",
    height: "48px",
    padding: "0px 0px 0px 16px !important",
    borderRadius: "7px",
    // background: "linear-gradient(266.69deg, #3FC0C2 0.23%, #3FC0C2 99.28%)",
  },
  sidebarmenuactiveSummary: {
    color: "white",
    display: "flex",
    alignItems: "center",
    width: "196px",
    height: "48px",
    margin: "0px !important",
    padding: "0px 0px 0px 16px !important",
    borderRadius: "7px",
    background: "linear-gradient(266.69deg, #5B4DD3 0.23%, #5B4DD3 99.28%)",
  },
  sidebarclosed: {
    display: "flex",
    alignItems: "center",
    width: "48px",
    height: "48px",
    justifyContent: "center",
  },
  sidebarclosedactive: {
    display: "flex",
    alignItems: "center",
    width: "48px",
    height: "48px",
    justifyContent: "center",
    borderRadius: "8px",
    color: "#FFFFFF",
    background: "linear-gradient(266.69deg, #5B4DD3 0.23%, #5B4DD3 99.28%)",
  },
  pro_image: {
    position: "relative",
    width: "25px",
  },
  closed_pro_image: {
    position: "relative",
    marginTop: "16px",
    width: "25px",
  },
  clientinitial: {
    border: "1px solid grey",
    borderRadius: "8px",
    backgroundColor: "white",
    color: "#545B65",
    fontSize: "12px",
    fontWeight: "bold",
    width: "16px",
    height: "15px",
    top: "50%",
    left: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "40px",
    minHeight: "40px",
  },
  tooltipArrow: {
    color: "#08001D",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
    borderRadius: "5px",
  },
  menuButton: {
    // marginRight: theme.spacing(2),
    position: "absolute",
    left: 110,
    marginTop: 9,
    zIndex: 2,
    // color: "rgb(179, 160, 186)"
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: 240,
    flexShrink: 0,
  },
  outerSkel: {
    height: "100%",
    padding: "0 25px",
    display: "block",
    "@media only screen and (max-width: 600px)": {
      display: "none",
    },
  },
  drawerNone: {
    width: 100,
    flexShrink: 0,
  },
  flexmargin: {
    display: "flex",
    alignItems: "center",
    marginLeft: "-10px",
  },
  drawerPaper: {
    width: "250px",
    marginTop: "0px !important",
    height: "100% !important",
    overflowY: "unset",
  },
  drawerHeader: {
    alignItems: "center",
    padding: "1px 1px -1px 24px",
    justifyContent: "space-between",
    backgroundColor: "#FFFFFF",
  },
  drawerClosedHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#FFFFFF",
  },
  linkStyles: {
    fontFamily: "Montserrat !important",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "24px",
    display: "inline",
    alignItems: "center",
    color: "#545B65",
    paddingLeft: 12,
  },
  linkStylesForSites: {
    fontFamily: "Montserrat !important",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "24px",
    display: "inline",
    alignItems: "center",
    color: "#545B65",
    paddingLeft: 12,
  },
  linkStylesActive: {
    fontFamily: "Montserrat !important",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "24px",
    display: "inline",
    alignItems: "center",
    color: "#ffffff",
    paddingLeft: 12,
  },
  listItemRoot: {
    width: "196px !important",
    height: "48px",
  },
  linkStylesBottom: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "24px",
    display: "inline",
    alignItems: "center",
    color: "#545B65",
    paddingLeft: 12,
  },
  linkStylesActiveBottom: {
    fontFamily: "Montserrat !important",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    lineHeight: "24px",
    display: "inline",
    alignItems: "center",
    color: "#ffffff",
    paddingLeft: 12,
  },
  Version: {
    color: "#545B65",
    fontSize: 12,
    fontFamily: "Montserrat",
    letterSpacing: "normal",
    fontWeight: "500",
    marginLeft: "38px",
  },
  logoDisplay: {
    "@media only screen and (max-height: 700px)": {
      display: "none",
    },
    backgroundColor: "#FFFFFF",
  },
  defSidebar: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
  },
  languageLinkStyles: {
    display: "flex",
    justifyContent: "center",
  },
  languageTooltipOption: {
    position: "relative",
    zIndex: "100",
  },
  myProfileStyle: {
    display: "flex",
    justifyContent: "center",
  },
  outerMenu: {
    padding: "0px 16px 16px 16px",
    marginLeft: "223px",
  },
  sidebarOpenProfileStyles: {
    borderRadius: 36,
    width: 24,
    height: 24,
    textAlign: "center",
    alignItems: "center",
    display: "grid",
    textTransform: "uppercase",
  },
  outerMenuClosedSB: {
    padding: "0px 16px 16px 16px",
    marginLeft: "75px",
  },
  logoutButtonStyles: {
    display: "flex",
    justifyContent: "center",
  },
  outerMenuHeading: {
    color: "#536471",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "20px",
  },
  proLabel: {
    color: "#C5C9D0",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "10px",
    lineHeight: "20px",
    margin: "5px 0 10px 0",
    "@media(max-width : 1400px)": {
      marginTop: -6,
    },
  },
  languageText: {
    background: "black",
    color: "#ffffff",
    position: "absolute",
    left: "60px",
    top: "8px",
    zIndex: "100",
    width: "130px",
    padding: "10px 5px",
    borderRadius: "4px",
  },
  sidebarOpen: {
    height: "100%",
    maxHeight: "100vh",
    overflow: "auto",
  },
  sidebarOpenSkeleton: {
    marginLeft: "10px",
    borderRadius: "8px",
    backgroundColor: "grey",
    marginTop: 0,
  },
  zeitviewLogo: {
    height: 32,
    marginTop: 15,
    paddingBottom: 5,
  },
  appVersion: {
    color: "#545B65",
    fontSize: 12,
    fontFamily: "Montserrat",
    letterSpacing: "normal",
    fontWeight: "500",
  },
  versionText: {
    marginBottom: 10,
  },
  sideberCloseStyle: {
    height: "100%",
    position: "fixed",
    backgroundColor: "#FFFFFF",
    transition: "0.3s",
    maxHeight: "100vh",
    overflow: "auto",
  },
  sidebarOpenSkeleton2: {
    borderRadius: "8px",
    marginLeft: "30px",
    marginTop: "-5px",
    backgroundColor: "grey",
  },
};
