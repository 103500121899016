import { Grid, Hidden } from "@material-ui/core";

import AnomaliesChartCard from "../common/anomaliesChartCard";
import AnomaliesVsSections from "./AnomaliesVsSections";
import CompareInsp from "../../assets/img/compareInsp.png";
import CustomButton from "../common/customStyle/Button";
import DownloadFile from "../common/downloadFile/DownloadFileNew";
import InspectionSummary from "./InspectionSummary";
import MetadataBox from "../common/MetadataBox/index";
import { NavLink } from "react-router-dom";
import PowerLoss from "../dashboard/powerLoss";
import ProLabel from "../common/ProLabel";
import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import moment from "moment";
import styles from "./siteSummary.style";
import { withStyles } from "@material-ui/core/styles";

//Inspection table
class InspTab extends React.Component {
  state = {
    selectedInspectionDate: this.props.metadataDetails
      ? this.props.metadataDetails.inspection_list[0].inspection_date
      : null,
  };

  //These functions are called whenever component props or state changes
  componentDidUpdate() {
    //Update the data when the site meta-details are changed (keeping inspection date default)
    if (
      this.props.metadataDetails &&
      this.props.metadataDetails.inspection_list &&
      !this.state.selectedInspectionDate
    ) {
      const metadataDetails = _.orderBy(
        this.props.metadataDetails.inspection_list,
        "inspection_date",
        "desc"
      );

      this.setState({
        selectedInspectionDate: metadataDetails[0].inspection_date,
      });
    }
  }

  //Handles change of the inspection date
  handleInspDate = (event) => {
    const getObj =
      this.props.metadataDetails.inspection_list &&
      this.props.metadataDetails.inspection_list.find((listData) => {
        return (
          moment(listData.inspection_date).format("D MMM, YYYY") ===
          event.target.value
        );
      });

    this.setState({ selectedInspectionDate: getObj.inspection_date });
    this.props.redirectInspDate(getObj);
  };

  //This renders the inspection table
  render() {
    const { classes, metadataDetails } = this.props;

    const inspDateList = [];
    // Dropdown object for Inspection List
    if (metadataDetails) {
      metadataDetails.inspection_list.map((objInsp) => {
        return inspDateList.push({
          name: moment(objInsp.inspection_date).format("D MMM, YYYY"),
          value: moment(objInsp.inspection_date).format("D MMM, YYYY"),
          id: moment(objInsp.inspection_date).format("D MMM, YYYY"),
        });
      });
    }

    return (
      <>
        <Grid
          container
          alignItems="center"
          className={`${classes.btnBox} ${classes.justifyCon}`}
        >
          {metadataDetails && (
            <NavLink to={`/defects/${metadataDetails.plant_id}`}>
              <Grid className={classes.viewAnomaly}>
                <CustomButton
                  text={"View Anomalies"}
                  intercom="view-site-anomalies-button"
                />
              </Grid>
            </NavLink>
          )}

          {metadataDetails && (
            <Hidden smDown>
              {this.props.product?.product_type === "Basic" ? (
                metadataDetails.inspection_list.length !== 1 && (
                  <div style={{ position: "relative", marginLeft: 20 }}>
                    <CustomButton
                      text={"Compare Inspections"}
                      intercom="compare-inspections-button"
                      onClick={() => {
                        return this.props.onHandleShowPlans(true);
                      }}
                      btnIcon={CompareInsp}
                    />
                    <div className={classes.proCompare}>
                      <ProLabel marginRight="0" marginLeft="5px" show />
                    </div>
                  </div>
                )
              ) : (
                <NavLink
                  to={{
                    pathname: `/inspectionComp/${metadataDetails.plant_id}`,
                    state: { component: "SITE" },
                  }}
                >
                  {metadataDetails.inspection_list.length !== 1 && (
                    <div style={{ position: "relative", marginLeft: 15 }}>
                      <CustomButton
                        text={"Compare Inspections"}
                        intercom="compare-inspections-button"
                        btnIcon={CompareInsp}
                        type={"cancel"}
                        margin={5}
                      />
                      <div className={classes.proCompare}>
                        <ProLabel marginRight="0" marginLeft="5px" show />
                      </div>
                    </div>
                  )}
                </NavLink>
              )}
            </Hidden>
          )}

          {this.props.metadataDetails &&
          this.props.metadataDetails.downloads_list &&
          this.props.metadataDetails.downloads_list.length ? (
            <DownloadFile
              downloadList={this.props.metadataDetails.downloads_list}
            />
          ) : null}
        </Grid>

        <Grid className={classes.outerPadd}>
          <MetadataBox
            userDetails={this.props.metadataDetails}
            anomaliesSeverityData={this.props.anomalies_by_severity_top}
            anomaliesStatusData={this.props.anomalies_by_status_top}
            anomaliesIECData={this.props.anomalies_by_iec_top}
            onHandleShowPlans={this.props.onHandleShowPlans}
            // fromComp={"sites"}
          />
        </Grid>
        <Hidden smDown>
          <PowerLoss
            applyLossesFilter={this.props.applyLossesFilter}
            data={this.props.lossChartData}
            filterOptions={this.props.lossFilterOptions}
            component={"sites"}
            metadata={this.props.metadataDetails}
          />
        </Hidden>

        <Grid container className={classes.anoOuter}>
          <Grid item md={8} sm={12} xs={12} className={classes.pad12}>
            <AnomaliesChartCard
              anomalies_metrics={this.props.anomalies_metrics}
              anomalies_metrics_href={this.props.anomalies_metrics_href}
              anomalies_metrics_filter_options={
                this.props.anomalies_metrics_filter_options
              }
              onHandleShowPlans={this.props.onHandleShowPlans}
              compName="inspectionTab"
              cardHeight={440}
              chartHeight={320}
              selectedFilterChips={this.props.selectedAnomaliesFilterChips}
              selectedChips={this.props.selectedAnomaliesChips}
            />
          </Grid>
          <Grid item xs={12} md={4} sm={12} className={classes.pad12}>
            <InspectionSummary metadataDetails={this.props.metadataDetails} />
          </Grid>
        </Grid>

        <Grid container className={classes.secCard}>
          <Grid
            item
            xs={12}
            className={`${classes.pad12} ${classes.scrollBar}`}
            style={{ overflowX: "hidden" }}
          >
            <AnomaliesVsSections
              anomalies_by_status={this.props.anomalies_by_status}
              anomalies={this.props.anomalies}
              product={this.props.product}
              selectedChips={this.props.selectedChips}
              selectedFilterChips={(v) => {
                return this.props.selectedFilterChips(v);
              }}
              onHandleShowPlans={this.props.onHandleShowPlans}
              anomalies_filter_options={this.props.anomalies_filter_options}
              anomalies_by_status_filter_options={
                this.props.anomalies_by_status_filter_options
              }
              applyBenchmarkFilter={this.props.applyBenchmarkFilter}
            />
          </Grid>
        </Grid>
      </>
    );
  }
}

//Calling redux to get the product details (pro/basic)
const mapStateToProps = (state) => {
  return {
    product: state.product,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(InspTab));
