import leftArrow from "../../components/assets/img/leftArrow.svg";
import rightArrow from "../../components/assets/img/right.svg";

const styles = {
  title: {
    fontFamily: "Montserrat !important",
    fontSize: 20,
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.2,
    letterSpacing: "normal",
    color: "#21282d",
    marginLeft: 10,
    marginRight: 10,
    "@media only screen and (min-width: 980px)": {
      marginRight: 30,
    },
  },
  marMob: {
    "@media only screen and (max-width: 1024px)": {
      marginLeft: 40,
      marginTop: 10,
      marginBottom: 12,
    },
  },
  padd10R20: {
    padding: "2px 10px",
  },
  titleText: {
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "20px",
    color: "#536471",
  },
  boxText: {
    fontStyle: "normal",
    letterSpacing: "normal",
    fontFamily: "Montserrat",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "20px",
  },
  dropdownBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "8px",
  },
  dropdown: {
    fontStyle: "normal",
    letterSpacing: "normal",
    fontFamily: "Montserrat",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "24px",
  },
  dropdownMenu: {
    width: "206px",
    // height: "154px",
    padding: "0px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "16px",
    cursor: "default",
    marginTop: "100px",
  },
  dropdownMenu2: {
    width: "206px",
    // height: "154px",
    padding: "0px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "16px",
    cursor: "default",
    marginTop: "85px",
  },
  dropdownDiv: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    // padding: "0px 16px",
  },
  menuItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    gap: "8px",
    height: "32px",
    cursor: "pointer",
    padding: "0px 16px",

    "&:hover": {
      background: "#EFF0F2",
    },
  },
  faultNo: {
    fontFamily: "Montserrat",
    fontSize: 12,
    fontWeight: 500,
    fontStyle: "normal",
    textTransform: "capitalize",
    color: "#1D2D47",
    marginRight: 12,
  },
  anomalyCoordinate: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontSize: 12,
    fontWeight: 500,
    color: "#1D2D47",
    marginRight: 12,
    lineHeight: "15px",
  },
  anomalyLabel: {
    fontFamily: "Montserrat",
    fontSize: 12,
    fontWeight: 500,
    fontStyle: "normal",
    color: "#1D2D47",
    marginRight: 12,
    lineHeight: "20px",
    textTransform: "lowercase",
    "&:first-letter": {
      textTransform: "uppercase",
    },
  },
  anomalyId: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontSize: 12,
    fontWeight: 500,
    color: "#1D2D47",
    lineHeight: "15px",
  },
  titleBtn: {
    fontSize: 12,
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.67,
    letterSpacing: "normal",
    color: "#536471",
    padding: "6px 9px",
    borderRadius: 6,
  },
  imgIconBtn: {
    color: "#536471",
    padding: "9px 0",
    maxWidth: "50px",
    minWidth: "40px",
    borderRadius: 6,
  },
  btnImg: {
    height: 12,
    width: 13,
    marginTop: 0,
    marginLeft: 4,
    marginRight: 4,
  },
  proCompare: {
    top: 0,
    position: "absolute",
    transform: "rotate(-40deg)",
    left: "-10px",
  },
  paddAlign: {
    padding: "15px 0px 10px 0px",
  },
  closeIcon: {
    cursor: "pointer",
    color: "#536471",
  },
  filterDrawer: {
    width: "100%",
    maxWidth: 700,
    bottom: 0,
    "@media only screen and (max-width: 720px)": {
      marginTop: 0,
      height: "100%",
    },
  },
  filterArea: {
    marginBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    "@media only screen and (max-width: 720px)": {
      overflow: "auto",
    },
  },
  filterSecond: {
    paddingTop: 5,
    // marginLeft: -20,
    "@media only screen and (max-width: 600px)": {
      // paddingLeft: 30,
      // paddingRight: 30,
      paddingTop: 10,
      borderTop: "1px solid #e0e0e0",
    },
  },
  marB20: {
    marginBottom: 20,
  },
  checkLabel: {
    fontSize: 12,
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "20px",
    letterSpacing: "normal",
    color: "#536471",
  },
  commentedLabel: {
    fontSize: 12,
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "20px",
    letterSpacing: "normal",
    color: "#21282d",
  },
  fltBtn: {
    color: "#536471",
    background: "#FFFFFF",
    border: "1px solid #E4EAF5",
    borderRadius: 5,
    fontSize: 12,
    marginLeft: 0,
    height: 32,
    paddingLeft: 6,
    paddingRight: 6,
  },
  subText: {
    fontSize: 12,
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "20px",
    letterSpacing: "normal",
    color: "#21282d",
    marginBottom: 5,
  },
  marT25: {
    marginTop: 25,
  },
  sliderBox: {
    height: 40,
    width: 40,
    border: "1px solid #e4eaf5",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    fontSize: 12,
    margin: 5,
    fontWeight: "normal",
    borderRadius: 5,
  },
  sliderPoint: {
    height: 27,
    width: 27,
    backgroundColor: "rgb(255, 255, 255)",
    border: "1px solid currentcolor",
    marginTop: -8,
    marginLeft: -13,
    boxShadow: "rgb(235, 235, 235) 0px 2px 2px",
  },
  sliderLine: {
    height: 10,
  },
  height10: {
    height: 10,
  },
  trackBgcolor: {
    backgroundColor: "red !important",
  },
  railBgcolor: {
    backgroundColor: "#ccc !important",
  },
  applyBtn: {
    background: "linear-gradient(266.69deg, #59AFFF 0.23%, #0882F3 99.28%)",

    borderRadius: 8,
    backgroundColor: "#005aff",
    color: "#fff",
    width: "auto",
    marginLeft: 30,
  },
  plantName: {
    fontSize: 12,
    fontWeight: 500,
    fontFamily: "Montserrat",
    letterSpacing: "normal",
    lineHeight: "16px",
    pointerEvents: "none",
  },
  plantLocation: {
    letterSpacing: "normal",
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "16px",
    opacity: 0.6,
  },
  plantsMenu: {
    background: "#FFFFFF",
    border: "1px solid #F0F4FA",
    boxShadow: "0px 4px 12px rgba(78, 89, 109, 0.05)",
    borderRadius: "5px",
  },
  plantsList: {
    color: "#08001D",
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    "&:hover": {
      backgroundColor: "#F0F4FA",
    },
  },
  selectPlant: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "12px",
    height: "36px",
    width: "fit-content",
    minWidth: 170,
    margin: "16px 0px",
    "& div": {
      display: "flex",
      color: "#08001D",
    },
    "@media only screen and (max-width: 720px)": {
      margin: 0,
      marginLeft: 5,
      maxWidth: 210,
    },
  },
  selectedList: {
    color: "#5B4DD3 !important",
    backgroundColor: "#FFFFFF !important",
  },
  blockMenu: {
    background: "#FFFFFF",
    border: "1px solid #DDE5EC",
    boxShadow: "0px 4px 12px rgba(78, 89, 109, 0.05)",
    borderRadius: "8px",
    marginTop: "4px",
  },
  selectBlock: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "12px",
    color: "#536471",
    height: "36px",
    marginTop: 2,
    "& div": {
      marginBottom: 2,
    },
  },
  blockList: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "12px",
    color: "#536471",
    "&:hover": {
      backgroundColor: "#F0F4FA",
    },
  },
  marLR8: {
    marginLeft: 8,
    marginRight: 8,
  },
  filterBtn: {
    minWidth: "auto",
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    borderRadius: 4,
    textTransform: "capitalize",
    padding: 2,
    marginLeft: 20,
    marginRight: 0,
    "@media only screen and (min-width: 1280px)": {
      marginRight: 20,
    },
  },
  addDrawer: {
    height: 20,
    cursor: "pointer",
    margin: "2px 5px 5px",
  },
  bgGray: {
    backgroundColor: "#f3f5fa",
    overflow: "auto",
    padding: "10px 10px 0px 10px",
  },
  defectDrawer: {
    width: 500,
    marginTop: "70px",
    overflow: "visible",
    visibility: "unset !important",
    // backgroundColor: '#f0f4fa'
  },
  bgWhite: {
    fontFamily: "Montserrat",
    backgroundColor: "#fff",
    marginTop: 6,
    borderRadius: 5,
  },
  inputComment: {
    fontFamily: "Montserrat",
    fontSize: 12,
  },
  commentProLabel: {
    transform: "rotate(-40deg)",
    position: "absolute",
    top: "16%",
    right: "21%",
    "@media only screen and (max-width: 960px)": {
      right: "22%",
    },
  },
  defColor: {
    width: 10,
    height: 10,
    borderRadius: 2,
    display: "block",
    marginLeft: 10,
  },
  modeIcon: {
    width: 22,
    marginLeft: "-3px",
  },
  defType: {
    display: "inline-block",
    fontSize: 12,
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "20px",
    letterSpacing: "normal",
    // marginBottom: 4,
    fontFamily: "Montserrat",
    color: "#1D2D47",
  },
  defText: {
    fontSize: 12,
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
    color: "#7e909d",
    marginRight: 15,
    textDecoration: "underline",
  },
  defSelected: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "#f0f4fa",
    zIndex: 101,
  },
  arrowIcon: {
    margin: "auto",
    cursor: "pointer",
    width: 28,
    display: "block",
  },
  squareColor: {
    width: 10,
    height: 10,
    borderRadius: 2,
    marginLeft: 5,
    marginRight: 5,
  },
  detailTitle: {
    display: "flex",
    alignItems: "center",
    marginBottom: 4,
    fontSize: 12,
    fontWeight: 600,
    fontStyle: "normal",
    fontFamily: "Montserrat",
    lineHeight: "20px",
    color: "#1D2D47",
  },
  mobScroll: {
    "@media only screen and (max-width: 720px)": {
      overflow: "auto",
      flexWrap: "nowrap",
    },
  },
  detailImg: {
    // width: 140,
    // height: 140,
    width: "auto",
    height: "auto",
    maxWidth: "120px",
    maxHeight: "120px",
    margin: 10,
    borderRadius: 3,
    cursor: "pointer",
    "@media only screen and (max-width: 720px)": {
      // width: 80,
      // height: 80,
      width: "auto",
      height: "auto",
      maxWidth: "100px",
      maxHeight: "100px",
    },
  },
  marTB10: {
    marginBottom: 10,
    marginTop: 10,
  },
  detailTxt: {
    fontFamilt: "Montserrat",
    fontSize: 12,
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "21px",
    letterSpacing: "normal",
    color: "#536471",
    // '&>span': {
    //   fontWeight: 600
    // }
  },
  detailSpan: {
    fontFamilt: "Montserrat",
    fontSize: 12,
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "21px",
    letterSpacing: "normal",
    color: "#08001D",
  },
  commentFlow: {
    "@media only screen and (min-width: 600px)": {
      overflow: "auto",
    },
  },
  cardRoot: {
    padding: "10px !important",
  },
  msgUser: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: "18px",
    color: "#08001D",
    fontStyle: "normal",
    textTransform: "capitalize",
    marginLeft: 5,
  },
  msgText: {
    fontSize: 12,
    fontWeight: "normal",
    lineHeight: "18px",
    color: "#536471",
    whiteSpace: "pre-line",
    fontStyle: "normal",
    marginTop: 5,
    marginLeft: 5,
  },
  msgTime: {
    fontSize: 11,
    fontWeight: 500,
    lineHeight: "18px",
    color: "#536471",
    fontStyle: "normal",
    textTransform: "capitalize",
    marginRight: 5,
  },
  addText: {
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 1.13,
    color: "#4d4d4d",
    marginTop: 8,
    marginLeft: 4,
  },
  filterChip: {
    margin: 5,
    height: 32,
    borderRadius: 5,
    color: "#536471",
    background: "#0084ff0d",
    size: "60%",
    fontStyle: "normal",
    fontWeight: 500,
    lineWeight: "20px",
    fontStretch: "normal",
    letterSpacing: "normal",
  },
  postButton: {
    fontFamily: "Montserrat",
    color: "#fff",
    borderRadius: 6,
    boxShadow: "0 2px 4px 0 #1b61df19",
    background: "linear-gradient(268.76deg, #5B4DD3 3.61%, #5B4DD3 100%)",
    height: 32,
    "@media only screen and (max-width: 600px)": {
      margin: 0,
    },
  },
  marL8: {
    marginRight: 8,
  },
  fileLabel: {
    width: "100%",
    margin: 0,
    padding: "10px 15px",
    cursor: "pointer",
  },
  fileList: {
    padding: 0,
  },
  fileInput: {
    display: "none",
  },
  mapArea: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: "100%",
    gridColumn: "1",
    gridRow: "1",
    // height: window.innerHeight - 72,
  },
  staticMapArea: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    gridColumn: "1",
    gridRow: "1",
    width: "100%",
  },
  bigMark: {
    width: 30,
    height: 58,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    borderRadius: 20,
    position: "absolute",
    top: "-16px",
    left: "0px",
  },
  infoIcon: {
    color: "#fff",
    position: "absolute",
    left: 15,
    top: 7,
    cursor: "pointer",
    width: 22,
    height: 30,
  },
  recenterIcon: {
    position: "absolute",
    left: 35,
    top: 2,
    cursor: "pointer",
    borderRadius: "8px",
    background: "#FFFFFF",
    border: "1px solid #F0F4FA",
    boxShadow: "0px 4px 12px rgba(78, 89, 109, 0.05)",
    fontSize: "12px",
  },
  recenterIconmobile: {
    position: "absolute",
    height: "30px",
    width: "30px",
    backgroundColor: "#fff",
    right: 12,
    top: 62,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
  },
  layerDiv: {
    textAlign: "left",
    marginLeft: "5px",
  },
  layerIcon: {
    size: "14px",
    outline: "none",
  },
  layersBtn: {
    position: "absolute",
    right: "5px",
    top: 86,
    cursor: "pointer",
    backgroundColor: "#fff",
    borderRadius: "5px",
    // height: "auto",
    minWidth: "30px",
    // width: "8px",
    border: "none",
    outline: "none",
    "&:hover": {
      backgroundColor: "#fff",
    },
    "@media only screen and (min-width: 600px)": {
      display: "none",
    },
  },
  layersBtnImageMap: {
    position: "absolute",
    cursor: "pointer",
    backgroundColor: "#fff",
    borderRadius: "5px",
    // height: "auto",
    minWidth: "30px",
    width: "36px",
    height: "36px",
    border: "none",
    outline: "none",
    "&:hover": {
      backgroundColor: "#fff",
    },
    "@media only screen and (min-width: 600px)": {
      // display: "none",
    },
  },
  toolBox: {
    position: "absolute",
    top: 50,
    left: 10,
    backgroundColor: "#fff",
    width: "auto",
    borderRadius: "5px",
    border: "1px solid #E4EAF5",
    boxShadow: "0px 4px 12px rgba(78, 89, 109, 0.05)",
  },
  filterTitle: {
    display: "inline-block",
    fontSize: 12,
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "20px",
    letterSpacing: "normal",
    color: "#536471",
    marginLeft: 10,
    marginTop: 8,
    fontFamily: "Montserrat",
  },
  clearText: {
    fontSize: 13,
    fontWeight: 500,
    lineHeight: 1.67,
    color: "#5B4DD3",
    textDecoration: "underline",
    marginLeft: 12,
    marginTop: 6,
    cursor: "pointer",
  },
  panelRoot: {
    position: "fixed",
    bottom: 0,
    right: 0,
    left: 0,
    maxHeight: 90,
    overflow: "auto",
    zIndex: 2,
    backgroundColor: "#f0f4fa",
  },
  panelDetail: {
    padding: 0,
    flexDirection: "column",
  },
  expHeader: {
    minHeight: "24px !important",
  },
  expContent: {
    margin: "10px 0px !important",
  },
  maxIcon: {
    position: "absolute",
    left: 0,
    right: 0,
    top: -5,
    margin: "auto",
    color: "#376eff",
    fontSize: 50,
  },
  minIcon: {
    position: "fixed",
    left: 0,
    right: 0,
    top: "9%",
    margin: "auto",
    color: "#376eff",
    fontSize: 50,
    zIndex: 1,
  },
  filterText: {
    fontSize: 14,
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "20px",
    letterSpacing: "normal",
    color: "#21282d",
  },
  siteSelect: {
    minWidth: 170,
    // maxWidth: 170,
    // marginLeft: 4,
    "@media only screen and (max-width: 600px)": {
      marginBottom: 5,
    },
  },
  filterMob: {
    position: "absolute",
    zIndex: 1,
    width: 40,
    height: 40,
    right: 5,
    top: 58,
  },
  filterLabel: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    textTransform: "capitalize",
    color: "#536471",
  },
  secMar: {
    "@media only screen and (min-width: 760px)": {
      marginLeft: 8,
      marginRight: 16,
    },
    "@media only screen and (max-width: 760px)": {
      marginBottom: 5,
    },
  },
  inspMar: {
    marginLeft: 6,
    marginRight: 6,
    "@media only screen and (max-width: 760px)": {
      marginRight: 6,
      maxWidth: 100,
    },
  },
  defSideHead: {
    padding: 6,
    borderLeft: "1px solid #E4EAF5",
  },
  tabLabel: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    textTransform: "capitalize",
    outline: 0,
    color: "#536471",
    minWidth: "50px",
  },
  marTB12: {
    marginTop: 12,
    marginBottom: 12,
  },
  commBg: {
    padding: "10px 20px",
    height: "100%",
    backgroundColor: "#F0F4FA",
  },
  commCard: {
    margin: "10px 0",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.05)",
    borderRadius: 5,
  },
  uploadTypeImg: {
    width: 18,
    height: 16,
    margin: 0,
    marginLeft: "-5px",
    marginRight: 5,
  },
  postLabel: {
    fontFamily: "Montserrat",
    opacity: 0.6,
  },
  listBg: {
    marginBottom: 10,
    borderRadius: 4,
    backgroundColor: "#fff",
  },
  secListHeader: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 12,
    lineHeight: "18px",
    textTransform: "capitalize",
    color: "#536471",
    padding: "7px 20px",
    borderTop: "0px solid #E4EAF5",
    borderBottom: "1px solid #E4EAF5",
    marginTop: 10,
  },
  listDefTxt: {
    // borderLeft: "3px solid #F3F5FA",
    paddingLeft: 15,
  },
  iconMove: {
    right: 2,
  },
  padd0: {
    padding: 0,
  },
  btnView: {
    position: "absolute",
    bottom: 25,
    left: 125,
    textTransform: "capitalize",
    borderRadius: 5,
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
  },
  mapBtn: {
    margin: 0,
    color: "#000",
    borderRadius: 5,
    height: 40,
    boxShadow: "0 1px 2px 0 #0000000c",
    fontSize: 12,
    outline: "none",
  },
  arrowDrawer: {
    position: "absolute",
    left: "-30px",
    top: 10,
    background: "#FFFFFF",
    border: "1px solid #E4EAF5",
    boxSizing: "border-box",
    borderRadius: 4,
    transform: "matrix(-1, 0, 0, 1, 0, 0)",
    height: 67,
    width: 30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  detailArea: {
    padding: 24,
    background: "#F3F5FA",
    "@media only screen and (min-width: 720px)": {
      overflow: "auto",
    },
  },
  detailsBox: {
    margin: "16px 0px",
    background: "#FFFFFF",
    border: "1px solid #DDE5EC",
    borderRadius: 8,
    "& > div": {
      minHeight: 40,
      // padding: "10px 20px",
      paddingLeft: 10,
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 12,
      lineHeight: "20px",
      color: "#536471",
      alignItems: "center",
      display: "flex",
    },
  },
  detailsBoxNew: {
    margin: "16px 0px",
    background: "#FFFFFF",
    border: "1px solid #DDE5EC",
    borderRadius: 8,
    "& > div": {
      minHeight: 40,
      // borderBottom: "1px solid #DDE5EC",
      // padding: "10px 20px",
      // paddingLeft: 10,
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 12,
      lineHeight: "20px",
      color: "#536471",
      alignItems: "center",
      display: "flex",
    },
  },
  headingText: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 12,
    lineHeight: "20px",
    color: "#536471",
  },
  editDetailsBox: {
    display: "block",
    margin: "0px",
  },
  detail: {
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "20px",
    letterSpacing: "0px",
    color: "#1D2D47",
    minHeight: 40,
    alignItems: "center",
    display: "flex",
    paddingRight: 15,
    borderLeft: "1px solid #DDE5EC",
    justifyContent: "flex-end",
  },
  txtField: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "500",
    color: "#08001D",
    "@media only screen and (max-width: 720px)": {
      borderBottom: "1px solid black",
    },
  },
  selectedTab: {
    textTransform: "capitalize",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    lineHeight: "20px",
    outline: "none",
    color: "#08001D",
    minWidth: "50px",
  },
  panelDetailsValue: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "20px",
    color: "##536471",
  },
  indicatorColor: {
    background: "#5B4DD3",
  },
  underline: {
    color: "black",
    "&::after": {
      transition: "none",
      border: "none",
    },
  },
  closeButton: {
    "&:focus": {
      outline: "none !important",
    },
    "&:before": {
      position: "fixed",
    },
    // right: "-101px !important",
    zIndex: 1,
    backgroundColor: "rgb(0,0,0,0)",
    color: "#F0F4FA !important",
    right: "5px !important",
    display: "table",
    opacity: 0.5,
    top: "17px !important",
    padding: "6px !important",
    width: "48px !important",
    // height: "48px !important",
    minWidth: "48px !important",
    position: "fixed",
    "& span": {
      marginRight: "0px !important",
      marginLeft: "0px !important",
      width: "20px !important",
      height: "20px !important",
    },
    "&:hover": {
      backgroundColor: "rgb(0,0,0,0)",
    },
    "@media only screen and (max-width: 768px)": {
      // right: "0px !important",
      // top: "-500px !important",
      // display: "none"
    },
  },
  carouselRoot: {
    backgroundColor: "rgb(8,0,29,0.9)",
    // "& :first-child": {
    //   "& :first-child": {
    //     "& :first-child": {
    //       "& div": {
    //         "& :nth-child(2)": {
    //           position: "fixed",
    //         },
    //       },
    //     },
    //   },
    // },
    "& :nth-child(3)": {
      maxWidth: "unset !important",
      position: "fixed",
      width: "100%",
      maxHeight: "100%",
      height: "100%",
      "@media only screen and (max-width: 768px)": {
        width: "100%",
      },
      "& :nth-child(2) + div": {
        // backgroundColor: "rgb(8,0,29,1)",
        position: "absolute",
        top: "0px",
        marginLeft: "-15px",
        width: "100%",
        height: "72px",
        // marginTop: "16px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 0,
        "& :first-child span": {
          display: "none",
        },
        "& :last-child span": {
          display: "none",
        },
        "& :first-child": {
          backgroundImage: `url(${leftArrow})`,
          backgroundRepeat: "no-repeat",
          position: "initial",
          top: "0px",
          backgroundColor: "rgb(0,0,0,0)",
          color: "#F0F4FA",
          backgroundPosition: "center",
          // height: "40px !important",
          "&:focus": {
            outline: "none !important",
          },
        },
        "& :last-child": {
          backgroundImage: `url(${rightArrow})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: "rgb(0,0,0,0)",
          position: "initial",
          top: "0px",
          color: "#F0F4FA",
          backgroundPosition: "center",
          // height: "40px !important",
          "&:focus": {
            outline: "none !important",
          },
        },
      },
    },
    "& div": {
      "&:first-child": {
        boxShadow: "none !important",
      },
    },
  },
  imageTitle: {
    position: "fixed",
    top: "37px",
    marginLeft: "20px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "24px",
    color: "#FFFFFF",
  },
  headerDetail: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    lineHeight: "20px",
    color: "#08001D",
    minHeight: 40,
    display: "flex",
    alignItems: "center",
    paddingLeft: 15,
    borderLeft: "1px solid #DDE5EC",
  },
  popupContainer: {
    position: "fixed",
    top: 0,
    left: 0,
    height: "100vh",
    width: "100vw",
    background: "rgba(0,0,0,0.5)",
    zIndex: 1300,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  popupItem: {
    background: "white",
    borderRadius: "8px",
    maxWidth: "653px",
    height: "98vh",
  },
  proofUploadPopup: {
    background: "white",
    borderRadius: "8px",
    maxWidth: 700,
  },
  dropField: {
    height: "91px",
    border: "1px dashed #8C9AA4",
    borderRadius: "8px",
    padding: "2rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "1rem 0",
  },
  fileInputField: {
    border: "1px solid #C7CEDB",
    borderRadius: "8px",
    marginBottom: "20px",
    padding: "0 10px",
  },
  filesAndFolderSelections: {
    border: "1px solid #C7CEDB",
    borderRadius: "8px",
    marginBottom: "20px",
    "& > div:not(:last-child)": {
      borderBottom: "1px solid #C7CEDB",
    },
  },
  selectLink: {
    color: "#5B4DD3",
    cursor: "pointer",
  },
  reviewButton: {
    background: "linear-gradient(266.69deg, #59AFFF 0.23%, #0882F3 99.28%)",
    borderRadius: "8px",
    MaxWidth: "157px",
    height: "36px",
  },
  popUpTitle: {
    fontSize: "14px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "24px",
    letterSpacing: "normal",
    color: "#536471",
    fontFamily: "Montserrat",
    fontWeight: "600",
  },
  tableText: {
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
    fontFamily: "Montserrat",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "20px",
    color: "#08001D",
    whiteSpace: "nowrap",
    width: "auto",
  },
  tableInput: {
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
    fontFamily: "Montserrat",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "20px",
    color: "#08001D",
    whiteSpace: "nowrap",
    // width: "auto",
    padding: "3px 7px",
    height: 30,
    width: 120,
  },
  tableCell: {
    background: "#FBFCFF",
    borderRight: "1px solid #E4EAF5",
    padding: 10,
  },
  errorIcon: {
    color: "#F84343",
  },
  addBorder: {
    "&:hover": {
      border: "1px solid #ccc",
      borderRadius: "4px",
    },
    cursor: "pointer",
  },
  searchBar: {
    width: 300,
    height: "40px",
    background: "#FFFFFF",
    border: "1px solid #DDE5EC",
    borderRadius: "8px",
    display: "flex",
    alignContent: "center",
    justifyContent: "space-between",
  },
  toolBar: {
    margin: "15px 12px",
    padding: "0px 10px 0px 10px",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  input: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "20px",
    border: "none",
    width: "83%",
    // marginLeft: "17.04px",
  },
  iconButton: {
    marginLeft: "20px",
    width: "10px",
    height: "16px",
    color: "#A8B9C6",
    alignSelf: "center",
  },
  sortBar: {
    "@media only screen and (max-width: 700px)": {
      marginTop: "10px",
      marginLeft: "50px",
    },
  },
  formControl: {
    width: "240px",
    height: "40px",
    background: "#FFFFFF",
    border: "1px solid #DDE5EC",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  selectEmpty: {
    position: "relative",
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "12px",
    color: "#08001D",
    width: "100%",
    height: "15px",
    "& svg": {
      display: "none",
    },
    "& div": {
      height: "28px",
      alignItems: "center",
      display: "flex",
      backgroundColor: "rgba(0,0,0,0)",
      color: "#08001D",
    },
    "& :focus": {
      backgroundColor: "rgba(0,0,0,0)",
    },
  },
  pad12: {
    padding: 12,
  },
  contentMob: {
    paddingBottom: 12,
    "@media only screen and (max-width: 600px)": {
      padding: "12px 8px",
    },
  },
  tableHeader: {
    fontStyle: "normal",
    letterSpacing: "normal",
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: 12,
    lineHeight: "20px",
    color: "#536471",
    whiteSpace: "nowrap",
  },
  tableCard: {
    background: "#FFFFFF",
    boxShadow: "0px 4px 12px rgba(78, 89, 109, 0.05)",
    borderRadius: 8,
  },
  searchBottomBox: {
    "&:hover": {
      "&::before": {
        borderBottom: "none !important",
      },
    },
  },
  tablePadd: {
    padding: "24px 24px 0",
    width: "-webkit-fill-available",
    "@media only screen and (max-width: 760px)": {
      padding: "10px 10px 0px",
    },
    "@media only screen and (max-width: 1400px)": {
      padding: "10px 10px 0px",
      position: "absolute",
    },
  },
  tooltipInfo: {
    maxWidth: "149px",
    color: "#ffffff",
    background: "#08001D",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
    borderRadius: "5px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "11px",
    lineHeight: "16px",
  },
  recenterTooltip: {
    maxWidth: "149px",
    color: "#ffffff",
    background: "#08001D",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
    borderRadius: "5px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "11px",
    lineHeight: "16px",
    position: "absolute",
    top: 35,
    left: 40,
  },
  styleChangeBtn: {
    position: "absolute",
    bottom: "25px",
    left: "65px",
    boxShadow: "0px 2px 4px rgba(78, 89, 109, 0.15)",
    borderRadius: "8px",
    border: "1px solid #FFFFFF",
    outline: "none !important",
    height: "48px",
    width: "48px",
    margin: "0",
    padding: "0",
    "@media only screen and (max-width: 960px)": {
      position: "absolute",
      right: "10px",
      left: "auto",
      top: "15px",
      width: 35,
      height: 35,
    },
  },
  tempInfo: {
    display: "flex",
    flexDirection: "row",
    padding: 10,
    justifyContent: "space-between",
    alignItems: "center",
  },
  tempInfoText: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "11px",
    lineHeight: "16px",
    display: "flex",
    alignTtems: "center",
    color: "#8C9AA4",
  },
  scanButton: {
    position: "relative",
    color: "#0084FF",
    display: "flex",
    margin: "3px",
    width: "140px",
    justifyContent: "space-evenly",
  },
  barcodeBackDrop: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    background: "rgba(0,0,0,0.5)",
    zIndex: 100000,
  },
  barcodeModal: {
    width: "504px",
    height: "406px",
    background: "transperant",
    position: "relative",
    "@media only screen and (max-width: 500px)": {
      width: "90%",
      margin: "0 auto",
    },
  },
  barcodeModalContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "8px 72px",
  },
  scanTitle: {
    margin: "20px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "20px",
    color: "#ccc",
  },
  barcodeCloseIcon: {
    position: "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer",
    color: "#ccc",
    fontWeight: "bold",
  },
  inputAdornment: {
    backgroundColor: "#F0F4FA",
    color: "#536471",
    "& .MuiTypography-colorTextSecondary-287": {
      color: "red",
    },
  },
  "@media only screen and (max-width: 500px)": {
    top: "20px",
  },
  linkIcon: {
    height: 19,
    width: 14,
    marginLeft: 6,
    marginTop: 4,
  },
  proofArea: {
    border: "1px solid #DDE5EC",
    borderRadius: 8,
    minHeight: 100,
    padding: 12,
    marginTop: 5,
    marginBottom: 20,
  },
  proofBtn: {
    background: "linear-gradient(266.69deg, #59AFFF 0.23%, #0882F3 99.28%)",
    borderRadius: 8,
    width: 120,
    height: 32,
    color: "#fff",
    boxShadow: "none",
    marginLeft: 0,
    marginTop: 8,
  },
  detailText: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    lineHeight: "20px",
    color: "#536471",
  },
  outerFalse: {
    width: 90,
    height: 25,
    border: "1px solid #DDE5EC",
    borderRadius: 8,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 8,
  },

  dialogChips: {
    display: "flex",
    alignContent: "flex-start",
    minHeight: "40px",
    marginTop: "20px",
    "@media only screen and (min-width: 600px)": {
      flexWrap: "wrap",
      alignContent: "flex-start",
      minHeight: "100px",
    },
  },
  dialogActions: {
    padding: "16px 24px",
    "@media only screen and (max-width: 600px)": {
      display: "flex",
      justifyContent: "flex-end",
      flexDirection: "row-reverse",
      alignContent: "flex-start",
    },
  },
  chipStyle: {
    position: "absolute",
    left: "28px",
    top: "16px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "20px",
    /* identical to box height, or 167% */

    display: "flex",
    alignItems: "center",

    /* Secondary / 5 */

    color: "#536471",
  },
  noSelectText: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "20px",
    color: "#536471",
  },
  paperDialog: {
    "@media only screen and (max-width: 600px)": {
      margin: 5,
      width: "100%",
    },
  },
  filterDialogContent: {
    padding: "0",
    display: "flex",
    flexDirection: "row",
    overflow: "auto",
  },
  filterSidebar: {
    width: 240,
    "@media only screen and (max-width: 600px)": {
      width: 120,
    },
  },
  comStyle: {
    fontFamily: "Montserrat",
    fontWeight: "500",
    paddingLeft: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
    color: "inherit",
    "@media only screen and (max-width: 600px)": {
      paddingLeft: 12,
    },
  },
  bulkUploadButtons: {
    border: "1px solid #DDE5EC",
    height: "36px",
    borderRadius: "8px",
    background: "#FFFFFF",
    color: "#536471",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "20px",
    "&:hover": {
      background: "#FFFFFF",
    },
  },
  uploadBlockContainer: {
    width: "400px",
    position: "absolute",
    right: 100,
    zIndex: 2000,
  },
  uploadBlockBlock: {
    height: "56px",
    background: "#1F043F",
    borderRadius: "4px 4px 0px 0px",
    padding: "0px 22px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  notImageBox: {
    width: 140,
    cursor: "pointer",
    height: 140,
    margin: 10,
    borderRadius: 3,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    background: "#F0F4FA",
  },
  btnLabel: {
    pointerEvents: "none",
  },
  ToolTipClass1: {
    height: 6,
    width: 6,
    borderRadius: "50%",
    marginRight: 5,
    backgroundColor: "rgb(197, 33, 125);",
  },
  ToolTipClass2: {
    height: 6,
    width: 6,
    borderRadius: "50%",
    marginRight: 5,
    backgroundColor: "rgb(56, 131, 81)",
  },
  ToolTipClass3: {
    height: 6,
    width: 6,
    borderRadius: " 50%",
    marginRight: 5,
    backgroundColor: "rgb(161, 31, 171)",
  },
  ToolTipClass4: {
    height: 6,
    width: 6,
    borderRadius: "50%",
    marginRight: 5,
    backgroundColor: "rgb(130, 90, 44)",
  },
  panelFooter: {
    position: "fixed",
    width: "100%",
    bottom: 0,
    padding: "5px",
    backgroundColor: "#fff",
    boxShadow: "0px -4px 4px #f0f5f5",
    display: "flex",
    margin: "0 auto",
    overflowX: "hidden",
    "@media (max-width: 600px)": {
      position: "static",
    },
  },
  filterBg: {
    background: "#fff",
    borderTop: "1px solid #E4EAF5",
    minHeight: 48,
  },
  trialLabel: {
    position: "absolute",
    top: 75,
    left: 16,
    transform: "rotate(-40deg)",
  },
  editFooter: {
    position: "fixed",
    width: "100%",
    bottom: 0,
    padding: "20px",
    backgroundColor: "#fff",
    boxShadow: "0px -4px 4px #f0f5f5",
    marginLeft: 0,
    "@media (max-width: 600px)": {
      position: "static",
    },
  },
  zoomButtons: {
    background: "transparent",
    border: "none",
    width: "40px",
    height: "35.16px",
    cursor: "pointer",
  },

  controlsDiv: {
    display: "flex",
    flexDirection: "row",
    background: "#2A1F36",
    position: "absolute",
    top: "10px",
    left: "10px",
    borderRadius: "4px",
    boxShadow: "0px 4px 12px rgba(78, 89, 109, 0.05)",
  },

  filtersName: {
    fontSize: "18px",
    lineHeight: "20px",
    width: "75px",
    fontWeight: "600",
    fontFamily: "Montserrat",
  },

  statusDrpdown: {
    width: "200px",
    textAlign: "right",
  },

  popupClass: {
    padding: "none",
  },

  dot: {
    width: "4px",
    height: "4px",
    fontWeight: 500,
    fontSize: "12px",
    lineHieght: "20px",
    backgroundColor: "#C5C9D0",
    borderRadius: "10px",
    marginRight: "6px",
    marginLeft: "-5px",
  },

  SmallDot: {
    width: "4px",
    height: "4px",
    fontWeight: 500,
    fontSize: "12px",
    lineHieght: "20px",
    backgroundColor: "#C5C9D0",
    borderRadius: "10px",
    marginLeft: "7px",
    marginRight: "-5px",
  },
  anomalieTooltipArrow: {
    color: "#08001D",
  },
};

export default styles;
