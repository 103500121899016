import { Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import CheckBox from "../common/customStyle/CheckBox";
import CustomTooltip from "../common/customStyle/Tooltip";
import RoleSelectDropdown from "./roleSelectDropdown";
import styles from "./user.style";
import { withStyles } from "@material-ui/core/styles";

const MultiSiteAccess = ({
  classes,
  sitesaccess,
  getSiteAccess,
  isViewOption,
  isEditOption,
  isNewUserCreation,
  plantList,
}) => {
  const loggedInUserType = sessionStorage.getItem("userRole");
  const [sitesAccessList, setSitesAccessList] = useState([]);
  const [isGeneralAdminSelected, setIsGeneralAdminSelected] = useState(false);
  const [isCheckedForAllPlants, setIsCheckedForAllPlants] = useState(false);
  const [isInitialDesc, setIsInitialDesc] = useState(true);

  //Insert isChecked key for select, deselect
  useEffect(() => {
    sitesaccess?.map((item) => {
      item["isChecked"] = false;
    });

    // show those sites only for which role is site admin when user is logged in as site:admin.
    if (isNewUserCreation && loggedInUserType === "site:admin") {
      const filteredSitesForSiteAdmin = sitesaccess.filter(
        (item) => item.role === "SITE_ADMIN"
      );
      return setSitesAccessList(filteredSitesForSiteAdmin);
    }

    setSitesAccessList(sitesaccess);
  }, []);

  //handle changed roles
  const handleChangedRole = (plantData) => {
    //set initial description as false after role changed.
    setIsInitialDesc(false);

    //if general admin is selected for any one site then auto select all the sites as a general admin.
    if (plantData.role === "General Admin") {
      sitesAccessList.map((item) => {
        item.isChecked = true;
        item.role = "CLIENT_ADMIN";
        return item;
      });
      setIsCheckedForAllPlants(true);
      setIsGeneralAdminSelected(!isGeneralAdminSelected);
      setSitesAccessList(sitesAccessList);
      getSiteAccess(sitesAccessList);
      return;
    }
    
    //individual site select except general admin.
    sitesAccessList.map((item) => {
      if (plantData.plant_id === item.plant_id) {
        item.role =
          plantData.role === "Site Admin"
            ? "SITE_ADMIN"
            : plantData.role === "User"
            ? "CLIENT_USER"
            : "";
        return item;
      }
    });

    setSitesAccessList(sitesAccessList);
    getSiteAccess(sitesAccessList);
  };

  //update boolean values of checked sites.
  const handleSiteSelect = (e) => {
    const isChecked = e.target.checked;
    const selectedPlantId = e.target.value;

    //enable checkmark after site select.
    const selectedSites = sitesAccessList.map((item) => {
      if (item.plant_id === selectedPlantId) {
        item.isChecked = isChecked;

        //if any only one site is remaining to select. following condition will handle all select as well.
        if (!isChecked) {
          setIsCheckedForAllPlants(false);
        }

        //For General Admin
        //set default role as a user , if no role is selected.
        if (
          item.role !== "CLIENT_USER" &&
          loggedInUserType === "client:admin" &&
          (isNewUserCreation || isEditOption)
        ) {
          item.role = "CLIENT_USER";
        }

        //For Site Admin
        if (
          item.role !== "CLIENT_USER" &&
          loggedInUserType === "site:admin" &&
          (isNewUserCreation || isEditOption)
        ) {
          item.role = "CLIENT_USER";
        }

        return item;
      } else {
        return item;
      }
    });

    //check if all plants are already selected or not
    const isAllReadyChecked = selectedSites.some((item) =>
      Object.values(item).includes(false)
    );

    if (!isAllReadyChecked) {
      setIsCheckedForAllPlants(true);
    }

    setSitesAccessList(selectedSites);
    getSiteAccess(selectedSites);
  };

  //handle All site select and deselect.
  const handleAllSiteSelect = (e) => {
    const list = sitesAccessList.map((item) => {
      if (e.target.checked) {
        item["isChecked"] = true;

        //make default role user when all option is selected
        if (isNewUserCreation && loggedInUserType === "client:admin") {
          item.role = "CLIENT_USER";
        }
        setIsCheckedForAllPlants(true);
        return item;
      } else {
        if (
          (isEditOption &&
            item.role === "CLIENT_USER" &&
            loggedInUserType === "site:admin") ||
          (isEditOption &&
            item.role === "SITE_ADMIN" &&
            loggedInUserType === "site:admin")
        ) {
          item["isChecked"] = true;
        } else {
          item["isChecked"] = false;

          //reset roles to User after deselect
          if (isNewUserCreation && loggedInUserType === "client:admin") {
            item.role = "CLIENT_USER";
          }

          //handle all plants deselect
          setIsCheckedForAllPlants(false);
          setIsGeneralAdminSelected(false);
        }

        return item;
      }
    });

    setSitesAccessList(list);
    getSiteAccess(list);
  };

  //show all the sitesaccess along with already given accessed plants in edit as already checked.
  useEffect(() => {
    if (isEditOption) {
      plantList.sitesAccess.map((item) => {
        item["isChecked"] = false;
      });

      if (loggedInUserType === "site:admin") {
        sitesaccess.map((item) => {
          item.isChecked = true;
        });

        let plantListArr = [...plantList.sitesAccess];
        let siteAccessArr = [...sitesaccess];

        //it will remove duplicates and create array of unique plant_id's
        let newSetForMerged = [
          ...new Set(
            [...siteAccessArr, ...plantListArr].map((item) => item.plant_id)
          ),
        ];

        // Create a new array of unique objects based on plant_id's
        let uniqueMergedArray = newSetForMerged.map((plant_id) => {
          const siteAccessItem = siteAccessArr.find(
            (item) => item.plant_id === plant_id
          );
          const plantListItem = plantListArr.find(
            (item) => item.plant_id === plant_id
          );

          //return whichever item is present.
          return siteAccessItem || plantListItem;
        });

        //This will remove those sites which are of role type USER. and not assigned to user which we're editing.
        let updatedUniqueMergedArray = uniqueMergedArray
          .map((item) => {
            if (!item.isChecked && item.role === "CLIENT_USER") {
              return null;
            } else {
              return item;
            }
          })
          .filter((item) => item !== null);

        //check if all plants are already selected or not
        const isAllReadyChecked = updatedUniqueMergedArray.some((item) =>
          Object.values(item).includes(false)
        );

        if (!isAllReadyChecked) {
          setIsCheckedForAllPlants(true);
        }
        setSitesAccessList(updatedUniqueMergedArray);
        getSiteAccess(updatedUniqueMergedArray);
      } else {
        plantList.sitesAccess.forEach((item) => {
          const isFound = sitesaccess.some(
            (item1) => item1.plant_id === item.plant_id
          );
          if (isFound) {
            const accessedSite = sitesaccess.find(
              (item1) => item1.plant_id === item.plant_id
            );
            item.isChecked = true;
            item.role = accessedSite.role;
          } else {
            item.isChecked = false;
            item.role = "CLIENT_USER";
          }
          return item;
        });
        //check if all plants are already selected or not
        const isAllReadyChecked = plantList.sitesAccess.some((item) =>
          Object.values(item).includes(false)
        );

        if (!isAllReadyChecked) {
          setIsCheckedForAllPlants(true);
          // setIsGeneralAdminSelected(false);
        }
        setSitesAccessList(plantList.sitesAccess);
        getSiteAccess(plantList.sitesAccess);
      }
    }
  }, []);

  return (
    <Grid className={classes.siteAccessCard}>
      <Grid container alignItems="center" className={classes.rowTitle}>
        {!isViewOption && (
          <CheckBox
            checked={isCheckedForAllPlants}
            value={sitesAccessList}
            onChange={handleAllSiteSelect}
          />
        )}
        <Grid container justifyContent="space-between">
          <Typography className={classes.titleStyle}>Site</Typography>
          <Typography className={classes.titleStyle}>User Role</Typography>
          <Typography className={classes.titleStyle}>
            Role Description
          </Typography>
        </Grid>
      </Grid>

      {/* we will use map function here to render all the sites */}
      {sitesAccessList?.map((item) => {
        const notFound = plantList?.sitesAccess.some(
          (item1) => item1.plant_id === item.plant_id
        );

        const getElementPresentInPlantList = plantList?.sitesAccess.find(
          (item1) => item1.plant_id === item.plant_id
        );

        return (
          <>
            <Grid container className={classes.siteDetails} key={item.plant_id}>
              {!isViewOption && (
                <CheckBox
                  disable={
                    //if user role is CLIENT_USER and is present in plant list then disable it in edit option if user logged in as site admin.
                    isEditOption &&
                    item.isChecked &&
                    loggedInUserType === "site:admin" &&
                    !getElementPresentInPlantList?.isChecked &&
                    getElementPresentInPlantList?.role === "CLIENT_USER"
                      ? true
                      : item.role === "SITE_ADMIN" &&
                        item.isChecked &&
                        loggedInUserType === "site:admin" &&
                        notFound &&
                        isCheckedForAllPlants
                      ? false
                      : (isEditOption &&
                          item.role === "CLIENT_USER" &&
                          item.isChecked &&
                          loggedInUserType === "site:admin" &&
                          !notFound) ||
                        (isEditOption &&
                          item.role === "SITE_ADMIN" &&
                          item.isChecked &&
                          loggedInUserType === "site:admin" &&
                          !notFound)
                      ? true
                      : isEditOption &&
                        item.isChecked &&
                        loggedInUserType === "site:admin" &&
                        notFound.role === "CLIENT_USER"
                      ? false
                      : isCheckedForAllPlants && isGeneralAdminSelected
                      ? true
                      : false
                  }
                  checked={item?.isChecked}
                  value={item?.plant_id}
                  onChange={handleSiteSelect}
                />
              )}

              <CustomTooltip
                title={`${item.plant_name},\n${item.location}`}
                placement="bottom-end"
                arrow
              >
                <Grid className={classes.plantInfo}>
                  <Grid className={classes.plantName}>{item.plant_name}</Grid>
                  <Grid className={classes.plantLocation}>{item.location}</Grid>
                </Grid>
              </CustomTooltip>

              <Grid
                container
                justifyContent="space-evenly"
                className={classes.dropdownAndUserMargin}
              >
                <Grid style={{ marginLeft: !isViewOption ? "20px" : "40px" }}>
                  <RoleSelectDropdown
                    plantDetails={item}
                    returnSelectedRole={(plantData) =>
                      handleChangedRole(plantData)
                    }
                    notFound={notFound}
                    loggedInUserType={loggedInUserType}
                    isEditOption={isEditOption}
                    isViewOption={isViewOption}
                    isNewUserCreation={isNewUserCreation}
                    selectedRoleDisplayInView={item.role}
                    selectedValue={
                      isGeneralAdminSelected
                        ? "General Admin"
                        : isEditOption
                        ? item.role === "SITE_ADMIN"
                          ? "Site Admin"
                          : item.role === "CLIENT_ADMIN"
                          ? "General Admin"
                          : "User"
                        : "User"
                    }
                    sitesAccessList={sitesAccessList}
                    variant={"standard"}
                    isCheckedForAllPlants={isCheckedForAllPlants}
                    isGeneralAdminSelected={isGeneralAdminSelected}
                    getElementPresentInPlantList={getElementPresentInPlantList}
                  />
                </Grid>
                <Grid
                  className={classes.normalText}
                  style={{ marginLeft: "auto" }}
                >
                  {isInitialDesc && isNewUserCreation
                    ? "Access only to selected sites."
                    : item.role === "SITE_ADMIN"
                    ? "Access to selected sites, manage users (add/edit/delete) only for selected sites."
                    : item.role === "CLIENT_ADMIN"
                    ? "Access to all sites, manage users (add/edit/delete) for all sites."
                    : "Access only to selected sites."}
                </Grid>
              </Grid>
            </Grid>
          </>
        );
      })}
    </Grid>
  );
};

export default withStyles(styles)(MultiSiteAccess);
