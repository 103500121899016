import {
  GET_PRODUCT_STATUS,
  RESET_PRODUCT_STATUS,
} from "../constants/actionTypes";

//Initial state of the reducer
const initialState = {
  product_type: null,
  remaining_days: null,
};

//Create and export the reducer to get the product status (basic/pro)
export const getProductStatus = (product) => {
  return (dispatch) => {
    dispatch({
      type: GET_PRODUCT_STATUS,
      payload: product,
    });
  };
};

//Create and export the reducer to reset the product status (basic/pro)
export const resetProductStatus = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_PRODUCT_STATUS,
    });
  };
};

//Defining the reducer
// skipcq Default parameters should be defined at the last JS-0106
export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_PRODUCT_STATUS:
      return { ...state, ...payload };
    case RESET_PRODUCT_STATUS:
      return initialState;
    default:
      return state;
  }
};
