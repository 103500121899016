export default {
  card: {
    "@media only screen and (max-width: 600px)": {
      height: "100%",
    },
    borderRadius: 8,
    boxShadow: "0px 2px 10px rgba(78, 89, 109, 0.05)",
    overflow: "visible",
  },
  contentMob: {
    paddingBottom: 12,
    "@media only screen and (max-width: 600px)": {
      padding: "12px 8px",
    },
  },
  Wrap: { display: "flex", flexWrap: "wrap" },
  vectorImg: {
    marginTop: 0,
    marginLeft: 4,
    marginRight: 4,
  },
  donutChartBox: {
    position: "relative",
    height: "100%",
  },
  cancelBtn2: {
    color: "white",
    background: "linear-gradient(266.69deg, #59AFFF 0.23%, #0882F3 99.28%)",
    fontSize: "25px",
    padding: "6px 25px",
    borderRadius: "5px",
  },
  showPlansPopup: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    zIndex: "1",
    display: "flex",
  },
  cardHeading: {
    padding: 0,
    height: "100%",
    "@media only screen and (max-width: 600px)": {
      padding: "12px 8px",
    },
  },
  chartHeading: {
    fontFamily: "Montserrat",
    fontWeight: "600",
    fontStyle: "normal",
    fontSize: "14px",
    textTransform: "uppercase",
    color: "#536471",
    height: 64,
    display: "flex",
    alignItems: "center",
    paddingLeft: 16,
  },
  severityBox: {
    height: 8,
    display: "inline-block",
    width: 8,
    borderRadius: "50%",
  },
  severityText: {
    margin: "0 5px",
    fontFamily: "Montserrat",
    fontSize: 12,
    fontWeight: 500,
    color: "#536471",
    fontStyle: "normal",
  },
  cancelBtn: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    marginRight: "8px",
    color: "#5B4DD3",
    cursor: "pointer",
  },
  inlineTitle: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    paddingRight: "64px",
    "@media only screen and (max-width: 400px)": {
      // paddingRight: "4px !important",
      "& button": {
        marginLeft: "2px !important",
        marginRight: "2px !important",
      },
    },
  },
  filterButton: {
    background: "#FFFFFF",
    border: " 1px solid #DDE5EC",
    boxSizing: "border-box",
    borderRadius: "8px",
    marginRight: "10px",
    "&:focus": {
      outline: "none !important",
    },
  },
  infoIconStyle: {
    color: "#C7CEDB",
    cursor: "pointer",
    margin: "4px 20px",
    "@media only screen and (min-width: 960px)": {
      display: "none",
    },
  },
  proLabel: {
    position: "absolute",
    top: "-20px ",
    left: "52%",
    transform: "rotate(-35deg)",
    zIndex: "2",
    "@media only screen and (max-width: 600px)": {
      top: "-22px ",
    },
  },
  downBtn: {
    color: "#536471",
    padding: "9px 0",
    maxWidth: "40px",
    minWidth: "30px",
    borderRadius: 6,
    outline: "none",
    position: "absolute",
    right: -43,
    top: -20,
    "@media only screen and (max-width: 600px)": {
      top: "-12px ",
    },
  },
  anomaliesTooltip: {
    padding: "10px 10px 10px 16px",
    position: "absolute",
    top: 85,
    right: 28,
    zIndex: 10,
    maxHeight: 400,
    backgroundColor: "#fff",
    width: "auto",
    borderRadius: "5px",
    border: "1px solid #E4EAF5",
    boxShadow: "0px 4px 12px rgba(78, 89, 109, 0.05)",
    overflow: "auto",
    flexWrap: "nowrap",
    "@media only screen and (max-width: 600px)": {
      maxHeight: 300,
    },
  },
  anomaliesTooltipb: {
    padding: "10px 10px 10px 16px",
    position: "absolute",
    top: 52,
    right: 28,
    zIndex: 10,
    maxHeight: 400,
    backgroundColor: "#fff",
    width: "auto",
    borderRadius: "5px",
    border: "1px solid #E4EAF5",
    boxShadow: "0px 4px 12px rgba(78, 89, 109, 0.05)",
    overflow: "auto",
    flexWrap: "nowrap",
    "@media only screen and (max-width: 600px)": {
      maxHeight: 300,
      top: 130,
    },
    // "@media only screen and (maxWidth: 400px)": {
    //   marginTop: "60px",
    // },
  },
  noDividerTable: {
    border: 0,
    padding: "9px 0px",
  },
  chipSec: {
    padding: "0px 12px",
    overflow: "auto",
    whiteSpace: "nowrap",
    display: "block",
    // width: "100%",
    "@media screen and (max-width: 760px)": {
      width: "90%",
    },
  },
  filterChip: {
    background: "rgba(0, 132, 255, 0.05)",
    borderRadius: "8px",
    border: 0,
    color: "#536471",
    padding: "15px 4px",
    margin: "3px 10px 3px 0px",
    fontWeight: 500,
  },
  btnImg: {
    marginRight: "2px",
    marginTop: "-3px",
  },
  anomaliesChartBox: {
    // "@media only screen and (min-width: 800px)": {
    //   "& div": {
    //     marginTop:"-60px",
    //   },
    // },
    "@media only screen and (max-width: 600px)": {
      "& div": {
        height: "320px !important",
      },
    },
  },
  siteChardBox: {
    "@media only screen and (max-width: 600px)": {
      "& div": {
        height: "260px !important",
      },
    },
  },
  spinnerMedia: {
    "@media only screen and (min-width: 959px)": {
      display: "none",
    },
  },
  anomaliesTable: {
    alignSelf: "center",
    maxHeight: 310,
    overflowX: "hidden",
    overflow: "auto",
    padding: 10,
    marginTop: "-20px",
    width: "-webkit-fill-available",
    "@media only screen and (max-width: 959px)": {
      display: "none",
    },
  },
  filterCount: {
    width: "21px",
    height: "21px",
    borderRadius: "8px",
    background: "#5B4DD3",
    marginLeft: "8px",
    color: "white",
  },
  defCount: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "12px",
    fontStyle: "normal",
    color: "#21282d",
  },
  insideOuter: {
    width: "100%",
    display: "grid",
    position: "absolute",
    top: "44%",
    textAlign: "center",
    right: 0,
    lineHeight: 1.2,
    zIndex : -1
  },
  insideCount: {
    fontSize: "28px",
    color: "black",
    fontFamily: "Montserrat",
    fontWeight: 600,
  },
  insideText: {
    fontSize: "12px",
    fontFamily: "Montserrat",
    fontWeight: 500,
    color: "#536471",
    fontStyle: "normal",
  },
  defDataCount: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "12px",
    fontStyle: "normal",
    color: "#21282d",
  },
  filterOuter: {
    position: "absolute",
    right: "26px",
    top: "50px",
    zIndex: 1,
    "@media only screen and (max-width: 600px)": {
      right: "auto",
    },
  },
  filterOuterMin: {
    position: "absolute",
    right: 26,
    top: 50,
    zIndex: 1,
    "@media only screen and (max-width: 600px)": {
      right: "auto",
      top: 106,
    },
  },
};
