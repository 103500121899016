import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";

import Icon from "../../../assets/img/Checkbox.svg";
import IconBorder from "../../../assets/img/CheckboxBorder.svg";
import React from "react";
import { Trans } from "react-i18next";
import roundChecked from "../../../assets/img/roundIconChecked.svg";
import roundIcon from "../../../assets/img/roundIcon.svg";
import { withStyles } from "@material-ui/core/styles";

//checkbox styles
const styles = () => {
  return {
    boxCss: {
      borderRadius: 4,
      boxSizing: "border-box",
      pointerEvents: "none",
    },
    titleText: {
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      fontFamily: "Montserrat",
      fontWeight: "500",
      fontSize: "12px",
      lineHeight: "20px",
      color: "#536471",
      wordWrap: "break-word",
      width: "fit-content",
      pointerEvents: "none",
    },
    formLabel: {
      pointerEvents: "none",
    },
    darkText: {
      color: "#08001D",
    },
  };
};

//custom checkbox
function CheckBox(props) {
  const { classes } = props;
  return (
    <FormControlLabel
      id={props.id}
      classes={{
        label: classes.formLabel,
      }}
      control={
        <Checkbox
          name={props.name}
          value={props.value}
          checked={props.checked}
          onChange={props.onChange}
          className={classes.boxCss}
          disabled={props.disable}
          icon={
            props.roundIcon ? (
              <img alt="roundImg" src={roundIcon} />
            ) : (
              <img alt="roundImg" src={IconBorder} />
            )
          }
          checkedIcon={
            props.roundIcon ? (
              <img alt="roundImg" src={roundChecked} />
            ) : (
              <img alt="roundImg" src={Icon} />
            )
          }
        />
      }
      label={
        <Typography
          className={props.darkText ? classes.darkText : classes.titleText}
        >
          <Trans>{props.label}</Trans>
        </Typography>
      }
    />
  );
}

//Default props for the checkbox
CheckBox.defaultProps = {
  id: "gtm_checkbox",
};

export default withStyles(styles)(CheckBox);
