import React, { Component } from "react";

import { Trans } from "react-i18next";
import styles from "./index.style.js";
import { withStyles } from "@material-ui/styles";

//client logo
class ClientLogo extends Component {
  //Renders the client logo
  render() {
    const { userDetails: user_profile, classes } = this.props;
    return (
      <div className={classes.ClientLogo}>
        <Trans>{user_profile && user_profile.user_profile.client_name}</Trans>
      </div>
    );
  }
}

export default withStyles(styles)(ClientLogo);
