import { Grid } from "@material-ui/core";
import React from "react";
import YieldDropdown from "../defectMap/compare Insp charts/yieldDropdown";
import downArrow from "../../assets/img/powerRevenue.svg";
import moment from "moment";
import powerEnergyConverter from "../../utils/powerEnergyConverter";
import styles from "./inspectionsComp.style";
import { withStyles } from "@material-ui/core/styles";

//Inspection comparison table
const ComparisonTable = (props) => {
  const { classes } = props;
  const [catToggle, setCatToggle] = React.useState(false);
  const SI_SYMBOL = ["", "k", "M", "B", "T", "P", "E"];

  //This function converts the number to a short form (Million, Billion, etc)
  function abbreviateNumber(number) {
    // what tier? (determines SI symbol)
    const tier = (Math.log10(Math.abs(number)) / 3) | 0;

    // if zero, we don't need a suffix
    if (tier === 0) return number;

    // get suffix and determine scale
    const suffix = SI_SYMBOL[tier];
    const scale = Math.pow(10, tier * 3);

    // scale the number
    const scaled = number / scale;

    // format number and add suffix
    return scaled.toFixed(2) + suffix;
  }

  //Format a large number with proper commas (10000000 -> 10,000,000)
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
  });

  //Renders the inspection comaprison table
  return (
    <Grid container className={classes.chartContainer}>
      <div className={classes.chartInnerDiv}>
        <div className={classes.cardName}>ESTIMATED LOSS BY ANOMALIES</div>
        <div className={classes.dropdownBox}>
          <YieldDropdown
            siteId={props.siteId}
            revenueLossTrigger={props.revenueLossTrigger}
            setRevenueLossTrigger={props.setRevenueLossTrigger}
            popupToggle={props.popupToggle}
            setPopupToggle={props.setPopupToggle}
          />
        </div>
      </div>

      <div className={classes.tableDivider} style={{ overflowX: "auto" }}>
        <div style={{ display: "flex" }}>
          {props.plantData.map((data) => {
            return (
              <>
                <div
                  style={{
                    borderRight: "1px solid #F0F4FA",
                  }}
                >
                  <div
                    className={[classes.tableValue, classes.inspDate].join(" ")}
                    style={{ width: "100%" }}
                  />
                  <div
                    className={[classes.tableCategory, classes.anomalyTxt].join(
                      " "
                    )}
                  >
                    Anomalies
                  </div>

                  {data?.anomaly_list
                    ?.sort((a, b) => a.name.localeCompare(b.name))
                    .map((x, id) => {
                      return (
                        <div
                          key={id}
                          className={classes.tableCategoryContainer}
                        >
                          <div
                            className={classes.colorCircle}
                            style={{
                              background: x.color,
                            }}
                          />
                          <div className={classes.tableCategoryData}>
                            {x.name}
                          </div>
                        </div>
                      );
                    })}
                </div>

                <div
                  key={data.inspection_date}
                  style={{ borderRight: "1px solid #F0F4FA" }}
                >
                  <div
                    className={[classes.tableValue, classes.inspDate].join(" ")}
                  >
                    <div style={{ width: "100%" }}>
                      Inspection:{" "}
                      {moment(data.inspection_date).format("DD MMM, YYYY")}
                    </div>
                  </div>

                  <div
                    className={[classes.tableValue, classes.catToggleBox].join(
                      " "
                    )}
                  >
                    <div>Count</div>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setCatToggle(!catToggle);
                      }}
                      role="button"
                      tabIndex={"0"}
                      aria-hidden="true"
                    >
                      {catToggle ? "Revenue Loss / yr" : "Power Loss"}
                      <span style={{ marginLeft: "4px" }}>
                        <img alt="arrowImg" src={downArrow} />
                      </span>
                    </div>
                  </div>
                  {data?.anomaly_list
                    ?.sort((a, b) => a.name.localeCompare(b.name))
                    .map((values) => {
                      return (
                        <div
                          key={values ? values.revenue_loss : "nullValu"}
                          className={classes.tableValue}
                          style={{
                            width: "351px",
                          }}
                        >
                          <div className={classes.revenueLossValue}>
                            {values ? formatter.format(values.count) : "-"}
                          </div>
                          <div style={{ fontSize: "14px" }}>
                            {values
                              ? catToggle
                                ? values.revenue_loss
                                  ? `${values.currency} ${abbreviateNumber(
                                      values.revenue_loss.toFixed(2)
                                    )}`
                                  : "-"
                                : values.power_loss
                                ? powerEnergyConverter(
                                    "power",
                                    values.power_loss
                                  )
                                : "-"
                              : "-"}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </>
            );
          })}
        </div>
      </div>
    </Grid>
  );
};

export default withStyles(styles)(ComparisonTable);
