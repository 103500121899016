import {
  CLEAR_ALERTS,
  REMOVE_ALERT,
  SET_ALERT,
} from "../constants/actionTypes";

import { v1 as uuid } from "uuid";

//Create and export the action to set the alert
export const setAlert = (msg, alertType, timeout = 5000) => {
  return (dispatch) => {
    const id = uuid.v4();
    dispatch({
      type: SET_ALERT,
      payload: { msg, alertType, id },
    });

    setTimeout(() => {
      return dispatch({ type: REMOVE_ALERT, payload: id });
    }, timeout);
  };
};

//Create and export the action to remove the alert
export const clearAlert = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_ALERTS });
  };
};
