const styles = {
  severityText: {
    margin: "0 5px",
    fontFamily: "Montserrat",
    fontSize: 12,
    fontWeight: 500,
    color: "#536471",
    fontStyle: "normal",
  },
  severityBox: {
    height: 8,
    display: "inline-block",
    width: 8,
    borderRadius: "50%",
  },
  totalText: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "18px",
    fontStyle: "normal",
    color: "#08001D",
  },
  subHeader: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "12px",
    fontStyle: "normal",
    color: "#08001d",
  },
  subText: {
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontStyle: "normal",
    color: "#536471",
    fontWeight: 500,
  },
  legendOuter: {
    flexWrap: "nowrap",
    overflow: "auto",
  },
};
export default styles;
