import {
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  SwipeableDrawer,
  Typography,
  withStyles,
} from "@material-ui/core";
import { Trans, withTranslation } from "react-i18next";
import { URLS, version } from "../../constants";

import CheckBox from "../../views/common/customStyle/CheckBox";
import ClientLogo from "../../views/common/clientlogo/index";
import CustomDropdown from "../../views/common/customStyle/CustomDropdown";
import CustomLink from "./customLink";
import CustomTooltip from "../../views/common/customStyle/Tooltip";
import { Link } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import React from "react";
import ViewPlans from "../../views/product/ViewPlans.js";
import { allRequestHandler } from "../../api";
import { connect } from "react-redux";
import i18n from "i18next";
import { setSelectedLanguage } from "../../reducers/language";
import sidebar_logo from "../../assets/img/zeitviewLogo.svg";
import styles from "./header.style";

// import prouserimg from "../assets/img/prouserimg.svg";

const languages = [
  {
    name: "English – EN",
    value: "en",
  },
  {
    name: "Español – ES",
    value: "sp",
  },
];

//humburger menu
class Hamburger extends React.Component {
  state = {
    useHandleDrawer: false,
    setOpen: true,
  };

  toggleDrawer = (value) => {
    this.setState({ useHandleDrawer: value });
  };

  handleDrawerOpen = () => {
    this.setState((prevState) => {
      return {
        useHandleDrawer: !prevState.useHandleDrawer,
      };
    });
  };

  // hideDrawer = () => {
  //   this.setState({ useHandleDrawer: false });
  // };

  handleChange = (e, item) => {
    this.props.setSelectedLanguage(item.value);
    this.props.i18n.changeLanguage(item.value);
    this.setState({ useHandleDrawer: false });
  };

  handleLogout = async () => {
    await allRequestHandler({
      requestType: "POST",
      requestUrl: URLS.LOGOUT,
      requestData: "",
    });

    sessionStorage.setItem("jwtToken", "");
  };

  //render humberger menu for mobile
  render() {
    const { classes } = this.props;
    const path = window.location.pathname;
    const logo =
      this.props.userDetails && this.props.userDetails.user_profile
        ? this.props.userDetails.user_profile.client_logo
        : null;
    const initial =
      this.props.userDetails && this.props.userDetails.user_profile
        ? this.props.userDetails.user_profile.client_name
            .split(" ")
            .map((n, i, a) => {
              return i === 0 || i + 1 === a.length ? n[0] : null;
            })
            .join("")
        : null;

    const languageIcon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          fill="#A8B9C6"
          fillRule="evenodd"
          d="M12.2142 5.82015C11.6878 2.57178 10.4465 0.291016 9.00181 0.291016C7.55717 0.291016 6.31579 2.57178 5.78947 5.82015H12.2142ZM5.51724 9.31223C5.51724 10.1198 5.5608 10.8946 5.63702 11.6403H12.363C12.4392 10.8946 12.4828 10.1198 12.4828 9.31223C12.4828 8.50469 12.4392 7.72988 12.363 6.98418H5.63702C5.5608 7.72988 5.51724 8.50469 5.51724 9.31223ZM17.3031 5.82015C16.265 3.35023 14.1633 1.44049 11.5681 0.669325C12.4537 1.89883 13.0635 3.75036 13.3829 5.82015H17.3031ZM6.43194 0.669325C3.84029 1.44049 1.73503 3.35023 0.700544 5.82015H4.62069C4.93648 3.75036 5.54628 1.89883 6.43194 0.669325ZM17.6915 6.98418H13.5281C13.6044 7.74807 13.6479 8.53015 13.6479 9.31223C13.6479 10.0943 13.6044 10.8764 13.5281 11.6403H17.6878C17.8875 10.8946 18 10.1198 18 9.31223C18 8.50469 17.8875 7.72988 17.6915 6.98418ZM4.35572 9.31223C4.35572 8.53015 4.39927 7.74807 4.4755 6.98418H0.31216C0.116152 7.72988 0 8.50469 0 9.31223C0 10.1198 0.116152 10.8946 0.31216 11.6403H4.47187C4.39927 10.8764 4.35572 10.0943 4.35572 9.31223ZM5.78947 12.8043C6.31579 16.0527 7.55717 18.3334 9.00181 18.3334C10.4465 18.3334 11.6878 16.0527 12.2142 12.8043H5.78947ZM11.5717 17.9551C14.1633 17.184 16.2686 15.2742 17.3067 12.8043H13.3866C13.0672 14.8741 12.4573 16.7256 11.5717 17.9551ZM0.700544 12.8043C1.73866 15.2742 3.84029 17.184 6.43557 17.9551C5.54991 16.7256 4.94011 14.8741 4.62069 12.8043H0.700544Z"
          clipRule="evenodd"
        />
      </svg>
    );

    return (
      <>
        {this.props.showPlans && (
          <ViewPlans onHandleShowPlans={this.props.onHandleShowPlans} />
        )}

        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => {
            return this.handleDrawerOpen();
          }}
          classes={{ root: classes.iconAlign }}
        >
          <MenuIcon />
        </IconButton>

        <SwipeableDrawer
          anchor="left"
          open={this.state.useHandleDrawer}
          onClose={() => {
            return this.toggleDrawer(false);
          }}
          onOpen={() => {
            return this.toggleDrawer(true);
          }}
          classes={{
            root: classes.drawerRoot,
            paper: classes.marT0,
          }}
          style={{ overflowX: "hidden" }}
        >
          <Link to="/dashboard">
            <div style={{ display: "flex", alignItems: "center" }}>
              {logo ? (
                <img
                  src={logo}
                  alt="client"
                  width="40"
                  height="40"
                  style={{ margin: "12px" }}
                />
              ) : (
                <div className={classes.initial}>
                  <Typography className={classes.clientinitialHumberger}>
                    {initial}
                  </Typography>
                </div>
              )}
              <Typography
                className={classes.typoclientname}
                style={{ maxWidth: 170 }}
              >
                <ClientLogo userDetails={this.props.userDetails} />
              </Typography>
            </div>
            {this.props.userDetails &&
              this.props.userDetails.user_profile &&
              this.props.userDetails.user_profile.product &&
              this.props.userDetails.user_profile.product.product_type ===
                "Pro" && <div className={classes.proLabel}>PRO PLAN</div>}
          </Link>

          <List
            classes={{ root: classes.linksRoot }}
            component="nav"
            aria-label="main mailbox folders"
          >
            <CustomLink
              closeSidebar={() => {
                return this.toggleDrawer(false);
              }}
              isActivePath={({ isActive }) =>
                isActive ? classes.linkActive : ""
              }
              path="/dashboard"
              Icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill="#A8B9C6"
                    fillRule="evenodd"
                    d="M20 10c0 5.523-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0s10 4.477 10 10zM2.856 10h2.326c.736 0 1.039.709 1.399 1.55.525 1.227 1.171 2.736 3.456 2.736 2.382 0 3.217-1.64 3.854-2.89.393-.772.71-1.396 1.27-1.396h1.98c0-3.945-3.197-7.143-7.142-7.143-3.945 0-7.143 3.198-7.143 7.143zm7.145 2.857c.454 0 .859-.212 1.12-.542.193-.244 3.754-5.573 3.166-6.023-.588-.45-5.174 3.935-5.434 4.286-.176.238-.28.532-.28.85 0 .79.639 1.43 1.428 1.43z"
                    clipRule="evenodd"
                  />
                </svg>
              }
              menuItemName={i18n.t("Dashboard")}
            />

            <CustomLink
              closeSidebar={() => {
                return this.toggleDrawer(false);
              }}
              isActivePath={({ isActive }) =>
                isActive || path.includes("/sites") ? classes.linkActive : ""
              }
              path="/sites"
              Icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="19"
                  fill="none"
                  viewBox="0 0 20 19"
                >
                  <path
                    fill="#A8B9C6"
                    fillRule="evenodd"
                    d="M7.816 0l-.332 2.637h4.999L12.15 0H7.816zm-1.01 0l-.331 2.637H1.6l.229-1.305C1.964.564 2.682 0 3.524 0h3.283zm-5.38 3.632L.534 8.72H5.71l.64-5.088H1.426zM.38 9.6l-.358 2.037c-.169.964.637 1.839 1.695 1.839H5.11L5.598 9.6H.38zm10.605 3.876h2.862L13.359 9.6H6.608l-.488 3.876h2.862v2.717h2.003v-2.717zm3.871 0h3.427c1.057 0 1.864-.875 1.694-1.839L19.62 9.6h-5.252l.488 3.876zm4.61-4.756l-.892-5.088h-4.957l.64 5.088h5.209zm-1.067-6.083l-.229-1.305C18.036.564 17.318 0 16.476 0H13.16l.332 2.637H18.4zm-11.04.995h5.25l.64 5.088h-6.53l.64-5.088zM4.976 17.187c0-.549.446-.994.995-.994h8.025c.55 0 .994.445.994.994 0 .55-.445.995-.994.995H5.97c-.55 0-.995-.445-.995-.995z"
                    clipRule="evenodd"
                  />
                </svg>
              }
              menuItemName={i18n.t("Sites")}
            />

            {this.props.siteListData && this.props.siteListData.length && (
              <CustomLink
                closeSidebar={() => {
                  return this.toggleDrawer(false);
                }}
                isActivePath={({ isActive }) =>
                  isActive ? classes.linkActive : ""
                }
                path={`/defects/${this.props.siteListData[0].plant_id}`}
                Icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fill="#A8B9C6"
                      fillRule="evenodd"
                      d="M9.231 16.193c4.343 0 6.962-2.62 6.962-6.962 0-4.343-2.62-6.962-6.962-6.962-4.343 0-6.962 2.62-6.962 6.962 0 4.343 2.62 6.962 6.962 6.962zm0 2.269c2.094 0 4.025-.697 5.573-1.872l.04.04 2.997 3c.494.493 1.295.493 1.789 0 .493-.495.493-1.295 0-1.79l-2.999-2.997-.04-.04c1.174-1.548 1.87-3.479 1.87-5.572C18.462 4.133 14.33 0 9.232 0 4.133 0 0 4.133 0 9.231s4.133 9.23 9.231 9.23zm1.96-4.88v-8.57H7.728v8.57h-.432v-8.57H6.264L4.533 6.815v4.962l1.731 1.805h6.06l1.732-1.805V6.816L12.325 5.01h-.7v8.572h-.434z"
                      clipRule="evenodd"
                    />
                  </svg>
                }
                menuItemName={i18n.t("Anomalies")}
              />
            )}

            <CustomLink
              closeSidebar={() => {
                return this.toggleDrawer(false);
              }}
              isActivePath={({ isActive }) =>
                isActive ? classes.linkActive : ""
              }
              path="/MyProfile/"
              Icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="20"
                  fill="none"
                  viewBox="0 0 19 20"
                >
                  <path
                    fill="#A8B9C6"
                    fillRule="evenodd"
                    d="M9.494 0C5.935 0 4.28 2.637 4.924 5.557l.526 2.387c.418 1.898 2.1 3.25 4.044 3.25 1.943 0 3.625-1.352 4.043-3.25l.526-2.387C14.707 2.637 13.053 0 9.493 0zm-.388 14.457c-.46-.48-1.293-1.351-2.176-2.746-2.038.323-6.276 1.591-6.923 4.076v1.682c-.065.744.298 2.232 2.264 2.232h14.72c1.708 0 2.049-1.488 2.006-2.232v-1.682c-.622-2.407-4.896-3.72-6.956-4.076-.957 1.734-2.124 2.814-2.588 3.138-.045-.076-.168-.204-.347-.392z"
                    clipRule="evenodd"
                  />
                </svg>
              }
              menuItemName={i18n.t("My Profile")}
            />

            <div
              style={{ marginTop: "8px" }}
              activeClassName={classes.linkActive}
            >
              <ListItem className={{ root: classes.listItemRoot }} button>
                <ListItemIcon style={{ minWidth: 35 }}>
                  {this.state.setOpen ? (
                    <CustomDropdown
                      buttonLabel={
                        <Grid container>
                          <Grid style={{ marginTop: "3px" }}>
                            {languageIcon}
                          </Grid>

                          <Grid style={{ marginLeft: "15px" }}>
                            <Typography className={classes.linkStyles}>
                              <Trans>Language</Trans>
                              <span>
                                :{" "}
                                {this.props.language === "en"
                                  ? "English"
                                  : "Spanish"}
                              </span>
                            </Typography>
                          </Grid>
                        </Grid>
                      }
                      outerMenuClass={classes.outerMenu}
                      closeOnClick
                      menuItems={
                        <div>
                          <p className={classes.outerMenuHeading}>
                            <Trans>Change Language</Trans>
                          </p>
                          {languages.map((item) => {
                            return (
                              <div key={item.name}>
                                <CheckBox
                                  roundIcon
                                  darkText
                                  label={item.name}
                                  checked={this.props.language === item.value}
                                  onChange={(e) => {
                                    return this.handleChange(e, item);
                                  }}
                                />
                              </div>
                            );
                          })}
                        </div>
                      }
                    />
                  ) : (
                    <CustomTooltip
                      title="Language"
                      placement="right"
                      arrow
                      classes={{
                        tooltip: classes.sidebarTooltip,
                        arrow: classes.tooltipArrow,
                      }}
                    >
                      <div>{languageIcon}</div>
                    </CustomTooltip>
                  )}
                </ListItemIcon>
              </ListItem>
            </div>

            <CustomLink
              closeSidebar={() => {
                return this.toggleDrawer(false);
              }}
              isActivePath={classes.linkColor}
              path="/"
              Icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="19"
                  fill="none"
                  viewBox="0 0 18 19"
                >
                  <path
                    fill="#A8B9C6"
                    d="M0 3.63v10.921c0 1.005.418 1.9 1.088 2.597.67.67 1.534 1.034 2.539 1.034h4.337c.625 0 1.131-.507 1.131-1.131 0-.625-.506-1.131-1.131-1.131H3.627c-.753 0-1.34-.615-1.34-1.369V3.631c0-.754.587-1.34 1.34-1.34H7.95c.632 0 1.145-.513 1.145-1.146C9.095.513 8.582 0 7.95 0H3.627c-1.005 0-1.87.391-2.539 1.061C.418 1.76 0 2.625 0 3.631zm5.998 4.296v2.357c0 .503.447.922.949.922h5.05v2.435c0 .307.167.559.446.698.111.028.223.028.279.028.195 0 .363-.055.502-.195l4.556-4.563c.307-.252.28-.754 0-1.034L13.224 4.04c-.39-.42-1.228-.168-1.228.503v2.463h-5.05c-.501 0-.948.419-.948.921z"
                  />
                </svg>
              }
              menuItemName={i18n.t("Log Out")}
            />
          </List>
          <div className={classes.bottomLogo}>
            <div>
              <img
                className={classes.airprobeLogo}
                src={sidebar_logo}
                alt="dronebaseLogo"
              />
            </div>
            <div className={classes.poweredByAirprobe}>
              <p style={{ marginBottom: "5px" }}>
                <Trans>Powered by DroneBase</Trans>
              </p>
              <p style={{ marginLeft: "55px" }}>{version}</p>
            </div>
          </div>
        </SwipeableDrawer>
      </>
    );
  }
}

//updating current language state
const mapStateToProps = (state) => {
  return {
    language: state.language.selectedLanguage,
  };
};

export default connect(mapStateToProps, { setSelectedLanguage })(
  withStyles(styles)(withTranslation()(Hamburger))
);
