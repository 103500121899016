/* eslint-disable quotes */

import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart as ChartJS } from "chart.js";
import DownloadIcon from "../../../../components/assets/img/Vector.svg";
import React from "react";
import _ from "lodash";
import i18n from "i18next";
import moment from "moment";
import { withTranslation } from "react-i18next";

// import { withRouter } from "react-router-dom";

ChartJS.register(ChartDataLabels);

let newLabel = [];
let newDataset = [];

//anomalies category bar chart
class AnomaliesCatBar extends React.Component {
  state = {
    allData: null,
    allLabel: null,
    // labels: null,
    datasets: null,
    constData: null,
  };

  //Create chart data on the initial render
  componentDidMount() {
    localStorage.setItem("path", window.location.pathname.includes("sites"));
    this.callChartData();
  }

  //Update chart data on the subsequent renders
  componentDidUpdate(prevProps) {
    //Update chart data
    if (!_.isEqual(prevProps.chartData, this.props.chartData)) {
      this.callChartData();
    }

    newLabel = [];
    newDataset = [];

    //Update language change
    if (prevProps.language !== this.props.language) {
      this.state.allData.labels.map((label) => {
        const newVal = i18n.t(label);
        newLabel.push(newVal);
        return newLabel;
      });

      //Add style properties for the chart
      this.state.constData.map((dataset) => {
        const newVal = {
          backgroundColor: "",
          data: [],
          hoverBackgroundColor: "",
          label: "",
          barThickness: 24,
          percentage: "",
        };
        newVal.backgroundColor = dataset.backgroundColor;
        newVal.data = dataset.data;
        newVal.hoverBackgroundColor = dataset.hoverBackgroundColor;
        newVal.label = i18n.t(dataset.label.toLowerCase());
        newVal.barThickness = dataset.barThickness;
        newVal.percentage = dataset.percentage;
        newDataset.push(newVal);
        return newDataset;
      });

      // skipcq Avoid usage of setState in componentDidUpdate JS-0443
      this.setState({
        // labels: newLabel,
        datasets: newDataset,
      });
    }
  }

  //Handles chart data creation
  callChartData() {
    // if (prevProps.getChartData !== this.props.getChartData) {
    if (this.props.chartData) {
      const allLabel = [];

      const allChartLoop = this.props.chartData;

      let defectData = {};
      let defectColor = {};

      //Chart labels
      if (allChartLoop.label_comparison) {
        allChartLoop.label_comparison.forEach(({ label_list }) => {
          label_list.sort().forEach(({ name }) => {
            defectData = {
              ...defectData,
              [name]: [],
            };
            defectColor = {
              ...defectColor,
              NEW: "#149B9E",
              PERSISTENT: "#8D95A2",
              RECURRING: "#FFCB05",
              TRANSITIONED: "#F26649",
            };
            // percentage = {
            //   ...percentage,
            //   [percentage]: [],
            // };
          });
        });

        //Chart data processing
        _.sortBy(allChartLoop.label_comparison, "inspection_date").forEach(
          ({ label_list, name }) => {
            if (name) allLabel.push(name);
            else allLabel.push(name);

            const sortedList = _.orderBy(label_list, ["name"], ["asc"]);
            Object.keys(defectData).forEach((defName) => {
              let count = 0;
              if (
                sortedList.find((defData) => {
                  return defData.name === defName;
                })
              ) {
                sortedList.forEach((defData) => {
                  if (defData.name === defName) count = defData.count;
                });
              } else count = 0;

              const newData = [...defectData[defName], count];
              defectData = {
                ...defectData,
                [defName]: newData,
              };
            });
          }
        );
      }

      console.log("***this.state.datasets", this.state.datasets);

      //Chart data processing
      const dataset = Object.entries(defectData).map((anomaly) => {
        return {
          label: anomaly[0],
          backgroundColor: defectColor[anomaly[0]],
          hoverBackgroundColor: defectColor[anomaly[0]],
          data: anomaly[1],
          maxBarThickness: 40,
          percentages: "",
          labels: "",
        };
      });

      const currState = {
        labels: allLabel,
        fontSize: "15px",
        datasets: dataset,
      };

      this.setState({
        allData: currState,
        allLabel,
        constData: dataset,
      });

      //Set labels for the chart
      currState.labels.map((label) => {
        const newVal = i18n.t(label);
        newLabel.push(newVal);
        return newLabel;
      });

      //Set dataset for the chart
      currState.datasets.map((datasetVal) => {
        const newVal = {
          backgroundColor: "",
          data: [],
          hoverBackgroundColor: "",
          label: "",
          maxBarThickness: "",
        };
        newVal.backgroundColor = datasetVal.backgroundColor;
        newVal.data = datasetVal.data;
        newVal.hoverBackgroundColor = datasetVal.hoverBackgroundColor;
        newVal.label = i18n.t(
          datasetVal.label.charAt(0).toUpperCase() +
            datasetVal.label.slice(1).toLowerCase()
        );
        newVal.maxBarThickness = 24;
        (newVal.percentages =
          this.props.chartData &&
          this.props.chartData.label_comparison &&
          _.sortBy(
            this.props.chartData.label_comparison,
            "inspection_date"
          ).map((val) => {
            return val.label_list.map((item) => {
              return item;
            });
          })),
          (newVal.labels =
            this.props.chartData &&
            this.props.chartData.label_comparison &&
            _.sortBy(
              this.props.chartData.label_comparison,
              "inspection_date"
            ).map((val) => {
              return moment(val.inspection_date).format("D MMM, YYYY");
            })),
          newDataset.push(newVal);
        return newDataset;
      });

      this.setState({
        // labels: newLabel,
        datasets: newDataset,
      });

      //Download Chart Image
      document.getElementById("download").addEventListener("click", () => {
        /*Get image of canvas element*/
        const url_base64jp = document
          .getElementById("barChart")
          .toDataURL("image/jpg");
        /*get download button (tag: <a></a>) */
        const downloadButton = document.getElementById("download");
        /*insert chart image url to download button (tag: <a></a>) */
        downloadButton.href = url_base64jp;
      });
    }
  }

  //Handles update of chart width on window resize
  addWidth = () => {
    if (this.state.allLabel && window.innerWidth <= 760) {
      if (this.state.allLabel.length > 15) return 1000;
      else if (this.state.allLabel.length > 8) return 700;
      else if (this.state.allLabel.length > 5) return 500;

      return 356;
    }
    return null;
  };

  //Renders the stacked column chart
  render() {
    //Chart styles
    const styles = {
      iconBg: {
        border: "1px solid #DDE5EC",
        boxSizing: "border-box",
        borderRadius: 8,
        padding: "7px 9px",
        position: "absolute",
        right: 15,
        top: 15,
        width: 33,
        height: 33,
        zIndex: 2,
        visibility: `${
          this.state.allData &&
          this.state.allData.datasets.length &&
          this.props.product &&
          this.props.product.product_type !== "Basic"
            ? "visible"
            : "hidden"
        }`,
      },
    };

    //tooltip card
    const getOrCreateTooltip = (chart) => {
      let tooltipEl = chart.canvas.parentNode.querySelector("div");

      if (!tooltipEl) {
        //tooltip element
        tooltipEl = document.createElement("div");
        tooltipEl.style.background = "#FFFFFF";
        tooltipEl.style.borderRadius = "5px";
        tooltipEl.style.border = "1px solid #E4EAF5";
        tooltipEl.style.boxShadow = "0px 1px 2px rgba(0, 0, 0, 0.05)";
        tooltipEl.style.color = "black";
        tooltipEl.style.opacity = 1;
        tooltipEl.style.pointerEvents = "none";
        tooltipEl.style.position = "absolute";
        tooltipEl.style.transform = "translate(-50%, 0)";
        tooltipEl.style.transition = "all .1s ease";
        tooltipEl.style.width = "227px";
        tooltipEl.style.maxWidth = "250px";

        const table = document.createElement("table");
        table.style.margin = "0px";

        tooltipEl.appendChild(table);
        chart.canvas.parentNode.appendChild(tooltipEl);
      }

      return tooltipEl;
    };

    //custom tooltip handler
    const externalTooltipHandler = (context) => {
      // Tooltip Element
      const { chart, tooltip } = context;
      const tooltipEl = getOrCreateTooltip(chart);

      // Hide if no tooltip
      if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
      }

      // Set Text
      if (tooltip.body) {
        const titleLines = tooltip.title || [];
        const bodyLines = tooltip.body.map((b) => b.lines);

        const tableHead = document.createElement("thead");

        //set title of the tooltip
        titleLines.forEach((title) => {
          const tr = document.createElement("tr");
          tr.style.borderWidth = 0;
          tr.style.color = "#1D2D47";
          tr.style.fontFamily = "Montserrat";
          tr.style.fontStyle = "normal";
          tr.style.fontWeight = 500;
          tr.style.fontSize = "12px";
          tr.style.lineHeight = "20px";
          tr.style.textAlign = "left";

          const th = document.createElement("th");
          th.style.borderWidth = 0;
          const text = document.createTextNode(title);

          th.appendChild(text);
          tr.appendChild(th);
          tableHead.appendChild(tr);
        });

        //created table body
        const tableBody = document.createElement("tbody");

        //getting table data
        bodyLines.forEach((body, i) => {
          const bodySplit = body[0]?.toString().split("-");
          const colors = tooltip.labelColors[i];

          //span and style
          const span = document.createElement("span");
          span.style.borderRadius = "50%";
          span.style.background = colors.backgroundColor;
          span.style.borderColor = colors.borderColor;
          span.style.borderWidth = "2px";
          span.style.marginRight = "10px";
          span.style.height = "6px";
          span.style.width = "6px";
          span.style.display = "inline-block";

          //table row and style
          const tr = document.createElement("tr");
          tr.style.backgroundColor = "white";
          tr.style.borderWidth = 0;
          tr.style.marginBottom = 10;
          tr.style.width = "300px";

          //data element and styles
          const td = document.createElement("td");
          td.style.borderWidth = 0;
          td.style.fontFamily = "Montserrat";
          td.style.fontStyle = "normal";
          td.style.fontWeight = 500;
          td.style.fontSize = "12px";
          td.style.lineHeight = "18px";
          td.style.color = "#536471";
          td.style.width = "300px";
          td.style.marginTop = "20px";
          td.style.textAlign = "left";

          if (body[0]) {
            const text = document.createTextNode(bodySplit[0]);

            //value element and style
            const value = document.createElement("span");
            value.textContent = bodySplit[1];
            value.style.textAlign = "right";
            value.style.marginBottom = "-50px";
            value.style.color = "#536471";
            value.style.fontFamily = "Montserrat";
            value.style.fontStyle = "normal";
            value.style.fontWeight = 500;
            value.style.fontSize = "12px";
            value.style.lineHeight = "18px";
            value.style.marginRight = "5px";

            const percentage = document.createElement("span");
            percentage.textContent = bodySplit[2];
            percentage.style.textAlign = "right";
            percentage.style.marginBottom = "-50px";
            percentage.style.color = "#536471";
            percentage.style.fontFamily = "Montserrat";
            percentage.style.fontStyle = "normal";
            percentage.style.fontWeight = 500;
            percentage.style.fontSize = "12px";
            percentage.style.lineHeight = "18px";

            td.appendChild(span);
            td.appendChild(text);
            tr.appendChild(td);
            tr.appendChild(value);
            tr.appendChild(percentage);
            tableBody.appendChild(tr);
          }
        });

        const tableRoot = tooltipEl.querySelector("table");

        // Remove old children
        while (tableRoot.firstChild) {
          tableRoot.firstChild.remove();
        }

        // Add new children
        tableRoot.appendChild(tableHead);
        tableRoot.appendChild(tableBody);
      }

      const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

      // Display, position, and set styles for font
      tooltipEl.style.opacity = 1;
      tooltipEl.style.left = positionX + tooltip.caretX + "px";
      tooltipEl.style.top = positionY + tooltip.caretY + "px";
      tooltipEl.style.font = tooltip.options.bodyFont.string;
      tooltipEl.style.padding =
        tooltip.options.padding + "px " + tooltip.options.padding + "px";
    };

    //bar chart options
    const options = {
      layout: {
        padding: {
          left: 24,
          right: 30,
          top: 24,
          bottom: 114,
        },
      },
      animation: {
        duration: 0,
      },
      maintainAspectRatio: false,
      events: ["mouseout", "mousemove"],
      title: {
        display: true,
        fontSize: 12,
      },
      responsive: true,
      plugins: {
        datalabels: {
          anchor: "end",
          align: "top",
          formatter: (context, value) => {
            const datasetArray = [];
            value.dataset.percentages.forEach((val, index) => {
              if (value.dataIndex === index) {
                val.forEach((val) => {
                  return datasetArray.push(val.count);
                });
              }
            });

            //calculate total sum
            function totalSum(total, datapoint) {
              return total + datapoint;
            }

            const sum = datasetArray.reduce(totalSum, 0);

            if (value.datasetIndex === datasetArray.length - 1) {
              return sum;
            }
            return "";
          },
        },
        legend: {
          display: true,
          position: "bottom",
          labels: {
            padding: 25,
            boxWidth: 4,
            usePointStyle: true,
            font: {
              family: "Montserrat",
              style: "normal",
              color: "#536471",
              size: "11px",
              weight: 500,
              lineHeight: "15px",
            },
            boxHeight: 4.5,
            pointStyleWidth: 6,
          },
        },
        tooltip: {
          yAlign: "bottom",
          mode: "index",
          titleFontSize: 12,
          titleFontFamily: "Montserrat",
          titleFontStyle: "normal",
          fullWidth: true,
          tooltipFontSize: 10,
          titleMarginBottom: 15,
          titleSpacing: 25,
          titleAlign: "left",
          titleFontColor: "#536471",
          bodyAlign: "center",
          bodyFontSize: 12,
          bodySpacing: 15,
          bodyFontFamily: "Montserrat",
          bodyFontColor: "#536471",
          position: "nearest",
          yPadding: 15,
          xPadding: 20,
          cornerRadius: 5,
          caretSize: 10,
          borderColor: "#ccc",
          borderWidth: 1,
          footerSpacing: 6,
          backgroundColor: "white",
          enabled: false,

          external: externalTooltipHandler,

          callbacks: {
            title: function (tooltipItems) {
              return `Inspection: ${tooltipItems[0].label}`;
            },
            label: function (tooltipItems) {
              const getPerIndex = tooltipItems.dataset.labels.findIndex(
                (obj) => {
                  return obj === tooltipItems.label;
                }
              );

              const getPercentage =
                tooltipItems.dataset.percentages[getPerIndex][
                  tooltipItems.datasetIndex
                ];

              return `${getPercentage.name
                .charAt(0)
                .toUpperCase()}${getPercentage.name.slice(1).toLowerCase()}-${
                getPercentage.count
              }-(${getPercentage.percentage}%)`;
            },
          },
        },
      },
      scales: {
        x: {
          stacked: true,
          border: {
            display: true,
            dash: [0, 9],
          },
          ticks: {
            font: {
              family: "Montserrat",
              style: "normal",
              color: "#536471",
              size: "11px",
              weight: 500,
              lineHeight: "15px",
            },
          },
        },
        y: {
          stacked: true,
          border: {
            display: false,
            dash: [8, 4],
            color: "#E4EAF5",
          },
          ticks: {
            font: {
              family: "Montserrat",
              style: "normal",
              color: "#536471",
              size: "11px",
              weight: 500,
              lineHeight: "15px",
            },
          },
          title: {
            text: "Anomalies",
            display: true,
            font: {
              family: "Montserrat",
              style: "normal",
              color: "#536471",
              size: "11px",
              weight: 500,
              lineHeight: "15px",
            },
          },
        },
      },
    };

    //rendering anomalies category bar chart.
    return (
      <div style={{ minWidth: this.addWidth() }}>
        <a
          id="download"
          download="ChartImage.jpg"
          style={styles.iconBg}
          title="Download Charts"
        >
          <img alt="downloadImg" src={DownloadIcon} />
        </a>

        {this.state.allData && (
          <Bar
            id="barChart"
            data={{
              labels:
                this.props.chartData &&
                this.props.chartData.label_comparison &&
                _.sortBy(
                  this.props.chartData.label_comparison,
                  "inspection_date"
                ).map((val) => {
                  return moment(val.inspection_date).format("D MMM, YYYY");
                }),
              percentages:
                this.props.chartData &&
                this.props.chartData.label_comparison &&
                _.sortBy(
                  this.props.chartData.label_comparison,
                  "inspection_date"
                ).map((val) => {
                  return val.label_list.map((item) => {
                    return item;
                  });
                }),
              totalAnomalies:
                this.props.chartData &&
                this.props.chartData.label_comparison &&
                _.sortBy(
                  this.props.chartData.label_comparison,
                  "inspection_date"
                ).map((val) => {
                  return val.total_anomalies;
                }),
              datasets: this.state.datasets,
              fontSize: this.state.allData.fontsize,
            }}
            height={450}
            redraw
            options={options}
          />
        )}
      </div>
    );
  }
}

export default withTranslation()(AnomaliesCatBar);
