import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, { Component } from "react";
import {
  anomalyStatusData,
  iecButtons,
  labelButtons,
  severityButtons,
} from "../../../constants/filtersConstant";

import AnomalyStatus from "../anomalyStatus";
import CustomButton from "../customStyle/Button";
import CustomCheckbox from "../customStyle/CheckBox";
import IEC1 from "../../../assets/img/iec1.svg";
import IEC2 from "../../../assets/img/iec2.svg";
import IEC3 from "../../../assets/img/iec3.svg";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import TemperatureRange from "../temperatureRange";
import { Trans } from "react-i18next";
import Uncategorized from "../../../assets/img/uncategorized.svg";
import criticalImg from "../../../assets/img/Vector.svg";
import { imageOptions } from "../../../constants";
import minorImg from "../../../assets/img/Subtract.svg";
import moderateImg from "../../../assets/img/Moderate2.svg";
import styles from "./filters.style";

//Create a styled component
const DialogContent = withStyles(() => {
  return {
    root: {
      position: "relative",
    },
  };
})(MuiDialogContent);

//Create a styled component
const DialogActions = withStyles((theme) => {
  return {
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  };
})(MuiDialogActions);

//Filters component
class Filter extends Component {
  state = {
    tabValue: 0,
    anomalies: [],
    inspection_types: [],
    module_types: [],
    plant_names: [],
    inspectionFilters: "All",
    severity: [],
    status: [],
    sites: [],
    iec: [],
    images: [],
    tempRange: [0, 100],
    current: "severity",
    defectSelectCount: 0,
    hasCount: 0,
    label: [],
  };

  //These functions are called whenever the component updates
  componentDidMount() {
    //Update filter object with the data change
    if (this.props.filters) {
      let filters = {};
      let inspectionFilters = "All";
      const severity = [];
      const status = [];
      const sites = [];
      const iec = [];
      let tempRange = [0, 100];
      const label = [];

      this.props.filters.defect_types &&
        this.props.filters.defect_types.forEach((data) => {
          return (filters = {
            ...filters,
            [data]: false,
          });
        });

      this.props.filters.anomaly_has &&
        this.props.filters.anomaly_has.forEach((data) => {
          return (filters = {
            ...filters,
            [data]: false,
          });
        });

      this.props.filters.module_types &&
        this.props.filters.module_types.forEach((data) => {
          return (filters = {
            ...filters,
            [data]: false,
          });
        });

      this.props.filterChips.forEach((data) => {
        if (data.type === "inspectionFilters") inspectionFilters = data.name;
        else if (data.type === "severity") severity.push(data.name);
        else if (data.type === "status") status.push(data.name);
        else if (data.type === "sites") sites.push(data.name);
        else if (data.type === "iec") iec.push(data.name);
        else if (data.type === "temperature_range") tempRange = data.value;
        else if (data.type === "label") label.push(data.name);
        else {
          filters = {
            ...filters,
            [data.name]: true,
          };
        }
      });

      // skipcq Avoid using setState in componentDidMount JS-0442
      this.setState({
        anomalies: this.props.filters.defect_types
          ? this.props.filters.defect_types
          : [],
        images: this.props.filters.anomaly_has
          ? this.props.filters.anomaly_has
          : [],
        inspection_types: this.props.filters.inspection_types
          ? this.props.filters.inspection_types
          : [],
        module_types: this.props.filters.module_types
          ? this.props.filters.module_types
          : [],
        plant_names: this.props.filters.plant_names
          ? this.props.filters.plant_names
          : this.props.filters.section_names
          ? this.props.filters.section_names
          : [],
        ...filters,
        inspectionFilters,
        severity,
        status,
        sites,
        iec,
        tempRange,
        label,
      });
    }
  }

  //Handles "Anomaly Has" filter
  handleAnomalyHas = (e, value) => {
    const getData = this.state.hasCount;
    const counter = e.target.checked ? getData + 1 : getData - 1;
    this.setState({
      [value]: e.target.checked,
      hasCount: counter,
    });
  };

  //Handles "Temperature Range" slider
  handleSlider = (event, value) => {
    if (value && value.length) {
      value[0] = Number(value[0]);
      value[1] = Number(value[1]);
    }
    if (value[0] === 0 && value[1] === 100)
      this.setState({ tempRange: value, tempChanged: false });
    else this.setState({ tempRange: value, tempChanged: true });
  };

  //Apply filters to the data
  handleApply = () => {
    const filterChips = [];
    const tempValue = this.state.tempRange;
    const temperature_range = { start: tempValue[0], end: tempValue[1] };

    const {
      anomalies,
      images,
      severity,
      status,
      module_types,
      sites,
      iec,
      tempRange,
    } = this.state;

    let severity_types = severity.map((data) => {
      return data;
    });
    let status_type = status.map((data) => {
      return data;
    });
    let sites_type = sites.map((data) => {
      return data;
    });
    let iec_type = iec.map((data) => {
      return data;
    });
    // let label_type = label.map((data) => data);
    let anomaly_has = images
      .map((data) => {
        if (this.state[data]) return data;

        return null;
      })
      .filter(Boolean);
    let defect_types = anomalies
      .map((data) => {
        if (this.state[data]) return data;

        return null;
      })
      .filter(Boolean);
    let inspection_type = this.state.inspectionFilters;
    let module_type = module_types
      .map((data) => {
        if (this.state[data]) return data;

        return null;
      })
      .filter(Boolean);

    if (module_type.includes("All")) module_type = [];

    defect_types.forEach((data) => {
      filterChips.push({
        name: data,
        type: "anomalies",
        delete: (name) => {
          defect_types = defect_types.filter((item) => {
            return name !== item;
          });
          this.setState({ [name]: false });

          this.props.applyFilter(
            defect_types,
            inspection_type,
            module_type,
            severity_types.length === 0 ? ["All"] : severity_types,
            status_type.length === 0 ? ["All"] : status_type,
            iec_type.length === 0 ? ["All"] : iec_type,
            sites_type,
            temperature_range,
            anomaly_has === 0 ? ["All"] : anomaly_has
          );
        },
      });
    });

    //For "Anomaly Has" filter
    anomaly_has.forEach((data) => {
      filterChips.push({
        name: data,
        type: "images",
        delete: (name) => {
          anomaly_has = anomaly_has.filter((item) => {
            return name !== item;
          });
          this.setState({ [name]: false });
          this.props.applyFilter(
            defect_types,
            inspection_type,
            module_type,
            severity_types.length === 0 ? ["All"] : severity_types,
            status_type.length === 0 ? ["All"] : status_type,
            iec_type.length === 0 ? ["All"] : iec_type,
            sites_type,
            temperature_range,
            anomaly_has === 0 ? ["All"] : anomaly_has
          );
        },
      });
    });

    //For "Temperature Range" filter
    if (!(tempRange[0] === 0 && tempRange[1] === 100)) {
      filterChips.push({
        name: "Temperature Difference",
        type: "temperature_range",
        value: this.state.tempRange,
        delete: () => {
          this.setState({
            tempRange: [0, 100],
          });
          this.props.applyFilter(
            defect_types,
            inspection_type,
            module_type,
            severity_types.length === 0 ? ["All"] : severity_types,
            status_type.length === 0 ? ["All"] : status_type,
            iec_type.length === 0 ? ["All"] : iec_type,
            sites_type,
            { start: 0, end: 100 },
            anomaly_has === 0 ? ["All"] : anomaly_has
          );
        },
      });
    }

    //For "Inspection Type" filter
    if (inspection_type !== "All")
      filterChips.push({
        name: inspection_type,
        type: "inspectionFilters",
        delete: () => {
          this.setState({
            inspectionFilters: "All",
          });
          inspection_type = "All";
          this.props.applyFilter(
            defect_types,
            "All",
            module_type,
            severity_types.length === 0 ? ["All"] : severity_types,
            status_type.length === 0 ? ["All"] : status_type,
            iec_type.length === 0 ? ["All"] : iec_type,
            sites_type,
            temperature_range,
            anomaly_has === 0 ? ["All"] : anomaly_has
          );
        },
      });

    //For "Module Type" filter
    module_type.forEach((data) => {
      if (data !== "All")
        filterChips.push({
          name: data,
          type: "module_type",
          delete: (name) => {
            module_type = module_type.filter((item) => {
              return name !== item;
            });
            this.setState({ [name]: false });
            this.props.applyFilter(
              defect_types,
              inspection_type,
              module_type,
              severity_types.length === 0 ? ["All"] : severity_types,
              status_type.length === 0 ? ["All"] : status_type,
              iec_type.length === 0 ? ["All"] : iec_type,
              sites_type,
              temperature_range,
              anomaly_has === 0 ? ["All"] : anomaly_has
            );
          },
        });
    });

    //For "Severity" filter
    severity.forEach((data) => {
      filterChips.push({
        name: data,
        type: "severity",
        delete: (name) => {
          severity_types = severity_types.filter((item) => {
            return item !== name;
          });
          this.setState({ severity_types });
          this.props.applyFilter(
            defect_types,
            inspection_type,
            module_type,
            severity_types.length === 0 ? ["All"] : severity_types,
            status_type.length === 0 ? ["All"] : status_type,
            iec_type.length === 0 ? ["All"] : iec_type,
            sites_type,
            temperature_range,
            anomaly_has === 0 ? ["All"] : anomaly_has
          );
        },
      });
    });

    //For "Status" filter
    status.forEach((data) => {
      filterChips.push({
        name: data,
        type: "status",
        delete: (name) => {
          status_type = status_type.filter((item) => {
            return item !== name;
          });
          this.setState({ status_type });
          this.props.applyFilter(
            defect_types,
            inspection_type,
            module_type,
            severity_types.length === 0 ? ["All"] : severity_types,
            status_type.length === 0 ? ["All"] : status_type,
            iec_type.length === 0 ? ["All"] : iec_type,
            sites_type,
            temperature_range,
            anomaly_has === 0 ? ["All"] : anomaly_has
          );
        },
      });
    });

    //For "Sites" filter
    sites.forEach((data) => {
      filterChips.push({
        name: data,
        type: "sites",
        delete: (name) => {
          sites_type = sites_type.filter((item) => {
            return item !== name;
          });
          this.setState({ sites_type });
          this.props.applyFilter(
            defect_types,
            inspection_type,
            module_type,
            severity_types.length === 0 ? ["All"] : severity_types,
            status_type.length === 0 ? ["All"] : status_type,
            iec_type.length === 0 ? ["All"] : iec_type,
            sites_type,
            temperature_range,
            anomaly_has === 0 ? ["All"] : anomaly_has
          );
        },
      });
    });

    //For IEC
    iec.forEach((data) => {
      filterChips.push({
        name: data,
        type: "iec",
        delete: (name) => {
          iec_type = iec_type.filter((item) => {
            return item !== name;
          });
          this.setState({ iec_type });
          this.props.applyFilter(
            defect_types,
            inspection_type,
            module_type,
            severity_types.length === 0 ? ["All"] : severity_types,
            status_type.length === 0 ? ["All"] : status_type,
            iec_type.length === 0 ? ["All"] : iec_type,
            sites_type,
            temperature_range,
            anomaly_has === 0 ? ["All"] : anomaly_has
          );
        },
      });
    });

    this.props.addFilterChips(filterChips);
    this.props.applyFilter(
      defect_types,
      inspection_type,
      module_type,
      this.state.severity.length === 0 ? ["All"] : this.state.severity,
      this.state.status.length === 0 ? ["All"] : this.state.status,
      iec_type.length === 0 ? ["All"] : iec_type,
      sites_type,
      temperature_range,
      anomaly_has === 0 ? ["All"] : anomaly_has
    );
    this.props.closeFilter();
  };

  //Handles "Severity" filter
  handleSeverity = (severity_selected) => {
    let severity = [];
    if (this.state.severity.includes(severity_selected)) {
      severity = this.state.severity.filter((data) => {
        return data !== severity_selected;
      });
    } else {
      severity = [...this.state.severity];
      severity.push(severity_selected);
    }
    this.setState({ severity });
  };

  //Handles "Anomaly NAme" filter
  handleAnomalyName = (value) => {
    const getField = value.target.name;
    this.setState((prevState) => {
      return { [getField]: !prevState[getField] };
    });
  };

  //Handles "Status" filter
  updateHandleStatus = (status_selected) => {
    let status = [];
    if (this.state.status.includes(status_selected)) {
      status = this.state.status.filter((data) => {
        return data !== status_selected;
      });
    } else {
      status = [...this.state.status];
      status.push(status_selected);
    }
    this.setState({ status });
  };

  //Handles "Sites" filter
  handleSites = (site_selected) => {
    let sites = [];
    if (this.state.sites.includes(site_selected))
      sites = this.state.sites.filter((data) => {
        return data !== site_selected;
      });
    else {
      sites = [...this.state.sites];
      sites.push(site_selected);
    }
    this.setState({ sites });
  };

  //Handles "IEC" filter
  handleIEC = (iec_selected) => {
    let iec = [];
    if (this.state.iec.includes(iec_selected))
      iec = this.state.iec.filter((data) => {
        return data !== iec_selected;
      });
    else {
      iec = [...this.state.iec];
      iec.push(iec_selected);
    }
    this.setState({ iec });
  };

  //Handles "Label" filter
  handleLabel = (label_selected) => {
    let label = [];
    if (this.state.label.includes(label_selected))
      label = this.state.label.filter((data) => {
        return data !== label_selected;
      });
    else {
      label = [...this.state.label];
      label.push(label_selected);
    }
    this.setState({ label });
  };

  fieldSelected = (value, field) => {
    this.setState({ [field]: value });
    if (field === "selectedIEC") {
      this.handleIEC(value);
    } else if (field === "selectedSeverity") {
      this.handleSeverity(value);
    } else if (field === "selectedStatus") {
      this.updateHandleStatus(value);
    } else if (field === "selectedLabel") {
      this.handleLabel(value);
    }
  };

  //Clear all the filters
  clearFilter = () => {
    this.state.anomalies.map((f) => {
      return this.setState({ [f]: false });
    });
    this.setState({
      severity: [],
      status: [],
      iec: [],
      comments: false,
      tempRange: [0, 100],
      tempChanged: false,
      el_image: false,
      handheld_image: false,
      iv_curve_data: false,
    });
    this.props.onClearFilter();
  };

  //Add severity icon as per the key
  addSeverityIcon = (value) => {
    if (value === "Critical")
      return (
        <>
          <div style={{ width: 24 }}>
            <img
              alt="sevImg"
              style={{
                width: 10,
                marginTop: 0,
                marginLeft: 4,
                marginRight: 9,
              }}
              src={criticalImg}
            />
          </div>
          <div
            style={{
              width: 75,
              textAlign: "left",
            }}
          >
            <Trans>{value}</Trans>
          </div>
        </>
      );
    else if (value === "Moderate")
      return (
        <>
          <div style={{ width: 24 }}>
            <img
              alt="sevImg"
              style={{
                width: 13.5,
                marginTop: -2,
                marginLeft: 4,
                marginRight: 7,
              }}
              src={moderateImg}
            />
          </div>
          <div
            style={{
              width: 75,
              textAlign: "left",
            }}
          >
            <Trans>{value}</Trans>
          </div>
        </>
      );

    return (
      <>
        <div style={{ width: 24 }}>
          <img
            alt="sevImg"
            style={{
              width: 12,
              marginTop: 0,
              marginLeft: 4,
              marginRight: 8,
            }}
            src={minorImg}
          />
        </div>
        <div
          style={{
            width: 75,
            textAlign: "left",
          }}
        >
          <Trans>{value}</Trans>
        </div>
      </>
    );
  };

  //Add IEC icon as per the key
  addIecIcon = (value) => {
    const iecIcon =
      value === "IEC 1"
        ? IEC1
        : value === "IEC 2"
        ? IEC2
        : value === "IEC 3"
        ? IEC3
        : Uncategorized;

    return (
      <>
        <div style={{ width: 24, marginTop: 2 }}>
          <img
            alt="sevImg"
            style={{
              width: 16,
              marginTop: 0,
              marginLeft: 2,
              marginRight: 4,
            }}
            src={iecIcon}
          />
        </div>
        <div
          style={{
            minWidth: 90,
            textAlign: "left",
          }}
        >
          <Trans>{value}</Trans>
        </div>
      </>
    );
  };

  selectStyle = {
    // grid style when selected
    display: "flex",
    width: "100%",
    color: "#08001D",
    background: "#FFFFFF",
    overflow: this.props.compName === "inspectionTab" && "hidden",
    height: this.props.status
      ? 87
      : this.props.compName === "inspectionTab"
      ? 65
      : 72,
  };
  gridStyle = {
    //grid normal style
    display: "flex",
    width: "100%",
    color: "#536471",
    background: "#F0F3F7",
    height: this.props.compName === "inspectionTab" ? 65 : 72,
    overflow: this.props.compName === "inspectionTab" && "hidden",
    cursor: "pointer",
    "&:hover": {
      background: "#E5E5E5 !important",
    },
  };
  blueDiv = {
    height: "100%",
    width: "2px",
  };
  blueDivSelect = {
    height: "100%",
    width: "2px",
    background: "#5B4DD3",
    transition: "transform",
  };
  selectedCount = {
    // count style
    fontFamily: "Montserrat",
    color: "#5B4DD3",
  };

  //Renders the filter dialog
  render() {
    const { classes } = this.props;
    const { anomalies } = this.state;
    let defectCount = 0;
    this.state.anomalies.map((f) => {
      return this.state[f] ? (defectCount = defectCount + 1) : null;
    });
    let anomalyHasCount = 0;

    this.state.images.map((f) => {
      return this.state[f] ? (anomalyHasCount = anomalyHasCount + 1) : null;
    });

    return (
      <Card className={classes.card}>
        <CardContent style={{ padding: 0 }}>
          <DialogContent
            dividers
            className={classes.filterDialogContent}
            style={{ paddingTop: 0, border: 0 }}
          >
            <Grid
              container
              alignItems="flex-start"
              className={classes.filterSidebar}
            >
              <div style={{ width: "100%", background: "#F0F3F7" }} />
              <Grid
                item
                xs={12}
                onClick={() => {
                  return this.setState({ current: "severity" });
                }}
                style={
                  this.state.current === "severity"
                    ? this.selectStyle
                    : this.gridStyle
                }
              >
                <div
                  style={
                    this.state.current === "severity"
                      ? this.blueDivSelect
                      : this.blueDiv
                  }
                />
                <Typography xs={4} varient="body1" className={classes.comStyle}>
                  <Trans>Severity</Trans>
                  {this.state.severity.length ? (
                    <p style={{ ...this.selectedCount, margin: "-1px 0" }}>
                      {this.state.severity.length} <Trans>Selected</Trans>
                    </p>
                  ) : null}
                </Typography>
              </Grid>
              {/* For Internal Benchmark Filter display Anomaly Name */}
              {anomalies.length ? (
                <Grid
                  item
                  xs={12}
                  onClick={() => {
                    return this.setState({ current: "anomalyName" });
                  }}
                  style={
                    this.state.current === "anomalyName"
                      ? this.selectStyle
                      : this.gridStyle
                  }
                >
                  <div
                    style={
                      this.state.current === "anomalyName"
                        ? this.blueDivSelect
                        : this.blueDiv
                    }
                  />
                  <Typography
                    xs={4}
                    varient="body1"
                    className={classes.comStyle}
                  >
                    <Trans>Anomaly Name</Trans>
                    {defectCount ? (
                      <p style={{ ...this.selectedCount, margin: "-2px 0" }}>
                        {defectCount} <Trans>Selected</Trans>
                      </p>
                    ) : null}
                  </Typography>
                </Grid>
              ) : null}
              {!this.props.status && (
                <Grid
                  item
                  xs={12}
                  onClick={() => {
                    return this.setState({ current: "status" });
                  }}
                  style={
                    this.state.current === "status"
                      ? this.selectStyle
                      : this.gridStyle
                  }
                >
                  <div
                    style={
                      this.state.current === "status"
                        ? this.blueDivSelect
                        : this.blueDiv
                    }
                  />
                  <Typography
                    xs={4}
                    varient="body1"
                    className={classes.comStyle}
                  >
                    <Trans>Status</Trans>
                    {this.state.status.length ? (
                      <p style={{ ...this.selectedCount, margin: "-1px 0" }}>
                        {this.state.status.length} <Trans>Selected</Trans>
                      </p>
                    ) : null}
                  </Typography>
                </Grid>
              )}

              <Grid
                item
                xs={12}
                onClick={() => {
                  return this.setState({ current: "iecs" });
                }}
                style={
                  this.state.current === "iecs"
                    ? this.selectStyle
                    : this.gridStyle
                }
              >
                <div
                  style={
                    this.state.current === "iecs"
                      ? this.blueDivSelect
                      : this.blueDiv
                  }
                />
                <Typography xs={4} varient="body1" className={classes.comStyle}>
                  <Trans>IEC CoA</Trans>
                  {this.state.iec.length ? (
                    <div style={this.selectedCount}>
                      {this.state.iec.length} <Trans>Selected</Trans>
                    </div>
                  ) : null}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                onClick={() => {
                  return this.setState({ current: "anomaly has" });
                }}
                style={
                  this.state.current === "anomaly has"
                    ? this.selectStyle
                    : this.gridStyle
                }
              >
                <div
                  style={
                    this.state.current === "anomaly has"
                      ? this.blueDivSelect
                      : this.blueDiv
                  }
                />
                <Typography xs={4} varient="body1" className={classes.comStyle}>
                  <Trans>Anomaly Has</Trans>
                  {anomalyHasCount ? (
                    <div style={this.selectedCount}>
                      {anomalyHasCount} <Trans>Selected</Trans>
                    </div>
                  ) : null}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                onClick={() => {
                  return this.setState({ current: "temperature" });
                }}
                style={
                  this.state.current === "temperature"
                    ? this.selectStyle
                    : this.gridStyle
                }
              >
                <div
                  style={
                    this.state.current === "temperature"
                      ? this.blueDivSelect
                      : this.blueDiv
                  }
                />

                <Typography xs={4} varient="body1" className={classes.comStyle}>
                  <Trans>Temperature Difference (∆T)</Trans>
                  {this.state.tempChanged ? (
                    <div style={this.selectedCount}>
                      {" "}
                      <Trans>Selected</Trans>
                    </div>
                  ) : null}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              direction="column"
              justifyContent="space-between"
              alignItems="baseline"
              style={{ padding: "13px 8px", width: "90%", overflow: "hidden" }}
            >
              {anomalies.length && this.state.current === "anomalyName" ? (
                <div style={{ height: "300px", overflowY: "scroll" }}>
                  <Typography className={classes.subText}>
                    <Trans>Anomalies Name</Trans>
                  </Typography>
                  <Grid
                    container
                    alignItems="center"
                    style={{ paddingLeft: "6px" }}
                  >
                    {anomalies.map((data) => {
                      return (
                        <Grid item xs={12} key={data}>
                          <CustomCheckbox
                            checked={this.state[data]}
                            onChange={(e) => {
                              return this.handleAnomalyName(e);
                            }}
                            name={data}
                            label={data}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </div>
              ) : this.state.current === "severity" ? (
                /*Severity filter*/
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography className={classes.subText}>
                    <Trans>Severity</Trans>
                  </Typography>
                  {severityButtons.map((btnValue) => {
                    return (
                      <Button
                        key={btnValue}
                        size="small"
                        variant="outlined"
                        classes={{ root: classes.fltBtn }}
                        onClick={() => {
                          this.fieldSelected(btnValue, "selectedSeverity");
                          this.setState({ selectedSeverity: btnValue });
                        }}
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          marginBottom: "8px",
                          border: this.state.severity.includes(btnValue)
                            ? "1px solid #5B4DD3"
                            : "1px solid #e4eaf5",
                        }}
                      >
                        {this.addSeverityIcon(btnValue)}
                      </Button>
                    );
                  })}
                </div>
              ) : this.state.current === "status" ? (
                /* status Filter*/
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography className={classes.subText}>
                    <Trans>Status</Trans>
                  </Typography>
                  {anomalyStatusData.map((item, id) => {
                    return (
                      <div key={id}>
                        <AnomalyStatus
                          statusType={item.type}
                          statusLabel={item.label}
                          statusView={item.view}
                          handleStatus={this.updateHandleStatus}
                          status={this.state.status}
                        />
                      </div>
                    );
                  })}
                </div>
              ) : this.state.current === "iecs" ? (
                /*IEC filter*/
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography className={classes.subText}>IEC CoA</Typography>
                  {iecButtons.map((iecValue) => {
                    return (
                      <Button
                        key={iecValue}
                        size="small"
                        variant="outlined"
                        classes={{ root: classes.fltBtn }}
                        onClick={() => {
                          this.fieldSelected(iecValue, "selectedIEC");
                        }}
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          marginBottom: "8px",
                          flexWrap: "nowrap",
                          border: this.state.iec.includes(iecValue)
                            ? "1px solid #5B4DD3"
                            : "1px solid #e4eaf5",
                        }}
                      >
                        {this.addIecIcon(iecValue)}
                      </Button>
                    );
                  })}
                </div>
              ) : this.state.current === "labels" ? (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography className={classes.subText}>Labels</Typography>
                  {labelButtons.map((labelValue) => {
                    return (
                      <Button
                        key={labelValue}
                        size="small"
                        variant="outlined"
                        classes={{ root: classes.fltBtn }}
                        onClick={() => {
                          this.fieldSelected(labelValue, "selectedLabel");
                        }}
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          marginBottom: "8px",
                          flexWrap: "nowrap",
                          border: this.state.label.includes(labelValue)
                            ? "1px solid #5B4DD3"
                            : "1px solid #e4eaf5",
                        }}
                      ></Button>
                    );
                  })}
                </div>
              ) : this.state.current === "anomaly has" ? (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography className={classes.subText}>
                    <Trans>Anomaly Has</Trans>
                  </Typography>
                  <div style={{ marginLeft: "6px" }}>
                    {imageOptions.map((objImage) => {
                      return (
                        <Grid key={objImage.name} container alignItems="center">
                          <CustomCheckbox
                            value={this.state[objImage.value]}
                            checked={this.state[objImage.value]}
                            onChange={(e) => {
                              return this.handleAnomalyHas(e, objImage.value);
                            }}
                            label={objImage.name}
                          />
                        </Grid>
                      );
                    })}
                  </div>
                </div>
              ) : this.state.current === "temperature" ? (
                /* Temperature range filter */
                <TemperatureRange
                  chartFilter
                  tempRange={this.state.tempRange}
                  onHandleSlider={this.handleSlider}
                  showInstruction
                />
              ) : null}
            </Grid>
          </DialogContent>
          <Divider />
          <DialogActions
            style={{
              position: "sticky",
              bottom: 0,
              padding: "8px 16px",
            }}
          >
            <CustomButton
              text={"Clear"}
              type={"cancel"}
              onClick={() => {
                this.clearFilter();
              }}
            />
            <CustomButton
              text={"Apply"}
              onClick={() => {
                this.handleApply();
              }}
            />
          </DialogActions>
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles)(Filter);
