import MuiAlert from "@material-ui/lab/Alert";
import PropTypes from "prop-types";
import React from "react";
import { Snackbar } from "@material-ui/core";
import { connect } from "react-redux";

//To show alert message
const Alert = ({ alerts }) => {
  return (
    alerts !== null &&
    alerts.length > 0 &&
    alerts.map((alert) => {
      return (
        <Snackbar
          key={alert.alertType}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={3000}
          open
          onClose={false}
        >
          <MuiAlert elevation={6} variant="filled" severity={alert.alertType}>
            {alert.msg}
          </MuiAlert>
        </Snackbar>
      );
    })
  );
};

Alert.propTypes = {
  alerts: PropTypes.any.isRequired,
};

//updating current state to redux
const mapStateToProps = (state) => {
  return {
    alerts: state.alert,
  };
};

export default connect(mapStateToProps)(Alert);
