import React, { useEffect, useState } from "react";
import styles from "../users/user.style";
import { Grid, Switch, Typography, withStyles } from "@material-ui/core";
import RoleSelectDropdown from "../users/roleSelectDropdown";
import { allRequestHandler } from "../../api";
import config from "../../config";

const requestURL = config.hostName;

const UserListCard = ({ classes, user, usersListData, index }) => {
  const [isSwitchOn, setIsSwitchOn] = useState(user.has_site_access);
  const loggedInUserType = sessionStorage.getItem("userRole");
  const [selectedRole, setSelectedRole] = useState(user.role);
  const [showDesription, setShowDescription] = useState(
    "Access only to selected sites."
  );

  //update role description based on role type.
  useEffect(() => {
    let updatedRole = selectedRole;

    if (updatedRole?.includes("_")) {
      if (updatedRole === "SITE_ADMIN") {
        updatedRole = "Site Admin";
      } else if (updatedRole === "CLIENT_USER") {
        updatedRole = "User";
      } else {
        updatedRole = "General Admin";
      }
    }

    let desc = "";
    switch (updatedRole) {
      case "Site Admin":
        desc =
          "Access to selected sites, manage users (add/edit/delete) only for selected sites.";
        break;
      case "General Admin":
        desc =
          "Access to all sites, manage users (add/edit/delete) for all sites";
        break;
      default:
        desc = "Access only to selected sites.";
        break;
    }
    setShowDescription(desc);
  }, [selectedRole]);

  //Update site access for user.
  const fetch = async (data) => {
    await allRequestHandler({
      requestType: "PUT",
      requestUrl: `${requestURL}/api/sites/${usersListData.plant_id}/users`,
      requestData: {
        user_id: user.user_id,
        site_access: data.isSwitch,
        role:
          data.role === "General Admin"
            ? "CLIENT_ADMIN"
            : data.role === "Site Admin"
            ? "SITE_ADMIN"
            : "CLIENT_USER",
      },
    });
  };

  //handle switch button and handle role update
  const handleSwitchButton = (e) => {
    setIsSwitchOn(e.target.checked);

    //update string to match at the time of API request.
    let updateRole = selectedRole;
    if (updateRole === "SITE_ADMIN") {
      updateRole = "Site Admin";
    } else if (updateRole === "CLIENT_ADMIN") {
      updateRole = "General Admin";
    } else {
      updateRole = "User";
    }
    const dataObj = {
      role: updateRole,
      isSwitch: e.target.checked,
    };
    fetch(dataObj);
  };

  //handle role select and put method.
  const handleRoleSelect = (data) => {
    setSelectedRole(data.role);
    const dataObj = {
      role: data?.role,
      isSwitch: isSwitchOn,
    };
    fetch(dataObj);
  };

  return (
    <div
      className={classes.UserListCard}
      style={{ backgroundColor: "#FFF", margin: "5px 12px 5px 12px" }}
    >
      <Grid container style={{ alignItems: "center", width: "85vw" }}>
        <Grid item xs={1} className={classes.textUserList}>
          <span> {index} </span>
        </Grid>
        <Grid item xs={2} className={classes.gridUser}>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            className={classes.nameAvtar}
          >
            <span className={classes.avtarText}>
              {user.first_name.charAt(0)}
              {user.last_name.charAt(0)}
            </span>
          </Grid>

          <span className={classes.firstLastName}>
            <span>
              {user.first_name} {user.last_name}
            </span>
          </span>
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.emailUser}>{user.email_id}</Typography>
        </Grid>
        <Grid item xs={1} className={classes.gridUser}>
          <span style={{ marginLeft: "10px" }}>
            {user.active ? (
              <div className={classes.success}>{"Active"}</div>
            ) : (
              <div className={classes.danger}>{"Inactive"}</div>
            )}
          </span>
        </Grid>
        <Grid item xs={1} className={classes.textUserList}>
          <span>
            <Switch
              defaultChecked={user.has_site_access}
              disabled={
                selectedRole === "General Admin" ||
                selectedRole == "CLIENT_ADMIN"
                  ? true
                  : false
              }
              color="primary"
              onChange={handleSwitchButton}
              style={{ color: "#7363FB" }}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </span>
        </Grid>
        <Grid item xs className={classes.roleDrop}>
          <span>
            <RoleSelectDropdown
              variant={"standard"}
              loggedInUserType={loggedInUserType}
              isViewOption={false}
              isSitesAccessUsersList={true}
              isToggle={
                selectedRole === "General Admin" ||
                selectedRole == "CLIENT_ADMIN"
                  ? false
                  : isSwitchOn
              }
              selectedRoleDisplayInView={user.role}
              selectedValue={
                user.role === "CLIENT_ADMIN"
                  ? "General Admin"
                  : user.role === "SITE_ADMIN"
                  ? "Site Admin"
                  : "User"
              }
              returnSelectedRole={(data) => handleRoleSelect(data)}
            />
          </span>
        </Grid>
        <Grid item xs className={classes.descriptionUsersRole}>
          {showDesription}
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(UserListCard);
