//sort list based on name
export const nameSort = (users, isAscending) => {
  let newIsAscending = isAscending;
  const sortUsers = [...users].sort((a, b) => {
    if (isAscending) {
      newIsAscending = false;
      return a.first_name.localeCompare(b.first_name);
    } else {
      newIsAscending = true;
      return b.first_name.localeCompare(a.first_name);
    }
  });
  return { sortUsers, isAscending: newIsAscending };
};

//sort list based on email
export const emailSort = (users, isAscending) => {
  let newIsAscending = isAscending;
  const sortUsers = [...users].sort((a, b) => {
    if (isAscending) {
      newIsAscending = false;
      return a.email_id.localeCompare(b.email_id);
    } else {
      newIsAscending = true;
      return b.email_id.localeCompare(a.email_id);
    }
  });
  return { sortUsers, isAscending: newIsAscending };
};

//sort list based on status
export const statusSort = (users, isAscending) => {
  let newIsAscending = isAscending;
  const sortUsers = [...users].sort((a, b) => {
    if (isAscending) {
      newIsAscending = false;
      if (b.active > a.active) return -1;
      if (b.active < a.active) return 1;
      return 0;
    } else {
      newIsAscending = true;
      if (b.active < a.active) return -1;
      if (b.active > a.active) return 1;
      return 0;
    }
  });
  return { sortUsers, isAscending: newIsAscending };
};
