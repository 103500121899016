import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
} from "@material-ui/core";

import React from "react";
import { Trans } from "react-i18next";
import { URLS } from "../../constants";
import { allRequestHandler } from "../../api";
import { connect } from "react-redux";
import i18n from "i18next";
import styles from "./settings.style";

//handle severity if changed
class ChangeSeverity extends React.Component {
  state = {
    allData: this.props.allDefects,
    selectedSeverity: null,
  };

  //Handles the toggle switch
  handleSwitch = (index) => {
    this.setState((prevState) => {
      return {
        [index]: !prevState[index],
      };
    });
  };

  //Handles the change in severity
  handleSeverity = (e, val) => {
    const filterObj = val;
    filterObj.custom_severity_type = e.target.value;
    this.setState({ selectedSeverity: filterObj });
  };

  //Update severity of an defect
  updateSeverity = async () => {
    if (this.state.selectedSeverity) {
      await allRequestHandler({
        requestType: "PUT",
        requestUrl: URLS.UPDATE_DEFECT_SEVERITY,
        requestData: {
          custom_defect_list: [
            {
              custom_severity_type:
                this.state.selectedSeverity.custom_severity_type,
              defect_type_id: this.state.selectedSeverity.defect_type_id,
            },
          ],
        },
      });

      this.props.notifyMsg("success", "Defect Severity Changed");
      this.setState({ selectedSeverity: null });
    }
  };

  //Renders the option to change severity of a defect
  render() {
    const { classes } = this.props;
    const CustomMenuItem = (severityValue) => (
      <MenuItem
        value={severityValue}
        ListItemClasses={{
          root: classes.lists,
          selected: classes.selectedList,
        }}
      >
        {" "}
        <Trans>{severityValue}</Trans>{" "}
      </MenuItem>
    );
    return (
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell
                className={classes.tableCell}
                style={{ borderRight: "1px solid #E4EAF5", width: "50%" }}
              >
                {" "}
                <Trans>Default Severity</Trans>
              </TableCell>
              <TableCell className={classes.tableCell} style={{ width: "50%" }}>
                {" "}
                <Trans>Updated Severity</Trans>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.allData &&
              this.state.allData.map((def, index) => {
                return (
                  <TableRow hover key={def.defect_type_id}>
                    <TableCell
                      className={classes.bodyText}
                      style={{ borderRight: "1px solid #E4EAF5" }}
                    >
                      {i18n.t(def.default_defect_name)}
                    </TableCell>
                    <TableCell className={classes.bodyText}>
                      <Grid container alignItems="center">
                        <div style={{ minWidth: 40, marginRight: 20 }}>
                          {!def.custom_severity_type && (
                            <Switch
                              onChange={() => {
                                return this.handleSwitch(index);
                              }}
                              size="small"
                              color="primary"
                              style={{ color: "#FFFFFF" }}
                              name={def.default_defect_name}
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                          )}
                        </div>

                        {(def.custom_severity_type || this.state[index]) && (
                          <>
                            <FormControl className={classes.formControl}>
                              <Select
                                defaultValue={
                                  def.custom_severity_type
                                    ? def.custom_severity_type
                                    : def.default_severity_type
                                }
                                MenuProps={{
                                  getContentAnchorEl: null,
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                  },
                                  classes: { paper: classes.paperStyle },
                                }}
                                className={classes.selectEmpty}
                                onChange={(e) => {
                                  return this.handleSeverity(e, def);
                                }}
                              >
                                {CustomMenuItem("Critical")}
                                {CustomMenuItem("Moderate")}
                                {CustomMenuItem("Minor")}
                              </Select>
                            </FormControl>

                            <Button
                              className={classes.saveBtn}
                              onClick={() => {
                                return this.updateSeverity();
                              }}
                            >
                              {" "}
                              <Trans>Save</Trans>{" "}
                            </Button>
                          </>
                        )}
                      </Grid>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

//updating current langugae state to redux
const mapStateToProps = (state) => {
  return {
    language: state.language.selectedLanguage,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(ChangeSeverity));
