import "./index.css";

import {
  AppBar,
  Button,
  Grid,
  Snackbar,
  Toolbar,
  Typography,
} from "@material-ui/core";

import InputBase from "@material-ui/core/InputBase";
import MuiAlert from "@material-ui/lab/Alert";
import React from "react";
import SearchIcon from "@material-ui/icons/Search";
import VideoDetail from "./videoDetail";
import VideoList from "./videoList";
import { styles } from "./Help.style";
import videosapi from "./videosapi";
import { withStyles } from "@material-ui/core/styles";

//Help component
class Help extends React.Component {
  //Initialize states
  constructor(props) {
    super(props);
    this.state = {
      videos: [],
      selectedVideo: null,
    };
  }

  //These functions are called when the component is mounted (in the initial render)
  async componentDidMount() {
    //Get the videos on the initial render
    const response = await videosapi.get();
    // skipcq Avoid using setState in componentDidMount JS-0442
    this.setState({
      videos: response.data,
    });
  }

  //Handles the search of video and updates the state
  onSearchSubmit = async () => {
    const response = await videosapi.get();
    this.setState({
      videos: response.data,
    });
  };

  //handles the selected video and updates the state
  handleVideoSelect = (video) => {
    this.setState({
      selectedVideo: video,
    });
  };

  //Renders the help page
  render() {
    const { classes } = this.props;

    return (
      <>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={3000}
          open={this.state.useSnackbar}
          onClose={this.handleCloseSnackbar}
        >
          <MuiAlert elevation={6} variant="filled" severity="info">
            {" "}
            {this.state.displayMsg}{" "}
          </MuiAlert>
        </Snackbar>
        <AppBar
          position="static"
          style={{
            backgroundColor: "white",
            color: "black",
            boxShadow: "none",
          }}
        >
          <Toolbar>
            <Typography variant="h6" color="inherit" className={classes.title}>
              Help
            </Typography>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
              />
            </div>
          </Toolbar>
          <div>
            <Button className={classes.bottonls}>Filter</Button>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
            >
              Attachements
            </Button>
            <Button className={classes.bottonls}>Comment</Button>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
            >
              Reports
            </Button>
            <Button className={classes.bottonls}>Commented Deffects</Button>
          </div>
        </AppBar>
        <Grid className={classes.align}>
          <div className={classes.root}>
            <Grid container spacing={3}>
              <VideoDetail
                video={
                  this.state.selectedVideo
                    ? this.state.selectedVideo
                    : this.state.videos[0]
                }
              />

              <VideoList
                videos={this.state.videos}
                onVideoSelect={this.handleVideoSelect}
              />
            </Grid>
          </div>
        </Grid>
      </>
    );
  }
}
export default withStyles(styles)(Help);
