const styles = {
  cookieConsent: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    background: "rgba(240, 230, 230, 0.137)",
    zIndex: 1000,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  consentContainer: {
    position: "absolute",
    left: 0,
    bottom: 0,
    zIndex: 1100,
    minHeight: "208px",
    width: "541px",
    background: "#FFFFFF",
    border: "1px solid #F0F4FA",
    boxSizing: "border-box",
    boxShadow: "0px 16px 24px rgba(0, 0, 0, 0.05)",
    borderRadius: "8px",
    display: "flex",
    "@media only screen and (max-width: 700px)": {
      width: "98vw",
    },
  },
  breadCrumb: {
    flex: 0.3,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media only screen and (max-width: 700px)": {
      flex: 0.2,
    },
  },
  consentSection: {
    flex: 0.7,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: 12,
    "@media only screen and (max-width: 700px)": {
      flex: 0.8,
      padding: 0,
    },
  },
  titleText: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "40px",
    color: "#2A0950",
  },
  subTitleText: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "13.5px",
    lineHeight: "24px",
    color: "#2A0950",
  },
  buttonSection: {
    marginTop: 10,
  },
  cookiePopUpContainer: {
    position: "absolute",
    width: "520px",
    height: "98vh",
    maxHeight: "800px",
    background: "#FFFFFF",
    border: "1px solid #F0F4FA",
    boxShadow: "0px 16px 24px rgba(0, 0, 0, 0.05)",
    borderRadius: "8px",
    "@media only screen and (max-width: 700px)": {
      width: "98vw",
    },
  },
  header: {
    height: "8%",
    width: "100%",
    padding: " 0 32px",
  },
  bodySection: {
    height: "84%",
    // width: "100%",
    overflowY: "scroll",
    padding: " 0 32px",
  },
  footer: {
    height: "8%",
    // width: "100%",
    padding: " 0 32px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  popUpTitle: {
    fontSize: "14px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "24px",
    letterSpacing: "normal",
    color: "#536471",
    fontFamily: "Montserrat",
    fontWeight: "600",
  },
  closeIcon: {
    cursor: "pointer",
    color: "#536471",
  },
  privacyPolicySection: {
    "& > a": {
      textDecoration: "underline !important",
    },
  },
  manageCookiesBlock: {
    width: "100%",
    background: "#FFFFFF",
    border: "1px solid #DDE5EC",
    borderRadius: "8px",
    marginBottom: "16px",
  },
  manageCookiesHeader: {
    // width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: " 0 16px",
  },
  manageCookiesBody: {
    padding: " 8px 16px",
  },
  cookieListingContainer: {
    position: "absolute",
    width: "520px",
    height: "656px",
    maxHeight: "656px",
    background: "#FFFFFF",
    border: "1px solid #F0F4FA",
    boxShadow: "0px 16px 24px rgba(0, 0, 0, 0.05)",
    borderRadius: "8px",
    "@media only screen and (max-width: 700px)": {
      width: "98vw",
    },
  },
  mobilePadding: {
    "@media only screen and (max-width: 700px)": {
      paddingLeft: 15,
    },
  },
  cookieViewDetail: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // padding: 16,
  },
};

export default styles;
