import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  Snackbar,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";

import CheckDefects from "./ChangeSeverity";
import MuiAlert from "@material-ui/lab/Alert";
import { Navigate } from "react-router-dom";
import PointDownImg from "../../assets/img/pointDownImg.svg";
import React from "react";
import ReactGA from "react-ga";
import RenameDefects from "./RenameDefects";
import { Trans } from "react-i18next";
import { URLS } from "../../constants";
import _ from "lodash";
import { allRequestHandler } from "../../api";
import { connect } from "react-redux";
import styles from "./settings.style";
import { trackId } from "../../config";
import { withStyles } from "@material-ui/core/styles";

ReactGA.initialize(trackId);

//Configuration page
class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allDefects: null,
      useSnackbar: false,
      displayMsg: null,
      sevText: "info",
      tabValue: 0,
      getCurrency: null,
      selectedCurrency: null,
    };
  }

  //These functions are called when the page is mounted (on the initial render)
  componentDidMount() {
    // For Google Analytics
    ReactGA.pageview(window.location.pathname);

    //Get the default settings
    this.callDefaultApi();
  }

  //API call to get the default settings
  callDefaultApi = async () => {
    const getData = await allRequestHandler({
      requestType: "GET",
      requestUrl: URLS.CUSTOME_DEFECT_DICTIONARY,
    });

    // On session timeout redirect
    if (getData && getData.status && getData.status === 401) {
      sessionStorage.setItem("jwtToken", "");
      this.setState({ redirect: true });
    } else {
      const getCurrency = await allRequestHandler({
        requestType: "GET",
        requestUrl: URLS.GET_CURRENCY,
      });

      this.setState({
        allDefects: getData,
        getCurrency,
        selectedCurrency: getCurrency?.currency_code,
      });
    }
  };

  //Closing the snackbar (warnings, info and errors alert )
  handleCloseSnackbar = () => {
    this.setState({ useSnackbar: false });
  };

  //Notification message
  notifyMsg = (sev, msg) => {
    this.setState({
      useSnackbar: true,
      displayMsg: msg,
      sevText: sev,
    });
  };

  //Handles the change in input fields
  handleChange = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };

  //Handles the change in currency
  handleCurrency = async (event) => {
    this.setState({ selectedCurrency: event.target.value });

    const getCurrency = this.state.getCurrency;
    const filterCurrency = getCurrency.currency_options.find((data) => {
      return data?.currency_code === event.target.value;
    });
    getCurrency.currency_code = filterCurrency?.currency_code;
    getCurrency.currency_id = filterCurrency.currency_id;
    getCurrency.currency_symbol = filterCurrency.currency_symbol;

    await allRequestHandler({
      requestType: "PATCH",
      requestUrl: `${URLS.GET_CURRENCY}/${getCurrency?.currency_code}`,
      requestData: getCurrency,
    });

    this.notifyMsg("success", "Currency updated successfully.");
  };

  //Renders the settings page
  render() {
    const { classes } = this.props;

    // On session timeout redirect
    if (this.state.redirect) return <Navigate replace to="/" />;

    return (
      <>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={3000}
          open={this.state.useSnackbar}
          onClose={this.handleCloseSnackbar}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            severity={this.state.sevText}
          >
            {" "}
            {this.state.displayMsg}{" "}
          </MuiAlert>
        </Snackbar>

        <Grid
          container
          justifyContent="space-between"
          className={classes.header}
        >
          <Typography
            className={classes.title}
            style={{ marginLeft: `${this.props.toggleMenu && "30px"}` }}
          >
            <Trans>Configurations</Trans>
          </Typography>
        </Grid>

        <Grid container>
          <Grid item xs={12} lg={10} className={classes.cardBox}>
            <Tabs
              indicatorColor="primary"
              textColor="primary"
              value={this.state.tabValue}
              onChange={this.handleChange}
              classes={{ root: classes.tabHeader }}
            >
              <Tab
                classes={{ root: classes.tabLabel }}
                label={<Trans>Rename Anomalies</Trans>}
              />
              <Tab
                classes={{ root: classes.tabLabel }}
                label={<Trans>Change Severity Of Anomalies</Trans>}
                data-intercom-target="change-anomalies-severity"
              />
              <Tab
                classes={{ root: classes.tabLabel }}
                label={<Trans>Set Currency</Trans>}
                data-intercom-target="set-currency"
              />
            </Tabs>

            <div className={classes.padd20}>
              {this.state.tabValue === 0 ? (
                <Grid container>
                  <Grid item xs>
                    <RenameDefects
                      allDefects={
                        this.state.allDefects
                          ? _.sortBy(
                              this.state.allDefects.custom_defect_list,
                              "default_defect_name"
                            )
                          : null
                      }
                      notifyMsg={(sev, msg) => {
                        return this.notifyMsg(sev, msg);
                      }}
                    />
                  </Grid>

                  <Grid item xs className={classes.outerBox}>
                    <Typography className={classes.renameTxt}>
                      <Trans>Renaming defects</Trans>
                      <img src={PointDownImg} alt="" />
                    </Typography>
                    <Typography className={classes.renameSub}>
                      <Trans>
                        This will overwrite defect names in the application and
                        downloaded reports will have renamed defect names.
                      </Trans>
                    </Typography>
                  </Grid>
                </Grid>
              ) : this.state.tabValue === 1 ? (
                <Grid container>
                  <Grid item xs>
                    <CheckDefects
                      allDefects={
                        this.state.allDefects
                          ? _.sortBy(
                              this.state.allDefects.custom_defect_list,
                              "default_defect_name"
                            )
                          : null
                      }
                      notifyMsg={(sev, msg) => {
                        return this.notifyMsg(sev, msg);
                      }}
                    />
                  </Grid>
                  <Grid item xs className={classes.outerBox}>
                    <Typography className={classes.renameTxt}>
                      <Trans>Changing Anomalies Severity</Trans>
                      <img src={PointDownImg} alt="" />
                    </Typography>
                    <Typography className={classes.renameSub}>
                      <Trans>
                        Changing Anomalies severity will update stats in the
                        application with updated Anomalies severity.
                      </Trans>
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                <Grid container alignItems="center">
                  <Grid item xs={3}>
                    <Typography
                      className={classes.bodyText}
                      style={{ paddingTop: 12 }}
                    >
                      <Trans>Currency</Trans>
                    </Typography>
                  </Grid>
                  <Grid item xs={4} data-intercom-target="set-currency-form">
                    <FormControl className={classes.formControl}>
                      <Select
                        fullWidth
                        value={this.state.selectedCurrency}
                        MenuProps={{
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                        }}
                        variant="outlined"
                        className={classes.selectEmpty}
                        onChange={(e) => {
                          return this.handleCurrency(e);
                        }}
                      >
                        {this.state.getCurrency &&
                          this.state.getCurrency.currency_options.map(
                            (data) => {
                              return (
                                <MenuItem
                                  key={data?.currency_code}
                                  ListItemClasses={{
                                    root: classes.lists,
                                    selected: classes.selectedList,
                                  }}
                                  className={classes.MenuItem}
                                  value={data?.currency_code}
                                >
                                  {data?.currency_code}
                                </MenuItem>
                              );
                            }
                          )}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              )}
            </div>
          </Grid>
        </Grid>
      </>
    );
  }
}

//Calling redux to get the selected language
const mapStateToProps = (state) => {
  return {
    language: state.language.selectedLanguage,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(Settings));
