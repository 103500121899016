import React, { useState } from "react";
import { Typography, withStyles } from "@material-ui/core";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import styles from "./cookieConsent.style";

//view cookies
function CookieView({
  classes,
  item: { title, description, host, duration, type },
}) {
  const [showMore, setShowMore] = useState(false);

  //view item
  const ViewItem = ({ detailName, detailValue }) => {
    return (
      <div className={classes.cookieViewDetail}>
        <Typography
          className={classes.subTitleText}
          style={{ color: "#536471" }}
        >
          {detailName ? detailName : " - "}
        </Typography>
        <Typography
          className={classes.subTitleText}
          style={{ color: "#536471" }}
        >
          {detailName === "Host" ? (
            <a
              target="_blank"
              rel="noreferrer"
              className={classes.subTitleText}
              style={{
                color: "#536471",
                cursor: "pointer",
              }}
              href={detailValue}
            >
              {detailValue ? detailValue : " - "}
            </a>
          ) : detailValue ? (
            detailValue
          ) : (
            " - "
          )}
        </Typography>
      </div>
    );
  };

  return (
    <div className={classes.manageCookiesBlock}>
      <div
        style={{
          borderBottom: showMore ? "1px solid #DDE5EC" : "none",
          minHeight: 120,
          // width: "100%",
          padding: " 0 16px",
        }}
      >
        <div
          onClick={() => {
            return setShowMore(!showMore);
          }}
          style={{ padding: "8px 0", textAlign: "justify", cursor: "pointer" }}
          role="button"
          aria-hidden="true"
        >
          <Typography
            className={classes.subTitleText}
            style={{
              color: "#536471",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              padding: 0,
              margin: 0,
            }}
          >
            {" "}
            {showMore ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
            {title}
          </Typography>
          <p
            className={classes.subTitleText}
            style={{
              color: "#536471",
              padding: 0,
              margin: 0,
            }}
          >
            {description}
          </p>
        </div>
      </div>
      {showMore && (
        <div className={classes.manageCookiesBody}>
          <ViewItem detailName="Host" detailValue={host} />
          <ViewItem detailName="Duration" detailValue={duration} />
          <ViewItem detailName="Type" detailValue={type} />
        </div>
      )}
    </div>
  );
}

export default withStyles(styles)(CookieView);
