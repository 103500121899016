import { Box, Typography } from "@material-ui/core";

import Config from "../../../config";
import { Line } from "rc-progress";
import React from "react";
import { Trans } from "react-i18next";
import { allRequestHandler } from "../../../api";
import styles from "../../defectMap/defectMap.style";
import { withStyles } from "@material-ui/core/styles";

const requestUrl = Config.hostName;

//common component for file upload
class fileUpload extends React.Component {
  state = {
    progressValue: 0,
    progressMax: 0,
    showProgress: false,
  };

  //handles the file input
  handleFileInput = async (e) => {
    const files = e.target.files;
    let fileObj = [];
    Object.entries(files).forEach((fileData) => {
      const file = fileData[1];
      fileObj = [
        ...fileObj,
        {
          file_name: file.name,
          content_type: file.type,
          content_length: file.size,
          attachment_type: "false_positive_proof",
        },
      ];
    });

    // let updatefile
    const listArray = await allRequestHandler({
      requestType: "POST",
      requestUrl: `${requestUrl}/api/attachments/aws_params`,
      requestData: { attachment_list: fileObj },
    });

    if (listArray && listArray.aws_params && listArray.aws_params.length) {
      listArray.aws_params.map((getUrl, index) => {
        const data = new FormData();
        data.append("key", getUrl.fields.key);
        data.append("Content-Type", getUrl.fields["Content-Type"]);
        data.append("policy", getUrl.fields.policy);
        data.append("acl", "public-read");
        data.append("success_action_status", "201");
        data.append("x-amz-algorithm", getUrl.fields["x-amz-algorithm"]);
        data.append("x-amz-credential", getUrl.fields["x-amz-credential"]);
        data.append("x-amz-date", getUrl.fields["x-amz-date"]);
        data.append("x-amz-signature", getUrl.fields["x-amz-signature"]);
        data.append("file", files[index], getUrl.file_name);

        const xhr = new XMLHttpRequest();
        xhr.upload.onloadstart = (x) => {
          this.setState({
            showProgress: true,
            progressValue: 0,
            progressMax: x.total,
          });
        };
        xhr.upload.onprogress = (p) => {
          this.setState({
            progressValue: p.loaded,
            progressMax: p.total,
          });
        };
        xhr.upload.onloadend = () => {
          this.setState({
            showProgress: false,
          });
        };
        xhr.open("POST", getUrl.url);
        return xhr.send(data);
      });

      this.props.setData(listArray.aws_params);
    }

    this.props.setInput(true);
  };

  //Renders a custom file input dialog
  render() {
    const { classes } = this.props;
    return (
      <>
        {this.state.showProgress && (
          <Line
            percent={(this.state.progressValue / this.state.progressMax) * 100}
            strokeWidth="2"
            strokeColor="#0882F3"
          />
        )}
        <label
          component="div"
          className={classes.dropField}
          htmlFor="fileUpload"
        >
          <Typography className={classes.titleText}>
            <Box className={`${classes.selectLink} ${classes.titleText}`}>
              <label
                className={`${classes.selectLink} ${classes.titleText}`}
                htmlFor="fileUpload"
              >
                <Trans>Select file</Trans>
              </label>
            </Box>{" "}
          </Typography>
        </label>
        <input
          className={classes.fileInput}
          multiple
          id="fileUpload"
          type="file"
          onChange={(e) => {
            this.handleFileInput(e);
          }}
        />
      </>
    );
  }
}

export default withStyles(styles)(fileUpload);
