import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

import ProLabel from "../ProLabel";
import React from "react";
import { Trans } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";

//styles for toggle switch component
const styles = () => {
  return {
    groupToggle: {
      // marginLeft: "24px",
      width: "fit-content",
      height: "32px",
      borderRadius: "8px",
      display: "flex",
      textAlign: "center",
      color: "#536471",
      justifyContent: "space-between",
      "@media only screen and (max-width: 600px)": {
        marginLeft: 0,
        marginTop: 12,
      },
    },
    rootToggle: {
      minWidth: 76,
      whiteSpace: "nowrap",
      backgroundColor: "#FFFFFF",
      borderRadius: "8px",
      outline: "none",
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      color: "#8D95A2",
      border: "1px solid #F0F3F7 !important",
      "&:hover": {
        backgroundColor: "#FFFFFF",
      },
      "&:focus": {
        backgroundColor: "#FFFFFF",
        outline: "none",
      },
    },
    labelToggle: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "20px",
      textTransform: "none",
    },
    Start: {
      borderTopRightRadius: "0px !important",
      borderBottomRightRadius: "0px !important",
      height: "32px",
      backgroundColor: "#F0F3F7 !important",
      borderRadius: "8px !important",
      color: "#545B65 !important",
      alignSelf: "center",
      outline: "none !important",
    },
    Middle: {
      height: "32px",
      backgroundColor: "#F0F3F7 !important",
      color: "#545B65 !important",
      alignSelf: "center",
      outline: "none !important",
    },
    End: {
      borderTopLeftRadius: "0px !important",
      borderBottomLeftRadius: "0px !important",
      height: "32px",
      backgroundColor: "#F0F3F7 !important",
      borderRadius: "8px !important",
      color: "#545B65 !important",
      alignSelf: "center",
      outline: "none !important",
    },
    proLabel: {
      position: "absolute",
      top: "-3px",
      right: 45,
      transform: "rotate(-40deg)",
    },
  };
};

class CustomToggle extends React.Component {
  //Renders a custom styled toggle switch
  render() {
    const { classes } = this.props;

    return (
      <ToggleButtonGroup
        exclusive
        value={this.props.value}
        onChange={this.props.onChange}
        classes={{ root: classes.groupToggle }}
        data-intercom-target={this.props.intercom}
      >
        {this.props.btnGroup.map((obj) => {
          return (
            <ToggleButton
              disabled={this.props.value === obj.value ? true : false}
              classes={{
                root: classes.rootToggle,
                label: classes.labelToggle,
                selected: classes[obj.cssPlace],
              }}
              value={obj.value}
              key={obj.value}
            >
              <Trans>{obj.name}</Trans>
              {obj.proValue && (
                <div className={classes.proLabel}>
                  <ProLabel marginRight="0" marginLeft="5px" show />
                </div>
              )}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
    );
  }
}

export default withStyles(styles)(CustomToggle);
