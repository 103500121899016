import React, { useState } from "react";
import { Typography, withStyles } from "@material-ui/core";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import CustomSwitch from "./Switch";
import styles from "./cookieConsent.style";

//manage cookies
function ManageCookie({
  classes,
  getCookieListing,
  onChangeHandler,
  item: { cookieName, isActive, cookieTitle, cookieText },
}) {
  const [showMore, setShowMore] = useState(false);

  //view cookie list
  const viewListingHandler = () => {
    getCookieListing();
  };

  return (
    <div
      className={classes.manageCookiesBlock}
      style={{ height: showMore ? "180px" : "48px" }}
    >
      <div
        className={classes.manageCookiesHeader}
        style={{
          borderBottom: showMore ? "1px solid #DDE5EC" : "none",
          height: showMore ? "46px" : "100%",
        }}
      >
        <Typography
          className={classes.subTitleText}
          style={{
            color: "#536471",
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => {
            return setShowMore(!showMore);
          }}
        >
          {" "}
          {showMore ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
          {cookieTitle}
        </Typography>
        <Typography
          className={classes.subTitleText}
          style={{ color: "#5ADB84" }}
        >
          {isActive ? (
            "Always Active"
          ) : (
            <CustomSwitch
              onChangeHandler={onChangeHandler}
              cookieName={cookieName}
            />
          )}
        </Typography>
      </div>
      {showMore && (
        <div className={classes.manageCookiesBody}>
          <p className={classes.subTitleText} style={{ color: "#536471" }}>
            {cookieText}
          </p>
          <Typography
            className={classes.subTitleText}
            style={{
              color: "#536471",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={viewListingHandler}
          >
            View Details
          </Typography>
        </div>
      )}
    </div>
  );
}

export default withStyles(styles)(ManageCookie);
