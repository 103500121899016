import { Button } from "@material-ui/core";
import React from "react";
import { Trans } from "react-i18next";

function CustomBtn({
  onClickHandler,
  dataTarget,
  title,
  color,
  variant,
  className,
}) {
  return (
    <Button
      variant={variant}
      color={color}
      className={className}
      onClick={onClickHandler}
      data-intercom-target={dataTarget}
    >
      <Trans>{title}</Trans>
    </Button>
  );
}

export default CustomBtn;
