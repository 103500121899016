import { Button, Grid, Typography, withStyles } from "@material-ui/core";
import React, { useEffect, useRef } from "react";

import CheckImg from "./img/check.svg";
import { connect } from "react-redux";
import styles from "./product.style";

//view plans
const ViewPlans = ({ classes, onHandleShowTrial, product }) => {
  const containerRef = useRef();
  const features = [
    { title: "Access to All Filters", checked: true },
    { title: "Defects and Team Management", checked: true },
    { title: "Unlimited Internal & External Users", checked: true },
    { title: "Custom Reports", checked: true },
    { title: "Bulk Data Upload ", checked: true },
    { title: "Internal & External Benchmarking", checked: true },
  ];

  useEffect(() => {
    //pro trial handler
    const handler = (event) => {
      if (containerRef && containerRef.current) {
        if (!containerRef.current.contains(event.target)) {
          onHandleShowTrial(false);
        }
      }
    };

    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  return (
    <Grid
      container
      className={classes.popupContainer}
      direction="column"
      wrap="nowrap"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <div
        style={{
          width: "400px",
          height: "515px",
          background: "white",
          boxShadow: "0px 4px 12px rgba(78, 89, 109, 0.05)",
          borderRadius: "8px",
        }}
        ref={containerRef}
      >
        <div className={classes.trialHeader}>
          <Typography className={classes.trialHeaderTitle}>
            {product.remaining_days
              ? `${product.remaining_days} ${
                  product.remaining_days > 1 ? "days" : "day"
                } left in your Pro Trial `
              : "your Pro Trial expired "}
            <span role="img" aria-label="Surprised">
              {product.remaining_days ? "😮" : "😟"}
            </span>{" "}
          </Typography>
          <p className={classes.trialHeaderParagraph}>
            {product.remaining_days
              ? "Post trial you will be switched to basic plan, upgrade now to keep using PRO features."
              : "You have been switched to basic plan, upgrade now to keep using PRO features."}
          </p>
        </div>
        <div style={{ padding: "30px" }}>
          <Typography
            className={classes.trialHeaderTitle}
            style={{ color: "#536471" }}
          >
            pro features
          </Typography>
          <div style={{ background: "#FFFFFF" }}>
            {features &&
              features.length > 0 &&
              features.map((feature) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                    key={feature.title}
                  >
                    <img
                      src={CheckImg}
                      alt="CheckImg"
                      style={{ marginRight: "10px" }}
                    />
                    <span
                      className={classes.headTitle}
                      style={{
                        fontWeight: "500",
                        fontSize: "14px",
                        color: "#536471",
                        margin: "0",
                        lineHeight: "30px",
                      }}
                    >
                      {feature.title}
                    </span>
                  </div>
                );
              })}
          </div>
        </div>
        <div style={{ paddingLeft: "30px" }}>
          <Button
            variant="contained"
            style={{
              color: "white",
              background:
                "linear-gradient(266.69deg, #59AFFF 0.23%, #0882F3 99.28%)",
              display: "none",
            }}
          >
            View Plans
          </Button>
        </div>
      </div>
    </Grid>
  );
};

//update product status
const mapStateToProps = (state) => {
  return {
    product: state.product,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(ViewPlans));
