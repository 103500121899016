// skipcq Avoid using wildcard imports JS-C1003

import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";

import React, { Component } from "react";

import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { connect } from "react-redux";
import i18n from "i18next";

// skipcq Avoid using wildcard imports JS-C1003
am4core.useTheme(am4themes_animated);

//Power loss chart
class PowerLossChart extends Component {
  //Handles the chart creation
  createChart = (chartData, data) => {
    //Initialize chart type
    const chart = am4core.create("chartdiv1", am4charts.XYChart);

    //Chart properties
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.maxTooltipDistance = -1;
    chart.cursor.lineY.disabled = true;
    chart.responsive.enabled = true;
    chart.responsive.useDefault = false;
    chart.scrollbarX = new am4core.Scrollbar();
    chart.scrollbarX.parent = chart.bottomAxesContainer;

    //Handles the chart horizontal scroll
    function customizeGrip(grip) {
      grip.icon.disabled = true;
    }

    customizeGrip(chart.scrollbarX.startGrip);
    customizeGrip(chart.scrollbarX.endGrip);
    chart.scrollbarX.thumb.minWidth = 50;
    chart.scrollbarX.thumb.maxWidth = 50;
    chart.zoomOutButton.disabled = true;

    /* Create axes */
    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "date";
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.grid.template.strokeWidth = 0;
    categoryAxis.renderer.labels._template.fill = "#536471";
    categoryAxis.renderer.labels._template.fontFamily = "Montserrat";
    categoryAxis.renderer.labels._template.fontSize = 8;
    categoryAxis.renderer.labels._template.fontWeight = 500;
    categoryAxis.cursorTooltipEnabled = false;

    chart.events.on(
      "ready",
      () => {
        categoryAxis.zoomToIndexes(0, 3);
      },
      this
    );

    /* Create value axis */
    const valueAxis1 = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis1.renderer.grid.template.strokeDasharray = "5,5";
    valueAxis1.renderer.grid.template.strokeOpacity = ".1";
    valueAxis1.min = 0;
    // valueAxis1.strictMinMax = true;
    valueAxis1.calculateTotals = true;
    valueAxis1.title.text =
      this.props.chartType === "power"
        ? `${i18n.t("Power")} ${data.power_units}`
        : `${i18n.t("Revenue")} ${data.currency}`;
    valueAxis1.title.fontFamily = "Montserrat";
    valueAxis1.renderer.labels._template.fontFamily = "Montserrat";
    valueAxis1.renderer.labels._template.fill = "#536471";
    valueAxis1.renderer.labels._template.fontWeight = 500;
    valueAxis1.fontSize = 12;
    valueAxis1.fontWeight = 500;
    valueAxis1.fontFamily = "Montserrat";
    valueAxis1.fill = "#7E909D";
    valueAxis1.cursorTooltipEnabled = false;
    const valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis2.renderer.opposite = true;
    valueAxis2.title.text = i18n.t("Anomalies");
    valueAxis2.renderer.grid.template.disabled = true;
    valueAxis2.title.fontFamily = "Montserrat";
    valueAxis2.renderer.labels._template.fontFamily = "Montserrat";
    valueAxis2.renderer.labels._template.fill = "#536471";
    valueAxis2.renderer.labels._template.fontWeight = 500;
    valueAxis2.fontSize = 12;
    valueAxis2.fontWeight = 500;
    valueAxis2.fontFamily = "Montserrat";
    valueAxis2.fill = "#7E909D";
    valueAxis2.cursorTooltipEnabled = false;
    /* Create series */
    const columnSeries = chart.series.push(new am4charts.ColumnSeries());
    columnSeries.name = "Power loss";
    columnSeries.dataFields.valueY =
      this.props.chartType === "power"
        ? "power_loss_recovered"
        : "revenue_loss_recovered";
    columnSeries.dataFields.categoryX = "date";
    
    //Add colors to the chart
    columnSeries.stroke =
      this.props.chartType === "power"
        ? am4core.color("#FFECBA")
        : am4core.color("#DEEFFF");
    columnSeries.fill =
      this.props.chartType === "power"
        ? am4core.color("#FFECBA")
        : am4core.color("#DEEFFF");

    columnSeries.sequencedInterpolation = true;
    columnSeries.defaultState.transitionDuration = 1000;
    columnSeries.sequencedInterpolationDelay = 100;
    columnSeries.columns.template.strokeOpacity = 0;
    columnSeries.columns.template.background.fillOpacity = 0.8;
    columnSeries.columns.template.width = 35;

    //Chart bullets
    const chartbullet =
      this.props.chartType === "power"
        ? `<svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="3" cy="3" r="3" fill="#FFC101"/>
    </svg>`
        : `<svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="3" cy="3" r="3" fill="#DEEFFF"/>
    </svg>`;

    //Handles tooltip
    const chartValue =
      this.props.chartType === "power"
        ? `<td style = "color: #536471; font-size : 12px;font-weight : 500;margin : 10px; text-align : left">{power_loss_recovered} &nbsp;${data.power_units}</td>`
        : `<td style = "color: #536471; font-size : 12px;font-weight : 500;margin : 10px; text-align : left">${data.currency} &nbsp;{revenue_loss_recovered}</td>`;

    const tooltipHTML = `<div><p style = "font-family : Montserrat; color : #08001D; font-size : 12px; text-align: left; font-weight : 500; margin : 7px">{date}</p>
    <table>
    <tr>
      <th align="left">
      <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="3" cy="3" r="3" fill="#0084FF"/>
    </svg> &nbsp;<span style = "color: #536471; font-size : 12px; text-align: left; font-weight : 500; margin : 10px">${i18n.t(
      "Anomalies Processed"
    )}</span></th>
      <td style = "color: #536471; font-size : 12px;font-weight : 500;margin : 10px">{anomalies_processed}</td>
    </tr>
    <tr>
      <th align="left">
      ${chartbullet}
       &nbsp;<span style = "color: #536471; font-size : 12px; text-align: left; font-weight : 500;margin : 10px">${
         this.props.chartType === "power"
           ? i18n.t("Power Loss recovered")
           : i18n.t("Revenue Loss recovered")
       }</span></th>
      ${chartValue}
    </tr>
    </table>
    </div>
    `;

    //Handles chart labels
    const labelText =
      this.props.chartType === "power"
        ? i18n.t("Power Loss recovered")
        : i18n.t("Revenue Loss recovered");
    columnSeries.tooltipHTML = tooltipHTML;
    columnSeries.tooltip.background.pointerLength = 0;
    columnSeries.tooltip.getFillFromObject = false;
    columnSeries.tooltip.background.fill = am4core.color("#FFFFFF");
    columnSeries.tooltip.autoTextColor = false;

    columnSeries.columns.template.propertyFields.fillOpacity = "fillOpacity";
    columnSeries.columns.template.propertyFields.stroke = "stroke";
    columnSeries.columns.template.propertyFields.strokeWidth = "strokeWidth";
    columnSeries.columns.template.propertyFields.strokeDasharray = "columnDash";
    columnSeries.tooltip.label.textAlign = "middle";
    columnSeries.legendSettings.labelText = `{color}${labelText}`;
    const strokeColor =
      this.props.chartType === "power"
        ? am4core.color("#FFC101")
        : am4core.color("#0084FF");
    const chartColor =
      this.props.chartType === "power"
        ? am4core.color("#FFECBA")
        : am4core.color("#DEEFFF");

    columnSeries.columns.template.adapter.add("fill", () => {
      const pattern = new am4core.LinePattern();
      pattern.width = 10;
      pattern.height = 10;
      pattern.strokeWidth = 1;
      pattern.stroke = strokeColor;
      pattern.rotation = 45;
      return pattern;
    });

    columnSeries.columns.template.background.adapter.add(
      "fill",
      (fill, target) => {
        return target.dataItem ? chartColor : fill;
      }
    );

    //Customize line series
    const lineSeries = chart.series.push(new am4charts.LineSeries());
    lineSeries.name = "Anomalies";
    lineSeries.dataFields.valueY = "anomalies_processed";
    lineSeries.dataFields.categoryX = "date";

    lineSeries.stroke = am4core.color("#5B48FB");
    lineSeries.fill = am4core.color("#5B48FB");
    lineSeries.strokeWidth = 3;
    lineSeries.yAxis = valueAxis2;
    lineSeries.legendSettings.labelText = i18n.t("Anomalies Processed");
    const bullet = lineSeries.bullets.push(new am4charts.Bullet());
    bullet.fill = am4core.color("#fff"); // tooltips grab fill from parent by default
    const circle = bullet.createChild(am4core.Circle);
    circle.radius = 4;
    circle.fill = am4core.color("#fff");
    circle.strokeWidth = 3;
    bullet.fillOpacity = 1;
    bullet.strokeOpacity = 1;

    //Add legends
    chart.legend = new am4charts.Legend();
    chart.legend.useDefaultMarker = true;
    chart.legend.labels.template.fontFamily = "Montserrat";
    chart.legend.valueLabels.template.fontFamily = "Montserrat";
    chart.legend.itemContainers.template.clickable = false;
    chart.legend.itemContainers.template.focusable = false;
    chart.legend.itemContainers.template.cursorOverStyle =
      am4core.MouseCursorStyle.default;

    const marker = chart.legend.markers.template.children.getIndex(0);
    marker.cornerRadius(12, 12, 12, 12);
    const markerTemplate = chart.legend.markers.template;
    markerTemplate.width = 7;
    markerTemplate.height = 7;

    chart.data = chartData;
  };

  //Creates chart on the initial render
  componentDidMount() {
    if (this.props.chartData)
      this.createChart(this.props.chartData, this.props.data);
  }

  //Update chart when data or language is updated
  componentDidUpdate(prevProps) {
    const { chartType, chartData, data, language } = this.props;
    if (
      chartType !== prevProps.chartType ||
      chartData !== prevProps.chartData ||
      language !== prevProps.language
    ) {
      this.createChart(chartData, data);
    }
  }

  //Renders the power and revenue loss chart (line chart)
  render() {
    return (
      <div
        id="chartdiv1"
        style={{
          width: "100%",
          height: "400px",
        }}
      />
    );
  }
}

//Calling redux to get information about the selected language
const mapStateToProps = (state) => {
  return {
    language: state.language.selectedLanguage,
  };
};

export default connect(mapStateToProps)(PowerLossChart);
