const en = {
  translations: {
    Dashboard: "Dashboard",
    Portfolio: "Portfolio",
    "Dashboard Tour": "Dashboard Tour",
    "All Inspections": "All Inspections",
    "Download Reports": "Download Reports",
    Site: "Site",
    "Select Plant": "Select Plant",
    "Report Type": "Report Type",
    "Select Report": "Select Report",
    Download: "Download",
    "Anomalies/MWp": "Anomalies/MWp",
    "Anomaly Status": "Anomaly Status",
    Filters: "Filters",
    "DC Capacity": "DC Capacity",
    "AC Capacity": "AC Capacity",
    "Power Loss": "Power Loss",
    "Revenue Loss/yr": "Revenue Loss/yr",
    ANOMALIES: "ANOMALIES",
    Status: "Status",
    Severity: "Severity",
    IEC: "IEC",
    Total: "Total",
    Pending: "Pending",
    Resolved: "Resolved",
    "In Progress": "In Progress",
    "Not Found": "Not Found",
    "False +ve": "False +ve",
    Critical: "Critical",
    Moderate: "Moderate",
    Minor: "Minor",
    "IEC 1": "IEC 1",
    "IEC 2": "IEC 2",
    "IEC 3": "IEC 3",
    Uncategorized: "Uncategorized",
    "LOSS RECOVERED": "LOSS RECOVERED",
    Power: "Power",
    Revenue: "Revenue",
    All: "All",
    "Anomaly Name": "Anomaly Name",
    "Vegetation growth causing hotspots": "Vegetation growth causing hotspots",
    "Junction box highlighted module": "Junction box highlighted module",
    "Multi cell hotspot and degraded module":
      "Multi cell hotspot and degraded module",
    "Isolated / Underperforming string": "Isolated / Underperforming string",
    "Short circuited module": "Short circuited module",
    "Multi hotspot affected module": "Multi hotspot affected module",
    "Isolated/Underperforming module": "Isolated/Underperforming module",
    "Soiling causing hotspots": "Soiling causing hotspots",
    "Wrongly inclined table": "Wrongly inclined table",
    "Hotspot affected modules": "Hotspot affected modules",
    "Foreign object causing hotspot": "Foreign object causing hotspot",
    "Severe Multicell Hotspot affected module":
      "Severe Multicell Hotspot affected module",
    "Hotspot affected and cell mismatch module":
      "Hotspot affected and cell mismatch module",
    "Missing module": "Missing module",
    "Bypass diode activated module": "Bypass diode activated module",
    "Isolated / Underperforming table": "Isolated / Underperforming table",
    "Hotspot affected module": "Hotspot affected module",
    "Single hotspot affected module": "Single hotspot affected module",
    "Substring short circuited module": "Substring short circuited module",
    "Underperforming Module": "Underperforming Module",
    "LA / TP shadow causing hotspots": "LA / TP shadow causing hotspots",
    "IEC Standards": "IEC Standards",
    "Anomaly Has": "Anomaly Has",
    Comments: "Comments",
    "EL Image": "EL Image",
    "IV Curve Image": "IV Curve Image",
    "Handheld Image": "Handheld Image",
    "Temperature Difference (∆T)": "Temperature Difference (∆T)",
    Clear: "Clear",
    Apply: "Apply",
    "Power (KWp)": "Power (KWp)",
    "Anomalies Processed": "Anomalies Processed",
    "Inspection Date": "Inspection Date",
    "Inspection Type": "Inspection Type",
    // "Report Type": "Report Type",
    "INTERNAL BENCHMARK": "INTERNAL BENCHMARK",
    "Site Details": "Site Details",
    "Sites Tour": "Sites Tour",
    "Inspection Date : Recent First": "Inspection Date : Recent First",
    "Inspection Date :Oldest First": "Inspection Date : Oldest First",
    "COD : Recent First": "COD : Recent First",
    "COD : Oldest First": "COD : Oldest First",
    COD: "COD",
    "Add Image": "Add Image",
    "Search by site name , location": "Search by site name , location",
    Inspections: "Inspections",
    "View Anomalies": "View Anomalies",
    Calculate: "Calculate",
    "SITE LAYOUT": "SITE LAYOUT",
    "Commissioning Date": "Commissioning Date",
    "Number of Panels": "Number of Panels",
    "Module Mounting Structure": "Module Mounting Structure",
    "Panel Brands & Models": "Panel Brands & Models",
    "Inverter Brands & Models": "Inverter Brands & Models",
    "Feed in tariff": "Feed in tariff",
    "Panel Maximum Power (Pmax)": "Panel Maximum Power (Pmax)",
    "Tilt Angle (Degree)": "Tilt Angle (Degree)",
    "Photovoltaic power potential (Yield)":
      "Photovoltaic power potential (Yield)",
    "Lat/Long": "Lat/Long",
    "Sites/Inspections": "Sites/Inspections",
    "Invalid date": "Invalid date",
    Edit: "Edit",
    "Ground mounted": "Ground mounted",
    Table: "Table",
    Map: "Map",
    Video: "Video",
    Attachments: "Attachments",
    Thermal: "Thermal",
    Handheld: "Handheld",
    "Actual Module Temperature": "Actual Module Temperature",
    "Hotspot Temperature": "Hotspot Temperature",
    "Module Coordinate": "Module Coordinate",
    "IEC Category": "IEC Category",
    "Site Condition": "Site Condition",
    Recommendations: "Recommendations",
    "Defective Panel": "Defective Panel",
    "Module Serial Number": "Module Serial Number",
    "Module Manufacturer Name": "Module Manufacturer Name",
    "String ID": "String ID",
    "Module Wp": "Module Wp",
    "Module Voc": "Module Voc",
    "Module Isc": "Module Isc",
    "String Current": "String Current",
    "Module Vmp": "Module Vmp",
    "Module Imp": "Module Imp",
    "Healthy Panel": "Healthy Panel",
    "Ref Module Wp": "Ref Module Wp",
    "Ref Module Voc": "Ref Module Voc",
    "Ref Module Isc": "Ref Module Isc",
    "Ref Module Vmp": "Ref Module Vmp",
    "Ref Module Imp": "Ref Module Imp",
    "Edit Panel Details": "Edit Panel Details",
    "Save Changes": "Save Changes",
    Discard: "Discard",
    "DOWNLOAD REPORTS (PDF)": "DOWNLOAD REPORTS (PDF)",
    Reports: "Reports",
    "Report Tour": "Report Tour",
    "Executive Report": "Executive Report",
    "Includes high level data of site inspection":
      "Includes high level data of site inspection",
    "Warranty Claim Report": "Warranty Claim Report",
    "Download anomalies with serial no. & manufacturer details":
      "Download anomalies with serial no. & manufacturer details",
    "Thermal Report": "Thermal Report",
    "Detailed thermal report for all the anomalies":
      "Detailed thermal report for all the anomalies",
    "Anomalies CSV": "Anomalies CSV",
    "Comma separated list of all anomalies with module co-ordinates":
      "Comma separated list of all anomalies with module co-ordinates",
    "Anomalies KML": "Anomalies KML",
    "Download kml data for all anomalies":
      "Download kml data for all anomalies",
    Portrait: "Portrait",
    Landscape: "Landscape",
    "PDF Orientation": "PDF Orientation",
    Language: "Language",
    English: "English",
    Japanese: "Japanese",
    Spanish: "Spanish",
    Cancel: "Cancel",
    "Generate & Download": "Generate & Download",
    Sections: "Sections",
    "Report Name": "Report Name",
    "Created by": "Created by",
    "Date created": "Date created",
    "REPORTS HISTORY": "REPORTS HISTORY",
    "Search By Report Name": "Search By Report Name",
    "Generate Custom Report": "Generate Custom Report",
    Rename: "Rename",
    Delete: "Delete",
    "Export As .PDF": "Export As .PDF",
    "Thermal Analysis Document": "Thermal Analysis Document",
    "View Report": "View Report",
    METRICS: "METRICS",
    "Anomaly Details": "Anomaly Details",
    // Clear: "Clear",
    Irradiation: "Irradiation",
    Back: "Back",
    Activity: "Activity",
    "Add a new comment": "Add a new comment",
    "(Data storage upto 10GB/100MW)": "(Data storage upto 10GB/100MW)",
    "Post Comment": "Post Comment",
    "Upload an Image or File": "Upload an Image or File",
    Users: "Users",
    "New User": "New User",
    Id: "Id",
    Name: "Name",
    Email: "Email",
    "Status ": "Status ",
    Role: "Role",
    "Site Access": "Site Access",
    View: "View",
    "Last Login": "Last Login",
    "Job Title": "Job Title",
    "User Details": "User Details",
    "Edit User": "Edit User",
    "Delete User": "Delete User",
    "Update User": "Update User",
    "First Name": "First Name",
    "Last Name": "Last Name",
    Username: "Username",
    Submit: "Submit",
    // Cancel: "Cancel",
    Active: "Active",
    Inactive: "Inactive",
    "All Sites": "All Sites",
    "Allowed Sites": "Allowed Sites",
    "Search by name, email": "Search by name, email",
    Configurations: "Configurations",
    "Rename Anomalies": "Rename Anomalies",
    "Change Severity Of Anomalies": "Change Severity Of Anomalies",
    "Set Currency": "Set Currency",
    "Default Name": "Default Name",
    "Default Severity": "Default Severity",
    "Updated Severity": "Updated Severity",
    "This will overwrite defect names in the application and downloaded reports will have renamed defect names.":
      "This will overwrite defect names in the application and downloaded reports will have renamed defect names.",
    "Changing Anomalies severity will update stats in the application with updated Anomalies severity.":
      "Changing Anomalies severity will update stats in the application with updated Anomalies severity.",
    Currency: "Currency",
    "My Profile": "My Profile",
    "PERSONAL INFORMATION": "PERSONAL INFORMATION",
    PASSWORD: "PASSWORD",
    "Edit Profile": "Edit Profile",
    "Change Password": "Change Password",
    Save: "Save",
    "Log Out": "Log Out",
    "Log In": "Log In",
    Password: "Password",
    "Forgot Password": "Forgot Password",
    "The terminals of the modules are open circuited. Accordingly, check the dis- connectivity point along the cables.Some of the modules are operating at higher temperature than the normal modules. The reason could be cracks, external fallen bodies, non-uniform soiling or internal cell mismatch. Perform visual inspection of this modules. If no signs of external factors, IV tracing of the module is recommended to understand the electrical impact of fault if any.":
      "The terminals of the modules are open circuited. Accordingly, check the dis- connectivity point along the cables.Some of the modules are operating at higher temperature than the normal modules. The reason could be cracks, external fallen bodies, non-uniform soiling or internal cell mismatch. Perform visual inspection of this modules. If no signs of external factors, IV tracing of the module is recommended to understand the electrical impact of fault if any.",

    " MSEDCL Rajasthan 6": " MSEDCL Rajasthan 6",
    "SECI 600": "SECI 600",
    "Rajasthan 5.4 50 MW_Bhadla": "Rajasthan 5.4 50 MW_Bhadla",
    "Rajasthan 5.1 50 MW_Bhadla": "Rajasthan 5.1 50 MW_Bhadla",
    "Rajasthan 5.3 50 MW_Bhadla": "Rajasthan 5.3 50 MW_Bhadla",
    "Isolated / Underperforming module": "Isolated / Underperforming module",
    "Wrongly inclined modules": "Wrongly inclined modules",
    "Damaged Module": "Damaged Module",
  },
};

export default en;
