const data = [
  { category: "Category", description: "Description", color: "" },
  {
    category: "New",
    description: "New anomaly reported for a module",
    color: "#149B9E",
  },
  {
    category: "Persistent",
    description: "An unresolved anomaly reported again in current inspection",
    color: "#8D95A2",
  },
  {
    category: "Recurring",
    description: "A resolved anomaly reported again in current inspection",
    color: "#FFCB05",
  },
  {
    category: "Transitioned",
    description:
      "Module has different anomaly in current inspection than the previous inspection",
    color: "#F26649",
  },
];

export default data;
