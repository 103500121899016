import "./PopUp.css";

import {
  Button,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  Hidden,
  SwipeableDrawer,
  Typography,
} from "@material-ui/core";

import AnomaliesTable from "./AnomaliesTable";
import Config from "../../config";
import CustomButton from "../common/customStyle/Button";
import CustomDropdown from "../common/customStyle/Dropdown";
import CustomFilterChip from "../common/customStyle/FilterChip";
import CustomTooltip from "../common/customStyle/Tooltip";
import DefectDetailsView from "./DefectDetailsView";
import DownloadFile from "../downloadFile";
import FilterDrawer from "./FilterDrawerNew";
import ImportDataPopup from "./bulkUpload/ImportDataPopupNew";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import ModalVideo from "react-modal-video";
import { Navigate } from "react-router-dom";
import NewLoader from "../../layout/newLoader";
import PaginationMap from "./paginationMap";
import PaginationPolygon from "./paginationPolygon";
import ProLabel from "../common/ProLabel";
import React from "react";
import ReviewAnomaly from "./bulkUpload/reviewAnomalyPopUp";
import { SET_ACTIVITY } from "../../constants/actionTypes";
import { Trans } from "react-i18next";
import { URLS } from "../../constants";
import _ from "lodash";
import { allRequestHandler } from "../../api";
import bulkUploadImg from "../../assets/img/bulkUpload.svg";
import { connect } from "react-redux";
import filterImg from "../../assets/img/filter3.svg";
import i18n from "i18next";
import leftArrowImg from "../../assets/img/leftArrow.svg";
import locationImg from "../../assets/img/location.svg";
import moment from "moment";
import { orderSectionList } from "../../utils/sortSectionList";
import rightArrowImg from "../../assets/img/rightArrow.svg";
import styles from "./defectMap.style";
import tableImg from "../../assets/img/table.png";
import { withStyles } from "@material-ui/core/styles";
import youtubeTransparent from "../../assets/img/transparentYoutube.svg";

const requestUrl = Config.hostName;

//Anomalies page
class DefectMap extends React.Component {
  //Initialize state
  constructor(props) {
    super(props);
    this.paginationRef = React.createRef();
  }
  state = {
    selectedSection: null,
    // userDetails: this.props.userDetails ? this.props.userDetails[0] : null,
    selectedSite: this.props.siteId ? this.props.siteId : null,
    toggleFilterSidebar: false,
    toggleDefectSidebar: false,
    defectData: [],
    isDigitized: false,
    isDataUpdating: false,
    allSectionList: null,
    selectedInspectionDate: null,
    fileAvailable: null,
    sectionInspectionId: null,
    mapCoordinate: null,
    mapUrl: null,
    selectedDefect: null,
    highlightMarker: null,
    allData: null,
    mapData: null,
    // selectedFilter: null,
    displayFilters: null,
    expandPanel: false,
    selectedSectionName: null,
    inspectionList: null,
    count: 0,
    selectedBaseMap: null,
    tiling_metadata: null,
    is_tiling: null,
    is_stc_temp_available: null,
    filterFaultType: null,
    showImportDataPopup: false,
    showReviewAnomalyPopup: false,
    selectedView: "map",
    bulkUploadUrl: null,
    // csvToJsonData: null,
    chipFilter: false,
    isImportingAnomaly: false,
    isOpen: false,
    width: window.innerWidth,
    // height: window.innerHeight,
    mapDuplicateData: null,
    // metadata: null,
    objectCountVal: null,
    filteredDate: null,
    randomId: Math.floor(100000 + Math.random() * 900000),
    // pagination: null,
    removedDefect: null,
    inspDateChanged: false,
    bulkUpload: false,
  };

  //Update the window width when screen is resized
  updateDimensions = () => {
    this.setState({
      width: window.innerWidth,
      // height: window.innerHeight,
    });
  };

  //These functions are called on the initial render of the component
  componentDidMount() {
    //add event listener for window resize
    window.addEventListener("resize", this.updateDimensions);

    //Fetch the defects data
    if (this.state.selectedSection) {
      this.fetchAllDefect();
    }

    // By default select site from url
    const filterList =
      this.props.userDetails &&
      this.props.userDetails.filter((site) => {
        return site.plant_id === this.props.siteId;
      })[0];

    if (filterList) {
      // skipcq Avoid using setState in componentDidMount JS-0442
      this.setState({
        selectedSection: filterList.section_list[0].section_id,
        currentSectionDataId: filterList.section_list[0].section_id,
        selectedSectionName: filterList.section_list[0].section_name,
        allSectionList: filterList,
      });
    }

    // Set default inspection date
    if (this.props.userDetails) this.handleInspectionDate();

    if (localStorage.getItem("sectionId") !== "null") {
      localStorage.setItem("tempId", localStorage.getItem("sectionId"));
      // skipcq Avoid using setState in componentDidMount JS-0442
      this.setState(
        {
          selectedSection: localStorage.getItem("sectionId"),
          currentSectionDataId: localStorage.getItem("tempId"),
          // count: this.state.count + 1
        },
        () => {
          return localStorage.setItem("sectionId", "null");
        }
      );
    }
  }

  //These functions are called when the component updates
  componentDidUpdate(prevProps, prevState) {
    //Update data when the site is changed5
    if (prevProps.userDetails !== this.props.userDetails) {
      const filterSec = this.props.userDetails.filter((data) => {
        return data.plant_id === this.props.siteId;
      })[0];

      if (
        filterSec &&
        filterSec.section_list &&
        filterSec.section_list.length
      ) {
        // skipcq Avoid usage of setState in componentDidUpdate JS-0443

        this.setState(
          {
            // userDetails: this.props.userDetails[0],
            selectedSite: this.props.siteId,
            selectedSection: filterSec.section_list[0].section_id,
            selectedSectionName:
              this.props.userDetails[0].section_list[0].section_name,
            allSectionList: this.props.userDetails[0],
          },
          () => {
            this.handleSite();
          }
        );
      }
    }

    //Update defect data when the section is changed
    // if (
    //   this.state.selectedSection &&
    //   this.state.selectedSite &&
    //   (prevState.selectedSection !== this.state.selectedSection ||
    //     prevState.selectedSite !== this.state.selectedSite)
    // ) {
    //   this.fetchAllDefect();
    //   // skipcq Avoid usage of setState in componentDidUpdate JS-0443
    //   this.setState({ displayFilters: null, filteredDate: null });
    // }

    //First time load only
    if (
      this.state.selectedSection &&
      this.state.selectedSite &&
      ((!prevState.selectedSection &&
        prevState.selectedSection !== this.state.selectedSection) ||
        (!prevState.selectedSite &&
          prevState.selectedSite !== this.state.selectedSite))
    ) {
      this.fetchAllDefect();
      // skipcq Avoid usage of setState in componentDidUpdate JS-0443
      this.setState({ displayFilters: null, filteredDate: null });
    }

    if (this.state.bulkUpload) {
      this.fetchSideListAnomalies(
        Math.ceil(this.state.objectCountVal / 100),
        this.state.filteredDate
      );
    }

    //Set defeault inspection date when the user is changed
    if (
      prevProps.userDetails !== this.props.userDetails &&
      this.state.selectedSite
    ) {
      this.handleInspectionDate();
    }

    //When the selected defect is closed, remove the highlight marker from the map
    if (prevState.selectedDefect !== this.state.selectedDefect) {
      // skipcq Avoid usage of setState in componentDidUpdate JS-0443
      this.setState({
        highlightMarker: null,
      });
    }

    //Open the defect details and highlight the marker when a defect is selected from the activity page
    if (
      this.props.fetchActivity.activity &&
      this.state.defectData.length > 0 &&
      this.state.currentSectionDataId === localStorage.getItem("tempId")
    ) {
      let selectedDefect = null;
      this.state.defectData.forEach((data) => {
        if (
          data.properties.defect_id ===
          this.props.fetchActivity.activity.defect_id
        ) {
          selectedDefect = data;
        }
      });

      if (selectedDefect) {
        // skipcq Avoid usage of setState in componentDidUpdate JS-0443
        this.setState({
          selectedDefect: selectedDefect.properties,
          toggleDefectSidebar: true,
          highlightMarker: selectedDefect,
          // count: this.state.count + 1
        });
        this.props.setActivity(null);
      }
    }

    //Close sidebar on toggle of map and table  view
    if (
      this.state.selectedView &&
      prevState.selectedView !== this.state.selectedView
    ) {
      // skipcq Avoid usage of setState in componentDidUpdate JS-0443
      this.setState({
        toggleFilterSidebar: false,
        toggleDefectSidebar: false,
        selectedDefect: null,
        highlightMarker: null,
      });
    }

    //Call defects list and map data when the data is changed
    if (prevState.randomId !== this.state.randomId) {
      // #TODO Temp. get all anomalies in single api for polygon display
      const getOffset = this.state.objectCountVal / 100;

      this.paginationRef.current = "RUNNING";
      this.fetchSideListAnomalies(
        Math.ceil(this.state.objectCountVal / 100),
        this.state.filteredDate
      );

      this.fetchPartialGeojson(Math.ceil(getOffset), this.state.filteredDate);
    }
  }

  //API call to fetch the defects list
  fetchSideListAnomalies = async (offsetCount, value) => {
    let offset = 0;
    let storeAnomalies = [];
    let offsetInspDate = "?";

    if (value) {
      let formatDate = moment(value.inspection_date).format("YYYY-MM-DD");
      offsetInspDate = `?inspection=${formatDate}&`;
    }

    for (let i = 0; i < offsetCount; i++) {
      if (this.paginationRef.current === "BREAK") {
        break;
      }

      // skipcq Found await inside of loops JS-0032
      const getAnomaliesList = await allRequestHandler({
        requestType: "GET",
        requestUrl: `${URLS.DEFECT_SECTION}${this.state.selectedSection}/inspections/anomalies${offsetInspDate}offset=${offset}`,
      });
      if (this.paginationRef.current === "BREAK") {
        break;
      }
      offset = offset + 100;
      if (
        getAnomaliesList &&
        getAnomaliesList.status &&
        getAnomaliesList.status === 401
      ) {
        sessionStorage.setItem("jwtToken", "");
        this.setState({ redirect: true });
      } else {
        if (getAnomaliesList) {
          const anomaliesArray =
            getAnomaliesList.anomalies_list &&
            getAnomaliesList.anomalies_list.length &&
            getAnomaliesList.anomalies_list.map((el) => {
              return {
                properties: {
                  ...el,
                  delta_temperature2:
                    this.state.is_stc_temp_available && el.stc_delta_temperature
                      ? el.stc_delta_temperature
                      : el.delta_temperature,
                  iec_category2: el.iec_category ? el.iec_category : -1,
                },
              };
            });

          if (anomaliesArray)
            storeAnomalies = [...storeAnomalies, ...anomaliesArray];
          if (this.paginationRef.current === "BREAK") {
            break;
          }
          if (i === 0) {
            this.setState((prevState) => {
              return {
                // allData: storeAnomalies,
                // defectData: _.orderBy(storeAnomalies, "defect_name", "asc"),
                allData: anomaliesArray,
                defectData: _.orderBy(anomaliesArray, "defect_name", "asc"),
                currentSectionDataId: prevState.selectedSection,
                count: prevState.count + 1,
              };
            });
          }
          // #TODO Critical Issue -
          // Once all Anomalies loaded, map stuck for 4-6 sec.
          else {
            this.setState({
              allData: storeAnomalies,
              defectData: _.orderBy(storeAnomalies, "defect_name", "asc"),
            });
          }
        }
      }
    }
    // return this.setState({
    //   currentSectionDataId: this.state.selectedSection,
    //   count: this.state.count + 1,
    // });
  };

  //API call to fetch the features data for map markers and polygons
  fetchPartialGeojson = async (offsetCount, value) => {
    let offset = 0;
    let geojsonArray = [];
    let offsetInspDate = "?";

    if (value) {
      let formatDate = moment(value.inspection_date).format("YYYY-MM-DD");
      offsetInspDate = `?inspection=${formatDate}&`;
    }

    for (let i = 0; i < offsetCount; i++) {
      if (this.paginationRef.current === "BREAK") {
        break;
      }

      // skipcq Found await inside of loops JS-0032
      const featuresData = await allRequestHandler({
        requestType: "GET",
        requestUrl: `${URLS.DEFECT_SECTION}${this.state.selectedSection}/inspections/features${offsetInspDate}offset=${offset}`,
      });
      if (this.paginationRef.current === "BREAK") {
        break;
      }
      offset = offset + 100;

      if (featuresData && featuresData.status && featuresData.status === 401) {
        sessionStorage.setItem("jwtToken", "");
        this.setState({ redirect: true });
      } else {
        if (
          featuresData &&
          featuresData.feature_list &&
          featuresData.feature_list.length
        ) {
          geojsonArray = [...geojsonArray, ...featuresData.feature_list];
          if (this.paginationRef.current === "BREAK") {
            break;
          }
          if (i === 0) {
            this.setState({
              mapData: featuresData.feature_list,
              mapDuplicateData: featuresData.feature_list,
            });
          } else {
            this.setState({
              mapData: geojsonArray,
              mapDuplicateData: geojsonArray,
            });
          }

          // let length = 500;

          // if (i === 0) {
          //   this.setState({
          //     mapData: featuresData.feature_list,
          //     mapDuplicateData: featuresData.feature_list,
          //   });
          // } else if (geojsonArray.length === length) {
          //   this.setState({
          //     mapData: geojsonArray,
          //     mapDuplicateData: geojsonArray,
          //   });
          //   length = length + 500
          // } else if (i > 0 && i == offsetCount - 1) {
          //   this.setState({
          //     mapData: geojsonArray,
          //     mapDuplicateData: geojsonArray,
          //   });
          // }
        }
      }
    }
  };

  //API call to fetch the site data
  fetchAllDefect = async (value) => {
    const localSelectedSec = this.state.selectedSection;
    this.paginationRef.current = "BREAK";
    this.setState({ defectData: [] });
    let inspDateId = "";
    let getDefectDetail = "";

    if (value) {
      inspDateId = `?inspection=${value.inspection_date}`;
    }

    this.setState({ isDataUpdating: true });

    getDefectDetail = await allRequestHandler({
      requestType: "GET",
      requestUrl: `${
        URLS.DEFECT_SECTION
      }${localSelectedSec}/inspections/metadata${(this, inspDateId)}`,
    });

    // if (getDefectDetail) {
    //   var objectCount = Math.ceil(
    //     getDefectDetail.anomaly_list_object_count / 100
    //   );
    //   this.setState({
    //     objectCountVal: objectCount,
    //   });
    // }
    // On session timeout redirect
    if (
      getDefectDetail &&
      getDefectDetail.status &&
      getDefectDetail.status === 401
    ) {
      sessionStorage.setItem("jwtToken", "");
      this.setState({ redirect: true });
    }

    if (getDefectDetail) {
      // var objectCount = Math.ceil(
      //   getDefectDetail.anomaly_list_object_count / 100
      // );
      // Get all raster tilig layout
      let allRasterLayer = {};
      if (getDefectDetail.is_tiling) {
        allRasterLayer = {
          layout_tile_layer: [],
          rgb_tile_layer: [],
          thermal_tile_layer: [],
        };

        //filtering raster tile layers
        getDefectDetail.tiling_metadata.raster_url_list.map((rasterData) => {
          if (rasterData.layout_tile_layer)
            return allRasterLayer.layout_tile_layer.push(
              rasterData.layout_tile_layer
            );

          if (rasterData.rgb_tile_layer)
            return allRasterLayer.rgb_tile_layer.push(
              rasterData.rgb_tile_layer
            );

          if (rasterData.thermal_tile_layer)
            return allRasterLayer.thermal_tile_layer.push(
              rasterData.thermal_tile_layer
            );

          return false;
        });

        //Set default layer
        if (allRasterLayer.layout_tile_layer.length)
          this.updateBaseMap("layout");
        else if (allRasterLayer.rgb_tile_layer.length)
          this.updateBaseMap("rgb");
        else this.updateBaseMap("thermal");
      }

      this.setState({
        objectCountVal: getDefectDetail.anomaly_list_object_count,
        allRasterLayer: allRasterLayer ? allRasterLayer : null,
        filterFaultType: _.orderBy(
          getDefectDetail.defects_metadata,
          "defect_type_name",
          "asc"
        ),
        isDataUpdating: false,
        isDigitized: getDefectDetail.is_digitized,
        fileAvailable: getDefectDetail.downloads,
        sectionInspectionId: getDefectDetail.section_inspection_id,
        mapCoordinate: getDefectDetail.map_overlay_coordinate,
        inspectionList: getDefectDetail.inspection_list,
        mapUrl: {
          layout_map_image_url: getDefectDetail.layout_map_image_url,
          rgb_map_image_url: getDefectDetail.rgb_map_image_url,
          thermal_map_image_url: getDefectDetail.thermal_map_image_url,
        },
        tiling_metadata: getDefectDetail.tiling_metadata,
        is_tiling: getDefectDetail.is_tiling,
        is_stc_temp_available: getDefectDetail.is_stc_temp_available,
        bulkUploadUrl: getDefectDetail.href_list,
        // metadata: getDefectDetail,
        randomId: Math.floor(100000 + Math.random() * 900000),
      });
    }
  };

  //Remove event listener on component unmount
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  //Get the basic details of a particular defect
  getDefectName = (getAllDefect) => {
    const filterList = getAllDefect;
    filterList.defects_list.map((outerDef) => {
      return getAllDefect.defect_metadata.filter((innerDef) => {
        if (outerDef.defect_type_id === innerDef.defect_type_id)
          return (
            (outerDef.defect_type = innerDef.defect_type_name),
            (outerDef.color_code = innerDef.color_code),
            (outerDef.severity_type = innerDef.severity_type),
            (outerDef.recommendation = innerDef.recommendation)
          );
        return null;
      });
    });
  };

  //Handles the site change
  handleSite = (event) => {
    this.setState({
      inspDateChanged: false,
    });
    let siteId = "";
    if (event && event.target?.value) siteId = event.target.value;
    else siteId = this.props.siteId;

    const filterList = this.props.userDetails.filter((site) => {
      return site.plant_id === siteId;
    })[0];

    // Sort Section list
    const selectSec = orderSectionList(filterList.section_list);

    this.setState(
      {
        mapData: null,
        selectedSite: siteId,
        selectedSection: selectSec[0].section_id,
        allSectionList: filterList,
        selectedSectionName: filterList.section_list[0].section_name,
        selectedBaseMap: null,
        filteredDate: filterList,
      },
      () => {
        this.fetchAllDefect();
        this.handleInspectionDate();
      }
    );
  };

  //Handles the section change
  handleSection = (event) => {
    const localAllSection = this.state.allSectionList;
    const filterSec =
      localAllSection &&
      localAllSection.section_list &&
      localAllSection.section_list.length &&
      localAllSection.section_list.filter((sec) => {
        return sec.section_id === event.target.value;
      })[0];

    // const dates = [];
    // this.state.inspectionList &&
    //   this.state.inspectionList.length &&
    //   this.state.inspectionList.forEach((arrayItem) => {
    //     dates.push(arrayItem.inspection_date);
    //   });
    // const maxDate = dates.sort().reverse()[0];
    // if (
    //   this.state.inspectionList &&
    //   this.state.inspectionList.length &&
    //   this.state.selectedInspectionDate !== maxDate
    // ) {
    //   // This is creating a issue
    //   // const prevDate = this.state.selectedInspectionDate;
    //   // // this.setState({ selectedInspectionDate: maxDate });
    //   // this.setState({ selectedInspectionDate: prevDate });

    //   this.setState({ selectedInspectionDate: maxDate });
    // }

    this.setState(
      {
        mapData: null,
        selectedSection: event.target.value,
        selectedSectionName: filterSec.section_name,
        selectedBaseMap: null,
        // count: this.state.count + 1
      },
      () => {
        let inspDate = {
          inspection_date: "",
        };
        if (this.state.selectedInspectionDate) {
          inspDate.inspection_date = this.state.selectedInspectionDate;
          this.fetchAllDefect(inspDate);
          // this.setState({
          //   inspDateChanged: true,
          // });
        }
      }
    );
  };

  //Handles the inspection date change
  handleInspectionDate = () => {
    const filterDate = this.props.userDetails.filter((site) => {
      return site.plant_id === this.state.selectedSite;
    })[0];
    if (filterDate) {
      this.setState({
        selectedDefect: null,
        mapData: null,
        selectedInspectionDate: moment(filterDate.inspection_date).format(
          "YYYY-MM-DD"
        ),
        selectedBaseMap: null,
      });
    }
  };

  //Handles the filter dialog and close the anomalies sidebar when filter dialog is opened
  handleFilterDrawer = () => {
    this.setState((prevState) => {
      return {
        toggleDefectSidebar: false,
        toggleFilterSidebar: !prevState.toggleFilterSidebar,
      };
    });
  };

  //Handles the import data popup. Close all other popups when import data popup is opened
  handleImportDataPopup = () => {
    this.setState((prevState) => {
      return {
        showImportDataPopup: !prevState.showImportDataPopup,
        showReviewAnomalyPopup: false,
        isImportingAnomaly: false,
      };
    });
  };

  //Handles the review anomaly popup. Close all other popups when review anomaly popup is opened
  handleReviewAnomalyPopup = () => {
    this.setState((prevState) => {
      return {
        showReviewAnomalyPopup: !prevState.showReviewAnomalyPopup,
        showImportDataPopup: false,
        isImportingAnomaly: false,
      };
    });
  };

  //Opens the import data popup
  handleBackToImportData = () => {
    this.setState({
      showReviewAnomalyPopup: false,
      showImportDataPopup: true,
    });
  };

  //Handles the pro update popup. Close all other popups when pro update popup is opened
  linkToProductPage = () => {
    this.props.onHandleShowPlans(true);
    this.setState({
      isImportingAnomaly: false,
      showReviewAnomalyPopup: false,
      showImportDataPopup: false,
    });
  };

  // uploadFileChange = async (file) => {
  //   if (file) {
  //     this.setState({ isImportingAnomaly: true });
  //     const fileObj = {
  //       file_name: file.name,
  //       file_type: file.type,
  //     };

  //     if (this.state.bulkUploadUrl) {
  //       try {
  //         const getUrl = await allRequestHandler({
  //           requestType: "POST",
  //           requestUrl: `${requestUrl}/${this.state.bulkUploadUrl[0].href}`,
  //           requestData: fileObj,
  //         });

  //         if (getUrl) {
  //           const data = new FormData();
  //           data.append("key", getUrl.aws_param_list.fields.key);
  //           data.append("Content-Type", file.type);
  //           data.append("policy", getUrl.aws_param_list.fields.policy);
  //           data.append("acl", "public-read");
  //           data.append("success_action_status", "201");
  //           data.append("x-amz-algorithm", "AWS4-HMAC-SHA256");
  //           data.append(
  //             "x-amz-credential",
  //             getUrl.aws_param_list.fields["x-amz-credential"]
  //           );
  //           data.append(
  //             "x-amz-date",
  //             getUrl.aws_param_list.fields["x-amz-date"]
  //           );
  //           data.append(
  //             "x-amz-signature",
  //             getUrl.aws_param_list.fields["x-amz-signature"]
  //           );
  //           data.append("file", file, getUrl.aws_param_list.file_name);

  //           const xhr = new XMLHttpRequest();
  //           // xhr.addEventListener("readystatechange", function () {
  //           //   if (this.readyState === 4) {
  //           //   }
  //           // });
  //           xhr.open("POST", getUrl.aws_param_list.url);
  //           xhr.send(data);

  //           xhr.onreadystatechange = async () => {
  //             if (xhr.readyState === 4) {
  //               // try{
  //               const csvToJson = await allRequestHandler({
  //                 requestType: "POST",
  //                 requestUrl: `${requestUrl}/api/section_inspection/e59767c5-374c-42f7-bc68-acde403c05ea/anomalies/csv_to_json_temporary`,
  //                 requestData: {
  //                   csv_url:
  //                     getUrl.aws_param_list.url +
  //                     getUrl.aws_param_list.fields.key,
  //                 },
  //               });
  //               if (csvToJson) {
  //                 this.setState((prevState) => {return {
  //                   // csvToJsonData: csvToJson,
  //                   isImportingAnomaly: false,
  //                   showReviewAnomalyPopup: !prevState.showReviewAnomalyPopup,
  //                   showImportDataPopup: false,
  //                 };});
  //               } else {
  //                 this.setState({
  //                   isImportingAnomaly: false,
  //                 });
  //               }
  //             }
  //           };
  //         }
  //       } catch (error) {
  //         this.props.callSnackbar(
  //           "Please ensure that file is a valid csv file and try again",
  //           "warning"
  //         );
  //         this.setState({ isImportingAnomaly: false });
  //       }
  //     }
  //   } else {
  //     this.props.callSnackbar("Please select a file", "warning");
  //   }
  // };

  //Update the anomaly list after the bulk upload
  updateAnomalyList = async (defectJson) => {
    if (defectJson) {
      try {
        await allRequestHandler({
          requestType: "PUT",
          requestUrl: `${requestUrl}/${this.state.bulkUploadUrl[2].href}`,
          requestData: {
            anomalies_list: defectJson,
          },
        });
        this.fetchAllDefect();
        this.setState({
          showReviewAnomalyPopup: false,
          showImportDataPopup: false,
          isImportingAnomaly: false,
          selectedView: "table",
        });
        this.props.callSnackbar("Defect list successfully imported", "success");
      } catch (error) {
        this.props.callSnackbar("errror while updating anomalies", "error");
      }
    }
  };

  //Toggles the defect drawer
  handleDefectDrawer = () => {
    this.setState((prevState) => {
      return {
        toggleFilterSidebar: false,
        toggleDefectSidebar: !prevState.toggleDefectSidebar,
        selectedDefect: null,
        highlightMarker: null,
      };
    });
  };

  //Toggles the defect sidebar
  showDefectSidebar = (value) => {
    this.setState({
      toggleDefectSidebar: true,
      selectedDefect: value.properties,
      toggleFilterSidebar: false,
    });

    this.updateSelectedDefect(value);
    this.openFullPanel();
  };

  //for panelPolygon
  showSidebar = (val) => {
    this.setState({
      toggleDefectSidebar: true,
      selectedDefect: val[0].properties,
      toggleFilterSidebar: false,
    });

    this.updateSelectedDefect(val);
    this.openFullPanel();
  };

  //Handles the update of a selected defect
  updateSelectedDefect = (value) => {
    if (value === "removeMarker") this.setState({ selectedDefect: null });

    this.setState({ highlightMarker: value });
  };

  //Handle filters applied to the anomalies page
  applyFilter = (obj, chipFilter) => {
    let filterDefects = [];
    // For Defects name Filter
    if (obj && obj.defectsFilter && obj.defectsFilter.length) {
      obj.defectsFilter.length &&
        this.state.allData.map((allDefect) => {
          return obj.defectsFilter.find((filterDef) => {
            return (
              allDefect.properties.defect_type_name ===
                filterDef.defect_type_name && filterDefects.push(allDefect)
            );
          });
        });
    } else filterDefects = this.state.allData;

    // For Severity Filter
    if (obj && obj.severity && filterDefects.length) {
      filterDefects =
        // filterDefects.length &&
        filterDefects.filter((defType) => {
          return defType.properties.severity_type === obj.severity;
        });
    }

    //For Status filter
    if (obj && obj.status && obj.status.length && filterDefects.length) {
      filterDefects =
        // filterDefects.length &&
        filterDefects.filter((value) => {
          return obj.status.includes(value.properties.status);
        });
    }

    // For commented filter
    if (obj && obj.iec_category && filterDefects.length) {
      filterDefects =
        // filterDefects.length &&
        filterDefects.filter((f) => {
          return f.properties.iec_category === obj.iec_category;
        });
    }

    if (obj && obj.label && filterDefects.length) {
      filterDefects =
        // filterDefects.length &&
        filterDefects.filter((f) => {
          return f.properties.label === obj.label;
        });
    }

    // For Temp. filter
    if (obj && obj.tempValue && filterDefects.length) {
      filterDefects =
        // filterDefects.length &&
        filterDefects.filter((f) => {
          if (this.state.is_stc_temp_available) {
            return (
              f.properties.stc_delta_temperature &&
              // !f.properties.stc_delta_temperature ||
              f.properties.stc_delta_temperature >= obj.tempValue[0] &&
              f.properties.stc_delta_temperature <= obj.tempValue[1]
            );
          }
          return (
            f.properties.delta_temperature &&
            f.properties.delta_temperature >= obj.tempValue[0] &&
            f.properties.delta_temperature <= obj.tempValue[1]
          );
        });
    }

    // For commented filter
    if (obj && obj.is_commented && filterDefects.length) {
      filterDefects =
        // filterDefects.length &&
        filterDefects.filter((f) => {
          return f.properties.is_commented;
        });
    }

    // For El Image filter
    if (obj && obj.el_image && filterDefects.length) {
      filterDefects =
        // filterDefects.length &&
        filterDefects.filter((f) => {
          return f.properties.el_image;
        });
    }

    // For hadheld filter filter
    if (obj && obj.handheld_image && filterDefects.length) {
      filterDefects =
        // filterDefects.length &&
        filterDefects.filter((f) => {
          return f.properties.handheld_image;
        });
    }

    // For thermal image filter
    if (obj && obj.thermal_image && filterDefects.length) {
      filterDefects =
        // filterDefects.length &&
        filterDefects.filter((f) => {
          return f.properties.thermal_image;
        });
    }

    // For iv_curve_data filter
    if (obj && obj.iv_curve_data && filterDefects.length) {
      filterDefects =
        // filterDefects.length &&
        filterDefects.filter((f) => {
          return f.properties.iv_curve_data;
        });
    }

    let updateObj = true;
    if (
      obj &&
      (!obj.defectsFilter || !obj.defectsFilter.length) &&
      !obj.severity &&
      (!obj.status || !obj.status.length) &&
      !obj.iec_category &&
      !obj.label &&
      !obj.tempValue &&
      !obj.is_commented &&
      !obj.el_image &&
      !obj.handheld_image &&
      !obj.iv_curve_data
    )
      updateObj = null;

    if (filterDefects && filterDefects.length) {
      const defect_ids_list = filterDefects.map(({ properties }) => {
        return properties.defect_id;
      });
      this.setState({
        defectData: filterDefects,
        defect_ids_list,
        toggleFilterSidebar: false,
        displayFilters: updateObj ? obj : updateObj,
        chipFilter,
      });
    } else {
      this.props.callSnackbar("No Defect found.");
    }
  };

  //Handles the removal of a filter chip
  handleRemoveDefect = (value, index) => {
    let filterObj = this.state.displayFilters;

    if (
      value === "severity" ||
      value === "tempValue" ||
      value === "is_commented" ||
      value === "el_image" ||
      value === "handheld_image" ||
      value === "iv_curve_data" ||
      value === "iec_category" ||
      value === "label"
    ) {
      filterObj[value] = null;
    } else if (value === "status") {
      filterObj.status.splice(index, 1);
    } else {
      const removedDefect = filterObj.defectsFilter.index;
      this.setState({ removedDefect });
      filterObj.defectsFilter.splice(index, 1);
    }

    if (
      filterObj &&
      (!filterObj.defectsFilter || !filterObj.defectsFilter.length) &&
      !filterObj.severity &&
      (!filterObj.status || !filterObj.status.length) &&
      !filterObj.iec_category &&
      !filterObj.label &&
      !filterObj.tempValue &&
      !filterObj.is_commented &&
      !filterObj.el_image &&
      !filterObj.handheld_image &&
      !filterObj.iv_curve_data
    ) {
      filterObj = null;
    }
    this.applyFilter(filterObj, true);
  };

  //Get the comments of a defect and locally update defect data
  updateDefect = async (def) => {
    const getData = await allRequestHandler({
      requestType: "GET",
      requestUrl: URLS.GET_COMMENT_LIST + def.defect_id,
    });

    getData.defect_type = def.defect_type;
    getData.color_code = def.color_code;
    getData.severity_type = def.severity_type;
    getData.recommendation = def.recommendation;

    this.setState({ selectedDefect: getData });
  };

  //Handles the change of inspection date
  handleInspDate = (event) => {
    const localInspList = this.state.inspectionList;
    const filterInspdate =
      localInspList &&
      localInspList.find((inspDate) => {
        return inspDate.inspection_date === event.target.value;
      });
    this.setState({
      selectedInspectionDate: event.target.value,
      filteredDate: filterInspdate,
      toggleDefectSidebar: false,
    });
    this.fetchAllDefect(filterInspdate);
  };

  //Handles the navigation to the next or previous defect
  handleNavigation = (defect_name, type) => {
    const sortedDefects = this.state.defectData.sort((a, b) => {
      if (!a.properties.panel_id) {
        return a.properties.defect_no - b.properties.defect_no;
      } else if (a.properties.defect_no === b.properties.defect_no) {
        return a.properties.panel_id - b.properties.panel_id;
      }
      return a.properties.defect_no - b.properties.defect_no;
    });
    const defectIndex = sortedDefects.findIndex((x) => {
      if (defect_name?.panel_id) {
        return (
          x.properties.defect_no === defect_name.defect_no &&
          x.properties.panel_id === defect_name.panel_id
        );
      } else {
        return x.properties.defect_no === defect_name.defect_no;
      }
    });

    let element = sortedDefects[0];
    if (type === "Next") {
      if (defectIndex < sortedDefects.length - 1) {
        element = sortedDefects[defectIndex + 1];
      } else {
        element = sortedDefects[0];
      }
    } else {
      if (defectIndex > 0) {
        element = sortedDefects[defectIndex - 1];
      } else {
        element = sortedDefects[sortedDefects.length - 1];
      }
    }
    // let c = 0;
    // for (c = 0; c < this.state.defectData.length; c++) {
    //   const element = this.state.defectData[c];
    //   if (defect_name && element.properties.defect_no === defect_name.defect_no)
    //     break;
    // }
    // if (type === "Next") c++;
    // else c--;
    // if (c < 0) c = this.state.defectData.length + c;
    // const data = this.state.defectData[c % this.state.defectData.length];
    this.setState({
      selectedDefect: element.properties,
      highlightMarker: element,
    });
  };

  //Update the base-map (RGB layer, thermal layer or layout layer)
  updateBaseMap = (value) => {
    this.setState((prevState) => {
      return {
        count: prevState + 1,
        selectedBaseMap: value,
      };
    });
  };

  //Handles the panel expansion
  openFullPanel = () => {
    if (!this.state.expandPanel) this.setState({ expandPanel: true });
  };

  patchDefect = (value) => {
    const localDefects = this.state.defectData;
    const getDefect = [];
    localDefects &&
      localDefects.length &&
      localDefects.find((replaceData) => {
        return (
          replaceData.properties.defect_id === value.defect_id &&
          getDefect.push(replaceData)
        );
      });

    if (getDefect && getDefect[0].properties)
      getDefect[0].properties.status = value.status;
    // after uploading proof for old False+ve disable dropdown locally
    if (value.status === "falsepositive")
      getDefect[0].properties.is_fp_proof_available = true;

    const updatedDef = localDefects.map((obj) => {
      return (
        getDefect.find((o) => {
          return o.properties.defect_id === obj.defect_id;
        }) || obj
      );
    });
    this.setState(
      {
        allData: updatedDef,
        highlightMarker: null,
        defectData: _.orderBy(updatedDef, "defect_name", "asc"),
      },
      () => {
        this.showDefectSidebar(getDefect[0]);
      }
    );
  };

  //Whenever map data is updated, update the duplicate state to keep track of changes
  updateMap = (value, id) => {
    this.setState((prevState) => {
      return {
        mapDuplicateData: prevState.mapData.map((x) => {
          if (x.properties.defect_id === id) {
            x.properties.status = value;
          }
          return x;
        }),
      };
    });
  };

  //Whenever map data is updated, update the duplicate state to keep track of changes
  updateDuplicateProperties = (value) => {
    const indexToUpdate = this.state.mapDuplicateData.findIndex((x) => {
      if (x.properties.panel_id) {
        return (
          x.properties.defect_no === value.defect_no &&
          x.properties.panel_id === value.panel_id
        );
      }
      return x.properties.defect_no === value.defect_no;
    });
    this.setState((prevState) => {
      return {
        mapDuplicateData: prevState.mapDuplicateData.map((x, i) => {
          if (i === indexToUpdate) {
            x.properties.string_reference = value.string_reference;
          }
          return x;
        }),
      };
    });
  };

  //Locally update the defect data whenever any change is made to display it on the site
  updateListLocally = (id, status) => {
    const getDefect = [];
    const localDefects = this.state.defectData;

    localDefects &&
      localDefects.length &&
      localDefects.find((replaceData) => {
        return (
          replaceData.properties.defect_id === id && getDefect.push(replaceData)
        );
      });

    if (getDefect) {
      getDefect[0].properties.status = status;
      // after uploading proof for old False+ve disable dropdown locally
      if (status === "falsepositive")
        getDefect[0].properties.is_fp_proof_available = true;
    }

    const updatedDef = localDefects.map((obj) => {
      return (
        getDefect.find((o) => {
          return o.properties.defect_id === obj.defect_id;
        }) || obj
      );
    });

    this.setState({ allData: updatedDef });
  };

  //Update the defect data whenever any change is made to display it on the site
  dataUpdated = (value, type) => {
    if (type === "comment") {
      this.setState((prevState) => {
        return {
          allData: prevState.allData.map((data) => {
            if (data.properties.defect_id === value) {
              data.properties.comment_count = data.properties.comment_count + 1;
            }
            return data;
          }),
        };
      });
    } else {
      this.setState((prevState) => {
        return {
          allData: prevState.allData.map((data) => {
            if (data.properties.defect_id === value) {
              data.properties.attachment_count =
                data.properties.attachment_count + 1;
            }
            return data;
          }),
        };
      });
    }
  };

  //Closes the sidebar in table view
  closeTableSidebar = () => {
    this.setState((prevState) => {
      return {
        toggleFilterSidebar: false,
        toggleDefectSidebar: !prevState.toggleDefectSidebar,
        selectedDefect: null,
      };
    });
  };

  //Opens the modal
  handleOpenModal = () => {
    this.setState({ isOpen: true });
  };

  handleBulkUploadData = (state) => {
    this.setState({
      bulkUpload: state,
    });
  };

  //Renders the anomalies page with the map and anomalies list
  render() {
    const { classes } = this.props;
    const siteMenuItems = [];
    const sectionMenuItems = [];
    let inspectionDateMenuItems = [];
    let isKarnataka1Site = "";
    let defectFromActivity = "";
    // Dropdown object for Plant list
    if (this.props.userDetails) {
      _.orderBy(this.props.userDetails, ["plant_name"], ["asc"]).map(
        (objPlant) => {
          return siteMenuItems.push({
            name: objPlant.plant_name,
            value: objPlant.plant_id,
            id: objPlant.plant_id,
            location: objPlant.location,
          });
        }
      );
    }

    //show viewport of a 2nd anomaly for karnataka 1 site.
    if (siteMenuItems && siteMenuItems.length) {
      const filterKarnatakaSite = siteMenuItems.find((val) => {
        return val.name === "Karnataka 1";
      });
      if (
        filterKarnatakaSite &&
        filterKarnatakaSite.id === this.state.selectedSite
      ) {
        isKarnataka1Site = filterKarnatakaSite.name;
      }
    }

    //select particular defect viewport if user coming from activity page
    if (this.state.selectedDefect) {
      if (this.state.mapData) {
        defectFromActivity = this.state.mapData.find((val) => {
          return (
            val.properties.defect_no === this.state.selectedDefect.defect_no
          );
        });
      }
    }

    // Dropdown object for Section list
    if (this.state.allSectionList) {
      orderSectionList(this.state.allSectionList.section_list).map(
        (objSection) => {
          return sectionMenuItems.push({
            name: objSection.section_name,
            value: objSection.section_id,
            id: objSection.section_id,
          });
        }
      );
    }

    // Dropdown object for inspection date
    if (this.state.inspectionList) {
      _.orderBy(this.state.inspectionList, "inspection_date", "desc").map(
        (inspDate) => {
          return inspectionDateMenuItems.push({
            value: inspDate.inspection_date,
            name: moment(inspDate.inspection_date).format("D MMM, YYYY"),
          });
        }
      );

      // inspectionDateMenuItems = inspectionDateMenuItems.reverse();
    }

    // On session timeout redirect
    if (this.state.redirect) return <Navigate replace to="/" />;

    return (
      <>
        {!this.state.defectData ||
        !this.state.defectData.length ||
        !this.state.mapUrl ? (
          <div
            style={{
              position: "absolute",
              top: "0px",
              left: "0px",
              height: "calc(100vh)",
              width: "calc(100vw)",
              background: "transparent",
              zIndex: "1500",
            }}
          />
        ) : (
          ""
        )}
        <Grid
          container
          direction="column"
          style={{ height: "100%", flexWrap: "nowrap" }}
        >
          <Grid item>
            <Grid
              container
              alignItems="center"
              className={classes.padd10R20}
              style={{
                backgroundColor: "white",
                minHeight: 72,
              }}
            >
              <Typography
                className={[classes.title, classes.marMob].join(" ")}
                style={{ marginLeft: `${this.props.toggleMenu && "30px"}` }}
              >
                <Trans>Anomalies</Trans>
              </Typography>

              <Hidden smUp>
                <div style={{ flex: "auto", width: "100%" }} />
              </Hidden>
              <Grid item data-intercom-target="site-section-dropdown">
                <Grid container>
                  {/* Site Dropdown */}
                  <CustomDropdown
                    selectValue={this.state.selectedSite}
                    onHandleSelectChange={this.handleSite}
                    menuItems={siteMenuItems}
                    secondItemName="location"
                    minWidth={180}
                  />

                  {/* Section Dropdown */}
                  <div style={{ margin: "0 5px" }}>
                    <CustomDropdown
                      selectValue={this.state.selectedSection}
                      onHandleSelectChange={(e) => {
                        return this.handleSection(e);
                      }}
                      menuItems={sectionMenuItems}
                      minWidth={140}
                    />
                  </div>
                </Grid>
              </Grid>

              <CustomTooltip
                title={i18n.t("Filter")}
                placement="bottom-end"
                arrow
                classes={{
                  tooltip: classes.tooltipInfo,
                  arrow: classes.anomalieTooltipArrow,
                }}
              >
                <div>
                  <CustomButton
                    variant="outlined"
                    btnIcon={filterImg}
                    type="cancel"
                    onClick={() => {
                      return this.handleFilterDrawer();
                    }}
                    style={{
                      padding: "9px 0",
                      maxWidth: "50px",
                      minWidth: "40px",
                      marginRight: "5px",
                    }}
                    data-intercom-target="site-filter"
                    id={"gtm_filterIcon"}
                  />
                </div>
              </CustomTooltip>

              <Grid item data-intercom-target="download-anomalies-report">
                {this.state.allSectionList && (
                  <DownloadFile
                    fileAvailable={this.state.fileAvailable}
                    fileObj={["sections", this.state.selectedSection]}
                    displayFilters={
                      this.state.displayFilters
                        ? this.state.displayFilters.defectsFilter
                        : null
                    }
                    filters={this.state.displayFilters}
                    sectionId={this.state.sectionInspectionId}
                    defect_ids_list={this.state.defect_ids_list}
                    sectionInspectionId={this.state.sectionInspectionId}
                    allData={this.state.allData}
                    gtmId={`${this.props.loggedInUser.user_profile.client_name}-${this.state.allSectionList.plant_name}`}
                  />
                )}
              </Grid>

              <Hidden only={["xs", "sm"]}>
                <div style={{ position: "relative" }}>
                  <CustomTooltip
                    // style={{position:'relative'}}
                    arrow
                    classes={{
                      tooltip: classes.tooltipInfo,
                      arrow: classes.anomalieTooltipArrow,
                    }}
                    placement="top-start"
                    title={i18n.t("Bulk Upload")}
                  >
                    <div>
                      <CustomButton
                        variant="outlined"
                        btnIcon={bulkUploadImg}
                        type="cancel"
                        onClick={() => {
                          return this.handleImportDataPopup();
                        }}
                        style={{
                          borderColor: "#DDE5EC",
                          padding: "9px 0",
                          width: "90px",
                          minWidth: "40px",
                          margin: "0 5px",
                        }}
                        margin={"8px"}
                        text={"Import"}
                        data-intercom-target="anomalies-bulk-upload"
                      />
                    </div>
                  </CustomTooltip>
                  <div
                    style={{
                      position: "absolute",
                      top: "-11%",
                      left: "-8%",
                      transform: "rotate(-40deg)",
                    }}
                  >
                    <ProLabel marginRight="0" marginLeft="5px" />
                  </div>
                </div>
              </Hidden>

              <Hidden smDown>
                <div style={{ flex: "auto" }} />
              </Hidden>

              {window.innerWidth >= 760 && (
                <Grid>
                  <div style={{ position: "relative" }}>
                    <CustomButton
                      variant="outlined"
                      onClick={() => {
                        return this.setState({ selectedView: "table" });
                      }}
                      style={{
                        padding: "9px 0",
                        maxWidth: "50px",
                        minWidth: "40px",
                        margin: "0",
                        borderRight: 0,
                        borderRadius: "8px 0 0 8px",
                        background: `${
                          this.state.selectedView === "map"
                            ? "white"
                            : "#F0F4FA"
                        }`,
                      }}
                      data-intercom-target="anomalies-table-view"
                      btnIcon={tableImg}
                      text={this.state.width > 1355 ? "" : ""}
                      type="cancel"
                      margin="3px"
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: "-11%",
                        left: "-8%",
                        transform: "rotate(-40deg)",
                      }}
                    >
                      <ProLabel marginRight="0" marginLeft="5px" />
                    </div>

                    <CustomButton
                      variant="outlined"
                      onClick={() => {
                        return this.setState({ selectedView: "map" });
                      }}
                      style={{
                        padding: "9px 0",
                        maxWidth: "50px",
                        minWidth: "40px",
                        margin: "0",
                        borderRadius: "0 8px 8px 0",
                        background: `${
                          this.state.selectedView === "table"
                            ? "white"
                            : "#F0F4FA"
                        }`,
                      }}
                      btnIcon={locationImg}
                      text={this.state.width > 1355 ? "" : ""}
                      type="cancel"
                      margin="3px"
                    />
                  </div>
                </Grid>
              )}

              {this.state.inspectionList && (
                <div style={{ margin: "0 8px" }}>
                  <CustomDropdown
                    selectValue={this.state.selectedInspectionDate}
                    onHandleSelectChange={(e) => {
                      return this.handleInspDate(e);
                    }}
                    data-intercom-target="anomalies-inspection-date"
                    menuItems={inspectionDateMenuItems}
                  />
                </div>
              )}

              <ModalVideo
                channel="youtube"
                isOpen={this.state.isOpen}
                videoId="BJwAoshMKj4"
                onClose={() => {
                  return this.setState({ isOpen: false });
                }}
              />
              <Button
                style={{
                  color: "#376EFF",
                  display: "flex",
                  width: this.state.width > 1355 ? "128px" : "18px",
                  height: "34px",
                  justifyContent: "center",
                  padding: "0",
                  paddingRight: "7px",
                  borderRadius: "8px",
                  borderColor: "#DDE5EC",
                }}
                variant="outlined"
                onClick={this.handleOpenModal}
              >
                {this.state.width > 1355 ? (
                  <Hidden only={["xs"]}>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-evenly"
                    >
                      <img
                        style={{ marginLeft: "10px" }}
                        src={youtubeTransparent}
                        alt="youtubeIcon"
                      />
                      <Typography
                        style={{
                          paddingRight: "6px",
                          color: "#536471",
                          fontFamily: "Montserrat",
                          fontWeight: 500,
                          fontSize: "12px",
                          lineHeight: "20px",
                        }}
                      >
                        <Trans>Video Tour</Trans>
                      </Typography>
                    </Grid>
                  </Hidden>
                ) : (
                  <Grid
                    style={{ marginRight: "-4px" }}
                    container
                    alignItems="center"
                    justifyContent="center"
                  >
                    <img src={youtubeTransparent} alt="youtubeIcon" />
                  </Grid>
                )}
              </Button>
            </Grid>
          </Grid>

          {/* Display Filters name */}
          <Hidden only={["xs"]}>
            {this.state.displayFilters && (
              <Grid item>
                <Grid item>
                  <Grid
                    container
                    alignItems="center"
                    className={classes.filterBg}
                  >
                    <Typography
                      className={classes.filterTitle}
                      style={{ marginTop: 0 }}
                    >
                      Filter :
                    </Typography>
                    {this.state.displayFilters &&
                      this.state.displayFilters.defectsFilter.map(
                        (f, index) => {
                          return f ? (
                            <Grid item key={f.defect_type_name}>
                              <CustomFilterChip
                                variant="outlined"
                                onDelete={() => {
                                  return this.handleRemoveDefect(f, index);
                                }}
                                type="anomalies"
                                name={f.defect_type_name}
                                color={f.color_code}
                              />{" "}
                            </Grid>
                          ) : null;
                        }
                      )}
                    {this.state.displayFilters.severity && (
                      <Grid item>
                        <CustomFilterChip
                          variant="outlined"
                          onDelete={() => {
                            return this.handleRemoveDefect("severity");
                          }}
                          type="severity"
                          name={this.state.displayFilters.severity}
                        />
                      </Grid>
                    )}
                    {this.state.displayFilters &&
                      this.state.displayFilters.status &&
                      this.state.displayFilters.status.map((stat, index) => {
                        return stat ? (
                          <Grid item key={stat}>
                            <CustomFilterChip
                              variant="outlined"
                              onDelete={() => {
                                return this.handleRemoveDefect("status", index);
                              }}
                              type="status"
                              name={stat}
                            />
                          </Grid>
                        ) : null;
                      })}
                    {this.state.displayFilters.iec_category && (
                      <Grid item>
                        <CustomFilterChip
                          variant="outlined"
                          onDelete={() => {
                            return this.handleRemoveDefect("iec_category");
                          }}
                          type="iec"
                          name={this.state.displayFilters.iec_category}
                        />
                      </Grid>
                    )}
                    {this.state.displayFilters.label && (
                      <Grid item>
                        <CustomFilterChip
                          variant="outlined"
                          onDelete={() => {
                            return this.handleRemoveDefect("label");
                          }}
                          type="label"
                          name={this.state.displayFilters.label}
                        />
                      </Grid>
                    )}
                    {this.state.displayFilters.tempValue &&
                      this.state.displayFilters.tempValue.length && (
                        <Grid item>
                          <CustomFilterChip
                            variant="outlined"
                            onDelete={() => {
                              return this.handleRemoveDefect("tempValue");
                            }}
                            type="temperature_range"
                            lowerTempValue={
                              this.state.displayFilters.tempValue[0]
                            }
                            higherTempValue={
                              this.state.displayFilters.tempValue[1]
                            }
                          />
                        </Grid>
                      )}
                    {this.state.displayFilters.is_commented && (
                      <Grid item>
                        <CustomFilterChip
                          variant="outlined"
                          onDelete={() => {
                            return this.handleRemoveDefect("is_commented");
                          }}
                          type="images"
                          name="comments"
                        />
                      </Grid>
                    )}
                    {this.state.displayFilters.el_image && (
                      <Grid item>
                        <CustomFilterChip
                          variant="outlined"
                          onDelete={() => {
                            return this.handleRemoveDefect("el_image");
                          }}
                          type="images"
                          name="el_image"
                        />
                      </Grid>
                    )}
                    {this.state.displayFilters.handheld_image && (
                      <Grid item>
                        <CustomFilterChip
                          variant="outlined"
                          onDelete={() => {
                            return this.handleRemoveDefect("handheld_image");
                          }}
                          type="images"
                          name="handheld_image"
                        />
                      </Grid>
                    )}
                    {this.state.displayFilters.iv_curve_data && (
                      <Grid item>
                        <CustomFilterChip
                          variant="outlined"
                          onDelete={() => {
                            return this.handleRemoveDefect("iv_curve_data");
                          }}
                          type="images"
                          name="iv_curve_data"
                        />
                      </Grid>
                    )}
                    <Typography
                      className={classes.clearText}
                      onClick={() => {
                        this.applyFilter(null, false);
                      }}
                    >
                      {" "}
                      <Trans>Clear Filters</Trans>{" "}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Hidden>

          <Grid item xs style={{ position: "relative", width: "100%" }}>
            {this.state.defectData.length && this.state.mapUrl ? (
              this.state.selectedView === "map" ? (
                <div>
                  {!this.state.isDataUpdating &&
                    this.state.mapData &&
                    this.state.mapData.length &&
                    (!this.state.isDigitized ? (
                      <PaginationMap
                        geojsoData={this.state.mapData}
                        mapCoords={
                          isKarnataka1Site
                            ? {
                                lat: this.state.mapData[1].geometry
                                  .coordinates[1],
                                lng: this.state.mapData[1].geometry
                                  .coordinates[0],
                              }
                            : defectFromActivity
                            ? {
                                lat: defectFromActivity.geometry.coordinates[1],
                                lng: defectFromActivity.geometry.coordinates[0],
                              }
                            : {
                                lat: this.state.mapData[0].geometry
                                  .coordinates[1],
                                lng: this.state.mapData[0].geometry
                                  .coordinates[0],
                              }
                        }
                        filters={this.state.displayFilters}
                        showDefectSidebar={(value) => {
                          return this.showDefectSidebar(value);
                        }}
                        chipFilter={this.state.chipFilter}
                        updateChipFilter={() => {
                          return this.setState({ chipFilter: false });
                        }}
                        selectedDefect={this.state.selectedDefect}
                        defectData={this.state.defectData}
                        allData={this.state.allData}
                        highlightMarker={this.state.highlightMarker}
                        mapDuplicateData={this.state.mapDuplicateData}
                        changeKey={(v) => {
                          return this.updateBaseMap(v);
                        }}
                        filterFaultType={this.state.filterFaultType}
                        mapCoordinate={this.state.mapCoordinate}
                        mapUrl={this.state.mapUrl}
                        selectedBaseMap={this.state.selectedBaseMap}
                        is_tiling={this.state.is_tiling}
                        tiling_metadata={this.state.tiling_metadata}
                        allRasterLayer={this.state.allRasterLayer}
                        allSectionList={this.state.allSectionList}
                        onHandleShowPlans={(value) => {
                          return this.props.onHandleShowPlans(value);
                        }}
                        toggleDefectSidebar={this.state.toggleDefectSidebar}
                      />
                    ) : (
                      <div>
                        {this.state.mapData &&
                          this.state.mapData.length &&
                          this.state.mapData[0].geometry.type === "Polygon" && (
                            <PaginationPolygon
                              geojsoData={this.state.mapData}
                              mapCoords={{
                                lat: this.state.mapData[0].geometry
                                  .coordinates[0][0][1],
                                lng: this.state.mapData[0].geometry
                                  .coordinates[0][0][0],
                              }}
                              filters={this.state.displayFilters}
                              showDefectSidebar={(value) => {
                                return this.showDefectSidebar(value);
                              }}
                              chipFilter={this.state.chipFilter}
                              updateChipFilter={() => {
                                return this.setState({ chipFilter: false });
                              }}
                              selectedDefect={this.state.selectedDefect}
                              defectData={this.state.defectData}
                              allData={this.state.allData}
                              highlightMarker={this.state.highlightMarker}
                              mapDuplicateData={this.state.mapDuplicateData}
                              changeKey={(v) => {
                                return this.updateBaseMap(v);
                              }}
                              filterFaultType={this.state.filterFaultType}
                              mapCoordinate={this.state.mapCoordinate}
                              mapUrl={this.state.mapUrl}
                              selectedBaseMap={this.state.selectedBaseMap}
                              is_tiling={this.state.is_tiling}
                              tiling_metadata={this.state.tiling_metadata}
                              allRasterLayer={this.state.allRasterLayer}
                              allSectionList={this.state.allSectionList}
                              onHandleShowPlans={(value) => {
                                return this.props.onHandleShowPlans(value);
                              }}
                              toggleDefectSidebar={
                                this.state.toggleDefectSidebar
                              }
                            />
                          )}
                      </div>
                    ))}
                </div>
              ) : (
                <AnomaliesTable
                  defectData={this.state.defectData}
                  selectedView={this.state.selectedView}
                  showDefectSidebar={(v) => {
                    return this.showDefectSidebar(v);
                  }}
                  handleShowPlans={() => {
                    return this.props.onHandleShowPlans(true);
                  }}
                />
              )
            ) : (
              <Grid
                style={{ height: "100%" }}
                container
                justifyContent="center"
                alignItems="center"
              >
                <NewLoader />
              </Grid>
            )}
          </Grid>
        </Grid>

        {/* Filter Dialog */}
        <FilterDrawer
          closeFilterDrawer={() => {
            return this.handleFilterDrawer();
          }}
          filterFaultType={this.state.filterFaultType}
          filterDefects={(value) => {
            return this.applyFilter(value, false);
          }}
          selectedSection={this.state.selectedSection}
          displayFilters={this.state.displayFilters}
          open={this.state.toggleFilterSidebar}
          removedDefect={this.state.removedDefect}
        />

        {/* Defect list Sidebar */}
        <>
          <Hidden only={["xs", "sm"]}>
            <SwipeableDrawer
              anchor="right"
              variant="persistent"
              open={this.state.toggleDefectSidebar}
              onClose={() => {
                return this.handleDefectDrawer();
              }}
              onOpen={() => {
                return this.handleDefectDrawer();
              }}
              classes={{ paper: classes.defectDrawer }}
            >
              {this.state.toggleFilterSidebar ||
              this.state.selectedView === "table" ? null : (
                <div
                  className={classes.arrowDrawer}
                  onClick={() => {
                    return this.handleDefectDrawer();
                  }}
                  data-intercom-target="defect-swipeable"
                  role="button"
                  tabIndex={"0"}
                  aria-hidden="true"
                >
                  <img
                    src={
                      this.state.toggleDefectSidebar
                        ? leftArrowImg
                        : rightArrowImg
                    }
                    width="8"
                    alt="arrowImg"
                  />
                </div>
              )}
              <DefectDetailsView
                selectedInspectionDate={this.state.selectedInspectionDate}
                showDefectSidebar={(value) => {
                  return this.showDefectSidebar(value);
                }}
                defectData={this.state.defectData}
                selectedDefect={this.state.selectedDefect}
                removeSelectedDefect={(value) => {
                  return this.updateSelectedDefect(value);
                }}
                callDefects={(v) => {
                  return this.patchDefect(v);
                }}
                updateListLocally={(id, status) => {
                  return this.updateListLocally(id, status);
                }}
                isDigitized={this.state.isDigitized}
                updateDefect={(def) => {
                  return this.updateDefect(def);
                }}
                selectedSection={this.state.selectedSection}
                selectedSectionName={this.state.selectedSectionName}
                allData={this.state.allData}
                handleNavigation={(data, type) => {
                  return this.handleNavigation(data, type);
                }}
                callSnackbar={(msg, sev) => {
                  return this.props.callSnackbar(msg, sev);
                }}
                selectedView={this.state.selectedView}
                closeTableSidebar={() => {
                  return this.closeTableSidebar();
                }}
                selectedSiteName={this.state.allSectionList}
                loggedInUser={this.props.loggedInUser}
                updateMap={(value, id) => {
                  return this.updateMap(value, id);
                }}
                dataUpdated={(value, type) => {
                  return this.dataUpdated(value, type);
                }}
                updateDuplicateProperties={(value) => {
                  return this.updateDuplicateProperties(value);
                }}
                isDataUpload={this.state.bulkUpload}
                bulkDataUpload={this.handleBulkUploadData}
              />
            </SwipeableDrawer>
          </Hidden>
          {/* Add drawer for mobile view */}

          <Hidden mdUp>
            {this.state.selectedDefect &&
              this.state.defectData &&
              this.state.defectData.length && (
                <ExpansionPanel
                  classes={{ root: classes.panelRoot }}
                  expanded={this.state.expandPanel}
                  style={{
                    minHeight: this.state.expandPanel ? "90%" : "auto",
                  }}
                  onClick={() => {
                    return this.openFullPanel();
                  }}
                >
                  <ExpansionPanelSummary
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    onTouchMove={() => {
                      return this.setState({ expandPanel: true });
                    }}
                    onClick={() => {
                      return this.setState((prevState) => {
                        return {
                          expandPanel: !prevState.expandPanel,
                        };
                      });
                    }}
                    classes={{
                      root: classes.expHeader,
                      content: classes.expContent,
                    }}
                  >
                    {this.state.expandPanel ? (
                      <KeyboardArrowDownIcon className={classes.minIcon} />
                    ) : (
                      <KeyboardArrowUpIcon className={classes.maxIcon} />
                    )}
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails className={classes.panelDetail}>
                    <DefectDetailsView
                      selectedInspectionDate={this.state.selectedInspectionDate}
                      showDefectSidebar={(value) => {
                        return this.showDefectSidebar(value);
                      }}
                      defectData={this.state.defectData}
                      selectedDefect={this.state.selectedDefect}
                      removeSelectedDefect={(value) => {
                        return this.updateSelectedDefect(value);
                      }}
                      callDefects={(v) => {
                        return this.patchDefect(v);
                      }}
                      updateListLocally={(id, status) => {
                        return this.updateListLocally(id, status);
                      }}
                      updateDefect={(def) => {
                        return this.updateDefect(def);
                      }}
                      selectedSection={this.state.selectedSection}
                      selectedSectionName={this.state.selectedSectionName}
                      allData={this.state.allData}
                      callSnackbar={(msg, sev) => {
                        return this.props.callSnackbar(msg, sev);
                      }}
                      selectedSiteName={this.state.allSectionList}
                      loggedInUser={this.props.loggedInUser}
                      updateMap={(value, id) => {
                        return this.updateMap(value, id);
                      }}
                      isDigitized={this.state.isDigitized}
                      handleNavigation={(data, type) => {
                        return this.handleNavigation(data, type);
                      }}
                      selectedView={this.state.selectedView}
                      closeTableSidebar={() => {
                        return this.closeTableSidebar();
                      }}
                      dataUpdated={(value, type) => {
                        return this.dataUpdated(value, type);
                      }}
                      updateDuplicateProperties={(value) => {
                        return this.updateDuplicateProperties(value);
                      }}
                      isDataUpload={this.state.bulkUpload}
                      bulkDataUpload={this.handleBulkUploadData}
                    />
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              )}
          </Hidden>
        </>
        {/* )} */}
        {this.state.showImportDataPopup && (
          <ImportDataPopup
            // uploadFileChange={this.uploadFileChange}
            onHandleImportDataPopup={this.handleImportDataPopup}
            isImportingAnomaly={this.state.isImportingAnomaly}
            linkToProductPage={this.linkToProductPage}
            sectionInspectionId={this.state.sectionInspectionId}
            onHandleReviewAnomalyPopup={this.handleReviewAnomalyPopup}
            callSnackbar={(msg, sev) => {
              return this.props.callSnackbar(msg, sev);
            }}
          />
        )}
        {this.state.showReviewAnomalyPopup && (
          <ReviewAnomaly
            onHandleReviewAnomalyPopup={this.handleReviewAnomalyPopup}
            onHandleBackToImportData={this.handleBackToImportData}
            sectionInspectionId={this.state.sectionInspectionId}
            bulkDataUpload={this.handleBulkUploadData}
          />
        )}
      </>
    );
  }
}

//calling redux to get the activity data and selected language
const mapStateToProps = (state) => {
  return {
    fetchActivity: state.fetchActivity,
    language: state.language.selectedLanguage,
  };
};

//Initializing redux actions to update the activity data
const mapDispatchToProps = (dispatch) => {
  return {
    setActivity: (value) => {
      return dispatch({ type: SET_ACTIVITY, payload: value });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(DefectMap));
