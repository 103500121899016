import "chartjs-plugin-datalabels";

import { useEffect, useState } from "react";

import { Bar } from "react-chartjs-2";
import { CategoryScale } from "chart.js";
import Chart from "chart.js/auto";
import { Grid } from "@material-ui/core";
import React from "react";
import { Trans } from "react-i18next";
import styles from "./inspectionsComp.style";

Chart.register(CategoryScale);

const HorizontalSevBarChart = (props) => {
  const [allData, setAllData] = useState(null);

  useEffect(() => {
    //Chart data processing
    handleDataset();
  }, [props.getChartData, props.dataType]);

  const handleDataset = () => {
    if (props.getChartData) {
      const loopObj = props.getChartData;

      const allCritical = [];
      const allModerate = [];
      const allMinor = [];
      const allPending = [];
      const allResolved = [];
      const allFalsePositive = [];

      //Push the chart data to the appropriate array
      loopObj.map((chartData) => {
        if (chartData.name === "Critical") allCritical.push(chartData.count);
        else if (chartData.name === "Moderate")
          allModerate.push(chartData.count);
        else if (chartData.name === "Minor") allMinor.push(chartData.count);
        else if (chartData.name === "Pending") allPending.push(chartData.count);
        else if (chartData.name === "Resolved")
          allResolved.push(chartData.count);
        else if (chartData.name === "FalsePositive")
          allFalsePositive.push(chartData.count);
        return false;
      });

      //Parameters required to render the chart
      const currState = {
        fontSize: "15px",
        labels: [""],
        datasets: [
          {
            label: props.chartType === "Severity" ? "Critical" : "Pending",
            backgroundColor:
              props.chartType === "Severity" ? "#EE4B23" : "#FFD159",
            data: props.chartType === "Severity" ? allCritical : allPending,
            maxBarThickness: 30,
          },
          {
            label: props.chartType === "Severity" ? "Moderate" : "Resolved",
            backgroundColor:
              props.chartType === "Severity" ? "#0084FF" : "#5ED584",
            data: props.chartType === "Severity" ? allModerate : allResolved,
            maxBarThickness: 30,
          },
          {
            label: props.chartType === "Severity" ? "Minor" : "FalsePositive",
            backgroundColor:
              props.chartType === "Severity" ? "#BBD1EC" : "#9E85FF",
            data: props.chartType === "Severity" ? allMinor : allFalsePositive,
            maxBarThickness: 30,
          },
        ],
      };

      setAllData(currState);
    }
  };

  const options = {
    indexAxis: "y",
    maintainAspectRatio: false,
    title: {
      display: true,
      fontSize: 12,
    },
    legend: {
      display: false,
    },
    responsive: true,
    tooltips: {
      enabled: false,
    },
    hover: { mode: null },
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    scales: {
      x: {
        display: false,
        stacked: true,
        ticks: {
          beginAtZero: true,
          max: props.getTotalDefectedModules,
        },
      },
      y: {
        display: false,
        stacked: true,
      },
    },
  };

  const severityColor = [],
    critical = [],
    moderate = [],
    minor = [],
    statusColor = [],
    pending = [],
    resolved = [],
    falsePositive = [];
  if (props.getChartData) {
    props.getChartData.map((data) => {
      if (data.name === "Critical") {
        critical.push(data.percentage);
        critical.push(data.count);
        return (severityColor[0] = data.color);
      } else if (data.name === "Moderate") {
        moderate.push(data.percentage);
        moderate.push(data.count);
        return (severityColor[1] = data.color);
      } else if (data.name === "Minor") {
        minor.push(data.percentage);
        minor.push(data.count);
        return (severityColor[2] = data.color);
      } else if (data.name === "Pending") {
        pending.push(data.percentage);
        pending.push(data.count);
        return (statusColor[0] = data.color ? data.color : "#FFD159");
      } else if (data.name === "Resolved") {
        resolved.push(data.percentage);
        resolved.push(data.count);
        return (statusColor[1] = data.color ? data.color : "#5ED584");
      } else if (data.name === "FalsePositive") {
        falsePositive.push(data.percentage);
        falsePositive.push(data.count);
        return (statusColor[2] = data.color ? data.color : "#9E85FF");
      }

      return false;
    });
  }
  return (
    <>
      {allData && (
        <div>
          <Bar
            id="bar"
            data={allData}
            height={40}
            width={128}
            options={options}
          />
          {props.chartType === "Severity" && props.showStats && (
            <Grid container style={styles.severityChartBox}>
              <Grid item xs={6} md={3}>
                <div style={styles.flexColBox}>
                  <div>
                    <span
                      style={{
                        ...styles.severityBoxes,
                        backgroundColor: severityColor[0],
                      }}
                    />
                    <span style={styles.severityText}>
                      <Trans>Critical</Trans>
                    </span>
                  </div>
                  <div style={styles.severityText}>
                    <span style={styles.percent}>{critical[0]}%</span>{" "}
                    <span>({critical[1]})</span>
                  </div>
                </div>
              </Grid>
              <Grid item xs={6} md={3}>
                <div style={styles.flexColBox}>
                  <div>
                    <span
                      style={{
                        ...styles.severityBoxes,
                        backgroundColor: severityColor[1],
                      }}
                    />
                    <span style={styles.severityText}>
                      <Trans>Moderate</Trans>
                    </span>
                  </div>
                  <div style={styles.severityText}>
                    <span style={styles.percent}>{moderate[0]}%</span>{" "}
                    <span>({moderate[1]})</span>
                  </div>
                </div>
              </Grid>
              <Grid item xs={6} md={3}>
                <div style={styles.flexColBox}>
                  <div>
                    <span
                      style={{
                        ...styles.severityBoxes,
                        backgroundColor: severityColor[2],
                      }}
                    />
                    <span style={styles.severityText}>
                      <Trans>Minor</Trans>
                    </span>
                  </div>
                  <div>
                    <div style={styles.severityText}>
                      <span style={styles.percent}>{minor[0]}%</span>{" "}
                      <span>({minor[1]})</span>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          )}
          {props.chartType === "Status" && props.showStatusStats && (
            <Grid container style={styles.status}>
              <Grid item xs={6} md={3}>
                <div style={styles.flexColBox}>
                  <div>
                    <span
                      style={{
                        ...styles.severityBoxes,
                        backgroundColor: statusColor[0],
                      }}
                    />
                    <span style={styles.severityText}>
                      <Trans>Pending</Trans>
                    </span>
                  </div>
                  <div style={styles.severityText}>
                    <span style={styles.percent}>{pending[0] * 100}%</span>{" "}
                    <span>({pending[1]})</span>
                  </div>
                </div>
              </Grid>
              <Grid item xs={6} md={3}>
                <div style={styles.flexColBox}>
                  <div>
                    <span
                      style={{
                        ...styles.severityBoxes,
                        backgroundColor: statusColor[1],
                      }}
                    />
                    <span style={styles.severityText}>
                      <Trans>Resolved</Trans>
                    </span>
                  </div>
                  <div style={styles.severityText}>
                    <span style={styles.percent}>{resolved[0] * 100}%</span>{" "}
                    <span>({resolved[1]})</span>
                  </div>
                </div>
              </Grid>
              <Grid item xs={6} md={3}>
                <div style={styles.flexColBox}>
                  <div>
                    <span
                      style={{
                        ...styles.severityBoxes,
                        backgroundColor: statusColor[2],
                      }}
                    />
                    <span style={styles.severityText}>
                      <Trans>False Positive</Trans>
                    </span>
                  </div>
                  <div>
                    <div style={styles.severityText}>
                      <span style={styles.percent}>
                        {falsePositive[0] * 100}%
                      </span>{" "}
                      <span>({falsePositive[1]})</span>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          )}
        </div>
      )}
    </>
  );
};

export default HorizontalSevBarChart;
