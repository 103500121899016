import "./index.css";

import {
  Button,
  Grid,
  Hidden,
  Snackbar,
  Typography,
  withStyles,
} from "@material-ui/core";
import { Link, Navigate } from "react-router-dom";
import { URLS, version } from "../../constants";

import CookieConsent from "../../components/CookieConsent";
import CustomTextField from "../common/customStyle/TextField";
import MuiAlert from "@material-ui/lab/Alert";
import React from "react";
import { SET_SELECTEDLANGUAGE } from "../../constants/actionTypes";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { allRequestHandler } from "../../api";
import { connect } from "react-redux";
import loginIllustration from "../../components/assets/img/login_Illustration_compressed.png";
import styles from "./login.style";
import zeitviewLogo from "../../assets/img/zeitviewLogo.svg";
import WebToMobileScreen from "./webToMobileScreen";

//login page
class Login extends React.Component {
  state = {
    email: "",
    password: "",
    redirect: false,
    useSnackbar: false,
    displayMsg: "",
    showPassword: false,
  };
  //handling online ,offline state here.
  componentDidMount() {
    localStorage.setItem("val1", 1);
    localStorage.setItem("loginData", null);
    this.props.handleResetData();
  }

  //handling username and password input
  handleInput = (e, field) => {
    this.setState({ [field]: e.target.value });
  };

  //Checking login credentials and allowing user to login
  loginScreen = async (event) => {
    event.preventDefault();

    if (this.state.email !== "" && this.state.password !== "") {
      const getToken = await allRequestHandler({
        requestType: "LOGIN",
        requestUrl: URLS.LOGIN,
        requestData: {
          username: this.state.email,
          password: this.state.password,
        },
      });

      if (getToken) {
        localStorage.setItem("loginData", JSON.stringify(getToken));
        sessionStorage.setItem("jwtToken", getToken.access_token);
        sessionStorage.setItem("userRole", getToken.role);

        this.props.loginUrl(getToken);

        this.props.validateUser(true);
        this.setState({ redirect: true });
      }
    } else
      this.setState({
        useSnackbar: true,
        displayMsg: "Please Enter Username and Password",
      });
  };

  //Closing the snackbar (warnings, info and errors alert )
  handleCloseSnackbar = () => {
    this.setState({ useSnackbar: false });
  };

  //Handling password visibility (eye icon)
  handleClickShowPassword = () => {
    this.setState((prevState) => {
      return {
        showPassword: !prevState.showPassword,
      };
    });
  };

  //Renders the login page where users can enter their credentials to login
  render() {
    window.history.pushState(null, null, "/");
    const { classes } = this.props;
    if (this.state.redirect) {
      return <Navigate replace to="/dashboard" />;
    }

    return (
      <>
        {window.innerWidth >= 780 ? (
          <Grid
            container
            className="height100 overHide outerDiv"
            alignItems="center"
          >
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              autoHideDuration={3000}
              open={this.state.useSnackbar}
              onClose={this.handleCloseSnackbar}
            >
              <MuiAlert elevation={6} variant="filled" severity="error">
                {" "}
                {this.state.displayMsg}{" "}
              </MuiAlert>
            </Snackbar>

            <Grid item xs={12} sm={6} className={classes.mobBox}>
              <Grid
                container
                className="height100"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item style={{ width: 400 }}>
                  <img
                    className={classes.imgLogo}
                    style={{ verticalAlign: "top" }}
                    src={zeitviewLogo}
                    alt="logo"
                  />
                  <h5 className={classes.versionTxt}> {version} </h5>
                  <Hidden xsDown>
                    <Typography className={classes.loginText}>
                      Log In{" "}
                    </Typography>
                  </Hidden>

                  <form
                    onSubmit={(e) => {
                      return this.loginScreen(e);
                    }}
                    className={classes.loginOuter}
                  >
                    <div>
                      <div className={classes.loginlabel}>Username / Email</div>
                      <CustomTextField
                        id="email"
                        name="email"
                        value={this.state.email}
                        autoComplete="off"
                        autoFocus
                        handleChange={(e) => {
                          this.handleInput(e, "email");
                        }}
                        BoxHeight={48}
                        hideInputProps
                        isOutlineInput
                        unit=""
                      />
                    </div>
                    <div>
                      <div className={classes.loginlabel}>Password</div>
                      <CustomTextField
                        name="password"
                        value={this.state.password}
                        type={this.state.showPassword ? "text" : "password"}
                        id="password"
                        autoComplete="current-password"
                        handleChange={(e) => {
                          this.handleInput(e, "password");
                        }}
                        BoxHeight={48}
                        unit={
                          this.state.showPassword ? (
                            <Visibility style={{ cursor: "pointer" }} />
                          ) : (
                            <VisibilityOff style={{ cursor: "pointer" }} />
                          )
                        }
                        onClickIcon={this.handleClickShowPassword}
                        hideInputProps
                        isOutlineInput
                      />
                    </div>
                    <Link to="/ForgotPassword">
                      <div className={classes.forgotPassText}>
                        Forgot Password ?
                      </div>
                    </Link>

                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      style={{ color: "#FFFFFF" }}
                      className={classes.loginBtn}
                      classes={{ label: classes.btnLoginLabel }}
                    >
                      Log In
                    </Button>
                  </form>
                </Grid>
              </Grid>
            </Grid>

            <Hidden xsDown>
              <Grid item xs={12} sm={6}>
                <img
                  className={classes.illustrationImg}
                  src={loginIllustration}
                  alt="login_Design"
                />
              </Grid>
            </Hidden>
            {<CookieConsent cookieStorage={this.props.cookieStorage} />}
          </Grid>
        ) : (
          <WebToMobileScreen classes={classes} />
        )}
      </>
    );
  }
}

//Calling the redux to get the language of the app
const mapStateToProps = (state) => {
  return {
    language: state.language.selectedLanguage,
  };
};

//setting changed language into redux
const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedLanguage: (value) => {
      return dispatch({ type: SET_SELECTEDLANGUAGE, payload: value });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Login));
