const styles = {
  paper1: {
    padding: 5,
    textAlign: "center",
    backgroundColor: "#8D95A2",
    borderRadius: "14px",
    maxWidth: 100,
    margin: "20px 0 0 25px",
    fontSize: "12px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#ffffff",
    boxShadow: "unset",
    fontFamily: "Montserrat",
  },
  item: {
    backgroundColor: "white",
    margin: "20px 0 20px 20px",
    borderRadius: "5px",
    maxWidth: "900px",
    height: 60,
    boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
    cursor: "pointer",
  },
  data: {
    fontFamily: "Montserrat",
    padding: "18px",
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.67,
    letterSpacing: "normal",
    color: "#536471",
  },
  data1: {
    fontFamily: "Montserrat",
    margin: "7px 0 0 20px",
    width: "353px",
    height: "17px",
    color: "#21282d",
  },
  name: {
    fontFamily: "Montserrat",
    padding: "0.5%",
    fontSize: "13px",
    color: "#536471",
    lineHeight: 1.67,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
  },
  header: {
    padding: "10px 20px",
    height: 72,
    backgroundColor: "#fff",
  },
  title: {
    fontFamily: "Montserrat",
    fontSize: 20,
    fontWeight: 700,
    marginTop: "3px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.2,
    color: "#21282d",
  },
  initNames: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    lineHeight: "18px",
    color: "#7E909D",
    background: "#F4F7FC",
    height: 30,
    width: 30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 20,
    textTransform: "uppercase",
  },
  noActivity: {
    fontFamily: "Montserrat",
    fontSize: 40,
    textAlign: "center",
    marginTop: 150,
    color: "#919191",
  },
};

export default styles;
