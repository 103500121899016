/* eslint-disable quotes */

import { Grid, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { Bar } from "react-chartjs-2";
import Divider from "@mui/material/Divider";
import YieldDropdown from "./yieldDropdown";
import _ from "lodash";
import currencValConverter from "../../../utils/currencyValConverter";
import moment from "moment";
import powerEnergyConverter from "../../../utils/powerEnergyConverter";

export const useStyles = makeStyles(() => {
  return {
    divider: {
      // Theme Color, or use css color in quote
      background: "#F0F4FA",
    },
  };
});

//revenue and power chart component
const RevenueAndPowerChart = (props) => {
  const [anomalyComparisonData, setAnomalyComparisonData] = useState({
    anomaly_inspection_list: "",
    label_comparison: "",
    losses_comparison: "",
  });
  const [power, setPower] = useState(null);
  
  useEffect(() => {
    //Setting chart data
    if (props.anomalyComparisonData) {
      setAnomalyComparisonData({
        anomaly_inspection_list:
          props.anomalyComparisonData.anomaly_inspection_list,
        label_comparison: props.anomalyComparisonData.label_comparison,
        losses_comparison: _.sortBy(
          props.anomalyComparisonData.losses_comparison,
          "inspection_date"
        ),
      });
    }    
  }, [props.anomalyComparisonData]);

  //create tooltip card
  const getOrCreateTooltip = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector("div");

    if (!tooltipEl) {
      //tooltip element
      tooltipEl = document.createElement("div");
      tooltipEl.style.background = "#FFFFFF";
      tooltipEl.style.borderRadius = "5px";
      tooltipEl.style.border = "1px solid #E4EAF5";
      tooltipEl.style.boxShadow = "0px 1px 2px rgba(0, 0, 0, 0.05)";
      tooltipEl.style.color = "black";
      tooltipEl.style.opacity = 1;
      tooltipEl.style.pointerEvents = "none";
      tooltipEl.style.position = "absolute";
      tooltipEl.style.transform = "translate(-50%, 0)";
      tooltipEl.style.transition = "all .1s ease";
      tooltipEl.style.width = "227px";
      tooltipEl.style.maxWidth = "250px";

      const table = document.createElement("table");
      table.style.margin = "0px";

      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }
    return tooltipEl;
  };

  //custom tooltip handler
  const externalTooltipHandler = (context) => {
    // Tooltip Element
    const { chart, tooltip } = context;
    const tooltipEl = getOrCreateTooltip(chart);

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    // Set Text
    if (tooltip.body) {
      const titleLines = tooltip.title || [];
      const bodyLines = tooltip.body.map((b) => b.lines);

      const tableHead = document.createElement("thead");

      //set title of the tooltip
      titleLines.forEach((title) => {
        const tr = document.createElement("tr");
        tr.style.borderWidth = 0;
        tr.style.color = "#1D2D47";
        tr.style.fontFamily = "Montserrat";
        tr.style.fontStyle = "normal";
        tr.style.fontWeight = 500;
        tr.style.fontSize = "12px";
        tr.style.lineHeight = "20px";
        tr.style.textAlign = "left";

        const th = document.createElement("th");
        th.style.borderWidth = 0;
        const text = document.createTextNode(title);

        th.appendChild(text);
        tr.appendChild(th);
        tableHead.appendChild(tr);
      });

      //created table body
      const tableBody = document.createElement("tbody");

      //getting table data
      bodyLines.forEach((body, i) => {
        const bodySplit = body[0]?.toString().split("-");
        const colors = tooltip.labelColors[i];

        //span and style
        const span = document.createElement("span");
        span.style.borderRadius = "50%";
        span.style.background = colors.backgroundColor;
        span.style.borderColor = colors.borderColor;
        span.style.borderWidth = "2px";
        span.style.marginRight = "10px";
        span.style.height = "6px";
        span.style.width = "6px";
        span.style.display = "inline-block";

        //table row and style
        const tr = document.createElement("tr");
        tr.style.backgroundColor = "white";
        tr.style.borderWidth = 0;
        tr.style.marginBottom = 10;
        tr.style.width = "300px";

        //data element and styles
        const td = document.createElement("td");
        td.style.borderWidth = 0;
        td.style.fontFamily = "Montserrat";
        td.style.fontStyle = "normal";
        td.style.fontWeight = 500;
        td.style.fontSize = "12px";
        td.style.lineHeight = "18px";
        td.style.color = "#536471";
        td.style.width = "300px";
        td.style.marginTop = "20px";
        td.style.textAlign = "left";

        if (body[0]) {
          const text = document.createTextNode(bodySplit[0]);

          //value element and style
          const value = document.createElement("span");
          value.textContent = bodySplit[1];
          value.style.textAlign = "right";
          value.style.marginBottom = "-50px";
          value.style.color = "#536471";
          value.style.fontFamily = "Montserrat";
          value.style.fontStyle = "normal";
          value.style.fontWeight = 500;
          value.style.fontSize = "12px";
          value.style.lineHeight = "18px";

          td.appendChild(span);
          td.appendChild(text);
          tr.appendChild(td);
          tr.appendChild(value);
          tableBody.appendChild(tr);
        }
      });

      const tableRoot = tooltipEl.querySelector("table");

      // Remove old children
      while (tableRoot.firstChild) {
        tableRoot.firstChild.remove();
      }

      // Add new children
      tableRoot.appendChild(tableHead);
      tableRoot.appendChild(tableBody);
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = positionX + tooltip.caretX + "px";
    tooltipEl.style.top = positionY + tooltip.caretY + "px";
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding =
      tooltip.options.padding + "px " + tooltip.options.padding + "px";
  };

  //chart options
  const options = {
    layout: {
      padding: {
        left: 24,
        right: 24,
        top: 24,
        bottom: 85,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          padding: 25,
          boxWidth: 4,
          usePointStyle: true,
          font: {
            family: "Montserrat",
            style: "normal",
            color: "#536471",
            size: "11px",
            weight: 500,
            lineHeight: "15px",
          },
          boxHeight: 4.5,
          pointStyleWidth: 6,
        },
      },
      title: {
        display: false,
        text: "ESTIMATED REVENUE & POWER",
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        yAlign: "top",
        mode: "index",
        titleFontSize: 12,
        titleFontFamily: "Montserrat",
        titleFontStyle: "normal",
        fullWidth: true,
        tooltipFontSize: 10,
        titleMarginBottom: 15,
        titleSpacing: 25,
        titleAlign: "left",
        titleFontColor: "#536471",
        bodyAlign: "center",
        bodyFontSize: 12,
        bodySpacing: 15,
        bodyFontFamily: "Montserrat",
        bodyFontColor: "#536471",
        position: "nearest",
        yPadding: 15,
        xPadding: 20,
        cornerRadius: 5,
        caretSize: 10,
        borderColor: "#ccc",
        borderWidth: 1,
        footerSpacing: 6,
        backgroundColor: "white",
        usePointStyle: true,
        enabled: false,

        external: externalTooltipHandler,

        callbacks: {
          title: function (tooltipItems) {
            return `Inspection: ${tooltipItems[0].label}`;
          },
          label: function (tooltipItems, data) {
            if (tooltipItems.parsed.y !== 0) {
              const label = tooltipItems.dataset.label;

              //revenue loss label and value
              if (label === "Revenue Loss") {
                const result = currencValConverter(tooltipItems.parsed.y, data);
                return `${label}-${props.currency} ${result}`;
              }

              //anomalies label and value
              if (label === "Anomalies") {
                let yLabel = tooltipItems.parsed.y.toFixed(0);
                return `${label}-${yLabel}`;
              }

              let power = powerEnergyConverter(
                "power",
                tooltipItems.parsed.y
              ).split(" ");

              //power loss label and value
              return `${label}-${power[0]}${power[1]}`;
            }
            return false;
          },
        },
      },
    },
    animation: {
      duration: 0,
    },
    maintainAspectRatio: false,
    events: ["mouseout", "mousemove"],
    title: {
      display: true,
      fontSize: 12,
    },
    scales: {
      x: {
        barPercentage: 0.5,
        categoryPercentage: 1.0,
        stacked: false,
        border: {
          display: true,
          dash: [0, 9],
        },
        ticks: {
          font: {
            family: "Montserrat",
            style: "normal",
            color: "#536471",
            size: "11px",
            weight: 500,
            lineHeight: "15px",
          },
        },
      },

      y: {
        id: "A",
        type: "linear",
        position: "left",
        stacked: false,
        border: {
          dash: [8, 4],
          display: false,
          color: "#E4EAF5",
        },
        ticks: {
          callback(value) {
            setPower(value);
            const res = currencValConverter(value);

            return res;
          },
          font: {
            family: "Montserrat",
            style: "normal",
            color: "#536471",
            size: "11px",
            weight: 500,
            lineHeight: "15px",
          },
        },
        title: {
          display: true,
          text:
            power >= 1000
              ? "Power Loss (Mwp)"
              : power >= 1000000
              ? "Power Loss (Gwp)"
              : "Power Loss (Kwp)",
          fontFamily: "Montserrat",
          fontStyle: 500,
        },
      },
      y2: {
        id: "B",
        type: "linear",
        position: "right",
        stacked: false,
        grid: {
          drawTicks: false,
        },
        border: {
          display: false,
          dash: [0, 9],
        },
        ticks: {
          callback(value) {
            const res = currencValConverter(value);
            return `${props.currency} ${res}`;
          },
          beginAtZero: true,
          font: {
            family: "Montserrat",
            style: "normal",
            color: "#536471",
            size: "11px",
            weight: 500,
            lineHeight: "15px",
          },
        },
        title: {
          display: true,
          text: "Revenue Loss / yr",
          fontFamily: "Montserrat",
          fontStyle: 500,
        },
      },
    },
  };

  //chart dataset
  const data = {
    labels:
      anomalyComparisonData &&
      anomalyComparisonData.losses_comparison &&
      anomalyComparisonData.losses_comparison.map((val) => {
        return moment(val.inspection_date).format("D MMM, YYYY");
      }),
    datasets: [
      {
        type: "line",
        label: "Anomalies",
        lineTension: 0,
        data:
          anomalyComparisonData &&
          anomalyComparisonData.losses_comparison &&
          anomalyComparisonData.losses_comparison.map((val) => {
            return val.count;
          }),
        fill: false,
        backgroundColor: "#7363FB",
        borderColor: "#7363FB",
      },
      {
        yAxisID: "y",
        barThickness: 24,
        label: "Power Loss",
        data:
          anomalyComparisonData &&
          anomalyComparisonData.losses_comparison &&
          anomalyComparisonData.losses_comparison.map((val) => {
            return val.power_loss;
          }),
        backgroundColor: "#FFCB05",
      },
      {
        yAxisID: "y2",
        barThickness: 24,
        label: "Revenue Loss",
        data:
          anomalyComparisonData &&
          anomalyComparisonData.losses_comparison &&
          anomalyComparisonData.losses_comparison.map((val) => {
            return val.revenue_loss;
          }),
        backgroundColor: "#F26649",
      },
    ],
  };

  //rendering revenue and power charts in historical comparison option.
  return (
    <Grid
      style={{
        height: "420px",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
      }}
    >
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid
          style={{
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "14px",
            lineHeight: "24px",
            color: "#536471",
            paddingTop: "19px",
            paddingLeft: "24px",
            paddingBottom: "19px",
          }}
        >
          ESTIMATED POWER & REVENUE LOSS
        </Grid>
        <Grid style={{ marginRight: "19px" }}>
          <YieldDropdown
            siteId={props.siteId}
            siteData={props.siteData}
            revenueLossTrigger={props.revenueLossTrigger}
            setRevenueLossTrigger={props.setRevenueLossTrigger}
            popupToggle={props.popupToggle}
            setPopupToggle={props.setPopupToggle}
          />
        </Grid>
      </Grid>
      <Divider sx={{ border: "1px solid #F0F4FA", bgcolor: "#F0F4FA" }} />
      <div style={{ display: "flex", width: "100%", height: "420px" }}>
        <Bar options={options} data={data} />
      </div>
    </Grid>
  );
};

export default RevenueAndPowerChart;
