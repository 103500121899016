import {
  AUTH_ERROR,
  CLEAR_ALERTS,
  CLEAR_PROFILE,
  GET_SITEACCESS,
  GET_USERS,
  LOADING,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  REGISTER_FAIL,
  REMOVE_USER,
  TRANSACTION_ERROR,
  UPDATE_USER,
  USER_LOADED,
} from "../constants/actionTypes";

import Config from "../config";
import axios from "axios";
import { setAlert } from "./alert.actions";
import setAuthToken from "../utils/setAuthToken.js";

const requestUrl = Config.hostName;

//Create and export the action to load the user information
export const loadUser = () => {
  return async (dispatch) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    try {
      const res = await axios.get(`${requestUrl}/api/auth`);

      dispatch({
        type: USER_LOADED,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: AUTH_ERROR,
      });
    }
  };
};

// Register User
export const register = (name, phone, email, password, userType = "user") => {
  return (dispatch) => {
    axios
      .post(`${requestUrl}/api/users`, {
        name,
        phone,
        email,
        password,
        userType,
      })

      .then(
        userType === "user"
          ? dispatch(setAlert("Successfully Registered", "success"))
          : dispatch(
              setAlert("Successfully Registered, Kindly Login", "success")
            ),
        (error) => {
          const errors = error.response.data.errors;

          if (errors) {
            errors.forEach((err) => {
              return dispatch(setAlert(err.msg, "danger"));
            });
          }

          dispatch({
            type: REGISTER_FAIL,
          });
        }
      );
  };
};

//Create and export the action to get the list of all the users
export const getUsers = () => {
  return async (dispatch) => {
    dispatch({
      type: LOADING,
    });
    dispatch({
      type: CLEAR_ALERTS,
    });

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
        },
      };
      const result = await axios.get(
        `${requestUrl}/api/users`,
        // {},
        config
      );

      dispatch({
        type: GET_SITEACCESS,
        payload: result.data.plants_list,
      });

      dispatch({
        type: GET_USERS,
        payload: result.data.users_list,
      });
    } catch (err) {
      console.log(err.response.data.message);
      dispatch({
        type: TRANSACTION_ERROR,
        payload: {
          msg: err.response.data.message,
          status: err.response.status,
        },
      });
    }
  };
};

//Create and export the action to create a new user
export const addUser = (body) => {
  return async (dispatch) => {
    dispatch({
      type: LOADING,
    });
    dispatch({
      type: CLEAR_ALERTS,
    });

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
        },
      };
      await axios.post(`${requestUrl}/api/users`, body, config);

      dispatch(getUsers());
    } catch (err) {
      console.log(err.response.data.message);
      dispatch({
        type: TRANSACTION_ERROR,
        payload: {
          msg: err.response.data.message,
          status: err.response.status,
        },
      });
    }
  };
};

//Create and export the action to update the user information
export const updateUser = (id, data) => {
  return async (dispatch) => {
    dispatch({
      type: CLEAR_ALERTS,
    });

    try {
      const res = await axios.put(`${requestUrl}/api/users/${id}`, data);

      dispatch({
        type: UPDATE_USER,
        payload: { _id: id, data: res.data },
      });
      dispatch(setAlert("Updated", "success"));
    } catch (err) {
      console.log(err.response.statusText);
      dispatch({
        type: TRANSACTION_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
    }
  };
};

//Create and export the action to delete the user
export const deleteUser = (id) => {
  return async (dispatch) => {
    dispatch({
      type: CLEAR_ALERTS,
    });

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
        },
      };
      await axios.delete(`${requestUrl}/api/users/${id}`, config);

      dispatch({
        type: REMOVE_USER,
        payload: id,
      });

      dispatch(setAlert("User Removed", "success"));
    } catch (err) {
      dispatch({
        type: TRANSACTION_ERROR,
        payload: { msg: err.response, status: err.response?.status },
      });
    }
  };
};

// Login User
export const login = (email, password, userType = "user") => {
  return async (dispatch) => {
    try {
      const res = await axios.post(`${requestUrl}/api/auth`, {
        email,
        password,
        userType,
      });
      setAuthToken(res.data.token);
      await dispatch(loadUser());

      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      const errors = err.response.data.errors;

      if (errors) {
        errors.forEach((error) => {
          return dispatch(setAlert(error.msg, "danger"));
        });
      }

      dispatch({
        type: LOGIN_FAIL,
      });
    }
  };
};

// Logout / Clear Profile
export const logout = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_PROFILE });
    dispatch({ type: CLEAR_ALERTS });
    dispatch({ type: LOGOUT });
  };
};
