import { AutoRotatingCarousel, Slide } from "material-auto-rotating-carousel";
import {
  Card,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import Autocomplete from "@material-ui/lab/Autocomplete";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import Config from "../../config";
import CustomButton from "../common/customStyle/Button";
import CustomDropdown from "../common/customStyle/Dropdown";
import CustomFilterChip from "../common/customStyle/FilterChip";
import CustomTextField from "../common/customStyle/TextField";
import DateFnsUtils from "@date-io/date-fns";
import PVTechnologyOptions from "./pvTechnologyValues";
import React from "react";
import { Trans } from "react-i18next";
import UploadImg from "../../assets/img/uploadImg.svg";
import { allRequestHandler } from "../../api";
import { callSnackbarAction } from "../../reducers/snackbar";
import moment from "moment";
import resizedImageUrl from "../../utils/imageResizing";
import { store } from "../../store";
import styles from "./siteSummary.style";
import { tiltAngle } from "../../constants";
import { withStyles } from "@material-ui/core/styles";

const requestUrl = Config.hostName;

//site details tab
class SiteDetailsTab extends React.Component {
  state = {
    commissioning_date: this.props.site_details.commissioning_date
      ? this.props.site_details.commissioning_date
      : "",
    dc_capacity: this.props.site_details.dc_capacity,
    ac_capacity: this.props.site_details.ac_capacity,
    peak_irradiation_month: this.props.site_details.peak_irradiation_month,
    inverter_brands: this.props.site_details.inverter_brands,
    module_mounting_type: this.props.site_details.module_mounting_type,
    panel_brands: this.props.site_details.panel_brands,
    panel_maximum_power: this.props.site_details.panel_maximum_power,
    panels_count: this.props.site_details.panels_count,
    plant_name: this.props.site_details.plant_name,
    tilt_angle: this.props.site_details.tilt_angle
      ? this.props.site_details.tilt_angle[0].season
      : null,
    layout_image_url: this.props.site_details.layout_image_url,
    currency_code: this.props.site_details.tariff_data,
    tiltCount: this.props.site_details.tilt_angle
      ? this.props.site_details.tilt_angle[0].angle
      : null,
    powerTariff: this.props.site_details.tariff_data.power_tariff,
    pv_out: this.props.site_details.pv_out,

    land_area: this.props.site_details.land_area,
    pitch: this.props.site_details.pitch,
    clear_distance_between_row:
      this.props.site_details.clear_distance_between_row,
    pv_technology: this.props.site_details.pv_technology,

    dcError: false,
    openImage: false,
    pmpError: false,
    isEditSite: false,
    panel_brands_textfield: "",
    inverter_brands_textfield: "",
    add_new_panel_brands: false,
    add_new_inverter_brands: false,
  };

  //These functions are called on the initial render of the component
  componentDidMount() {
    //Get tilt angle and season on the initial render
    if (this.props.site_details.tilt_angle[1].angle) {
      // skipcq Avoid using setState in componentDidMount JS-0442
      this.setState({
        tilt_angle: this.props.site_details.tilt_angle[1].season,
        tiltCount: this.props.site_details.tilt_angle[1].angle,
      });
    } else {
      // skipcq Avoid using setState in componentDidMount JS-0442
      this.setState({
        tilt_angle: this.props.site_details.tilt_angle[0].season,
        tiltCount: this.props.site_details.tilt_angle[0].angle,
      });
    }
  }

  //Handles the input from various fields while editing site details
  handleInput = (event, field) => {
    if (field === "dc_capacity") {
      if (event.target.value === "" || event.target.value === null)
        this.setState({ dcError: true });
      else this.setState({ dcError: false });
    }

    if (field === "panel_maximum_power") {
      if (event.target.value === "" || event.target.value === null)
        this.setState({ pmpError: true });
      else this.setState({ pmpError: false });
    }

    if (field === "tilt_angle") {
      const filterAngle =
        this.props.site_details &&
        this.props.site_details.tilt_angle.filter((data) => {
          return data.season === event.target.value;
        })[0];

      this.setState({
        tilt_angle: filterAngle.season,
        tiltCount: filterAngle.angle,
      });
    } else if (field === "pv_out") {
      this.setState({ pv_out: event.target.value });
    } else if (field === "powerTariff") {
      this.setState({ powerTariff: event.target.value });
    } else if (field === "currency_code") {
      const filterCurrency =
        this.props.site_details &&
        this.props.site_details.currency_options.filter((data) => {
          return data.currency_code === event.target.value;
        })[0];

      this.setState({ currency_code: filterCurrency });
    } else if (field === "module_mounting_type") {
      this.setState({ module_mounting_type: event.target.value });
    } else
      this.setState({
        [field]: event.target.value ? event.target.value : null,
      });
  };

  //Hides the site layout image
  displayImageClose = () => {
    this.setState({ openImage: false });
  };

  //Shows the site layout image
  displayImageOpen = () => {
    this.setState({ openImage: true });
  };

  //Verify and handles the input from the commission date field
  handleDate = (date) => {
    const currentDate = new Date();
    if (date > currentDate) {
      this.props.callSnackbar("Cannot Set Future Date", "warning");
    } else {
      this.setState({ commissioning_date: date });
    }
  };

  //Handles the auto complete of the input fields
  handleAutoComplete = (event, newValue, field) => {
    this.setState({ [field]: newValue ? newValue : null });
  };

  //Checks the validity of the input fields. If all fields are valid, then it calls the API to update the site details otherwise it shows the error message
  updateSiteDetails = async (field) => {
    if (this.state.ac_capacity < 0) {
      this.props.callSnackbar("AC Capacity can't be negative", "warning");
      return false;
    } else if (this.state.tiltCount < 0) {
      this.props.callSnackbar("Tilt Angle can't be negative", "warning");
      return false;
    } else if (this.state.panels_count < 0) {
      this.props.callSnackbar("panels count can't be negative", "warning");
      return false;
    } else if (this.state.powerTariff < 0) {
      this.props.callSnackbar("Feed in tariff can't be negative", "warning");
      return false;
    } else if (this.state.panel_maximum_power < 0) {
      this.props.callSnackbar(
        "panel maximum power can't be negative",
        "warning"
      );
    } else if (this.state.land_area < 0) {
      this.props.callSnackbar("land area can't be negative", "warning");
    } else if (this.state.pitch < 0) {
      this.props.callSnackbar("pitch can't be negative", "warning");
    } else if (this.state.clear_distance_between_row < 0) {
      this.props.callSnackbar("clear distance can't be negative", "warning");
    }

    const newField = this.state[field] ? this.state[field] : null;
    let prevField = "";

    if (field === "tiltCount") {
      prevField = this.props.site_details.tilt_angle[0].angle;
    }
    if (field === "dc_capacity") {
      prevField = this.props.site_details.dc_capacity;
    }
    if (field === "ac_capacity") {
      prevField = this.props.site_details.ac_capacity;
    }
    if (field === "commissioning_date") {
      prevField = this.props.site_details.commissioning_date;
    }
    if (field === "panels_count") {
      prevField = this.props.site_details.panels_count;
    }
    if (field === "powerTariff") {
      prevField = this.props.site_details.tariff_data.power_tariff;
    }
    if (field === "pv_out") {
      prevField = this.props.site_details.pv_out;
    }
    if (field === "panel_maximum_power") {
      prevField = this.props.site_details.panel_maximum_power;
    }
    if (field === "land_area") {
      prevField = this.props.site_details.tariff_data.land_area;
    }
    if (field === "pitch") {
      prevField = this.props.site_details.pitch;
    }
    if (field === "clear_distance_between_row") {
      prevField = this.props.site_details.clear_distance_between_row;
    }
    if (field === "pv_technology") {
      prevField = this.props.site_details.pv_technology;
    }

    if (newField !== prevField) {
      const angleObj =
        this.props.site_details && this.props.site_details.tilt_angle;

      // Tilt Angle switch
      if (this.state.tilt_angle && this.state.tiltCount) {
        angleObj.filter((data) => {
          if (data.season === this.state.tilt_angle)
            return (data.angle = parseInt(this.state.tiltCount, 10));

          return false;
        });
      }

      const fieldObj = {
        ac_capacity: this.state.ac_capacity,
        commissioning_date: new Date(this.state.commissioning_date),
        coordinate: {
          latitude: this.props.site_details.coordinate.latitude,
          longitude: this.props.site_details.coordinate.longitude,
        },
        dc_capacity: this.state.dc_capacity,
        inverter_brands: this.state.inverter_brands,
        layout_image_url: this.state.layout_image_url,
        module_mounting_type: this.state.module_mounting_type,
        panel_brands: this.state.panel_brands,
        panel_maximum_power: this.state.panel_maximum_power,
        panels_count: this.state.panels_count,
        peak_irradiation_month: this.state.peak_irradiation_month,
        plant_name: this.props.site_details.plant_name,
        tariff_data: {
          power_tariff: this.state.powerTariff,
          units: this.props.site_details.tariff_data.units,
        },
        pv_out: this.state.pv_out,
        tilt_angle: angleObj,
        land_area: parseFloat(this.state.land_area),
        pitch: parseFloat(this.state.pitch),
        clear_distance_between_row: parseFloat(
          this.state.clear_distance_between_row
        ),
        pv_technology: this.state.pv_technology,
      };

      if (this.state.dc_capacity && this.state.panel_maximum_power) {
        if (this.state.dc_capacity <= 0) {
          if (this.state.dc_capacity === 0)
            this.props.callSnackbar("DC capacity can't be Zero", "warning");
          else
            this.props.callSnackbar("DC capacity can't be negative", "warning");
        } else if (this.state.panel_maximum_power <= 0) {
          if (this.state.panel_maximum_power === 0)
            this.props.callSnackbar(
              "Panel Maximum Power can't be Zero",
              "warning"
            );
          else
            this.props.callSnackbar(
              "Panel Maximum Power can't be negative",
              "warning"
            );
        } else {
          this.setState({ isEditSite: false });
          const putFields = await allRequestHandler({
            requestType: "PUT",
            requestUrl: requestUrl + this.props.updateSiteDetailsUrl,
            requestData: fieldObj,
          });
          this.props.callSiteDetails();
          if (putFields)
            store.dispatch(
              callSnackbarAction("Site Details Updated", "success")
            );
        }
      } else {
        if (this.state.dc_capacity === null) {
          this.props.callSnackbar("Please add DC Capacity.", "warning");
        }
        if (this.state.panel_maximum_power === null) {
          this.props.callSnackbar("Please add Panel Maximum Power.", "warning");
        }
      }

      return false;
    }
    return false;
  };

  EditSiteDetails = () => {
    this.setState({ isEditSite: true });
  };

  //Handles the upload of the site layout image
  uploadFileChange = async (event, value) => {
    const files = event.target.files[0];
    if (files) {
      const fileObj = {
        file_name: files.name,
        file_type: files.type,
      };

      const getUrl = await allRequestHandler({
        requestType: "POST",
        requestUrl: requestUrl + value.upload_href,
        requestData: fileObj,
      });

      if (getUrl) {
        const data = new FormData();
        data.append("key", getUrl.aws_param_list.fields.key);
        data.append("Content-Type", files.type);
        data.append("policy", getUrl.aws_param_list.fields.policy);
        data.append("acl", "public-read");
        data.append("success_action_status", "201");
        data.append("x-amz-algorithm", "AWS4-HMAC-SHA256");
        data.append(
          "x-amz-credential",
          getUrl.aws_param_list.fields["x-amz-credential"]
        );
        data.append("x-amz-date", getUrl.aws_param_list.fields["x-amz-date"]);
        data.append(
          "x-amz-signature",
          getUrl.aws_param_list.fields["x-amz-signature"]
        );
        data.append("file", files, getUrl.aws_param_list.file_name);
        const xhr = new XMLHttpRequest();

        xhr.open("POST", getUrl.aws_param_list.url);
        xhr.send(data);

        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4) {
            //Update Site Details
            this.setState(
              {
                layout_image_url:
                  getUrl.aws_param_list.url + getUrl.aws_param_list.fields.key,
              },
              () => {
                this.updateSiteDetails();
              }
            );
          }
        };
      }
    }
  };

  //This renders the site details tab
  render() {
    const { classes, site_details } = this.props;
    const moduleMountingTypeMenu = [];
    const tiltAngleMenuItems = [];

    tiltAngle.map((data) => {
      return tiltAngleMenuItems.push({
        value: data.season,
        name: data.season,
      });
    });
    if (this.props.site_details.mounting_options) {
      this.props.site_details.mounting_options.map((type) => {
        return moduleMountingTypeMenu.push({
          value: type,
          name: type,
        });
      });
    }
    const layout_image_url = this.state.layout_image_url;
    return (
      <Grid container className={classes.siteOuterPadd}>
        {this.state.isEditSite ? (
          <Grid
            item
            xs={12}
            md={6}
            className={classes.pad12}
            data-intercom-target="site-details-card"
          >
            <Card style={styles.card}>
              <CardContent style={styles.contentMob}>
                <Grid container className={classes.editSiteSummary}>
                  <Typography style={styles.chartHeading}>
                    <Trans>Site Details</Trans>
                  </Typography>
                  <CustomButton
                    text="Update"
                    onClick={() => {
                      return this.updateSiteDetails();
                    }}
                  />
                </Grid>

                <Divider />
                <Grid container>
                  <Grid container className={classes.detailsBox}>
                    <Grid item xs={6}>
                      <span>
                        <Trans>DC Capacity (MWp)</Trans>
                        <span style={{ color: "#FF0000" }}>*</span> :
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container>
                        <Grid item xs>
                          <CustomTextField
                            type="number"
                            gtmId="dc_capacity"
                            value={this.state.dc_capacity}
                            handleChange={(value) => {
                              return this.handleInput(value, "dc_capacity");
                            }}
                            error={this.state.dcError}
                            helperText={
                              this.state.dcError ? "Required field." : null
                            }
                            BoxHeight={this.state.dcError ? 50 : 36}
                            required
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={6}>
                      <Trans>AC Capacity (MW) :</Trans>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container>
                        <Grid item xs>
                          <CustomTextField
                            type="number"
                            gtmId="ac_capacity"
                            handleChange={(value) => {
                              return this.handleInput(value, "ac_capacity");
                            }}
                            value={this.state.ac_capacity}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={6}>
                      <Trans>Commissioning Date:</Trans>
                    </Grid>
                    <Grid item xs={6}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          classes={{ root: classes.datePicker }}
                          disableToolbar
                          fullWidth
                          variant="inline"
                          format="d MMM, yyyy"
                          invalidDateMessage={false}
                          emptyLabel="-"
                          id="commissioning_date"
                          value={
                            this.state.commissioning_date
                              ? this.state.commissioning_date
                              : "-"
                          }
                          maxDate={new Date()}
                          onChange={(e) => {
                            return this.handleDate(new Date(e));
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid item xs={6}>
                      <Trans>Lat / Long:</Trans>
                    </Grid>
                    <Grid item xs={6}>
                      {site_details.coordinate.latitude},{" "}
                      {site_details.coordinate.longitude}
                    </Grid>

                    <Grid item xs={6}>
                      <Trans>Module Mounting Structure:</Trans>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container>
                        <Grid item xs>
                          <CustomDropdown
                            selectValue={this.state.module_mounting_type}
                            onHandleSelectChange={(e) => {
                              return this.handleInput(
                                e,
                                "module_mounting_type"
                              );
                            }}
                            menuItems={moduleMountingTypeMenu}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={6}>
                      <Trans>Number of Panels</Trans>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container>
                        <Grid item xs>
                          <CustomTextField
                            type="number"
                            gtmId="panels_count"
                            handleChange={(value) => {
                              return this.handleInput(value, "panels_count");
                            }}
                            value={this.state.panels_count}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={6}>
                      <Trans>Panel Brands & Models</Trans>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container>
                        <Grid item xs>
                          <Autocomplete
                            size="small"
                            multiple
                            freeSolo
                            id="tags-outlined"
                            options={site_details.panel_brands}
                            defaultValue={site_details.panel_brands}
                            classes={{
                              endAdornment: classes.removeClose,
                              tag: classes.autoChip,
                              input: classes.txtField,
                              inputRoot: classes.paddR0,
                            }}
                            onChange={(e, newValue) => {
                              return this.handleAutoComplete(
                                e,
                                newValue,
                                "panel_brands"
                              );
                            }}
                            renderInput={(params) => (
                              <TextField variant="outlined" {...params} />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={6}>
                      <Trans>Inverter Brands & Models</Trans>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container>
                        <Grid item xs>
                          <Autocomplete
                            size="small"
                            multiple
                            freeSolo
                            id="tags-outlined"
                            options={site_details.inverter_brands}
                            defaultValue={site_details.inverter_brands}
                            classes={{
                              endAdornment: classes.removeClose,
                              tag: classes.autoChip,
                              input: classes.txtField,
                              inputRoot: classes.paddR0,
                            }}
                            onChange={(e, newValue) => {
                              return this.handleAutoComplete(
                                e,
                                newValue,
                                "inverter_brands"
                              );
                            }}
                            renderInput={(params) => (
                              <TextField variant="outlined" {...params} />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={6}>
                      <Trans>Feed in tariff</Trans>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container alignItems="center">
                        <Grid item xs={6}>
                          <label style={{ marginTop: 5 }}>
                            {site_details.tariff_data.units}
                          </label>
                        </Grid>

                        <Grid item xs={6}>
                          <CustomTextField
                            type="number"
                            gtmId="powerTariff"
                            handleChange={(value) => {
                              return this.handleInput(value, "powerTariff");
                            }}
                            value={this.state.powerTariff}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={6}>
                      <span>
                        <Trans>Panel Maximum Power (Pmax)</Trans>
                        <span style={{ color: "#FF0000" }}>*</span> :
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container>
                        <Grid item xs>
                          <CustomTextField
                            gtmId="panel_maximum_power"
                            type="number"
                            handleChange={(value) => {
                              return this.handleInput(
                                value,
                                "panel_maximum_power"
                              );
                            }}
                            value={this.state.panel_maximum_power}
                            error={this.state.pmpError}
                            helperText={
                              this.state.pmpError ? "Required field." : null
                            }
                            BoxHeight={this.state.pmpError ? 50 : 36}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={6}>
                      <Trans>Tilt Angle (Degree)</Trans>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid
                        container
                        alignItems="center"
                        className={classes.tilt}
                      >
                        <Grid item xs={12} md={6}>
                          <CustomDropdown
                            selectValue={this.state.tilt_angle}
                            onHandleSelectChange={(e) => {
                              return this.handleInput(e, "tilt_angle");
                            }}
                            menuItems={tiltAngleMenuItems}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <CustomTextField
                            gtmId="tiltCount"
                            handleChange={(value) => {
                              return this.handleInput(value, "tiltCount");
                            }}
                            value={this.state.tiltCount}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={6}>
                      <Trans>Photovoltaic power potential (Yield)</Trans>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container>
                        <Grid item xs={6}>
                          <CustomTextField
                            gtmId="pv_out"
                            handleChange={(value) => {
                              return this.handleInput(value, "pv_out");
                            }}
                            value={this.state.pv_out}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <label htmlFor="kWp-id" style={{ marginTop: 12 }}>
                            <Trans>kWh/kWp per year</Trans>
                          </label>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={6}>
                      <Trans>Land Area (acre)</Trans>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container>
                        <Grid item xs>
                          <CustomTextField
                            type="number"
                            gtmId="land_area"
                            handleChange={(value) => {
                              return this.handleInput(value, "land_area");
                            }}
                            value={this.state.land_area}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={6}>
                      <Trans>Pitch (m)</Trans>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container>
                        <Grid item xs>
                          <CustomTextField
                            type="number"
                            gtmId="pitch"
                            handleChange={(value) => {
                              return this.handleInput(value, "pitch");
                            }}
                            value={this.state.pitch}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={6}>
                      <Trans>Clear distance b/w row (m)</Trans>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container>
                        <Grid item xs>
                          <CustomTextField
                            type="number"
                            gtmId="clear_distance_between_row"
                            handleChange={(value) => {
                              return this.handleInput(
                                value,
                                "clear_distance_between_row"
                              );
                            }}
                            value={this.state.clear_distance_between_row}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={6}>
                      <Trans>PV Technology</Trans>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container>
                        <Grid item xs>
                          <CustomDropdown
                            selectValue={
                              this.state.pv_technology
                                ? this.state.pv_technology
                                : null
                            }
                            onHandleSelectChange={(e) => {
                              return this.handleInput(e, "pv_technology");
                            }}
                            menuItems={PVTechnologyOptions(
                              this.props.site_details.pv_technology_options
                            )}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ) : (
          <Grid
            item
            xs={12}
            md={6}
            className={classes.pad12}
            data-intercom-target="site-details-card"
          >
            <Card style={styles.card}>
              <CardContent style={styles.contentMob}>
                <Grid container className={classes.editSiteSummary}>
                  <Typography style={styles.chartHeading}>
                    <Trans>Site Details</Trans>
                  </Typography>
                  <CustomButton
                    text="Edit"
                    onClick={() => {
                      return this.EditSiteDetails();
                    }}
                  />
                </Grid>

                <Divider />
                <Grid container>
                  <Grid container className={classes.detailsBox}>
                    <Grid item xs={6}>
                      <span>
                        <Trans>DC Capacity (MWp)</Trans>
                        <span style={{ color: "#FF0000" }}>*</span> :
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container>
                        <Grid item xs>
                          {this.state.dc_capacity
                            ? this.state.dc_capacity
                            : "-"}
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={6}>
                      <Trans>AC Capacity (MW) :</Trans>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container>
                        <Grid item xs>
                          {this.state.ac_capacity
                            ? this.state.ac_capacity
                            : "-"}
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={6}>
                      <Trans>Commissioning Date:</Trans>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container>
                        <Grid item xs>
                          <Trans>
                            {this.state.commissioning_date
                              ? moment(this.state.commissioning_date).format(
                                  "D MMM, YYYY"
                                )
                              : "-"}
                          </Trans>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={6}>
                      <Trans>Lat / Long:</Trans>
                    </Grid>
                    <Grid item xs={6}>
                      {site_details.coordinate.latitude},{" "}
                      {site_details.coordinate.longitude}
                    </Grid>

                    <Grid item xs={6}>
                      <Trans>Module Mounting Structure:</Trans>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container>
                        <Grid item xs>
                          <Trans>
                            {this.state.module_mounting_type
                              ? this.state.module_mounting_type
                              : "-"}
                          </Trans>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={6}>
                      <Trans>Number of Panels</Trans>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container>
                        <Grid item xs>
                          {this.state.panels_count
                            ? this.state.panels_count
                            : "-"}
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={6}>
                      <Trans>Panel Brands & Models</Trans>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container>
                        {this.state.panel_brands.length > 0 &&
                          this.state.panel_brands.map((item) => (
                            <CustomFilterChip name={item} key={item} />
                          ))}
                      </Grid>
                    </Grid>

                    <Grid item xs={6}>
                      <Trans>Inverter Brands & Models</Trans>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container>
                        {this.state.inverter_brands.length > 0 &&
                          this.state.inverter_brands.map((item) => {
                            return <CustomFilterChip key={item} name={item} />;
                          })}
                      </Grid>
                    </Grid>

                    <Grid item xs={6}>
                      <Trans>Feed in tariff</Trans>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container alignItems="center">
                        <Grid item xs={6}>
                          <label style={{ marginTop: 5 }}>
                            {site_details.tariff_data.units}
                          </label>
                        </Grid>

                        <Grid item xs={6}>
                          {this.state.powerTariff
                            ? this.state.powerTariff
                            : "-"}
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={6}>
                      <span>
                        <Trans>Panel Maximum Power (Pmax)</Trans>
                        <span style={{ color: "#FF0000" }}>*</span> :
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container>
                        <Grid item xs>
                          {this.state.panel_maximum_power
                            ? this.state.panel_maximum_power
                            : "-"}
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={6}>
                      <Trans>Tilt Angle (Degree)</Trans>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container alignItems="center">
                        <Trans>{this.state.tilt_angle}</Trans>
                        <Grid item xs style={{ marginLeft: 10 }}>
                          {this.state.tiltCount}
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={6}>
                      <Trans>Photovoltaic power potential (Yield)</Trans>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container>
                        <Grid item xs={6} style={{ marginTop: 5 }}>
                          {this.state.pv_out}
                        </Grid>
                        <Grid item xs={6}>
                          <label
                            htmlFor="kWh-id"
                            style={{ marginTop: 5, marginLeft: -2 }}
                          >
                            <Trans>kWh/kWp per year</Trans>
                          </label>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={6}>
                      <Trans>Land Area (acre)</Trans>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container>
                        <Grid item xs>
                          {this.state.land_area ? this.state.land_area : "-"}
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={6}>
                      <Trans>Pitch (m)</Trans>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container>
                        <Grid item xs>
                          {this.state.pitch ? this.state.pitch : "-"}
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={6}>
                      <Trans>Clear distance b/w row (m)</Trans>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container>
                        <Grid item xs>
                          {this.state.clear_distance_between_row
                            ? this.state.clear_distance_between_row
                            : "-"}
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={6}>
                      <Trans>PV Technology</Trans>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container>
                        <Grid item xs>
                          {this.state.pv_technology
                            ? this.state.pv_technology
                            : "-"}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        )}

        <Grid item xs={12} md={6} className={classes.pad12}>
          <Card style={styles.card}>
            <CardContent style={styles.contentMob}>
              <Typography style={styles.chartHeading}>
                <Trans>SITE LAYOUT</Trans>
              </Typography>
              <Divider />
              <Grid container>
                <Grid item xs={12} className={classes.imgLayout}>
                  <div className={classes.uploadOuter}>
                    <label className={classes.uploadLabel} htmlFor="fileUpload">
                      <img alt="uploadImg" src={UploadImg} />
                    </label>
                    <input
                      style={{ display: "none" }}
                      accept=".png, .jpg, .svg"
                      id="fileUpload"
                      type="file"
                      onChange={(e) => {
                        this.uploadFileChange(e, this.props.site_details);
                      }}
                    />
                  </div>
                  <div
                    onClick={() => {
                      return this.displayImageOpen();
                    }}
                    style={{ cursor: "pointer" }}
                    role="button"
                    tabIndex={"0"}
                    aria-hidden="true"
                  >
                    <img
                      alt="layoutImg"
                      width="100%"
                      height="auto"
                      src={resizedImageUrl(layout_image_url)}
                    />
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <div className={classes.carouselContainer}>
            <AutoRotatingCarousel
              label="true"
              autoplay={false}
              hideArrows={false}
              open={this.state.openImage}
              onClose={() => {
                return this.displayImageClose();
              }}
              onStart={() => {
                return this.displayImageClose();
              }}
              style={{ position: "absolute" }}
              ModalProps={{ className: classes.carouselRoot }}
              ButtonProps={{
                style: { borderRadius: "50%", color: "#616161" },
                startIcon: <CloseRoundedIcon style={{ fontSize: "50px" }} />,
                classes: { root: classes.closeButton },
              }}
            >
              <>
                <Typography className={classes.imageTitle}>
                  {layout_image_url && layout_image_url.match(/([^/]*)\/*$/)[1]}
                </Typography>
                <Slide
                  media={
                    <img
                      alt="layoutImg"
                      className={classes.layoutImg}
                      src={layout_image_url}
                    />
                  }
                  mediaBackgroundStyle={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgb(0,0,0,0)",
                  }}
                  className={classes.slide}
                />
              </>
            </AutoRotatingCarousel>
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(SiteDetailsTab);
