import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { customReportTableProps, iFormData } from "../../constants";

import Config from "../../config";
import CustomDownloadButton from "./CustomDownloadButton";
import { Trans } from "react-i18next";
import { allRequestHandler } from "../../api";
import el_img from "../../assets/img/el_image.jpg";
import falsepositiveImg from "../../assets/img/falsePositive.svg";
import handheld_img from "../../assets/img/handheld_img.png";
import iec1 from "../../assets/img/iec1.svg";
import iec2 from "../../assets/img/iec2.svg";
import iec3 from "../../assets/img/iec3.svg";
import inprogressImg from "../../assets/img/progress.svg";
import iv_data from "../../assets/img/iv_data.png";
import minorImg from "../../assets/img/Subtract.svg";
import notfoundImg from "../../assets/img/notfound.svg";
import pendingImg from "../../assets/img/pending.svg";
import resolvedImg from "../../assets/img/resolved.svg";
import rgb_img from "../../assets/img/rgb_image.jpg";
import styles from "./reports.style";
import subtractImg from "../../assets/img/Moderate.svg";
import thermal_image from "../../assets/img/thermal_image.jpg";
import uncategorized from "../../assets/img/uncategorized.svg";
import vectorImg from "../../assets/img/Vector.svg";

const requestUrl = Config.hostName;

//View the custom report created by the user
const CustomReportView = ({
  customAnomalyList,
  setFormData,
  downloadHref,
  customFormHandler,
  backButtonHandler,
  getReportHistory,
  formData,
  reportRef,
  callSnackbar,
  startIndex,
  setStartIndex,
  classes,
}) => {
  //List of all the images used in the report
  const imageArr = {
    attachments: {
      thermal_image,
      iv_data,
      handheld_image: handheld_img,
      rgb_image: rgb_img,
      el_image: el_img,
    },
    iecArr: [iec1, iec2, iec3, uncategorized],
    status: {
      pending: pendingImg,
      falsepositive: falsepositiveImg,
      resolved: resolvedImg,
      notfound: notfoundImg,
      inprogress: inprogressImg,
    },
    severity: {
      Critical: vectorImg,
      Moderate: subtractImg,
      Minor: minorImg,
    },
  };

  //Get the images and keys for the table
  const getTableProps = (obj, section) => {
    const keys = Object.keys(obj).map((key) => {
      return key;
    });
    const imgs = keys.filter((item) => {
      return (
        item === "thermal_image" ||
        item === "rgb_image" ||
        item === "el_image" ||
        item === "iv_data" ||
        item === "handheld_image"
      );
    });
    if (section === "header") {
      return customReportTableProps.filter((item) => {
        return item.name === "Attachments" && imgs.length > 0
          ? "Attachments"
          : keys.includes(item.key) && item;
      });
    } else if (section === "body") {
      return customReportTableProps
        .filter((item) => {
          return item.name === "Attachments"
            ? imgs.length > 0
            : keys.includes(item.key);
        })
        .map((item) => {
          if (item.name === "Attachments") {
            const attachments = item.key.filter((oneItem) => {
              return obj[oneItem] !== null && keys.includes(oneItem);
            });
            const validImgArr = attachments.map((innerItem) => {
              return [obj[innerItem], innerItem];
            });
            return attachments.length > 0 ? ["attachments", validImgArr] : [];
          }

          return [obj[item.key], item.key];
        });
    }
    return false;
  };

  //handle pagination (load next/previous 10 records)
  const handleNavigation = (direction) => {
    if (direction === "next") {
      setStartIndex(startIndex + 10);
    } else if (direction === "prev") {
      setStartIndex(startIndex - 10);
    }
  };

  //Load the records on the 1st page on the initial render
  useEffect(() => {
    if (customAnomalyList.length < startIndex) {
      setStartIndex(0);
    }
  }, []);

  //Handles the download of the custom report
  const downloadCustomReport = async (data, type) => {
    backButtonHandler();
    setFormData({
      ...iFormData,
      plant_id: formData.plant_id,
      section_id: formData.section_id,
    });
    const requestBody = {
      ...data,
      report_name: data.report_name.trim(),
      section_id: data.section_id === "All" ? null : data.section_id,
    };
    await allRequestHandler(
      {
        requestType: "POST",
        requestUrl:
          type === "csv"
            ? requestUrl + downloadHref[0].href
            : requestUrl + downloadHref[1].href,
        requestData: requestBody,
      },
      () => {
        backButtonHandler();
      }
    );
    await getReportHistory();
  };

  // removing the pdf item in downloadTypes removes it from download options
  const downloadTypes = ["csv"];

  //View and download the custom report created by the user
  return (
    <Grid className={classes.customContainer} ref={reportRef}>
      <Grid xs={12} className={classes.reportTable}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className={classes.downloadBtnOuter}
        >
          {downloadTypes &&
            downloadTypes.map((type) => {
              return (
                <CustomDownloadButton
                  callSnackbar={callSnackbar}
                  key={type}
                  type={type}
                  formData={formData}
                  customFormHandler={customFormHandler}
                  downloadCustomReport={downloadCustomReport}
                />
              );
            })}
        </Grid>
        <Grid style={{ overflow: "scroll" }}>
          <Table className={classes.table} style={{ padding: "50px" }}>
            <TableHead className={classes.tableCellOuter}>
              <TableRow>
                {customAnomalyList[0] &&
                  getTableProps(customAnomalyList[0], "header").map((item) => {
                    return (
                      <TableCell
                        key={item.key}
                        className={classes.tableHeader}
                        style={{ whiteSpace: "nowrap" }}
                      >
                        <Trans>{item.name}</Trans>
                      </TableCell>
                    );
                  })}
              </TableRow>
            </TableHead>
            <TableBody>
              {customAnomalyList &&
                customAnomalyList.map((outer, mainIndex) => {
                  return (
                    mainIndex >= startIndex &&
                    mainIndex < startIndex + 10 && (
                      <TableRow
                        key={customAnomalyList[mainIndex].module_coordinates}
                      >
                        {customAnomalyList[mainIndex] &&
                          getTableProps(
                            customAnomalyList[mainIndex],
                            "body"
                          ).map((item) => {
                            let splitIEC = "";
                            if (item && item[0] && item[0].length) {
                              splitIEC = item[0].split(" ");
                            }
                            return (
                              <TableCell
                                key={item}
                                className={classes.tableHeader}
                                style={{ whiteSpace: "nowrap" }}
                              >
                                {item[0] ? (
                                  item[0] === "attachments" ? (
                                    item[1].length > 0 &&
                                    item[1].map((innerItem) => {
                                      return (
                                        <React.Fragment key={innerItem[0]}>
                                          <a
                                            href={innerItem[0]}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            <img
                                              src={
                                                imageArr.attachments[
                                                  innerItem[1]
                                                ]
                                              }
                                              alt="attachement"
                                              className={classes.innerArrImg}
                                              aria-hidden="true"
                                            />
                                          </a>
                                        </React.Fragment>
                                      );
                                    })
                                  ) : item[1] === "anomaly_number" ? (
                                    `#${item[0]}`
                                  ) : item[1] === "iec_category" ? (
                                    <>
                                      <img
                                        style={{ marginRight: 5 }}
                                        src={
                                          item[0] === "Uncategorized"
                                            ? imageArr.iecArr[
                                                imageArr.iecArr.length - 1
                                              ]
                                            : imageArr.iecArr[
                                                parseInt(splitIEC[1]) - 1
                                              ]
                                        }
                                        alt="attachement"
                                      />
                                      <Trans>{`${item[0]}`}</Trans>
                                    </>
                                  ) : item[1] === "status" ? (
                                    <>
                                      <img
                                        style={{ marginRight: 5 }}
                                        src={imageArr.status[item[0]]}
                                        alt="attachement"
                                      />{" "}
                                      <Trans>
                                        {item[0] === "resolved"
                                          ? "Resolved"
                                          : item[0] === "falsepositive"
                                          ? "False +ve"
                                          : item[0] === "notfound"
                                          ? "Not Found"
                                          : item[0] === "inprogress"
                                          ? "In Progress"
                                          : item[0] === "pending"
                                          ? "Pending"
                                          : item[0]}
                                      </Trans>
                                    </>
                                  ) : item[1] === "severity" ? (
                                    <>
                                      <img
                                        src={imageArr.severity[item[0]]}
                                        alt="attachement"
                                        className={
                                          item[0] === "Moderate" &&
                                          classes.reportMod
                                        }
                                      />{" "}
                                      <Trans>{item[0]}</Trans>
                                    </>
                                  ) : (
                                    item[0]
                                  )
                                ) : (
                                  "-"
                                )}
                              </TableCell>
                            );
                          })}
                      </TableRow>
                    )
                  );
                })}
            </TableBody>
          </Table>
        </Grid>
        {customAnomalyList.length > 0 && (
          <div className={classes.anomalyList}>
            {startIndex > 0 && (
              <Typography
                className={`${classes.titleText} ${classes.selectLink}`}
                onClick={() => {
                  return handleNavigation("prev");
                }}
              >
                <Trans>load previous 10</Trans>
              </Typography>
            )}

            <Typography
              style={{ margin: " 0 1rem" }}
              className={classes.titleText}
            >
              <Trans>showing</Trans> {startIndex + 1} -{" "}
              {customAnomalyList.length > startIndex + 10
                ? startIndex + 10
                : customAnomalyList.length}{" "}
              <Trans>of</Trans>{" "}
              {customAnomalyList.length && customAnomalyList.length}{" "}
              <Trans>items.</Trans>
            </Typography>
            {customAnomalyList && startIndex + 10 < customAnomalyList.length && (
              <Typography
                className={`${classes.titleText} ${classes.selectLink}`}
                onClick={() => {
                  return handleNavigation("next");
                }}
              >
                <Trans>load next 10</Trans>
              </Typography>
            )}
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(CustomReportView);
