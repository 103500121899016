import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@material-ui/lab";

import React from "react";
import { Trans } from "react-i18next";
import styles from "./siteSummary.style";
import { withStyles } from "@material-ui/core/styles";

//Request inspection page
class RequestInsp extends React.Component {
  //This renders the UI for request inspection page
  render() {
    const { classes } = this.props;
    return (
      <Card className={classes.cardOuter}>
        <CardContent style={{ padding: 0 }}>
          <Grid
            container
            justifyContent="space-between"
            style={{ borderBottom: "1px solid #EFF3FB" }}
          >
            <Typography className={classes.reqHeader}>
              <Trans>New Inspection</Trans>
            </Typography>
            <Typography className={classes.reqTime}>
              <Trans>Last updated: 12 Oct, 2020</Trans>
            </Typography>
          </Grid>

          <Grid>
            <Timeline align="left">
              <TimelineItem className={classes.alignTimeline}>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Card className={classes.cardInner}>
                    <Typography className={classes.cardHeader}>
                      <Trans>Inspection Requirements</Trans>
                    </Typography>
                    <Typography className={classes.cardLink}>
                      <Trans>Requirements PDF</Trans>
                    </Typography>
                  </Card>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem className={classes.alignTimeline}>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Card className={classes.cardInner}>
                    <Typography className={classes.cardHeader}>
                      <Trans>Flight</Trans>
                    </Typography>
                    <Typography className={classes.cardSubHeader}>
                      <Trans>Done: 23 Oct, 2020</Trans>
                    </Typography>
                  </Card>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem className={classes.alignTimeline}>
                <TimelineSeparator>
                  <TimelineDot />
                </TimelineSeparator>
                <TimelineContent>
                  <Card className={classes.cardInner}>
                    <Typography className={classes.cardHeader}>
                      <Trans>Analysis Report</Trans>
                    </Typography>
                    <Typography className={classes.cardSubHeader}>
                      <Trans>Expected on: 30 Oct, 2020</Trans>
                    </Typography>
                  </Card>
                </TimelineContent>
              </TimelineItem>
            </Timeline>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles)(RequestInsp);
