import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
} from "react-virtualized";
import { Grid, ListItem, ListItemText, Typography } from "@material-ui/core";

import AnomalyStatus from "../common/anomalyStatus/index.js";
import React from "react";
import { Trans } from "react-i18next";
import attachment from "../../assets/img/attachmentsUpdated.svg";
import comment from "../../assets/img/commentsUpdated.svg";
import iec1 from "../../assets/img/iec1.svg";
import iec2 from "../../assets/img/iec2.svg";
import iec3 from "../../assets/img/iec3.svg";
import minorImg from "../../assets/img/Subtract.svg";
import moderateIcon from "../../assets/img/moderateicon.svg";
import { statusList } from "../../constants";
import styles from "./defectMap.style";
import uncategorized from "../../assets/img/uncategorized.svg";
import vectorImg from "../../assets/img/Vector.svg";
import { withStyles } from "@material-ui/core/styles";

// import './sidelist.css';

//Side list view component
const SideListView = (props) => {
  const { classes } = props;
  const cache = React.useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 115,
    })
  );

  //To handle click events when marker is clicked
  const clickDefect = (value) => {
    props.showDefectSidebar(value);
    props.removeSelectedDefect(value);
  };

  return props.defectList && props.defectList.length ? (
    <div
      style={{
        width: "474px",
        height: "calc(100vh - 182px)",
        overflowX: "hidden",
      }}
    >
      <AutoSizer>
        {({ width, height }) => {
          return (
            <List
              width={width}
              height={height}
              rowHeight={cache.current.rowHeight}
              deferredMeasurementCache={cache.current}
              rowCount={props.defectList.length}
              rowRenderer={({ key, index, style, parent }) => {
                const defect = props.defectList[index];

                return (
                  <CellMeasurer
                    key={key}
                    cache={cache.current}
                    parent={parent}
                    columnIndex={0}
                    rowIndex={index}
                  >
                    <div style={style}>
                      <ListItem
                        button
                        className={classes.listBg}
                        // style={{
                        //   marginBottom:
                        //     props.defectList.length == index + 1 ? 84 : 10,

                        // }}
                        onClick={() => {
                          clickDefect(defect);
                        }}
                        data-intercom-target={
                          index === 0 ? "first-defect-item" : "others"
                        }
                        key={key}
                      >
                        <ListItemText>
                          <Grid container alignItems="center">
                            <Grid
                              item
                              xs
                              className={classes.listDefTxt}
                              style={{
                                borderLeft: `3px solid ${defect.properties.color_code}`,
                              }}
                            >
                              <Grid container>
                                <Grid item xs={12} md>
                                  <Grid container alignItems="center">
                                    <Typography className={classes.faultNo}>
                                      #{defect.properties.defect_no}
                                    </Typography>

                                    <Typography
                                      container="true"
                                      className={classes.dot}
                                    />

                                    <Typography
                                      className={classes.anomalyCoordinate}
                                    >
                                      {defect.properties.module_coordinates
                                        ? defect.properties.module_coordinates
                                        : "-"}
                                    </Typography>

                                    <Typography
                                      container="true"
                                      className={classes.dot}
                                    />

                                    <Typography
                                      className={classes.anomalyLabel}
                                    >
                                      {defect.properties.label}
                                    </Typography>

                                    <Typography
                                      container="true"
                                      className={classes.dot}
                                    />

                                    <Typography className={classes.anomalyId}>
                                      {defect.properties.string_reference
                                        ? defect.properties.string_reference
                                        : "-"}
                                    </Typography>
                                  </Grid>
                                  <Typography
                                    variant="h5"
                                    className={classes.defType}
                                  >
                                    <Trans>
                                      {defect.properties.defect_type_name}
                                    </Trans>
                                  </Typography>
                                </Grid>
                                <Grid container alignItems="center">
                                  {defect.properties.iec_category ===
                                  "IEC 1" ? (
                                    <img
                                      alt="iecImg"
                                      className={classes.btnImg}
                                      style={{ marginRight: 10 }}
                                      src={iec1}
                                    />
                                  ) : defect.properties.iec_category ===
                                    "IEC 2" ? (
                                    <img
                                      alt="iecImg"
                                      className={classes.btnImg}
                                      style={{ marginRight: 10 }}
                                      src={iec2}
                                    />
                                  ) : defect.properties.iec_category ===
                                    "IEC 3" ? (
                                    <img
                                      alt="iecImg"
                                      className={classes.btnImg}
                                      style={{ marginRight: 10 }}
                                      src={iec3}
                                    />
                                  ) : (
                                    <img
                                      alt="iecImg"
                                      className={classes.btnImg}
                                      src={uncategorized}
                                      style={{ marginRight: 10 }}
                                    />
                                  )}

                                  {defect.properties.severity_type ===
                                  "Critical" ? (
                                    <img
                                      alt="sevImg"
                                      style={{ marginRight: 10 }}
                                      className={classes.btnImg}
                                      src={vectorImg}
                                    />
                                  ) : defect.properties.severity_type ===
                                    "Moderate" ? (
                                    <img
                                      alt="sevImg"
                                      style={{ marginRight: 10 }}
                                      className={[
                                        classes.btnImg,
                                        classes.modeIcon,
                                      ].join(" ")}
                                      src={moderateIcon}
                                    />
                                  ) : (
                                    <img
                                      alt="sevImg"
                                      style={{ marginRight: 10 }}
                                      className={classes.btnImg}
                                      src={minorImg}
                                    />
                                  )}

                                  <span style={{ marginRight: 10 }}>
                                    <img
                                      alt="sevImg"
                                      style={{ marginRight: 5 }}
                                      className={classes.btnImg}
                                      src={comment}
                                    />
                                    {defect.properties.comment_count}
                                  </span>

                                  <span>
                                    <img
                                      alt="sevImg"
                                      style={{ marginRight: 5 }}
                                      className={classes.btnImg}
                                      src={attachment}
                                    />
                                    {defect.properties.attachment_count
                                      ? defect.properties.attachment_count
                                      : 0}
                                  </span>

                                  <AnomalyStatus
                                    statusList={statusList}
                                    defect={defect}
                                    handleStatus={props.handleStatus()}
                                    statusView="selectView"
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </ListItemText>
                      </ListItem>
                    </div>
                  </CellMeasurer>
                );
              }}
            />
          );
        }}
      </AutoSizer>
    </div>
  ) : (
    ""
  );
};
export default withStyles(styles)(SideListView);
