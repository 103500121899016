import "./index.css";

import {
  AppBar,
  Divider,
  Grid,
  Snackbar,
  SwipeableDrawer,
  Toolbar,
  Typography,
} from "@material-ui/core";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CustomBtn from "./customBtn";
import CustomButton from "../common/customStyle/Button";
import CustomFormControl from "./customFormControl";
import CustomTextField from "../common/customStyle/TextField";
import MuiAlert from "@material-ui/lab/Alert";
import { Navigate } from "react-router-dom";
import NewLoader from "../../layout/newLoader";
import React from "react";
import ReactGA from "react-ga";
import { Trans } from "react-i18next";
import { URLS } from "../../constants";
import { allRequestHandler } from "../../api";
import { callSnackbarAction } from "../../reducers/snackbar";
import { connect } from "react-redux";
import { store } from "../../store";
import { styles } from "./myProfile.style";
import { trackId } from "../../config";
import { withStyles } from "@material-ui/core/styles";

ReactGA.initialize(trackId);

//profile component
class MyProfile extends React.Component {
  //Initialize state
  constructor(props) {
    super(props);
    this.state = {
      oldPassword: "",
      newPassword: "",
      getUserData: this.props.loggedInUser
        ? this.props.loggedInUser.user_profile
        : null,
      open: false,
      confirmPassword: "",
      showPassword: false,
      showPasswordNew: false,
      showPasswordConfirm: false,
      useSnackbar: false,
      loading: this.props.loggedInUser ? false : true,
      displayMsg: null,
      redirect: false,
      drawerContent: null,
      firstName: this.props.loggedInUser
        ? this.props.loggedInUser.user_profile.first_name
        : null,
      lastName: this.props.loggedInUser
        ? this.props.loggedInUser.user_profile.last_name
        : null,
      userEmail: this.props.loggedInUser
        ? this.props.loggedInUser.user_profile.email_id
        : null,
      userJobTitle: this.props.loggedInUser
        ? this.props.loggedInUser.user_profile.job_title
        : null,
    };
  }

  //These functions are called when the component is mounted (on initial render)
  componentDidMount() {
    // For Google Analytics
    ReactGA.pageview(window.location.pathname);
  }

  //These functions are called when the component is updated (on subsequent renders)
  componentDidUpdate(prevProps) {
    //Update data when the user logs in
    if (
      prevProps.loggedInUser !== this.props.loggedInUser &&
      this.props.loggedInUser
    ) {
      // skipcq Avoid usage of setState in componentDidUpdate JS-0443
      this.setState({
        getUserData: this.props.loggedInUser.user_profile,
        loading: false,
        firstName: this.props.loggedInUser.user_profile.first_name,
        lastName: this.props.loggedInUser.user_profile.last_name,
        userEmail: this.props.loggedInUser.user_profile.email_id,
        userJobTitle: this.props.loggedInUser.user_profile.job_title,
      });
    }
  }

  //Handles the password update
  handlePasswordChange = async () => {
    // #TODO Use only for demo site
    if (window.location.hostname === "demo.airprobe.app")
      this.props.callSnackbar("This feature is not available on demo site");
    else {
      const response = await allRequestHandler({
        requestType: "POST",
        requestUrl: URLS.CHANGE_PASSWORD,
        requestData: {
          old_password: this.state.oldPassword,
          new_password: this.state.newPassword,
        },
      });

      if (response) {
        await allRequestHandler({
          requestType: "POST",
          requestUrl: URLS.LOGOUT,
          requestData: "",
        });
        sessionStorage.setItem("jwtToken", "");

        store.dispatch(
          callSnackbarAction(
            "Password updated Successfully. Please login again.",
            "success"
          )
        );
        this.setState({
          redirect: true,
        });
      }
    }
  };

  //Closing the snackbar (warnings, info and errors alert)
  handleCloseSnackbar = () => {
    this.setState({ useSnackbar: false });
  };

  //Handles the change in the input fields
  handleChange = (event, prop) => {
    this.setState({ [prop]: event.target.value });
  };

  //Handles the visibility of the new password field  (eye icon)
  handleClickNew = () => {
    this.setState((prevState) => {
      return {
        showPasswordNew: !prevState.showPasswordNew,
      };
    });
  };

  //Handles the visibility of the confirm password field  (eye icon)
  handleClickConfirm = () => {
    this.setState((prevState) => {
      return {
        showPasswordConfirm: !prevState.showPasswordConfirm,
      };
    });
  };

  //Handles the visibility of the old password field  (eye icon)
  handleClickShowPassword = () => {
    this.setState((prevState) => {
      return {
        showPassword: !prevState.showPassword,
      };
    });
  };

  //Checks the validity of the new password and update password if valid
  handleSubmit = () => {
    const { oldPassword, newPassword, confirmPassword } = this.state;

    if (oldPassword === "" || newPassword === "" || confirmPassword === "") {
      this.setState({
        useSnackbar: true,
        displayMsg: "Please fill all Details.",
      });
    } else if (newPassword !== confirmPassword) {
      this.setState({
        useSnackbar: true,
        displayMsg: "Passwords don't match",
      });
    } else {
      this.handlePasswordChange();
    }
  };

  //To handle toggle drawer
  toggleDrawer(open, field) {
    if (field === "Profile") {
      return this.setState({ drawerContent: field, open });
    }
    return (event) => {
      if (
        event &&
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
      ) {
        return;
      }
      this.setState({ open, drawerContent: null });
    };
  }

  //Handles the input from the edit user profile form
  editUser = (event, field) => {
    this.setState({ [field]: event.target.value });
  };

  //Handles the update of the user profile
  submitUser = async () => {
    // #TODO Use only for demo site
    let updateDef = "";
    if (window.location.hostname === "demo.airprobe.app")
      this.props.callSnackbar("This feature is not available on demo site");
    else if (this.state.firstName && this.state.lastName) {
      updateDef = await allRequestHandler({
        requestType: "PUT",
        requestUrl: `${URLS.USER_PROFILE}?method=PUT`,
        requestData: {
          email_id: this.state.userEmail,
          first_name: this.state.firstName,
          job_title: this.state.userJobTitle,
          last_name: this.state.lastName,
          username: this.props.loggedInUser.user_profile.username,
        },
      });
    } else {
      return store.dispatch(
        callSnackbarAction("Please fill all the details", "error")
      );
    }

    if (updateDef) {
      store.dispatch(
        callSnackbarAction("User Details updated successfully.", "success")
      );
      this.props.callLoggedInUser();
    }
  };

  //Renders the user profile page
  render() {
    const { open } = this.state;
    const { classes } = this.props;

    // If user has changed password, redirect to home (login page)
    if (this.state.redirect) {
      return <Navigate replace to="/" />;
    }

    return (
      <>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={3000}
          open={this.state.useSnackbar}
          onClose={this.handleCloseSnackbar}
        >
          <MuiAlert elevation={6} variant="filled" severity="info">
            {" "}
            {this.state.displayMsg}{" "}
          </MuiAlert>
        </Snackbar>
        <AppBar
          elevation={0}
          position="static"
          style={{
            backgroundColor: "white",
            color: "black",
            boxShadow: "0px 2px 10px rgba(78, 89, 109, 0.07)",
          }}
        >
          <Toolbar>
            <Typography
              variant="h6"
              color="inherit"
              className={classes.title}
              style={{ marginLeft: `${this.props.toggleMenu && "30px"}` }}
            >
              <Trans>My Profile</Trans>
            </Typography>
          </Toolbar>
        </AppBar>
        {this.state.loading ? (
         
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ height: "100%" }}
          >
            <NewLoader />
          </Grid>
        ) : (
          <div
            style={{ backgroundColor: "rgb(239,244,251)" }}
            className="pl-4 pr-4 pt-2"
          >
            <Grid container>
              <Grid item className={classes.outerCard}>
                <Typography
                  className={classes.heading}
                  style={{ padding: 15, borderBottom: "1px solid #E4EAF5" }}
                >
                  <Trans>Personal Information</Trans>
                </Typography>
                <div style={{ padding: 15 }}>
                  <Grid container style={{ padding: "10px 0" }}>
                    <Grid item xs={4} className={classes.userLabel}>
                      <Trans>Name</Trans>
                    </Grid>
                    <Grid item xs={8} className={classes.userData}>
                      {this.state.getUserData &&
                        this.state.getUserData.first_name}{" "}
                      {this.state.getUserData &&
                        this.state.getUserData.last_name}
                    </Grid>
                  </Grid>
                  <Grid container style={{ padding: "10px 0" }}>
                    <Grid item xs={4} className={classes.userLabel}>
                      <Trans>Email</Trans>
                    </Grid>
                    <Grid item xs={8} className={classes.userData}>
                      {this.state.getUserData &&
                        this.state.getUserData.email_id}
                    </Grid>
                  </Grid>
                  <Grid container style={{ padding: "10px 0" }}>
                    <Grid item xs={4} className={classes.userLabel}>
                      <Trans>Role</Trans>
                    </Grid>
                    <Grid item xs={8} className={classes.userData}>
                      {this.state.getUserData &&
                        this.state.getUserData.job_title}
                    </Grid>
                  </Grid>
                  <CustomBtn
                    onClickHandler={() => {
                      return this.toggleDrawer(true, "Profile");
                    }}
                    dataTarget="edit-profile"
                    title="Edit Profile"
                    variant="outlined"
                    className={classes.btn}
                  />
                </div>
              </Grid>

              <Grid item className={classes.outerCard}>
                <Typography
                  className={classes.heading}
                  style={{ padding: 15, borderBottom: "1px solid #E4EAF5" }}
                >
                  <Trans>Password</Trans>
                </Typography>
                <CustomBtn
                  variant="outlined"
                  onClickHandler={this.toggleDrawer(true)}
                  dataTarget="edit-password"
                  title="Change Password"
                  className={classes.btn}
                />
              </Grid>
            </Grid>
            <SwipeableDrawer
              classes={{ paper: classes.width500 }}
              style={{ margin: 0 }}
              variant="persistent"
              anchor="right"
              open={open}
              onClose={() => {
                return this.toggleDrawer(false);
              }}
              onOpen={() => {
                return this.toggleDrawer(true);
              }}
            >
              {!this.state.drawerContent ? (
                <>
                  <Grid container>
                    <ArrowBackIcon
                      style={{ margin: 12 }}
                      className={classes.backIcon}
                      onClick={this.toggleDrawer(false)}
                    />
                    <Typography className={classes.heading}>
                      <Trans>Change Password</Trans>
                    </Typography>
                  </Grid>
                  <Divider />

                  <Typography className={classes.userSideLabel}>
                    <Trans>Current Password</Trans>
                  </Typography>
                  <CustomFormControl
                    passwordState={this.state.showPassword}
                    value={this.state.oldPassword}
                    onChangeHandler={(e) => {
                      this.handleChange(e, "oldPassword");
                    }}
                    id="oldpass"
                    endadornmentHandler={this.handleClickShowPassword}
                  />
                  <br />
                  <Divider />
                  <br />

                  <Typography className={classes.userSideLabel}>
                    {" "}
                    <Trans>New Password</Trans>
                  </Typography>
                  <CustomFormControl
                    passwordState={this.state.showPasswordNew}
                    value={this.state.newPassword}
                    onChangeHandler={(e) => {
                      this.handleChange(e, "newPassword");
                    }}
                    id="newpass"
                    endadornmentHandler={this.handleClickNew}
                  />
                  <Typography className={classes.userSideLabel}>
                    <Trans>Confirm Password</Trans>
                  </Typography>
                  <CustomFormControl
                    passwordState={this.state.showPasswordConfirm}
                    value={this.state.confirmPassword}
                    onChangeHandler={(e) => {
                      this.handleChange(e, "confirmPassword");
                    }}
                    id="confirmpass"
                    endadornmentHandler={this.handleClickConfirm}
                  />
                  <div style={{ padding: 12 }}>
                    <CustomBtn
                      variant="contained"
                      color="primary"
                      className={classes.btnSidebar}
                      onClickHandler={() => {
                        return this.handleSubmit();
                      }}
                      title="Update Password"
                    />
                  </div>
                </>
              ) : (
                <>
                  <Grid container>
                    <ArrowBackIcon
                      style={{ margin: 12 }}
                      className={classes.backIcon}
                      onClick={this.toggleDrawer(false)}
                    />
                    <Typography className={classes.heading}>
                      <Trans>Edit Profile</Trans>
                    </Typography>
                  </Grid>
                  <Divider />
                  <Grid
                    container
                    direction="column"
                    className={classes.boxArea}
                  >
                    <CustomTextField
                      label={<Trans>First Name</Trans>}
                      value={this.state.firstName}
                      handleChange={(e) => {
                        return this.editUser(e, "firstName");
                      }}
                    />
                    <CustomTextField
                      label={<Trans>Last Name</Trans>}
                      value={this.state.lastName}
                      handleChange={(e) => {
                        return this.editUser(e, "lastName");
                      }}
                    />
                    <CustomTextField
                      label={<Trans>Email</Trans>}
                      value={this.state.userEmail}
                      handleChange={(e) => {
                        return this.editUser(e, "userEmail");
                      }}
                    />
                    <CustomTextField
                      label={<Trans>Job Title</Trans>}
                      value={this.state.userJobTitle}
                      handleChange={(e) => {
                        return this.editUser(e, "userJobTitle");
                      }}
                    />
                    <CustomButton
                      variant="contained"
                      onClick={() => {
                        return this.submitUser();
                      }}
                      text={<Trans>Save</Trans>}
                    />
                  </Grid>
                </>
              )}
            </SwipeableDrawer>
          </div>
        )}
      </>
    );
  }
}

//Calling redux to get the selected language
const mapStateToProps = (state) => {
  return {
    language: state.language.selectedLanguage,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(MyProfile));
