import "./App.css";

import * as Sentry from "@sentry/react";

import ErrorBoundary from "./views/errorHandling/ErrorBoundary";
import Header from "./components/header/header";
import { MuiThemeProvider } from "@material-ui/core";
import React from "react";
import theme from "./appStyle";
import { useNavigate } from "react-router-dom";

function App({ cookieStorage }) {
  //navigate for changing routes effectively.
  const navigate = useNavigate();
  const currentPath = window.location.pathname;

  return (
    <MuiThemeProvider theme={theme}>
      <ErrorBoundary>
        <Header
          navigate={navigate}
          currentPath={currentPath}
          cookieStorage={cookieStorage}
        />
      </ErrorBoundary>
    </MuiThemeProvider>
  );
}

export default Sentry.withProfiler(App);
