import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Divider, Grid, Typography, withStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import CloseIcon from "@material-ui/icons/Close";
import CustomButton from "../../common/customStyle/Button";
import CustomDropdown from "../../common/customStyle/Dropdown";
import CustomTooltip from "../../../views/common/customStyle/Tooltip";
import InfoIcon from "../../../assets/img/info.svg";
import { Multiselect } from "multiselect-react-dropdown";
import ProButton from "../../product/ProButton";
import TemperatureRange from "../../common/temperatureRange";
import { Trans } from "react-i18next";
import { URLS } from "../../../constants";
import _ from "lodash";
import { allRequestHandler } from "../../../api";
import { connect } from "react-redux";
import i18n from "i18next";
import landscape from "../../../assets/img/landscape.svg";
import moment from "moment";
import portrait from "../../../assets/img/portrait.svg";
import styles from "../reports.style";

//Download popover
const DownloadPopup = ({
  classes,
  hideDownloadPopup,
  plantList,
  reportType,
  type,
  callSnackbar,
  product,
  handleShowPlans,
  getReportHistory,
  loggedInUser,
  isDownloading,
  generateReport,
  reportDetails,
  anomalies,
}) => {
  const [selectedPlantId, setSelectedPlantId] = useState(plantList[0]?.plant_id);
  const [selectedSectionId, setSelectedSectionId] = useState(null);
  const [selectedPlantName, setSelectedPlantName] = useState(
    plantList[0]?.plant_name
  );
  const [pdfOrientation, setPdfOrientation] = useState("portrait");
  const [language, setLanguage] = useState("english");
  const [tempRange, setTempRange] = useState([0, 100]);
  const [translatedAnomalies, setTranslatedAnomalies] = useState([]);
  const [selectedAnomalies, setSelectedAnomalies] = useState([]);
  const [historicalData, setHistoricalData] = useState(false);

  const siteSelect = [];
  const sectionSelect = [];

  useEffect(() => {
    const anomaliesData = [];

    //Translate defect name based on the selected language and add defect_type_id in the object
    anomalies.map((data) => {
      const tempData = {};
      tempData.defect_name = i18n.t(data.defect_name);
      tempData.defect_type_id = data.defect_type_id;
      return anomaliesData.push(tempData);
    });

    setTranslatedAnomalies(anomaliesData);
  }, []);

  //Filters the data based on the selected anomalies
  const selectedInput = (selectedList) => {
    const array =
      selectedList.length &&
      selectedList.map((elements) => {
        return elements.defect_type_id;
      });

    setSelectedAnomalies(array);
  };

  //Removes an anomaly from the selected list
  const removeInput = (selectedList) => {
    const array =
      selectedList.length &&
      selectedList.map((elements) => {
        return elements.defect_type_id;
      });

    setSelectedAnomalies(array);
  };

  //handle pdf orientation
  const handlePDF = (event) => {
    setPdfOrientation(event.target.value);
  };

  //select language
  const handleLanguage = (event) => {
    setLanguage(event.target.value);
  };

  //Create an information object for the selected plant and section
  if (plantList && plantList.length > 0) {
    plantList.map((item) => {
      return siteSelect.push({
        name: item.plant_name,
        value: item.plant_id,
        id: item.plant_id,
        inspeList: item.inspection_list
          .map((inspData) => {
            return {
              name: moment(inspData.inspection_date).format("D MMM, YYYY"),
              value: inspData.plant_inspection_id,
              is_digitized: inspData.is_digitized,
            };
          })
          .sort((a, b) => new Date(b.name) - new Date(a.name)),
      });
    });
  }

  const [inspDateList, setInspDateList] = useState(siteSelect[0]?.inspeList);
  const [selectedInspDate, setSelectedInspDate] = useState(
    siteSelect[0]?.inspeList[0]?.value
  );

  //Update section select dropdown
  if (selectedPlantId) {
    if (reportType.type !== "thermal_report") {
      sectionSelect.push({
        name: "All Sections",
        value: "all",
        id: "all",
      });
    }

    _.orderBy(
      plantList.filter((selected) => {
        return selected.plant_id === selectedPlantId;
      })[0]?.section_list,
      (item) => {
        const string = item.section_name.split(" ");
        return parseInt(string[string.length - 1], 10);
      },
      ["asc"]
    ).map((site) => {
      return sectionSelect.push({
        name: site.section_name,
        value: site.section_id,
        id: site.section_id,
      });
    });
  }

  //download handler for reports
  const downloadHandler = async () => {
    let selectedSection = "";
    if (selectedSectionId) {
      const getSelectedSection = sectionSelect.find(
        (section) => section.id === selectedSectionId
      );
      selectedSection = getSelectedSection;
    }

    const reportData = {
      plant_name: selectedPlantName,
      report_type: reportType.type,
      report_name:
        reportType.type === "executive_report"
          ? "Executive Report"
          : "Thermal Analysis Document",
      section_name: selectedSection ? selectedSection.name : "All",
      created_on: new Date().toJSON(),
    };

    //passing report details
    reportDetails(reportData);

    generateReport(true);
    hideDownloadPopup();
    const requestBody = {
      plant_id: selectedPlantId,
      plant_inspection_id: selectedInspDate,
      section_id: selectedSectionId === "all" ? null : selectedSectionId,
      // section_id: selectedSectionId,
      report_type: reportType.type,
      orientation: pdfOrientation,
      language,
      temperature_range: { start: tempRange[0], end: tempRange[1] },
      anomaly_type_list: selectedAnomalies,
      historical_data: historicalData,
    };

    try {
      const genericReport = await allRequestHandler({
        requestType: "POST",
        requestUrl: URLS.REPORT_API,
        requestData: requestBody,
      });
      //On generating report call history API
      getReportHistory();

      if (!genericReport) {
        generateReport(false);
        return;
      }

      // hideDownloadPopup();
      generateReport(false);
      if (genericReport && genericReport.download_url)
        window.open(genericReport.download_url);
      else callSnackbar("Report generated successfully...", "success");
    } catch (error) {
      // handle error
      generateReport(false);
    }
  };

  //Change the default section to "ALL"
  const setDefaultSectionId = () => {
    return "all";
  };

  //Handles the default selected section
  useEffect(() => {
    if (reportType.type === "thermal_report") {
      // setSelectedSectionId(setDefaultSectionId());
      setSelectedSectionId(sectionSelect[0].value);
    } else if (reportType.type !== "executive_report")
      setSelectedSectionId(setDefaultSectionId());
  }, [selectedPlantId]);

  //Handle site change
  const handlePlant = (event) => {
    const filterPlantName =
      siteSelect.length &&
      siteSelect.find((siteName) => {
        return siteName.value === event.target.value;
      });

    setInspDateList(filterPlantName.inspeList);
    setSelectedPlantName(filterPlantName.name);
    setSelectedPlantId(event.target.value);
    setSelectedInspDate(filterPlantName.inspeList[0].value);
  };

  //Handle the temperature range slider
  const handleSlider = (event, value) => {
    if (value && value.length) {
      value[0] = Number(value[0]);
      value[1] = Number(value[1]);
    }
    setTempRange(value);
  };

  function checkFirstInspection(dateArray) {
    if (dateArray.length === 0) {
      return null;
    }

    const oldestDateObject = dateArray.reduce((oldest, current) => {
      const oldestDate = new Date(oldest.name);
      const currentDate = new Date(current.name);

      return currentDate < oldestDate ? current : oldest;
    });

    return oldestDateObject;
  }

  function checkDigitizedStatus(selectedInspDate, inspDateList) {
    const inspData = inspDateList.find(
      (item) => item.value === selectedInspDate
    );
    return inspData?.is_digitized;
  }

  return (
    <Grid
      container
      className={classes.popupContainer}
      direction="column"
      style={{
        minWidth: 600,
      }}
    >
      <Grid container className={classes.popupItem} direction="column">
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          style={{ padding: "21px 24px 16px 32px" }}
        >
          <Typography className={classes.popUpTitle}>
            <Trans>{type.title}</Trans>
          </Typography>
          <CloseIcon
            fontSize="large"
            className={classes.closeIcon}
            onClick={hideDownloadPopup}
          />
        </Grid>
        <Divider />
        <Grid container style={{ padding: 0 }}>
          <Grid item xs={12} md={12} style={{ padding: "8px 24px" }}>
            <span style={{ display: "flex", flexDirection: "column" }}>
              <Typography className={classes.titleText}>
                <Trans>Site</Trans>
              </Typography>
              <CustomDropdown
                selectValue={selectedPlantId}
                onHandleSelectChange={(e) => {
                  return handlePlant(e);
                }}
                fullWidth
                menuItems={siteSelect}
              />
            </span>

            {reportType.is_section_wise && (
              <span className={classes.sections}>
                <Typography className={classes.titleText}>
                  <Trans>Sections</Trans>
                </Typography>
                <CustomDropdown
                  selectValue={selectedSectionId ? selectedSectionId : "all"}
                  // selectValue={
                  //   reportType.type === "thermal_report"
                  //     ? selectedSectionId
                  //     : "all"
                  // }
                  onHandleSelectChange={(e) => {
                    return setSelectedSectionId(e.target.value);
                  }}
                  fullWidth
                  menuItems={sectionSelect}
                  ignoreMenuLength={false}
                />
              </span>
            )}

            <Grid container direction="column">
              <Typography className={classes.titleText}>
                <Trans>Inspection Date</Trans>
              </Typography>
              <CustomDropdown
                selectValue={selectedInspDate}
                onHandleSelectChange={(e) => {
                  setSelectedInspDate(e.target.value);
                  setHistoricalData(false);
                }}
                fullWidth
                menuItems={inspDateList}
              />
            </Grid>

            {/* PDF Orientation and Language */}
            {reportType.format === "pdf" ? (
              <>
                <Grid
                  container
                  className={classes.flexRow}
                  direction="row"
                  justifyContent="flex-start"
                >
                  <Typography
                    className={classes.titleText}
                    style={{ width: "100%" }}
                  >
                    <Trans>Anomaly Name</Trans>
                  </Typography>
                  <span className={classes.formOuterDiv} style={{ width: 550 }}>
                    <FormControl
                      className={[
                        classes.inputLabel,
                        classes.formInnerDiv,
                      ].join(" ")}
                    >
                      <Multiselect
                        // ref={anomalyRef}
                        options={translatedAnomalies}
                        style={{
                          searchBox: {
                            minHeight: "36px",
                            height: "auto",
                            padding: "3px",
                            float: "none",
                            width: "550px",
                          },
                          chips: {
                            width: "auto",
                            backgroundColor: "#5B4DD3",
                          },
                          inputField: {
                            float: "none",
                          },
                        }}
                        displayValue="defect_name"
                        onSelect={selectedInput}
                        onRemove={removeInput}
                      />
                    </FormControl>
                  </span>
                </Grid>

                <TemperatureRange
                  tempRange={tempRange}
                  onHandleSlider={handleSlider}
                  showInstruction={false}
                  chartFilter={false}
                />
                <Grid container direction="column">
                  <RadioGroup
                    defaultValue={pdfOrientation}
                    value={pdfOrientation}
                    onChange={handlePDF}
                  >
                    <Grid container direction="column">
                      <Typography className={classes.titleText}>
                        <Trans>PDF Orientation</Trans>
                      </Typography>
                      <Grid container>
                        <Grid style={{ width: "120px" }} container>
                          <Grid style={{ width: "20px" }}>
                            <FormControlLabel
                              value="portrait"
                              control={<Radio style={{ color: "#7363FB" }} />}
                              label=""
                            />
                          </Grid>
                          <Grid container className={classes.portrait}>
                            <img alt="porImg" src={portrait} />
                            <Typography className={classes.portraitTxt}>
                              <Trans>Portrait</Trans>
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid className={classes.landscapeTxtCont} container>
                          <Grid style={{ width: "25px" }}>
                            <FormControlLabel
                              value="landscape"
                              control={<Radio style={{ color: "#7363FB" }} />}
                              label=""
                            />
                          </Grid>
                          <Grid container className={classes.portrait}>
                            <img
                              alt="porImg"
                              src={landscape}
                              className={classes.landImg}
                            />
                            <Grid style={{ margin: "2px 2px" }}>
                              <Trans>Landscape</Trans>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </RadioGroup>

                  <RadioGroup
                    defaultValue={language}
                    value={language}
                    onChange={handleLanguage}
                  >
                    <Grid container>
                      <Typography className={classes.titleText}>
                        <Trans>Language</Trans>
                      </Typography>
                      <Grid container alignItems="center">
                        <FormControlLabel
                          value="english"
                          control={<Radio style={{ color: "#7363FB" }} />}
                          label=""
                        />
                        <Typography className={classes.langText}>
                          <Trans>English</Trans>
                        </Typography>
                        <FormControlLabel
                          value="spanish"
                          control={<Radio style={{ color: "#7363FB" }} />}
                          label=""
                        />
                        <Typography className={classes.langText}>
                          <Trans>Spanish</Trans>
                        </Typography>
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </Grid>
              </>
            ) : (
              ""
            )}
          </Grid>
        </Grid>

        {/* Historical Data Checkbox */}
        {type.title.includes("CSV") &&
          inspDateList?.length > 1 &&
          selectedInspDate !== checkFirstInspection(inspDateList).value && (
            <div className={classes.historicalCheckboxWrapper}>
              {
                <Checkbox
                  disabled={
                    checkDigitizedStatus(selectedInspDate, inspDateList)
                      ? false
                      : true
                  }
                  checked={historicalData}
                  onChange={() => setHistoricalData(!historicalData)}
                  style={{
                    color: historicalData ? "#9C91FC" : "",
                    marginLeft: "-10px",
                  }}
                />
              }
              <div
                className={[
                  classes.inputLabel,
                  classes.historicalDataText,
                ].join(" ")}
              >
                Include historical data
              </div>{" "}
              <CustomTooltip
                title={
                  checkDigitizedStatus(selectedInspDate, inspDateList)
                    ? "Generated report will contain data from older inspections."
                    : "The historical data is unavailable for inspection as it has not been digitised."
                }
                placement="bottom"
                arrow
              >
                <img src={InfoIcon} alt="" />
              </CustomTooltip>
            </div>
          )}

        <Divider />
        <Grid
          container
          justifyContent="flex-end"
          style={{
            padding: 24,
          }}
        >
          <CustomButton
            variant="outlined"
            type={"cancel"}
            text={<Trans>Cancel</Trans>}
            onClick={hideDownloadPopup}
          />

          {/* for warranty claim report */}
          {reportType.type === "warranty_claim_report" ? (
            product?.remaining_days || product?.product_type === "Pro" ? (
              <CustomButton
                text={
                  isDownloading ? (
                    `${i18n.t("downloading")}...`
                  ) : (
                    <Trans>Generate & Download</Trans>
                  )
                }
                onClick={downloadHandler}
                disabled={isDownloading}
                id={`${loggedInUser?.user_profile?.client_name}-${type.title}-${selectedPlantName}-Report-pageDownload`}
              />
            ) : (
              <ProButton
                onHandleShowPlans={() => {
                  return handleShowPlans(true);
                }}
              />
            )
          ) : (
            <CustomButton
              text={
                isDownloading ? (
                  `${i18n.t("downloading")}...`
                ) : (
                  <Trans>Generate & Download</Trans>
                )
              }
              onClick={downloadHandler}
              disabled={isDownloading}
              id={`${loggedInUser?.user_profile?.client_name}-${type.title}-${selectedPlantName}-Report-pageDownload`}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

//Calling redux to get information about the product (basic/pro) and the selected language
const mapStateToProps = (state) => {
  return {
    product: state.product,
    language: state.language.selectedLanguage,
  };
};
export default connect(mapStateToProps)(withStyles(styles)(DownloadPopup));