import { Grid } from "@material-ui/core";
import React from "react";
import falsepos from "../../../assets/img/falsepos.svg";
import inProgress from "../../../assets/img/statusImages/inProgress.svg";
import notFound from "../../../assets/img/statusImages/notFound.svg";
import pending from "../../../assets/img/statusImages/pending.svg";
import resizedImageUrl from "../../../utils/imageResizing";
import resolved from "../../../assets/img/statusImages/resolved.svg";
import styles from "./hoverPopupStyle";
import { withStyles } from "@material-ui/styles";

//To show popup on hover of markers or polygons
const HoverPopupWithImg = (props) => {
  const { classes } = props;

  return (
    props.panelData && (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "nowrap",
        }}
      >
        <Grid
          style={{
            width: "200px",
            marginBottom: "12px",
          }}
        >
          <img
            alt="urlImg"
            style={{
              borderTopLeftRadius: "7px",
              borderTopRightRadius: "7px",
              width: "200px",
            }}
            src={resizedImageUrl(props.panelData.thermal_image_url)}
          />
        </Grid>
        <Grid
          style={{ flexWrap: "nowrap", marginTop: "-6px" }}
          container
          rowspacing={2}
        >
          <Grid
            style={{ backgroundColor: props.panelData.color_code }}
            className={classes.line}
          />
          <Grid>
            <Grid container rowspacing={1}>
              <Grid className={classes.defcetNo}>
                #{props.panelData.defect_no}
              </Grid>
              <Grid container className={classes.dot} />
              <Grid item className={classes.defcetNo}>
                {props.panelData.module_coordinates === "null"
                  ? "-"
                  : props.panelData.module_coordinates}
              </Grid>
              <Grid container className={classes.dot} />
              <Grid
                style={{ marginRight: "40px" }}
                item
                className={classes.defcetNo}
              >
                {props.label
                  ? props.label.charAt(0).toUpperCase() +
                    props.label.slice(1).toLowerCase()
                  : "-"}
              </Grid>
            </Grid>

            {props.panelData.stringReference && (
              <Grid item className={classes.defcetNo}>
                {props.panelData.stringReference}
              </Grid>
            )}

            <Grid className={classes.defectName}>
              {props.panelData.defect_type_name}
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              className={classes.statusOuterBox}
            >
              <Grid className={classes.statusImg}>
                {props.panelData.status ? (
                  <Grid>
                    <img
                      alt="statusImg"
                      src={
                        props.panelData.status === "pending"
                          ? pending
                          : props.panelData.status === "resolved"
                          ? resolved
                          : props.panelData.status === "inprogress"
                          ? inProgress
                          : props.panelData.status === "notfound"
                          ? notFound
                          : props.panelData.status === "falsepositive"
                          ? falsepos
                          : "-"
                      }
                    />
                  </Grid>
                ) : (
                  "-"
                )}
              </Grid>
              <Grid className={classes.status}>
                {props.panelData.status === "pending"
                  ? "Pending"
                  : props.panelData.status === "resolved"
                  ? "Resolved"
                  : props.panelData.status === "inprogress"
                  ? "In Progress"
                  : props.panelData.status === "notfound"
                  ? "Not Found"
                  : props.panelData.status === "falsepositive"
                  ? "False +ve"
                  : "-"}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  );
};

export default withStyles(styles)(HoverPopupWithImg);
