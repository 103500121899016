export default {
  drawerRoot: {
    width: 500,
  },
  marT0: {
    marginTop: 0,
    height: "100%",
    minWidth: "220px",
    backgroundColor: "#FFFFFF",
  },
  pro_image: {
    position: "relative",
    top: "0px",
    left: "100px",
    width: "22px",
  },
  iconAlign: {
    position: "absolute",
    zIndex: 1,
    top: 10,
    left: 4,
    outline: "unset !important",
  },
  linkStyles: {
    fontFamily: "Montserrat !important",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "24px",
    display: "flex",
    alignItems: "center",
    color: "#545B65",
  },
  linksRoot: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignSelf: "center",
    width: "196px",
  },
  listItemRoot: {
    width: "196px !important",
    height: "48px",
  },
  linkActive: {
    background: "#5B4DD3",
    borderRadius: "8px",
    "& span": {
      color: "#fff",
    },
    "& path": {
      fill: "#fff",
    },
  },
  bottomLogo: {
    marginTop: "10px",
    marginLeft: "30px",
    position: "absolute",
    bottom: 0,
  },
  // initial: {
  //   height: "40px",
  //   width: "40px",
  //   alignItems: "center",
  //   backgroundImage: "linear-gradient(180deg, #412558 0.19%, #390F5E 80.46%)",
  //   borderRadius: "8px",
  //   margin: 12,
  // },
  clientinitial: {
    marginTop: 17,
    marginBottom: 25,
    border: "1px solid grey",
    borderRadius: "8px",
    backgroundColor: "white",
    color: "#545B65",
    fontSize: "12px",
    fontWeight: "bold",
    width: "16px",
    height: "15px",
    top: "50%",
    left: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "40px",
    minHeight: "40px",
  },
  clientinitialHumberger: {
    marginLeft: "15px",
    marginTop: 17,
    marginBottom: 25,
    border: "1px solid grey",
    borderRadius: "8px",
    backgroundColor: "white",
    color: "#545B65",
    fontSize: "12px",
    fontWeight: "bold",
    width: "16px",
    height: "15px",
    top: "50%",
    left: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "40px",
    minHeight: "40px",
  },
  proLabel: {
    color: "#C5C9D0",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "10px",
    lineHeight: "20px",
    margin: "5px 0 10px 0",
    marginLeft: "15px",
  },
  typoclientname: {
    display: "flex",
    alignItems: "center",
    marginLeft: "-20px",
  },
  airprobeLogo: {
    height: 28,
    marginTop: 7,
    paddingBottom: 5,
    marginLeft: "15px",
    width: "100px",
    objectFit: "contain",
  },
  poweredByAirprobe: {
    color: "#B3A0BA",
    fontSize: 12,
    fontFamily: "Montserrat",
    letterSpacing: "normal",
    fontWeight: "500",
  },
  DeskView: {
    display: "block",
    height: "100vh",
    "@media (max-width: 700px)": {
      display: "none",
    },
  },
  MobileView: {
    display: "none",
    height: "100vh",
    "@media (max-width: 700px)": {
      display: "block",
    },
  },
  outerMenu: {
    padding: "0px 16px 16px 16px",
    marginLeft: "230px",
    "@media only screen and (max-width: 780px)": {
      marginLeft: "198px",
    },
    "@media only screen and (max-width: 450px)": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginLeft: "7rem",
      height: "220px",
      width: "220px",
    },
    "@media only screen and (max-width: 400px)": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginLeft: "5rem",
      height: "220px",
      width: "220px",
    },
    "@media only screen and (max-width: 350px)": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginLeft: "2.5rem",
      height: "220px",
      width: "220px",
    },
  },
  outerMenuHeading: {
    color: "#536471",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "20px",
  },
};
