import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Table,
  TableBody,
  Typography,
  withStyles,
} from "@material-ui/core";

import CustomClear from "../customClear";
import CustomFilterChip from "../customStyle/FilterChip";
import CustomTableCell from "./customTableCell";
import DonutChart from "../charts/donutchart";
import EmptyCard from "../emptychart";
import ExportVectorImg from "../../../components/assets/img/Vector.svg";
import Filter from "../filters/filters";
import FilterImg from "../../../assets/img/filter2.svg";
import InfoIcon from "@material-ui/icons/Info";
import NewLoader from "../../../layout/newLoader";
import OutsideClickHandler from "react-outside-click-handler";
import ProButton from "../../product/ProButton";
import ProLabel from "../ProLabel";
import React from "react";
import { Trans } from "react-i18next";
import { allRequestHandler } from "../../../api";
import config from "../../../config";
import { connect } from "react-redux";
import shortid from "shortid";
import styles from "./AnomaliesChartCard.style";

const requestUrl = config.hostName;

//Anomalies card
class AnomaliesChartCard extends React.Component {
  state = {
    anomalies_metrics: null,
    filterAnomaliesChips: [],
    showFilter: false,
    showTooltip: false,
    isFiltering: false,
    open: false,
  };

  //Toggle to display tooltip
  displayTooltip = () => {
    this.setState((prevState) => {
      return {
        showTooltip: !prevState.showTooltip,
      };
    });
  };

  //Handles the filters on the Anomalies chart
  applyAnomaliesFilter = async (
    defect_types,
    inspection_type,
    module_type,
    severity_types,
    status_types,
    iec_category,
    temperature_range,
    anomaly_has,
    site,
    action
  ) => {
    const data = {
      anomaly_has,
      inspection_type,
      module_type,
      severity_types,
      status_types,
      iec_category,
      temperature_range,
    };
    let res = "";
    if (!action) {
      res = await allRequestHandler({
        requestType: "POST",
        requestData: data,
        // skipcq Avoid using this.state inside this.setState() JS-0435
        requestUrl: requestUrl + this.props.anomalies_metrics_href,
      });
    }
    if (res && res.anomalies_list.length === 0)
      this.setState({ showSnackbar: true, anomalies_metrics: res });
    else if (action) {
      const res = await allRequestHandler({
        requestType: "GET",
        requestData: data,
        // skipcq Avoid using this.state inside this.setState() JS-0435
        requestUrl: requestUrl + this.props.anomalies_metrics_href,
      });
      this.setState({ anomalies_metrics: res });
    } else this.setState({ anomalies_metrics: res });
  };

  //Toggle to display popup
  displaypopup = () => {
    this.setState((prevState) => {
      return {
        open: !prevState.open,
      };
    });
  };

  //Handles clear all filters
  handleClearFilter = async (action) => {
    this.props.compName === "dashboard"
      ? this.setState({
          filterAnomaliesChips: [],
          showFilter: false,
          isFiltering: true,
        })
      : this.props.selectedFilterChips([]);
    await this.applyAnomaliesFilter(
      [],
      "All",
      [],
      ["All"],
      ["All"],
      [],
      { start: 0, end: 100 },
      ["All"],
      "",
      action
    );
    this.setState({ isFiltering: false });
  };

  //Handles clear selected filters (remove filter chips)
  handleDeleteChip = async (data, action) => {
    // skipcq Avoid using this.state inside this.setState() JS-0435
    const filterChips = this.state.filterAnomaliesChips.filter((item) => {
      return item.name !== data.name;
    });

    if (filterChips.length === 0) {
      return this.handleClearFilter(action);
    }

    this.setState({
      filterAnomaliesChips: filterChips,
    });
    data.delete(data.name);
    return;
  };

  handleDeleteChipsInSites = (data, action) => {
    if (this.props.selectedChips.length === 1) {
      return this.handleClearFilter(action);
    }
    this.props.selectedFilterChips(
      this.props.selectedChips.filter((item) => {
        return item.name !== data.name;
      })
    );
    data.delete(data.name);
  };

  //Renders anomalies chart card
  render() {
    const { classes } = this.props;
    const { cardHeight } = this.props;
    const TotalAnomalies = (totalAnomalies) => {
      if (totalAnomalies) return this.state.anomalies_metrics.total_anomalies;
      else return this.props.anomalies_metrics.total_anomalies;
    };
    let filterAnomalyTableData = "";
    if (this.state.anomalies_metrics) {
      filterAnomalyTableData =
        this.state.anomalies_metrics &&
        this.state.anomalies_metrics.anomalies_list;
    } else {
      filterAnomalyTableData =
        this.props.anomalies_metrics &&
        this.props.anomalies_metrics.anomalies_list;
    }

    return (
      <Card style={{ height: cardHeight }}>
        <CardContent style={styles.cardHeading}>
          <Grid container direction="column" className={classes.donutChartBox}>
            <Grid container>
              <Grid
                item
                xs
                style={{
                  minWidth: this.state.filterAnomaliesChips.length
                    ? 200
                    : "auto",
                }}
              >
                <Typography style={styles.chartHeading}>
                  <Trans>Anomalies</Trans>
                </Typography>
              </Grid>
              <Grid item id="anomaliesChart" className={classes.inlineTitle}>
                {this.state.filterAnomaliesChips.length > 0 ||
                this.props.selectedChips?.length > 0 ? (
                  <CustomClear
                    classes={classes}
                    Filter={() => this.handleClearFilter(true)}
                  />
                ) : null}
                <Button
                  onClick={() => {
                    return this.setState((prevState) => {
                      return {
                        showFilter: !prevState.showFilter,
                      };
                    });
                  }}
                  className={classes.filterButton}
                  data-intercom-target="anomalies-chart-filter"
                >
                  <Trans>Filters</Trans>
                  {this.state.filterAnomaliesChips.length > 0 ||
                  this.props.selectedChips?.length > 0 ? (
                    <span className={classes.filterCount}>
                      {this.props.compName === "dashboard"
                        ? this.state.filterAnomaliesChips.length
                        : this.props.selectedChips.length}
                    </span>
                  ) : (
                    <img
                      style={{ marginLeft: "8px" }}
                      src={FilterImg}
                      alt="filterImg"
                    />
                  )}
                </Button>
                <div style={{ position: "relative" }}>
                  {!this.props.product?.remaining_days &&
                    this.props.product?.product_type != "Pro" && (
                      <Button
                        variant="outlined"
                        width={20}
                        className={classes.downBtn}
                        onClick={() => {
                          return this.displaypopup();
                        }}
                      >
                        <img
                          src={ExportVectorImg}
                          alt=""
                          className={classes.vectorImg}
                        />
                      </Button>
                    )}
                  <div className={classes.proLabel}>
                    <ProLabel marginRight="0" marginLeft="5px" show />
                  </div>
                </div>
                {this.state.showTooltip && (
                  <Grid
                    container
                    md={6}
                    className={
                      this.state.filterAnomaliesChips.length
                        ? classes.anomaliesTooltipb
                        : classes.anomaliesTooltip
                    }
                    alignContent="center"
                  >
                    <Table>
                      <TableBody>
                        {this.props.anomalies_metrics?.anomalies_list.map(
                          (obj) => {
                            return (
                              <CustomTableCell
                                obj={obj}
                                classes={classes}
                                styles={styles}
                                key={shortid.generate()}
                              />
                            );
                          }
                        )}
                      </TableBody>
                    </Table>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Divider />
            <Grid container justifyContent="flex-end">
              <InfoIcon
                onClick={() => {
                  return this.displayTooltip();
                }}
                className={classes.infoIconStyle}
              />
            </Grid>

            {this.props.compName === "dashboard"
              ? this.state.filterAnomaliesChips.length > 0 && (
                  <Grid
                    container
                    alignItems="center"
                    className={classes.chipSec}
                  >
                    <div className={classes.Wrap}>
                      {this.state.filterAnomaliesChips.map((data) => {
                        return data.type === "temperature_range" &&
                          data.value[0] === 0 &&
                          data.value[1] === 100 ? null : (
                          <CustomFilterChip
                            onDelete={() => this.handleDeleteChip(data, true)}
                            type={data.type}
                            name={data.name}
                            lowerTempValue={data.value && data.value[0]}
                            higherTempValue={data.value && data.value[1]}
                          />
                        );
                      })}
                    </div>
                  </Grid>
                )
              : this.props.selectedChips?.length > 0 && (
                  <Grid
                    container
                    alignItems="center"
                    className={classes.chipSec}
                  >
                    <div className={classes.Wrap}>
                      {this.props.selectedChips.map((data) => {
                        return data.type === "temperature_range" &&
                          data.value[0] === 0 &&
                          data.value[1] === 100 ? null : (
                          <CustomFilterChip
                            onDelete={() =>
                              this.handleDeleteChipsInSites(data, true)
                            }
                            type={data.type}
                            name={data.name}
                            lowerTempValue={data.value && data.value[0]}
                            higherTempValue={data.value && data.value[1]}
                          />
                        );
                      })}
                    </div>
                  </Grid>
                )}

            <Grid
              item
              xs
              container
              justifyContent="center"
              alignItems="center"
              style={{
                filter: this.state.open ? "blur(20px)" : "blur(0px)",
              }}
            >
              {this.state.isFiltering || !this.props.anomalies_metrics ? (
                <Grid container justifyContent="center" alignItems="center">
                  <NewLoader />
                </Grid>
              ) : (
                <>
                  <Grid item md={6}>
                    <div style={{ position: "relative" }}>
                      {" "}
                      {this.props.anomalies_metrics ? (
                        <div
                          className={
                            this.props.compName === "dashboard"
                              ? classes.anomaliesChartBox
                              : classes.siteChardBox
                          }
                        >
                          <DonutChart
                            moduleDefectChart={
                              this.state.anomalies_metrics
                                ? this.state.anomalies_metrics
                                : this.props.anomalies_metrics
                            }
                            chartHeight={this.props.chartHeight}
                          />
                        </div>
                      ) : null}
                      {this.props.anomalies_metrics?.total_anomalies !== 0 && (
                        <div className={classes.insideOuter}>
                          <div className={classes.insideCount}>
                            {TotalAnomalies(
                              this.state.anomalies_metrics?.total_anomalies
                            )}
                          </div>{" "}
                          <div className={classes.insideText}>
                            <Trans>Anomalies</Trans>
                          </div>{" "}
                        </div>
                      )}
                    </div>
                  </Grid>

                  <Grid item md={6} className={classes.anomaliesTable}>
                    <Table>
                      <TableBody>
                        {filterAnomalyTableData
                          ? filterAnomalyTableData.map((obj) => {
                              return (
                                <CustomTableCell
                                  obj={obj}
                                  classes={classes}
                                  styles={styles}
                                  key={shortid.generate()}
                                />
                              );
                            })
                          : null}
                      </TableBody>
                    </Table>
                  </Grid>
                </>
              )}
            </Grid>

            {this.state.open && (
              <div className={classes.showPlansPopup}>
                <ProButton onHandleShowPlans={this.props.onHandleShowPlans} />
                <Button
                  className={classes.cancelBtn2}
                  variant="outlined"
                  onClick={() => {
                    return this.displaypopup();
                  }}
                >
                  Cancel
                </Button>
              </div>
            )}
            <OutsideClickHandler
              onOutsideClick={() => {
                return this.setState({
                  showFilter: false,
                });
              }}
            >
              {this.props.anomalies_metrics && this.state.showFilter ? (
                <div
                  className={
                    this.state.filterAnomaliesChips.length
                      ? classes.filterOuterMin
                      : classes.filterOuter
                  }
                >
                  <Filter
                    filters={this.props.anomalies_metrics_filter_options}
                    compName={this.props.compName}
                    filterChips={
                      this.props.compName === "dashboard"
                        ? this.state.filterAnomaliesChips
                        : this.props.selectedChips
                        ? this.props.selectedChips
                        : []
                    }
                    applyFilter={async (
                      defType,
                      inspType,
                      moduleType,
                      sev,
                      status,
                      iec_category,
                      siteType,
                      temperature_range,
                      anomaly_has
                    ) => {
                      this.setState({ isFiltering: true });
                      await this.applyAnomaliesFilter(
                        defType,
                        inspType,
                        moduleType,
                        sev,
                        status,
                        iec_category,
                        temperature_range,
                        anomaly_has,
                        siteType
                      );
                      this.setState({ isFiltering: false });
                    }}
                    closeFilter={() => {
                      return this.setState({ showFilter: false });
                    }}
                    onClearFilter={() => this.handleClearFilter(true)}
                    addFilterChips={(filterAnomaliesChips) => {
                      this.props.compName === "dashboard"
                        ? this.setState({ filterAnomaliesChips })
                        : this.props.selectedFilterChips(filterAnomaliesChips);
                    }}
                  />
                </div>
              ) : (
                ""
              )}
            </OutsideClickHandler>
            {this.state.anomalies_metrics?.total_anomalies === 0 && (
              <EmptyCard compName="anomalies" />
            )}
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

//Calling redux to get information about the product (basic/pro) and the selected language
const mapStateToProps = (state) => {
  return {
    product: state.product,
    language: state.language.selectedLanguage,
  };
};
export default connect(mapStateToProps)(withStyles(styles)(AnomaliesChartCard));
