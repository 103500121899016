import _ from "lodash";

// To extract the non-numeric part of the section name
export const getSectionNamePart = (sectionName) => {
  const parts = sectionName.split(/[-\s]/);
  if (parts.length >= 2 && !Number.isNaN(Number(parts[parts.length - 1]))) {
    return parts.slice(0, -1).join("-").toLowerCase();
  }
  return sectionName.toLowerCase();
};

// To extract the numeric part (if any) from the section name
export const getSectionNumberPart = (sectionName) => {
  const parts = sectionName.split(/[-\s]/);
  const lastPart = parts[parts.length - 1];
  return Number.isNaN(Number(lastPart)) ? 0 : Number.parseInt(lastPart, 10);
};

// To order a section list by name and number
export const orderSectionList = (sectionList) => {
  return _.orderBy(
    sectionList,
    [
      (item) => getSectionNamePart(item.section_name),
      (item) => getSectionNumberPart(item.section_name),
    ],
    ["asc", "asc"]
  );
};
