const styles = {
  header: {
    width: "100%",
    backgroundColor: "white",
    height: 72,
    padding: "0 24px 0 24px",
    boxShadow: "unset",
    borderBottom: "1px solid #E4EAF5",
  },
  title: {
    fontFamily: "Montserrat",
    fontSize: 20,
    fontWeight: 700,
    marginTop: "3px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.2,
    color: "#21282d",
  },
  subtitle: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 14,
    textTransform: "uppercase",
    color: "#536471",
    padding: "24px 0px 8px 24px",
  },
  downloadSection: {
    width: "100%",
  },
  gridgap: {
    flexBasis: "calc(25% - 24px)",
    marginLeft: "24px",
    marginBottom: "24px",
  },
  swiperSection: {
    width: "100%",
    flexWrap: "nowrap",
    overflow: "scroll",
  },
  swiper: {
    width: 300,
    height: 120,
    minWidth: 300,
    marginLeft: "0",
    marginRight: "0",
    minHeight: 120,
    background: "#FFFFFF",
    borderRadius: "8px",
    border: "1px solid #DDE5EC",
    padding: "33px 24px",
    flexWrap: "nowrap",
    cursor: "pointer",
    gridColGap: "16px",
  },
  swiperImage: {
    paddingRight: 16,
    width: "40px",
  },
  swiperTitle: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "20px",
    color: "#08001D",
  },
  swiperText: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "11px",
    lineHeight: "16px",
    color: "#536471",
  },
  warrantyProLabel: {
    position: "relative",
    bottom: "43px",
    right: "5%",
    "@media only screen and (max-width: 960px)": {
      right: "57%",
    },
  },
  reportHistory: {
    width: "100%",
    padding: "0 24px",
    paddingBottom: "85px",
  },
  reportTable: {
    background: "white",
    width: "100%",
    borderRadius: "8px",
    boxShadow: "0px 4px 12px rgba(78, 89, 109, 0.05)",
    marginRight: "24px",
  },
  searchBar: {
    width: 340,
    height: "36px",
    background: "#FFFFFF",
    border: "1px solid #DDE5EC",
    borderRadius: "8px",
    display: "flex",
    alignContent: "center",
    justifyContent: "flex-start",
  },
  iconButton: {
    margin: "0 10px",
    width: "16px",
    height: "16px",
    color: "#A8B9C6",
    alignSelf: "center",
  },
  input: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "20px",
    border: "none",
    width: "83%",
  },
  button: {
    background: "linear-gradient(266.69deg, #59AFFF 0.23%, #0882F3 99.28%)",
    fontFamily: "Montserrat",
    color: "#FFFFFF",
    borderRadius: "8px",
  },
  tableHeader: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    color: "#536471",
    whiteSpace: "nowrap",
  },
  table: {
    padding: "0 24px",
  },
  tableItem: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "20px",
    color: "#08001D",
    whiteSpace: "nowrap",
  },
  titleText: {
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
    fontFamily: "Montserrat",
    fontWeight: "550",
    fontSize: "12px",
    lineHeight: "20px",
    color: "#536471",
    wordWrap: "break-word",
    width: "fit-content",
    marginLeft: 6,
  },
  titleTextForYield: {
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
    fontFamily: "Montserrat",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "20px",
    color: "#536471",
    wordWrap: "break-word",
    width: "fit-content",
    height: "20px",
    marginLeft: 6,
  },
  selectLink: {
    marginTop: 0,
    color: "#5B4DD3",
    cursor: "pointer",
  },
  customContainer: {
    padding: "0 24px",
    width: "100%",
    marginBottom: "50px",
  },
  customReportSection: {
    width: "100%",
    background: "white",
    borderRadius: 8,
    boxShadow: "0px 4px 12px rgba(78, 89, 109, 0.05)",
  },
  customInput: {
    width: "100%",
    border: "1px solid #DDE5EC",
    padding: "5px 20px",
    marginBottom: 25,
  },
  renameEditText: {
    border: "1px solid #DDE5EC",
    borderRadius: "8px",
    padding: "5px 20px",
  },
  customInputForYield: {
    width: "200px",
    height: "32px",
    background: "#FFFFFF",
    border: "1px solid #DDE5EC",
    borderRadius: "8px",
    padding: "5px 20px",
    marginTop: "4px",
  },
  currencyContainer: {
    width: "24px",
    height: "32px",
    margin: "0px -6px -3px 0px",
    backgroundColor: "#F0F4FA",
    border: "1px solid #DDE5EC",
    borderTopLeftRadius: "8px",
    borderBottomLeftRadius: "8px",
    borderTopRightRadius: "0px",
    borderBottomRightRadius: "0px",
    borderRightStyle: "none",
  },
  customInputForFeed: {
    width: "175px",
    height: "32px",
    background: "#FFFFFF",
    border: "1px solid #DDE5EC",
    borderRadius: "8px",
    padding: "5px 20px",
    marginTop: "4px",
    borderTopLeftRadius: "0px",
    borderBottomLeftRadius: "0px",
    borderLeftStyle: "none",
  },
  inputLabel: {
    width: "100%",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "20px",
  },
  inputLabelForYield: {
    width: "100%",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "20px",
    marginTop: "-35px",
  },
  editSection: {
    borderBottom: "1px solid #E4EAF5",
    padding: "24px 32px",
  },
  metricsSubSection: {
    marginRight: 70,
  },
  deleteBtn: {
    height: "36px",
    width: "36px",
    minWidth: "36px",
    background: "#F0F4FA",
    borderRadius: "8px",
  },
  buttonOutlined: {
    borderRadius: "8px",
    padding: 7,
    background: "#FFFFFF",
    border: "1px solid #DDE5EC",
    minWidth: 100,
  },
  menuOptions: {
    minHeight: 120,
    background: "#FFFFFF",
    border: "1px solid #DDE5EC",
    boxShadow: "0px 4px 12px rgba(78, 89, 109, 0.05)",
    borderRadius: 8,
    position: "absolute",
    right: 140,
    top: "-40px",
    minWidth: 134,
    padding: "10px 0px",
    zIndex: 100,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  menuOptionsForYield: {
    background: "#FFFFFF",
    border: "1px solid #DDE5EC",
    boxShadow: "0px 4px 12px rgba(78, 89, 109, 0.05)",
    borderRadius: 8,
    position: "absolute",
    padding: "6px 0px 6px 0px",
    zIndex: 100,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginLeft: "-190px",
  },
  menuDot: {
    cursor: "pointer",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  menuButton: {
    width: "80%",
    border: "none",
    outline: "none",
    margin: 0,
    paddingLeft: "10px",
    justifyContent: "left",
    "&:focus": {
      outline: "none",
      background: "#F0F4FA",
    },
  },
  menuButtonForYield: {
    border: "none",
    outline: "none",
    margin: 0,
    textAlign: "center",
  },
  popupContainer: {
    broder: "solid 5px green",
    position: "fixed",
    top: 0,
    left: 0,
    height: "100vh",
    width: "100vw",
    background: "rgba(0,0,0,0.5)",
    zIndex: 1000,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  popupItem: {
    background: "white",
    borderRadius: "8px",
    maxWidth: "600px",
    Height: "310px",
  },
  paddAlign: {
    padding: "15px 10px 10px 30px",
  },
  popUpTitle: {
    fontSize: "14px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "24px",
    letterSpacing: "normal",
    color: "#536471",
    fontFamily: "Montserrat",
    fontWeight: "600",
    textTransform: "uppercase",
  },
  closeIcon: {
    cursor: "pointer",
    color: "#536471",
  },
  reviewButton: {
    background: "#5B4DD3",
    borderRadius: "8px",
    MaxWidth: "157px",
    height: "36px",
    color: "white",
  },
  progressIcon: {
    color: "progressIcon",
  },
  anomalyDetailsSection: {
    display: "flex",
    justifyContent: "space-between",
    "@media only screen and (max-width: 760px)": {
      "flex-direction": "column",
    },
  },
  btnImg: {
    height: 12,
    width: 13,
    marginTop: 0,
    marginLeft: 4,
    marginRight: 4,
  },
  targetDiv: {
    display: "inline",
  },
  tableRow: {
    "&:last-child:last-child > .targetCell > .targetDiv > .targetMenu": {
      top: "-45px",
    },
  },
  reportMod: {
    marginLeft: "-4px",
    marginRight: "-5px",
  },
  btnLabel: {
    pointerEvents: "none",
  },
  marRight: {
    marginRight: "32px",
  },
  langText: {
    marginRight: 20,
    marginLeft: "-20px",
  },
  portrait: {
    width: "90px",
    marginTop: "8px",
  },
  portraitCont: {
    margin: "2px 12px",
    marginTop: "-24px",
  },
  InProgressTxt: {
    width: "71px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "11px",
    lineHeight: "16px",
    color: "#7363FB",
  },
  InProgressLoader: {
    marginRight: "-30px",
    display: "flex",
    gap: "10px",
    alignItems: "center",
  },
  reportTableCont: {
    padding: 24,
    borderBottom: "1px solid #DDE5EC",
  },
  prolabelCont: {
    display: "flex",
    alignItems: "center",
  },
  spinContainer: {
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loader: {
    height: "calc(100vh - 300px)",
    marginLeft: "300px",
  },
  filteredReport: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
  },
  innerArrImg: {
    height: "25px",
    width: "25px",
    borderRadius: "5px",
    cursor: "pointer",
    margin: "2px",
  },
  siteDropDetails: {
    display: "flex",
    flexDirection: "column",
    width: 386,
  },
  checkboxTitle: {
    paddingBottom: 10,
    color: "#536471",
  },
  sectionDropDetails: {
    display: "flex",
    flexDirection: "column",
    width: 214,
    marginLeft: 24,
  },
  FilterTxt: {
    padding: 0,
    paddingBottom: "30px",
    color: "#536471",
  },
  formOuterDiv: {
    display: "flex",
    flexDirection: "row",
  },
  formInnerDiv: {
    width: "610px",
    float: "none",
  },
  tempOuter: {
    width: "620px",
    float: "none",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  dltBtnbox: {
    paddingTop: "15px",
    margin: "0 0px",
    marginTop: "28px",
    width: "50px",
  },
  downloadBtnOuter: {
    padding: 15,
    borderBottom: "1px solid #DDE5EC",
  },
  tableCellOuter: {
    background: "#FBFCFF",
    whiteSpace: "nowrap",
  },
  anomalyList: {
    display: "flex",
    justifyContent: "center",
    padding: "20px 0",
  },
  flexWrap: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  inProgTxt: {
    color: "#5B4DD3",
    marginTop: 0,
  },
  dltTitleContainer: {
    padding: "10px 30px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
  },
  dltPopupInner: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  portraitTxt: {
    width: "80px",
    margin: "0 16px",
    marginTop: "-22px",
  },
  sections: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "1px",
  },
  landscapeTxtCont: {
    marginLeft: "10px",
    width: "120px",
  },
  landImg: {
    marginTop: "-7px",
    marginRight: "2px",
  },
  renamePopup: {
    height: "300px",
    width: "450px",
  },
  historicalDataText: {
    marginRight: "8px",
    color: "#181A1C",
    width: "fit-content",
  },
  historicalCheckboxWrapper: {
    display: "flex",
    marginLeft: "25px",
    marginBottom: "50px",
    alignItems: "center",
  },
};
export default styles;