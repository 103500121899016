import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";

import CustomFilterChip from "../common/customStyle/FilterChip";
import CustomToggleSwitch from "../common/customStyle/ToggleSwitch";
import EmptyChart from "../common/emptychart";
import Filter from "../common/filters/filters";
import FilterImg from "../../assets/img/filter2.svg";
import NewLoader from "../../layout/newLoader";
import OutsideClickHandler from "react-outside-click-handler";
import ProLabel from "../common/ProLabel";
import React from "react";
import StackColumnChart from "../common/charts/stackcolumn";
import { Trans } from "react-i18next";
import styles from "./siteSummary.style";
import { withStyles } from "@material-ui/core/styles";

//anomalies vs section card
class AnomaliesVsSections extends React.Component {
  state = {
    selectedChartMode: "SectionAnomalies",
    isFilteringBenchmark: false,
    showFilter: false,
    showTypeNames: false,
  };

  //Handles the data change when the chart mode is changed from  Anomalies to Anomalies Status and vice versa
  handleChartChange = (e, newData) => {
    if (newData !== null) this.setState({ selectedChartMode: newData });
  };

  //Removes all the filters applied on the Anomalies vs Sections card
  handleClearFilter = async () => {
    this.setState({
      showFilter: false,
      isFilteringBenchmark: true,
    });
    this.props.selectedFilterChips([]);
    await this.props.applyBenchmarkFilter(
      [],
      "All",
      [],
      ["All"],
      ["All"],
      [],
      [],
      { start: 0, end: 100 },
      ["All"]
    );
    this.setState({ isFilteringBenchmark: false });
  };

  //This renders the Anomalies vs Sections card
  render() {
    const arr = [];

    //Type of chart selected ("Anomalies" by default)
    const selectedChart =
      this.state.selectedChartMode === "SectionStatus"
        ? this.props.anomalies_by_status
        : this.props.anomalies;

    //Information about 2 types of charts available
    const toggleObj = [
      {
        value: "SectionAnomalies",
        name: "Anomalies",
        cssPlace: "Start",
      },
      {
        value: "SectionStatus",
        name: "Anomaly Status",
        cssPlace: "End",
      },
    ];

    const { classes } = this.props;
    return (
      <Card
        className={[classes.scrollBar, classes.card, classes.cardOuterDiv].join(
          " "
        )}
      >
        <CardContent style={styles.contentMob}>
          <div className={classes.cardInnerDiv}>
            <Grid container>
              <Grid item xs className={classes.benchmarkBar}>
                <Typography style={styles.chartHeading}>
                  <Trans>Anomalies v/s Sections</Trans>
                </Typography>
                <div className={classes.proLabel}>
                  <ProLabel marginRight="0" marginLeft="5px" show />
                </div>
                <Grid item>
                  <div
                    style={{
                      display:
                        this.props.product &&
                        this.props.product.product_type === "Basic"
                          ? "none"
                          : "flex",
                      alignItems: "center",
                    }}
                    className={classes.mobileToggle}
                  >
                    <CustomToggleSwitch
                      value={this.state.selectedChartMode}
                      onChange={this.handleChartChange}
                      btnGroup={toggleObj}
                    />
                  </div>
                </Grid>
              </Grid>
              <div style={{ margin: "10px 60px 0 0" }}>
                {this.props.selectedChips &&
                this.props.selectedChips.length > 0 ? (
                  <Button
                    style={{ color: "#5B4DD3" }}
                    onClick={this.handleClearFilter}
                    variant="text"
                  >
                    <Trans>Clear</Trans>
                  </Button>
                ) : null}
                <Button
                  onClick={() => {
                    return this.setState((prevState) => {
                      return {
                        showFilter: !prevState.showFilter,
                      };
                    });
                  }}
                  className={classes.filterButton}
                  variant="outlined"
                  style={{
                    display:
                      this.props.product &&
                      this.props.product.product_type === "Basic" &&
                      "none",
                  }}
                >
                  <Trans>Filters</Trans>
                  {this.props.selectedChips &&
                  this.props.selectedChips.length > 0 ? (
                    <span className={classes.chips}>
                      {this.props.selectedChips
                        ? this.props.selectedChips.length
                        : null}
                    </span>
                  ) : (
                    <img
                      style={{ marginLeft: "8px" }}
                      src={FilterImg}
                      alt="filter"
                    />
                  )}
                </Button>
              </div>

              <Grid container item alignItems="center" id="forChart">
                <div className={classes.mobileFilter}>
                  {this.state.showTypeNames && (
                    <Grid
                      container
                      md={6}
                      className={classes.anomaliesTooltip}
                      alignContent="center"
                    >
                      <Table>
                        {selectedChart &&
                        this.state.selectedChartMode === "SectionAnomalies" ? (
                          <TableBody>
                            <div style={{ display: "none" }}>
                              {selectedChart.section_anomalies.map((obj) => {
                                return obj.defect_type_list.map((srs) => {
                                  const srr = {
                                    name: srs.name,
                                    color: srs.color,
                                  };
                                  return arr.push(srr);
                                });
                              })}
                            </div>
                            {arr
                              .filter((ele, ind) => {
                                return (
                                  ind ===
                                  arr.findIndex((elem) => {
                                    return (
                                      elem.name === ele.name &&
                                      elem.color === ele.color
                                    );
                                  })
                                );
                              })
                              .map((val) => {
                                return (
                                  <TableRow key={val.name}>
                                    <TableCell
                                      classes={{
                                        root: classes.noDividerTable,
                                      }}
                                    >
                                      <div style={{ flexDirection: "column" }}>
                                        <div>
                                          <span
                                            style={{
                                              backgroundColor: val.color,
                                            }}
                                            className={classes.severityBox}
                                          />
                                          <span
                                            style={{ fontSize: "11px" }}
                                            className={classes.severityText}
                                          >
                                            {val.name}
                                          </span>
                                        </div>
                                      </div>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        ) : (
                          selectedChart && (
                            <TableBody>
                              <div style={{ display: "none" }}>
                                {selectedChart.section_status.map((obj) => {
                                  return obj.status_metrics.map((srs) => {
                                    if (srs.name && srs.color) {
                                      const srr = {
                                        name: srs.name,
                                        color: srs.color,
                                      };
                                      return arr.push(srr);
                                    }
                                    return false;
                                  });
                                })}
                              </div>
                              {arr
                                .filter((ele, ind) => {
                                  return (
                                    ind ===
                                    arr.findIndex((elem) => {
                                      return (
                                        elem.name === ele.name &&
                                        elem.color === ele.color
                                      );
                                    })
                                  );
                                })
                                .map((val) => {
                                  return (
                                    <TableRow key={val.name}>
                                      <TableCell
                                        classes={{
                                          root: classes.noDividerTable,
                                        }}
                                      >
                                        <div
                                          style={{ flexDirection: "column" }}
                                        >
                                          <div>
                                            <span
                                              style={{
                                                backgroundColor: val.color,
                                              }}
                                              className={classes.severityBox}
                                            />
                                            <span
                                              style={{ fontSize: "11px" }}
                                              className={classes.severityText}
                                            >
                                              {val.name}
                                            </span>
                                          </div>
                                        </div>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          )
                        )}
                      </Table>
                    </Grid>
                  )}
                </div>
              </Grid>
            </Grid>
            <Divider />
            <Grid container alignItems="center" className={classes.outerChip}>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {this.props.selectedChips &&
                  this.props.selectedChips.map((data) => {
                    return data.type === "temperature_range" &&
                      data.value[0] === 0 &&
                      data.value[1] === 100 ? null : (
                      <CustomFilterChip
                        onDelete={() => {
                          this.props.selectedFilterChips(
                            this.props.selectedChips.filter((item) => {
                              return item.name !== data.name;
                            })
                          );
                          data.delete(data.name);
                        }}
                        type={data.type}
                        name={data.name}
                        lowerTempValue={data.value && data.value[0]}
                        higherTempValue={data.value && data.value[1]}
                      />
                    );
                  })}
              </div>
            </Grid>
            <div className={classes.stackChartContainer}>
              {selectedChart && !this.state.isFilteringBenchmark ? (
                <StackColumnChart
                  dataType={this.state.selectedChartMode}
                  chartData={selectedChart}
                  onHandleShowPlans={this.props.onHandleShowPlans}
                />
              ) : (
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  style={{ height: "100%", marginTop: "150px" }}
                  className={classes.spin}
                >
                  <NewLoader />
                </Grid>
              )}
              <OutsideClickHandler
                onOutsideClick={() =>
                  this.setState({
                    showFilter: false,
                  })
                }
              >
                {selectedChart && this.state.showFilter ? (
                  <div className={classes.filter}>
                    <Filter
                      filters={
                        selectedChart &&
                        this.state.selectedChartMode === "SectionAnomalies"
                          ? this.props.anomalies_filter_options
                          : this.props.anomalies_by_status_filter_options
                      }
                      filterChips={
                        this.props.selectedChips ? this.props.selectedChips : []
                      }
                      applyFilter={async (
                        defect_types,
                        inspection_type,
                        module_type,
                        severity_types,
                        status_types,
                        iec_category,
                        section_names,
                        temperature_range,
                        anomaly_has
                      ) => {
                        this.setState({ isFilteringBenchmark: true });
                        await this.props.applyBenchmarkFilter(
                          defect_types,
                          inspection_type,
                          module_type,
                          severity_types,
                          status_types,
                          iec_category,
                          section_names,
                          temperature_range,
                          anomaly_has
                        );
                        this.setState({ isFilteringBenchmark: false });
                      }}
                      onClearFilter={this.handleClearFilter}
                      closeFilter={() => {
                        return this.setState({ showFilter: false });
                      }}
                      addFilterChips={(filterBenchmarkChips) => {
                        return this.props.selectedFilterChips(
                          filterBenchmarkChips
                        );
                      }}
                    />
                  </div>
                ) : null}
              </OutsideClickHandler>
              {selectedChart &&
                this.state.selectedChartMode === "SectionStatus" &&
                selectedChart.section_status.length === 0 && (
                  <EmptyChart cardName="internal" compName="section" />
                )}
              {selectedChart &&
                this.state.selectedChartMode === "SectionAnomalies" &&
                selectedChart.section_anomalies.length === 0 && (
                  <EmptyChart cardName="internal" compName="section" />
                )}
            </div>
          </div>
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles)(AnomaliesVsSections);
